import { Dropdown, IDropdownOption } from "office-ui-fabric-react";
import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";
import { MY_PACKAGES_AGREEMENTS_KEY, MY_PACKAGES_PRODUCTS_KEY,MY_PACKAGES_WORKS_KEY } from "../../../Consts";
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { title } from "process";
import MyPackagesGeneralDataView from "../MyPackagesGeneralDataView/MyPackagesGeneralDataView";
import { IMyPackagesDownloadFileFormat } from "../../../types/IMyPackagesDownloadFileFormat";
import MyPackagesGridView from "../MyPackagesGridView/MyPackagesGridView";
import { IMyPackagesDetails } from "../../../types/IMyPackagesDetails";
import { EntityType } from "../../../types/EntityType";
import moment from "moment";

export interface MyPackagesMaintenanceProps {
    packageMaintenanceGridsViewData: IRepertoireComponentDataItem;
    myPackagesGeneralDataViewData:IRepertoireComponentDataItem;
    searchResultsTableData?:IRepertoireComponentDataItem;
    componentInstance: string;
    tabs: ITabReduxItem[];
    activeTab: number;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    activeHeaderSection?: string;
    title:string;
    placeholder:string;
    downloadFileFormat:any;
    getMyPackagesDetails?: (userName: string, modalOpen: boolean) => void;
    currentUser?:string;
    myPackagesGridView?:IRepertoireComponentDataItem;
    myPackagesResult?:IMyPackagesDetails[];
    getDownloadFileFormat?:()=>void;   
    clearPackages?: (myPackages: IMyPackagesDetails) => void;
    generatePackage?: (myPackage: IMyPackagesDetails) => void;
    closePackage?: (myPackage: IMyPackagesDetails) => void;
    addPackageNote?: (myPackage: IMyPackagesDetails) => void;
}

interface MyPackagesMaintenanceState {
    activeHeaderSection: string;
    loaded: boolean;
    roleOptions: ILookupInstance[];
    role: any;
    title:string;
    placeholder:string;
    DownloadFileFormats:any[];
    myPackagesGridViewData:any[];
    lastModifiedDate:Date;
    packageItemsCount: number;
    entityType?: string;
}

export default class MyPackagesMaintenancePage extends React.PureComponent<
MyPackagesMaintenanceProps,
MyPackagesMaintenanceState
> {
    viewData;
    constructor(props: MyPackagesMaintenanceProps) {
        super(props);

        this.state = {
            activeHeaderSection: props.activeHeaderSection ?? "myPackagesWorks",
            title:"My Packages - " + EntityType.Work,
            loaded: false,
            roleOptions: [],
            role: '',
            placeholder:'Type new Work Note',
            DownloadFileFormats: [] ,
            myPackagesGridViewData:[],
            lastModifiedDate: null,
            packageItemsCount: 0,
            entityType: EntityType.Work,
        }
        this.viewData = {};
    }

    componentDidMount() {

        const { downloadFileFormat, myPackagesResult } = this.props;
        
        if (myPackagesResult && downloadFileFormat) {
            const intialGridData = this.getInitialmyPackagesGridViewData(EntityType.Work, myPackagesResult);
            this.setState({
                myPackagesGridViewData: intialGridData,
            });
            if (downloadFileFormat[EntityType.Work]) {
                const sectionData = downloadFileFormat[EntityType.Work];
                this.setState({
                    DownloadFileFormats: sectionData.DownloadFileFormats,
                });
            }
        }
    }

    componentDidUpdate(prevProps) {

        const { myPackagesResult, downloadFileFormat } = this.props;
        const { entityType } = this.state
        
       if (
           myPackagesResult !== prevProps.myPackagesResult ||
           downloadFileFormat !== prevProps.downloadFileFormat
       ) {
           if (myPackagesResult && downloadFileFormat) {
               const intialGridData = this.getInitialmyPackagesGridViewData(entityType, myPackagesResult);
               this.setState({
                   myPackagesGridViewData: intialGridData,
               });
               if (downloadFileFormat[entityType]) {
                   const sectionData = downloadFileFormat[entityType];
                   this.setState({
                       DownloadFileFormats: sectionData.DownloadFileFormats,
                   });
               }
           }
       }
    }
    getInitialmyPackagesGridViewData(defaultSection, myPackagesResult) { 
        if(myPackagesResult && myPackagesResult['data'].length > 0) 
        {
          const sectionData = myPackagesResult['data'];
          const section=sectionData.filter(x=>x.entityType===defaultSection && x.downloadLink)
          return section

        }
    }
    handleClearPackages = (updatedPackagesData: IMyPackagesDetails[],headerType?:string) => {
     
        const clearedGridData = updatedPackagesData.filter(packageItem => packageItem.entityType === headerType && packageItem.downloadLink);
        this.setState({
            myPackagesGridViewData: clearedGridData,
        });
    };

    onClickHeaderSection = (activeHeaderSection: any) => {
        const activeHeaderSectiondata=activeHeaderSection.data
        activeHeaderSection=activeHeaderSection.name;
        const { downloadFileFormat, myPackagesResult } = this.props;
        const sectionData = downloadFileFormat[activeHeaderSectiondata];
        const intialGridData = this.getInitialmyPackagesGridViewData(activeHeaderSectiondata, myPackagesResult);
        this.setState({
            activeHeaderSection,
             title:"My Packages - "+ activeHeaderSectiondata,
             placeholder:'Type new '+activeHeaderSectiondata +' Note',
             DownloadFileFormats:sectionData.DownloadFileFormats,
             myPackagesGridViewData: intialGridData,
            entityType:activeHeaderSectiondata,
        });
    };



    renderGridViewHeaderSections = () => {
        const { activeHeaderSection } = this.state;
        const { packageMaintenanceGridsViewData} = this.props;
        if (packageMaintenanceGridsViewData && packageMaintenanceGridsViewData.fields) {
            this.setState({
                title: "My Packages - " + packageMaintenanceGridsViewData.fields.filter(x => x.name === activeHeaderSection)[0].data,
            });
            return packageMaintenanceGridsViewData.fields.filter(f => f.fieldType === null).map((section, index) => (
                <div
                    className={[
                        "headerSection", activeHeaderSection === section.name ? "activeSection" : null
                    ].join(" ")}
                    key={index}
                    onClick={() => this.onClickHeaderSection(section)}
                >
                    {section.data}
                </div>
            ));
        }
    };

    onClickRefresh = () => {
        const { currentUser, getMyPackagesDetails } = this.props;
        getMyPackagesDetails(currentUser, true);
    };


    render() {
        const {
            myPackagesGeneralDataViewData,
            getMyPackagesDetails,
            myPackagesGridView,
            myPackagesResult,
            clearPackages,
            downloadFileFormat,
            generatePackage,
            closePackage,
            addPackageNote
        } = this.props;
        const { activeHeaderSection,title,placeholder,DownloadFileFormats,myPackagesGridViewData,lastModifiedDate,packageItemsCount} = this.state;
        const username = sessionStorage.getItem('username');
        const formattedContents = myPackagesGridViewData.map(item => ({
            lastModifiedDate: moment(item.lastModifiedDate).format("DD/MM/YYYY hh:mm A"), 
            note:item.note,
            id:item.id,
            downloadFileFormat: item.downloadFileFormat,
            PackageItems: item.PackageItems,
            status: item.status,
            username: item.username,
            entityType: item.entityType,
            downloadLink: item.downloadLink,
        }));
        return (
            <>
            <div>
              <div className="GridsView">
                <div className="row" style={{marginBottom: "15px"}}>
                    <div className="col-md-12 editableSectionHeading">
                          
                           <div><span className="title">{title}</span></div>

                                <button className="refreshBtn" title="Refresh myPackage details" onClick={() => this.onClickRefresh()}>
                                <i className="ms-Icon ms-Icon--Refresh"></i>
                            </button>
                          
                        </div>
                    </div>
           
           
                    <div className="headerContainer">
                        {this.renderGridViewHeaderSections()}
                    </div>
                   
                    {activeHeaderSection === MY_PACKAGES_WORKS_KEY ?
                    <>
                    
                       
                       <MyPackagesGeneralDataView
                                    myPackagesGeneralDataViewData={myPackagesGeneralDataViewData}
                                    isReadonly={false}
                                    clearPackages={clearPackages}
                                    lookupValues={undefined}
                                    placeholder={placeholder}
                                    DownloadFileFormats={DownloadFileFormats}
                                    myPackagesResult={myPackagesResult}
                                    getMyPackagesDetails={getMyPackagesDetails}
                                    currentUser={username}
                                    headerType={EntityType.Work}
                                    handleClearPackages={this.handleClearPackages}
                                    editableFields={[]}
                                    generatePackage={generatePackage}
                                    closePackage={closePackage}                                
                                    addPackageNote={addPackageNote}                                />
                        <div>
                       {myPackagesResult && myPackagesResult['data'].length>0 &&
                            <MyPackagesGridView 
                                currentUser={username}
                                getMyPackagesDetails={getMyPackagesDetails}
                                componentInstance={""} 
                                myPackagesGridView={myPackagesGridView} 
                                tableContents={formattedContents}                
                            />
                       }

                        </div>
                       
                    </>   : <div> </div>}

                    {activeHeaderSection === MY_PACKAGES_PRODUCTS_KEY ?  <>
                    
                       
                    <MyPackagesGeneralDataView
                                myPackagesGeneralDataViewData={myPackagesGeneralDataViewData}
                                clearPackages={clearPackages}
                                isReadonly={false}
                                lookupValues={undefined}
                                placeholder={placeholder}
                                DownloadFileFormats={DownloadFileFormats}
                                myPackagesResult={myPackagesResult} 
                                headerType={EntityType.Products}
                                getMyPackagesDetails={getMyPackagesDetails}
                                currentUser={username}
                                handleClearPackages={this.handleClearPackages}
                                editableFields={[]}
                                generatePackage={generatePackage}
                                closePackage={closePackage}
                                addPackageNote={addPackageNote} />
                        <div>
                            <MyPackagesGridView 
                                currentUser={username}
                                getMyPackagesDetails={getMyPackagesDetails}
                                componentInstance={""} 
                                myPackagesGridView={myPackagesGridView} 
                                tableContents={formattedContents}                   
                            />
                        </div>
                    
                 </>   : <div> </div>}

                    {activeHeaderSection === MY_PACKAGES_AGREEMENTS_KEY ?
                          <>
                    
                       
                        <MyPackagesGeneralDataView
                                    myPackagesGeneralDataViewData={myPackagesGeneralDataViewData}
                                    isReadonly={false}
                                    clearPackages={clearPackages}
                                    lookupValues={undefined}
                                    placeholder={placeholder}
                                    DownloadFileFormats={DownloadFileFormats}
                                    myPackagesResult={myPackagesResult} 
                                    headerType={EntityType.Agreements}
                                    getMyPackagesDetails={getMyPackagesDetails}
                                    currentUser={username}
                                    handleClearPackages={this.handleClearPackages}
                                    editableFields={[]}
                                    generatePackage={generatePackage}
                                    closePackage={closePackage}
                                    addPackageNote={addPackageNote} />
                        <div>
                            <MyPackagesGridView 
                                currentUser={username}
                                getMyPackagesDetails={getMyPackagesDetails}
                                componentInstance={""} 
                                myPackagesGridView={myPackagesGridView} 
                                tableContents={formattedContents}                   
                            />
                        </div>
                          
                       </>   : <div> </div>}
                </div>
            </div>
            
            </>
        );
    }
}