import * as React from "react";
import { IShowMoreItem } from "../../types/IShowMoreItem";

export interface IShowMoreProps {
    options: IShowMoreItem[];
    isReadOnly?: boolean;
}

interface IShowMoreState {
    showPopUp: boolean;
}

const ShowMore: React.FC<IShowMoreProps> = ({
    options,
    isReadOnly,
}) => {
    const [showPopUp, setShowPopup] = React.useState(false)

    let componentRefShowMore = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        document.addEventListener('click', onClickOutsideComponent)

        return document.removeEventListener(
            "mousedown",
            onClickOutsideComponent,
            false
        );
    }, [])

    const onClickOutsideComponent = (event: any) => {

        if (componentRefShowMore.current && componentRefShowMore.current.contains(event.target)) {
            return;
        }

        setShowPopup(false)
    };

    const onClick = (event) => {
        setShowPopup(!showPopUp)
    };

    const renderActions = () => {
        return options.map((option, index) => (
            <div
                className="popUpItem"
                key={index}
                onClick={() => { option.onClick(); setShowPopup(false) }}
                data-testid={"pop-up-" + index}
            >
                {option.isIcon != undefined && option.isIcon ? <i className={option.icon} /> : <img src={option.icon} alt={option.text} className="icon" />}
                <div>{option.text}</div>
            </div>
        ));
    };

    return (
        <div ref={componentRefShowMore}>
            <i className="icon ms-Icon ms-Icon--MoreVertical inheritPosition"
                aria-hidden="true"
                onClick={onClick} hidden={isReadOnly} data-testid={"pop-up-i"} />

            {showPopUp && (
                <div className="showMore" >
                    <div className={"popUp " + ((options.length === 1 && options[0].text !== 'Territory Exclude') ? "singleItemPopUp" : '') + "" + (options[0].text === 'Territory Exclude' ? "centeredSingleItemPop" : '')} data-testid={"pop-up-item"} >
                        {renderActions()}
                    </div>
                </div>)}
        </div>
    );
}

export default ShowMore;