import * as React from "react";
import { Tab } from "react-bootstrap";
import { ITab } from "../../types/ITab";
import TabComponent from "./TabComponent";
import { displayRepresentationFlag, removeTab } from "../../../redux/reducers/RepertoireReducer";
import CarouselScrollButton from "../carouselScrollButton/CarouselScrollButton";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { IYesNoPromptViewModalProps } from "../modalViews/yesNoPromptView/YesNoPromptView";
import { hideModal, showYesNoPrompt } from "../../../redux/reducers/ModalReducer";
import { WORK_INTRAY_TAB_ICON, WORK_INTRAY_TAB_ICON_TRANSFORM, INTRAY, WORK_REPERTOIRE_TAB_ICON, WORK_REPERTOIRE_TAB_ICON_TRANSFORM, PRODUCT_INTRAY_TAB_ICON, PRODUCT_INTRAY_TAB_ICON_TRANSFORM, PRODUCT_REPERTOIRE_TAB_ICON, PRODUCT_REPERTOIRE_TAB_ICON_TRANSFORM, AGREEMENT_INTRAY_TAB_ICON, AGREEMENT_INTRAY_TAB_ICON_TRANSFORM, AGREEMENT_REPERTOIRE_TAB_ICON, AGREEMENT_REPERTOIRE_TAB_ICON_TRANSFORM } from "../../Consts";
import { setCurrentPage } from "../../../redux/reducers/HomeReducer";
import { MATCHING_ENGINE, MEMBERS_PORTAL } from "../../../accounts/Consts";
import IShortCutKeys from "../../../redux/types/IShortCutKeys";
import { FIRST_PANE_INDEX, SECOND_PANE_INDEX } from "../../RepertoirePageContainer";

export interface ITabsViewProps {
    tabs: ITabReduxItem[];
    tabsPaneTwo: ITabReduxItem[];
    tabsViewData: IRepertoireComponentDataItem;
    removeTab: typeof removeTab;
    activeTab: number;
    activeTabPaneTwo: number;
    openTab: (index: number, pane: number) => void;
    setActivePane: (pane: number) => void;
    closeTabDisabled: boolean;
    hideModal?: typeof hideModal;
    showYesNoPrompt?: typeof showYesNoPrompt;
    displayRepresentation: typeof displayRepresentationFlag;
    setCurrentPage: typeof setCurrentPage;
    portalType: string;
    displayModal: boolean;
    shortCutKeys: IShortCutKeys[];
    activePane: number;
    closeAllTabsInSecondPane: () => void;
}

const TabsView: React.FC<ITabsViewProps> = (
        {
            tabs,
            tabsPaneTwo,
            tabsViewData,
            removeTab,
            activeTab,
            activeTabPaneTwo,
            openTab,
            setActivePane,
            closeTabDisabled,
            hideModal,
            showYesNoPrompt,
            displayRepresentation,
            setCurrentPage,
            portalType,
            displayModal,
            shortCutKeys,
            activePane,
            closeAllTabsInSecondPane,
        },
    ) => {
        
        const secondPaneRef = React.useRef<HTMLDivElement>(null);
        const firstPaneRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const onClickOutsideSecondPane = () => {
            setActivePane(0);
        };
        const onClickInsideSecondPane = () => {
            setActivePane(1)
        };

        const handleMakeAPaneActive = (event) => {
            if (displayModal) return;
            if (secondPaneRef.current && !secondPaneRef.current.contains(event.target)) {
                onClickOutsideSecondPane();
            }
            if (secondPaneRef.current && secondPaneRef.current.contains(event.target)) {
                onClickInsideSecondPane();
              }

            if (firstPaneRef.current && firstPaneRef.current.contains(event.target)) { 
                onClickOutsideSecondPane();
            }
          }

        const close = (e) => {
            const escapeKey = shortCutKeys  && shortCutKeys.find(key => key.actionCode === 'CLOSE_ACTIVE_TAB') ? shortCutKeys.find(key => key.actionCode === 'CLOSE_ACTIVE_TAB')?.key : 'Escape';
          if(e.key === escapeKey && !displayModal){
            onClickRemoveTab(activeTab, activePane);
          }
        }
        window.addEventListener('keydown', close)
        window.addEventListener('click', handleMakeAPaneActive);
      return () =>{
        window.removeEventListener('click', handleMakeAPaneActive);
         window.removeEventListener('keydown', close)}
    },[activeTab, displayModal])



    const onClickRemoveTab = (index: number, paneIndex: number) => {
        if (closeTabDisabled){
            return;
        }
        if ((paneIndex === FIRST_PANE_INDEX && tabs[index].changesMade) || (paneIndex === SECOND_PANE_INDEX && tabsPaneTwo[index].changesMade)) {
            const title: string = tabsViewData.fields[0].data;
            const props: IYesNoPromptViewModalProps = {
                yesCallback: () => { 
                    removeTab(index, paneIndex);  
                    hideModal();
                },
                noCallback: () => { hideModal();  }
            }
            showYesNoPrompt(title, props);
        } else {
            removeTab(index, paneIndex);
        }
    }


    const onClick = async(target, index, paneIndex, activeTab) => {
        let address = portalType === MEMBERS_PORTAL ? '/works/' : '/repertoire/';
        const META_DATA_KEY: any = {
            'find work': { 'link': "works",instance: MATCHING_ENGINE },
            'find agreement': { 'link': "agreements", instance: MATCHING_ENGINE },
            'find ip': { 'link': "ips", instance: MATCHING_ENGINE },
            'find artist': { 'link': "artists", instance: MATCHING_ENGINE },
            'find product': { 'link': "products", instance: MATCHING_ENGINE },
            'find workflow': { 'link': "workflows", instance: MATCHING_ENGINE },
            'find usage information': { 'link': "usage", instance: MATCHING_ENGINE },
            'find usage pools': { 'link': "pools", instance: MATCHING_ENGINE },
            'find distribution': { 'link': "distributions", instance: MATCHING_ENGINE },
            'find payment run': { 'link': "paymentRuns", instance: MATCHING_ENGINE },
            'find license': { 'link': "licenses", instance: MATCHING_ENGINE },
            'find adjustment': { 'link': "adjustments", instance: MATCHING_ENGINE },
        }

        const stringToSearch =`${paneIndex} icon ms-Icon ms-Icon--ChromeClose`;
        const remove = document.getElementsByClassName(stringToSearch);
        const isRemoved = remove[index].contains(target);
        if(isRemoved) {
            await onClickRemoveTab(index, paneIndex);
        }
        else  {
            await openTab(index, paneIndex);
        }
        if (paneIndex === FIRST_PANE_INDEX && tabs[activeTab]?.title && tabs[activeTab].title.toLocaleLowerCase().search('find') > 0 ) {
            setCurrentPage(address + META_DATA_KEY[tabs[activeTab].title.toLowerCase()].link);
        } 
        if (paneIndex === SECOND_PANE_INDEX && tabsPaneTwo[activeTabPaneTwo]?.title && tabsPaneTwo[activeTabPaneTwo].title.toLocaleLowerCase().search('find') > 0) {
            setCurrentPage(address + META_DATA_KEY[tabsPaneTwo[activeTabPaneTwo].title.toLowerCase()].link);
        }

        let documentTitle = document.title;
        if (paneIndex === FIRST_PANE_INDEX && tabs[activeTab]?.title ) {
            documentTitle = tabs[activeTab].title;
        }
        if (paneIndex === SECOND_PANE_INDEX && tabsPaneTwo[activeTabPaneTwo]?.title ) {
            documentTitle = tabsPaneTwo[activeTabPaneTwo].title;
        }

        document.title = documentTitle;

    }

    const removeTabOnClick = async(index: number, paneIndex: number) => {
        await onClickRemoveTab(index, paneIndex)
    };

    const renderTabs = (tabs, paneIndex, activeTab) => {
        return tabs.map((tab: ITab, index: number) => (
            <div onClick={(e) => onClick(e.target, index, paneIndex, activeTab)} key={index} className={activeTab === index ? "activeTab" : "tab"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 100 100">
                    {tabs[index].workMaintenanceState && tabs[index].workMaintenanceState.work ?
                        (tabs[index].workMaintenanceState.work.dataSource === INTRAY ?
                            <path id="" className="tabIconType" d={WORK_INTRAY_TAB_ICON} transform={WORK_INTRAY_TAB_ICON_TRANSFORM}></path>
                            : <path id="" className="tabIconType" d={WORK_REPERTOIRE_TAB_ICON} transform={WORK_REPERTOIRE_TAB_ICON_TRANSFORM}></path>)
                        : tabs[index].productMaintenanceState && tabs[index].productMaintenanceState.product ?
                            (tabs[index].productMaintenanceState.product.dataSource === INTRAY ?
                                <path id="" className="tabIconType" d={PRODUCT_INTRAY_TAB_ICON} transform={PRODUCT_INTRAY_TAB_ICON_TRANSFORM}></path>
                                : <path id="" className="tabIconType" d={PRODUCT_REPERTOIRE_TAB_ICON} transform={PRODUCT_REPERTOIRE_TAB_ICON_TRANSFORM}></path>)
                            : tabs[index].agreementMaintenanceState && tabs[index].agreementMaintenanceState.agreement ?
                                (tabs[index].agreementMaintenanceState.agreement.dataSource === INTRAY ?
                                    <path id="" className="tabIconType" d={AGREEMENT_INTRAY_TAB_ICON} transform={AGREEMENT_INTRAY_TAB_ICON_TRANSFORM}></path>
                                    : <path id="" className="tabIconType" d={AGREEMENT_REPERTOIRE_TAB_ICON} transform={AGREEMENT_REPERTOIRE_TAB_ICON_TRANSFORM}></path>)
                        :<></>
                    }
                    </svg> 

                <div>
                    {tab.title ? tab.title : "untitled"}
                </div>
                <div
                    className={["removeTabDiv", !!closeTabDisabled && "closeDisabled"].join(" ")}
                >
                    <span className={`${paneIndex} icon ms-Icon ms-Icon--ChromeClose`} aria-hidden="true"></span>
                </div>
            </div>
        ));
    };

    const renderTabContent = (tabsContent, paneIndex) => {
        return tabsContent.map((tab: ITab, index: number) => (
            <Tab.Pane eventKey={index} key={index} className={tab.isReadonly ? 'versionHistory' : ''} >
                <TabComponent component={tab.component} displayRepresentation={displayRepresentation} paneIndex={paneIndex}/>
            </Tab.Pane>
        ));
    };

        return (
            <div className="flexRow">
                <div ref={firstPaneRef} className={`${tabsPaneTwo && tabsPaneTwo.length > 0 && 'TabsViewSplitScreen'} form-group col-md-12 TabsView`}>
                <div className="tabsDiv">
                    <CarouselScrollButton
                        content={renderTabs(tabs, FIRST_PANE_INDEX, activeTab)}
                        distanceToScroll={100}
                    />
                </div>
                <Tab.Container
                    activeKey={activeTab}
                    onSelect={() => null}
                    id="tab-container" className="tab-content formBox tab-pane active fade in"
                >
                    <Tab.Content>{renderTabContent(tabs, FIRST_PANE_INDEX)}</Tab.Content>
                </Tab.Container>

                </div>
                {tabsPaneTwo && tabsPaneTwo.length > 0 && (
                <div ref={secondPaneRef} className={`${tabsPaneTwo && tabsPaneTwo.length > 0 && 'TabsViewSplitScreen'} form-group col-md-12 TabsView`}>
                    <div className="tabsDiv">
                        <CarouselScrollButton
                            content={renderTabs(tabsPaneTwo, SECOND_PANE_INDEX, activeTabPaneTwo)}
                            distanceToScroll={100}
                        />
                    <button type="button" className="closeSecondPane"  onClick={() => closeAllTabsInSecondPane()}>
                        <i className="icon ms-Icon ms-Icon--ChromeClose" aria-hidden="true"></i>
                    </button>
                    </div>
                        <Tab.Container
                            activeKey={activeTabPaneTwo}
                            onSelect={() => null}
                            id="tab-container" className="tab-content formBox tab-pane active fade in"
                        >
                            <Tab.Content>{renderTabContent(tabsPaneTwo, SECOND_PANE_INDEX)}</Tab.Content>
                        </Tab.Container>
                </div>
                )}
            </div>
        );
}

export default TabsView;