import {
    getRepertoireComponentDataRequest,
    getRepertoireComponentDataSuccess,
    getRepertoireComponentDataFailure,
    searchWorksRequest,
    searchWorksSuccess,
    searchWorksFailure,
    searchArtistsRequest,
    searchArtistsSuccess,
    searchArtistsFailure,
    searchContributorsRequest,
    searchContributorsSuccess,
    searchContributorsFailure,
    mergeMatchingWorkSuccess,
    mergeMatchingWorkFailure,
    mergeRepertoireWorkSuccess,
    mergeRepertoireWorkFailure,
    deleteWorkequest,
    deleteWorkSuccess,
    deleteWorkFailure,
    saveWorkRequest,
    saveWorkSuccess,
    saveWorkFailure,
    getWorkDetailsRequest,
    getWorkDetailsSuccess,
    getWorkDetailsFailure,
    refreshWorkDetailsRequest,
    refreshWorkDetailsSuccess,
    refreshWorkDetailsFailure,
    addTab,
    removeTab,
    undoWorkChangesRequest,
    undoWorkChangesSuccess,
    searchAgreementsRequest,
    searchAgreementsSuccess,
    searchAgreementsFailure,
    getAgreementDetailsRequest,
    getAgreementDetailsSuccess,
    getAgreementDetailsFailure,
    searchIPsRequest,
    searchIPsSuccess,
    searchIPsFailure,
    getIPDetailsRequest,
    getIPDetailsSuccess,
    getIPDetailsFailure,
    updateWorkContributorIPRequest,
    updateWorkContributorIPSuccess,
    updateWorkContributorIPFailure,
    saveAccountRequest,
    saveAccountSuccess,
    saveAgreementRequest,
    saveAgreementFailure,
    saveAgreementSuccess,
    deleteAgreementSuccess,
    deleteAgreementFailure,
    deleteAgreementRequest,
    searchProductsRequest,
    searchProductsSuccess,
    searchProductsFailure,
    getProductDetailsRequest,
    getProductDetailsSuccess,
    getProductDetailsFailure,
    saveProductRequest,
    saveProductFailure,
    saveProductSuccess,
    deleteProductRequest,
    deleteProductSuccess,
    deleteProductFailure,
    undoAgreementChangesSuccess,
    undoAgreementChangesFailure,
    undoAgreementChangesRequest,
    undoIPChangesRequest,
    undoIPChangesSuccess,
    undoIPChangesFailure,
    searchWorkVersionHistoryRequest,
    searchWorkVersionHistorySuccess,
    searchWorkVersionHistoryFailure,
    undoProductChangesRequest,
    undoProductChangesSuccess,
    searchProductVersionHistoryRequest,
    searchProductVersionHistorySuccess,
    searchProductVersionHistoryFailure,
    deleteAccountRequest,
    deleteAccountRequestFailure,
    deleteAccountRequestSuccess,
    saveAccountFailure,
    filterIpAgreementsRequest,
    filterIpAgreementsSuccess,
    updateIpAgreementsFilterStateField,
    getContributorsDisplaySettingSuccess,
    getOtherIndicatorsWorkFlagTypesSuccess,
    getReadonlyIndicatorsWorkFlagTypesSuccess,
    getContributorsDisplaySettingRequest,
    getContributorsDisplaySettingFailure,
    updateFieldComponentRequest,
    updateFieldComponentRequestFailure,
    updateFieldComponentRequestSuccess,
    getFormatFieldsRequest,
    getFormatFieldsSuccess,
    applyFormatsWorkMaintenance,
    applyFormatsProductMaintenance,
    searchSocIPVersionHistoryRequest,
    searchSocIPVersionHistorySuccess,
    searchSocIPVersionHistoryFailure,
    replaceTab,
    removeWorkAttachmentSuccess,
    removeWorkAttachmentRequest,
    removeWorkAttachmentFailure,
    searchWorkflowRequest,
    searchWorkflowSuccess,
    assignWorkflowsRequest,
    assignWorkflowsSuccess,
    assignWorkflowsFailure,
    updateStatusWorkflowsRequest,
    updateStatusWorkflowsSuccess,
    updateStatusWorkflowsFailure,
    getUsersRequest,
    getUsersSuccess,
    getUsersFailure,
    markSelectAll,
    getAgreementDisplayOptionsRequest,
    getAgreementDisplayOptionsSuccess,
    getAgreementDisplayOptionsFailure,
    getShowCommentSubjectAsDropdownConfigurationParameterRequest,
    getShowCommentSubjectAsDropdownConfigurationParameterSuccess,
    getShowCommentSubjectAsDropdownConfigurationParameterFailure,
    removeAgreementAttachmentSuccess,
    removeAgreementAttachmentRequest,
    removeAgreementAttachmentFailure,
    getShareDecimalsToDisplayRequest,
    getShareDecimalsToDisplaySuccess,
    getShareDecimalsToDisplayFailure,
    nextWorkflowInSession,
    cancelWorkflowSession,
    submitProductAVRequest,
    submitProductAVRequestSuccess,
    submitProductAVRequestFailure,
    getProductMaintenanceSubmissionConfigRequest,
    getProductMaintenanceSubmissionConfigSuccess,
    getProductMaintenanceSubmissionConfigFailure,
    updateUserPreference,
    getUserPreferenceRequest,
    getUserPreferenceSuccess,
    getUserPreferenceFailure,
    removeProductAttachmentSuccess,
    removeProductAttachmentRequest,
    removeProductAttachmentFailure,
    getUsageDistributionsRequest,
    getUsageDistributionsSuccess,
    getUsageDistributionsFailure,
    getSourcesRequest,
    getSourcesSuccess,
    getSourcesFailure,
    getSourceMajorsRequest,
    getSourceMajorsSuccess,
    getSourceMajorsFailure,
    getSourceMinorsRequest,
    getSourceMinorsSuccess,
    getSourceMinorsFailure,
    saveUsageGroupRequest,
    saveUsageGroupFailure,
    saveUsageGroupSuccess,
    refreshProductDetailsRequest,
    refreshProductDetailsSuccess,
    saveArtistRequest,
    saveArtistFailure,
    saveArtistSuccess,
    deleteArtistRequest,
    deleteArtistRequestSuccess,
    deleteArtistRequestFailure,
    undoArtistChangesSuccess,
    getArtistDetailsRequest,
    getArtistDetailsSuccess,
    searchUsagePoolsRequest,
    searchUsagePoolsSuccess,
    searchUsagePoolsFailure,
    getArtistDetailsFailure, searchMatchProductsRequest, searchMatchProductsFailure, searchMatchProductsSuccess, getUsagePoolRequest, getUsagePoolSuccess, getUsagePoolFailure, saveUsagePoolRequest, saveUsagePoolSuccess, saveUsagePoolFailure, deleteUsagePoolRequest, deleteUsagePoolSuccess, deleteUsagePoolFailure, getMatchingSourcesRequest, getMatchingSourcesSuccess, searchDistributionsRequest, searchDistributionsSuccess, searchDistributionsFailure, getDistributionTypesRequest, getDistributionTypesSuccess, getDistributionTypesFailure, getDistributionRequest, getDistributionSuccess, getDistributionFailure, saveDistributionRequest, saveDistributionFailure, saveDistributionSuccess, getIPMaintenanceDisplaySettingsRequest, getIPMaintenanceDisplaySettingsSuccess, getIPMaintenanceDisplaySettingsFailure, undoDistributionChangesRequest, undoDistributionChangesSuccess, undoDistributionChangesFailure, deleteDistributionRequest, deleteDistributionSuccess, deleteDistributionFailure, searchAgreementVersionHistoryFailure, searchAgreementVersionHistoryRequest, searchAgreementVersionHistorySuccess, getUsagePoolsFailure, getUsagePoolsRequest, getUsagePoolsSuccess, exportDistributionPoolsRequest, searchDistributionVersionHistoryRequest, searchDistributionVersionHistorySuccess, searchDistributionVersionHistoryFailure, searchPoolVersionHistoryRequest, searchPoolVersionHistorySuccess, searchPoolVersionHistoryFailure, exportDistributionPoolsSuccess, getMatchingWorkInfoRequest, getMatchingWorkInfoFailure, getMatchingWorkInfoSuccess, addRepresentationRequest,
    addRepresentationFailure, addRepresentationSuccess, getRepresentationGroupRequest, getRepresentationGroupFailure, getRepresentationGroupSuccess, deleteRepresentationsRequest, deleteRepresentationsFailure, deleteRepresentationsSuccess,
    saveClaimRequest, saveClaimSuccess, saveClaimFailure, getOtherIndicatorsWorkFlagTypesRequest, getOtherIndicatorsWorkFlagTypesFailure, getReadonlyIndicatorsWorkFlagTypesRequest, getReadonlyIndicatorsWorkFlagTypesFailure, getReadonlyFlagsFieldRequest, getReadonlyFlagsFieldSuccess, getReadonlyFlagsFieldFailure,
    searchAdjustmentsRequest, searchAdjustmentsSuccess, searchAdjustmentsFailure,
    saveWorkflowRequest, saveWorkflowSuccess, saveWorkflowFailure, refreshUsageDetailsRequest, refreshUsageDetailsSuccess, refreshUsageDetailsFailure, getAdjustmentDetailsRequest, getAdjustmentDetailsSuccess, getAdjustmentDetailsFailure, saveAdjustmentRequest, saveAdjustmentFailure, saveAdjustmentSuccess, searchLastPercentageRequest, searchLastPercentageSuccess, searchLastPercentageFailure, ipTransferJobRequest, ipTransferJobSuccess, ipTransferJobFailure, searchDatabricksAllocationsRequest, searchDatabricksAllocationsSuccess, getGenreCategoriesRequest, getGenreCategoriesFailure, getGenreCategoriesSuccess,
    getGenreCategoriesByDomOrForeignFailure,
    getGenreCategoriesByDomOrForeignRequest,
    getGenreCategoriesByDomOrForeignSuccess,
    searchPaymentRunsRequest,
    searchPaymentRunsSuccess,
    searchPaymentRunsFailure,
    getPaymentRunIDsRequest,
    getPaymentRunIDsSuccess,
    getPaymentRunIDsFailure,
    getPaymentRunRequest,
    getPaymentRunSuccess,
    savePaymentRunRequest,
    getPaymentRunFailure,
    savePaymentRunFailure,
    savePaymentRunSuccess,
    deletePaymentRunRequest,
    deletePaymentRunFailure,
    deletePaymentRunSuccess,
    undoPaymentRunChangesRequest,
    undoPaymentRunChangesSuccess,
    undoPaymentRunChangesFailure,
    getUsageMatchingDefaultsProductsRequest,
    getUsageMatchingDefaultsProductsSuccess,
    getUsageMatchingDefaultsProductsFailure,
    getUsageMatchingDefaultsWorksRequest,
    getUsageMatchingDefaultsWorksSuccess,
    getUsageMatchingDefaultsWorksFailure,
    searchPaymentRunVersionHistoryRequest,
    searchPaymentRunVersionHistorySuccess,
    searchPaymentRunVersionHistoryFailure,
    searchAdjustmentVersionHistoryRequest,
    searchAdjustmentVersionHistorySuccess,
    searchAdjustmentVersionHistoryFailure,
    saveUsageGroupFromSearchSuccess,
    saveUsageGroupFromSearchFailure,
    searchLicensesFailure,
    searchLicensesSuccess,
    searchLicensesRequest,
    getLicenseRequest,
    getLicenseSuccess,
    getLicenseFailure,
    saveLicenseRequest,
    deleteLicenseRequest,
    getShoppingCartParametersSuccess,
    searchLicenseUserRequest,
    searchLicenseUserSuccess,
    searchLicenseUserFailure,
    mergeMatchingProductFailure,
    mergeRepertoireProductFailure,
    mergeMatchingProductSuccess,
    mergeRepertoireProductSuccess,
    saveLicenseSuccess,
    saveLicenseFailure,
    deleteBlobSuccess,
    deleteBlobFailure,
    getLicenseRequestWorkParameterRequest,
    getLicenseRequestWorkParameterSuccess,
    getLicenseRequestWorkParameterFailure,
    getLicenseWorksContributorsSuccess,
    removeUsageAttachmentRequest,
    removeUsageAttachmentSuccess,
    removeUsageAttachmentFailure,
    undoAdjustmentChanges,
    undoAdjustmentChangesSuccess,
    undoAdjustmentChangesFailure,
    calculateLicenseWorksPricesRequest,
    calculateLicenseWorksPricesFailure,
    calculateLicenseWorksPricesSuccess,
    updateIPNames,
    getClaimSuccess,
    getClaimFailure,
    saveIPNamesSuccess,
    mergeMatchingIPFailure,
    mergeMatchingIPSuccess,
    getCommentCategoryFlagRequest,
    getCommentCategoryFlagFailure,
    getCommentCategoryFlagSuccess,
    getDistributionSubTypesRequest,
    getDistributionSubTypesSuccess,
    getDistributionSubTypesFailure,
    approveRejectWorkflowSuccess,
    submitLicenseSuccess,
    submitLicenseFailure,
    undoLicenseSuccess,
    getAgreementAttachmentTypeRequiredRequest,
    getAgreementAttachmentTypeRequiredSuccess,
    getAgreementAttachmentTypeRequiredFailure,
    getWorkMaintenanceGeneralViewDisplayOptionsRequest,
    getWorkMaintenanceGeneralViewDisplayOptionsSuccess,
    getWorkMaintenanceGeneralViewDisplayOptionsFailure,
    getShareToleranceSettingsRequest,
    getShareToleranceSettingsSuccess,
    getShareToleranceSettingsFailure,
    saveWorkNumbersAdditionalSuccess,
    saveWorkNumbersAdditionalFailure,
    getCurrentPageAdditionalWorkNumberDetailsFailure,
    getCurrentPageAdditionalWorkNumberDetailsSuccess,
    getUsageGridSearchResultsDisplayOptionsRequest,
    getUsageGridSearchResultsDisplayOptionsSuccess,
    getUsageGridSearchResultsDisplayOptionsFailure,
    searchSubjectSuccess,
    getExcludeSourceTypePickShowFieldsRequest,
    getExcludeSourceTypePickShowFieldsSuccess,
    getExcludeSourceTypePickShowFieldsFailure,
    getFilterGenreCategoriesRequest,
    getFilterGenreCategoriesSuccess,
    getFilterGenreCategoriesFailure,
    getDistributionMaintenanceSettingsViewDisplayOptionsRequest,
    getDistributionMaintenanceSettingsDisplayOptionsSuccess,
    getDistributionMaintenanceSettingsDisplayOptionsFailure,
    getIPGridSearchResultsDisplayOptionsRequest,
    getIPGridSearchResultsDisplayOptionsSuccess,
    getIPGridSearchResultsDisplayOptionsFailure,
    getShortCutKeysRequest,
    getShortCutKeysSuccess,
    getProductMaintenanceGeneralViewDisplayOptionsSuccess,
    getProductMaintenanceGeneralViewDisplayOptionsRequest,
    getProductMaintenanceGeneralViewDisplayOptionsFailure,
    getPDArrangementMusicLyricRequest,
    getPDArrangementMusicLyricSuccess,
    getPDArrangementMusicLyricFailure,
    getProductMaintenanceEnableCuesheetsRequest,
    getProductMaintenanceEnableCuesheetsSuccess,
    getProductMaintenanceEnableCuesheetsFailure,
    getProductMaintenanceCuesheetsDataSourceRequest,
    getProductMaintenanceCuesheetsDataSourceSuccess,
    getProductMaintenanceCuesheetsDataSourceFailure,
    filterIpRepresentationsRequest,
    filterIpRepresentationsSuccess,
    getPoolMaintenanceGeneralViewDisplayOptionsRequest,
    getPoolMaintenanceGeneralViewDisplayOptionsSuccess,
    getPoolMaintenanceGeneralViewDisplayOptionsFailure,
    GetDomesticorForeignFlagSuccess,
    GetDomesticorForeignFlagRequest,
    GetDomesticorForeignFlagFailure,
    getDownloadFileFormatSuccess,
    addToPackageFailure,
    addToPackageSuccess,
    mergeAgreementSuccess,
    mergeAgreementRequest,
    mergeAgreementFailure,
    getAllPackagesDetailsSuccess,
    getAllPackagesDetailsFailure,
    ClearPackageSuccess,
    ClearPackageFailure,
    generatePackageSuccess,
    generatePackageFailure,
    closePackageFailure,
    addPackageNoteFailure,
    getWorkMatchConnectorRequest,
    getWorkMatchConnectorSuccess,
    getWorkMatchConnectorFailure,
    getPoolByCodeRequest,
    getPoolByCodeSuccess,
    onUpdatePoolCodeToSearch,
    removePaneTwo,
} from "../reducers/RepertoireReducer";
import { RepertoireService } from "../services/RepertoireService";
import { Action, Dispatch } from "redux";
import IAppState from "../types/IAppState";
import IRepertoireComponentData from "../types/IRepertoireComponentData";
import { IWorksSearchQuery } from "../../repertoire/types/IWorksSearchQuery";
import { IWorksSearchResult } from "../../repertoire/types/IWorksSearchResult";
import { IArtist } from "../../repertoire/types/IArtist";
import { IContributorSearchQuery } from "../../repertoire/types/IContributorSearchQuery";
import { IContributorSearchResult } from "../../repertoire/types/IContributorSearchResult";
import { IWork } from "../../repertoire/types/IWork";
import { ITabReduxItem } from "../types/ITabReduxItem";
import { ThunkAction } from "redux-thunk";
import { LookupService } from "../../lookup/services/LookupService";
import { ComponentFields } from "../../core/services/ComponentService";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { getDataAction } from "../../repertoire/components/toolBar/ToolbarHelper";
import {
    CONFIGURATION_PARAMETER_AGREEMENT_SHARE_DISPLAY_KEY,
    CONFIGURATION_PARAMETER_CUSTOMER_SOCIETY_CODE,
    CONFIGURATION_PARAMETER_SHARE_DECIMALS_TO_DISPLAY, CONFIGURATION_PRODUCT_MAINTENANCE_SUBMISSION_KEY, DISPLAY_CONTRIBUTORS_COLUMNS_KEY, CONFIGURATION_PARAMETER_FIELDS_EXCLUDED_FROM_COPY_WORK, INGESTION_GROUP, PRODUCT_MAINTENANCE_GROUP, WORK_MAINTENANCE_GROUP, INTERESTED_PARTY_MAINTENANCE_GROUP, CONFIGURATION_PARAMETER_GET_UI_CONFIGURATION, CONFIGURATION_PARAMETER_WRITER_CONTRIBUTOR_TYPES, CONFIGURATION_PARAMETER_PUBLISHER_CONTRIBUTOR_TYPES, WORK_FLAG_KEY,READONLY_FLAG_DOMESTIC_OR_FOREIGN,
    READONLY_WORK_FLAG_KEY, READONLY_FLAGS_FIELD_KEY, CONFIGURATION_PARAMETER_GENRE_CATEGORY_DEFAULTS_TYPES, CONFIGURATION_PARAMETER_GENRE_CATEGORY_FROM_WORK_TYPE, USAGE_MAINTENANCE_GROUP, CONFIGURATION_PARAMETER_USAGE_MATCHING_DEFAULTS_WORKS, CONFIGURATION_PARAMETER_USAGE_MATCHING_DEFAULTS_PRODUCTS, CONFIGURATION_PARAMETER_SHOW_COMMENT_SUBJECT_AS_DROPDOWN_KEY, CONFIGURATION_PARAMETER_WORK_GENERAL_VIEW_DISPLAY_OPTIONS, CONFIGURATION_PARAMETER_EXCLUDE_SOURCE_TYPE_PICK_SHOW_FIELDS_KEY, CONFIGURATION_PARAMETER_ENABLE_CORE_WORK_NUMBERS, CONFIGURATION_PARAMETER_USAGE_GRID_SEARCH_RESULT_DISPLAY_OPTIONS, CONFIGURATION_PARAMETER_FILTER_GENRE_CATEGORY, CONFIGURATION_PARAMETER_DISTRIBUTION_SETTINGS_VIEW_DISPLAY_OPTIONS, DISTRIBUTION_MAINTENANCE_GROUP, CONFIGURATION_PARAMETER_IP_GRID_SEARCH_RESULT_DISPLAY_OPTIONS, SHARE_TOLERANCE_FIELD_KEY,
    CONFIGURATION_PARAMETER_PRODUCT_GENERAL_VIEW_DISPLAY_OPTIONS,
    CONFIGURATION_PARAMETER_PD_ARRANGEMENT_MUSIC_LYRIC_OPTIONS,
    IPI_CONFIGURATION_GROUP,
    CONFIGURATION_PARAMETER_ENABLE_COMMON_CUESHEETS,
    CONFIGURATION_PARAMETER_CUESHEETS_DATASOURCES,
    CONFIGURATION_PARAMETER_POOL_GENERAL_VIEW_DISPLAY_OPTIONS,
    MY_PACKAGE_MAINTENANCE_KEY,
    MY_PACKAGE_MAINTENANCE_GROUP
} from "../../repertoire/ConfigurationConsts";
import {
    AGREEMENTS_PAGE, AGREEMENT_MAINTENANCE_PAGE,
    AGREEMENT_NOT_FOUND_VIEW,
    EMPTY_STRING_VALUE, GENERIC_ERROR, INTERESTED_PARTY_NOT_FOUND_VIEW, IPS_PAGE, IP_MAINTENANCE_PAGE, PRODUCTS_PAGE,
    PRODUCT_MAINTENANCE_PAGE,
    PRODUCT_NOT_FOUND_VIEW, SEARCH_VIEW_IPS, SEARCH_VIEW_PRODUCTS, SEARCH_VIEW_WORKS, WORKFLOW_PAGE, CLAIMS_PAGE,
    WORKFLOW_PAGE_TOOLBAR, WORKS_PAGE, WORK_MAINTENANCE_PAGE,
    WORK_NOT_FOUND_VIEW, FINDUSAGE_PAGE, ARTIST_MAINTENANCE_PAGE, ARTIST_NOT_FOUND_VIEW, ARTISTS_PAGE, USAGE_POOLS_PAGE, CONFIGURATION_PARAMETER_USAGE_SORT_NUMBER_MATCHING_WORKS_KEY, CONFIGURATION_PARAMETER_USAGE_MAINTENANCE_GROUP, USAGETOWORK, USAGETOPRODUCT, USAGE_POOL_MAINTENANCE_PAGE_KEY, USAGE_POOL_MAINTENANCE_PAGE, DISTRIBUTIONS_PAGE, DISTRIBUTION_MAINTENANCE_PAGE, SEARCH_VIEW_AGREEMENTS, REPERTOIRE, SEARCH_VIEW_DISTRIBUTIONS, SEARCH_VIEW_POOLS, DISTRIBUTIONS_PAGE_VIEW, REPRESENTATION_PAGE, ADJUSTMENTS_PAGE, ADJUSTMENT_MAINTENANCE_PAGE, IP_TRANSFER_VIEW,
    CLAIM_MAINTENANCE_PAGE,
    USAGE_WORKFLOW,
    WORK_WORKFLOW,
    PAYMENTRUN_PAGE,
    PAYMENTRUN_MAINTENANCE_PAGE,
    SEARCH_VIEW_PAYMENTRUN,
    SEARCH_VIEW_ADJUSTMENTS,
    LICENSE_MAINTENANCE_PAGE,
    LICENSE_PAGE,
    PRODUCT_WORKFLOW,
    AGREEMENTS_PAGE_VIEW,
    COMMENT_CATEGORY_FLAG_CONFIGURATION_KEY,
    CUSTOMER_CONFIGURATION_GROUP,
    CLAIM_TYPE_SHARES_IN_SUSPENSE,
    AGREEMENT_ATTACHMENT_TYPE_REQUIRED_CONFIGURATION_KEY,
    AGREEMENT_MAINTENANCE_CONFIGURATION_GROUP,
    QA_JOB_TYPE,
    CUE_SHEET_KEY,
    ORIGIN_OF_REVISION,
    ALK_JOB_TYPE,
    INTRAY,
    MY_PACKAGES_PAGE,
    TEXT_INPUT,
    GENERIC_ERROR_ADDTOPACKAGE
} from "../../repertoire/Consts";
import { AgreementDetailsMapper } from "../../repertoire/services/AgreementDetailsMapper";
import { AgreementValidator } from "../../repertoire/services/AgreementValidator";
import { ProductDetailsMapper } from "../../repertoire/services/ProductDetailsMapper";
import { SearchRequests } from "../../repertoire/services/SearchRequests";
import { WorkDetailsMapper } from "../../repertoire/services/WorkDetailsMapper";
import { WorkValidator } from "../../repertoire/services/WorkValidator";
import { DataSource } from "../../repertoire/types/DataSource";
import { IAccountSaveResult } from "../../repertoire/types/IAccountSaveResult";
import { IAgreement } from "../../repertoire/types/IAgreement";
import { IAgreementSaveResult } from "../../repertoire/types/IAgreementSaveResult";
import { IAgreementSearchQuery } from "../../repertoire/types/IAgreementSearchQuery";
import { IAgreementSearchResult } from "../../repertoire/types/IAgreementSearchResult";
import { IAgreementSearchState, IAgreementSearchStateKeys } from "../../repertoire/types/IAgreementSearchState";
import { IAgreementShareDisplayConfiguration } from "../../repertoire/types/IAgreementShareDisplayConfiguration";
import { IArtistSearchQuery } from "../../repertoire/types/IArtistSearchQuery";
import { IAssignWorkflowModel } from "../../repertoire/types/IAssignWorkflowModel";
import { IContributorItem } from "../../repertoire/types/IContributorItem";
import { IContributorSiteConfiguration } from "../../repertoire/types/IContributorSiteConfiguration";
import { IDataActionToolbar } from "../../repertoire/types/IDataActionToolbar";
import { IInterestedParty } from "../../repertoire/types/IInterestedParty";
import { IIPAgreement } from "../../repertoire/types/IIPAgreement";
import { IIPSaveResult } from "../../repertoire/types/IIPSaveResult";
import { IIPsSearchQuery } from "../../repertoire/types/IIPsSearchQuery";
import { IIPsSearchResult } from "../../repertoire/types/IIPsSearchResult";
import { IP } from "../../repertoire/types/IP";
import { IProductCore } from "../../repertoire/types/IProductCore";
import { IProductSaveResult } from "../../repertoire/types/IProductSaveResult";
import { IProductSearchQuery } from "../../repertoire/types/IProductSearchQuery";
import { IProductSearchResult } from "../../repertoire/types/IProductSearchResult";
import { IProductVersionHistorySearchResult } from "../../repertoire/types/IProductVersionHistorySearchResult";
import { IRepertoireField } from "../../repertoire/types/IRepertoireField";
import { IRepertoirePathInfo } from "../../repertoire/types/IRepertoirePathInfo";
import { ISocIP } from "../../repertoire/types/ISocIP";
import { ISocIPVersionHistorySearchResult } from "../../repertoire/types/ISocIPVersionHistorySearchResult";
import { IValidationMessage } from "../../repertoire/types/IValidationMessage";
import { IVersionHistorySearchResult } from "../../repertoire/types/IVersionHistorySearchResult";
import { UserPreferencesService } from "../../repertoire/services/UserPreferencesService";
import {
    copyExistingWorkRequest, copyExistingWorkSuccess,
    searchUsageGroupFailure,
    searchUsageGroupRequest,
    searchUsageGroupSuccess,
    getUsageRequest,
    getUsageSuccess,
    getUsageFailure,
    searchMatchWorksFailure,
    searchMatchWorksRequest,
    searchMatchWorksSuccess,
    searchClaimsSuccess,
    searchClaimsFailure,
    searchClaimsRequest
} from "../reducers/RepertoireReducer";
import { IAddProductAttachmentModel } from "../types/IAddProductAttachmentModel";
import { hideModal, showDistributionVersionHistoryModal, showIpTransferView, showLoading, showMessage, showModal, showScheduledJobModalView, showVersionHistoryModal } from "../reducers/ModalReducer";
import { FormatFields } from "../types/FormatFields";
import IRepertoireComponentDataItem from "../types/IRepertoireComponentDataItem";
import { SaveOption } from "../../repertoire/types/SaveOption";
import { IWorkSaveResult } from "../../repertoire/types/IWorkSaveResult";
import { IWorkState } from "../types/IWorkState";
import { IAgreementState } from "../types/IAgreementState";
import { IProductState } from "../types/IProductState";
import { ManualMergeOptions } from "../../repertoire/types/ManualMergeOptions";
import { IAttachedFile } from "../types/IAttachedFile";
import { IAddWorkAttachmentModel } from "../types/IAddAttchmentWorkModel";
import { WorkMaintenancePage } from "../../repertoire/works/components/workMaintenancePage/WorkMaintenancePage";
import { IWorkflowSearchQuery } from "../../repertoire/types/IWorkflowSearchQuery";
import { IWorkflowSearchResult } from "../../repertoire/types/IWorkflowSearchResult";
import { IUpdateFieldSearchScreen } from "../../repertoire/types/IUpdateFieldSearchScreen";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { IProductAVRequest } from "../../repertoire/types/IProductAVRequest";
import { IProductAVWorkSubmission } from "../../repertoire/types/IProductAVWorkSubmission";
import { IArtistState } from "../types/IArtistState";
import { IResultsPerPage } from "../types/IResultsPerPage";
import { IActiveAccordion } from "../types/IActiveAccordion";
import { USAGE_MAINTENANCE_PAGE } from "../../repertoire/Consts";
import { IUsagesSearchQuery } from "../../repertoire/types/usageTypes/IUsagesSearchQuery";
import { UsageSearchRequests } from "../../repertoire/services/usageServices/UsageSearchRequests";
import { UsageDetailsMapper } from "../../repertoire/services/usageServices/UsageDetailsMapper";
import { UsageService } from "../../repertoire/services/usageServices/UsageServices";
import { IMatchWorksSearchResult } from "../../repertoire/types/usageTypes/IMatchWorksSearchResult";
import { IMatchWorksSearchQuery } from "../../repertoire/types/usageTypes/IMatchWorksSearchQuery";
import { IUsageGroupState } from "../types/IUsageGroupState";
import { SourceService } from '../../settings/services/SourceService';
import { Dictionary } from "../../core/types/Dictionary";
import { ISourceMatchType } from "../../settings/types/ISourceMatchType";
import { ArtistDetailsMapper } from "../../repertoire/services/ArtistDetailsMapper";
import { IArtistSaveResult } from "../../repertoire/types/IArtistSaveResult";
import { IArtistCore } from "../../repertoire/types/IArtistCore";
import { IUsagePoolSearchQuery } from "../../repertoire/types/usageTypes/IUsagePoolSearchQuery";
import { IMatchProductsSearchQuery } from "../../repertoire/types/usageTypes/IMatchProductsSearchQuery";
import { UsagePoolDetailsMapper } from "../../repertoire/services/UsagePoolDetailsMapper";
import { IUsagePoolCore } from "../../repertoire/types/usageTypes/IUsagePoolCore";
import { IUsagePoolSaveResult } from "../../repertoire/types/usageTypes/IUsagePoolSaveResult";
import { JobService } from "../services/JobService";
import { getLookupValues } from "../../lookup/services/LookupHelpers";
import { AGREEMENT_SOURCE_LOOKUP } from "../../lookup/Consts";
import { IUsagePoolState } from "../types/IUsagePoolState";
import { IUsagePoolSource } from "../../repertoire/types/usageTypes/IUsagePoolSource";
import { ITreeData } from "../../repertoire/types/ITreeData";
import { IDistributionSearchQuery } from "../../repertoire/types/usageTypes/IDistributionSearchQuery";
import { IDistribution } from "../../repertoire/types/usageTypes/IDistribution";
import { IDistributionType } from "../../repertoire/types/usageTypes/IDistibutionType";
import { DistributionDetailsMapper } from "../../repertoire/services/DistributionDetailsMapper";
import { IDistributionState } from "../types/IDistributionState";
import { IDistributionSaveResult } from "../../repertoire/types/usageTypes/IDistributionSaveResult";
import { IIPMaintenancePageUIConfiguration } from "../types/IIPMaintenancePageUIConfiguration";
import { IAgreementVersionHistorySearchResult } from "../../repertoire/types/IAgreementVersionHistorySearchResult";
import { IMatchingWorksRowData } from "../../repertoire/types/usageTypes/IMatchingWorksRowData";
import { remove, startCase } from "lodash";
import { IMatchingProductsRowData } from "../../repertoire/types/usageTypes/IMatchingProductsRowData";
import { IUsagePoolVersionHistorySearchResult } from "../../repertoire/types/usageTypes/IUsagePoolVersionHistorySearchResult";
import { DistributionValidator } from "../../repertoire/services/DistributionValidator";
import { getDistributionsThunk as dataIngestionGetDistributions } from "../../redux/thunks/DataIngestionThunks";
import { IRepresentation } from "../../repertoire/types/usageTypes/IRepresentation";
import { IDeleteRepresentations } from "../../repertoire/types/usageTypes/IDeleteRepresentations";
import { ClaimDetailsMapper } from "../../repertoire/services/ClaimDetailsMapper";
import { IClaimStateKeys, IClaimState } from "../types/IClaimState";
import { IClaimSaveResult } from "../../repertoire/types/IClaimSaveResult";
import { IWorkflow } from "../../repertoire/types/IWorkflow";
import { IReadonlyFlagsField } from "../../repertoire/types/IReadonlyFlagsField";
import { IAdjustmentSearchQuery } from "../../repertoire/types/usageTypes/IAdjustmentSearchQuery";
import { IAdjustment } from "../../repertoire/types/usageTypes/IAdjustment";
import { IAdjustmentSaveResult } from "../../repertoire/types/usageTypes/IAdjustmentSaveResult";
import { IAdjustmentState } from "../types/IAdjustmentState";
import { AdjustmentDetailsMapper } from "../../repertoire/services/AdjustmentDetailsMapper";
import { ILastPercentageSearchQuery } from "../../repertoire/types/usageTypes/ILastPercentageSearchQuery";
import { IDistributionSubjects } from "../../repertoire/types/usageTypes/IDistributionSubjects";
import { DistributionService } from "../../dataingestion/services/DistributionService";
import { IClaimSearchResult } from "../../repertoire/types/IClaimSearchResult";
import { IClaimSearchQuery } from "../../repertoire/types/IClaimSearchQuery";
import { IClaim } from "../../repertoire/types/IClaim";
import { IGenreCategoriesConfiguration } from "../../repertoire/types/IGenreCategoriesConfiguration";
import { DatabricksApiService } from "../services/DatabricksApiService";
import { IipTransfer } from "../../repertoire/types/usageTypes/IipTransfer";
import { IGenereCategoryDefaultsConfiguration } from "../../repertoire/types/IGenereCategoryDefaultsConfiguration";
import { IWorkflowParams } from "../../repertoire/types/IWorkFlowParams";
import { IPaymentRunSearchQuery } from "../../repertoire/types/IPaymentRunSearchQuery";
import { IPaymentRunSearchResult } from "../../repertoire/types/IPaymentRunSearchResult";
import { IPaymentRun } from "../../repertoire/types/usageTypes/IPaymentRun";
import { PaymentRunDetailsMapper } from "../../repertoire/services/PaymentRunDetailsMapper";
import { IPaymentRunState } from "../types/IPaymentRunState";
import { PaymentRunValidator } from "../../repertoire/services/PaymentRunValidator";
import { IPaymentRunSaveResult } from "../../repertoire/types/usageTypes/IPaymentRunSaveResult";
import { IPaymentRunVersionHistorySearchResult } from "../../repertoire/types/usageTypes/IPaymentRunVersionHistorySearchResult";
import { IAdjustmentVersionHistorySearchResult } from "../../repertoire/types/usageTypes/IAdjustmentVersionHistorySearchResult";
import { IShoppingCart } from "../../repertoire/types/IShoppingCart";
import { IShoppingCartSearchQuery } from "../../repertoire/types/IShoppingCartSearchQuery";
import { LicenseDetailsMapper } from "../../repertoire/services/LicenseDetailsMapper";
import { IProductWork } from "../../repertoire/types/IProductWork";
import { ILicenseUserSearchQuery } from "../../repertoire/types/ILicenseUserSearchQuery";
import { ILicenseUserSearchResult } from "../../repertoire/types/ILicenseUserSearchResult";
import { IRemoveBlobModel } from "../types/IRemoveBlobModel";
import { IShoppingCartState } from "../types/IShoppingCartState";
import { IUsageDetailsRowData } from "../../repertoire/types/usageTypes/IUsageDetailsRowData";
import { ILicenseInput } from "../../repertoire/types/ILicenseInput";
import { ILicenseInputItem } from "../types/ILicenseInputItem";
import { ILicenseSaveResult } from "../../repertoire/types/ILicenseSaveResult";
import { IWorkContributor } from "../../repertoire/types/IWorkContributor";
import { IAddUsageAttachmentModel } from "../types/IAddUsageAttachmentModel";
import { Switch } from "antd";
import { ILicenseRequestItem } from "../types/ILicenseRequestItem";
import { ILicenseRequestWorkItem } from "../types/ILicenseRequestWorkItem";
import { LicenseValidator } from "../../repertoire/services/LicenseValidator";
import { IName } from "../../repertoire/types/IName";
import IMembersPortalComponentDataItem from "../types/IMembersPortalComponentDataItem";
import { IInterestedPartySaveResult } from "../../repertoire/types/IInterestedPartySaveResult";
import { IntertestedPartyValidator } from "../../repertoire/services/InterestedPartyValidator";
import { ProductValidator } from "../../repertoire/services/ProductValidator";
import { IDistributionSubType } from "../../repertoire/types/usageTypes/IDistributionSubType";
import { ILicenseSubmitResult } from "../../repertoire/types/ILicenseSubmitResult";
import { IUsageGroup } from "../../repertoire/types/usageTypes/IUsageGroup";
import { IUsageGroupsSearchResult, IUsageSearchResultRowType } from "../../repertoire/types/usageTypes/IUsageGroupsSearchResult";
import { IUsageMatch } from "../../repertoire/types/usageTypes/IUsageMatch";
import { IUsageGroupSaveResult } from "../../repertoire/types/usageTypes/IUsageGroupSaveResult";
import { IUsagePool } from "../../repertoire/types/usageTypes/IUsagePool";
import { UsagePoolValidator } from "../../repertoire/services/UsagePoolValidator";
import { IDictionary } from "@fluentui/utilities";
import { IWorkSubjectSearchResult } from "../../repertoire/types/IWorkSubjectSearchResult";
import { ISubjectsSearchQuery } from "../../repertoire/types/ISubjectsSearchQuery";
import { IScheduledJobState } from "../types/IScheduledJobState";
import IShortCutKeys from "../types/IShortCutKeys";
import { IShareToleranceValueConfiguration } from "../../repertoire/types/IShareToleranceValueConfiguration";
import { isNullOrUndefined } from "../../util/tools";
import { IRepresentationSearchState } from "../../repertoire/types/IRepresentationSearchState";
import { InterestedPartyDetailsMapper } from "../../repertoire/services/InterestedPartyDetailsMapper";
import { InterestedPartyOnSaveValidator } from "../../repertoire/services/InterestedPartyOnSaveValidator";
import { IIPRepresentation } from "../../repertoire/types/IIPRepresentation";
import { IAllocationSearchModel } from "../types/IAllocationSearchModel";
import { IMyPackageMaintenanceState } from "../types/IMyPackageMaintenanceState";
import { IMyPackagesDownloadFileFormat } from "../../repertoire/types/IMyPackagesDownloadFileFormat";
import { IAddtoPackageResult } from "../../repertoire/types/IAddtoPackageResult";
import { IMyPackagesDetails } from "../../repertoire/types/IMyPackagesDetails";
import { IMyPackagesResult } from "../../repertoire/types/IMyPackagesResult";
import { IPopUpInfoProps } from "../../repertoire/components/modalViews/popUpInfo/PopUpInfo";
import { SECOND_PANE_INDEX } from "../../repertoire/RepertoirePageContainer";


export const getRepertoireComponentDataThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>
> => {
    return (dispatch: Dispatch) => {
        dispatch(getRepertoireComponentDataRequest());
        return ComponentFields.getRepertoireComponentData()
            .then((res: IRepertoireComponentData) => {

                dispatch(getRepertoireComponentDataSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getRepertoireComponentDataFailure(error));
            });
    };
};

export const loadRepertoireFromPathThunk = (pathInfo: IRepertoirePathInfo, location: Location): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        if (!pathInfo || !pathInfo.submodule)
           return null;
        const { submodule } = pathInfo;

        
        if (location !== undefined && location.pathname === "/repertoire/ips" && location.search) {
            ComponentFields.getRepertoireComponentData()
                .then((res: IRepertoireComponentData) => {
                    dispatch(getRepertoireComponentDataSuccess(res));
                    let searchParams = new URLSearchParams(location.search);
                    let ipBaseNumber = searchParams.get('ipBaseNumber');
                    dispatch<any>(getIPDetailsThunk(ipBaseNumber, null, null));
                })
                .catch((error: any) => {
                    dispatch(getRepertoireComponentDataFailure(error));
                });
        } else {
            switch (submodule) {
                case 'works':
                    dispatch(addTab(WORKS_PAGE));
                    break;
                case 'agreements':
                    dispatch(addTab(AGREEMENTS_PAGE));
                    break;
                case 'ips':
                    dispatch(addTab(IPS_PAGE));
                    break;
                case 'products':
                    dispatch(addTab(PRODUCTS_PAGE));
                    break;
                case 'workflows':
                    const dataActions = getDataAction(EMPTY_STRING_VALUE, WORKFLOW_PAGE_TOOLBAR);
                    WORKFLOW_PAGE.dataActionToolbar = dataActions;
                    dispatch(addTab(WORKFLOW_PAGE));
                    break;
                case 'usage':
                    dispatch(addTab(FINDUSAGE_PAGE));
                    break;
                case 'artists':
                    dispatch(addTab(ARTISTS_PAGE));
                    break;
                case 'pools':
                    dispatch(addTab(USAGE_POOLS_PAGE));
                    break;
                case 'distributions':
                    dispatch(addTab(DISTRIBUTIONS_PAGE))
                    break;
                case 'Representation':

                    dispatch(addTab(REPRESENTATION_PAGE))
                    break;
                case 'claims':
                    dispatch(addTab(CLAIMS_PAGE))
                    break;
                case 'adjustments':
                    dispatch(addTab(ADJUSTMENTS_PAGE))
                    break;
                case 'paymentRuns':
                    dispatch(addTab(PAYMENTRUN_PAGE))
                    break;
                case 'licenses':
                    dispatch(addTab(LICENSE_PAGE))
                    break;
                case 'mypackages':
                    dispatch(addTab(MY_PACKAGES_PAGE))
                    break;
            }
        }
    }
};

export const getShortCutKeysThunk = (
): ThunkAction<void, IAppState, null, Action<IShortCutKeys>> => {
    return (dispatch: Dispatch) => {
        dispatch(getShortCutKeysRequest())
        return ComponentFields.getShortcutKeys()
            .then((res: IShortCutKeys[]) => {
                dispatch(getShortCutKeysSuccess(res))
            })
    }
}

export const searchWorksThunk = (
    searchBody: IWorksSearchQuery,
    modalOpen: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchWorksRequest());
        if (!modalOpen) {
            dispatch(showLoading());
        }
        return SearchRequests.getWorks(searchBody)
            .then((res: IWorksSearchResult[]) => {
                dispatch(searchWorksSuccess(res, searchBody));
                if (!modalOpen)
                    dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(searchWorksFailure(err));
            });
    };
};

export const searchSubjectsThunk = (
    searchBody: ISubjectsSearchQuery,
    modalOpen: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchWorksRequest());
        if (!modalOpen) {
            dispatch(showLoading());
        }
        return SearchRequests.getSubjects(searchBody)
            .then((res: IWorkSubjectSearchResult[]) => {
                dispatch(searchSubjectSuccess(res));
                if (!modalOpen)
                    dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(searchWorksFailure(err));
            });
    };
};

export const searchMatchWorksThunk = (
    searchBody: IMatchWorksSearchQuery,
    modalOpen: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchMatchWorksRequest());
        if (!modalOpen) {
            dispatch(showLoading());
        }
        return UsageSearchRequests.getWorks(searchBody)
            .then((res: IMatchWorksSearchResult[]) => {
                dispatch(searchMatchWorksSuccess(res, searchBody));
                if (!modalOpen)
                    dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(searchMatchWorksFailure(err));
            });
    };
};

export const searchMatchProductsThunk = (
    searchBody: IMatchProductsSearchQuery,
    modalOpen: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchMatchProductsRequest());
        if (!modalOpen) {
            dispatch(showLoading());
        }
        return SearchRequests.getProducts(searchBody)
            .then((res: IProductSearchResult[]) => {
                dispatch(searchMatchProductsSuccess(res, searchBody));
                if (!modalOpen)
                    dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(searchMatchProductsFailure(err));
            });
    };
};

export const searchWorkVersionHistoryThunk = (workID: number, lookups: ILookupDictionary, actionList: IDataActionToolbar[], formats: FormatFields[], otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataSource: DataSource, paneIndex: number): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading());
        dispatch(searchWorkVersionHistoryRequest());
        return SearchRequests.getWorkVersionHistory(workID, dataSource)
            .then((res: IVersionHistorySearchResult[]) => {
                dispatch(hideModal());
                dispatch(searchWorkVersionHistorySuccess(res));
                dispatch(showVersionHistoryModal("Work Version History", SEARCH_VIEW_WORKS, {
                    openCallback: (entityId: number, versionNumber: number, mergedWork: boolean) => getWorkHistoryVersion(dispatch, entityId, versionNumber, mergedWork, lookups, actionList, formats, otherIndicatorsWorkFlagTypes, readonlyIndicatorsWorkFlagTypes, dataSource, paneIndex),
                    versionHistorySearchResult: res
                }))
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(searchWorkVersionHistoryFailure());
            });
    };
}

function getWorkHistoryVersion(
    dispatch: Dispatch, entityId: number, versionNumber: number, mergedWork: boolean, lookups: ILookupDictionary, actionList: IDataActionToolbar[], formats: FormatFields[], otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataSource: DataSource, paneIndex: number): void {
    SearchRequests.getWorkHistoryVersion(entityId, versionNumber, mergedWork, dataSource)
        .then((res: IWork) => {
            let newWorkTab: ITabReduxItem = {
                title: WorkDetailsMapper.getOriginalTitleFromWorkNames(res.workNames),
                component: WORK_MAINTENANCE_PAGE.component,
                workMaintenanceState: {
                    loaded: false,
                    workBatchOperationInProgress: false,
                    work: WorkDetailsMapper.mapWorkToWorkState(res, lookups, otherIndicatorsWorkFlagTypes, readonlyIndicatorsWorkFlagTypes),
                    contributorInheritanceChanged: false,
                    isNew: false
                },
                changesMade: false,
                versionNumber,
                isReadonly: true,
                dataActionToolbar: actionList,
                formatFields: formats,
                otherIndicatorWorkFlagTypes: otherIndicatorsWorkFlagTypes,
                readonlyIndicatorWorkFlagTypes: readonlyIndicatorsWorkFlagTypes
            };
            dispatch(addTab(newWorkTab, undefined, paneIndex));
            dispatch(hideModal());
        });
}

export const searchIPsThunk = (
    searchBody: IIPsSearchQuery,
    keepModalOpenAfterSearch?: boolean,
    isSameSearch?: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        if (!keepModalOpenAfterSearch)
            dispatch(showLoading());
        dispatch(searchIPsRequest());
        return SearchRequests.getIPs(searchBody)
            .then((res: IIPsSearchResult[]) => {
                dispatch(searchIPsSuccess(res, isSameSearch));
                if (!keepModalOpenAfterSearch)
                    dispatch(hideModal());
            })
            .catch(err => {
                dispatch(searchIPsFailure(err));
                if (!keepModalOpenAfterSearch)
                    dispatch(hideModal());
            });
    };
}

export const searchArtistsThunk = (
    artist: IArtistSearchQuery,
    dataSource: string,
    keepModalOpenAfterSearch?: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchArtistsRequest());
        if (!keepModalOpenAfterSearch)
            dispatch(showLoading());
        return SearchRequests.getArtists(artist, dataSource)
            .then((res: IArtist[]) => {
                dispatch(searchArtistsSuccess(res));
                if (!keepModalOpenAfterSearch)
                    dispatch(hideModal());
            })
            .catch(err => {
                dispatch(searchArtistsFailure(err));
                if (!keepModalOpenAfterSearch)
                    dispatch(hideModal());
            });
    };
};

export const searchContributorsThunk = (
    searchBody: IContributorSearchQuery
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchContributorsRequest());
        return SearchRequests.getContributors(searchBody)
            .then((res: IContributorSearchResult[]) => {
                dispatch(searchContributorsSuccess(res));
            })
            .catch(err => {
                dispatch(searchContributorsFailure(err));
            });
    };
};

export const searchAgreementsThunk = (
    searchBody: IAgreementSearchQuery,
    lookupValues: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading());
        dispatch(searchAgreementsRequest());
        return SearchRequests.getAgreements(searchBody, lookupValues)
            .then((res: IAgreementSearchResult[]) => {
                dispatch(searchAgreementsSuccess(res));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(searchAgreementsFailure(err));
                dispatch(hideModal());
            });
    };
}

export const searchProductsThunk = (
    searchBody: IProductSearchQuery,
    modalOpen: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchProductsRequest());
        if (!modalOpen) {
            dispatch(showLoading());
        }
        return SearchRequests.getProducts(searchBody)
            .then((res: IProductSearchResult[]) => {
                dispatch(searchProductsSuccess(res));
                if (!modalOpen)
                    dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(searchProductsFailure(err));
            });
    };
}

export const getAgreementDetailsThunk = (
    dataSource: string,
    agreementID: number,
    lookups: ILookupDictionary,
    dataActions: IDataActionToolbar[],
    openEntityForWorflowSession?: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getAgreementDetailsRequest());
        dispatch(showLoading());
        return SearchRequests.getAgreement(dataSource, agreementID)
            .then((res: IAgreement) => {
                dispatch(getAgreementDetailsSuccess(res));
                if (res.source == null && res.sourceID) {
                    res.source = getLookupValues(AGREEMENT_SOURCE_LOOKUP, lookups).find(a => a.typeID === res.sourceID).code;
                }
                let newAgreementTab: ITabReduxItem = {
                    title: AgreementDetailsMapper.getAgreementTitle(res),
                    component: AGREEMENT_MAINTENANCE_PAGE.component,
                    agreementMaintenanceState: {
                        loaded: false,
                        agreement: AgreementDetailsMapper.mapAgreementToState(res),
                        agreementBatchOperationInProgress: false
                    },
                    changesMade: false,
                    dataActionToolbar: dataActions,
                    isEntityForWorkflowSession: openEntityForWorflowSession
                };
                dispatch(addTab(newAgreementTab));
                if(res.agreementProductWorks?.length > 0){
                    res.agreementProductWorks.forEach(pw => {
                        dispatch<any>( getProductDetailsThunk(REPERTOIRE, pw.productId, null,
                            [], false, true) )
                    })
                }

                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(getAgreementDetailsFailure(err, agreementID));
                if (err === 404) {
                    dispatch(showModal(AGREEMENT_NOT_FOUND_VIEW, null, agreementID, true, "Agreement not Found"));
                }
            });
    }
}

export const getIPDetailsThunk = (
    ipBaseNumber?: string,
    accountNumber?: string,
    ipMaintenanceGeneralViewData?: IRepertoireComponentDataItem,
    lookups?: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getIPDetailsRequest());
        if (ipMaintenanceGeneralViewData) {
            dispatch(showLoading());
        }
        return SearchRequests.getIP(ipBaseNumber, accountNumber)
            .then((res: IInterestedParty) => {
                dispatch(getIPDetailsSuccess(res));
                let ipResult: IInterestedParty = {
                    ...res,
                    socIP: {
                        ...res.socIP,
                        ipRepresentations: InterestedPartyDetailsMapper.mapInterestedPartStatetoState(res.socIP, lookups)
                    }
                };
                let newIPTab: ITabReduxItem = {
                    title: "IP: " + res.ipBaseNumber,
                    component: IP_MAINTENANCE_PAGE.component,
                    interestedPartyMaintenanceState: {
                        loaded: false,
                        interestedParty: ipResult
                    },
                    changesMade: false
                };
                dispatch(addTab(newIPTab));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(getIPDetailsFailure(err, ipBaseNumber, accountNumber));
                if (err === 404) {
                    dispatch(showModal(INTERESTED_PARTY_NOT_FOUND_VIEW, null, ipBaseNumber, true, "Interested Party not Found"));
                }
            });
    }
}


export const getArtistDetailsThunk = (
    performerID: number,
    artistMaintenanceGeneralViewData?: IRepertoireComponentDataItem
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getArtistDetailsRequest());
        dispatch(showLoading());
        return SearchRequests.getArtist(performerID)
            .then((res: IArtistCore) => {
                dispatch(getArtistDetailsSuccess(res));
                let newArtistTab: ITabReduxItem = {
                    title: "Artist: " + res.fullName,
                    component: ARTIST_MAINTENANCE_PAGE.component,
                    artistMaintenanceState: {
                        loaded: false,
                        artist: ArtistDetailsMapper.mapArtistToState(res)
                    },
                    changesMade: false,
                };
                dispatch(addTab(newArtistTab));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(getArtistDetailsFailure(err, performerID));
                if (err === 404) {
                    dispatch(showModal(ARTIST_NOT_FOUND_VIEW, null, performerID, true, "Artist not Found"));
                }
            });
    }
}

export const saveArtistThunk = (
    updatedArtist: IArtistState
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        {
            dispatch(saveArtistRequest());
            dispatch(showLoading());
            RepertoireService.saveArtist(ArtistDetailsMapper.mapStateToArtist(updatedArtist))
                .then(res => {
                    if (res.status !== 200 || !res.data.success) {
                        const errorMessages: string[] = res.data.messages || [GENERIC_ERROR];
                        const saveResult: IArtistSaveResult = {
                            messages: errorMessages,
                            performer: res.data.performer,
                            saved: false,
                            success: false,
                            validationSuccess: false
                        }
                        dispatch(saveArtistFailure(saveResult));
                    }
                    else {
                        dispatch(saveArtistSuccess(res.data));
                    }
                })
                .catch((error: any) => {
                    const errorMessages: string[] = [error];
                    const saveResult: IArtistSaveResult = {
                        messages: errorMessages,
                        performer: ArtistDetailsMapper.mapStateToArtist(updatedArtist),
                        saved: false,
                        success: false,
                        validationSuccess: false
                    }
                    dispatch(saveArtistFailure(saveResult));
                })
                .finally(() => {
                    dispatch(hideModal());
                })
        };
    };
};

export const deleteArtistThunk = (
    performerID: number,
    activeTab: number
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(deleteArtistRequest(performerID));
        RepertoireService.deleteArtist(performerID)
            .then(res => {
                dispatch(deleteArtistRequestSuccess(performerID));
                dispatch(removeTab(activeTab));
            })
            .catch((error: any) => {
                dispatch(deleteArtistRequestFailure(error));
            });
    };
};

export const undoArtistChangesThunk = (
    performerID: number
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(undoWorkChangesRequest());
        dispatch(showLoading());
        return SearchRequests.getArtist(performerID)
            .then((artist: IArtistCore) => {
                dispatch(undoArtistChangesSuccess(artist));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
            });
    };
};

export const getProductDetailsThunk = (
    dataSource: string,
    productCoreID: number,
    productMaintenanceGeneralDataView: IRepertoireComponentDataItem,
    formats: FormatFields[],
    openEntityForWorflowSession?: boolean,
    openNewTab?: boolean,
    id1?: string,
    id2?: string,
    productType?: string,
    originOfRevision?: string,
    enableCuesheet?: boolean,
    cuesheetDatasource?: any,
    productionType?:string,
    musicDuration?:number[],
    isOpenedInSecondTab?:boolean

): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        let fetchCuesheetEnableProduct: boolean;
        if (dataSource === DataSource.Repertoire && productType && productType.replace(" ", "").toUpperCase() === CUE_SHEET_KEY.toUpperCase()
            && enableCuesheet && !isNullOrUndefined(id1) && !isNullOrUndefined(id2) && !isNullOrUndefined(originOfRevision)) {
            if (originOfRevision == cuesheetDatasource.Current) {
                fetchCuesheetEnableProduct = false;
            } else {
                fetchCuesheetEnableProduct = true;
            }
        } else {
            fetchCuesheetEnableProduct = false;
        }


        dispatch(getProductDetailsRequest());
        dispatch(showLoading());
        return (fetchCuesheetEnableProduct ? (SearchRequests.getCueSheetEnabledProduct(id1, id2, originOfRevision)) : (SearchRequests.getProduct(dataSource, productCoreID)))
            .then(async (res: IProductCore) => {
                if (res.productWorks && res.productWorks.length > 0) {
                    const productWorks: IProductWork[] = [];

                    // As the Product can contain Intray or Repertoire works, we have to loop through each as they will have a different dataSource
                    const intrayWorkIDs = res.productWorks.filter(pw => pw.workDataSource == "Intray").map(pw => pw.workCode)
                    const repertoireWorkIDs = res.productWorks.filter(pw => pw.workDataSource == "Repertoire").map(pw => pw.workCode)

                    if (intrayWorkIDs.length > 0) {
                        let hydratedWorks = hydrateProductWorksFromIndex(intrayWorkIDs, 'Intray', res.productWorks);
                        (await hydratedWorks).forEach(w => productWorks.push(w))
                    }

                    if (repertoireWorkIDs.length > 0) {
                        let hydratedWorks = hydrateProductWorksFromIndex(repertoireWorkIDs, 'Repertoire', res.productWorks);
                        (await hydratedWorks).forEach(w => productWorks.push(w))
                    }

                    if (productWorks.length > 0) {
                        res.productWorks = productWorks.sort((a, b) => a.productWorkID - b.productWorkID);
                    } else {
                        res.productWorks.forEach(
                            (x) => (x.title = res.productCoreID === 0 ? "Save this external Cue Sheet to load works" : "Data pending search indexer run")
                        );
                    }
                }

                res.originOfRevision = originOfRevision;

                dispatch(getProductDetailsSuccess(res, openNewTab))
                if (openNewTab) {
                }
                else {
                    let newProductTab: ITabReduxItem = {
                        title: ProductDetailsMapper.getOriginalTitleFromProductNames(res.productNames),
                        component: PRODUCT_MAINTENANCE_PAGE.component,
                        productMaintenanceState: {
                            loaded: false,
                            product: ProductDetailsMapper.mapProductToState(res, formats),
                            productBatchOperationInProgress: false
                        },
                        isEntityForWorkflowSession: openEntityForWorflowSession,
                        changesMade: false,
                        formatFields: formats
                    };
                    if (isOpenedInSecondTab) {
                        dispatch(addTab(newProductTab, undefined, SECOND_PANE_INDEX));
                    } else {
                        dispatch(addTab(newProductTab));
                    } 
                    dispatch(hideModal());
                }
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(getProductDetailsFailure(err, productCoreID));
                if (err === 404) {
                    dispatch(showModal(PRODUCT_NOT_FOUND_VIEW, null, productCoreID, true, "Product not Found"));
                }
            });
    }
}

export const saveAccountChangesThunk = (
    account: ISocIP,
    lookups?: ILookupDictionary,
    ipNames?: IName[],
    usagePools?: IUsagePool[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        if (InterestedPartyOnSaveValidator.validateInterestedParty(account, lookups).length === 0) {
            let updatedAccount;
            if (account.accountNumber === undefined) {
                updatedAccount = account;
            } else {
                let accountValue: ISocIP = {
                    ...account,
                    ipRepresentations: InterestedPartyDetailsMapper.mapSocIP(account, lookups, usagePools)
                }
                updatedAccount = accountValue;
            }

            dispatch(saveAccountRequest());
            dispatch(showLoading());
            if (!updatedAccount) {
                return RepertoireService.saveIPNames(ipNames)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(saveIPNamesSuccess(res.data))
                        }
                    })
                    .finally(() => {
                        dispatch(hideModal());
                    });
            }
            return RepertoireService.saveAccountChanges(updatedAccount)
                .then(res => {
                    if (res.status !== 200 || !res.data.success) {
                        dispatch(saveAccountFailure(res.data, lookups));
                    }
                    else {
                        dispatch(saveAccountSuccess(res.data, lookups));
                        if (res.data.ipNameNumberAdded) {
                            JobService.triggerContributorMassUpdate({ accountNumberToChange: account.accountNumber, targetIPINumber: res.data.ipNameNumberAdded })
                        }
                        if (ipNames) {
                            return RepertoireService.saveIPNames(ipNames)
                                .then(res => {
                                    if (res.status === 200) {
                                        dispatch(saveIPNamesSuccess(res.data))
                                    }
                                });
                        }
                    }
                })
                .catch((error: any) => {
                    SearchRequests.getIP(account.ipBaseNumber ? account.ipBaseNumber : null, account.accountNumber ? account.accountNumber : null)
                        .then((oldIp: IInterestedParty) => {

                            let updatedAccount;
                            if (account.accountNumber === undefined) {
                                updatedAccount = account;
                            } else {
                                let accountValue: ISocIP = {
                                    ...account,
                                    ipRepresentations: InterestedPartyDetailsMapper.mapSocIP(account, lookups, usagePools)
                                }
                                updatedAccount = accountValue;
                            }

                            oldIp.socIP = updatedAccount;
                            const errorMessages: IValidationMessage[] = [{ messageKey: error }];
                            const accountSave: IAccountSaveResult = {
                                interestedParty: oldIp,
                                messages: errorMessages,
                                saved: false,
                                success: false,
                                validationSuccess: false
                            }
                            dispatch(saveAccountFailure(accountSave, lookups));
                        });
                })
                .finally(() => {
                    dispatch(hideModal());
                });

        } else {
            SearchRequests.getIP(account.ipBaseNumber ? account.ipBaseNumber : null, account.accountNumber ? account.accountNumber : null)
                .then((oldIp: IInterestedParty) => {
                    let updatedAccount;
                    if (account.accountNumber === undefined) {
                        updatedAccount = account;
                    } else {
                        let accountValue: ISocIP = {
                            ...account,
                            ipRepresentations: InterestedPartyDetailsMapper.mapSocIP(account, lookups, usagePools)
                        }
                        updatedAccount = accountValue;
                    }

                    oldIp.socIP = updatedAccount;
                    const errorMessages: IValidationMessage[] = InterestedPartyOnSaveValidator.validateInterestedParty(account, lookups);
                    const accountSave: IAccountSaveResult = {
                        interestedParty: oldIp,
                        messages: errorMessages,
                        saved: false,
                        success: false,
                        validationSuccess: false
                    }
                    dispatch(saveAccountFailure(accountSave, lookups));
                    dispatch(hideModal());
                });

        }



    }
};


export const getWorkDetailsThunk = (
    dataSource: string,
    workID: number,
    lookups: ILookupDictionary,
    otherIndicatorsWorkFlagTypes: string[],
    dataActions: IDataActionToolbar[] = [],
    workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem,
    formats: FormatFields[],
    readonlyIndicatorsWorkFlagTypes: string[],
    openEntityForWorflowSession?: boolean, 
    workGroup?: string,
    isOpenedInSecondTab?: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getWorkDetailsRequest());
        dispatch(showLoading());
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_WRITER_CONTRIBUTOR_TYPES, INGESTION_GROUP)
            .then((writersContributorTypes: string[]) => {
                return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_PUBLISHER_CONTRIBUTOR_TYPES, INGESTION_GROUP)
                    .then((publisherContributorTypes: string[]) => {
                        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_ENABLE_CORE_WORK_NUMBERS, WORK_MAINTENANCE_GROUP)
                            .then((enableCoreWorkNumbers: boolean) => {
                                return SearchRequests.getWork(dataSource, workID)
                                    .then((res: IWork) => {
                                        dispatch(getWorkDetailsSuccess(res));
                                        const workState = WorkDetailsMapper.mapWorkToWorkState(res, lookups, otherIndicatorsWorkFlagTypes, readonlyIndicatorsWorkFlagTypes);
                                        const validationMessages = WorkValidator.validateWork(workState, writersContributorTypes, publisherContributorTypes);
                                        let newWorkTab: ITabReduxItem = {
                                            title: WorkDetailsMapper.getOriginalTitleFromWorkNames(res.workNames),
                                            component: WORK_MAINTENANCE_PAGE.component,
                                            workMaintenanceState: {
                                                loaded: false,
                                                workBatchOperationInProgress: false,
                                                work: workState,
                                                contributorInheritanceChanged: false,
                                                isNew: false,
                                                workGroup: workGroup
                                            },
                                            changesMade: false,
                                            dataActionToolbar: dataActions,
                                            isReadonly: false,
                                            formatFields: formats,
                                            isEntityForWorkflowSession: openEntityForWorflowSession,
                                            validationMessages: validationMessages,
                                            saveSuccessful: validationMessages.length ? false : undefined,
                                            enableCoreWorkNumbers: enableCoreWorkNumbers
                                        };
                                        if (isOpenedInSecondTab) {
                                            dispatch(addTab(newWorkTab, undefined, SECOND_PANE_INDEX));
                                        } else {
                                            dispatch(addTab(newWorkTab));
                                        } 
                                        dispatch(hideModal());
                                    })
                                    .catch(err => {
                                        dispatch(hideModal());
                                        dispatch(getWorkDetailsFailure(err, workID));
                                        if (err.response && err.response.status === 404) {
                                            dispatch(showModal(WORK_NOT_FOUND_VIEW, null, workID, true, "Work not found"));
                                        }

                                    });
                            })
                    })
            })
    };
};

export const getWorkDetailsWorkflowThunk = (
    dataSource: string,
    workID: number,
    lookups: ILookupDictionary,
    otherIndicatorsWorkFlagTypes: string[],
    dataActions: IDataActionToolbar[] = [],
    workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem,
    formats: FormatFields[],
    readonlyIndicatorsWorkFlagTypes: string[],
    openEntityForWorkflowSession?: boolean,
    activeTab?: number, currentWorkflowIndex?: number,
    workflows?: IWorkflowSearchResult[],
    workGroup?: string,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getWorkDetailsRequest());
        dispatch(showLoading());
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_WRITER_CONTRIBUTOR_TYPES, INGESTION_GROUP)
            .then((writersContributorTypes: string[]) => {
                return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_PUBLISHER_CONTRIBUTOR_TYPES, INGESTION_GROUP)
                    .then((publisherContributorTypes: string[]) => {
                        return SearchRequests.getWork(dataSource, workID)
                            .then((res: IWork) => {
                                dispatch(getWorkDetailsSuccess(res));
                                const workState = WorkDetailsMapper.mapWorkToWorkState(res, lookups, otherIndicatorsWorkFlagTypes, readonlyIndicatorsWorkFlagTypes);
                                const validationMessages = WorkValidator.validateWork(workState, writersContributorTypes, publisherContributorTypes);
                                let newWorkTab: ITabReduxItem = {
                                    title: WorkDetailsMapper.getOriginalTitleFromWorkNames(res.workNames),
                                    component: WORK_MAINTENANCE_PAGE.component,
                                    workMaintenanceState: {
                                        loaded: false,
                                        workBatchOperationInProgress: false,
                                        work: workState,
                                        contributorInheritanceChanged: false,
                                        isNew: false,
                                        workGroup: workGroup
                                    },
                                    changesMade: false,
                                    dataActionToolbar: dataActions,
                                    isReadonly: false,
                                    formatFields: formats,
                                    isEntityForWorkflowSession: openEntityForWorkflowSession,
                                    validationMessages: validationMessages,
                                    saveSuccessful: validationMessages.length ? false : undefined,
                                };
                                dispatch(addTab(newWorkTab));
                                dispatch(hideModal());
                            })
                            .catch(err => {
                                dispatch(getWorkDetailsFailure(err, workID));
                                dispatch(hideModal());
                                let currentIndex = currentWorkflowIndex;
                                let removeTab = true;
                                if (!currentWorkflowIndex) {
                                    currentIndex = 0;
                                    removeTab = false;
                                }

                                dispatch<any>(skipWorkflowInSessionThunk(activeTab, currentIndex + 1, workflows, lookups, otherIndicatorsWorkFlagTypes, dataActions, workMaintenanceGeneralDataViewData, formats, readonlyIndicatorsWorkFlagTypes, removeTab));
                                if (err === 404) {
                                    dispatch(showModal(WORK_NOT_FOUND_VIEW, null, workID, true, "Work not found"));
                                }
                            });
                    })
            })
    };
};

export const getMatchingWorkInfo = (workID: number): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getMatchingWorkInfoRequest());
        return SearchRequests.getWork(REPERTOIRE, workID)
            .then((work: IWork) => {
                dispatch(getMatchingWorkInfoSuccess(work));
            })
            .catch(err => {
                dispatch(getMatchingWorkInfoFailure(err, workID));
            })

    }
}

export const refreshWorkDetailsThunk = (
    dataSource: string,
    workID: number,
    lookups: ILookupDictionary,
    otherIndicatorsWorkFlagTypes: string[],
    activeTabItem: ITabReduxItem,
    formats: FormatFields[],
    readonlyIndicatorsWorkFlagTypes: string[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(refreshWorkDetailsRequest());
        dispatch(showLoading());
        return SearchRequests.getWork(dataSource, workID)
            .then((res: IWork) => {
                dispatch(refreshWorkDetailsSuccess(res));
                let tab: ITabReduxItem = {
                    ...activeTabItem,
                    title: WorkDetailsMapper.getOriginalFullTitleFromWorkNames(res.workNames, res.workID),
                    workMaintenanceState: {
                        ...activeTabItem.workMaintenanceState,
                        loaded: false,
                        work: WorkDetailsMapper.mapWorkToWorkState(res, lookups, otherIndicatorsWorkFlagTypes, readonlyIndicatorsWorkFlagTypes),
                        isNew: false
                    },
                    changesMade: false
                };
                dispatch(replaceTab(tab, activeTabItem.title))
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(refreshWorkDetailsFailure(err, workID));
                if (err == 404) {
                    dispatch(showModal(WORK_NOT_FOUND_VIEW, null, workID, true, "Work not found"));
                }
            });
    };
};

export const undoWorkChangesThunk = (
    dataSource: string,
    workID: number,
    lookups: ILookupDictionary,
    paneIndex: number,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(undoWorkChangesRequest());
        dispatch(showLoading());
        return SearchRequests.getWork(dataSource, workID)
            .then((work: IWork) => {
                dispatch(undoWorkChangesSuccess(work, lookups, paneIndex));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
            });
    };
};

export const copyExistingWorkThunk = (actionList: IDataActionToolbar[], userName: string, lookups: ILookupDictionary, paneIndex?: number): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(copyExistingWorkRequest());
        dispatch(showLoading());
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELDS_EXCLUDED_FROM_COPY_WORK, WORK_MAINTENANCE_GROUP)
            .then((excludedFields: string[]) => {
                SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_CUSTOMER_SOCIETY_CODE, INGESTION_GROUP)
                    .then((societyNumberCode) => {
                        dispatch(copyExistingWorkSuccess(actionList, userName, lookups, excludedFields, societyNumberCode.toString(), paneIndex));
                        dispatch(hideModal());
                    })
                    .catch(err => {
                        dispatch(hideModal());
                    });
            })
            .catch(err => {
                dispatch(hideModal());
            });
    };
};

export const deleteWorkThunk = (
    dataSource: DataSource,
    workId: number,
    activeTab: number,
    activePane?: number
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(deleteWorkequest(dataSource, workId));
        RepertoireService.deleteWork(dataSource, workId)
            .then(res => {
                dispatch(deleteWorkSuccess(workId));
                dispatch(removeTab(activeTab, activePane));
            })
            .catch((error: any) => {
                dispatch(deleteWorkFailure(error));
            });
    };
};

export const updateWorkContributorIPThunk = (
    dataSource: DataSource,
    workContributor: IContributorItem, ip: IP
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(updateWorkContributorIPRequest());
        RepertoireService.linkedAgreements({
            dataSource: dataSource,
            ipBaseNumber: ip.ipBaseNumber,
            setTypeGroup: workContributor.setTypeGroup,
            setType: workContributor.setType
        })
            .then(res => {
                dispatch(updateWorkContributorIPSuccess(workContributor.workContributorID, ip, res.data));
            })
            .catch((error: any) => {
                dispatch(updateWorkContributorIPFailure());
            });
    };
};

export const saveWorkThunk = (
    updatedWork: IWorkState,
    saveOption: SaveOption,
    lookups: ILookupDictionary,
    otherIndicatorsWorkFlagTypes: string[],
    dataActions: IDataActionToolbar[] = [],
    isWorkflowSession: boolean = false,
    readonlyIndicatorsWorkFlagTypes: string[],
    workflowsSessionItems?: IWorkflowSearchResult[],
    currentWorkflowIndex?: number,
    activeTab?: number,
    formats?: FormatFields[],
    workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem,
    isOpenForWorkflowSession?: boolean,
    customer?: string,
    worksSearchResults?: IWorksSearchResult[],
    paneIndex?: number,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(saveWorkRequest());
        dispatch(showLoading());

        SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_WRITER_CONTRIBUTOR_TYPES, INGESTION_GROUP)
            .then((writersContributorTypes: string[]) => {
                SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_PUBLISHER_CONTRIBUTOR_TYPES, INGESTION_GROUP)
                    .then((publisherContributorTypes: string[]) => {
                        if (WorkValidator.validateWork(updatedWork, writersContributorTypes, publisherContributorTypes, worksSearchResults).length === 0) {
                            updatedWork.artists.forEach(artist => {
                                if (artist.performerID == undefined || (artist.performerID <= 0 && artist.workPerformerID == 0)) {
                                    SearchRequests.getArtists({ firstName: artist.firstName.value, lastName: artist.lastName.value, fullName: artist.fullName.value, type: artist.type.value, number: artist.number.value }, REPERTOIRE)
                                        .then(result => {
                                            if (result.length > 1) {
                                                artist.performerID = result[0].performerID
                                            }
                                        });
                                }
                            })
                            return RepertoireService.saveChanges(WorkDetailsMapper.mapWorkStateToWork(updatedWork, lookups, false, customer), saveOption)
                                .then(res => {
                                    const result: IWorkSaveResult = res.data;
                                    if (res.status !== 200 || !result.success) {
                                        dispatch(saveWorkFailure(result, lookups, paneIndex));
                                    }
                                    else {
                                        SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_ENABLE_CORE_WORK_NUMBERS, WORK_MAINTENANCE_GROUP).then((enableCoreWorkNumbers: boolean) => {
                                            dispatch(saveWorkSuccess(result, lookups, dataActions, saveOption, enableCoreWorkNumbers, paneIndex));
                                        })
                                        if (isOpenForWorkflowSession) {
                                            if (workflowsSessionItems[currentWorkflowIndex]) {
                                                dispatch(nextWorkflowInSession());
                                                dispatch(getWorkDetailsRequest());
                                                const item = workflowsSessionItems[currentWorkflowIndex];
                                                return SearchRequests.getWork(item.setType, parseInt(item.entityID))
                                                    .then((res: IWork) => {
                                                        dispatch(getWorkDetailsSuccess(res));
                                                        let newWorkTab: ITabReduxItem = {
                                                            title: WorkDetailsMapper.getOriginalTitleFromWorkNames(res.workNames),
                                                            component: WORK_MAINTENANCE_PAGE.component,
                                                            workMaintenanceState: {
                                                                loaded: false,
                                                                workBatchOperationInProgress: false,
                                                                work: WorkDetailsMapper.mapWorkToWorkState(res, lookups, otherIndicatorsWorkFlagTypes, readonlyIndicatorsWorkFlagTypes),
                                                                contributorInheritanceChanged: false,
                                                                isNew: false
                                                            },
                                                            changesMade: false,
                                                            dataActionToolbar: dataActions,
                                                            isReadonly: false,
                                                            formatFields: formats,
                                                            isEntityForWorkflowSession: isOpenForWorkflowSession,
                                                        };
                                                        if (paneIndex) {
                                                            dispatch(removeTab(activeTab, paneIndex));
                                                            dispatch(addTab(newWorkTab, undefined, paneIndex));
                                                        } else {
                                                            dispatch(removeTab(activeTab))
                                                            dispatch(addTab(newWorkTab));
                                                        }
                                                       
                                                        
                                                        dispatch(hideModal());
                                                    })
                                                    .catch(err => {
                                                        dispatch(hideModal());
                                                        dispatch(getWorkDetailsFailure(err, parseInt(item.entityID)));
                                                        if (err === 404) {
                                                            dispatch(showModal(WORK_NOT_FOUND_VIEW, null, item.entityID, true, "Work not found"));
                                                        }
                                                    });
                                            }
                                            else {
                                                dispatch(markSelectAll(false));
                                                dispatch(cancelWorkflowSession());
                                                dispatch(removeTab(activeTab, paneIndex));
                                            }
                                        }
                                    }
                                })
                                .finally(() => {
                                    dispatch(hideModal());
                                })
                                .catch((error: any) => {
                                    const errorMessage: IValidationMessage[] = [{
                                        messageKey: error
                                    }]
                                    const result: IWorkSaveResult = {
                                        saved: false,
                                        success: false,
                                        validationSuccess: false,
                                        messages: errorMessage,
                                        work: WorkDetailsMapper.mapWorkStateToWork(updatedWork, lookups, false, customer)
                                    }
                                    dispatch(saveWorkFailure(result, lookups, paneIndex));
                                    dispatch(hideModal());
                                });
                        } else {
                            const result: IWorkSaveResult = {
                                saved: false,
                                success: false,
                                validationSuccess: false,
                                messages: WorkValidator.validateWork(updatedWork, writersContributorTypes, publisherContributorTypes, worksSearchResults),
                                work: WorkDetailsMapper.mapWorkStateToWork(updatedWork, lookups, false, customer)
                            }
                            dispatch(saveWorkFailure(result, lookups));
                            dispatch(hideModal());
                        }
                    })

            })
    };
};

export const AddtoPackageThunk = (
    userName: string,
    entityType: string,
    entityID: number,
    paneIndex?: number
): ThunkAction<Promise<IAddtoPackageResult>, IAppState, null, Action<string>> => {
    return async (dispatch: Dispatch): Promise<IAddtoPackageResult> => {
            const res = await RepertoireService.addToPackage(userName, entityType, entityID);
            if (res.status !== 200 || !res.data.success) {
                const errorMessages: IValidationMessage[] = res.data.messages || [
                    { messageKey: GENERIC_ERROR_ADDTOPACKAGE }
                ];
                const saveResult: IAddtoPackageResult = {
                    messages: errorMessages,
                    saved: false,
                    success: false,
                    validationSuccess: false,                };
                dispatch(addToPackageFailure(saveResult, paneIndex));
                dispatch(hideModal());
                return saveResult;
            } else {
                const successResult: IAddtoPackageResult = {
                    success: true,
                    saved: true,
                    messages: [],
                    validationSuccess: true,
                };

                const messageKeys = {
                    Works: "workAddtoPackageSuccessMessage",
                    Products: "productAddtoPackageSuccessMessage",
                    Agreements: "agreementAddtoPackageSuccessMessage",
                };
                const validationMsgKey: IValidationMessage = { messageKey: messageKeys[entityType] };
                successResult.messages.push(validationMsgKey);
                dispatch(addToPackageSuccess(successResult, paneIndex));
                dispatch(hideModal());
                return successResult;
            }
    }
};

export const getMyPackageDetailsThunk = (
    userName: string,
    modalOpen: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading()); 
        return SearchRequests.GetAllPackages(userName)
        .then((res: IMyPackagesDetails[]) => {
            dispatch(getAllPackagesDetailsSuccess(res));
            dispatch(hideModal());
        })
        .catch(err => {
            dispatch(hideModal());
            dispatch(getAllPackagesDetailsFailure(err,userName));
        });
    }
};
export const generatePackageThunk = (
    myPackages: IMyPackagesDetails
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        return RepertoireService.generatePackage(myPackages)
            .then((res: IMyPackagesDetails[]) => {
                dispatch(generatePackageSuccess(res));
            })
            .catch(err => {
                dispatch(generatePackageFailure(err));
            });
    }
};

export const closePackageThunk = (
    myPackages: IMyPackagesDetails
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        return RepertoireService.closePackage(myPackages)
            .catch(err => {
                dispatch(closePackageFailure(err));
            });
    }
};

export const addPackageNoteThunk = (
    myPackages: IMyPackagesDetails
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        return RepertoireService.addPackageNote(myPackages)
            .catch(err => {
                dispatch(addPackageNoteFailure(err));
            });
    }
};

export const saveAgreementThunk = (
    updatedAgreement: IAgreementState,
    postAgreement: boolean,
    dataActions: IDataActionToolbar[] = [],
    workflowsSessionItems?: IWorkflowSearchResult[],
    currentWorkflowIndex?: number,
    isOpenForWorkflowSession?: boolean,
    activeTab?: number,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        if (AgreementValidator.validateAgreement(updatedAgreement).length === 0) {
            dispatch(saveAgreementRequest());
            dispatch(showLoading());
            RepertoireService.saveAgreement(AgreementDetailsMapper.mapStateToAgreement(updatedAgreement), postAgreement)
                .then(res => {
                    if (res.status !== 200 || !res.data.success) {
                        dispatch(saveAgreementFailure(res.data));
                    }
                    else {
                        if (isOpenForWorkflowSession) {
                            if (workflowsSessionItems[currentWorkflowIndex]) {
                                dispatch(nextWorkflowInSession());
                                dispatch(getAgreementDetailsRequest());

                                const item = workflowsSessionItems[currentWorkflowIndex];
                                return SearchRequests.getAgreement(INTRAY, parseInt(item.entityID))
                                    .then((res2: IAgreement) => {
                                        dispatch(getAgreementDetailsSuccess(res2));
                                        let newAgreementTab: ITabReduxItem = {
                                            title: AgreementDetailsMapper.getAgreementTitle(res2),
                                            component: AGREEMENT_MAINTENANCE_PAGE.component,
                                            agreementMaintenanceState: {
                                                loaded: false,
                                                agreement: AgreementDetailsMapper.mapAgreementToState(res2),
                                                agreementBatchOperationInProgress: false
                                            },
                                            changesMade: false,
                                            dataActionToolbar: dataActions,
                                            isEntityForWorkflowSession: isOpenForWorkflowSession
                                        };
                                        dispatch(removeTab(activeTab))
                                        dispatch(addTab(newAgreementTab));
                                        dispatch(hideModal());
                                    })
                                    .catch(err => {
                                        dispatch(hideModal());
                                        dispatch(getAgreementDetailsFailure(err, parseInt(item.entityID)));
                                        if (err === 404) {
                                            dispatch(showModal(WORK_NOT_FOUND_VIEW, null, item.entityID, true, "Work not found"));
                                        }
                                    });
                            }
                            else {
                                dispatch(markSelectAll(false));
                                dispatch(cancelWorkflowSession());
                                dispatch(removeTab(activeTab));
                            }
                        }
                        
                        res.data.agreement.agreementProductWorks.forEach(apw => {
                            apw.productWorks = updatedAgreement.agreementProductWorks
                            .find(apw2 => apw2.productId === apw.productId)
                            .productWorks;
                        });
                        dispatch(saveAgreementSuccess(res.data, dataActions));
                        
                    }
                })
                .catch((error: any) => {
                    const agreementResult: IAgreementSaveResult = {
                        agreement: AgreementDetailsMapper.mapStateToAgreement(updatedAgreement),
                        messages: [GENERIC_ERROR],
                        saved: false,
                        success: false,
                        validationSuccess: false
                    }
                    dispatch(saveAgreementFailure(agreementResult));
                })
                .finally(() => {
                    dispatch(hideModal());
                });
        } else {
            const result: IAgreementSaveResult = {
                saved: false,
                success: false,
                validationSuccess: false,
                messages: AgreementValidator.validateAgreement(updatedAgreement),
                agreement: AgreementDetailsMapper.mapStateToAgreement(updatedAgreement)
            }
            dispatch(saveAgreementFailure(result));
            dispatch(hideModal());
        }
    };
};

export const deleteAgreementThunk = (
    dataSource: DataSource,
    agreementId: number,
    activeTab: number
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(deleteAgreementRequest(dataSource, agreementId));
        dispatch(showLoading());
        RepertoireService.deleteAgreement(dataSource, agreementId)
            .then(res => {
                dispatch(deleteAgreementSuccess(agreementId));
            })
            .finally(() => {
                dispatch(removeTab(activeTab));
                dispatch(hideModal());

            })
            .catch((error: any) => {
                dispatch(deleteAgreementFailure(error));
                dispatch(hideModal());
            });
    };
};

export const mergeAgreementThunk = (
    mergeAgreement: IAgreementState,    
    agreementId: number,
    dataActions: IDataActionToolbar[] = [],
    lookups: ILookupDictionary,      
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        if (AgreementValidator.validateAgreement(mergeAgreement).length === 0) {
            dispatch(saveAgreementRequest());
            dispatch(showLoading());
            RepertoireService.mergeAgreement(AgreementDetailsMapper.mapStateToAgreement(mergeAgreement), agreementId)
                .then(res => {
                    if (res.status !== 200 || !res.data.success) {
                        dispatch(mergeAgreementFailure(res.data,lookups));
                    }
                    else {
                        dispatch(mergeAgreementSuccess(res.data, lookups, dataActions));                                            
                    }
                })
                .catch((error: any) => {
                    const agreementResult: IAgreementSaveResult = {
                        agreement: AgreementDetailsMapper.mapStateToAgreement(mergeAgreement),
                        messages: [GENERIC_ERROR],
                        saved: false,
                        success: false,
                        validationSuccess: false
                    }
                    dispatch(mergeAgreementFailure(agreementResult,lookups));
                })
                .finally(() => {
                    dispatch(hideModal());
                });
        } else {
            const result: IAgreementSaveResult = {
                saved: false,
                success: false,
                validationSuccess: false,
                messages: AgreementValidator.validateAgreement(mergeAgreement),
                agreement: AgreementDetailsMapper.mapStateToAgreement(mergeAgreement)
            }
            dispatch(mergeAgreementFailure(result, lookups));
            dispatch(hideModal());
        }
    };
};

export const saveProductThunk = (
    updatedProduct: IProductState,
    saveOption: SaveOption,
    lookups: ILookupDictionary,
    workflowsSessionItems?: IWorkflowSearchResult[],
    currentWorkflowIndex?: number,
    isOpenForWorkflowSession?: boolean,
    activeTab?: number,
    formats?: FormatFields[],
    dataActions: IDataActionToolbar[] = [],
    paneIndex?: number,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        {
            dispatch(saveProductRequest());
            dispatch(showLoading());
            updatedProduct.productPerformer.forEach(artist => {
                if (artist.performerID == undefined || (artist.performerID <= 0 && artist.productPerformerID == 0)) {
                    SearchRequests.getArtists({ fullName: artist.performerName.value, type: artist.type.value }, REPERTOIRE)
                        .then(result => {
                            if (result.length > 1) {
                                artist.performerID = result[0].performerID
                            }
                        });
                }
            })
            let draftProductValidationMessages = ProductValidator.validateProduct(updatedProduct);
            if (draftProductValidationMessages.length === 0) {
                RepertoireService.saveProduct(ProductDetailsMapper.mapStateToProduct(updatedProduct), saveOption)
                    .then(async res => {
                        if (res.status !== 200 || !res.data.success) {
                            const errorMessages: IValidationMessage[] = res.data.messages || [{ messageKey: GENERIC_ERROR }];
                            const saveResult: IProductSaveResult = {
                                messages: errorMessages,
                                product: res.data.product,
                                saved: false,
                                success: false,
                                matchingSuccess: false,
                                postingSuccess: false,
                                validationSuccess: false
                            }
                            dispatch(saveProductFailure(saveResult));
                        }
                        else {
                            if (res.data.product.productWorks && res.data.product.productWorks.length > 0) {
                                const productWorks: IProductWork[] = [];

                                const intrayWorkIDs: number[] = res.data.product.productWorks.filter(pw => pw.workDataSource == "Intray").map(pw => pw.workCode)
                                const repertoireWorkIDs: number[] = res.data.product.productWorks.filter(pw => pw.workDataSource == "Repertoire").map(pw => pw.workCode)

                                if (intrayWorkIDs.length > 0) {
                                    let hydratedWorks = hydrateProductWorksFromIndex(intrayWorkIDs, 'Intray', res.data.product.productWorks);
                                    (await hydratedWorks).forEach(w => productWorks.push(w))
                                }

                                if (repertoireWorkIDs.length > 0) {
                                    let hydratedWorks = hydrateProductWorksFromIndex(repertoireWorkIDs, 'Repertoire', res.data.product.productWorks);
                                    (await hydratedWorks).forEach(w => productWorks.push(w))
                                }
                                res.data.product.productWorks = productWorks;
                            }
                            dispatch(saveProductSuccess(res.data, paneIndex));

                            if (isOpenForWorkflowSession) {
                                if (workflowsSessionItems[currentWorkflowIndex]) {
                                    dispatch(removeTab(activeTab))
                                    dispatch(nextWorkflowInSession());
                                    dispatch(getProductDetailsRequest());
                                    const item = workflowsSessionItems[currentWorkflowIndex];
                                    return SearchRequests.getProduct(item.setType, parseInt(item.entityID))
                                        .then((res: IProductCore) => {
                                            dispatch(getProductDetailsSuccess(res));
                                            let newProductTab: ITabReduxItem = {
                                                title: ProductDetailsMapper.getOriginalTitleFromProductNames(res.productNames),
                                                component: PRODUCT_MAINTENANCE_PAGE.component,
                                                productMaintenanceState: {
                                                    loaded: false,
                                                    productBatchOperationInProgress: false,
                                                    product: ProductDetailsMapper.mapProductToState(res, formats),
                                                    isNew: false
                                                },
                                                changesMade: false,
                                                dataActionToolbar: dataActions,
                                                isReadonly: false,
                                                formatFields: formats,
                                                isEntityForWorkflowSession: isOpenForWorkflowSession
                                            };
                                            dispatch(addTab(newProductTab));
                                            dispatch(hideModal());
                                        })
                                        .catch(err => {
                                            dispatch(hideModal());
                                            dispatch(getProductDetailsFailure(err, parseInt(item.entityID)));
                                            if (err === 404) {
                                                dispatch(showModal(PRODUCT_NOT_FOUND_VIEW, null, item.entityID, true, "Product not found"));
                                            }
                                        });
                                }
                                else {
                                    dispatch(markSelectAll(false));
                                    dispatch(cancelWorkflowSession());
                                    dispatch(removeTab(activeTab, paneIndex));
                                }
                            }
                        }
                    })
                    .catch((error: any) => {
                        const errorMessages: IValidationMessage[] = [{ messageKey: GENERIC_ERROR }];
                        const saveResult: IProductSaveResult = {
                            messages: ProductValidator.validateProduct(updatedProduct),
                            product: ProductDetailsMapper.mapStateToProduct(updatedProduct),
                            saved: false,
                            success: false,
                            matchingSuccess: false,
                            postingSuccess: false,
                            validationSuccess: false
                        }
                        dispatch(saveProductFailure(saveResult, paneIndex));
                    })
                    .finally(() => {
                        dispatch(hideModal());
                    })
            }
            else {
                const errorMessages: IValidationMessage[] = [{ messageKey: GENERIC_ERROR }];
                const saveResult: IProductSaveResult = {
                    messages: ProductValidator.validateProduct(updatedProduct),
                    product: ProductDetailsMapper.mapStateToProduct(updatedProduct),
                    saved: false,
                    success: false,
                    matchingSuccess: false,
                    postingSuccess: false,
                    validationSuccess: false
                }
                dispatch(saveProductFailure(saveResult, paneIndex));
                dispatch(hideModal());
            }
        };
    };
};

async function hydrateProductWorksFromIndex(workIDs: number[], dataSource: string, productWorks: IProductWork[]): Promise<IProductWork[]> {
    return new Promise<IProductWork[]>(async (resolve, reject) => {
        const productWorksToReturn: IProductWork[] = [];
        const workSearchQuery: IMatchWorksSearchQuery =
        {
            workIDs: workIDs,
            title: "",
            number: "",
            artist: "",
            writers: "",
            societyAccountNumber: "",
            workBatchID: "",
            dataSource: dataSource,
            hasOpenWorkflow: false,
        };

        await UsageSearchRequests.getWorksByIdList(workSearchQuery)
            .then((result: IMatchWorksSearchResult[]) => {
                result.forEach((w) => {
                    const currentProductWorks: IProductWork[] = productWorks.filter(x => x.workCode === w.workID);
                    currentProductWorks.forEach(currentProductWork => {
                        const hydratedWork: IProductWork = {
                            productWorkID: currentProductWork.productWorkID,
                            workDataSource: dataSource,
                            duration: currentProductWork.duration,
                            workCode: currentProductWork.workCode,
                            musicUsage: currentProductWork.musicUsage,
                            title: w.title.find(t => t !== undefined && t != null) ?? "",
                            number: w.number.find(t => t !== undefined && t != null) ?? "",
                            writer: w.writers.find(t => t !== undefined && t != null) ?? "",
                            sequence: currentProductWork.sequence,
                            side: currentProductWork.side,
                            track: currentProductWork.track,
                        }
                        productWorksToReturn.push(hydratedWork);
                    });
                });
                return resolve(productWorksToReturn);

            })
            .catch(err => {
                reject(err);
            });
    });
}


export const deleteProductThunk = (
    dataSource: DataSource,
    productCoreId: number,
    activeTab: number,
    paneIndex?: number,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(deleteProductRequest(dataSource, productCoreId));
        RepertoireService.deleteProduct(dataSource, productCoreId)
            .then(res => {
                dispatch(deleteProductSuccess(productCoreId));
                dispatch(removeTab(activeTab, paneIndex));
            })
            .catch((error: any) => {
                dispatch(deleteProductFailure(error));
            });
    };
};

export const undoProductChangesThunk = (
    dataSource: string,
    productCoreId: number,
    lookups: ILookupDictionary,
    paneIndex: number,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(undoProductChangesRequest());
        dispatch(showLoading());
        return SearchRequests.getProduct(dataSource, productCoreId)
            .then((product: IProductCore) => {
                dispatch(undoProductChangesSuccess(product, paneIndex));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
            });
    };
};

export const undoAdjustmentChangesThunk = (
    adjustmentId: string,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(undoAdjustmentChanges());
        dispatch(showLoading());
        return SearchRequests.getAdjustment(adjustmentId)
            .then((adjustment: IAdjustment) => {
                dispatch(undoAdjustmentChangesSuccess(adjustment));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(undoAdjustmentChangesFailure(err));
                dispatch(hideModal());
            });
    };
};


export const submitProductAVRequestThunk = (
    productAVRequest: IProductAVRequest,
    productCoreID: number
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(submitProductAVRequest(productAVRequest, productCoreID));
        dispatch(showLoading());
        return RepertoireService.saveAVRequest(productAVRequest, productCoreID)
            .then((res) => {
                dispatch(submitProductAVRequestSuccess(res.data));
                dispatch(hideModal());
            })
            .catch(err => {
                submitProductAVRequestFailure();
                dispatch(hideModal());
            });
    };
};

export const searchAgreementVersionHistoryThunk = (agreementID: number, activeTabItem: ITabReduxItem, actionList: IDataActionToolbar[]): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading());
        dispatch(searchAgreementVersionHistoryRequest());
        return SearchRequests.getAgreementVersionHistory(agreementID)
            .then((res: IAgreementVersionHistorySearchResult[]) => {
                dispatch(hideModal());
                dispatch(searchAgreementVersionHistorySuccess(res));
                dispatch(showVersionHistoryModal("Agreement Version History", SEARCH_VIEW_AGREEMENTS, {
                    openCallback: (entityId: number, versionNumber: number) => getAgreementHistoryVersion(dispatch, agreementID, versionNumber, activeTabItem, actionList),
                    versionHistorySearchResult: res
                }))
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(searchAgreementVersionHistoryFailure());
            });
    };
}

function getAgreementHistoryVersion(
    dispatch: Dispatch, agreementID: number, versionNumber: number, activeTabItem: ITabReduxItem, actionList: IDataActionToolbar[]): void {
    SearchRequests.getAgreementHistoryVersion(agreementID, versionNumber)
        .then((res: IAgreement) => {
            let newTab: ITabReduxItem = {
                ...activeTabItem,
                component: AGREEMENT_MAINTENANCE_PAGE.component,
                agreementMaintenanceState: {
                    agreement: AgreementDetailsMapper.mapAgreementToState(res),
                    loaded: false,
                    isNew: false,
                    agreementBatchOperationInProgress: false,
                },
                changesMade: false,
                versionNumber,
                isReadonly: true,
                dataActionToolbar: actionList
            };
            dispatch(addTab(newTab));
            dispatch(hideModal());
        });
}

export const searchSocIPVersionHistoryThunk = (socIPID: number, activeTabItem: ITabReduxItem, formats: FormatFields[], lookups: ILookupDictionary): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading());
        dispatch(searchSocIPVersionHistoryRequest());
        return SearchRequests.getSocIPVersionHistory(socIPID)
            .then((res: ISocIPVersionHistorySearchResult[]) => {
                dispatch(hideModal());
                dispatch(searchSocIPVersionHistorySuccess(res));
                dispatch(showVersionHistoryModal("Society IP Version History", SEARCH_VIEW_IPS, {
                    openCallback: (entityId: number, versionNumber: number) => getSocIPHistoryVersion(dispatch, socIPID, versionNumber, activeTabItem, formats, lookups),
                    versionHistorySearchResult: res
                }))
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(searchSocIPVersionHistoryFailure());
            });
    };
}

function getSocIPHistoryVersion(
    dispatch: Dispatch, socIPID: number, versionNumber: number, activeTabItem: ITabReduxItem, formats: FormatFields[], lookups: ILookupDictionary): void {
    SearchRequests.getSocIPHistoryVersion(socIPID, versionNumber)
        .then((res: ISocIP) => {            
            res.ipRepresentations = InterestedPartyDetailsMapper.mapInterestedPartStatetoState(res, lookups)

            let newSocIPTab: ITabReduxItem = {
                ...activeTabItem,
                component: IP_MAINTENANCE_PAGE.component,
                interestedPartyMaintenanceState: {
                    isNew: false,
                    loaded: false,
                    interestedParty: {
                        ...activeTabItem.interestedPartyMaintenanceState.interestedParty,
                        socIP: res
                    }
                },
                changesMade: false,
                versionNumber,
                isReadonly: true,
                formatFields: formats
            };
            dispatch(addTab(newSocIPTab));
            dispatch(hideModal());
        });
}

export const searchProductVersionHistoryThunk = (productCoreID: number, formats: FormatFields[]): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading());
        dispatch(searchProductVersionHistoryRequest());
        return SearchRequests.getProductVersionHistory(productCoreID)
            .then((res: IProductVersionHistorySearchResult[]) => {
                dispatch(hideModal());
                dispatch(searchProductVersionHistorySuccess(res));
                dispatch(showVersionHistoryModal("Product Version History", SEARCH_VIEW_PRODUCTS, {
                    openCallback: (entityId: number, versionNumber: number) => getProductHistoryVersion(dispatch, productCoreID, versionNumber, formats),
                    versionHistorySearchResult: res
                }))
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(searchProductVersionHistoryFailure());
            });
    };
}

function getProductHistoryVersion(
    dispatch: Dispatch, productCoreID: number, versionNumber: number, formats: FormatFields[]): void {
    SearchRequests.getProductHistoryVersion(productCoreID, versionNumber)
        .then((res: IProductCore) => {
            let newProductTab: ITabReduxItem = {
                title: ProductDetailsMapper.getOriginalTitleFromProductNames(res.productNames),
                component: PRODUCT_MAINTENANCE_PAGE.component,
                productMaintenanceState: {
                    loaded: false,
                    product: ProductDetailsMapper.mapProductToState(res, formats),
                    productBatchOperationInProgress: false
                },
                changesMade: false,
                versionNumber,
                isReadonly: true,
                formatFields: formats
            };
            dispatch(addTab(newProductTab));
            dispatch(hideModal());
        });
}

export const searchPoolVersionHistoryThunk = (poolID: number, activeTabItem: ITabReduxItem, actionList: IDataActionToolbar[], sources: ITreeData[]): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading());
        dispatch(searchPoolVersionHistoryRequest());
        return SearchRequests.getPoolVersionHistory(poolID)
            .then((res: IUsagePoolVersionHistorySearchResult[]) => {
                dispatch(hideModal());
                dispatch(searchPoolVersionHistorySuccess(res));
                dispatch(showVersionHistoryModal("Pool Version History", SEARCH_VIEW_POOLS, {
                    openCallback: (entityId: number, versionNumber: number) => getPoolHistoryVersion(dispatch, poolID, versionNumber, activeTabItem, actionList, sources),
                    versionHistorySearchResult: res
                }))
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(searchPoolVersionHistoryFailure());
            });
    };
}

function getPoolHistoryVersion(
    dispatch: Dispatch, poolID: number, versionNumber: number, activeTabItem: ITabReduxItem, actionList: IDataActionToolbar[], sources: ITreeData[]): void {
    SearchRequests.getPoolHistoryVersion(poolID, versionNumber)
        .then((res: IUsagePoolCore) => {
            let newTab: ITabReduxItem = {
                ...activeTabItem,
                component: USAGE_POOL_MAINTENANCE_PAGE.component,
                usagePoolMaintenanceState: {
                    usagePool: UsagePoolDetailsMapper.mapPoolToState(res, sources),
                    loaded: false,
                    isNew: false,
                },
                changesMade: false,
                versionNumber,
                isReadonly: true,
                dataActionToolbar: actionList
            };
            dispatch(addTab(newTab));
            dispatch(hideModal());
        });
}

export const undoAgreementChangesThunk = (
    dataSource: string,
    agreementID: number,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(undoAgreementChangesRequest());
        dispatch(showLoading());
        return SearchRequests.getAgreement(dataSource, agreementID)
            .then((agreement: IAgreement) => {
                dispatch(undoAgreementChangesSuccess(agreement));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(undoAgreementChangesFailure(err));
                dispatch(hideModal());
            });
    };
};



export const undoIPChangesThunk = (
    ipBaseNumber?: string,
    accountNumber?: string
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(undoIPChangesRequest());
        dispatch(showLoading());
        return SearchRequests.getIP(ipBaseNumber, accountNumber)
            .then((ip: IInterestedParty) => {
                dispatch(undoIPChangesSuccess(ip));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(undoIPChangesFailure(err));
                dispatch(hideModal());
            });
    };
};


export const undoPaymentRunChangesThunk = (
    paymentRunId: number,
    lookups: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(undoPaymentRunChangesRequest());
        dispatch(showLoading());
        return SearchRequests.getPaymentRun(paymentRunId)
            .then((paymentRun: IPaymentRun) => {
                dispatch(undoPaymentRunChangesSuccess(paymentRun, lookups));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(undoPaymentRunChangesFailure(err));
                dispatch(hideModal());
            });
    };
};

export const deleteAccountThunk = (ipBaseNumber: string, account: string, activeTab: number): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(deleteAccountRequest());
        dispatch(showLoading());
        return RepertoireService.deleteAccount(ipBaseNumber, account)
            .then(result => {
                const resultIp: IIPSaveResult = result.data
                if (result.status !== 200 || !resultIp.success) {
                    dispatch(deleteAccountRequestFailure(resultIp.messages));
                }
                else {
                    dispatch(deleteAccountRequestSuccess(resultIp));
                    if (resultIp.interestedParty === null)
                        dispatch(removeTab(activeTab))
                }
            })
            .finally(() => {
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(deleteAccountRequestFailure(err));
                dispatch(hideModal());
            });
    }
};

export const mergeWorkThunk = (
    work: IWorkState,
    matchingWorkIds: number,
    mergeOption: ManualMergeOptions,
    lookups: ILookupDictionary,
    dataActions: IDataActionToolbar[] = [],
    mergeIntoMatch: boolean,
    customer: string,
    paneIndex?: number
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(saveWorkRequest());
        dispatch(showLoading());

        let test1: string[];
        let test2: string[];
        SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_WRITER_CONTRIBUTOR_TYPES, INGESTION_GROUP)
            .then((writersContributorTypes: string[]) => {
                test1 = writersContributorTypes;
                SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_PUBLISHER_CONTRIBUTOR_TYPES, INGESTION_GROUP)
                    .then((publisherContributorTypes: string[]) => {
                        test2 = publisherContributorTypes;

                    })

            })

        if (WorkValidator.validateWork(work, test1, test2).length === 0) {
            return RepertoireService.mergeWork(WorkDetailsMapper.mapWorkStateToWork(work, lookups, false, customer), matchingWorkIds, mergeOption, mergeIntoMatch)
                .then(res => {
                    const result: IWorkSaveResult = res.data;
                    if (res.status !== 200 || !result.success) {
                        if (mergeIntoMatch == true) {
                            dispatch(mergeMatchingWorkFailure(result, lookups, paneIndex));
                        }
                        else {
                            dispatch(mergeRepertoireWorkFailure(result, lookups, paneIndex));
                        }
                    }
                    else {
                        if (mergeIntoMatch == true) {
                            dispatch(mergeMatchingWorkSuccess(result, lookups, dataActions, paneIndex));
                        }
                        else {
                            dispatch(mergeRepertoireWorkSuccess(result, lookups, dataActions, paneIndex));
                        }
                    }
                })
                .finally(() => {
                    dispatch(hideModal());
                })
                .catch((error: any) => {
                    dispatch(mergeMatchingWorkFailure(error, lookups, paneIndex));
                    dispatch(hideModal());
                });
        } else {
            const result: IWorkSaveResult = {
                saved: false,
                success: false,
                validationSuccess: false,
                messages: WorkValidator.validateWork(work, test1, test2),
                work: WorkDetailsMapper.mapWorkStateToWork(work, lookups, false, customer)
            }
            dispatch(mergeMatchingWorkFailure(result, lookups, paneIndex));
            dispatch(hideModal());
        }
    };
};

export const mergeProductThunk = (
    product: IProductState,
    matchingProductId: number,
    mergeOption: ManualMergeOptions,
    lookups: ILookupDictionary,
    dataActions: IDataActionToolbar[] = [],
    mergeIntoMatch: boolean,
    paneIndex: number,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(saveProductRequest());
        dispatch(showLoading());

        return RepertoireService.mergeProduct(ProductDetailsMapper.mapStateToProduct(product), matchingProductId, mergeOption, mergeIntoMatch)
            .then(async res => {
                const result: IProductSaveResult = res.data;
                if (res.status !== 200 || !result.success) {
                    if (mergeIntoMatch == true) {
                        dispatch(mergeMatchingProductFailure(result, lookups));
                    }
                    else {
                        dispatch(mergeRepertoireProductFailure(result, lookups));
                    }
                }
                else {
                    if (res.data.product.productWorks && res.data.product.productWorks.length > 0) {
                        const productWorks: IProductWork[] = [];

                        const intrayWorkIDs: number[] = res.data.product.productWorks.filter(pw => pw.workDataSource == "Intray").map(pw => pw.workCode)
                        const repertoireWorkIDs: number[] = res.data.product.productWorks.filter(pw => pw.workDataSource == "Repertoire").map(pw => pw.workCode)

                        if (intrayWorkIDs.length > 0) {
                            let hydratedWorks = hydrateProductWorksFromIndex(intrayWorkIDs, 'Intray', res.data.product.productWorks);
                            (await hydratedWorks).forEach(w => productWorks.push(w))
                        }

                        if (repertoireWorkIDs.length > 0) {
                            let hydratedWorks = hydrateProductWorksFromIndex(repertoireWorkIDs, 'Repertoire', res.data.product.productWorks);
                            (await hydratedWorks).forEach(w => productWorks.push(w))
                        }
                        res.data.product.productWorks = productWorks;
                    }
                    if (mergeIntoMatch == true) {
                        dispatch(mergeMatchingProductSuccess(result, lookups, dataActions, paneIndex));
                    }
                    else {
                        dispatch(mergeRepertoireProductSuccess(result, lookups, dataActions, paneIndex));
                    }
                }
            })
            .finally(() => {
                dispatch(hideModal());
            })
            .catch((error: any) => {
                dispatch(mergeMatchingProductFailure(error, lookups, paneIndex));
                dispatch(hideModal());
            });

    };
};

export const mergeIPThunk = (
    fromIP: IInterestedParty,
    toIP: IInterestedParty,
    ipMaintenanceGeneralDataViewData: IRepertoireComponentDataItem,
    lookups: ILookupDictionary,
    dataActions: IDataActionToolbar[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(saveWorkRequest());
        dispatch(showLoading());

        let MergeIP = {
            fromIP: fromIP,
            toIP: toIP
        }
        if (IntertestedPartyValidator.validateIntertestedParty(fromIP, toIP, ipMaintenanceGeneralDataViewData).length === 0) {
            return RepertoireService.mergeInterestedParty(MergeIP)
                .then(res => {
                    const result: IInterestedParty = res.data;
                    if (res.status !== 200) {
                        dispatch(mergeMatchingIPFailure(result, lookups));
                    }
                    else {
                        dispatch(mergeMatchingIPSuccess(result, lookups, dataActions));
                    }
                })
                .finally(() => {
                    dispatch(hideModal());
                })
                .catch((error: any) => {
                    dispatch(mergeMatchingIPFailure(error, lookups));
                    dispatch(hideModal());
                });
        } else {
            const result: IInterestedParty = null;
            dispatch(mergeMatchingIPFailure(result, lookups));
            dispatch(hideModal());
        }
    };
};

export const updateIpAgreementsFilterField = (value: any, field: IAgreementSearchStateKeys): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(updateIpAgreementsFilterStateField(value, field));
    }
}

export const filterIpAgreementsThunk = (searchQuery: IAgreementSearchState, ipBaseNumber?: string, accountNumber?: string, creationClasses?: string[]): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(filterIpAgreementsRequest());
        dispatch(showLoading());

        return RepertoireService.filterIpAgreements(searchQuery, ipBaseNumber, accountNumber, creationClasses)
            .then((res: IIPAgreement[]) => {
                dispatch(filterIpAgreementsSuccess(res));
                dispatch(hideModal());
            })
            .catch((error: any) => {
                dispatch(hideModal());
            });
    };
};

export const getDownloadFileFormatThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        return SearchRequests.getConfigurationParameter(MY_PACKAGE_MAINTENANCE_KEY,MY_PACKAGE_MAINTENANCE_GROUP)
            .then((res: IMyPackagesDownloadFileFormat[]) => {
                dispatch(getDownloadFileFormatSuccess(res));
            })
    }
}

export const removePaneTwoThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(removePaneTwo());
    }
}

export const filterIpRepresentationThunk = (searchQuery: IRepresentationSearchState, ipBaseNumber?: string, accountNumber?: string, creationClasses?: string[], role?: string): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(filterIpRepresentationsRequest());
        dispatch(showLoading());

        return RepertoireService.filterIpRepresentations(searchQuery, ipBaseNumber, accountNumber, creationClasses, role)
            .then((res: IIPRepresentation[]) => {

                res.map((representation: IIPRepresentation) => {
                    representation.includeTerritories = { value: InterestedPartyDetailsMapper.mapIncludeTerritory(representation.applicableTerritories), inputType: TEXT_INPUT };
                    representation.excludeTerritories = { value: InterestedPartyDetailsMapper.mapExcludeTerritory(representation.applicableTerritories), inputType: TEXT_INPUT };

                    representation.includePools = { value: InterestedPartyDetailsMapper.mapIncludePool(representation.applicablePools), inputType: TEXT_INPUT };
                    representation.excludePools = { value: InterestedPartyDetailsMapper.mapExcludePool(representation.applicablePools), inputType: TEXT_INPUT };
                });

                dispatch(filterIpRepresentationsSuccess(res));
                dispatch(hideModal());
            })
            .catch((error: any) => {
                dispatch(hideModal());
            });
    };
};

export const getContributorDisplaySettingsThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getContributorsDisplaySettingRequest());
        return SearchRequests.getConfigurationParameter(DISPLAY_CONTRIBUTORS_COLUMNS_KEY, WORK_MAINTENANCE_GROUP)
            .then((res: IContributorSiteConfiguration[]) => {
                dispatch(getContributorsDisplaySettingSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getContributorsDisplaySettingFailure(error));
            });
    }
}

export const getGenreCategoriesThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getGenreCategoriesRequest());
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_GENRE_CATEGORY_FROM_WORK_TYPE, WORK_MAINTENANCE_GROUP)
            .then((res: IGenreCategoriesConfiguration) => {
                dispatch(getGenreCategoriesSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getGenreCategoriesFailure(error));
            });
    }
}

export const getGenreCategoriesByDomOrForeignThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getGenreCategoriesByDomOrForeignRequest());
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_GENRE_CATEGORY_DEFAULTS_TYPES, WORK_MAINTENANCE_GROUP)
            .then((res: IGenereCategoryDefaultsConfiguration) => {
                dispatch(getGenreCategoriesByDomOrForeignSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getGenreCategoriesByDomOrForeignFailure(error));
            });
    }
}

export const getFilterGenreCategoriesThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getFilterGenreCategoriesRequest());
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FILTER_GENRE_CATEGORY, WORK_MAINTENANCE_GROUP)
            .then((res: boolean) => {
                dispatch(getFilterGenreCategoriesSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getFilterGenreCategoriesFailure(error));
            });
    }
}

export const getReadonlyFlagsFieldThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getReadonlyFlagsFieldRequest());
        return SearchRequests.getConfigurationParameter(READONLY_FLAGS_FIELD_KEY, WORK_MAINTENANCE_GROUP)
            .then((res: IReadonlyFlagsField[]) => {
                dispatch(getReadonlyFlagsFieldSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getReadonlyFlagsFieldFailure(error));
            });
    }
}

export const getOtherIndicatorsWorkFlagTypesThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getOtherIndicatorsWorkFlagTypesRequest());
        return SearchRequests.getConfigurationParameter(WORK_FLAG_KEY, WORK_MAINTENANCE_GROUP)
            .then((res: string[]) => {
                dispatch(getOtherIndicatorsWorkFlagTypesSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getOtherIndicatorsWorkFlagTypesFailure(error));
            });
    }
}

export const getReadonlyIndicatorsWorkFlagTypesThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getReadonlyIndicatorsWorkFlagTypesRequest());
        return SearchRequests.getConfigurationParameter(READONLY_WORK_FLAG_KEY, WORK_MAINTENANCE_GROUP)
            .then((res: string[]) => {
                dispatch(getReadonlyIndicatorsWorkFlagTypesSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getReadonlyIndicatorsWorkFlagTypesFailure(error));
            });
    }
}

export const getAgreementShareDisplayOptionsThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getAgreementDisplayOptionsRequest());
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_AGREEMENT_SHARE_DISPLAY_KEY, WORK_MAINTENANCE_GROUP)
            .then((res: IAgreementShareDisplayConfiguration) => {
                dispatch(getAgreementDisplayOptionsSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getAgreementDisplayOptionsFailure(error));
            });
    }
}

export const getShowCommentSubjectAsDropdownConfigurationParameterThunk = (group: string): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getShowCommentSubjectAsDropdownConfigurationParameterRequest());
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_SHOW_COMMENT_SUBJECT_AS_DROPDOWN_KEY, group)
            .then((res: boolean) => {
                dispatch(getShowCommentSubjectAsDropdownConfigurationParameterSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getShowCommentSubjectAsDropdownConfigurationParameterFailure(error));
            });
    }
}

export const getIPMaintenaceDisplaySettingsThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getIPMaintenanceDisplaySettingsRequest());
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_GET_UI_CONFIGURATION, INTERESTED_PARTY_MAINTENANCE_GROUP)
            .then((res: IIPMaintenancePageUIConfiguration) => {
                dispatch(getIPMaintenanceDisplaySettingsSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getIPMaintenanceDisplaySettingsFailure(error));
            });
    }
}
export const getRecalculateDomesticorForeignThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(GetDomesticorForeignFlagRequest())
        return SearchRequests.getConfigurationParameter(READONLY_FLAG_DOMESTIC_OR_FOREIGN, WORK_MAINTENANCE_GROUP)
            .then((res: string) => {
                dispatch(GetDomesticorForeignFlagSuccess(res == "1" ? true : false))
            })
            .catch((error: any) => {
                dispatch(GetDomesticorForeignFlagFailure(error))
            })
    }
}

export const updateComponentFieldItemsThunk = (
    fields: IRepertoireField[],
    componentName: string,
    componentInstance: string,
    componentDataFieldName: string,
    tabs: ITabReduxItem[],
    activeTab: number,
): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {

    return (dispatch: Dispatch) => {
        dispatch(showLoading());
        dispatch(updateFieldComponentRequest());
        return ComponentFields.updateComponentFieldItem(fields, componentName, componentInstance)
            .then((res: IRepertoireField[]) => {
                if (res) {
                    dispatch(updateFieldComponentRequestSuccess(res, componentDataFieldName, componentInstance));
                    // remove tab and add it back in to force components to re-mount and reload ViewData
                    dispatch(removeTab(activeTab));
                    dispatch(addTab(tabs[activeTab], true));
                    dispatch(hideModal());
                }
            })
            .catch(error => {
                updateFieldComponentRequestFailure(error);
            });
    }
}

export const getFieldFormatsThunk = (key: string, group: string): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getFormatFieldsRequest());
        return SearchRequests.getConfigurationParameter(key, group)
            .then((res: FormatFields[]) => {
                dispatch(getFormatFieldsSuccess(res));
                if (group === WORK_MAINTENANCE_GROUP) {
                    dispatch(applyFormatsWorkMaintenance());
                }
                else if (group === PRODUCT_MAINTENANCE_GROUP) {
                    dispatch(applyFormatsProductMaintenance());
                }
            })
            .catch((error: any) => {
                dispatch(getContributorsDisplaySettingFailure(error));
            });
    }
}



export const removeWorkAttachmentIfExistsThunk = (attachedFile: IAttachedFile, source: DataSource): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(removeWorkAttachmentRequest());
        const model: IAddWorkAttachmentModel = {
            attachedFile: attachedFile,
            dataSource: source
        }
        return RepertoireService.removeWorkAttachment(model)
            .then((res: boolean) => {
                if (res) {
                    dispatch(removeWorkAttachmentSuccess(model.attachedFile));
                }
            })
            .catch((error: any) => {
                dispatch(removeWorkAttachmentFailure(error));
            });
    }
}


export const deleteBlobFileThunk = (fullName: string, name: string): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        const model: IRemoveBlobModel = {
            fullPath: fullName,
            filename: name
        }
        return RepertoireService.removeUsageInputBlob(model)
            .then((res: boolean) => {
                if (res) {
                    dispatch(deleteBlobSuccess(res));
                }
            })
            .catch((error: any) => {
                dispatch(deleteBlobFailure(error));
            });
    }
}

export const removeProductAttachmentIfExistsThunk = (attachedFile: IAttachedFile, source: DataSource): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(removeProductAttachmentRequest());
        const model: IAddProductAttachmentModel = {
            attachedFile: attachedFile,
            dataSource: source
        }
        return RepertoireService.removeProductAttachment(model)
            .then((res: boolean) => {
                if (res) {
                    dispatch(removeProductAttachmentSuccess(model.attachedFile));
                }
            })
            .catch((error: any) => {
                dispatch(removeProductAttachmentFailure(error));
            });
    }
}

export const removeUsageAttachmentIfExistsThunk = (attachedFile: IAttachedFile): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(removeUsageAttachmentRequest());
        const model: IAddUsageAttachmentModel = {
            attachedFile,
        }
        return RepertoireService.removeUsageAttachment(model)
            .then((res: boolean) => {
                if (res) {
                    dispatch(removeUsageAttachmentSuccess(model.attachedFile))
                }
            })
            .catch((error: any) => {
                dispatch(removeUsageAttachmentFailure(error))
            })
    }
}

export const searchWorkflowThunk = (
    searchBody: IWorkflowSearchQuery,
    modalOpen: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchWorkflowRequest());
        dispatch(showLoading());
        return SearchRequests.getWorkflows(searchBody)
            .then((res: IWorkflowSearchResult[]) => {
                dispatch(searchWorkflowSuccess(res, searchBody));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(searchWorksFailure(err));
            });
    };
};

export const assignWorkflowsThunk = (model: IAssignWorkflowModel, searchBody: IWorkflowSearchQuery)
    : ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(assignWorkflowsRequest());
        dispatch(showLoading());
        return RepertoireService.assignWorkflows(model)
            .then((res: boolean) => {
                dispatch(assignWorkflowsSuccess(res));
                return SearchRequests.getWorkflows(searchBody)
                    .then((res: IWorkflowSearchResult[]) => {
                        dispatch(searchWorkflowSuccess(res, searchBody));
                        dispatch(markSelectAll(false));
                        dispatch(hideModal());
                    })
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(assignWorkflowsFailure(err));
            });
    };
};

export const updateStatusWorkflowsThunk = (model: IAssignWorkflowModel, searchBody: IWorkflowSearchQuery)
    : ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(updateStatusWorkflowsRequest());
        dispatch(showLoading());
        return RepertoireService.assignWorkflows(model)
            .then((res: boolean) => {
                dispatch(updateStatusWorkflowsSuccess(res));
                return SearchRequests.getWorkflows(searchBody)
                    .then((res: IWorkflowSearchResult[]) => {
                        dispatch(searchWorkflowSuccess(res, searchBody));
                        dispatch(markSelectAll(false));
                        dispatch(hideModal());
                    })
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(updateStatusWorkflowsFailure(err));
            });
    };
};

export const getUsersThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getUsersRequest());
        return LookupService.getUsers()
            .then((res: string[]) => {
                dispatch(getUsersSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getUsersFailure(error));
            });
    };
};

export const getShareDecimalsToDisplayThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getShareDecimalsToDisplayRequest());
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_SHARE_DECIMALS_TO_DISPLAY, WORK_MAINTENANCE_GROUP)
            .then((res: number) => {
                dispatch(getShareDecimalsToDisplaySuccess(res));
            })
            .catch((error: any) => {
                dispatch(getShareDecimalsToDisplayFailure(error));
            });
    }
}

export const getUsageGridSearchResultsDisplayOptions = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getUsageGridSearchResultsDisplayOptionsRequest())
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_USAGE_GRID_SEARCH_RESULT_DISPLAY_OPTIONS, USAGE_MAINTENANCE_GROUP)
            .then((res: boolean) => {
                dispatch(getUsageGridSearchResultsDisplayOptionsSuccess(res))
            })
            .catch((error: any) => {
                dispatch(getUsageGridSearchResultsDisplayOptionsFailure(error))
            })
    }
}

export const getIPGridSearchResultsDisplayOptions = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getIPGridSearchResultsDisplayOptionsRequest())
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_IP_GRID_SEARCH_RESULT_DISPLAY_OPTIONS, INTERESTED_PARTY_MAINTENANCE_GROUP)
            .then((res: string) => {
                dispatch(getIPGridSearchResultsDisplayOptionsSuccess(res === "1" ? true : false))
            })
            .catch((error: any) => {
                dispatch(getIPGridSearchResultsDisplayOptionsFailure(error))
            })
    }
}

export const getProductMaintenanceEnableCuesheets = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getProductMaintenanceEnableCuesheetsRequest())
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_ENABLE_COMMON_CUESHEETS, PRODUCT_MAINTENANCE_GROUP)
            .then((res: string) => {
                dispatch(getProductMaintenanceEnableCuesheetsSuccess(res == "1" ? true : false))
            })
            .catch((error: any) => {
                dispatch(getProductMaintenanceEnableCuesheetsFailure(error))
            })
    }
}
export const getProductMaintenanceCuesheetsDataSource = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getProductMaintenanceCuesheetsDataSourceRequest())
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_CUESHEETS_DATASOURCES, PRODUCT_MAINTENANCE_GROUP)
            .then((res: any) => {
                dispatch(getProductMaintenanceCuesheetsDataSourceSuccess(res))
            })
            .catch((error: any) => {
                dispatch(getProductMaintenanceCuesheetsDataSourceFailure(error))
            })
    }
}


export const getPDArrangementMusicLyricOptions = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getPDArrangementMusicLyricRequest())
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_PD_ARRANGEMENT_MUSIC_LYRIC_OPTIONS, IPI_CONFIGURATION_GROUP)
            .then((res: any) => {
                dispatch(getPDArrangementMusicLyricSuccess(res === 1 ? true : false))
            })
            .catch((error: any) => {
                dispatch(getPDArrangementMusicLyricFailure(error))
            })
    }
}

export const getWorkMaintenanceGeneralViewDisplayOptions = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getWorkMaintenanceGeneralViewDisplayOptionsRequest())
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_WORK_GENERAL_VIEW_DISPLAY_OPTIONS, USAGE_MAINTENANCE_GROUP)
            .then((res: boolean) => {
                dispatch(getWorkMaintenanceGeneralViewDisplayOptionsSuccess(res))
            })
            .catch((error: any) => {
                dispatch(getWorkMaintenanceGeneralViewDisplayOptionsFailure(error))
            })
    }
}

export const getProductMaintenanceGeneralViewDisplayOptions = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getProductMaintenanceGeneralViewDisplayOptionsRequest())
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_PRODUCT_GENERAL_VIEW_DISPLAY_OPTIONS, USAGE_MAINTENANCE_GROUP)
            .then((res: boolean) => {
                dispatch(getProductMaintenanceGeneralViewDisplayOptionsSuccess(res))
            })
            .catch((error: any) => {
                dispatch(getProductMaintenanceGeneralViewDisplayOptionsFailure(error))
            })
    }
}

export const getPoolMaintenanceGeneralViewDisplayOptions = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getPoolMaintenanceGeneralViewDisplayOptionsRequest())
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_POOL_GENERAL_VIEW_DISPLAY_OPTIONS, USAGE_MAINTENANCE_GROUP)
            .then((res: boolean) => {
                dispatch(getPoolMaintenanceGeneralViewDisplayOptionsSuccess(res))
            })
            .catch((error: any) => {
                dispatch(getPoolMaintenanceGeneralViewDisplayOptionsFailure(error))
            })
    }
}

export const getShareToleranceSettings = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getShareToleranceSettingsRequest())
        return SearchRequests.getConfigurationParameter(SHARE_TOLERANCE_FIELD_KEY, WORK_MAINTENANCE_GROUP)
            .then((res: IShareToleranceValueConfiguration) => {
                dispatch(getShareToleranceSettingsSuccess(res))
            })
            .catch((error: any) => {
                dispatch(getShareToleranceSettingsFailure(error))
            })
    }
}

export const getDistributionMaintenanceSettingsDisplayOptions = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getDistributionMaintenanceSettingsViewDisplayOptionsRequest())
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_DISTRIBUTION_SETTINGS_VIEW_DISPLAY_OPTIONS, DISTRIBUTION_MAINTENANCE_GROUP)
            .then((res: string) => {
                dispatch(getDistributionMaintenanceSettingsDisplayOptionsSuccess(res))
            })
            .catch((error: any) => {
                dispatch(getDistributionMaintenanceSettingsDisplayOptionsFailure(error))
            })
    }
}

export const getUsageMatchingDefaultsProducts = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getUsageMatchingDefaultsProductsRequest())
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_USAGE_MATCHING_DEFAULTS_PRODUCTS, USAGE_MAINTENANCE_GROUP)
            .then((res: string[]) => {
                dispatch(getUsageMatchingDefaultsProductsSuccess(res))
            })
            .catch((error: any) => {
                dispatch(getUsageMatchingDefaultsProductsFailure(error))
            })
    }
}

export const getUsageMatchingDefaultsWorks = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getUsageMatchingDefaultsWorksRequest())
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_USAGE_MATCHING_DEFAULTS_WORKS, USAGE_MAINTENANCE_GROUP)
            .then((res: string[]) => {
                dispatch(getUsageMatchingDefaultsWorksSuccess(res))
            })
            .catch((error: any) => {
                dispatch(getUsageMatchingDefaultsWorksFailure(error))
            })
    }
}

export const getProductMaintenanceSubmissionConfigThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getProductMaintenanceSubmissionConfigRequest());
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PRODUCT_MAINTENANCE_SUBMISSION_KEY, PRODUCT_MAINTENANCE_GROUP)
            .then((res) => {
                dispatch(getProductMaintenanceSubmissionConfigSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getProductMaintenanceSubmissionConfigFailure());
            });
    }
}

export const skipWorkflowInSessionThunk = (
    activeTab: number
    , currentWorkflowIndex: number
    , workflows: IWorkflowSearchResult[]
    , lookups: ILookupDictionary
    , otherIndicatorsWorkFlagTypes: string[]
    , dataActions: IDataActionToolbar[] = []
    , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
    , formats: FormatFields[]
    , readonlyIndicatorsWorkFlagTypes: string[]
    , replaceTab: boolean = true
): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading());
        if (replaceTab)
            dispatch(removeTab(activeTab))
        dispatch(nextWorkflowInSession());
        dispatch(getWorkDetailsRequest());
        if (workflows[currentWorkflowIndex]) {
            const item = workflows[currentWorkflowIndex];
            return SearchRequests.getWork(item.setType, parseInt(item.entityID))
                .then((res: IWork) => {
                    dispatch(getWorkDetailsSuccess(res));
                    let newWorkTab: ITabReduxItem = {
                        title: WorkDetailsMapper.getOriginalTitleFromWorkNames(res.workNames),
                        component: WORK_MAINTENANCE_PAGE.component,
                        workMaintenanceState: {
                            loaded: false,
                            workBatchOperationInProgress: false,
                            work: WorkDetailsMapper.mapWorkToWorkState(res, lookups, otherIndicatorsWorkFlagTypes, readonlyIndicatorsWorkFlagTypes),
                            contributorInheritanceChanged: false,
                            isNew: false
                        },
                        changesMade: false,
                        dataActionToolbar: dataActions,
                        isReadonly: false,
                        formatFields: formats,
                        isEntityForWorkflowSession: true
                    };
                    dispatch(addTab(newWorkTab));
                    dispatch(hideModal());
                })
                .catch(err => {
                    dispatch(hideModal());
                    dispatch(getWorkDetailsFailure(err, parseInt(item.entityID)));

                    dispatch<any>(skipWorkflowInSessionThunk(activeTab, currentWorkflowIndex + 1, workflows, lookups, otherIndicatorsWorkFlagTypes, dataActions, workMaintenanceGeneralDataViewData, formats, readonlyIndicatorsWorkFlagTypes, replaceTab));
                    if (err === 404) {
                        dispatch(showModal(WORK_NOT_FOUND_VIEW, null, item.entityID, true, "Work not found"));
                    }
                })
        }
        else {
            dispatch(markSelectAll(false));
            dispatch(cancelWorkflowSession());
            dispatch(hideModal());
        }
    }
}

export const cancelWorkflowSessionThunk = (activeTab: number): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading());
        dispatch(cancelWorkflowSession());
        dispatch(removeTab(activeTab));
        dispatch(hideModal());
    }
}

//USAGES
export const searchUsagesThunk = (
    searchBody: IUsagesSearchQuery,
    modalOpen: boolean,
    usageType: string,
    sources: ITreeData[],
    continuationToken?: string,
    isHundredResultsPerPage?: boolean,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchUsageGroupRequest(continuationToken));
        if (!modalOpen) {
            dispatch(showLoading());
        }
        return UsageSearchRequests.getUsages(searchBody, sources, continuationToken)
            .then(async (res: IUsageGroupsSearchResult) => {

                let initialUsageGroupSearchResult: IUsageGroupsSearchResult = {
                    usageGroups: res.usageGroups,
                    continuationToken: continuationToken
                }

                if (usageType === USAGETOWORK) {
                    await UsageSearchRequests.searchMatchingWorks(initialUsageGroupSearchResult, searchBody, dispatch, modalOpen, res.continuationToken);
                }
                else if (usageType === USAGETOPRODUCT) {
                    await UsageSearchRequests.searchMatchingProducts(initialUsageGroupSearchResult, searchBody, dispatch, modalOpen, res.continuationToken);
                }
                dispatch(searchUsageGroupSuccess(res, searchBody, res.continuationToken, isHundredResultsPerPage));
                if (!modalOpen)
                    dispatch(hideModal());

            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(searchUsageGroupFailure(err));
            });
    };
};

export const updateUsageGroupMatchesThunk = (
    usageGroup: IUsageSearchResultRowType

) => {

}

export const getUsageMaintenanceDataThunk = (
    usageID: string,
    openEntityForWorflowSession?: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getUsageRequest());
        dispatch(showLoading());
        return UsageSearchRequests.getUsage(usageID)
            .then(async (res: IUsageGroup) => {
                const matchingWorks: IMatchingWorksRowData[] = [];
                const matchingProducts: IMatchingProductsRowData[] = [];

                if (res.matches && res.matches.length > 0 && res.usageType === "UsageToWork") {

                    const workSearchQuery: IMatchWorksSearchQuery =
                    {
                        workIDs: res.matches.map(mw => mw.id),
                        title: "",
                        number: "",
                        artist: "",
                        writers: "",
                        societyAccountNumber: "",
                        workBatchID: "",
                        dataSource: REPERTOIRE,
                        hasOpenWorkflow: false
                    };

                    await UsageSearchRequests.getWorks(workSearchQuery)
                        .then((result: IMatchWorksSearchResult[]) => {
                            result.forEach((w) => {
                                const match: IUsageMatch = res.matches.find(x => x.id === w.workID);

                                const matchingWork: IMatchingWorksRowData = {
                                    status: startCase(match.matchType),
                                    title: w.title,
                                    composers: w.publishers.concat(w.writers),
                                    performers: w.artists,
                                    number: w.number[0],
                                    source: startCase(match.matchSource),
                                    workID: match.id
                                }
                                matchingWorks.push(matchingWork);
                            });
                        });
                }
                else if (res.matches && res.matches.length > 0 && res.usageType === "UsageToProduct") {

                    const searchQuery: IMatchProductsSearchQuery =
                    {
                        productIDs: res.matches.map(mw => mw.id),
                        title: "",
                        number: "",
                        artist: "",
                        contributor: "",
                        productBatchID: "",
                        productType: "",
                        dataSource: REPERTOIRE,
                    };

                    await UsageSearchRequests.getProducts(searchQuery)
                        .then((result: IProductSearchResult[]) => {
                            result.forEach((w) => {
                                const match: IUsageMatch = res.matches.find(x => x.id === w.productCoreID);

                                const matchingProduct: IMatchingProductsRowData = {
                                    status: startCase(match.matchType),
                                    id1: w.id1,
                                    id2: w.id2,
                                    artist: w.artist,
                                    contributor: w.contributor,
                                    productCoreID: w.productCoreID,
                                    source: startCase(match.matchSource),
                                    title: w.title,
                                    type: w.type,
                                    number: w.number,
                                    productionType:w.productionType,
                                    musicDuration:w.musicDuration
                                }
                                matchingProducts.push(matchingProduct);
                            });
                        });
                }

                dispatch(getUsageSuccess(res));
                let newUsageTab: ITabReduxItem = {
                    title: UsageDetailsMapper.getUsageTitle(res),
                    component: USAGE_MAINTENANCE_PAGE.component,
                    usageMaintenanceState: {
                        dataSource: DataSource.Repertoire,
                        loaded: false,
                        usageGroup: UsageDetailsMapper.mapUsageToState(res, matchingWorks.length > 0 ? matchingWorks : undefined, matchingProducts.length > 0 ? matchingProducts : undefined)
                    },
                    changesMade: false,
                    isEntityForWorkflowSession: openEntityForWorflowSession
                }
                dispatch(addTab(newUsageTab));
                dispatch(hideModal());
            }).catch(err => {
                dispatch(hideModal());
                dispatch(getUsageFailure(err));
                dispatch(showModal(WORK_NOT_FOUND_VIEW, null, usageID, true, "Usage not found"));
            });
    }
}

export const getDistributionsThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        dispatch(getUsageDistributionsRequest());
        const customer = getState().account.customer;
        return UsageService.getDistributions()
            .then((res: IDistributionState[]) => {
                dispatch(getUsageDistributionsSuccess(res, customer));
            })
            .catch((error: any) => {
                dispatch(getUsageDistributionsFailure(error));
            });
    };
};

export const getPoolsThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getUsagePoolsRequest());
        return UsageService.getPools()
            .then((res: IUsagePoolState[]) => {
                dispatch(getUsagePoolsSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getUsagePoolsFailure(error));
            });
    };
};


export const getSourcesThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getSourcesRequest());
        return SourceService.getSourcesForAllMatchTypes()
            .then((res: Dictionary<ISourceMatchType>) => {
                dispatch(getSourcesSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getSourcesFailure(error));
            });
    };
};

export const getSourceMajorsThunk = (
    matchType: string,
    level: number
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getSourceMajorsRequest());
        return UsageService.getSources(matchType, level)
            .then((res: string[]) => {
                dispatch(getSourceMajorsSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getSourceMajorsFailure(error));
            });
    };
};

export const getSourceMinorsThunk = (
    matchType: string,
    level: number
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getSourceMinorsRequest());
        return UsageService.getSources(matchType, level)
            .then((res: string[]) => {
                dispatch(getSourceMinorsSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getSourceMinorsFailure(error));
            });
    };
};

export const skipUsageWorkflowInSessionThunk = (
    activeTab: number
    , currentWorkflowIndex: number
    , workflows: IWorkflowSearchResult[]
): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading());
        dispatch(removeTab(activeTab))
        dispatch(nextWorkflowInSession());
        dispatch(getUsageRequest());
        if (workflows[currentWorkflowIndex]) {
            const item = workflows[currentWorkflowIndex];
            return UsageSearchRequests.getUsage(item.entityID.toString())
                .then(async (res: IUsageGroup) => {
                    const matchingWorks: IMatchingWorksRowData[] = [];
                    const matchingProducts: IMatchingProductsRowData[] = [];

                    if (res.matches && res.matches.length > 0 && res.usageType === "UsageToWork") {

                        const workSearchQuery: IMatchWorksSearchQuery =
                        {
                            workIDs: res.matches.map(mw => mw.id),
                            title: "",
                            number: "",
                            artist: "",
                            writers: "",
                            societyAccountNumber: "",
                            workBatchID: "",
                            dataSource: REPERTOIRE,
                            hasOpenWorkflow: false
                        };

                        await UsageSearchRequests.getWorks(workSearchQuery)
                            .then((result: IMatchWorksSearchResult[]) => {
                                result.forEach((w) => {
                                    const match: IUsageMatch = res.matches.find(x => x.id === w.workID);

                                    const matchingWork: IMatchingWorksRowData = {
                                        status: startCase(match.matchType),
                                        title: w.title,
                                        composers: w.publishers.concat(w.writers),
                                        performers: w.artists,
                                        number: w.number[0],
                                        source: startCase(match.matchSource),
                                        workID: match.id
                                    }
                                    matchingWorks.push(matchingWork);
                                });
                            });
                    }
                    else if (res.matches && res.matches.length > 0 && res.usageType === "UsageToProduct") {

                        const searchQuery: IMatchProductsSearchQuery =
                        {
                            productIDs: res.matches.map(mw => mw.id),
                            title: "",
                            number: "",
                            artist: "",
                            contributor: "",
                            productBatchID: "",
                            productType: "",
                            dataSource: REPERTOIRE,
                        };

                        await UsageSearchRequests.getProducts(searchQuery)
                            .then((result: IProductSearchResult[]) => {
                                result.forEach((w) => {
                                    const match: IUsageMatch = res.matches.find(x => x.id === w.productCoreID);

                                    const matchingProduct: IMatchingProductsRowData = {
                                        status: startCase(match.matchType),
                                        id1: w.id1,
                                        id2: w.id2,
                                        artist: w.artist,
                                        contributor: w.contributor,
                                        productCoreID: w.productCoreID,
                                        source: startCase(match.matchSource),
                                        title: w.title,
                                        type: w.type,
                                        number: w.number,
                                        productionType:w.productionType,
                                        musicDuration:w.musicDuration
                                    }
                                    matchingProducts.push(matchingProduct);
                                });
                            });
                    }


                    dispatch(getUsageSuccess(res));
                    let newUsageTab: ITabReduxItem = {
                        title: UsageDetailsMapper.getUsageTitle(res),
                        component: USAGE_MAINTENANCE_PAGE.component,
                        usageMaintenanceState: {
                            dataSource: DataSource.Repertoire,
                            loaded: false,
                            usageGroup: UsageDetailsMapper.mapUsageToState(res, matchingWorks.length > 0 ? matchingWorks : undefined, matchingProducts.length > 0 ? matchingProducts : undefined),
                            isNew: false
                        },
                        changesMade: false,
                        isReadonly: false,
                        isEntityForWorkflowSession: true
                    };
                    dispatch(addTab(newUsageTab));
                    dispatch(hideModal());
                })
                .catch(err => {
                    dispatch(hideModal());
                    dispatch(getUsageFailure(err));
                    if (err === 404) {
                        dispatch(showModal(WORK_NOT_FOUND_VIEW, null, item.entityID, true, "Usage not found"));
                    }
                })
        }
        else {
            dispatch(markSelectAll(false));
            dispatch(cancelWorkflowSession());
            dispatch(hideModal());
        }
    }
}

export const saveAdditionalWorkNumbersThunk = (
    work: IWorkState,
    lookups: ILookupDictionary,
    customer: string,
    pageNumber: number
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading())
        RepertoireService.saveAdditionalWorkNumbers(WorkDetailsMapper.mapWorkStateToWork(work, lookups, false, customer), pageNumber)
            .then(res => {
                const result: IWork = res.data;
                if (res.status !== 200) {
                    dispatch(saveWorkNumbersAdditionalFailure(result, lookups));
                }
                else {
                    dispatch(saveWorkNumbersAdditionalSuccess(result, lookups));
                }
                dispatch(hideModal())
            })
    }
}

export const getCurrentPageDetailsThunk = (
    work: IWorkState,
    lookups: ILookupDictionary,
    pageNumber: number
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading())
        RepertoireService.getCurrentPageAdditionalWorkNumberDetails(work.workID, pageNumber)
            .then(res => {
                const result: IWork = res.data;
                if (res.status !== 200) {
                    dispatch(getCurrentPageAdditionalWorkNumberDetailsFailure(result, lookups));
                }
                else {
                    dispatch(getCurrentPageAdditionalWorkNumberDetailsSuccess(result, lookups));
                }
                dispatch(hideModal())
            })
    }
}

export const saveUsageGroupFromSearchResultsThunk = (
    updatedUsageGroup: IUsageSearchResultRowType
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(saveUsageGroupRequest());
        dispatch(showLoading())

        UsageService.saveUsageGroup(updatedUsageGroup.matchesExpanded, null, updatedUsageGroup.key.toString(), updatedUsageGroup.matchStatus, null, null, null, null, null, true)
            .then(res => {
                const result: IUsageGroupSaveResult = res.data;
                if (res.status !== 200 || !result.success) {
                    dispatch(saveUsageGroupFromSearchFailure(result));
                }
                else {
                    dispatch(saveUsageGroupFromSearchSuccess(result));
                }
                dispatch(hideModal())
            })
    }
}

export const saveUsageGroupThunk = (
    updatedUsageGroup: IUsageGroupState,
    isWorkflowSession: boolean = false,
    workflowsSessionItems?: IWorkflowSearchResult[],
    currentWorkflowIndex?: number,
    activeTab?: number,
    isOpenForWorkflowSession?: boolean,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(saveUsageGroupRequest());
        dispatch(showLoading());
        UsageService.saveUsageGroup(
            UsageDetailsMapper.mapStateToMatches(
                updatedUsageGroup.matchingWorks, updatedUsageGroup.matchingProducts
            ),
            updatedUsageGroup.workflows,
            updatedUsageGroup.id,
            updatedUsageGroup.matchStatus,
            UsageDetailsMapper.mapStateToUsages(updatedUsageGroup),
            updatedUsageGroup.location,
            updatedUsageGroup.setListAdditional,
            updatedUsageGroup.approvalStatus,
            updatedUsageGroup.venueDetails,
            false,
            updatedUsageGroup.qaStatus
        )
            .then(res => {
                const result: IUsageGroupSaveResult = res.data;
                if (res.status !== 200 || !result.success) {
                    dispatch(saveUsageGroupFailure(result));
                }
                else {
                    dispatch(saveUsageGroupSuccess(result));
                    if (isOpenForWorkflowSession) {
                        if (workflowsSessionItems[currentWorkflowIndex]) {
                            dispatch(removeTab(activeTab))
                            dispatch(nextWorkflowInSession());
                            dispatch(getUsageRequest());
                            const item = workflowsSessionItems[currentWorkflowIndex];
                            return UsageSearchRequests.getUsage(item.entityID.toString())
                                .then((res: IUsageGroup) => {
                                    dispatch(getUsageSuccess(res));
                                    let newUsageTab: ITabReduxItem = {
                                        title: UsageDetailsMapper.getUsageTitle(res),
                                        component: USAGE_MAINTENANCE_PAGE.component,
                                        usageMaintenanceState: {
                                            dataSource: DataSource.Repertoire,
                                            loaded: false,
                                            usageGroup: UsageDetailsMapper.mapUsageToState(res),
                                            isNew: false
                                        },
                                        changesMade: false,
                                        isReadonly: false,
                                        isEntityForWorkflowSession: isOpenForWorkflowSession
                                    };
                                    dispatch(addTab(newUsageTab));
                                    dispatch(hideModal());
                                })
                                .catch(err => {
                                    dispatch(hideModal());
                                    dispatch(getUsageFailure(err));
                                    if (err === 404) {
                                        dispatch(showModal(WORK_NOT_FOUND_VIEW, null, item.entityID, true, "Usage not found"));
                                    }
                                });
                        }
                        else {
                            dispatch(markSelectAll(false));
                            dispatch(cancelWorkflowSession());
                            dispatch(removeTab(activeTab));
                        }
                    }
                }
            })
            .finally(() => {
                dispatch(hideModal());
            })
            .catch((error: any) => {
                const errorMessages: IValidationMessage[] = [error];
                const result: IUsageGroupSaveResult = {
                    saved: false,
                    success: false,
                    messages: errorMessages,
                    usageGroup: UsageDetailsMapper.mapStateUsageGroup(updatedUsageGroup, false)
                }
                dispatch(saveUsageGroupFailure(result));
                dispatch(hideModal());
            });

    };
};

export const refreshUsageGroupDetailsThunk = (
    usageID: string,
    activeTabItem?: ITabReduxItem
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(refreshUsageDetailsRequest());
        dispatch(showLoading());
        return UsageSearchRequests.getUsage(usageID)
            .then((res: IUsageGroup) => {
                dispatch(refreshUsageDetailsSuccess(res));
                let tab: ITabReduxItem = {
                    ...activeTabItem,
                    title: UsageDetailsMapper.getUsageTitle(res),
                    usageMaintenanceState: {
                        ...activeTabItem.usagePoolMaintenanceState,
                        loaded: false,
                        usageGroup: UsageDetailsMapper.mapUsageToState(res),
                        isNew: false,
                        dataSource: DataSource.Repertoire
                    },
                    changesMade: false
                };
                dispatch(replaceTab(tab, activeTabItem.title))
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(refreshUsageDetailsFailure(err, usageID));
                if (err === 404) {
                    dispatch(showModal(WORK_NOT_FOUND_VIEW, null, usageID, true, "Usage not found"));
                }
            });


    };
};

export const refreshProductDetailsThunk = (
    dataSource: string,
    productCoreID: number,
    activeTabItem: ITabReduxItem,
    formats: FormatFields[],
    editableFields?: any[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(refreshProductDetailsRequest());
        dispatch(showLoading());
        return SearchRequests.getProduct(dataSource, productCoreID)
            .then((res: IProductCore) => {
                dispatch(refreshProductDetailsSuccess(res));
                let newProductTab: ITabReduxItem = {
                    title: ProductDetailsMapper.getOriginalTitleFromProductNames(res.productNames),
                    component: PRODUCT_MAINTENANCE_PAGE.component,
                    productMaintenanceState: {
                        ...activeTabItem.productMaintenanceState,
                        loaded: false,
                        product: ProductDetailsMapper.mapProductToState(res, formats),
                        productBatchOperationInProgress: false
                    },
                    changesMade: false,
                    editableFields: editableFields,
                    formatFields: formats
                };
                dispatch(replaceTab(newProductTab, activeTabItem.title))
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(getProductDetailsFailure(err, productCoreID));
                if (err === 404) {
                    dispatch(showModal(PRODUCT_NOT_FOUND_VIEW, null, productCoreID, true, "Product not Found"));
                }
            });
    };
};

export const updateUserPreferencesThunk = (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        let alteredResultsPerPage = allResultsPerPage.map(r => Object.assign({}, r));
        let changedRPP = alteredResultsPerPage.find(r => r.repertoireSection == repertoireSection);
        if (changedRPP) {
            changedRPP.indexOfFirstResult = indexOfFirstResult;
            changedRPP.indexOfLastResult = indexOfLastResult;
            changedRPP.resultsPerSection = resultsPerPage;
        }

        let alteredActiveAccordions = activeAccordions.map(r => Object.assign({}, r));
        if (newActiveAccordionName) {
            let changedAA = alteredActiveAccordions.find(a => a.componentName === componentName && a.fieldName === newActiveAccordionName);
            if (changedAA) {
                changedAA.isExpanded = accordionExpanded === true;
            } else {
                alteredActiveAccordions.push({
                    componentName: componentName,
                    fieldName: newActiveAccordionName,
                    isExpanded: accordionExpanded
                });
            }
        }

        dispatch(updateUserPreference(alteredActiveAccordions, alteredResultsPerPage));

        alteredResultsPerPage = allResultsPerPage.map(({ skip, ...keepAttrs }) => keepAttrs);
        return UserPreferencesService.update({
            activeAccordions: alteredActiveAccordions,
            resultsPerPage: alteredResultsPerPage
        });
    }
}

export const getUserPreferences = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getUserPreferenceRequest())
        return UserPreferencesService.get()
            .then(res => {
                dispatch(getUserPreferenceSuccess(res.resultsPerPage, res.activeAccordions));
            })
            .catch(error => {
                dispatch(getUserPreferenceFailure());
                console.error(error);
            })
    }
}

export const refreshAgreementDetailsThunk = (
    dataSource: string,
    agreementID: number,
    activeTabItem: ITabReduxItem,
    editableFields: any[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getAgreementDetailsRequest());
        dispatch(showLoading());
        return SearchRequests.getAgreement(dataSource, agreementID)
            .then((res: IAgreement) => {
                dispatch(getAgreementDetailsSuccess(res));
                let newAgreementTab: ITabReduxItem = {
                    ...activeTabItem,
                    title: AgreementDetailsMapper.getAgreementTitle(res),
                    component: AGREEMENT_MAINTENANCE_PAGE.component,
                    agreementMaintenanceState: {
                        loaded: false,
                        agreement: AgreementDetailsMapper.mapAgreementToState(res),
                        agreementBatchOperationInProgress: false
                    },
                    changesMade: false,
                    dataActionToolbar: activeTabItem.dataActionToolbar,
                    editableFields: editableFields
                };
                dispatch(replaceTab(newAgreementTab, activeTabItem.title))
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(getAgreementDetailsFailure(err, agreementID));
                if (err === 404) {
                    dispatch(showModal(PRODUCT_NOT_FOUND_VIEW, null, agreementID, true, "Product not Found"));
                }
            });
    };
};

export const searchUsagePoolsThunk = (
    pools: IUsagePoolSearchQuery,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchUsagePoolsRequest());
        dispatch(showLoading());
        return SearchRequests.getUsagePools(pools)
            .then((res: IUsagePool[]) => {
                dispatch(searchUsagePoolsSuccess(res));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(searchUsagePoolsFailure(err));
                dispatch(hideModal());
            });
    };
};

export const searchUsagePoolsByCodeDistributionPoolsThunk = (
    pools: IUsagePoolSearchQuery,
): ThunkAction<void, IAppState, null, Action<string>> => {
  return (dispatch: Dispatch) => {
        dispatch(getPoolByCodeRequest());
        dispatch(showLoading());
        return SearchRequests.getUsagePools(pools)
            .then((res: IUsagePool[]) => {
                dispatch(getPoolByCodeSuccess(res));
                dispatch(hideModal());
                
            })
            .then(() => { dispatch(onUpdatePoolCodeToSearch(EMPTY_STRING_VALUE));})
            .catch(err => {
                dispatch(searchUsagePoolsFailure(err));
                dispatch(hideModal());
            });
    };
};

export const getUsagePoolDetailsThunk = (
    poolId: number,
    sources: ITreeData[],
    isPoolMaintainence?: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getUsagePoolRequest())
        return SearchRequests.getUsagePool(poolId)
            .then((res: IUsagePoolCore) => {
                dispatch(getUsagePoolSuccess(res));
                if (isPoolMaintainence) {
                    let newUsagePoolTab: ITabReduxItem = {
                        title: "Pool: " + res.poolID,
                        component: USAGE_POOL_MAINTENANCE_PAGE.component,
                        usagePoolMaintenanceState: {
                            loaded: false,
                            usagePool: UsagePoolDetailsMapper.mapPoolToState(res, sources)
                        },
                        changesMade: false,
                    };
                    dispatch(addTab(newUsagePoolTab));
                }
            })
            .catch(err => {
                dispatch(getUsagePoolFailure(err));
            });
    };
}

export const saveUsagePoolThunk = (
    usagePool: IUsagePoolState
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(saveUsagePoolRequest())
        let usagePoolValidationMsgs = UsagePoolValidator.validateUsagePool(usagePool);
        if (usagePoolValidationMsgs.length > 0) {
            const saveResult: IUsagePoolSaveResult = {
                messages: usagePoolValidationMsgs,
                usagePool: UsagePoolDetailsMapper.mapStateToPool(usagePool),
                saved: false,
                success: false,
                validationSuccess: false
            }
            dispatch(saveUsagePoolFailure(saveResult));
            dispatch(hideModal())
        } else {
            return RepertoireService.saveUsagePool(UsagePoolDetailsMapper.mapStateToPool(usagePool))
                .then((res) => {
                    if (res.status !== 200 || !res.data.success) {
                        const errorMessages: string[] = res.data.messages || [GENERIC_ERROR];
                        const saveResult: IUsagePoolSaveResult = {
                            messages: errorMessages,
                            usagePool: res.data.usagePool,
                            saved: false,
                            success: false,
                            validationSuccess: false
                        }
                        dispatch(saveUsagePoolFailure(saveResult));
                    }
                    dispatch(saveUsagePoolSuccess(res.data));
                })
                .catch(err => {
                    dispatch(saveUsagePoolFailure(err));
                })
        }
    }
}

export const deleteUsagePoolThunk = (
    poolId: number,
    poolCode: string,
    sources: ITreeData[],
    activeTab: number
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(deleteUsagePoolRequest())
        const usageSearchQuery: IUsagesSearchQuery = {
            usageType: '',
            type: '',
            distribution: '',
            pool: poolCode,
            fileName: '',
            title: '',
            composer: '',
            artist: '',
            sourceMajor: '',
            sourceMinor: '',
            rightShareOwner: '',
            allocationStatus: '',
            includeFeesInError: '',
            totalWeight: '',
            licenseesWorkNumber: '',
            matchStatus: '',
            productType: ''
        }
        UsageSearchRequests.getUsages(usageSearchQuery, sources)
            .then((usages) => {
                if (usages && usages.usageGroups.length > 0) {
                    const errorMessage: IValidationMessage = { messageKey: 'poolHasUsagesValidationMessage' }
                    dispatch(deleteUsagePoolFailure(errorMessage))
                }
                else {
                    return RepertoireService.deleteUsagePool(poolId)
                        .then(() => {
                            dispatch(deleteUsagePoolSuccess(poolId));
                            dispatch(removeTab(activeTab));
                        })
                        .catch(err => {
                            dispatch(deleteUsagePoolFailure());
                        })
                }
            })
    }
}

export const getMatchingSourcesThunk = (
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getMatchingSourcesRequest())
        return SourceService.getSourcesForAllMatchTypes()
            .then(res => {
                let result = []
                Object.keys(res).forEach(function (key) {
                    if (res[key] && res[key].sources)
                        result.push(res[key].sources[0]);
                });
                dispatch(getMatchingSourcesSuccess(result))
            })
    }
}

export const searchDistributionsThunk = (
    distributionQuery: IDistributionSearchQuery
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchDistributionsRequest());
        dispatch(showLoading());
        return SearchRequests.getDistributions(distributionQuery)
            .then((res: IDistribution[]) => {
                dispatch(searchDistributionsSuccess(res));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(searchDistributionsFailure(err));
                dispatch(hideModal());
            });
    };
};

export const getLastPercentageValuesThunk = (
    distributionQuery: ILastPercentageSearchQuery,
    lookups?: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchLastPercentageRequest());
        return DistributionService.getLastPercentage(distributionQuery)
            .then((res: IDistributionSubjects[]) => {
                dispatch(searchLastPercentageSuccess(res, lookups));
            })
            .catch(err => {
                dispatch(searchLastPercentageFailure(err));
            });
    };
};

export const searchAdjustmentsThunk = (
    adjustmentQuery: IAdjustmentSearchQuery, dataSource: string
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchAdjustmentsRequest());
        dispatch(showLoading());
        return SearchRequests.getAdjustments(adjustmentQuery, dataSource)
            .then((res: IAdjustment[]) => {
                dispatch(searchAdjustmentsSuccess(res));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(searchAdjustmentsFailure(err))
                dispatch(hideModal());
            })
    }
}

export const getDistributionTypesThunk = (
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getDistributionTypesRequest());
        return SearchRequests.getDistributionsTypes()
            .then((res: IDistributionType[]) => {
                dispatch(getDistributionTypesSuccess(res));
            })
            .catch(err => {
                dispatch(getDistributionTypesFailure(err));
            });
    };
};

export const getDistributionSubTypesThunk = (
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getDistributionSubTypesRequest());
        return SearchRequests.getDistributionSubTypes()
            .then((res: IDistributionSubType[]) => {
                dispatch(getDistributionSubTypesSuccess(res));
            })
            .catch(err => {
                dispatch(getDistributionSubTypesFailure(err));
            });
    };
};


export const getDistributionDetailsThunk = (
    distributionId: number,
    lookupValues: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getDistributionRequest());
        return SearchRequests.getDistribution(distributionId)
            .then(async (res: IDistribution) => {
                dispatch(getDistributionSuccess(res));
                const jobParameters = JSON.stringify({ DistributionId: distributionId });
                let result = []

                await JobService.getScheduledJob(ALK_JOB_TYPE, jobParameters).then((scheduledJob: IScheduledJobState) => {
                    scheduledJob = {
                        cronSchedule: scheduledJob.cronSchedule ? scheduledJob.cronSchedule : '',
                        parameters: scheduledJob.parameters ? scheduledJob.parameters : jobParameters,
                        type: scheduledJob.type ? scheduledJob.type : ALK_JOB_TYPE,
                        lastRunDate: scheduledJob.lastRunDate ? scheduledJob.lastRunDate : null,
                        errorList: scheduledJob.errorList ? scheduledJob.errorList : [],
                    }
                    result.push(scheduledJob)
                })

                await JobService.getScheduledJob(QA_JOB_TYPE, jobParameters).then((scheduledJob: IScheduledJobState) => {
                    scheduledJob = {
                        cronSchedule: scheduledJob.cronSchedule ? scheduledJob.cronSchedule : '',
                        parameters: scheduledJob.parameters ? scheduledJob.parameters : jobParameters,
                        type: scheduledJob.type ? scheduledJob.type : QA_JOB_TYPE,
                        lastRunDate: scheduledJob.lastRunDate ? scheduledJob.lastRunDate : null,
                        errorList: scheduledJob.errorList ? scheduledJob.errorList : [],
                    }
                    result.push(scheduledJob)
                })

                let newDistributionTab: ITabReduxItem = {
                    title: "Distribution: " + res.distributionCode,
                    component: DISTRIBUTION_MAINTENANCE_PAGE.component,
                    distributionMaintenanceState: {
                        loaded: false,
                        distribution: DistributionDetailsMapper.mapDistributionToState(res, lookupValues),
                    },
                    scheduledJobStateState: result,
                    changesMade: false
                };

                dispatch(addTab(newDistributionTab));
            })
            .catch(err => {
                dispatch(getDistributionFailure(err));
            });
    };
};

export const saveDistributionThunk = (
    distribution: IDistributionState,
    distributionMaintenancePageData: IRepertoireComponentDataItem,
    lookupValues: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading())
        dispatch(saveDistributionRequest())
        let distribtionValidationMessages = DistributionValidator.validateDistribution(distribution, distributionMaintenancePageData);
        if (distribtionValidationMessages.length === 0) {
            return RepertoireService.saveDistribution(DistributionDetailsMapper.mapStateToDistribution(distribution))
                .then((res) => {
                    if (res.status !== 200 || !res.data.success) {
                        const errorMessages: IValidationMessage[] = res.data.messages || [{ messageKey: GENERIC_ERROR }];
                        const saveResult: IDistributionSaveResult = {
                            messages: errorMessages,
                            distribution: res.data.distribution,
                            saved: false,
                            success: false,
                            validationSuccess: false
                        }
                        dispatch(saveDistributionFailure(saveResult, lookupValues));
                        dispatch(hideModal());
                    }
                    dispatch<any>(dataIngestionGetDistributions());
                    dispatch(saveDistributionSuccess(res.data, lookupValues));
                    dispatch(hideModal());
                })
                .catch(err => {
                    dispatch(hideModal());
                    dispatch(saveDistributionFailure(err, lookupValues));
                })
        }
        else {
            const result: IDistributionSaveResult = {
                saved: false,
                success: false,
                validationSuccess: false,
                messages: distribtionValidationMessages,
                distribution: DistributionDetailsMapper.mapStateToDistribution(distribution)
            }
            dispatch(saveDistributionFailure(result, lookupValues));
            dispatch(hideModal());
        }
    }
}

export const clearPakagesThunk = (
    myPackagesDetails: IMyPackagesDetails,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading())
        dispatch(saveDistributionRequest())
            return RepertoireService.clearPakages(myPackagesDetails)
                .then((res) => {
                    if (res.status !== 200 || !res.data.success) {
                        const saveResult: IMyPackagesResult = {
                            MyPackages: res.data,
                            saved: false,
                            success: false,
                            validationSuccess: false,
                            messages: []
                        }
                        dispatch(ClearPackageSuccess(saveResult));
                        dispatch(hideModal());
                    }
                    dispatch(ClearPackageSuccess(res.data));
                    dispatch(hideModal());
                })
                .catch(err => {
                    dispatch(hideModal());
                    dispatch(ClearPackageFailure(err));
                })
            }
}
    
export const exportDistributionPoolsThunk = (
    distribution: IDistributionState,
    lookupValues: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(saveDistributionRequest())
        return RepertoireService.exportDistributionPools(DistributionDetailsMapper.mapStateToDistribution(distribution))
            .then((res) => {
                if (res.status !== 200 || !res.data.success) {
                    const errorMessages: IValidationMessage[] = res.data.messages || [{ messageKey: GENERIC_ERROR }];
                    const saveResult: IDistributionSaveResult = {
                        messages: errorMessages,
                        distribution: res.data.distribution,
                        saved: false,
                        success: false,
                        validationSuccess: false
                    }
                    dispatch(saveDistributionFailure(saveResult, lookupValues));
                }
                dispatch(exportDistributionPoolsSuccess(res.data, lookupValues));
            })
            .catch(err => {
                dispatch(saveDistributionFailure(err, lookupValues));
            })
    }
}

export const importDistributionPoolsThunk = (
    distribution: IDistributionState,
    fileName: string,
    lookupValues: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(saveDistributionRequest())
        return RepertoireService.importDistributionPools(DistributionDetailsMapper.mapStateToDistribution(distribution), fileName)
            .then((res) => {
                if (res.status !== 200 || !res.data.success) {
                    const errorMessages: IValidationMessage[] = res.data.messages || [{ messageKey: GENERIC_ERROR }];
                    const saveResult: IDistributionSaveResult = {
                        messages: errorMessages,
                        distribution: res.data.distribution,
                        saved: false,
                        success: false,
                        validationSuccess: false
                    }
                    dispatch(saveDistributionFailure(saveResult, lookupValues));
                }
                dispatch(saveDistributionSuccess(res.data, lookupValues));
            })
            .catch(err => {
                dispatch(saveDistributionFailure(err, lookupValues));
            })
    }
}

export const undoDistributionChangesThunk = (
    distributionId: number,
    lookupValues: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(undoDistributionChangesRequest());
        dispatch(showLoading());
        return SearchRequests.getDistribution(distributionId)
            .then((distribution: IDistribution) => {
                dispatch(undoDistributionChangesSuccess(distribution, lookupValues));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(undoDistributionChangesFailure(err));
                dispatch(hideModal());
            });
    };
};

export const deleteDistributionThunk = (
    distributionId: number,
    activeTab: number,
    distributionCode: string,
    sources: ITreeData[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(deleteDistributionRequest(distributionId));
        const usageSearchQuery: IUsagesSearchQuery = {
            usageType: '',
            type: '',
            distribution: distributionCode,
            pool: '',
            fileName: '',
            title: '',
            composer: '',
            artist: '',
            sourceMajor: '',
            sourceMinor: '',
            rightShareOwner: '',
            allocationStatus: '',
            includeFeesInError: '',
            totalWeight: '',
            licenseesWorkNumber: '',
            matchStatus: '',
            productType: ''
        }
        UsageSearchRequests.getUsages(usageSearchQuery, sources)
            .then((usages) => {
                if (usages && usages.usageGroups.length > 0) {
                    const errorMessage: IValidationMessage = { messageKey: 'distributionHasUsagesValidationMessage' }
                    dispatch(deleteDistributionFailure(errorMessage))
                } else {
                    RepertoireService.deleteDistribution(distributionId)
                        .then(res => {
                            dispatch(deleteDistributionSuccess(distributionId));
                        })
                        .finally(() => {
                            dispatch(removeTab(activeTab));
                            dispatch(hideModal());

                        })
                        .catch((error: any) => {
                            dispatch(deleteDistributionFailure(error));
                            dispatch(hideModal());
                        });
                }
            })
    };
};

export const searchDistributionVersionHistoryThunk = (
    distributionID: number,
    lookups: ILookupDictionary,
    actionList: IDataActionToolbar[],
    formats: FormatFields[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading());
        dispatch(searchDistributionVersionHistoryRequest());
        return SearchRequests.getDistributionVersionHistory(distributionID)
            .then((res: IVersionHistorySearchResult[]) => {
                dispatch(hideModal());
                dispatch(searchDistributionVersionHistorySuccess(res));
                dispatch(showDistributionVersionHistoryModal("Distribution Version History", SEARCH_VIEW_DISTRIBUTIONS, {
                    openCallback: (entityId: number, versionNumber: number, mergedWork: boolean) => getDistributionHistoryVersion(dispatch, entityId, versionNumber, lookups, actionList, formats),
                    versionHistorySearchResult: res
                }))
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(searchDistributionVersionHistoryFailure());
            });
    };
}

function getDistributionHistoryVersion(
    dispatch: Dispatch,
    entityId: number,
    versionNumber: number,
    lookups: ILookupDictionary,
    actionList: IDataActionToolbar[],
    formats: FormatFields[]
): void {
    SearchRequests.getDistributionHistoryVersion(entityId, versionNumber)
        .then((res: IDistribution) => {
            let newDistributionTab: ITabReduxItem = {
                title: "Distribution: " + res.distributionCode,
                component: DISTRIBUTION_MAINTENANCE_PAGE.component,
                distributionMaintenanceState: {
                    loaded: false,
                    distribution: DistributionDetailsMapper.mapDistributionToState(res, lookups)
                },
                changesMade: false,
                versionNumber: res.versionNumber,
                isReadonly: true
            };
            dispatch(addTab(newDistributionTab));
            dispatch(hideModal());
        });
}


export const searchPaymentRunVersionHistoryThunk = (
    paymentRunID: number,
    lookups: ILookupDictionary,
    actionList: IDataActionToolbar[],
    formats: FormatFields[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading());
        dispatch(searchPaymentRunVersionHistoryRequest());
        return SearchRequests.getPaymentRunVersionHistory(paymentRunID)
            .then((res: IPaymentRunVersionHistorySearchResult[]) => {
                dispatch(hideModal());
                dispatch(searchPaymentRunVersionHistorySuccess(res));
                dispatch(showVersionHistoryModal("PaymentRun Version History", SEARCH_VIEW_PAYMENTRUN, {
                    openCallback: (entityId: number, versionNumber: number) => getPaymentRunHistoryVersion(dispatch, paymentRunID, versionNumber, lookups, actionList, formats),
                    versionHistorySearchResult: res
                }))
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(searchPaymentRunVersionHistoryFailure());
            });
    };
}

function getPaymentRunHistoryVersion(
    dispatch: Dispatch,
    entityId: number,
    versionNumber: number,
    lookups: ILookupDictionary,
    actionList: IDataActionToolbar[],
    formats: FormatFields[]
): void {
    SearchRequests.getPaymentRunHistoryVersion(entityId, versionNumber)
        .then((res: IPaymentRun) => {
            let newPaymentRunTab: ITabReduxItem = {
                title: "PaymentRun: " + res.code,
                component: PAYMENTRUN_MAINTENANCE_PAGE.component,
                paymentRunMaintenanceState: {
                    paymentRun: PaymentRunDetailsMapper.mapPaymentRunToState(res, lookups),
                    loaded: false,
                    isNew: false
                },
                changesMade: false,
                versionNumber,
                isReadonly: true,
                dataActionToolbar: actionList
            };
            dispatch(addTab(newPaymentRunTab));
            dispatch(hideModal());
        });
}


export const searchAdjustmentVersionHistoryThunk = (
    adjustmentID: string,
    lookups: ILookupDictionary,
    actionList: IDataActionToolbar[],
    formats: FormatFields[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading());
        dispatch(searchAdjustmentVersionHistoryRequest());
        return SearchRequests.getAdjustmentVersionHistory(adjustmentID)
            .then((res: IAdjustmentVersionHistorySearchResult[]) => {
                dispatch(hideModal());
                dispatch(searchAdjustmentVersionHistorySuccess(res));
                dispatch(showVersionHistoryModal("Adjustment Version History", SEARCH_VIEW_ADJUSTMENTS, {
                    openCallback: (entityId: string, versionNumber: number) => getAdjustmentHistoryVersion(dispatch, adjustmentID, versionNumber, actionList, formats),
                    versionHistorySearchResult: res
                }))
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(searchAdjustmentVersionHistoryFailure());
            });
    };
}

function getAdjustmentHistoryVersion(
    dispatch: Dispatch,
    entityId: string,
    versionNumber: number,
    actionList: IDataActionToolbar[],
    formats: FormatFields[]
): void {
    SearchRequests.getAdjustmentHistoryVersion(entityId, versionNumber)
        .then((res: IAdjustment) => {
            let newAdjustmentTab: ITabReduxItem = {
                title: "Adjustment: " + res.id,
                component: ADJUSTMENT_MAINTENANCE_PAGE.component,
                adjustmentMaintenanceState: {
                    adjustment: AdjustmentDetailsMapper.mapAdjustmentToState(res),
                    loaded: false,
                    isNew: false
                },
                changesMade: false,
                versionNumber,
                isReadonly: true,
                dataActionToolbar: actionList
            };
            dispatch(addTab(newAdjustmentTab));
            dispatch(hideModal());
        });
}


export const addNewRepresentationThunk = (representation: IRepresentation, activeTab?: number, activePane?: number): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        dispatch(addRepresentationRequest());
        dispatch(showLoading());

        let validateRepresentationUnsuccessful = false;

        if (typeof representation.description == 'undefined' || representation.description.trim() == '') {
            validateRepresentationUnsuccessful = true;

            let validationMessages: IValidationMessage[] = [{ messageKey: "Description field must have a value" }]
            getState().repertoire.panes[activePane ? activePane : 0].tabs.find(e => e.component === REPRESENTATION_PAGE.component).validationMessages = validationMessages
        }

        if (representation.ipBaseNumber == null || typeof representation.ipBaseNumber == 'undefined' || representation.ipBaseNumber.trim() == '') {
            validateRepresentationUnsuccessful = true;

            let validationMessages: IValidationMessage[] = [{ messageKey: "IP Base Number field must have a value" }]
            getState().repertoire.panes[activePane ? activePane : 0].tabs.find(e => e.component === REPRESENTATION_PAGE.component).validationMessages = validationMessages
        }

        getState().repertoire.panes[activePane ? activePane : 0].tabs.find(e => e.component === REPRESENTATION_PAGE.component).validateRepresentationUnsuccessful = validateRepresentationUnsuccessful;

        if (validateRepresentationUnsuccessful) {
            dispatch(hideModal());
            return dispatch(addRepresentationFailure("Representation validation was unsuccessful"));
        }

        return RepertoireService.addNewRepresentation(representation)
            .then(result => {
                const resultIp: IIPSaveResult = result.data
                if (resultIp === undefined || result.status !== 200) {

                    let errorResult = resultIp === undefined ? result : resultIp.messages;

                    dispatch(removeTab(activeTab));
                    dispatch(addRepresentationFailure(errorResult));
                }
                else {
                    dispatch(addRepresentationSuccess(resultIp));
                    dispatch(removeTab(activeTab));
                }
            })
            .finally(() => {
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(addRepresentationFailure(err));
                dispatch(hideModal());
            });
    }
};

export const getRepresentationGroupsThunk = (ipBaseNumber: string, activeTab?: number): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getRepresentationGroupRequest());
        dispatch(showLoading());
        return RepertoireService.getRepresentationGroups(ipBaseNumber)
            .then(result => {
                const resultIp: {
                    representationGroups: { code: string, description: string }[],
                    representationUses: { code: string, description: string }[],
                    representationWorkGroups: { code: string, description: string }[]
                } = result.data
                if (result.status !== 200 || !resultIp) {
                    dispatch(getRepresentationGroupFailure(resultIp));
                }
                else {
                    dispatch(getRepresentationGroupSuccess(resultIp));
                    if (resultIp === null)
                        dispatch(removeTab(activeTab))
                }
            })
            .finally(() => {
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(getRepresentationGroupFailure(err));
                dispatch(hideModal());
            });
    }
};

export const deleteRepresentationsThunk = (representations: IDeleteRepresentations, activeTab?: number): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(deleteRepresentationsRequest());
        dispatch(showLoading());
        return RepertoireService.deleteInterestedPartyRepresentation(representations)
            .then(result => {

                if (result.status !== 200 || !result.data) {
                    dispatch(deleteRepresentationsFailure(result.data));
                }
                else {
                    dispatch(deleteRepresentationsSuccess(result.data));
                    if (result.data === null)
                        dispatch(removeTab(activeTab))
                }
            })
            .finally(() => {
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(deleteRepresentationsFailure(err));
                dispatch(hideModal());
            });
    }
};

export const saveClaimThunk = (
    updatedClaim: IClaimState
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        {
            dispatch(saveClaimRequest());
            dispatch(showLoading());
            RepertoireService.saveClaim(ClaimDetailsMapper.mapStateToClaim(updatedClaim))
                .then(res => {
                    const result: IClaimSaveResult = res.data;
                    if (res.status !== 200 || !result.success) {
                        dispatch(saveClaimFailure(result));
                    }
                    else {
                        dispatch(saveClaimSuccess(result));
                    }
                })
                .catch((error: any) => {
                    const errorMessages: IValidationMessage[] = [{ messageKey: error }];
                    const saveResult: IClaimSaveResult = {
                        messages: errorMessages,
                        claim: ClaimDetailsMapper.mapStateToClaim(updatedClaim),
                        saved: false,
                        success: false,
                        validationSuccess: false
                    }
                    dispatch(saveClaimFailure(saveResult));
                })
                .finally(() => {
                    dispatch(hideModal());
                })
        };
    };
};

export const saveWorkflowThunk = (workflowparams: IWorkflowParams, callFrom: string): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(saveWorkflowRequest(workflowparams.workflow));
        RepertoireService.saveCompleteWorkflow(workflowparams.workflow)
            .then(res => {
                dispatch(saveWorkflowSuccess(res, callFrom, workflowparams.isWFSession));
            }).then(async () => {
                await getNextWorkflowItem(dispatch, workflowparams, callFrom);
            })
            .catch((error: any) => {
                dispatch(saveWorkflowFailure(error));
            });
    };
};

export const updateWorkflowAndEntityStatusThunk = (workflowparams: IWorkflowParams, approvalStatus: boolean, callFrom: string, refreshDetails?: () => void): ThunkAction<void, IAppState, null, Action<string>> => {
    return async (dispatch: Dispatch) => {
        dispatch(saveWorkflowRequest(workflowparams.workflow));
        await RepertoireService.saveCompleteWorkflow(workflowparams.workflow)
            .then(async res => {
                await UsageService.updateUsageApprovalStatus(workflowparams.workflow.entityID.toString(), approvalStatus)

                if (!workflowparams.isWFSession) {
                    refreshDetails!();
                }

                dispatch(saveWorkflowSuccess(res, callFrom, workflowparams.isWFSession));
            })
            .then(async () => {
                await getNextWorkflowItem(dispatch, workflowparams, callFrom);
            })
            .catch((error: any) => {
                dispatch(saveWorkflowFailure(error));
            });
    };
}

export const updateClaimWorkflowAndEntityStatusThunk = (workflowparams: IWorkflowParams, callFrom: string, refreshDetails?: () => void): ThunkAction<void, IAppState, null, Action<string>> => {
    return async (dispatch: Dispatch) => {
        dispatch(saveWorkflowRequest(workflowparams.workflow));
        await RepertoireService.saveCompleteWorkflow(workflowparams.workflow)
            .then(async res => {
                await RepertoireService.updateClaimUsageWorkflow(workflowparams.workflow)

                dispatch(saveWorkflowSuccess(res, callFrom, workflowparams.isWFSession));
                dispatch(approveRejectWorkflowSuccess(workflowparams.workflow));

                if (!workflowparams.isWFSession) {
                    refreshDetails!();
                }
            })
            .then(async () => {
                await getNextWorkflowItem(dispatch, workflowparams, callFrom);
            })
            .catch((error: any) => {
                dispatch(saveWorkflowFailure(error));
            });
    };
}

function getNextWorkflowItem(dispatch: Dispatch, workflowparams: any, callFrom: string) {
    if (!workflowparams.isWFSession) {
        return;
    }

    dispatch(showLoading());
    dispatch(removeTab(workflowparams.activeTab))
    dispatch(nextWorkflowInSession());
    dispatch(getUsageRequest());

    if (!workflowparams.workflowSessionItems[workflowparams.currentWorkflowIndex]) {
        dispatch(markSelectAll(false));
        dispatch(cancelWorkflowSession());
        dispatch(hideModal());
        return;
    }

    const item = workflowparams.workflowSessionItems[workflowparams.currentWorkflowIndex];

    switch (callFrom) {
        case USAGE_WORKFLOW: {

            return UsageSearchRequests.getUsage(item.entityID.toString())
                .then(async (res: IUsageGroup) => {
                    const matchingWorks: IMatchingWorksRowData[] = [];
                    const matchingProducts: IMatchingProductsRowData[] = [];

                    if (res.matches && res.matches.length > 0 && res.usageType === "UsageToWork") {

                        const workSearchQuery: IMatchWorksSearchQuery =
                        {
                            workIDs: res.matches.map(mw => mw.id),
                            title: "",
                            number: "",
                            artist: "",
                            writers: "",
                            societyAccountNumber: "",
                            workBatchID: "",
                            dataSource: REPERTOIRE,
                            hasOpenWorkflow: false
                        };

                        await UsageSearchRequests.getWorks(workSearchQuery)
                            .then((result: IMatchWorksSearchResult[]) => {
                                result.forEach((w) => {
                                    const match: IUsageMatch = res.matches.find(x => x.id === w.workID);

                                    const matchingWork: IMatchingWorksRowData = {
                                        status: startCase(match.matchType),
                                        title: w.title,
                                        composers: w.publishers.concat(w.writers),
                                        performers: w.artists,
                                        number: w.number[0],
                                        source: startCase(match.matchSource),
                                        workID: match.id
                                    }
                                    matchingWorks.push(matchingWork);
                                });
                            });
                    }
                    else if (res.matches && res.matches.length > 0 && res.usageType === "UsageToProduct") {

                        const searchQuery: IMatchProductsSearchQuery =
                        {
                            productIDs: res.matches.map(mw => mw.id),
                            title: "",
                            number: "",
                            artist: "",
                            contributor: "",
                            productBatchID: "",
                            productType: "",
                            dataSource: REPERTOIRE,
                        };

                        await UsageSearchRequests.getProducts(searchQuery)
                            .then((result: IProductSearchResult[]) => {
                                result.forEach((w) => {
                                    const match: IUsageMatch = res.matches.find(x => x.id === w.productCoreID);

                                    const matchingProduct: IMatchingProductsRowData = {
                                        status: startCase(match.matchType),
                                        id1: w.id1,
                                        id2: w.id2,
                                        artist: w.artist,
                                        contributor: w.contributor,
                                        productCoreID: w.productCoreID,
                                        source: startCase(match.matchSource),
                                        title: w.title,
                                        type: w.type,
                                        number: w.number,
                                        productionType:w.productionType,
                                        musicDuration:w.musicDuration
                                    }
                                    matchingProducts.push(matchingProduct);
                                });
                            });
                    }

                    dispatch(getUsageSuccess(res));
                    let newUsageTab: ITabReduxItem = {
                        title: UsageDetailsMapper.getUsageTitle(res),
                        component: USAGE_MAINTENANCE_PAGE.component,
                        usageMaintenanceState: {
                            loaded: false,
                            usageGroup: UsageDetailsMapper.mapUsageToState(res, matchingWorks.length > 0 ? matchingWorks : undefined, matchingProducts.length > 0 ? matchingProducts : undefined),
                            isNew: false,
                            dataSource: DataSource.Repertoire
                        },
                        changesMade: false,
                        isReadonly: false,
                        isEntityForWorkflowSession: true
                    };
                    dispatch(addTab(newUsageTab));
                    dispatch(hideModal());
                })
                .catch(err => {
                    dispatch(hideModal());
                    dispatch(getUsageFailure(err));
                    if (err === 404) {
                        dispatch(showModal(WORK_NOT_FOUND_VIEW, null, item.entityID, true, "Usage not found"));
                    }
                })
        }
        case WORK_WORKFLOW: {
            return SearchRequests.getWork(item.setType, item.entityID)
                .then((res: IWork) => {
                    dispatch(getWorkDetailsSuccess(res));
                    let newWorkTab: ITabReduxItem = {
                        title: WorkDetailsMapper.getOriginalTitleFromWorkNames(res.workNames),
                        component: WORK_MAINTENANCE_PAGE.component,
                        workMaintenanceState: {
                            loaded: false,
                            workBatchOperationInProgress: false,
                            work: WorkDetailsMapper.mapWorkToWorkState(res, workflowparams.lookupValues, workflowparams.otherIndicatorsWorkFlagTypes, workflowparams.readonlyIndicatorWorkFlagTypes),
                            contributorInheritanceChanged: false,
                            isNew: false
                        },
                        changesMade: false,
                        dataActionToolbar: workflowparams.dataActionList,
                        isReadonly: false,
                        formatFields: workflowparams.workFormats,
                        isEntityForWorkflowSession: true
                    };
                    dispatch(addTab(newWorkTab));
                    dispatch(hideModal());
                })
                .catch(err => {
                    dispatch(hideModal());
                    dispatch(getWorkDetailsFailure(err, item.entityID));
                    if (err === 404) {
                        dispatch(showModal(WORK_NOT_FOUND_VIEW, null, item.entityID, true, "Work not found"));
                    }
                })
        }
        case PRODUCT_WORKFLOW: {
            return SearchRequests.getProduct(item.setType, item.entityID)
                .then((res: IProductCore) => {
                    dispatch(getProductDetailsSuccess(res))
                    let newProductTab: ITabReduxItem = {
                        title: ProductDetailsMapper.getOriginalTitleFromProductNames(res.productNames),
                        component: PRODUCT_MAINTENANCE_PAGE.component,
                        productMaintenanceState: {
                            loaded: false,
                            productBatchOperationInProgress: false,
                            product: ProductDetailsMapper.mapProductToState(res, workflowparams.workFormats),
                            isNew: false
                        },
                        changesMade: false,
                        dataActionToolbar: workflowparams.dataActionList,
                        isReadonly: false,
                        formatFields: workflowparams.workFormats,
                        isEntityForWorkflowSession: true
                    };
                    dispatch(addTab(newProductTab));
                    dispatch(hideModal())
                })
                .catch(err => {
                    dispatch(hideModal());
                    dispatch(getProductDetailsFailure(err, item.entityID));
                    if (err === 404) {
                        dispatch(showModal(PRODUCT_NOT_FOUND_VIEW, null, item.entityID, true, "Product not found"));
                    }
                })
        }
        default: {
            throw new Error(`No case for workflow type ${callFrom}`);
        }
    }
}

export const getWorkMatchConnectorStatusThunk = (
    workID: number,
    activeTabItem: ITabReduxItem
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getWorkMatchConnectorRequest());
        dispatch(showLoading());
        SearchRequests.getMatchConnectorStatus(workID)
            .then((matchConnectorStatus) => {
                    dispatch(getWorkMatchConnectorSuccess(matchConnectorStatus));
                    let tab: ITabReduxItem = {
                        ...activeTabItem,
                        workMaintenanceState: {
                            ...activeTabItem.workMaintenanceState,
                            loaded: false,
                            isNew: false
                        },
                        changesMade: false
                    };
                    tab.workMaintenanceState.work.matchConnectorStatus = matchConnectorStatus;
                    dispatch(replaceTab(tab, activeTabItem.title))
                    dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(getWorkMatchConnectorFailure(err));
                if (err.response.status === 404) {
                    dispatch(showMessage({ textToScreen: "An error occured calling ICE: " + err.response.data }));
                }
            });
    };
}

export const getAdjustmentDetailsThunk = (
    adjustmentId: string,
    adjustments: IAdjustment[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getAdjustmentDetailsRequest())
        SearchRequests.getAdjustment(adjustmentId)
            .then((adjustment) => {
                if (adjustment) {
                    dispatch(getAdjustmentDetailsSuccess(adjustment));
                    let newAdjustmentTab: ITabReduxItem = {
                        title: "Adjustment: " + adjustment.id,
                        component: ADJUSTMENT_MAINTENANCE_PAGE.component,
                        adjustmentMaintenanceState: {
                            loaded: false,
                            adjustment: AdjustmentDetailsMapper.mapAdjustmentToState(adjustment)
                        },
                        changesMade: false,
                    };
                    dispatch(addTab(newAdjustmentTab));
                }
                else {
                    dispatch(getAdjustmentDetailsFailure("An error occured."));
                }
            })
            .catch(() => dispatch(getAdjustmentDetailsFailure("An error occured.")));
    };
}

export const saveAdjustmentThunk = (
    adjustment: IAdjustmentState,
    saveOption?: string
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(saveAdjustmentRequest())
        return RepertoireService.saveAdjustment(AdjustmentDetailsMapper.mapStateToAdjustment(adjustment), saveOption)
            .then((res) => {
                if (res.status !== 200 || !res.data.success) {
                    const saveResult: IAdjustmentSaveResult = res.data;
                    dispatch(saveAdjustmentFailure(saveResult));
                }
                dispatch(saveAdjustmentSuccess(res.data));
            })
            .catch(err => {
                dispatch(saveAdjustmentFailure(err));
            })
    }
}

export const ipTransferThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        return dispatch(showIpTransferView("IP Transfer View", IP_TRANSFER_VIEW, {
            recipientIP: "",
            transferType: "",
            transferSubType: "",
            transferPercentage: 0,
            transferIPType: "",
            transferStartDate: "",
            transferEndDate: "",
            transferRegistationDate: "",
        })
        )
    }
}

export const searchClaimsThunk = (
    searchBody: IClaimSearchQuery
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading());
        dispatch(searchClaimsRequest());
        return SearchRequests.getClaims(searchBody)
            .then((res: IClaimSearchResult[]) => {
                dispatch(searchClaimsSuccess(res));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(searchClaimsFailure(err));
                dispatch(hideModal());
            });
    };
}

export const getClaimDetailsThunk = (
    searchBody: IClaimSearchQuery
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading());
        dispatch(searchClaimsRequest());
        return SearchRequests.getClaims(searchBody)
            .then((res: IClaimSearchResult[]) => {
                dispatch(searchClaimsSuccess(res));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(searchClaimsFailure(err));
                dispatch(hideModal());
            });
    };
}

export const searchDatabricksAllocationsThunk = (
    searchparams: IAllocationSearchModel
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchDatabricksAllocationsRequest())
        return DatabricksApiService.searchAllocations(searchparams)
            .then(res => {
                if (res)
                    dispatch(searchDatabricksAllocationsSuccess(res));
            })
    }
}


export const ipTransferJobThunk = (ipTransfer: IipTransfer): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        dispatch(ipTransferJobRequest());
        dispatch(showLoading());

        return RepertoireService.transferIp(ipTransfer)
            .then(result => {
                const resultIp: IIPSaveResult = result.data;

                dispatch(ipTransferJobSuccess(resultIp));
                dispatch(hideModal());
            })
            .finally(() => {
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(ipTransferJobFailure(err));
            });
    }
};

export const searchPaymentRunsThunk = (
    paymentRunQuery: IPaymentRunSearchQuery
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchPaymentRunsRequest());
        return SearchRequests.getPaymentRuns(paymentRunQuery)
            .then((res: IPaymentRun[]) => {
                dispatch(searchPaymentRunsSuccess(res));
            })
            .catch(err => {
                dispatch(searchPaymentRunsFailure(err));
            });
    };
};

export const getPaymentRunDetailsThunk = (
    paymentRunId: number,
    lookupValues: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getPaymentRunRequest())
        return SearchRequests.getPaymentRun(paymentRunId)
            .then((res: IPaymentRun) => {
                dispatch(getPaymentRunSuccess(res));

                let newPaymentRunTab: ITabReduxItem = {
                    title: "PaymentRun: " + res.code,
                    component: PAYMENTRUN_MAINTENANCE_PAGE.component,
                    paymentRunMaintenanceState: {
                        loaded: false,
                        paymentRun: PaymentRunDetailsMapper.mapPaymentRunToState(res, lookupValues)
                    },
                    changesMade: false,
                };
                dispatch(addTab(newPaymentRunTab));
            })
            .catch(err => {
                dispatch(getPaymentRunFailure(err));
            });
    };
}


export const savePaymentRunThunk = (
    paymentRun: IPaymentRunState,
    paymentRunMaintenancePageData: IRepertoireComponentDataItem,
    lookupValues: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading())
        dispatch(savePaymentRunRequest())
        let paymentRunValidationMessages = PaymentRunValidator.validatePaymentRun(paymentRun, paymentRunMaintenancePageData);

        if (paymentRunValidationMessages.length === 0) {
            return RepertoireService.savePaymentRun(PaymentRunDetailsMapper.mapPaymentRunStateToPaymentRun(paymentRun))
                .then((res) => {
                    if (res.status !== 200 || !res.data.success) {
                        const errorMessages: string[] = res.data.messages || [GENERIC_ERROR];
                        const saveResult: IPaymentRunSaveResult = {
                            messages: errorMessages,
                            paymentRun: res.data.paymentRun,
                            saved: false,
                            success: false,
                            validationSuccess: false
                        }
                        dispatch(savePaymentRunFailure(saveResult, lookupValues));
                        dispatch(hideModal());
                    }
                    dispatch<any>(dataIngestionGetDistributions());
                    dispatch(savePaymentRunSuccess(res.data, lookupValues));
                    dispatch(hideModal());
                })
                .catch(err => {
                    dispatch(hideModal());
                    dispatch(savePaymentRunFailure(err, lookupValues));
                })
        }
        else {
            const result: IPaymentRunSaveResult = {
                saved: false,
                success: false,
                validationSuccess: false,
                messages: paymentRunValidationMessages,
                paymentRun: PaymentRunDetailsMapper.mapPaymentRunStateToPaymentRun(paymentRun)
            }
            dispatch(savePaymentRunFailure(result, lookupValues));
            dispatch(hideModal());
        }
    }
}

export const deletePaymentRunThunk = (
    paymentRunId: number,
    activeTab: number,
    sources: ITreeData[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(deletePaymentRunRequest());
        dispatch(showLoading());
        return RepertoireService.deletePaymentRun(paymentRunId)
            .then(result => {
                dispatch(deletePaymentRunSuccess(result.data));
            })
            .finally(() => {
                dispatch(removeTab(activeTab));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(deletePaymentRunFailure(err));
                dispatch(hideModal());
            });
    }
};

export const getPaymentRunIDsThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getPaymentRunIDsRequest());
        return UsageService.getPaymentRunIDs()
            .then((res: string[]) => {
                var paymentRunIDs = res.map(x => ({ code: x, description: x }));
                dispatch(getPaymentRunIDsSuccess(paymentRunIDs));
            })
            .catch(err => {
                dispatch(getPaymentRunIDsFailure(err));
            });
    };
};

export const searchLicensesThunk = (
    licenseQuery: IShoppingCartSearchQuery
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchLicensesRequest());
        dispatch(showLoading());
        return SearchRequests.getLicenses(licenseQuery)
            .then((res: IShoppingCart[]) => {
                dispatch(searchLicensesSuccess(res));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(searchLicensesFailure(err));
                dispatch(hideModal());
            });
    };
};

export const saveLicenseThunk = (
    license: IShoppingCartState, licenseRequestWorkParameterFields: ILicenseInputItem[], lookupValues: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(saveLicenseRequest())
        dispatch(showLoading())
        let licenseValidationMsgs = LicenseValidator.validateLicenseRequest(license);
        if (licenseValidationMsgs.length > 0) {
            let saveLicenseError: ILicenseSaveResult = {
                shoppingCart: null,
                success: false,
                saved: false,
                messages: licenseValidationMsgs,
                validationSuccess: false
            };
            saveLicenseError.shoppingCart = LicenseDetailsMapper.mapShoppingCartStateToShoppingCart(license, licenseRequestWorkParameterFields);
            dispatch(saveLicenseFailure(saveLicenseError, lookupValues, licenseRequestWorkParameterFields))
            dispatch(hideModal())
        } else {
            return RepertoireService.saveLicenseRequest(LicenseDetailsMapper.mapShoppingCartStateToShoppingCart(license, licenseRequestWorkParameterFields))
                .then((res: ILicenseSaveResult) => {
                    let saveLicenseSuccessResult: ILicenseSaveResult = {
                        shoppingCart: res.shoppingCart,
                        success: true,
                        saved: true,
                        messages: [],
                        validationSuccess: true
                    };
                    let validationMsgKey: IValidationMessage = { messageKey: "saveSuccessMessage" };
                    saveLicenseSuccessResult.messages.push(validationMsgKey);
                    dispatch(saveLicenseSuccess(saveLicenseSuccessResult, lookupValues, licenseRequestWorkParameterFields))
                    dispatch(hideModal())
                })
                .catch(err => {
                    let saveLicenseError: ILicenseSaveResult = {
                        shoppingCart: null,
                        success: false,
                        saved: false,
                        messages: [],
                        validationSuccess: false
                    };
                    if (err === "saveFailedMessage" && licenseValidationMsgs.length === 0) {
                        let validationMsgKey: IValidationMessage = { messageKey: "saveFailedMessage" };
                        saveLicenseError.messages.push(validationMsgKey);
                    }
                    saveLicenseError.shoppingCart = LicenseDetailsMapper.mapShoppingCartStateToShoppingCart(license, licenseRequestWorkParameterFields);
                    dispatch(saveLicenseFailure(saveLicenseError, lookupValues, licenseRequestWorkParameterFields))
                    dispatch(hideModal())
                })
        }
    }
}

export const getLicenseDetailsThunk = (
    licenseId: number,
    lookupValues: ILookupDictionary, licenseRequestWorkParameterFields: ILicenseInputItem[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getLicenseRequest())
        dispatch(showLoading())
        return SearchRequests.getLicense(licenseId)
            .then((res: IShoppingCart) => {
                dispatch(getLicenseSuccess(res));

                let newLicenseTab: ITabReduxItem = {
                    title: "License: " + res.shoppingCartID,
                    component: LICENSE_MAINTENANCE_PAGE.component,
                    licenseMaintenanceState: {
                        loaded: false,
                        shoppingCart: LicenseDetailsMapper.mapLicenseToState(res, lookupValues, licenseRequestWorkParameterFields),
                        crmUsers: null,
                        isNew: false
                    },
                    changesMade: false,
                };
                dispatch(addTab(newLicenseTab));
                dispatch(hideModal())
            })
            .catch(err => {
                dispatch(getLicenseFailure(err));
                dispatch(hideModal())
            });
    };
}

export const undoLicenseChangesThunk = (
    licenseId: number,
    lookupValues: ILookupDictionary, licenseRequestWorkParameterFields: ILicenseInputItem[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading())
        return SearchRequests.getLicense(licenseId)
            .then((res: IShoppingCart) => {
                dispatch(undoLicenseSuccess(LicenseDetailsMapper.mapLicenseToState(res, lookupValues, licenseRequestWorkParameterFields)))
                dispatch(hideModal())
            })
            .catch(err => {
                dispatch(getLicenseFailure(err));
                dispatch(hideModal())
            });
    };
}

export const deleteLicenseThunk = (
    licenseId: number,
    sources: ITreeData[],
    activeTab: number,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(deleteLicenseRequest());
        dispatch(showLoading());
        return;
    }
};

export const getShoppingCartParameters = (
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        return RepertoireService.getShoppingCartParameters()
            .then((res: any) => {
                var shoppingCartParams = res.data;
                dispatch(getShoppingCartParametersSuccess(shoppingCartParams));
            })
            .catch(err => {
                dispatch(getPaymentRunIDsFailure(err));
            });
    }

};

export const searchLicenseUserThunk = (
    searchBody: ILicenseUserSearchQuery
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchLicenseUserRequest());
        return SearchRequests.getLicenseUser(searchBody)
            .then((res: ILicenseUserSearchResult[]) => {
                dispatch(searchLicenseUserSuccess(res));
            })
            .catch(err => {
                dispatch(searchLicenseUserFailure(err));
            });
    };
};

export const getLicenseRequestWorkParametersThunk = (
    commercialUseToken: number, geographicalToken: number,
    sectorToken: number, userTypeToken: number, useTypeToken: string, workID: number, licenseRequestWorkID: number
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        if (!ComponentsHelper.isEmpty(commercialUseToken)
            && !ComponentsHelper.isEmpty(geographicalToken)
            && !ComponentsHelper.isEmpty(sectorToken)
            && !ComponentsHelper.isEmpty(userTypeToken)
            && !ComponentsHelper.isEmpty(useTypeToken)) {

            dispatch(getLicenseRequestWorkParameterRequest());
            dispatch(showLoading());
            return RepertoireService.getLicenseRequestWorkParameters(commercialUseToken, geographicalToken, sectorToken, userTypeToken, useTypeToken, workID)
                .then((res: ILicenseInput[]) => {
                    dispatch(getLicenseRequestWorkParameterSuccess(LicenseDetailsMapper.mapLicenseInputToLicenseInputItem(res), licenseRequestWorkID));
                })
                .catch(err => {
                    dispatch(getLicenseRequestWorkParameterFailure(err));

                })
                .finally(() => {
                    dispatch(hideModal())
                });
        }
    };
};

export const getLicenseWorksContributorsThunk = (workIds: number[]): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        return RepertoireService.getLicenseWorkContributors(workIds, "ES", "repertoire").
            then((res: IWorkContributor[]) => {
                dispatch(getLicenseWorksContributorsSuccess(res));

            })
    };
};

export const processClaimThunk = (claimState: IClaimState): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {

        var claim = ClaimDetailsMapper.mapStateToClaim(claimState);
        var distributionQuery: IDistributionSearchQuery = { cat1: null, cat2: null, distributionCode: claim.targetDistribution, distributionStatus: null, distributionType: null, year: null, description: null };

        return SearchRequests.getDistributions(distributionQuery)
            .then((res: IDistribution[]) => {
                return RepertoireService.processClaim(res[0], false).
                    then((res: any) => {

                    })
            })

    };
};

export const calculateLicenseWorksPricesThunk = (shoppingCartState: IShoppingCartState, filteredLicenseRequestWorksState: ILicenseRequestWorkItem[],
    licenseRequestWorkParameterFields: ILicenseInputItem[], licenseRequest: ILicenseRequestItem, lookupValues: ILookupDictionary, isModified: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading());
        let licenseValidationMsgs = LicenseValidator.validateLicenseRequest(shoppingCartState);
        if (licenseValidationMsgs.length > 0) {
            let saveLicenseError: ILicenseSaveResult = {
                shoppingCart: null,
                success: false,
                saved: false,
                messages: licenseValidationMsgs,
                validationSuccess: false
            };
            saveLicenseError.shoppingCart = LicenseDetailsMapper.mapShoppingCartStateToShoppingCart(shoppingCartState, licenseRequestWorkParameterFields);
            dispatch(saveLicenseFailure(saveLicenseError, lookupValues, licenseRequestWorkParameterFields))
            dispatch(hideModal())
        } else {
            dispatch(calculateLicenseWorksPricesRequest());
            const filteredLicenseRequestWorks = LicenseDetailsMapper.mapLicenseRequestWorkItemToLicenseRequestWork(filteredLicenseRequestWorksState, licenseRequestWorkParameterFields)
            const shoppingCart = LicenseDetailsMapper.mapShoppingCartStateToShoppingCart(shoppingCartState, licenseRequestWorkParameterFields);
            if (!isModified) {
                RepertoireService.calculateLicenseWorksPrices(shoppingCart, filteredLicenseRequestWorks, licenseRequest.licenseRequestID)
                    .then((res) => {
                        if (res.status !== 200 || !res.data.success) {
                            dispatch(calculateLicenseWorksPricesFailure(res.data));
                        }
                        else {
                            dispatch(calculateLicenseWorksPricesSuccess(res.data));
                        }
                    })
                    .catch(err => {
                        dispatch(calculateLicenseWorksPricesFailure(err));
                    })
                    .finally(() => {
                        dispatch(hideModal());
                    })
            } else {
                RepertoireService.saveLicenseRequest(shoppingCart)
                    .then((res) => {
                        RepertoireService.calculateLicenseWorksPrices(shoppingCart, filteredLicenseRequestWorks, licenseRequest.licenseRequestID)
                            .then((res) => {
                                if (res.status !== 200 || !res.data.success) {
                                    dispatch(calculateLicenseWorksPricesFailure(res.data));
                                }
                                else {
                                    dispatch(calculateLicenseWorksPricesSuccess(res.data));
                                }
                            })
                            .catch(err => {
                                dispatch(calculateLicenseWorksPricesFailure(err));
                            })
                    })
                    .catch(err => {
                        let saveLicenseError: ILicenseSaveResult = {
                            shoppingCart: null,
                            success: false,
                            saved: false,
                            messages: [],
                            validationSuccess: false
                        };
                        if (err === "saveFailedMessage") {
                            let validationMsgKey: IValidationMessage = { messageKey: "saveFailedMessage" };
                            saveLicenseError.messages.push(validationMsgKey);
                        }
                        saveLicenseError.shoppingCart = LicenseDetailsMapper.mapShoppingCartStateToShoppingCart(shoppingCartState, licenseRequestWorkParameterFields);
                        dispatch(saveLicenseFailure(saveLicenseError, lookupValues, licenseRequestWorkParameterFields))
                        dispatch(hideModal())
                    })
                    .finally(() => {
                        dispatch(hideModal());
                    })
            }
        }
    }
};


export const getClaimsDetailsThunk = (claimID: number, lookupValues: ILookupDictionary): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading())
        return SearchRequests.getClaim(claimID)
            .then((res: IClaim) => {
                dispatch(getClaimSuccess(res));

                let newClaimTab: ITabReduxItem = {
                    title: "Claim: " + res.claimID,
                    component: CLAIM_MAINTENANCE_PAGE.component,
                    claimMaintenanceState: {
                        loaded: false,
                        claim: ClaimDetailsMapper.mapClaimToState(res),
                        isNew: false
                    },
                    changesMade: false,
                };
                dispatch(addTab(newClaimTab));
                dispatch(hideModal())
            })
            .catch(err => {
                dispatch(getClaimFailure(err));
                dispatch(hideModal())
            });
    };
};

export const submitLicenseToCRMThunk = (licenseRequestID: number, clientID: string): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading())
        return RepertoireService.submitLicenseToCRM(licenseRequestID, clientID)
            .then((res: ILicenseSubmitResult) => {
                dispatch(submitLicenseSuccess(res, licenseRequestID));
                dispatch(hideModal())
            })
            .catch((err) => {
                dispatch(submitLicenseFailure(err, licenseRequestID));
                dispatch(hideModal())
            })
    }
};


export const getCommentCategoryFlagThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getCommentCategoryFlagRequest());
        return SearchRequests.getConfigurationParameter(COMMENT_CATEGORY_FLAG_CONFIGURATION_KEY, CUSTOMER_CONFIGURATION_GROUP)
            .then((res: any) => {
                dispatch(getCommentCategoryFlagSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getCommentCategoryFlagFailure());
            });
    }
};

export const getAgreementAttachmentTypeRequiredThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getAgreementAttachmentTypeRequiredRequest());
        return SearchRequests.getConfigurationParameter(AGREEMENT_ATTACHMENT_TYPE_REQUIRED_CONFIGURATION_KEY, AGREEMENT_MAINTENANCE_CONFIGURATION_GROUP)
            .then((res: any) => {
                dispatch(getAgreementAttachmentTypeRequiredSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getAgreementAttachmentTypeRequiredFailure());
            });
    }
};


export const getExcludeSourceTypePickShowFieldsThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getExcludeSourceTypePickShowFieldsRequest());
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_EXCLUDE_SOURCE_TYPE_PICK_SHOW_FIELDS_KEY, WORK_MAINTENANCE_GROUP)
            .then((res: string[]) => {
                dispatch(getExcludeSourceTypePickShowFieldsSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getExcludeSourceTypePickShowFieldsFailure());
            });
    }
};



