import { SizedTextDataInput } from "../textDataInput/TextDataInput";
import * as React from "react";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import { IRepertoireField } from "../../types/IRepertoireField";
import {
    SEARCH_VIEW_WORKS,
    SEARCH_VIEW_ARTISTS,
    SEARCH_VIEW_CONTRIBUTORS,
    SEARCH_VIEW_AGREEMENTS,
    SEARCH_VIEW_IPS,
    APPLICABLE_WORKS_KEY,
    AGREEMENT_CARVEOUTS_KEY,
    AGREEMENT_CARVEOUTS_PRODUCT_NAME_STATE_KEY,
    SEARCH_VIEW_AGREEMENT_IP,
    SEARCH_VIEW_PRODUCTS,
    MATCHING_REPERTOIRE_WORKS_KEY,
    PRODUCT_WORKS_KEY,
    PRODUCT_PERFORMERS_KEY,
    PRODUCT_WORKS_REPERTOIRE_STATE_KEY,
    PRODUCT_CONTRIBUTORS_KEY,
    COMPONENTS_KEY,
    COMPONENTS_WORKS_REPERTOIRE_STATE_KEY,
    SEARCH_VIEW_WORKSOURCE_IP,
    SEARCH_VIEW_HAS_OPEN_WORKFLOW,
    EMPTY_STRING_VALUE,
    SEARCH_VIEW_WORKFLOW,
    WORKFLOW_WORKFLOW_TYPE_STATE_KEY,
    WORKFLOW_TYPE_VALIDATION_ERROR,
    WORKFLOW_TYPE_MATCHING_ERROR,    
    UPDATE_WORKFLOW_VIEW_STATUS_FIELD_STATE_KEY,
    WORKFLOW_ENTITY_TYPE_STATE_KEY,
    ASSING_TO_SEARCH_WORKFLOW_STATE_KEY,
    REFERENCE_STATE_KEY,
    RFERENCE_KEY_VALUE_STATE_KEY,
    REFERENCE_VALUE_STATE_KEY,
    VALUE_STATE_KEY,
    VALUE_KEY_VALUE_STATE_KEY,
    VALUE_MIN_STATE_KEY,
    VALUE_MAX_STATE_KEY,
    SEARCH_VIEW_PERFORMERS,
    SEARCH_VIEW_POOLS,
    HASOPEN_WORKFLOWS, DONOTCARE_ABOUTWORKFLOWS, WITHOUTOPEN_WORKFLOWS,
    SEARCH_VIEW_DISTRIBUTIONS,
    WORK_TYPE,
    LEGAL_ENTITY_TYPE,
    IS_DISPLAY,
    SEARCH_VIEW_WORK_CLAIMS,
    SEARCH_VIEW_ADJUSTMENTS,
    ALLOCATION_ADJUSTMENT_IP_KEY,
    SEARCH_VIEW_PAYMENTRUN,
    SEARCH_VIEW_JOB_IP,   
    SEARCH_VIEW_LICENSES,
    SEARCH_VIEW_LICENSE_USER,
    MATCHING_REPERTOIRE_PRODUCTS_KEY,
    SHOPPINGCART_KEY,
    USAGE_DETAILS_ROWS_SECTION_KEY,
    SEARCH_VIEW_WORK_LICENSES,
    SEARCH_VIEW_LICENSES_FILEIMPORT,
    SEARCH_VIEW_PRODUCTSOURCE_IP,
    PRODUCT_WORK_SOURCE_STATE_KEY,
    SEARCH_VIEW_CLAIMS,
    SEARCH_VIEW_SUBJECTS,
    SIMPLE,
    ADVANCED,
    COMPONENT_INSTANCE_IPS
} from "../../Consts";
import {
    DRAFT_WORKS_STATUS_SEARCH_TYPE, MEMBERS_PORTAL_VIEW_STATEMENTS_FROM_SEARCH_TYPE, SEARCH_VIEW_DRAFT_WORKS, SEARCH_VIEW_MEMBER_STATEMENTS, SEARCH_VIEW_REQUEST_ACCESS, 
    DRAFT_SET_LISTS_WORK_STATE_KEY, DRAFT_WORKS_STATE_KEY, REGISTERED_WORKS_STATE_KEY,
    SET_LISTS_WORK_SEARCHVIEW_STATE_KEY, INTRAY, REPERTOIRE,
    DRAFT_AGREEMENTS_APPLICABLE_WORKS_STATE_KEY
} from "../../../membersportal/Consts";
import SearchResultsTable from "../searchResultsTable/SearchResultsTable";
import SelectionTable from "../selectionTable/SelectionTable";
import { IWorksSearchResult } from "../../types/IWorksSearchResult";
import { addArtistToWork, addArtistToProduct, updateRelatedWork, updateWorkSourceIP,selectSingleWorkflow, clearWorkflowSelection, selectAllWorkflow, markSelectAll, nextWorkflowInSession, startWorkflowSession, setErrorWorkflowSession, addIPToAdjustmentDetails, addWorkToLicenseRequest, addIPToUsageDetails, updateProductSourceIP, addWorkToClaimRequest } from "../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import ActionButton, { SizedActionButton } from "../actionButton/ActionButton";
import { IWorksSearchQuery } from "../../types/IWorksSearchQuery";
import { IContributorSearchQuery } from "../../types/IContributorSearchQuery";
import { IArtist } from "../../types/IArtist";
import { IContributorSearchResult } from "../../types/IContributorSearchResult";
import { IArtistSearchQuery } from "../../types/IArtistSearchQuery";
import { openassignWorkflowTo, hideModal, showModal, showUpdateWorkflow } from "../../../redux/reducers/ModalReducer";
import { SizedDropdownDataInput } from "../dropdownDataInput/DropdownDataInput";
import { getLookupValues, mapLookupKey } from "../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { IAgreementSearchQuery } from "../../types/IAgreementSearchQuery";
import { IAgreementSearchResult } from "../../types/IAgreementSearchResult";
import { IIPsSearchQuery } from "../../types/IIPsSearchQuery";
import { IIPsSearchResult } from "../../types/IIPsSearchResult";
import { IP } from "../../types/IP";
import { IContributorItem } from "../../types/IContributorItem";
import { IProductSearchResult } from "../../types/IProductSearchResult";
import { IProductSearchQuery } from "../../types/IProductSearchQuery";
import { DataSource } from "../../types/DataSource";
import { SizedCheckboxDataInput } from "../checkboxDataInput/CheckboxDataInput";
import { IWorkflowSearchQuery } from "../../types/IWorkflowSearchQuery";
import { IWorkflowSearchResult } from "../../types/IWorkflowSearchResult";
import SelectorView from "../selectorView/SelectorView";
import { If } from "../../../core/components/If";
import WorkflowPageToolbar from "../../workflows/components/workflowPageToolbar/WorkflowPageToolbar";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { IDataActionToolbar } from "../../types/IDataActionToolbar";
import { FormatFields } from "../../../redux/types/FormatFields";
import { IUsagePoolSearchQuery } from "../../types/usageTypes/IUsagePoolSearchQuery";
import { IUsagePool } from "../../types/usageTypes/IUsagePool";
import { IDistributionSearchQuery } from "../../types/usageTypes/IDistributionSearchQuery";
import { IDistribution } from "../../types/usageTypes/IDistribution";
import { SizedRadiobuttonDataInput } from "../radioButtonDataInput/RadiobuttonDataInput";
import { ComponentsHelper } from "../../../core/services/ComponentHelper";
import { ILookupInstance } from "../../../lookup/types/ILookup";
import { IAdjustmentSearchQuery } from "../../types/usageTypes/IAdjustmentSearchQuery";
import { IAdjustment } from "../../types/usageTypes/IAdjustment";
import SizedDateDataInput from "../dateDataInput/DateDataInput";
import { IStoragePathItemData } from '../../../dataingestion/types/IStoragePathItemData';
import { IClaimSearchResult } from "../../types/IClaimSearchResult";
import { IClaimSearchQuery } from "../../types/IClaimSearchQuery";
import IconTextButton from "../iconTextButton/IconTextButton";
import { IWorkflowReferenceKeyValue } from "../../types/IWorkflowReferenceKeyValue";
import { IPaymentRunSearchResult } from "../../types/IPaymentRunSearchResult";
import { IPaymentRunSearchQuery } from "../../types/IPaymentRunSearchQuery";
import { IPaymentRun } from "../../types/usageTypes/IPaymentRun";
import { IShoppingCartSearchResult } from "../../types/IShoppingCartSearchResult";
import { IShoppingCartSearchQuery } from "../../types/IShoppingCartSearchQuery";
import { ILicenseUserSearchQuery } from "../../types/ILicenseUserSearchQuery";
import { ILicenseUserSearchResult } from "../../types/ILicenseUserSearchResult";
import { IDraftWorkSearchResult } from "../../../membersportal/types/IDraftWorkSearchResult";
import { IDraftWorkSearchQuery } from "../../../membersportal/types/IDraftWorkSearchQuery";
import { IMembersPortalField } from "../../../membersportal/types/IMembersPortalField";
import { IRegisteredWorkSearchQuery } from "../../../membersportal/types/IRegisteredWorkSearchQuery";
import { IRegisteredWork } from "../../../membersportal/types/IRegisteredWork";
import { ILicenseInputItem } from "../../../redux/types/ILicenseInputItem";
import { IMemberStatementSearchQuery } from "../../../membersportal/types/IMemberStatementSearchQuery";
import { AGREEMENT_MAINTENANCE_GROUP, PRODUCT_MAINTENANCE_GROUP, WORK_MAINTENANCE_GROUP } from "../../ConfigurationConsts";
import { IMemberStatementSearchResult } from "../../../membersportal/types/IMemberStatementSearchResult";
import StatementSearchResultsTable from "../searchResultsTable/StatementSearchResultsTable";
import { IDraftSetListSearchQuery } from "../../../membersportal/types/IDraftSetListSearchQuery";
import { IDraftSetListSearchResult } from "../../../membersportal/types/IDraftSetListSearchResult";
import { IRegisteredSetListsSearchQuery } from "../../../membersportal/types/IRegisteredSetListsSearchQuery";
import { IRegisteredSetListsSearchResults } from "../../../membersportal/types/IRegisteredSetListsSearchResults";
import { MemberPortalSearchTypeItem } from "../../../redux/types/MemberPortalSearchTypeItem";
import SizedDatePickerDataInput from "../dateDataInput/DatePickerDataInput";
import { ILivePerformanceSearchQuery } from "../../../membersportal/types/ILivePerformanceSearchQuery";
import { IUsageGroupResult } from "../../types/usageTypes/IUsageGroupsSearchResult";
import { ILivePerformanceResult } from "../../../membersportal/types/ILivePerformancesSearchResult";
import { WORK_STATUS_LOOKUP } from "../../../lookup/Consts";
import { IWorkSubjectSearchResult } from "../../types/IWorkSubjectSearchResult";
import { IWorkMaintenanceSubject } from "../../types/IWorkMaintenanceSubjects";
import { ISubjectsSearchQuery } from "../../types/ISubjectsSearchQuery";
import { ISubject } from "../../types/ISubject";
import SearchViewKeyEventListener from "./SearchViewKeyEventListener";
import { IAgreementStateKeys } from "../../../redux/types/IAgreementState";
import { ICwrgParameters } from "../../../redux/types/ICwrgParameters";
import { IMyPackagesResult } from "../../types/IMyPackagesResult";


export interface ISearchViewProps {
    memberPortalSearchType?: MemberPortalSearchTypeItem;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    workflowPageToolbarData?: IRepertoireComponentDataItem;
    componentInstance: string;
    modalProps: any;
    modalIndex?: number;
    modalKey?:string;
    dataSource: string;
    agreementsDataSource?: string;
    addArtistToWork?: typeof addArtistToWork;
    addArtistToProduct?: typeof addArtistToProduct;
    addWorkToProduct?: (work: IWorksSearchResult) => void;
    updateWorkContributorIP?: (workContributor: IContributorItem, ip: IP) => void;
    updateProductContributorIP?: (productContributorID: number, ip: IP) => void;
    updateAgreementIP?: (agreementAdminIPID: number, ip: IP) => void;
    updateAgreementWork?: (work: IWorksSearchResult) => void;
    updateAgreementCarveOutProduct?: (agreementProductWorkID: number, product: IProductSearchResult) => void;
    updateMatchingWork?: (workID: number, work: IWorksSearchResult) => void;
    updateMatchingProduct?: (productID: number, product: IProductSearchResult) => void;
    updateRelatedWork?: typeof updateRelatedWork;
    updateManualJobParams?: (params: any) => void;
    manualJobParameters?: any;
    getWorkDetails?: (dataSource: string, workID: number, isForWorkflowSession?: boolean) => void;
    getWorkDetailsWorkflow?: (dataSource: string, workID: number, selectedWorkflows: IWorkflowSearchResult[], openEntityForWorkflowSession?: boolean) => void;
    searchWorks?: (query: IWorksSearchQuery, modalOpen: boolean) => void;
    worksSearchResults?: IWorksSearchResult[];
    searchSubjects?: (query: ISubjectsSearchQuery, modalOpen: boolean) => void;
    updateSubjects?: (subject: ISubject) => void;
    searchArtists?: (query: IArtistSearchQuery, dataSource: string) => void;
    artistsSearchResults?: IArtist[];
    searchContributors?: (
        query: IContributorSearchQuery
    ) => void;
    contributorsSearchResults?: IContributorSearchResult[];
    searchAgreements?: (query: IAgreementSearchQuery, lookupValues: ILookupDictionary) => void;
    agreementsSearchResults?: IAgreementSearchResult[];
    getAgreementDetails?: (dataSource: string, agreementID: number, isForWorkflowSession?: boolean) => void;
    getArtistDetails?: (performerID: number) => void;
    getIPDetails?: (ipBaseNumber: string) => void;
    ipBaseNumber?: (ipBaseNumber: number) => void;
    searchIPs?: (query: IIPsSearchQuery, keepModalOpenAfterSearch?: boolean) => void;
    ipsSearchResults?: IIPsSearchResult[];
    searchProducts?: (query: IProductSearchQuery, modalOpen: boolean) => void;
    productSearchResults?: IProductSearchResult[];
    getProductDetails?: (dataSource: string, productCoreID: number, isForWorkflowSession?: boolean, openNewTab?: boolean,id1?:string,id2?:string,type?:string,originOfRevision?:string,productionType?:string,musicDuration?:number[]) => void;
    hideModal: typeof hideModal;
    lookupValues?: ILookupDictionary;
    searchSuccessful?: boolean;
    updateWorkSourceIP?: typeof updateWorkSourceIP;
    updateProductSourceIP?: typeof updateProductSourceIP;
    subjectSearchResults?: IWorkSubjectSearchResult[];
    expandWorkResult?: (workResult: number) => void;
    expandedWorkResults?: number[];
    expandProductResult?: (productResult: number) => void;
    expandedProductResults?: number[];
    expandAll?: boolean;
    expandAllResults?: () => void;
    searchWorkflow?: (query: IWorkflowSearchQuery, modalOpen: boolean) => void;
    workflowSearchResults?: IWorkflowSearchResult[];
    selectWorkflow?: typeof selectSingleWorkflow;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    showModal?: typeof showModal;
    scroll?: number;
    toolbarWidth?: number;
    onOpenAssignView?: typeof openassignWorkflowTo;
    assingWorkflowDataView?: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    onAssignWorkflow?: (userName: string) => void;
    onUpdateStatusWorkflow?: (status: string) => void;
    updateWorkflowViewData?: IRepertoireComponentDataItem;
    onOpenUpdateWorkflows?: typeof showUpdateWorkflow;
    clearSelection?: typeof clearWorkflowSelection;
    users?: {
        code: string;
        description: string;
    }[];
    workflowStatusList?: { code: string; description: string; }[];
    workflowEntityTypeList?: { code: string; description: string; }[];
    workflowPageData?: IRepertoireComponentDataItem;
    selectAllWorkflow?: typeof selectAllWorkflow;
    isSelectedAll?: boolean;
    markSelectAll?: typeof markSelectAll;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    searchType?: string;
    startWorkflowSession?: typeof startWorkflowSession;
    cancelWorkflowSession?: (activeTab: number) => void;
    nextWorkflowItem?: typeof nextWorkflowInSession;
    skipWorkflowItem?: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , lookups: ILookupDictionary
        , otherIndicatorsWorkFlagTypes: string[]
        , dataActions: IDataActionToolbar[]
        , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
        , formats: FormatFields[]
        , readonlyIndicatorsWorkFlagTypes: string[]) => void;
    workflowSessionItems?: IWorkflowSearchResult[];
    dataActionList?: IDataActionToolbar[];
    workFormats?: FormatFields[];
    workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    isAnySelectedCompletedError?: boolean;
    setErrorWorkflowSession?: typeof setErrorWorkflowSession;
    openEntityForWorflowSession?: boolean;
    currentWorkflowIndex?: number;
    searchUsagePools?: (query: IUsagePoolSearchQuery) => void;
    usagePoolsSearchResults?: IUsagePool[];
    getUsagePoolDetails?: (poolId: number) => void;
    searchDistributions?: (query: IDistributionSearchQuery) => void;
    distributionsSearchResults?: IDistribution[];
    searchAdjustments?: (query: IAdjustmentSearchQuery, dataSource?: string) => void;
    adjustmentsSearchResults?: IAdjustment[];
    getDistributionDetails?: (distributionID: number, lookupValues: ILookupDictionary) => void;
    getUsageDetails?: (usageID: string, openEntityForWorflowSession?: boolean) => void;
    skipUsageWorkflowItem?: (activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]) => void;
    sortSearchResults?: (name: string, results: any) => void;
    workGroupId?: string;
    distributions?: IDistribution[];
    pools?: { code: string; description: string; }[];
    getAdjustmentDetails?: (adjustmentId) => void;
    updateStoragePathItemIP?: (storagePathItemData: IStoragePathItemData, ip: IP) => void;
    searchClaims?: (query: IClaimSearchQuery) => void;
    claimSearchResults?: IClaimSearchResult[];
    getClaimDetails?: (claimID: number, lookupValues: ILookupDictionary) => void;
    addIpToAdjustmentDetail?: typeof addIPToAdjustmentDetails;
    addIPToUsageDetails?: typeof addIPToUsageDetails;
    searchPaymentRuns?: (query: IPaymentRunSearchQuery) => void;
    paymentRunSearchResults?: IPaymentRun[];
    getPaymentRunDetails?: (paymentRunID: number, lookupValues: ILookupDictionary) => void;
    searchDisabled?: boolean;
    searchLicenses?: (query: IShoppingCartSearchQuery) => void;
    licensesSearchResults?: IShoppingCartSearchResult[];
    getLicenseDetails?: (licenseID: number, lookupValues: ILookupDictionary, licenseRequestWorkParameterFields: ILicenseInputItem[]) => void;
    searchLicenseUser?: (query: ILicenseUserSearchQuery) => void;
    licenseUserSearchResults?: ILicenseUserSearchResult[];
    updateLicenceUserValues?: (params: any) => void;
    searchDraftWorks?: (query: IDraftWorkSearchQuery, isModalOpen?: boolean) => void;
    draftWorkSearchResults?: IDraftWorkSearchResult[];
    searchDraftSetLists?: (query: IDraftSetListSearchQuery) => void;
    draftSetListsSearchResults?: IDraftSetListSearchResult[];
    getDraftWorkDetails?: (dataSource: string, workId: number) => void;
    getDraftSetListDetails?: (dataSource: string, productCoreId: number) => void;
    isModalSearch?: boolean;
    addWorkToLicenseRequest?: typeof addWorkToLicenseRequest;
    searchRegisteredWorks?: (query: IRegisteredWorkSearchQuery) => void;
registeredWorkSearchResults?: IRegisteredWork[];
    getRegisteredWorkDetails?: (workId: number, lookupValues: ILookupDictionary) => void;
    requestViewAsAccess?: (requestAccess: IContributorItem) => void;
    searchMemberStatements?: (query: IMemberStatementSearchQuery) => void;
    getShowCommentSubjectAsDropdownConfigurationParameter?: (group: string) => void;
    groupName?: string;
    memberStatementSearchResults?: IMemberStatementSearchResult[];
    getMemberStatementDetails?: (paymentID: string) => void;
    expandMemberStatementResult?: (statementResult: number) => void;
    expandedMemberStatementResults?: number[];
    updateLicenseImportShoppingCart?: (storagePathItemData: IStoragePathItemData, shoppingCart: IShoppingCartSearchResult) => void;
    searchRegisteredSetLists?: (query: IRegisteredSetListsSearchQuery) => void;
    registeredSetListsSearchResults?: IRegisteredSetListsSearchResults[];
    isMembersPortal?: boolean;
    addSetListToLivePerformance?: (setList: IDraftSetListSearchResult) => void;
    addWorkToSetList?: (work: IDraftWorkSearchResult) => void;
    searchLivePerformances?: (query: ILivePerformanceSearchQuery) => void;
    livePerformanceSearchResults?: ILivePerformanceResult[];
    getLivePerformanceDetails?: (usageId: string) => void;
    addWorkToClaimRequest?: typeof addWorkToClaimRequest;
    currentUserNameNumber?: string;
    draftAgreementIPSearchResult?: IIPsSearchResult[];
    updateDraftAgreementField?: (value: any, fieldName: IAgreementStateKeys, index?: number) => void;
    draftAgreementWorksSearchResult?: IWorksSearchResult[];
    updateDraftAgreementWorkField?:(value: any, fieldName: IAgreementStateKeys, index?: number)=>void;
    getCWRGHighWaterMark?: (ipNameNumber: string) => void;
    addToPackages?:(userName:string,entityType:string,entityID:number)=>IMyPackagesResult;
    currentUser?:string;
    resultPageData?: IRepertoireComponentDataItem;
}

const initialState = (workGroupId) => { return {
        tableContents: [],
        title: "",
        artist: "",
        number: "",
        fullName: "",
        firstName: "",
        lastName: "",
        type: "",
        writers: "",
        workType: "",
        societyAccountNumber: "",
        ipiNumber: null,
        ipCode: "",
        ipName: "",
        accountNumber: "",
        publisherName: "",
        ipBaseNumber: "",
        ipNameNumber: "",
        ipType: "",
        legalEntityType: "",
        legalEntity: "",
        agreementType: "",
        agreementReference: "",
        agreementCategory: "",
        setTypeGroup: "",
        agreementId: 0,
        agreementBatchID: null,
        setPageOne: 0,
        region: "",
        workBatchID: null,
        contributor: "",
        productType: "",
        source: "",
        recordCompany: "",
        productBatchID: null,
        hasOpenWorkflow: null,
        entityType: EMPTY_STRING_VALUE,
        status: EMPTY_STRING_VALUE,
        workflowType: WORKFLOW_TYPE_VALIDATION_ERROR,
        assignedTo: EMPTY_STRING_VALUE,
        referenceKey: EMPTY_STRING_VALUE,
        referenceValue: EMPTY_STRING_VALUE,
        valueKey: EMPTY_STRING_VALUE,
        valuesMin: EMPTY_STRING_VALUE,
        valuesMax: EMPTY_STRING_VALUE,
        poolCode: "",
        cat1: "",
        cat2: "",
        allocationRule: "",
        year: "",
        distributionType: "",
        distributionStatus: "",
        distributionCode: "",
        ipNumber: "",
        id: "",
        workGroupID: workGroupId,
        adjustmentDateFrom: "",
        adjustmentDateTo: "",
        user: null,
        datasource: "",
        sourceDistribution: null,
        adjustmentDistribution: "",
        sourcePool: "",
        adjustmentCategory: null,
        adjustmentBatchID: null,
        adjustmentStatus: null,
        referenceKeyValueRows: [{ referenceKey: '', referenceValue: '', index: 0 }],
        fromDateCreated: "",
        toDateCreated: "",
        paymentRunID: "",
        paymentRunType: "",
        paymentRunDateFrom: null,
        paymentRunDateTo: null,
        paymentRunStatus: "",
        licenseID: "",
        email: "",
        workNumber: "",
        subjectCode: "",
        description: "",
        searchType:"",

        // Members Portal
        draftWorkStatus: "",
        draftWorkTitle: "",
        draftWorkNumber: "",
        draftWorkArtist: "",
        draftWorkIPNumber: "",
        draftWorkIPName: "",
        currentUserNameNumber: "",

        draftSetListID: "",
        draftSetListStatus: "",
        draftSetListTitle: "",

        memberStatementViewStatementsFrom: "",
        memberStatementCustomRange: false,
        memberStatementDateFrom: null,
        memberStatementDateTo: null,
        memberStatementPaymentID: "",
        memberStatementDistributionCode: "",
        memberStatementPool: "",
        memberStatementWorkNumber: "",

        livePerformanceSubmissionFromDate: null,
        livePerformanceSubmissionToDate: null
}
}
interface ISearchViewState {
    tableContents?: IWorksSearchResult[] | IArtist[] | IContributorSearchResult[] | IAgreementSearchResult[] | IIPsSearchResult[] | IProductSearchResult[] | IWorkflowSearchResult[] | IClaimSearchResult[] | IPaymentRunSearchResult[] | IShoppingCartSearchResult[]
    | IDraftWorkSearchResult[] | IMemberStatementSearchResult[] | IDraftSetListSearchResult[] | IRegisteredSetListsSearchResults[];
    title?: string;
    artist?: string;
    number?: string;
    fullName?: string;
    firstName?: string;
    lastName?: string;
    type?: string;
    writers?: string;
    workType?: string;
    societyAccountNumber?: string;
    societyOfLicense?: string;
    ipiNumber?: number;
    ipCode?: string;
    ipName?: string;
    accountNumber?: string;
    claimStatus?: string;
    claimCode?: string;
    publisherName?: string;
    setPageOne?: number;
    workBatchID?: string;
    ipBaseNumber?: string;
    ipNameNumber?: string;
    ipType?: string;
    legalEntityType?: string;
    legalEntity?: string;
    agreementType?: string;
    agreementReference?: string;
    agreementCategory?: string;
    setTypeGroup?: string;
    agreementId?: number;
    agreementBatchID?: string;
    region?: string;
    contributor?: string;
    productType?: string;
    source?: string;
    recordCompany?: string;
    productBatchID?: string;
    hasOpenWorkflow?: boolean;
    entityType?: string;
    status?: string;
    workflowType?: string;
    assignedTo?: string;
    referenceKey?: string;
    referenceValue?: string;
    valueKey?: string;
    valuesMin?: string;
    valuesMax?: string;
    poolCode?: string;
    cat1?: string;
    cat2?: string;
    allocationRule?: string;
    year?: string;
    distributionType?: string;
    distributionStatus?: string;
    distributionCode?: string;
    ipNumber?: string;
    workGroupID?: string;
    id?: string;
    adjustmentDateFrom?: string;
    adjustmentDateTo?: string;
    user?: string;
    datasource?: string;
    sourceDistribution?: string;
    adjustmentDistribution?: string;
    sourcePool?: string;
    adjustmentCategory?: string;
    adjustmentBatchID?: string;
    adjustmentStatus?: string;
    referenceKeyValueRows?: IWorkflowReferenceKeyValue[],
    fromDateCreated?: string;
    toDateCreated?: string;
    paymentRunID?: string;
    paymentRunType?: string;
    paymentRunDateFrom?: Date;
    paymentRunDateTo?: Date;
    paymentRunStatus?: string;
    licenseID?: string;
    email?: string;
    workNumber?: string;
    currentUserNameNumber?: string;
    subjectCode?: string;
    description?: string;
    searchType?:string;
    modalIndex?:number;
    modalKey?:string;

    // Members Portal
    draftWorkStatus?: string;
    draftWorkTitle?: string;
    draftWorkNumber?: string;
    draftWorkArtist?: string;
    draftWorkIPNumber?: string;
    draftWorkIPName?: string;

    draftSetListStatus?: string;
    draftSetListID?: string;
    draftSetListTitle?: string;

    memberStatementViewStatementsFrom?: string;
    memberStatementCustomRange?: boolean;
    memberStatementDateFrom?: Date;
    memberStatementDateTo?: Date;
    memberStatementPaymentID?: string;
    memberStatementDistributionCode?: string;
    memberStatementPool?: string;
    memberStatementWorkNumber?: string;

    livePerformanceSubmissionFromDate?: string;
    livePerformanceSubmissionToDate?: string;
}

interface ISearchViewDataKeys {
    title: string;
    artist: string;
    number: string;
    fullName: string;
    firstName: string;
    lastName: string;
    workType: string;
    type: string;
    writers: string;
    societyAccountNumber: string;
    societyOfLicense: string;
    ipiNumber: string;
    ipCode: string;
    ipName: string;
    accountNumber: string;
    publisherName: string;
    workBatchID: string;
    ipBaseNumber: string;
    ipNameNumber: string;
    ipType: string;
    legalEntityType: string;
    legalEntity: string;
    agreementType: string;
    agreementReference: string;
    agreementCategory: string;
    setTypeGroup: string;
    agreementId: number;
    agreementBatchID: string;
    contributor: string;
    productType: string;
    source: string;
    recordCompany: string;
    productBatchID: string;
    hasOpenWorkflow?: boolean;
    entityType: string;
    status: string;
    workflowType: string;
    assignedTo: string;
    referenceKey: string;
    referenceValue: string;
    valueKey: string;
    valuesMin: string;
    valuesMax: string;
    poolCode: string;
    cat1: string;
    cat2: string;
    allocationRule: string;
    year: string;
    distributionType: string;
    distributionStatus: string;
    distributionCode: string;
    ipNumber: string;
    workGroupID: string;
    id: string;
    adjustmentDateFrom: string;
    adjustmentDateTo: string;
    user: string;
    datasource: string;
    sourceDistribution: string;
    adjustmentDistribution: string;
    sourcePool: string;
    adjustmentCategory: string;
    adjustmentBatchID: string;
    adjustmentStatus: string;
    fromDateCreated: string;
    toDateCreated: string;
    livePerformanceSubmissionFromDate: string;
    livePerformanceSubmissionToDate: string;
    searchType:string;
    modalIndex?:number;
    modalKey?:string;
}

export type SearchViewStateKeys = keyof ISearchViewDataKeys;

export default class SearchView extends React.Component<
    ISearchViewProps,
    ISearchViewState
> {
    constructor(props: ISearchViewProps) {
        super(props);

        const { workGroupId } = this.props;
        this.state = initialState(workGroupId)
    }

    componentDidMount = () => {
        const { getShowCommentSubjectAsDropdownConfigurationParameter, groupName } = this.props;

        if (getShowCommentSubjectAsDropdownConfigurationParameter && groupName &&
            (groupName === AGREEMENT_MAINTENANCE_GROUP || groupName === WORK_MAINTENANCE_GROUP || groupName === PRODUCT_MAINTENANCE_GROUP)) {
            getShowCommentSubjectAsDropdownConfigurationParameter(groupName);
        }
    }
    setStateInitial = () => {
        const { workGroupId } = this.props;
        this.setState(initialState(workGroupId));
    }

    componentDidUpdate = (prevProps: ISearchViewProps) => {
        const { dataSource, isModalSearch } = this.props;
        if (prevProps.dataSource !== dataSource) {
            this.setState({
                tableContents: undefined
            });
        }
    };

    changeData = (value: any, fieldName: IRepertoireStateKeys, index?: number) => {
        if (fieldName === 'referenceKey') {
            this.changeReferenceKeyValueRowData(value, fieldName, index)
        }
        else if ((fieldName === "paymentRunDateFrom" || fieldName === "paymentRunDateTo" || fieldName === "livePerformanceSubmissionFromDate" || fieldName === "livePerformanceSubmissionToDate") && value === "") {
            this.setState({
                [fieldName]: null
            } as Pick<ISearchViewState, keyof ISearchViewDataKeys>);
        }
        else {
            this.setState({
                [fieldName]: value
            } as Pick<ISearchViewState, keyof ISearchViewDataKeys>);
        }
    }

    changeTextData = (value: any, fieldName: IRepertoireStateKeys, isOnBlur?: boolean, index?: number) => {
        if (fieldName === 'referenceValue') {
            this.changeReferenceKeyValueRowData(value, fieldName, index)
        }
        else {
            this.setState({
                [fieldName]: value
            } as Pick<ISearchViewState, keyof ISearchViewDataKeys>);
        }
    }

    changeDataDisabled = () => {
    }

    changeReferenceKeyValueRowData = (value: string, fieldName: string, index: number) => {
        const { referenceKeyValueRows } = this.state;
        let rkvs = referenceKeyValueRows;
        if (fieldName === 'referenceKey')
            rkvs[index].referenceKey = value;
        else
            rkvs[index].referenceValue = value;

        this.setState({ referenceKeyValueRows: rkvs })
    }


    keyDownSubmit = (value: any, fieldName: IRepertoireStateKeys) => {
        const { searchDisabled } = this.props
        if (value.key === 'Enter' && !searchDisabled) {
            this.onClickSearch();
        }
    }

    disabledSearchFields = (name: string) => {
        let fieldsToCheck = ["ipBaseNumber", "ipNameNumber", "ipName", "ipiNumber", "fullName"];
        if (fieldsToCheck.indexOf(name) > -1) {
            if (this.state[fieldsToCheck.filter(x => x !== name)[0]] || this.state[fieldsToCheck.filter(x => x !== name)[1]] || this.state[fieldsToCheck.filter(x => x !== name)[2]] || this.state[fieldsToCheck.filter(x => x !== name)[3]]) {
                return true;
            }
        }
        else
            return false;
    }

    memberStatementDisabledSearchFields = (name: string) => {
        let isCustomRangeChecked: boolean = this.state['memberStatementCustomRange'].valueOf();

        if (name === 'memberStatementViewStatementsFrom' && isCustomRangeChecked)
            return true;
        if (name === 'memberStatementViewStatementsFrom' && !isCustomRangeChecked)
            return false;

        if ((name === 'memberStatementDateTo' || name === 'memberStatementDateFrom') && !isCustomRangeChecked)
            return true;
        if ((name === 'memberStatementDateTo' || name === 'memberStatementDateFrom') && isCustomRangeChecked)
            return false;
    }

    setWorkflowType(type: string) {
        if (type.length > 0)
            this.setState({ workflowType: type })
    }

    setHasOpenWorkFlow(option: string) {
        let openWorkflowsearch: boolean = null;
        if (option.length > 0)
            switch (option) {
                case DONOTCARE_ABOUTWORKFLOWS:
                    openWorkflowsearch = null;
                    break;
                case HASOPEN_WORKFLOWS:
                    openWorkflowsearch = true;
                    break;
                case WITHOUTOPEN_WORKFLOWS:
                    openWorkflowsearch = false;
                    break
            }
        this.setState({ hasOpenWorkflow: openWorkflowsearch })
    }

    renderSearchFields = () => {
        const { searchViewData, lookupValues, dataSource, users, workflowStatusList, workflowEntityTypeList, distributions, pools, isMembersPortal } = this.props;
        let searchFromModal: boolean = false;

        let componentInstance = this.props.componentInstance;
        switch (componentInstance) {
            case COMPONENTS_KEY:
            case APPLICABLE_WORKS_KEY:
            case AGREEMENT_CARVEOUTS_PRODUCT_NAME_STATE_KEY:
            case PRODUCT_WORKS_KEY:
            case SEARCH_VIEW_WORK_CLAIMS:
            case SEARCH_VIEW_WORK_LICENSES:
                searchFromModal = true;
                if (isMembersPortal) {
                    componentInstance = DRAFT_WORKS_STATE_KEY;
                }
                else {
                    componentInstance = SEARCH_VIEW_WORKS;
                }

                break;
            case COMPONENTS_KEY:
            case MATCHING_REPERTOIRE_WORKS_KEY:
                searchFromModal=true;
            case PRODUCT_WORKS_REPERTOIRE_STATE_KEY:
            case COMPONENTS_WORKS_REPERTOIRE_STATE_KEY:
                componentInstance = SEARCH_VIEW_WORKS;
                break;
            case PRODUCT_PERFORMERS_KEY:
            case SEARCH_VIEW_PERFORMERS:
                componentInstance = SEARCH_VIEW_ARTISTS;
                break;
            case PRODUCT_CONTRIBUTORS_KEY:
            case SEARCH_VIEW_WORKSOURCE_IP:
            case SEARCH_VIEW_PRODUCTSOURCE_IP:
            case SEARCH_VIEW_JOB_IP:
                componentInstance = SEARCH_VIEW_CONTRIBUTORS;
                break;
            case ALLOCATION_ADJUSTMENT_IP_KEY:
                componentInstance = SEARCH_VIEW_IPS;
                break;
            case MATCHING_REPERTOIRE_PRODUCTS_KEY:
                componentInstance = SEARCH_VIEW_PRODUCTS;
                break;
            case SHOPPINGCART_KEY:
                componentInstance = SEARCH_VIEW_LICENSES;
                break;
            case USAGE_DETAILS_ROWS_SECTION_KEY:
                componentInstance = SEARCH_VIEW_IPS;
                break;
            case DRAFT_SET_LISTS_WORK_STATE_KEY:
                componentInstance = dataSource == DataSource.Repertoire ? REGISTERED_WORKS_STATE_KEY : DRAFT_WORKS_STATE_KEY;
                break;
            case DRAFT_AGREEMENTS_APPLICABLE_WORKS_STATE_KEY:
                if (isMembersPortal) {
                    //searchFromModal = true;
                componentInstance = DRAFT_AGREEMENTS_APPLICABLE_WORKS_STATE_KEY;
            }
        }
        const workTypeDropDownLookup = (fieldName: string): ILookupInstance[] => {
            const workTypes = getLookupValues(fieldName, lookupValues);
            let options = workTypes ? workTypes.filter(type => type.extraFields[IS_DISPLAY] === true) : null;
            if (options) {
                options.push({ typeID: -1, code: null, description: "All" })
            }
            return options;
        }

        const memberStatementDateFromLookup = (fieldName: string): ILookupInstance[] => {
            const dateSegments = getLookupValues(fieldName, lookupValues);
            return dateSegments;
        }

        if (searchViewData && searchViewData.fields) {

            let filteredSearchFields = searchViewData.fields.filter(
                (searchField: IRepertoireField | IMembersPortalField) =>
                    searchField.componentInstance === componentInstance
            );

            if (dataSource === DataSource.Repertoire) {
                filteredSearchFields = filteredSearchFields.filter(
                    (searchField: IRepertoireField | IMembersPortalField) =>
                        searchField.name != SEARCH_VIEW_HAS_OPEN_WORKFLOW
                );
            }

            if (isMembersPortal && dataSource === DataSource.Repertoire) {
                filteredSearchFields = filteredSearchFields.filter(
                    (searchField: IRepertoireField | IMembersPortalField) =>
                        searchField.name != 'dataSource'
                );
            }


            if (searchFromModal) {

                const idDataSource = filteredSearchFields.findIndex(x => x.name == 'dataSource');
                filteredSearchFields[idDataSource].hidden = true;

                if (!isMembersPortal) {
                    const idSearchType = filteredSearchFields.findIndex(x => x.name == 'searchType');
                    filteredSearchFields[idSearchType].hidden = true;
                }


            }

            let filteredSearchFieldsMapper = (searchField: IRepertoireField | IMembersPortalField, index: number): React.ReactElement => {
                let reactElement: React.ReactElement = <></>;

                if ((searchField.fieldType === 'text' || searchField.fieldType === 'integer' || searchField.fieldType === 'decimal') && searchField.name !== WORKFLOW_WORKFLOW_TYPE_STATE_KEY)
                    reactElement =
                            <SizedTextDataInput
                                fieldType={searchField.fieldType}
                                useEnterSubmit={true}
                                label={searchField.data}
                                fieldName={searchField.name}
                                changeData={this.disabledSearchFields(searchField.name) ? this.changeDataDisabled : (value, fieldName) => this.changeTextData(value, fieldName)}
                                handleKeyDown={this.keyDownSubmit}
                                value={searchField.name === 'values' ? EMPTY_STRING_VALUE : this.state[searchField.name]}
                                readOnly={this.disabledSearchFields(searchField.name)}
                                isHidden={searchField.hidden}
                                key={`${index}`}
                            />
                else if (searchField.name === WORKFLOW_WORKFLOW_TYPE_STATE_KEY && componentInstance === SEARCH_VIEW_WORKFLOW)
                    reactElement =
                            <SelectorView
                                selection={this.state[searchField.name]}
                                setSelection={this.setWorkflowType.bind(this)}
                                option1={WORKFLOW_TYPE_VALIDATION_ERROR}
                                option2={WORKFLOW_TYPE_MATCHING_ERROR}
                                selectorTitle={searchField.data}
                                customStyle={'noTopMargin'}
                                key={`${index}`}
                            />
                else if (searchField.fieldType === 'boolean' && searchField.name === SEARCH_VIEW_HAS_OPEN_WORKFLOW)
                    reactElement =
                            <SizedRadiobuttonDataInput
                            key={`${index}`}
                                selectedValue={this.state[searchField.name] === null ?
                                    DONOTCARE_ABOUTWORKFLOWS :
                                    this.state[searchField.name] === true ? HASOPEN_WORKFLOWS : WITHOUTOPEN_WORKFLOWS}
                                onSelectionChange={this.setHasOpenWorkFlow.bind(this)}
                                radioName="hasOpenWorkFlow"
                                title={searchField.data}
                                options={[
                                    { desc: ComponentsHelper.getFieldValueByName("doNotCareAboutWorkflowsDesc", searchViewData.fields), code: DONOTCARE_ABOUTWORKFLOWS },
                                    { desc: ComponentsHelper.getFieldValueByName("hasOpenWorkflowsDesc", searchViewData.fields), code: HASOPEN_WORKFLOWS },
                                    { desc: ComponentsHelper.getFieldValueByName("withoutOpenWorkflowsDesc", searchViewData.fields), code: WITHOUTOPEN_WORKFLOWS }
                                ]}
                            />
                else if (componentInstance === SEARCH_VIEW_IPS && searchField.name === LEGAL_ENTITY_TYPE)
                    reactElement =
                            <SizedRadiobuttonDataInput
                            key={`${index}`}
                                selectedValue={this.state.legalEntityType}
                                onSelectionChange={(option) => this.setState({ legalEntityType: option })}
                                radioName="legalEntityType"
                                title={searchField.data}
                                options={[
                                    { desc: ComponentsHelper.getFieldValueByName("legalEntity", searchViewData.fields), code: 'L' },
                                    { desc: ComponentsHelper.getFieldValueByName("naturalPerson", searchViewData.fields), code: 'N' },
                                    { desc: ComponentsHelper.getFieldValueByName("all", searchViewData.fields), code: '' },
                                ]}
                            />
                else if ((componentInstance === SEARCH_VIEW_CONTRIBUTORS || componentInstance === SEARCH_VIEW_AGREEMENT_IP || componentInstance === SEARCH_VIEW_REQUEST_ACCESS) && searchField.name === LEGAL_ENTITY_TYPE)
                    reactElement =
                            <SizedRadiobuttonDataInput
                            key={`${index}`}
                                selectedValue={this.state.legalEntityType}
                                onSelectionChange={(option) => this.setState({ legalEntityType: option })}
                                radioName="legalEntityType"
                                title={searchField.data}
                                options={[
                                    { desc: ComponentsHelper.getFieldValueByName("legalEntity", searchViewData.fields), code: 'L' },
                                    { desc: ComponentsHelper.getFieldValueByName("naturalPerson", searchViewData.fields), code: 'N' },
                                    { desc: ComponentsHelper.getFieldValueByName("all", searchViewData.fields), code: '' },
                                ]}
                            />
                else if (componentInstance === SEARCH_VIEW_MEMBER_STATEMENTS && searchField.fieldType === 'date')
                    reactElement =
                            <SizedDatePickerDataInput
                                value={this.state[searchField.name]}
                                fieldName={searchField.name}
                                changeData={this.changeData}
                                label={searchField.data}
                                isHidden={searchField.hidden}
                                readOnly={this.memberStatementDisabledSearchFields(searchField.name)}
                                key={`${index}`}
                            />
                else if (componentInstance === SET_LISTS_WORK_SEARCHVIEW_STATE_KEY && searchField.name === PRODUCT_WORK_SOURCE_STATE_KEY)
                    reactElement =
                            <SelectorView
                                selection={this.state.datasource === "" ? REPERTOIRE : this.state.datasource}
                                setSelection={(selection) => this.setState({
                                    datasource: selection
                                })}
                                option1={INTRAY}
                                option2={REPERTOIRE}
                                selectorTitle={searchField.data}
                                customStyle={'noTopMargin'}
                                key={`${index}`}

                            />
                else if (searchField.fieldType === 'date')
                    reactElement =
                            <SizedDatePickerDataInput
                                value={this.state[searchField.name]}
                                fieldName={searchField.name}
                                changeData={this.changeData}
                                label={searchField.data}
                                isHidden={searchField.hidden}
                                key={`${index}`}
                            />
                else if (searchField.fieldType === 'checkbox')
                    reactElement =
                        <SizedCheckboxDataInput
                            label={searchField.data}
                            fieldName={searchField.name}
                            value={this.state[searchField.name]}
                            changeData={this.changeData}
                            readonly={false}
                            key={`${index}`}
                        />
                        else {
                    let options: { code: string, description: string }[] = [];
                    let disabledDropDown = false;

                    if (componentInstance === SEARCH_VIEW_WORKFLOW && searchField.name === ASSING_TO_SEARCH_WORKFLOW_STATE_KEY || (componentInstance === SEARCH_VIEW_ADJUSTMENTS && searchField.name === 'user'))
                        options = users;
                    else if (componentInstance === SEARCH_VIEW_WORKFLOW && searchField.name === UPDATE_WORKFLOW_VIEW_STATUS_FIELD_STATE_KEY)
                        options = workflowStatusList;
                    else if (componentInstance === SEARCH_VIEW_WORKFLOW && searchField.name === WORKFLOW_ENTITY_TYPE_STATE_KEY)
                        options = workflowEntityTypeList;
                    else if (lookupValues && componentInstance === SEARCH_VIEW_WORKS && searchField.name === WORK_TYPE)
                        options = workTypeDropDownLookup(mapLookupKey(searchField.name, ''));
                    else if (lookupValues && componentInstance === SEARCH_VIEW_DRAFT_WORKS && searchField.name === DRAFT_WORKS_STATUS_SEARCH_TYPE) {
                        options = getLookupValues(WORK_STATUS_LOOKUP, lookupValues);
                    }
                    else if (lookupValues && componentInstance === SEARCH_VIEW_MEMBER_STATEMENTS && searchField.name === MEMBERS_PORTAL_VIEW_STATEMENTS_FROM_SEARCH_TYPE) {
                        options = memberStatementDateFromLookup(mapLookupKey(searchField.name, ''));
                        disabledDropDown = this.memberStatementDisabledSearchFields(searchField.name);
                    }
                    else if (lookupValues && getLookupValues(mapLookupKey(searchField.name, ''), lookupValues))
                        options = getLookupValues(mapLookupKey(searchField.name, ''), lookupValues);
                    else if (componentInstance === SEARCH_VIEW_ADJUSTMENTS && (searchField.name === 'sourceDistribution' || searchField.name === 'adjustmentDistribution'))
                        options = distributions.map((x) => ({ code: x.distributionCode, description: x.distributionCode }));
                    else if (componentInstance === SEARCH_VIEW_ADJUSTMENTS && searchField.name === 'sourcePool') {
                        if (this.state.sourceDistribution) {
                            let sourceDist = distributions.find(d => d.distributionCode === this.state.sourceDistribution);
                            let sourcePoolsList = sourceDist.distributionPools;
                            options = [...new Set(sourcePoolsList.map(p => p.poolCode))].sort().map(poolCode => ({ code: poolCode, description: poolCode }));
                        }
                        else
                            options = pools;
                    }
                    else
                        options = undefined;

                    reactElement =
                            <SizedDropdownDataInput
                                label={searchField.data}
                                fieldName={searchField.name}
                                changeData={this.changeData}
                                value={searchField.name === WORK_TYPE && !this.state[searchField.name] ? "All" : this.state[searchField.name]}
                                options={options}
                                allowNull={true}
                                isHidden={searchField.hidden}
                                componentInstance={componentInstance}
                                readOnly={disabledDropDown}
                                key={`${index}`}
                            />
                }

                if ((!(dataSource === DataSource.Intray)) && (searchField.name === "adjustmentStatus")) {
                    return;
                }

                return reactElement;

            };


            return <>
            <SearchViewKeyEventListener setStateInitial={this.setStateInitial.bind(this)} />
            {filteredSearchFields.map(filteredSearchFieldsMapper)}
            </>
            
                   }
    };

    onClickSearch = () => {
        const {
            title,
            artist,
            number,
            firstName,
            lastName,
            fullName,
            type,
            workType,
            writers,
            societyAccountNumber,
            ipiNumber,
            ipCode,
            ipName,
            accountNumber,
            claimStatus,
            claimCode,
            publisherName,
            setPageOne,
            workBatchID,
            ipBaseNumber,
            ipNameNumber,
            agreementType,
            agreementReference,
            agreementCategory,
            agreementId,
            agreementBatchID,
            region,
            setTypeGroup,
            contributor,
            productType,
            source,
            productBatchID,
            hasOpenWorkflow,
            entityType,
            status,
            workflowType,
            assignedTo,
            referenceKey,
            referenceValue,
            valueKey,
            valuesMin,
            valuesMax,
            poolCode,
            cat1,
            cat2,
            allocationRule,
            year,
            distributionType,
            distributionStatus,
            distributionCode,
            ipNumber,
            workGroupID,
            legalEntityType,
            id,
            adjustmentDateFrom,
            adjustmentDateTo,
            user,
            adjustmentCategory,
            adjustmentBatchID,
            adjustmentStatus,
            datasource,
            sourceDistribution,
            adjustmentDistribution,
            sourcePool,
            legalEntity,
            referenceKeyValueRows,
            fromDateCreated,
            toDateCreated,
            paymentRunID,
            paymentRunType,
            paymentRunDateFrom,
            paymentRunDateTo,
            paymentRunStatus,
            licenseID,
            email,
            workNumber,
            draftWorkStatus,
            draftWorkTitle,
            draftWorkNumber,
            draftWorkArtist,
            draftWorkIPNumber,
            draftWorkIPName,
            memberStatementViewStatementsFrom,
            memberStatementCustomRange,
            memberStatementDateFrom,
            memberStatementDateTo,
            memberStatementPaymentID,
            memberStatementDistributionCode,
            memberStatementPool,
            memberStatementWorkNumber,
            draftSetListID,
            draftSetListStatus,
            draftSetListTitle,
            subjectCode,
            description,
            recordCompany
        } = this.state;
        const {
            componentInstance,
            dataSource,
            agreementsDataSource,
            searchWorks,
            searchSubjects,
            searchArtists,
            searchContributors,
            searchAgreements,
            searchIPs,
            searchProducts,
            searchWorkflow,
            searchUsagePools,
            resetPagination,
            searchType,
            searchDistributions,
            searchAdjustments,
            lookupValues,
            searchPaymentRuns,
            searchLicenses,
            searchDraftWorks,
            isModalSearch,
            searchLicenseUser,
            searchMemberStatements,
            searchDraftSetLists,
            isMembersPortal,
            searchClaims,
            currentUserNameNumber,modalKey
        } = this.props;
        resetPagination(componentInstance);
        this.setState({ setPageOne: setPageOne + 1 })


        switch (componentInstance) {
            case SEARCH_VIEW_WORKS: {
                let searchQuery: IWorksSearchQuery = {
                    title,
                    number,
                    artist,
                    writers,
                    societyAccountNumber,
                    dataSource,
                    workBatchID,
                    hasOpenWorkflow,
                    workType,
                    workGroupID
                };
                if (isModalSearch) searchQuery.dataSource = DataSource.Repertoire
                searchWorks(searchQuery, false);
                break;
            }
            case SEARCH_VIEW_SUBJECTS: {
                let searchQuery: ISubjectsSearchQuery = {
                    subjectCode: subjectCode,
                    description: description,
                };
                searchSubjects(searchQuery, false);
                break;
            }
            case SEARCH_VIEW_ARTISTS: {
                const searchQuery: IArtistSearchQuery = {
                    fullName: fullName,
                    firstName: firstName,
                    lastName: lastName,
                    type: type,
                    number: number
                }
                searchArtists(searchQuery, DataSource.Repertoire);
                break;
            }
            case SEARCH_VIEW_PERFORMERS: {
                const searchQuery: IArtistSearchQuery = {
                    fullName: fullName,
                    firstName: firstName,
                    lastName: lastName,
                    type: type,
                    number: number
                }
                searchArtists(searchQuery, DataSource.Repertoire);
                break;
            }
            case PRODUCT_PERFORMERS_KEY: {
                const searchQuery: IArtistSearchQuery = {
                    fullName: fullName,
                    firstName: firstName,
                    lastName: lastName,
                    type: type,
                    number: number
                }
                searchArtists(searchQuery, DataSource.Repertoire);

                break;
            }
            case SEARCH_VIEW_CONTRIBUTORS: {
                let searchQuery: IContributorSearchQuery = {
                    ipNumber,
                    fullName,
                    accountNumber,
                    isMembersPortal,
                    legalEntityType: legalEntityType
                };
                searchContributors(searchQuery);
                break;
            }
            case SEARCH_VIEW_REQUEST_ACCESS: {
                let searchQuery: IContributorSearchQuery = {
                    ipNumber,
                    fullName,
                    accountNumber,
                    isMembersPortal,
                    legalEntityType: legalEntityType
                };
                searchContributors(searchQuery);
                break;
            }
            case SEARCH_VIEW_PRODUCTSOURCE_IP:
            case SEARCH_VIEW_WORKSOURCE_IP: {
                let searchQuery: IContributorSearchQuery = {
                    ipNumber,
                    fullName,
                    accountNumber,
                    isMembersPortal,
                    legalEntityType: legalEntityType
                };
                searchContributors(searchQuery);
                break;
            }
            case SEARCH_VIEW_AGREEMENT_IP: {
                let searchQuery: IContributorSearchQuery = {
                    ipNumber,
                    fullName,
                    accountNumber,
                    isMembersPortal,
                    legalEntityType: legalEntityType
                };
                searchContributors(searchQuery);
                break;
            }
            case SEARCH_VIEW_JOB_IP: {
                let searchQuery: IContributorSearchQuery = {
                    ipNumber,
                    fullName,
                    accountNumber,
                    isMembersPortal,
                    legalEntityType: legalEntityType
                };
                searchContributors(searchQuery);
                break;
            }
            case PRODUCT_CONTRIBUTORS_KEY: {
                let searchQuery: IContributorSearchQuery = {
                    ipNumber,
                    fullName,
                    accountNumber,
                    isMembersPortal,
                    legalEntityType: legalEntityType
                };
                searchContributors(searchQuery);
                break;
            }
            case SEARCH_VIEW_AGREEMENTS: {
                let searchQuery: IAgreementSearchQuery = {
                    ipBaseNumber,
                    ipNameNumber,
                    ipName,
                    type: agreementType,
                    agreementReference,
                    agreementCategory,
                    dataSource,
                    setTypeGroup,
                    agreementBatchID,
                    searchType: searchType
                };
                searchAgreements(searchQuery, lookupValues);
                break;
            }
            case SEARCH_VIEW_IPS: {
                let searchQuery: IIPsSearchQuery = {
                    ipNumber,
                    fullName,
                    accountNumber,
                    interestedPartyType:legalEntityType?legalEntityType:''
                };
                searchIPs(searchQuery);
                break;
            }
            case SEARCH_VIEW_PRODUCTS: {
                let searchQuery: IProductSearchQuery = {
                    productIDs: [],
                    productType,
                    title,
                    number,
                    contributor,
                    artist,
                    productBatchID,
                    dataSource,
                    hasOpenWorkflow,
                    recordCompany,
                    productSource: source
                };
                searchProducts(searchQuery, true);
                break;
            }
            case SEARCH_VIEW_LICENSE_USER: {
                let searchQuery: ILicenseUserSearchQuery = {
                    email
                };
                searchLicenseUser(searchQuery);
                break;
            }
            case MATCHING_REPERTOIRE_PRODUCTS_KEY: {
                let searchQuery: IProductSearchQuery = {
                    productIDs: [],
                    productType,
                    title,
                    number,
                    contributor,
                    artist,
                    productBatchID,
                    dataSource: DataSource.Repertoire,
                };
                searchProducts(searchQuery, false);
                break;
            }
            case APPLICABLE_WORKS_KEY: {
                let searchQuery: IWorksSearchQuery = {
                    title,
                    number,
                    artist,
                    writers,
                    societyAccountNumber,
                    dataSource: DataSource.Repertoire,
                    workBatchID,
                    hasOpenWorkflow,
                    workType
                };
                searchWorks(searchQuery, true);
                break;
            }
            case AGREEMENT_CARVEOUTS_PRODUCT_NAME_STATE_KEY: {
                let searchQuery: IProductSearchQuery = {
                    productIDs: [],
                    productType,
                    title,
                    number,
                    contributor,
                    artist,
                    productBatchID,
                    dataSource: DataSource.Repertoire,
                };
                searchProducts(searchQuery, false);
                break;
            }
            case MATCHING_REPERTOIRE_WORKS_KEY: {
                let searchQuery: IWorksSearchQuery = {
                    title,
                    number,
                    artist,
                    writers,
                    societyAccountNumber,
                    dataSource: this.state.datasource === "" ? REPERTOIRE : this.state.datasource,
                    workBatchID,
                    hasOpenWorkflow,
                    workType
                };
                searchWorks(searchQuery, true);
                break;
            }
            case SET_LISTS_WORK_SEARCHVIEW_STATE_KEY: {
                let dataSource = this.state.datasource === "" ? REPERTOIRE : this.state.datasource;

                let searchQuery: IDraftWorkSearchQuery = {
                    title: draftWorkTitle == "" ? null : draftWorkTitle,
                    status: null,
                    workNumber: draftWorkNumber == "" ? null : draftWorkNumber,
                    artist: null,
                    ipNumber: draftWorkIPNumber == "" ? null : draftWorkIPNumber,
                    ipName: draftWorkIPName == "" ? null : draftWorkIPName,
                    dataSource: dataSource,
                    currentUserNameNumber: null
                };
                searchDraftWorks(searchQuery);
                break;
            }
            case PRODUCT_WORKS_KEY:
            case DRAFT_SET_LISTS_WORK_STATE_KEY: {

                if (isMembersPortal) {

                    let searchQuery: IDraftWorkSearchQuery = {
                        title: draftWorkTitle == "" ? null : draftWorkTitle,
                        status: draftWorkStatus == "" ? null : draftWorkStatus,
                        workNumber: draftWorkNumber == "" ? null : draftWorkNumber,
                        artist: draftWorkArtist == "" ? null : draftWorkArtist,
                        ipNumber: draftWorkIPNumber == "" ? null : draftWorkIPNumber,
                        ipName: draftWorkIPName == "" ? null : draftWorkIPName,
                        dataSource: dataSource == "" ? null : dataSource,
                        currentUserNameNumber
                    }

                    searchDraftWorks(searchQuery)
                }
                else {
                    let searchQuery: IWorksSearchQuery = {
                        title,
                        number,
                        artist,
                        writers,
                        societyAccountNumber,
                        dataSource,
                        workBatchID,
                        hasOpenWorkflow,
                        workType
                    };
                    searchWorks(searchQuery, true);
                }

                break;
            }
            case PRODUCT_WORKS_REPERTOIRE_STATE_KEY: {
                let searchQuery: IWorksSearchQuery = {
                    title,
                    number,
                    artist,
                    writers,
                    societyAccountNumber,
                    dataSource: DataSource.Repertoire,
                    workBatchID,
                    hasOpenWorkflow,
                    workType
                };
                searchWorks(searchQuery, true);
                break;
            }
            case COMPONENTS_WORKS_REPERTOIRE_STATE_KEY: {
                let searchQuery: IWorksSearchQuery = {
                    title,
                    number,
                    artist,
                    writers,
                    societyAccountNumber,
                    dataSource: DataSource.Repertoire,
                    workBatchID,
                    hasOpenWorkflow,
                    workType
                };
                searchWorks(searchQuery, true);
                break;
            }
            case COMPONENTS_KEY: {
                let searchQuery: IWorksSearchQuery = {
                    title,
                    number,
                    artist,
                    writers,
                    societyAccountNumber,
                    dataSource,
                    workBatchID,
                    hasOpenWorkflow,
                    workType
                };
                searchWorks(searchQuery, true);
                break;
            }

            case SEARCH_VIEW_WORKFLOW: {
                let searchQuery: IWorkflowSearchQuery = {
                    entityType,
                    status,
                    workflowType,
                    assignedTo,
                    referenceKeyValues: referenceKeyValueRows,
                    valueKey,
                    valuesMax,
                    valuesMin,
                    fromDateCreated,
                    toDateCreated
                };

                searchWorkflow(searchQuery, true);
                break;
            }

            case SEARCH_VIEW_POOLS: {
                let searchQuery: IUsagePoolSearchQuery = {
                    poolCode,
                    cat1,
                    cat2,
                    allocationRule
                }

                searchUsagePools(searchQuery)
                break;
            }
            case SEARCH_VIEW_DISTRIBUTIONS: {
                let searchQuery: IDistributionSearchQuery =
                {
                    year,
                    distributionType,
                    distributionStatus,
                    distributionCode,
                    cat1,
                    cat2,
                    description
                }
                searchDistributions(searchQuery);
                break;
            }
            case SEARCH_VIEW_ADJUSTMENTS:
                const searchQuery: IAdjustmentSearchQuery = {
                    id,
                    adjustmentDateFrom,
                    adjustmentDateTo,
                    user,
                    adjustmentCategory,
                    adjustmentStatus,
                    datasource,
                    sourceDistribution,
                    adjustmentDistribution,
                    sourcePool,
                    adjustmentBatchID
                }
                searchAdjustments(searchQuery, dataSource);
                break;
            case SEARCH_VIEW_WORK_CLAIMS: {
                let searchQuery: IWorksSearchQuery = {
                    title,
                    number,
                    artist,
                    writers,
                    societyAccountNumber,
                    dataSource: DataSource.Repertoire,
                    workBatchID,
                    hasOpenWorkflow,
                    workType
                };
                searchWorks(searchQuery, true);
                break;
            }
            case ALLOCATION_ADJUSTMENT_IP_KEY: {
                let searchQuery: IContributorSearchQuery = {
                    ipNumber,
                    fullName,
                    accountNumber,
                    legalEntityType,
                    isMembersPortal

                };
                searchContributors(searchQuery);
                break;
            }
            case USAGE_DETAILS_ROWS_SECTION_KEY: {
                let searchQuery: IContributorSearchQuery = {
                    ipNumber,
                    fullName,
                    accountNumber,
                    legalEntityType,
                    isMembersPortal
                };
                searchContributors(searchQuery);
                break;
            }

            case SEARCH_VIEW_PAYMENTRUN: {
                let searchQuery: IPaymentRunSearchQuery = {
                    paymentRunDateFrom: paymentRunDateFrom,
                    paymentRunDateTo: paymentRunDateTo,
                    paymentRunID: paymentRunID,
                    status: paymentRunStatus,
                    type: paymentRunType
                }

                searchPaymentRuns(searchQuery)
                break;
            }
            case SEARCH_VIEW_LICENSES: {
                let searchQuery: IShoppingCartSearchQuery = {
                    shoppingCartID: Number(licenseID)
                }

                searchLicenses(searchQuery)
                break;
            }
            case SEARCH_VIEW_LICENSES_FILEIMPORT: {
                let searchQuery: IShoppingCartSearchQuery = {
                    shoppingCartID: Number(licenseID)
                }

                searchLicenses(searchQuery)
                break;
            }
            case SEARCH_VIEW_DRAFT_WORKS: {

                let searchQuery: IDraftWorkSearchQuery = {
                    title: draftWorkTitle == "" ? null : draftWorkTitle,
                    status: draftWorkStatus == "" ? null : draftWorkStatus,
                    workNumber: draftWorkNumber == "" ? null : draftWorkNumber,
                    artist: draftWorkArtist == "" ? null : draftWorkArtist,
                    ipNumber: draftWorkIPNumber == "" ? null : draftWorkIPNumber,
                    ipName: draftWorkIPName == "" ? null : draftWorkIPName,
                    dataSource: dataSource == "" ? null : dataSource,
                    currentUserNameNumber: currentUserNameNumber.toString()
                }

                searchDraftWorks(searchQuery, isModalSearch)
                break;
            }
            case SEARCH_VIEW_MEMBER_STATEMENTS: {

                let searchQuery: IMemberStatementSearchQuery = {
                    viewStatementsFrom: memberStatementViewStatementsFrom == "" ? null : memberStatementViewStatementsFrom,
                    customRange: memberStatementCustomRange,
                    dateFrom: memberStatementDateFrom,
                    dateTo: memberStatementDateTo,
                    paymentID: memberStatementPaymentID == "" ? null : memberStatementPaymentID,
                    distributionCode: memberStatementDistributionCode == "" ? null : memberStatementDistributionCode,
                    pool: memberStatementPool == "" ? null : memberStatementPool,
                    workNumber: memberStatementWorkNumber == "" ? null : memberStatementWorkNumber,
                    currentUserNameNumber
                };

                searchMemberStatements(searchQuery);
                break;
            }
            case SEARCH_VIEW_WORK_LICENSES: {
                let searchQuery: IWorksSearchQuery = {
                    title,
                    number,
                    artist,
                    writers,
                    societyAccountNumber,
                    dataSource: DataSource.Repertoire,
                    workBatchID,
                    hasOpenWorkflow,
                    workType
                };
                searchWorks(searchQuery, true);
                break;
            }
            case SEARCH_VIEW_CLAIMS: {
                let searchQuery: IClaimSearchQuery = {
                    ipiNumber,
                    fullName,
                    accountNumber,
                    claimStatus,
                    claimCode
                };
                searchClaims(searchQuery);
                break;
            }
            case DRAFT_AGREEMENTS_APPLICABLE_WORKS_STATE_KEY: {
                let searchQuery: IWorksSearchQuery = {
                    title,
                    number,
                    artist,
                    writers,
                    societyAccountNumber,
                    dataSource,
                    workBatchID,
                    hasOpenWorkflow,
                    workType,
                    workGroupID
                };
                if (isModalSearch) searchQuery.dataSource = DataSource.Repertoire
                searchWorks(searchQuery, false);
                break;
            }
            default: {
                return;
            }
        }
    };

    renderSearchResults() {
        const {
            addArtistToWork,
            addArtistToProduct,
            addWorkToProduct,
            searchResultsTableData,
            componentInstance,
            getWorkDetails,
            getWorkDetailsWorkflow,
            worksSearchResults,
            artistsSearchResults,
            contributorsSearchResults,
            ipsSearchResults,
            agreementsSearchResults,
            subjectSearchResults,
            getAgreementDetails,
            getIPDetails,
            getArtistDetails,
            productSearchResults,
            getProductDetails,
            modalProps,
            updateWorkContributorIP,
            updateProductContributorIP,
            updateAgreementIP,
            updateManualJobParams,
            manualJobParameters,
            updateAgreementWork,
            updateAgreementCarveOutProduct,
            updateMatchingWork,
            updateMatchingProduct,
            updateRelatedWork,
            hideModal,
            searchSuccessful,
            updateWorkSourceIP,
            updateProductSourceIP,
            expandedWorkResults,
            expandedProductResults,
            expandAllResults,
            expandAll,
            expandWorkResult,
            expandProductResult,
            workflowSearchResults,
            selectWorkflow,
            workflowPageToolbarData,
            activeTab,
            tabs,
            showModal,
            scroll,
            toolbarWidth,
            onOpenAssignView,
            assingWorkflowDataView,
            dataGridTableData,
            onAssignWorkflow,
            onUpdateStatusWorkflow,
            updateWorkflowViewData,
            onOpenUpdateWorkflows,
            clearSelection,
            users,
            selectAllWorkflow,
            isSelectedAll,
            markSelectAll,
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            currentPage,
            updatePagination,
            startWorkflowSession,
            skipWorkflowItem,
            nextWorkflowItem,
            cancelWorkflowSession,
            workflowSessionItems,
            dataActionList,
            workFormats,
            workMaintenanceGeneralDataViewData,
            lookupValues,
            isAnySelectedCompletedError,
            setErrorWorkflowSession,
            openEntityForWorflowSession,
            currentWorkflowIndex,
            usagePoolsSearchResults,
            getUsagePoolDetails,
            distributionsSearchResults,
            getDistributionDetails,
            getUsageDetails,
            skipUsageWorkflowItem,
            sortSearchResults,
            adjustmentsSearchResults,
            getAdjustmentDetails,
            updateStoragePathItemIP,
            addIpToAdjustmentDetail,
            addIPToUsageDetails,
            paymentRunSearchResults,
            getPaymentRunDetails,
            licensesSearchResults,
            getLicenseDetails,
            licenseUserSearchResults,
            updateLicenceUserValues,
            draftWorkSearchResults,
            getDraftWorkDetails,
            addWorkToLicenseRequest,
            requestViewAsAccess,
            updateLicenseImportShoppingCart,
            memberStatementSearchResults,
            getMemberStatementDetails,
            draftSetListsSearchResults,
            expandMemberStatementResult,
            expandedMemberStatementResults,
            dataSource,
            addWorkToSetList,
            addWorkToClaimRequest,
            claimSearchResults,
            getClaimDetails,
            updateSubjects,
            modalIndex,
            draftAgreementIPSearchResult,
            updateDraftAgreementField,
            draftAgreementWorksSearchResult,
            updateDraftAgreementWorkField,
            getCWRGHighWaterMark,
            addToPackages,
            currentUser,
            modalKey,
            resultPageData
        } = this.props;

        const { setPageOne } = this.state;
        let updatedDraftAgreementIPSearchResult;
        if(draftAgreementIPSearchResult && draftAgreementIPSearchResult.length){
            updatedDraftAgreementIPSearchResult=draftAgreementIPSearchResult.filter(item=>item.ipType!=='SI');
        }
        if (searchSuccessful &&
            ((!!worksSearchResults && worksSearchResults.length <= 0)
                || (!!artistsSearchResults && artistsSearchResults.length <= 0)
                || (!!contributorsSearchResults && contributorsSearchResults.length <= 0)
                || (!!agreementsSearchResults && agreementsSearchResults.length <= 0)
                || (!!ipsSearchResults && ipsSearchResults.length <= 0)
                || (!!productSearchResults && productSearchResults.length <= 0)
                || (!!workflowSearchResults && workflowSearchResults.length <= 0)
                || (!!usagePoolsSearchResults && usagePoolsSearchResults.length <= 0)
                || (!!distributionsSearchResults && distributionsSearchResults.length <= 0)
                || (!!adjustmentsSearchResults && adjustmentsSearchResults.length <= 0)
                || (!!paymentRunSearchResults && paymentRunSearchResults.length <= 0)
                || (!!licensesSearchResults && licensesSearchResults.length <= 0)
                || (!!licenseUserSearchResults && licenseUserSearchResults.length <= 0)
                || (!!draftWorkSearchResults && draftWorkSearchResults.length <= 0)
                || (!!draftSetListsSearchResults && draftSetListsSearchResults.length <= 0)
                || (!!memberStatementSearchResults && memberStatementSearchResults.length <= 0)
                || (!!claimSearchResults && claimSearchResults.length <= 0)
                || (!!subjectSearchResults && subjectSearchResults.length <= 0)
                || (!!updatedDraftAgreementIPSearchResult && updatedDraftAgreementIPSearchResult.length<=0)
            )) {
            return (
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 centerTextNotFound" >
                        No results found.
                    </div>
                </div>
            )
        }
        else if (componentInstance === SEARCH_VIEW_WORKS &&
            worksSearchResults && worksSearchResults.length >= 1) {
            return (<div className="row">
                <div className="col-md-12 searchResultsDiv">
                    <SearchResultsTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={worksSearchResults}
                        getWorkDetails={getWorkDetails}
                        componentInstance={componentInstance}
                        expandedResults={expandedWorkResults}
                        expandAll={expandAll}
                        expandResult={expandWorkResult}
                        expandAllResults={expandAllResults}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        currentUser={currentUser}
                        addToPackages={addToPackages}
                        repertoireSection={SEARCH_VIEW_WORKS}
                        sortSearchResults={sortSearchResults}
                        resultPageData={resultPageData}
                    />
                </div>
            </div>)
        } else if (componentInstance === APPLICABLE_WORKS_KEY &&
            worksSearchResults && worksSearchResults.length >= 1) {
            return (<div className="row" key={expandedWorkResults.length}>
                <SelectionTable
                    key={setPageOne}
                    searchResultsTableData={searchResultsTableData}
                    tableContents={worksSearchResults}
                    componentInstance={SEARCH_VIEW_WORKS}
                    sourceItem={modalProps}
                    updateAgreementWork={updateAgreementWork}
                    hideModal={hideModal}
                    expandedResults={expandedWorkResults}
                    expandAll={expandAll}
                    expandResult={expandWorkResult}
                    expandAllResults={expandAllResults}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    currentPage={currentPage}
                    updatePagination={updatePagination}
                    repertoireSection={SEARCH_VIEW_WORKS}

                />
            </div>)
        } else if (componentInstance === AGREEMENT_CARVEOUTS_PRODUCT_NAME_STATE_KEY &&
            productSearchResults && productSearchResults.length >= 1) {
            return (<div className="row" key={expandedProductResults.length}>
                <SelectionTable
                    key={setPageOne}
                    searchResultsTableData={searchResultsTableData}
                    tableContents={productSearchResults}
                    componentInstance={SEARCH_VIEW_PRODUCTS}
                    sourceItem={modalProps}
                    updateAgreementCarveOutProduct={updateAgreementCarveOutProduct}
                    hideModal={hideModal}
                    expandedResults={expandedProductResults}
                    expandAll={expandAll}
                    expandResult={expandProductResult}
                    expandAllResults={expandAllResults}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    currentPage={currentPage}
                    updatePagination={updatePagination}
                    repertoireSection={SEARCH_VIEW_PRODUCTS}
                    getProductDetails={getProductDetails}

                />
            </div>)
        } else if (componentInstance === SEARCH_VIEW_WORK_LICENSES &&
            worksSearchResults && worksSearchResults.length >= 1) {
            return (
                <div className="row" key={expandedWorkResults.length}>
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={worksSearchResults}
                        componentInstance={SEARCH_VIEW_WORK_LICENSES}
                        sourceItem={modalProps}
                        updateMatchingWork={updateMatchingWork}
                        hideModal={hideModal}
                        expandedResults={expandedWorkResults}
                        expandAll={expandAll}
                        expandResult={expandWorkResult}
                        expandAllResults={expandAllResults}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_WORKS}
                        addWorkToLicenseRequest={addWorkToLicenseRequest}
                    />
                </div>
            )
        }
        else if (componentInstance === SEARCH_VIEW_CLAIMS &&
            claimSearchResults && claimSearchResults.length >= 1) {
            return (
                <div className="col-md-12 searchResultsDiv">
                    <SearchResultsTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={claimSearchResults}
                        componentInstance={componentInstance}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_CLAIMS}
                        getClaimDetails={getClaimDetails}
                        sortSearchResults={sortSearchResults}
                        lookupValues={lookupValues}
                    />
                </div>
            )
        }
        else if (componentInstance === SEARCH_VIEW_WORK_CLAIMS &&
            worksSearchResults && worksSearchResults.length >= 1) {
            return (
                <div className="row" key={expandedWorkResults.length}>
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={worksSearchResults}
                        componentInstance={SEARCH_VIEW_WORK_CLAIMS}
                        sourceItem={modalProps}
                        hideModal={hideModal}
                        expandedResults={expandedWorkResults}
                        expandAll={expandAll}
                        expandResult={expandWorkResult}
                        expandAllResults={expandAllResults}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_WORKS}
                        addWorkToClaimRequest={addWorkToClaimRequest}
                    />
                </div>
            )
        }

        else if (componentInstance === MATCHING_REPERTOIRE_WORKS_KEY &&
            worksSearchResults && worksSearchResults.length >= 1) {
            return (
                <div className="row" key={expandedWorkResults.length}>
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={worksSearchResults}
                        componentInstance={SEARCH_VIEW_WORKS}
                        sourceItem={modalProps}
                        updateMatchingWork={updateMatchingWork}
                        hideModal={hideModal}
                        expandedResults={expandedWorkResults}
                        expandAll={expandAll}
                        expandResult={expandWorkResult}
                        expandAllResults={expandAllResults}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_WORKS}
                    />
                </div>
            )
        } else if (componentInstance === PRODUCT_WORKS_KEY &&
            worksSearchResults && worksSearchResults.length >= 0) {
            return (
                <div className="row" key={expandedWorkResults.length}>
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={worksSearchResults}
                        componentInstance={SEARCH_VIEW_WORKS}
                        sourceItem={modalProps}
                        addWorkToProduct={addWorkToProduct}
                        hideModal={hideModal}
                        expandedResults={expandedWorkResults}
                        expandAll={expandAll}
                        expandResult={expandWorkResult}
                        expandAllResults={expandAllResults}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_PRODUCTS}
                        sortSearchResults={sortSearchResults}
                    />
                </div>
            )
        }
        else if ((componentInstance === DRAFT_SET_LISTS_WORK_STATE_KEY || componentInstance === SET_LISTS_WORK_SEARCHVIEW_STATE_KEY) && draftWorkSearchResults && draftWorkSearchResults.length >= 1) {
            return (
                <div className="row" >
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={draftWorkSearchResults}
                        componentInstance={SET_LISTS_WORK_SEARCHVIEW_STATE_KEY}
                        sourceItem={modalProps}
                        addWorkToSetList={addWorkToSetList}
                        hideModal={hideModal}
                        expandedResults={expandedWorkResults}
                        expandAll={expandAll}
                        expandResult={expandWorkResult}
                        expandAllResults={expandAllResults}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SET_LISTS_WORK_SEARCHVIEW_STATE_KEY}
                        sortSearchResults={sortSearchResults}
                    />
                </div>
            )
        }
        else if (componentInstance === PRODUCT_WORKS_REPERTOIRE_STATE_KEY &&
            worksSearchResults && worksSearchResults.length >= 0) {
            return (
                <div className="row" key={expandedWorkResults.length}>
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={worksSearchResults}
                        componentInstance={SEARCH_VIEW_WORKS}
                        sourceItem={modalProps}
                        addWorkToProduct={addWorkToProduct}
                        hideModal={hideModal}
                        expandedResults={expandedWorkResults}
                        expandAll={expandAll}
                        expandResult={expandWorkResult}
                        expandAllResults={expandAllResults}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_PRODUCTS}
                    />
                </div>
            )
        } else if (componentInstance === SEARCH_VIEW_PERFORMERS &&
            artistsSearchResults && artistsSearchResults.length >= 1) {
            return (
                <div className="row">
                    <div className="col-md-12 searchResultsDiv">
                        <SearchResultsTable
                            key={setPageOne}
                            searchResultsTableData={searchResultsTableData}
                            tableContents={artistsSearchResults}
                            componentInstance={SEARCH_VIEW_ARTISTS}
                            getArtistDetails={getArtistDetails}
                            indexOfFirstResult={indexOfFirstResult}
                            indexOfLastResult={indexOfLastResult}
                            resultsPerPage={resultsPerPage}
                            currentPage={currentPage}
                            updatePagination={updatePagination}
                            repertoireSection={SEARCH_VIEW_ARTISTS}
                            sortSearchResults={sortSearchResults}
                        />
                    </div>
                </div>)
        } else if (componentInstance === SEARCH_VIEW_ARTISTS &&
            artistsSearchResults && artistsSearchResults.length >= 1) {
            return (
                <div className="row">
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={artistsSearchResults}
                        componentInstance={componentInstance}
                        addArtistToWork={addArtistToWork}
                        sourceItem={modalProps}
                        modalIndex={modalIndex}
                        hideModal={hideModal}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_WORKS}
                        sortSearchResults={sortSearchResults}
                    />
                </div>
            )
        } else if (componentInstance === PRODUCT_PERFORMERS_KEY &&
            artistsSearchResults && artistsSearchResults.length >= 1) {
            return (
                <div className="row">
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={artistsSearchResults}
                        componentInstance={SEARCH_VIEW_ARTISTS}
                        addArtistToProduct={addArtistToProduct}
                        sourceItem={modalProps}
                        modalIndex={modalIndex}
                        hideModal={hideModal}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_PRODUCTS}
                    />
                </div>
            )
        } else if (componentInstance === SEARCH_VIEW_CONTRIBUTORS &&
            contributorsSearchResults && contributorsSearchResults.length >= 1) {
            return (
                <div className="row">
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={contributorsSearchResults}
                        componentInstance={componentInstance}
                        sourceItem={modalProps}
                        updateWorkContributorIP={updateWorkContributorIP}
                        updateStoragePathItemIP={updateStoragePathItemIP}
                        hideModal={hideModal}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_WORKS}
                    />
                </div>
            )
        } else if (componentInstance === COMPONENT_INSTANCE_IPS && (modalKey ==='draftAgreement' || modalKey==='agreementsAssignee' )&&
            updatedDraftAgreementIPSearchResult && updatedDraftAgreementIPSearchResult.length >= 1) {
            return (
                <div className="row">
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={updatedDraftAgreementIPSearchResult}
                        componentInstance={SEARCH_VIEW_CONTRIBUTORS}
                        sourceItem={modalProps}
                        updateWorkContributorIP={updateWorkContributorIP}
                        updateStoragePathItemIP={updateStoragePathItemIP}
                        hideModal={hideModal}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_WORKS}
                    />
                </div>
            )
        } else if (componentInstance === SEARCH_VIEW_REQUEST_ACCESS &&
            contributorsSearchResults && contributorsSearchResults.length >= 1) {
            return (
                <div className="row">
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={contributorsSearchResults}
                        componentInstance={componentInstance}
                        sourceItem={modalProps}
                        hideModal={hideModal}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_WORKS}
                        requestViewAsAccess={requestViewAsAccess}
                        sortSearchResults={sortSearchResults}
                    />
                </div>
            )
        } else if (componentInstance === PRODUCT_CONTRIBUTORS_KEY &&
            contributorsSearchResults && contributorsSearchResults.length >= 1) {
            return (
                <div className="row">
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={contributorsSearchResults}
                        componentInstance={SEARCH_VIEW_CONTRIBUTORS}
                        sourceItem={modalProps}
                        updateProductContributorIP={updateProductContributorIP}
                        hideModal={hideModal}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_PRODUCTS}
                    />
                </div>
            )
        } else if (componentInstance === SEARCH_VIEW_AGREEMENT_IP &&
            contributorsSearchResults && contributorsSearchResults.length >= 1) {
            return (
                <div className="row" key={componentInstance}>
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={contributorsSearchResults}
                        componentInstance={componentInstance}
                        sourceItem={modalProps}
                        updateAgreementIP={updateAgreementIP}
                        hideModal={hideModal}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_AGREEMENTS}
                    />
                </div>
            )
        }
        else if (componentInstance === SEARCH_VIEW_IPS &&
            (draftAgreementIPSearchResult && draftAgreementIPSearchResult.length>=1)) {
            return (
                <div className="row" key={componentInstance}>
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={draftAgreementIPSearchResult}
                        componentInstance={SEARCH_VIEW_CONTRIBUTORS}
                        sourceItem={modalProps}
                        updateAgreementIP={updateAgreementIP}
                        hideModal={hideModal}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_AGREEMENTS}
                        updateDraftAgreementField={updateDraftAgreementField}
                    />
                </div>
            )
        } else if (componentInstance === SEARCH_VIEW_JOB_IP &&
            contributorsSearchResults && contributorsSearchResults.length >= 1) {
            return (
                <div className="row" key={componentInstance}>
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={contributorsSearchResults}
                        componentInstance={componentInstance}
                        sourceItem={modalProps}
                        updateManualJobParams={updateManualJobParams}
                        hideModal={hideModal}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        manualJobParameters={manualJobParameters}
                    />
                </div>
            )
        } else if (componentInstance === SEARCH_VIEW_AGREEMENTS &&
            agreementsSearchResults && agreementsSearchResults.length >= 1) {
            return (
                <div className="row">
                    <div className="col-md-12 searchResultsDiv">
                        <SearchResultsTable
                            key={setPageOne}
                            searchResultsTableData={searchResultsTableData}
                            tableContents={agreementsSearchResults}
                            componentInstance={componentInstance}
                            getAgreementDetails={getAgreementDetails}
                            indexOfFirstResult={indexOfFirstResult}
                            indexOfLastResult={indexOfLastResult}
                            resultsPerPage={resultsPerPage}
                            currentPage={currentPage}
                            updatePagination={updatePagination}
                            repertoireSection={SEARCH_VIEW_AGREEMENTS}
                            sortSearchResults={sortSearchResults}
                            currentUser={currentUser}
                            addToPackages={addToPackages}
                            resultPageData={resultPageData}
                        />
                    </div>
                </div>
            )
        } else if (componentInstance === SEARCH_VIEW_IPS &&
            (ipsSearchResults && ipsSearchResults.length >= 1)) {
            return (
                <div className="row">
                    <div className="col-md-12 searchResultsDiv">
                        <SearchResultsTable
                            key={setPageOne}
                            searchResultsTableData={searchResultsTableData}
                            tableContents={ipsSearchResults}
                            componentInstance={componentInstance}
                            getIPDetails={getIPDetails}
                            indexOfFirstResult={indexOfFirstResult}
                            indexOfLastResult={indexOfLastResult}
                            resultsPerPage={resultsPerPage}
                            currentPage={currentPage}
                            updatePagination={updatePagination}
                            repertoireSection={SEARCH_VIEW_IPS}
                            sortSearchResults={sortSearchResults}
                        />
                    </div>
                </div>
            )
        } else if (componentInstance === SEARCH_VIEW_PRODUCTS &&
            productSearchResults && productSearchResults.length >= 1) {
            return (
                <div className="row">
                    <div className="col-md-12 searchResultsDiv">
                        <SearchResultsTable
                            key={setPageOne}
                            searchResultsTableData={searchResultsTableData}
                            tableContents={productSearchResults}
                            componentInstance={componentInstance}
                            getProductDetails={getProductDetails}
                            expandedResults={expandedProductResults}
                            expandResult={expandProductResult}
                            expandAll={expandAll}
                            expandAllResults={expandAllResults}
                            indexOfFirstResult={indexOfFirstResult}
                            indexOfLastResult={indexOfLastResult}
                            resultsPerPage={resultsPerPage}
                            currentPage={currentPage}
                            currentUser={currentUser}
                            addToPackages={addToPackages}
                            resultPageData={resultPageData}
                            updatePagination={updatePagination}
                            repertoireSection={SEARCH_VIEW_PRODUCTS}
                            sortSearchResults={sortSearchResults}
                        />
                    </div>
                </div>
            )
        } else if (componentInstance === SEARCH_VIEW_LICENSE_USER &&
            licenseUserSearchResults && licenseUserSearchResults.length >= 1) {
            return (
                <div className="row" key={componentInstance}>
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={licenseUserSearchResults}
                        componentInstance={componentInstance}
                        sourceItem={modalProps}
                        hideModal={hideModal}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        licenseUserSearchResults={licenseUserSearchResults}
                        updateLicenceUserValues={updateLicenceUserValues}
                    />
                </div>
            )
        } else if (componentInstance === MATCHING_REPERTOIRE_PRODUCTS_KEY &&
            productSearchResults && productSearchResults.length >= 1) {
            return (
                <div className="row" key={expandedProductResults.length}>
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={productSearchResults}
                        componentInstance={SEARCH_VIEW_PRODUCTS}
                        sourceItem={modalProps}
                        updateMatchingProduct={updateMatchingProduct}
                        hideModal={hideModal}
                        expandedResults={expandedProductResults}
                        expandAll={expandAll}
                        expandResult={expandProductResult}
                        expandAllResults={expandAllResults}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_PRODUCTS}
                    />
                </div>
            )
        } else if (componentInstance === MATCHING_REPERTOIRE_PRODUCTS_KEY &&
            productSearchResults && productSearchResults.length >= 1) {
            return (
                <div className="row" key={expandedProductResults.length}>
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={productSearchResults}
                        componentInstance={SEARCH_VIEW_PRODUCTS}
                        sourceItem={modalProps}
                        updateMatchingProduct={updateMatchingProduct}
                        hideModal={hideModal}
                        expandedResults={expandedProductResults}
                        expandAll={expandAll}
                        expandResult={expandProductResult}
                        expandAllResults={expandAllResults}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_PRODUCTS}
                    />
                </div>
            )
        } else if (componentInstance === COMPONENTS_KEY &&
            worksSearchResults && worksSearchResults.length >= 1) {
            return (
                <div className="row" key={expandedWorkResults.length}>
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={worksSearchResults}
                        componentInstance={SEARCH_VIEW_WORKS}
                        sourceItem={modalProps}
                        updateRelatedWork={updateRelatedWork}
                        hideModal={hideModal}
                        expandedResults={expandedWorkResults}
                        expandAll={expandAll}
                        expandResult={expandWorkResult}
                        expandAllResults={expandAllResults}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_WORKS}
                    />
                </div>
            )
        } else if (componentInstance === COMPONENTS_WORKS_REPERTOIRE_STATE_KEY &&
            worksSearchResults && worksSearchResults.length >= 1) {
            return (
                <div className="row" key={expandedWorkResults.length}>
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={worksSearchResults}
                        componentInstance={SEARCH_VIEW_WORKS}
                        sourceItem={modalProps}
                        updateRelatedWork={updateRelatedWork}
                        hideModal={hideModal}
                        expandedResults={expandedWorkResults}
                        expandAll={expandAll}
                        expandResult={expandWorkResult}
                        expandAllResults={expandAllResults}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_WORKS}
                    />
                </div>
            )
        } else if (componentInstance === SEARCH_VIEW_WORKSOURCE_IP &&
            contributorsSearchResults && contributorsSearchResults.length >= 1) {
            return (
                <div className="row" key={componentInstance}>
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={contributorsSearchResults}
                        componentInstance={SEARCH_VIEW_CONTRIBUTORS}
                        sourceItem={modalProps}
                        updateWorkSourceIP={updateWorkSourceIP}
                        hideModal={hideModal}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_WORKS}
                    />
                </div>
            )
        } else if (componentInstance === SEARCH_VIEW_PRODUCTSOURCE_IP &&
            contributorsSearchResults && contributorsSearchResults.length >= 1) {
            return (
                <div className="row" key={componentInstance}>
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={contributorsSearchResults}
                        componentInstance={SEARCH_VIEW_CONTRIBUTORS}
                        sourceItem={modalProps}
                        updateProductSourceIP={updateProductSourceIP}
                        hideModal={hideModal}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_PRODUCTS}
                    />
                </div>
            )
        } else if (componentInstance === SEARCH_VIEW_WORKFLOW &&
            workflowSearchResults && workflowSearchResults.length >= 1) {

            return (
                <>
                    <If condition={workflowSearchResults && workflowSearchResults.length >= 1 && workflowSearchResults.filter(x => x.selected).length > 0}>
                        <WorkflowPageToolbar
                            activeTab={activeTab}
                            hideModal={hideModal}
                            showModal={showModal}
                            scroll={scroll}
                            toolbarWidth={toolbarWidth}
                            workflowPageToolbarData={workflowPageToolbarData}
                            tabs={tabs}
                            workflowSearchResultsCount={workflowSearchResults.filter(x => x.selected).length}
                            onOpenAssignView={onOpenAssignView}
                            assingWorkflowDataView={assingWorkflowDataView}
                            dataGridTableData={dataGridTableData}
                            onAssignWorkflow={onAssignWorkflow}
                            onUpdateStatusWorkflow={onUpdateStatusWorkflow}
                            updateWorkflowViewData={updateWorkflowViewData}
                            onOpenUpdateWorkflowsView={onOpenUpdateWorkflows}
                            clearSelection={clearSelection}
                            users={users}
                            markSelectAll={markSelectAll}
                            updatePagination={updatePagination}
                            startWorkflowSession={startWorkflowSession}
                            getWorkDetails={getWorkDetails}
                            getWorkDetailsWorkflow={getWorkDetailsWorkflow}
                            getProductDetails={getProductDetails}
                            nextWorkflowItem={nextWorkflowItem}
                            cancelWorkflowSession={cancelWorkflowSession}
                            skipWorkflowItem={skipWorkflowItem}
                            workflowSessionItems={workflowSessionItems}
                            dataActionList={dataActionList}
                            workFormats={workFormats}
                            workMaintenanceGeneralDataViewData={workMaintenanceGeneralDataViewData}
                            lookupValues={lookupValues}
                            isAnySelectedCompletedError={isAnySelectedCompletedError}
                            setErrorWorkflowSession={setErrorWorkflowSession}
                            openEntityForWorkflowSession={openEntityForWorflowSession}
                            currentWorkflowIndex={currentWorkflowIndex}
                            getUsageDetails={getUsageDetails}
                            skipUsageWorkflowItem={skipUsageWorkflowItem}
                            getAgreementDetails={getAgreementDetails}
                        />
                    </If>
                    <div className="row">
                        <div className="col-md-12 searchResultsDiv">
                            <SearchResultsTable
                                key={setPageOne}
                                searchResultsTableData={searchResultsTableData}
                                tableContents={workflowSearchResults}
                                componentInstance={componentInstance}
                                getWorkDetails={getWorkDetails}
                                getProductDetails={getProductDetails}
                                selectWorkflow={selectWorkflow}
                                selectAll={true}
                                selectAllWorkflow={selectAllWorkflow}
                                isSelectedAll={isSelectedAll}
                                markSelectAll={markSelectAll}
                                indexOfFirstResult={indexOfFirstResult}
                                indexOfLastResult={indexOfLastResult}
                                resultsPerPage={resultsPerPage}
                                currentPage={currentPage}
                                updatePagination={updatePagination}
                                repertoireSection={SEARCH_VIEW_WORKFLOW}
                                getUsageDetails={getUsageDetails}
                                sortSearchResults={sortSearchResults}
                                getAgreementDetails={getAgreementDetails}
                            />
                        </div>
                    </div>
                </>
            )
        }
        else if (componentInstance === SEARCH_VIEW_POOLS &&
            usagePoolsSearchResults && usagePoolsSearchResults.length >= 1) {
            return (
                <div className="row">
                    <div className="col-md-12 searchResultsDiv">
                        <SearchResultsTable
                            key={setPageOne}
                            searchResultsTableData={searchResultsTableData}
                            tableContents={usagePoolsSearchResults}
                            componentInstance={componentInstance}
                            getProductDetails={getProductDetails}
                            indexOfFirstResult={indexOfFirstResult}
                            indexOfLastResult={indexOfLastResult}
                            resultsPerPage={resultsPerPage}
                            currentPage={currentPage}
                            updatePagination={updatePagination}
                            repertoireSection={SEARCH_VIEW_POOLS}
                            getUsagePoolDetails={getUsagePoolDetails}
                            sortSearchResults={sortSearchResults}
                        />
                    </div>
                </div>
            )

        }
        else if (componentInstance === SEARCH_VIEW_DISTRIBUTIONS &&
            distributionsSearchResults && distributionsSearchResults.length >= 1) {
            return (
                <div className="row">
                    <div className="col-md-12 searchResultsDiv">
                        <SearchResultsTable
                            key={setPageOne}
                            searchResultsTableData={searchResultsTableData}
                            tableContents={distributionsSearchResults}
                            componentInstance={componentInstance}
                            indexOfFirstResult={indexOfFirstResult}
                            indexOfLastResult={indexOfLastResult}
                            resultsPerPage={resultsPerPage}
                            lookupValues={lookupValues}
                            currentPage={currentPage}
                            updatePagination={updatePagination}
                            repertoireSection={SEARCH_VIEW_DISTRIBUTIONS}
                            getDistributionDetails={getDistributionDetails}
                            sortSearchResults={sortSearchResults}
                        />
                    </div>
                </div>
            )
        }
        else if (componentInstance === SEARCH_VIEW_ADJUSTMENTS &&
            adjustmentsSearchResults && adjustmentsSearchResults.length >= 1) {
            return (
                <div className="row">
                    <div className="col-md-12 searchResultsDiv">
                        <SearchResultsTable
                            key={setPageOne}
                            searchResultsTableData={searchResultsTableData}
                            tableContents={adjustmentsSearchResults}
                            componentInstance={componentInstance}
                            indexOfFirstResult={indexOfFirstResult}
                            indexOfLastResult={indexOfLastResult}
                            resultsPerPage={resultsPerPage}
                            currentPage={currentPage}
                            updatePagination={updatePagination}
                            repertoireSection={SEARCH_VIEW_ADJUSTMENTS}
                            getAdjustmentDetails={getAdjustmentDetails}
                            sortSearchResults={sortSearchResults}
                        />
                    </div>
                </div>
            )
        }
        else if (componentInstance === ALLOCATION_ADJUSTMENT_IP_KEY &&
            contributorsSearchResults && contributorsSearchResults.length >= 1) {
            return (
                <div className="row">
                    <div className="col-md-12 searchResultsDiv">
                        <SelectionTable
                            key={setPageOne}
                            searchResultsTableData={searchResultsTableData}
                            tableContents={contributorsSearchResults}
                            componentInstance={SEARCH_VIEW_AGREEMENT_IP}
                            sourceItem={modalProps}
                            addIpToAdjustmentDetail={addIpToAdjustmentDetail}
                            hideModal={hideModal}
                            indexOfFirstResult={indexOfFirstResult}
                            indexOfLastResult={indexOfLastResult}
                            resultsPerPage={resultsPerPage}
                            currentPage={currentPage}
                            updatePagination={updatePagination}
                            repertoireSection={SEARCH_VIEW_AGREEMENTS}
                        />
                    </div>
                </div>
            )
        }
        else if (componentInstance === USAGE_DETAILS_ROWS_SECTION_KEY &&
            contributorsSearchResults && contributorsSearchResults.length >= 1) {
            return (
                <div className="row">
                    <div className="col-md-12 searchResultsDiv">
                        <SelectionTable
                            key={setPageOne}
                            searchResultsTableData={searchResultsTableData}
                            tableContents={contributorsSearchResults}
                            componentInstance={SEARCH_VIEW_AGREEMENT_IP}
                            sourceItem={modalProps}
                            addIPToUsageDetails={addIPToUsageDetails}
                            hideModal={hideModal}
                            indexOfFirstResult={indexOfFirstResult}
                            indexOfLastResult={indexOfLastResult}
                            resultsPerPage={resultsPerPage}
                            currentPage={currentPage}
                            updatePagination={updatePagination}
                            repertoireSection={SEARCH_VIEW_AGREEMENTS}
                        />
                    </div>
                </div>
            )
        }
        else if (componentInstance === SEARCH_VIEW_PAYMENTRUN &&
            paymentRunSearchResults && paymentRunSearchResults.length >= 1) {
            return (<div className="row">
                <div className="col-md-12 searchResultsDiv">
                    <SearchResultsTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={paymentRunSearchResults}
                        componentInstance={componentInstance}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_PAYMENTRUN}
                        getPaymentRunDetails={getPaymentRunDetails}
                        sortSearchResults={sortSearchResults}
                        lookupValues={lookupValues}
                    />
                </div>
            </div>)
        }
        else if (componentInstance === SEARCH_VIEW_LICENSES &&
            licensesSearchResults && licensesSearchResults.length >= 1) {
            return (<div className="row">
                <div className="col-md-12 searchResultsDiv">
                    <SearchResultsTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={licensesSearchResults}
                        componentInstance={componentInstance}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_LICENSES}
                        getLicenseDetails={getLicenseDetails}
                        sortSearchResults={sortSearchResults}
                        lookupValues={lookupValues}
                    />
                </div>
            </div>)
        }
        else if (componentInstance === SEARCH_VIEW_SUBJECTS &&
            subjectSearchResults && subjectSearchResults.length >= 1) {
            return (<div className="row">
                <div className="col-md-12 searchResultsDiv">
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={subjectSearchResults}
                        componentInstance={componentInstance}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_SUBJECTS}
                        sortSearchResults={sortSearchResults}
                        updateSubjects={updateSubjects}
                        sourceItem={modalProps}
                        hideModal={hideModal}
                    />
                </div>
            </div>)
        }
        else if (componentInstance === SEARCH_VIEW_LICENSES_FILEIMPORT &&
            licensesSearchResults && licensesSearchResults.length >= 1) {
            return (<div className="row">
                <div className="col-md-12 searchResultsDiv">
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={licensesSearchResults}
                        componentInstance={componentInstance}
                        sourceItem={modalProps}
                        hideModal={hideModal}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_LICENSES_FILEIMPORT}
                        updateLicenseImportShoppingCart={updateLicenseImportShoppingCart}
                    />
                </div>
            </div>)
        }
        else if (componentInstance === SEARCH_VIEW_DRAFT_WORKS &&
            draftWorkSearchResults && draftWorkSearchResults.length >= 1) {
            return (<div className="row">
                <div className="col-md-12 searchResultsDiv">
                    <SearchResultsTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={draftWorkSearchResults}
                        componentInstance={componentInstance}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_DRAFT_WORKS}
                        getDraftWorkDetails={getDraftWorkDetails}
                        sortSearchResults={sortSearchResults}
                        lookupValues={lookupValues}
                        expandResult={expandWorkResult}
                        expandedResults={expandedWorkResults}
                        expandAllResults={expandAllResults}
                        expandAll={expandAll}
                    />
                </div>
            </div>)
        }

        else if (componentInstance === SEARCH_VIEW_MEMBER_STATEMENTS &&
            memberStatementSearchResults && memberStatementSearchResults.length >= 1) {
            return (<div className="row">
                <div className="col-md-12 searchResultsDiv">
                    <StatementSearchResultsTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={memberStatementSearchResults}
                        componentInstance={componentInstance}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_MEMBER_STATEMENTS}
                        getMemberStatementDetails={getMemberStatementDetails}
                        sortSearchResults={sortSearchResults}
                        lookupValues={lookupValues}
                        expandResult={expandMemberStatementResult}
                        expandedResults={expandedMemberStatementResults}
                        expandAllResults={expandAllResults}
                        expandAll={expandAll}
                    />
                </div>
            </div>)
        }
        else if (componentInstance === DRAFT_AGREEMENTS_APPLICABLE_WORKS_STATE_KEY &&
            (draftAgreementWorksSearchResult && draftAgreementWorksSearchResult.length>=1)) {
            return (
                <div className="row" key={componentInstance}>
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={draftAgreementWorksSearchResult}
                        componentInstance={componentInstance}
                        sourceItem={modalProps}
                        updateAgreementIP={updateAgreementIP}
                        hideModal={hideModal}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_AGREEMENTS}
                        updateDraftAgreementField={updateDraftAgreementField}
                        updateDraftAgreementWorkField={updateDraftAgreementWorkField}
                        modalIndex={modalIndex}
                        expandResult={undefined}
                    />
                </div>
            )
        } 
        else if (searchSuccessful == false) {
            return (
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 centerTextNotFound" >
                        Error while searching, please contact customer support if issue persists.
                    </div>
                </div>
            )
        }
    }

    renderReferenceKeyValueRow(filteredSearchFields: IRepertoireField[]) {
        const { componentInstance } = this.props;
        const { referenceKeyValueRows } = this.state;
        return referenceKeyValueRows.map((rkv, index) => {
            return (
                <div key={index} className="row">
                    <SizedDropdownDataInput
                        label={filteredSearchFields.find(x => x.name === RFERENCE_KEY_VALUE_STATE_KEY).data}
                        fieldName={filteredSearchFields.find(x => x.name === RFERENCE_KEY_VALUE_STATE_KEY).name}
                        changeData={(value, fieldName) => this.changeData(value, fieldName, rkv.index)}
                        value={rkv.referenceKey}
                        options={[
                            { code: 'Pool', description: 'Pool' },
                            { code: 'Distribution', description: 'Distribution' },
                            { code: 'WorkBatchID', description: 'Work Batch ID' },
                            { code: 'ProductBatchID', description: 'Product Batch ID' }
                        ]}
                        allowNull={true}
                        isHidden={false}
                        componentInstance={componentInstance}
                    />
                    <SizedTextDataInput
                        fieldType={"text"}
                        useEnterSubmit={true}
                        label={filteredSearchFields.find(x => x.name === REFERENCE_VALUE_STATE_KEY).data}
                        fieldName={filteredSearchFields.find(x => x.name === REFERENCE_VALUE_STATE_KEY).name}
                        changeData={this.disabledSearchFields(filteredSearchFields.find(x => x.name === REFERENCE_VALUE_STATE_KEY).name) ? this.changeDataDisabled : (value, fieldName) => this.changeTextData(value, fieldName, undefined, rkv.index)}
                        handleKeyDown={this.keyDownSubmit}
                        value={rkv.referenceValue}
                        readOnly={this.disabledSearchFields(filteredSearchFields.find(x => x.name === REFERENCE_VALUE_STATE_KEY).name)}
                        isHidden={false}
                    />

                    {index !== 0 &&
                        <div style={{ paddingTop: '30px' }}>
                            <IconTextButton onClick={() => this.removeReferenceKeyValueRow(rkv.index)} text={"Remove"} icon={"icon ms-Icon ms-Icon--Delete"} />
                        </div>}
                </div>
            )
        });
    }

    addNewReferenceKeyValueRow = () => {
        const { referenceKeyValueRows } = this.state;
        this.setState(() => ({ referenceKeyValueRows: [...referenceKeyValueRows, { referenceKey: '', referenceValue: '', index: referenceKeyValueRows.length }] }));
    }

    removeReferenceKeyValueRow = (index: number) => {
        const { referenceKeyValueRows } = this.state;
        let rkvs = referenceKeyValueRows;
        rkvs.splice(index, 1);
        rkvs.forEach((rkv, i) => rkv.index = i);
        this.setState(() => ({ referenceKeyValueRows: rkvs }));

    }

    renderKeyAndValueInputsWorkflowSearch() {
        const { componentInstance, workflowPageData, searchViewData } = this.props;
        if (componentInstance !== SEARCH_VIEW_WORKFLOW)
            return null;

        if (searchViewData && searchViewData.fields) {

            let filteredSearchFields = workflowPageData.fields.filter(
                (searchField: IRepertoireField) =>
                    searchField.componentInstance === componentInstance
            );

            return (
                <>
                    <div key='workflow1' className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <label>{workflowPageData.fields.find(x => x.name === REFERENCE_STATE_KEY).data}</label>
                    </div>
                    <div key='content1' className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        {this.renderReferenceKeyValueRow(filteredSearchFields)}

                    </div>
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <SizedActionButton buttonAction={this.addNewReferenceKeyValueRow} buttonText={'Add New'} />
                    </div>
                    <div key='workflow2' className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <label>{workflowPageData.fields.find(x => x.name === VALUE_STATE_KEY).data}</label>
                    </div>
                    <div key='content2' className="">
                        <SizedDropdownDataInput
                            label={filteredSearchFields.find(x => x.name === VALUE_KEY_VALUE_STATE_KEY).data}
                            fieldName={filteredSearchFields.find(x => x.name === VALUE_KEY_VALUE_STATE_KEY).name}
                            changeData={this.changeData}
                            value={this.state[filteredSearchFields.find(x => x.name === VALUE_KEY_VALUE_STATE_KEY).name]}
                            options={[
                                { code: 'UsageWeight', description: 'Usage Group Weight' },
                            ]}
                            allowNull={true}
                            isHidden={false}
                            componentInstance={componentInstance}
                        />
                        <SizedTextDataInput
                            fieldType={"integer"}
                            useEnterSubmit={true}
                            label={filteredSearchFields.find(x => x.name === VALUE_MIN_STATE_KEY).data}
                            fieldName={filteredSearchFields.find(x => x.name === VALUE_MIN_STATE_KEY).name}
                            changeData={this.disabledSearchFields(filteredSearchFields.find(x => x.name === VALUE_MIN_STATE_KEY).name) ? this.changeDataDisabled : (value, fieldName) => this.changeTextData(value, fieldName)}
                            handleKeyDown={this.keyDownSubmit}
                            value={filteredSearchFields.find(x => x.name === VALUE_MIN_STATE_KEY).name === 'values' ? EMPTY_STRING_VALUE : this.state[filteredSearchFields.find(x => x.name === VALUE_MIN_STATE_KEY).name]}
                            readOnly={this.disabledSearchFields(filteredSearchFields.find(x => x.name === VALUE_MIN_STATE_KEY).name)}
                            isHidden={false}
                        />
                        <SizedTextDataInput
                            fieldType={"integer"}
                            useEnterSubmit={true}
                            label={filteredSearchFields.find(x => x.name === VALUE_MAX_STATE_KEY).data}
                            fieldName={filteredSearchFields.find(x => x.name === VALUE_MAX_STATE_KEY).name}
                            changeData={this.disabledSearchFields(filteredSearchFields.find(x => x.name === VALUE_MAX_STATE_KEY).name) ? this.changeDataDisabled : (value, fieldName) => this.changeTextData(value, fieldName)}
                            handleKeyDown={this.keyDownSubmit}
                            value={filteredSearchFields.find(x => x.name === VALUE_MAX_STATE_KEY).name === 'values' ? EMPTY_STRING_VALUE : this.state[filteredSearchFields.find(x => x.name === VALUE_MAX_STATE_KEY).name]}
                            readOnly={this.disabledSearchFields(filteredSearchFields.find(x => x.name === VALUE_MAX_STATE_KEY).name)}
                            isHidden={false}
                        />
                    </div>
                </>
            )
        }

    }

    render() {
        const { searchDisabled, componentInstance,modalKey} = this.props

        return (
            <div className="searchView">
                 {(componentInstance === 'matchingRepertoireWorks' && modalKey==='modalSearch') && <div>
                        <SelectorView
                            selection={this.state.datasource === "" ? REPERTOIRE : this.state.datasource}
                            setSelection={(selection) => this.setState({
                                datasource: selection
                            })}
                            option1={REPERTOIRE}
                            option2={INTRAY}
                            selectorTitle={"Data Source"}
                        />
                        <SelectorView
                            selection={this.state.searchType === "" ? SIMPLE : this.state.searchType}
                            setSelection={(selection) => this.setState({
                                searchType: selection
                            })}
                            option1={SIMPLE}
                            option2={ADVANCED}
                            selectorTitle={"Type of Search"}
                        />
                    </div>}
                <div key='searchFields' className="row flexWrapRow paddingVeryLarge">                   
                    {this.renderSearchFields()}
                    {/* Key and Value inputs only for Workflow search */}
                    {this.renderKeyAndValueInputsWorkflowSearch()}
                </div>
                <div key='action' className="row">
                    <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                        <ActionButton buttonText="Search" buttonAction={this.onClickSearch} disabled={searchDisabled} />
                    </div>
                </div>
                <div key='results'>
                    {this.renderSearchResults()}
                </div>
                </div>
        )
    }
}

