import { connect } from "react-redux";
import IAppState from "../../redux/types/IAppState";
import { addTab, clearWorkflowSelection, markSelectAll, selectAllWorkflow, selectSingleWorkflow, resetPagination, updatePagination, cancelWorkflowSession, skipWorkflowInSession, nextWorkflowInSession, startWorkflowSession, setErrorWorkflowSession, getOtherIndicatorsWorkFlagTypesFailure, sortSearchResults } from "../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { Dispatch } from "redux";
import { openassignWorkflowTo, hideModal, showModal, showUpdateWorkflow } from "../../redux/reducers/ModalReducer";
import WorkflowPage from "./WorkflowPage";
import { IWorkflowSearchQuery } from "../types/IWorkflowSearchQuery";
import { assignWorkflowsThunk, cancelWorkflowSessionThunk, getAgreementDetailsThunk, getFieldFormatsThunk, getProductDetailsThunk, getUsageMaintenanceDataThunk, getWorkDetailsThunk, getWorkDetailsWorkflowThunk, searchWorkflowThunk, skipUsageWorkflowInSessionThunk, skipWorkflowInSessionThunk, updateStatusWorkflowsThunk, updateUserPreferencesThunk } from "../../redux/thunks/RepertoireThunks";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IDataActionToolbar } from "../types/IDataActionToolbar";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { FormatFields } from "../../redux/types/FormatFields";
import { IAssingWorkflowViewProps } from "../components/assingWorkflow/AssingWorkflowView";
import { IAssignWorkflowModel } from "../types/IAssignWorkflowModel";
import { at } from "lodash";
import { IUpdateWorkflowViewProps } from "../components/updateWorkflow/UpdateWorkflowView";
import { getUsersThunk } from "../../redux/thunks/AccountThunks";
import { IWorkflowSearchResult } from "../types/IWorkflowSearchResult";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { ITabProps } from "../../redux/types/ITabProps";

export default connect(
    (state: IAppState, props: ITabProps) => ({
        workflowPageData: state.repertoire.componentData.WorkflowPage,
        searchViewData: state.repertoire.componentData.SearchView,
        searchResultsTableData: state.repertoire.componentData.SearchResultsTable,
        lookupValues: state.lookupEntities,
        searchSuccessful: state.repertoire.searchSuccessful,
        workflowSearchResults: state.repertoire.panes[props.paneIndex].tabs && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].workflowSearchResult,
        workMaintenanceGeneralDataViewData: state.repertoire.componentData.WorkMaintenanceGeneralDataView,
        productMaintenanceGeneralDataViewData: state.repertoire.componentData.ProductMaintenanceGeneralDataView,
        workflowPageToolbarData: state.repertoire.componentData.WorkflowPageToolbar,
        tabs: state.repertoire.panes[props.paneIndex].tabs,
        activeTab: state.repertoire.panes[props.paneIndex].activeTab,
        assingWorkflowDataView: state.repertoire.componentData.AssingWorkflowView,
        dataGridTableData: state.repertoire.componentData.DataGridTable,
        latestWorkflowSearchQuery: state.repertoire.panes[props.paneIndex].tabs && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].workflowSearchQuery,
        updateWorkflowViewData: state.repertoire.componentData.UpdateWorkflowViewData,
        users: state.repertoire.repertoireUsers && state.repertoire.repertoireUsers,
        isSelectedAll: state.repertoire.panes[props.paneIndex].tabs && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].isSelectedAll,
        indexOfFirstResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'workflows')[0].indexOfFirstResult,
        indexOfLastResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'workflows')[0].indexOfLastResult,
        resultsPerPage: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'workflows')[0].resultsPerSection,
        currentPage: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].currentPage ? state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].currentPage : undefined,
        isWorkflowSessionStarted: state.repertoire.isWorkflowSessionStarted,
        workflowSessionItems: state.repertoire.workflowSessionItems,
        dataActionList: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].dataActionToolbar,
        workFormats: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].formatFields,
        isAnySelectedCompletedError: state.repertoire.isAnySelectedCompletedError,
        currentWorkflowIndex: state.repertoire.currentWorkflowIndex,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        allResultsPerPage: state.repertoire.resultsPerPage,
        roles: state.account.roles,
    }),
    (dispatch: Dispatch) => ({
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        hideModal: () => dispatch(hideModal()),
        searchWorkflow: (query: IWorkflowSearchQuery, modalOpen: boolean) =>
            dispatch<any>(searchWorkflowThunk(query, false)),
        getWorkDetails: (dataSource: string, workID: number,
            lookups: ILookupDictionary,
            otherIndicatorsWorkFlagTypes: string[],
            actionDataList: IDataActionToolbar[],
            readonlyIndicatorsWorkFlagTypes: string[],
            workMaintenanceViewData?: IRepertoireComponentDataItem,
            formats?: FormatFields[]) =>
            dispatch<any>(getWorkDetailsThunk(dataSource, workID, lookups, otherIndicatorsWorkFlagTypes, actionDataList, workMaintenanceViewData, formats, readonlyIndicatorsWorkFlagTypes, true)),
        getWorkDetailsWorkflow: (
            dataSource: string,
            workID: number,
            lookups: ILookupDictionary,
            otherIndicatorsWorkFlagTypes: string[],
            dataActions: IDataActionToolbar[],
            workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem,
            formats: FormatFields[],
            readonlyIndicatorsWorkFlagTypes: string[],
            openEntityForWorkflowSession?: boolean,
            activeTab?: number, currentWorkflowIndex?: number,
            workflows?: IWorkflowSearchResult[],
            workGroup?: string) => 
            dispatch<any>(getWorkDetailsWorkflowThunk(dataSource, workID, lookups, otherIndicatorsWorkFlagTypes, dataActions, workMaintenanceGeneralDataViewData, formats, readonlyIndicatorsWorkFlagTypes, openEntityForWorkflowSession, activeTab, currentWorkflowIndex, workflows)),
        selectSingleWorkflow: (tableContentItem: any, index: number) => dispatch(selectSingleWorkflow(tableContentItem, index)),
        onOpenAssignView: (tittle: string, props: IAssingWorkflowViewProps) => dispatch(openassignWorkflowTo(tittle, props)),
        assignWorkflows: (model: IAssignWorkflowModel, searchBody: IWorkflowSearchQuery) => dispatch<any>(assignWorkflowsThunk(model, searchBody)),
        updateStatusWorkflows: (model: IAssignWorkflowModel, searchBody: IWorkflowSearchQuery) => dispatch<any>(updateStatusWorkflowsThunk(model, searchBody)),
        onOpenUpdateWorkflows: (tittle: string, props: IUpdateWorkflowViewProps) => dispatch(showUpdateWorkflow(tittle, props)),
        clearSelection: () => dispatch(clearWorkflowSelection()),
        getUsers: () => dispatch<any>(getUsersThunk()),
        selectAllWorkflow: (indexOfFirst: number, indexOfLast: number, value: boolean) => dispatch(selectAllWorkflow(indexOfFirst, indexOfLast, value)),
        markSelectAll: (value: boolean) => dispatch(markSelectAll(value)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        startWorkflowSession: (items: IWorkflowSearchResult[], index: number) => dispatch<any>(startWorkflowSession(items, index)),
        nextWorkflowItem: () => dispatch(nextWorkflowInSession()),
        cancelWorkflowSession: (activeTab: number) => dispatch<any>(cancelWorkflowSessionThunk(activeTab)),
        skipWorkflowItem: (activeTab: number
            , currentWorkflowIndex: number
            , workflows: IWorkflowSearchResult[]
            , lookups: ILookupDictionary
            , otherIndicatorsWorkFlagTypes: string[]
            , dataActions: IDataActionToolbar[] = []
            , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
            , formats: FormatFields[]
            , readonlyIndicatorsWorkFlagTypes: string[]) => dispatch<any>(skipWorkflowInSessionThunk(activeTab, currentWorkflowIndex, workflows, lookups, otherIndicatorsWorkFlagTypes, dataActions, workMaintenanceGeneralDataViewData, formats, readonlyIndicatorsWorkFlagTypes)),
        getFieldFormats: (key: string, group: string) => dispatch<any>(getFieldFormatsThunk(key, group)),
        setErrorWorkflowSession: () => dispatch(setErrorWorkflowSession()),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        getUsageDetails: (usageID: string, openEntityForWorflowSession?: boolean) =>
            dispatch<any>(getUsageMaintenanceDataThunk(usageID, openEntityForWorflowSession)),
        skipUsageWorkflowItem: (activeTab: number
            , currentWorkflowIndex: number
            , workflows: IWorkflowSearchResult[]) => dispatch<any>(skipUsageWorkflowInSessionThunk(activeTab, currentWorkflowIndex, workflows)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
        getProductDetails: (dataSource: string, productID: number,
            productMaintenanceGeneralDataView: IRepertoireComponentDataItem,
            formats?: FormatFields[],
            openEntityForWorflowSession?: boolean) =>
            dispatch<any>(getProductDetailsThunk(dataSource, productID, productMaintenanceGeneralDataView, formats, openEntityForWorflowSession)),
        getAgreementDetails: (dataSource: string, agreementID: number,lookups: ILookupDictionary,dataActions: IDataActionToolbar[],openEntityForWorflowSession?: boolean) =>
            dispatch<any>(getAgreementDetailsThunk(dataSource, agreementID, lookups, dataActions, openEntityForWorflowSession)),

    })
)(WorkflowPage);
