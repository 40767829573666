import { connect } from "react-redux";
import { openAccordion, closeAccordion, addRepertoireAttachmentSuccess, addDroppedFile, setProgressUploadFileRepertoireProccess, cleanDroppedFilesRepertoireState, incrementTempID, nextWorkflowInSession, updateProductFields, setValidationWarningMessage } from "../../../redux/reducers/RepertoireReducer";
import { IRepertoireField } from "../../types/IRepertoireField";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { hideModal, showMessage, showModal, showUpdateFieldsModal, showYesNoPrompt } from "../../../redux/reducers/ModalReducer";
import { addTab, copyExistingProduct, createNewProduct, resetMessageBanner, setChangesMade, setEditableFields, sortProductFieldData, updateEditableFields, updateProductField, expandMatchingProduct } from "../../../redux/reducers/RepertoireReducer";
import { matchProductBatchThunk, postProductBatchThunk } from "../../../redux/thunks/JobThunks";
import { clearModalSearchResultsThunk } from "../../../redux/thunks/ModalThunks";
import { AddtoPackageThunk, cancelWorkflowSessionThunk, deleteProductThunk, getFieldFormatsThunk, getIPDetailsThunk, getProductDetailsThunk, getProductMaintenanceSubmissionConfigThunk, getWorkDetailsThunk, mergeProductThunk, refreshProductDetailsThunk, removeProductAttachmentIfExistsThunk, saveProductThunk, saveWorkflowThunk, searchProductVersionHistoryThunk, skipUsageWorkflowInSessionThunk, skipWorkflowInSessionThunk, submitProductAVRequestThunk, undoProductChangesThunk, updateComponentFieldItemsThunk, updateUserPreferencesThunk } from "../../../redux/thunks/RepertoireThunks";
import { FormatFields } from "../../../redux/types/FormatFields";
import IAppState from "../../../redux/types/IAppState";
import { IProductState, IProductStateKeys } from "../../../redux/types/IProductState";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { IYesNoPromptViewModalProps } from "../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IShowFieldSettingViewModalProps } from "../../components/repertoireModal/RepertoireModal";
import { DataSource } from "../../types/DataSource";
import { IDataActionToolbar } from "../../types/IDataActionToolbar";
import { IProductAVRequest } from "../../types/IProductAVRequest";
import { IResultsPerPage } from "../../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../../redux/types/IActiveAccordion";
import { addFileToUpload, CleanUploadFilesState, setInDropZone, setProgressUploadFileProccess, sortColumn, updatePagination } from "../../../redux/reducers/DataIngestionReducer";
import { getDataIngestionComponentDataThunk, getFilesystemsThunk, refreshDirectoryThunk, selectedNodeChangedThunk } from "../../../redux/thunks/DataIngestionThunks";
import IFile from "../../../redux/types/IFiles";
import { IAttachedFile } from "../../../redux/types/IAttachedFile";
import { IFileSystem } from "../../../dataingestion/types/IFileSystem";
import { ITreeNodeData } from "../../../treeview/types/TreeNodeData";
import { SaveOption } from "../../types/SaveOption";
import { ITreeData } from "../../types/ITreeData";
import { PRODUCT_WORKFLOW } from "../../Consts";
import { IWorkflowParams } from "../../types/IWorkFlowParams";
import { IWorkflowSearchResult } from "../../types/IWorkflowSearchResult";
import { ManualMergeOptions } from "../../types/ManualMergeOptions";
import ProductMaintenancePageScrollListener from "./ProductMaintenancePageScrollListener";
import { IPopUpInfoProps } from "../../components/modalViews/popUpInfo/PopUpInfo";
import { ITabProps } from "../../../redux/types/ITabProps";

export default connect(
    (state: IAppState, props: ITabProps) => ({
        productMaintenanceGeneralDataViewData:
            state.repertoire.componentData.ProductMaintenanceGeneralDataView,
        productMaintenanceGridsViewData:
            state.repertoire.componentData.ProductMaintenanceGridsView,
        productMaintenanceAccordionViewData:
            state.repertoire.componentData.ProductMaintenanceAccordionView,
        productMaintenanceToolbarData:
            state.repertoire.componentData.ProductMaintenanceToolbar,
        productMaintenanceSaveResultData:
            state.repertoire.componentData.SaveResult,
        dataGridTableData: state.repertoire.componentData.DataGridTable,
        avWorkDetailsAccordianViewData: state.repertoire.componentData.AVWorkDetailsAccordianViewData,
        avSubmissionDetailsAccordianViewData: state.repertoire.componentData.AVSubmissionViewData,
        isFetchingProduct: state.repertoire.isFetchingProduct,
        lookupValues: state.lookupEntities,
        paneIndex: props.paneIndex,
        activeTab: state.repertoire.panes[props.paneIndex].activeTab,
        tabs: state.repertoire.panes[props.paneIndex].tabs,
        saveInProgress: state.repertoire.saveInProgress,
        dataSource: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].productMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].productMaintenanceState.product && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].productMaintenanceState.product.dataSource,
        loaded: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].productMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].productMaintenanceState.loaded,
        product: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].productMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].productMaintenanceState.product,
        activeAccordian: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].activeAccordions,
        workMaintenanceGeneralDataViewData: state.repertoire.componentData.WorkMaintenanceGeneralDataView,
        ipMaintenanceGeneralDataView: state.repertoire.componentData.IPMaintenanceGeneralDataView,
        editableFieldsDataView: state.repertoire.componentData.EditableFieldsDataView,
        formatFields: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].formatFields,
        workflowFieldsData: state.repertoire.componentData.WorkflowFieldsData,
        isWorkflowSessionStarted: state.repertoire.isWorkflowSessionStarted,
        workflowSessionItems: state.repertoire.workflowSessionItems,
        dataActionList: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].dataActionToolbar,
        workFormats: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].formatFields,
        currentWorkflowIndex: state.repertoire.currentWorkflowIndex,
        isOpenForWorkflowSession: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].isEntityForWorkflowSession,
        productMaintenanceSubmissionConfig: state.repertoire.productMaintenanceSubmissionConfig,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        allResultsPerPage: state.repertoire.resultsPerPage,
        containerDetailsWindowComponentData: state.dataIngestion.componentData.ContainerDetailsWindow,
        storagePathItemData: state.dataIngestion.componentData.StoragePathItem,
        distributions: state.dataIngestion.distributions,
        formats: state.dataIngestion.formats,
        storagePaths: state.dataIngestion.storagePaths,
        selectedFileSystem: state.dataIngestion.selectedFileSystem,
        destinations: state.dataIngestion.destinations && state.dataIngestion.destinations.lookups,
        droppedFiles: state.dataIngestion.droppedFiles,
        isDropZone: state.dataIngestion.droppedFiles.inDropZone,
        customFields: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].customFields,
        filesystems: state.dataIngestion.filesystems,
        treeNodes: state.dataIngestion.treeNodes,
        selectedNode: state.dataIngestion.selectedNode,
        attachedFiles: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].productMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].productMaintenanceState.product && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].productMaintenanceState.product.files,
        productAttachmentsDropped: state.repertoire.panes[props.paneIndex].tabs && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].droppedFiles,
        editableFields: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].editableFields,
        currentUser: state.account.currentUser,
        sources: state.repertoire.matchingSourceTreeData,
        paginationDetails: state.dataIngestion.paginationDetails,
        expandedProductResults: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].expandedProductResults,
        roles: state.account.roles,
        commentCategoryFlag: state.repertoire.commentCategoryFlag,
        useNewUIWorkMaintenanceGeneralView: state.repertoire.useNewUIWorkMaintenanceGeneralView,
        useNewUIProductMaintenanceGeneralView: state.repertoire.useNewUIProductMaintenanceGeneralView,
        cueSheetsEnabled:state.repertoire.enableCuesheet,
        cuesheetDatasource:state.repertoire.cuesheetDatasource
    }),
    dispatch => ({
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string,
            modalMessage?: string,
            isReadOnly?: boolean,
            key?: string,
            index?: number
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title, null, null, null, index)
            ),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps, message: string) => dispatch(showYesNoPrompt(title, props, message)),
        hideModal: () => dispatch(hideModal()),
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        clearModalSearchResults: () => dispatch<any>(clearModalSearchResultsThunk()),
        openAccordian: (accordionName: string, componentName: string) =>
            dispatch(openAccordion(accordionName, componentName)),
        closeAccordian: (accordionName: string, componentName: string) =>
            dispatch(closeAccordion(accordionName, componentName)),
        saveChanges: (
            updatedProduct: IProductState,
            saveOption: SaveOption,
            lookups: ILookupDictionary,
            dataActions: IDataActionToolbar[],
            activeTab?: number,
            formats?: FormatFields[],
            workflowsSessionItems?: IWorkflowSearchResult[],
            currentWorkflowIndex?: number,
            isOpenForWorkflowSession?: boolean,
            paneIndex?: number
        ) => dispatch<any>(saveProductThunk(updatedProduct, saveOption, lookups, workflowsSessionItems, currentWorkflowIndex, isOpenForWorkflowSession, activeTab, formats, dataActions, paneIndex)),
        undoChanges: (dataSource: string, productID: number, lookups: ILookupDictionary, paneIndex: number) => dispatch<any>(undoProductChangesThunk(dataSource, productID, lookups, paneIndex)),
        addNewProduct: (lookups: ILookupDictionary, isNew?: boolean, productMaintenanceGeneralViewData?: IRepertoireComponentDataItem, formats?: FormatFields[], paneIndex?: number) =>
            dispatch(createNewProduct(lookups, isNew, productMaintenanceGeneralViewData, formats, paneIndex)),
        copyExistingProduct: (paneIndex: number) => dispatch(copyExistingProduct(paneIndex)),
        deleteProduct: (dataSource: DataSource, productId: number, activeTab: number, paneIndex?: number) => dispatch<any>(deleteProductThunk(dataSource, productId, activeTab, paneIndex)),
        // mergeProduct: (product: IProductState, matchingProductId: number, mergeOption: ManualMergeOptions, lookups: ILookupDictionary, dataAction: IDataActionToolbar[], mergeIntoMatch: boolean) =>
        //     dispatch<any>(mergeProductThunk(product, matchingProductId, mergeOption, lookups, dataAction, mergeIntoMatch)),
        showMessage: (props: IPopUpInfoProps) => dispatch(showMessage(props)),
        setChangesMade: (tabIndex: number, changesMade: boolean) => dispatch(setChangesMade(tabIndex, changesMade)),
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        updateProductField: (value: any, fieldName: IProductStateKeys) => dispatch(updateProductField(value, fieldName)),
        updateProductFields: (value: any) => dispatch(updateProductFields(value)),
        sortProductFieldData: (value: any, fieldName: IProductStateKeys) => dispatch(sortProductFieldData(value, fieldName)),
        searchProductVersionHistory: (productCoreId: number, formats: FormatFields[]) => dispatch<any>(searchProductVersionHistoryThunk(productCoreId, formats)),
        getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataActions?: IDataActionToolbar[], workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[]) =>
            dispatch<any>(getWorkDetailsThunk(dataSource, workID, lookups, otherIndicatorsWorkFlagTypes, dataActions, workMaintenanceGeneralDataViewData, formats, readonlyIndicatorsWorkFlagTypes)),
        getIPDetails: (ipBaseNumber: string, accountNumber: string, ipMaintenanceGeneralDataView: IRepertoireComponentDataItem) =>
            dispatch<any>(getIPDetailsThunk(ipBaseNumber, accountNumber, ipMaintenanceGeneralDataView)),
        postProductBatch: (productBatchID: number) => dispatch<any>(postProductBatchThunk(productBatchID)),
        matchProductBatch: (productBatchID: number) => dispatch<any>(matchProductBatchThunk(productBatchID)),
        setEditableFields: (componentFieldName: string, componentInstance: string) => dispatch<any>(setEditableFields(componentFieldName, componentInstance)),
        updateEditableField: (value: any, key: string) => dispatch(updateEditableFields(value, key)),
        showUpdateFieldsModal: (tittle: string, props: IShowFieldSettingViewModalProps) => dispatch<any>(showUpdateFieldsModal(tittle, props)),
        updateComponentFieldsItems: (
            fields: IRepertoireField[],
            componentName: string,
            componentInstance: string,
            componentDataFieldName: string,
            tabs: ITabReduxItem[],
            activeTab: number) => dispatch<any>(updateComponentFieldItemsThunk(fields, componentName, componentInstance, componentDataFieldName, tabs, activeTab)),
        getFieldFormats: (key: string, group: string) => dispatch<any>(getFieldFormatsThunk(key, group)),
        submitProductAVRequest: (productAVRequest: IProductAVRequest, productCoreID: number) => dispatch<any>(submitProductAVRequestThunk(productAVRequest, productCoreID)),
        getProductDetails: (dataSource: string, productCoreID: number, productMaintenanceGeneralDataView: IRepertoireComponentDataItem, formats?: FormatFields[]) =>
            dispatch<any>(getProductDetailsThunk(dataSource, productCoreID, productMaintenanceGeneralDataView, formats)),
        getProductMaintenanceSubmissionConfig: () => dispatch<any>(getProductMaintenanceSubmissionConfigThunk()),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        setInDropZone: (inDropZone: boolean) => dispatch(setInDropZone(inDropZone)),
        refreshDirectory: (filesystem: IFileSystem, sources: ITreeData[]) => dispatch<any>(refreshDirectoryThunk(filesystem, sources)),
        addFileToUpload: (file: IFile, isComplete: boolean) => dispatch<any>(addFileToUpload(file, isComplete)),
        setProgressUploadFileProccess: (message: string, file: IFile, isUploadComplete: boolean) => dispatch<any>(setProgressUploadFileProccess(message, file, isUploadComplete)),
        sortColumn: (section: string, ascending: boolean) => dispatch<any>(sortColumn(section, ascending)),
        cleanUploadFilesState: () => dispatch(CleanUploadFilesState()),
        getFilesystems: (isRepertoireModule?: boolean) => dispatch<any>(getFilesystemsThunk(isRepertoireModule)),
        selectedNodeChanged: (node: ITreeNodeData<IFileSystem>, sources: ITreeData[]) => dispatch<any>(selectedNodeChangedThunk(node, sources)),
        getDataIngestionComponentData: () => dispatch<any>(getDataIngestionComponentDataThunk()),
        addRepertoireAttachmentToState: (attachedFile: IAttachedFile) => dispatch<any>(addRepertoireAttachmentSuccess(attachedFile)),
        removeProductAttachmentIfExists: (attachedFile: IAttachedFile, source: DataSource) => dispatch<any>(removeProductAttachmentIfExistsThunk(attachedFile, source)),
        addDroppedFileRepertoire: (file: IFile, inProgress: boolean) => dispatch(addDroppedFile(file, inProgress)),
        setProgressUploadFileRepertoire: (message: string, file: IFile, isUploadComplete: boolean) => dispatch(setProgressUploadFileRepertoireProccess(message, file, isUploadComplete)),
        cleanDroppedFilesRepertoire: () => dispatch(cleanDroppedFilesRepertoireState()),
        refreshProductDetails: (dataSource: string, productCoreID: number, activeTabItem: ITabReduxItem, editableFields: IRepertoireField[], formats: FormatFields[]) =>
            dispatch<any>(refreshProductDetailsThunk(dataSource, productCoreID, activeTabItem, formats, editableFields)),
        updatePagination: (resultsPerPage: number, currentPage: number, section: string) =>
            dispatch<any>(updatePagination(resultsPerPage, currentPage, section)),
        incrementTempID: () => dispatch<any>(incrementTempID()),
        nextWorkflowItem: () => dispatch(nextWorkflowInSession()),
        cancelWorkflowSession: (activeTab: number) => dispatch<any>(cancelWorkflowSessionThunk(activeTab)),
        skipWorkflowItem: (activeTab: number
            , currentWorkflowIndex: number
            , workflows: IWorkflowSearchResult[]
            , lookups: ILookupDictionary
            , otherIndicatorsWorkFlagTypes: string[]
            , dataActions: IDataActionToolbar[] = []
            , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
            , formats: FormatFields[],
            readonlyIndicatorsWorkFlagTypes: string[]) => dispatch<any>(skipWorkflowInSessionThunk(activeTab, currentWorkflowIndex, workflows, lookups, otherIndicatorsWorkFlagTypes, dataActions, workMaintenanceGeneralDataViewData, formats, readonlyIndicatorsWorkFlagTypes)),
        skipUsageWorkflowItem: (activeTab: number
            , currentWorkflowIndex: number
            , workflows: IWorkflowSearchResult[]) => dispatch<any>(skipUsageWorkflowInSessionThunk(activeTab, currentWorkflowIndex, workflows)),
        saveWorkflow: (workflowparams: IWorkflowParams) =>
            dispatch<any>(saveWorkflowThunk(workflowparams, PRODUCT_WORKFLOW)),
        expandMatchingProduct: (index: number) => dispatch<any>(expandMatchingProduct(index)),
        manualMergeProduct: (product: IProductState, matchingProductId: number, manualMergeOption: ManualMergeOptions, lookups: ILookupDictionary, dataAction: IDataActionToolbar[], mergeIntoMatch: boolean, paneIndex?: number) => 
            dispatch<any>(mergeProductThunk(product, matchingProductId, manualMergeOption, lookups, dataAction, mergeIntoMatch, paneIndex)),
        setValidationWarningMessage: (displayWarning: boolean) => dispatch(setValidationWarningMessage(displayWarning)),
        addToPackages:( userName:string,entityType:string,entityID:number, paneIndex: number)=>dispatch<any>(AddtoPackageThunk(userName,entityType,entityID,paneIndex)),
    })
)(ProductMaintenancePageScrollListener);
