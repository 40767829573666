import * as React from "react";
import { If } from "../../../../core/components/If";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { IDistribution } from "../../../types/usageTypes/IDistribution";
import { IFileSystem } from "../../../../dataingestion/types/IFileSystem";
import { IStoragePathItemData } from "../../../../dataingestion/types/IStoragePathItemData";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { addFileToUpload, CleanUploadFilesState, resetPagination, setInDropZone, setProgressUploadFileProccess, sortColumn, updatePagination } from "../../../../redux/reducers/DataIngestionReducer";
import {
    hideModal,
    showMessage,
    showModal,
    showUpdateFieldsModal,
    showWorkSubmission,
    showYesNoPrompt
} from "../../../../redux/reducers/ModalReducer";
import {
    addDroppedFile, addTab, autoMatchWork, cleanDroppedFilesRepertoireState, clearModalSearchResults, enableCustomField, getReadonlyFlagsFieldFailure, matchWorkBatchRequest, nextWorkflowInSession, postWorkBatchRequest,
    setChangesMade, setEditableFields, setProgressUploadFileRepertoireProccess, sortWorkFieldData,
    switchSetTypeGroup,
    updateContributorSetType, updateEditableFields, updateWorkField, updateWorkFields,
} from "../../../../redux/reducers/RepertoireReducer";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import { IAttachedFile } from "../../../../redux/types/IAttachedFile";
import IDroppedFiles from "../../../../redux/types/IDroppedFiles";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IResultsPerPage } from "../../../../redux/types/IResultsPerPage";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IWorkState } from "../../../../redux/types/IWorkState";
import { ITreeNodeData } from "../../../../treeview/types/TreeNodeData";
import { getDataAction } from "../../../components/toolBar/ToolbarHelper";
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP, PRODUCT_MAINTENANCE_GROUP, } from "../../../ConfigurationConsts";
import {
    COMPONENT_INSTANCE_WORKS, EVENT_HANDLER_THROTTLE_TIME, WORK_MAINTENANCE_GENERAL_DATA_VIEW, WORK_MAINTENANCE_TOOLBAR,
} from "../../../Consts";
import { WorkDetailsMapper } from "../../../services/WorkDetailsMapper";
import { DataSource } from "../../../types/DataSource";
import { IAgreementShareDisplayConfiguration } from "../../../types/IAgreementShareDisplayConfiguration";
import { IContainedWork } from "../../../types/IContainedWork";
import { IContributorSiteConfiguration } from "../../../types/IContributorSiteConfiguration";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IShareToleranceValueConfiguration } from "../../../types/IShareToleranceValueConfiguration";
import { IWorkflowSearchResult } from "../../../types/IWorkflowSearchResult";
import { IWorksSubmission } from "../../../types/IWorkSubmission";
import { ManualMergeOptions } from "../../../types/ManualMergeOptions";
import { SaveOption } from "../../../types/SaveOption";
import WorkMaintenanceGeneralDataView from "../workMaintenanceGeneralDataView/WorkMaintenanceGeneralDataView";
import WorkMaintenanceGeneralDataViewFormik from "../workMaintenanceGeneralDataView/WorkMaintenanceGeneralDataViewFormik";
import WorkMaintenanceGridsView from "../workMaintenanceGridsView/WorkMaintenanceGridsView";
import WorkMaintenanceToolbar from "../workMaintenanceToolbar/WorkMaintenanceToolbar";
import { ITreeData } from "../../../types/ITreeData";
import { SearchRequests } from "../../../services/SearchRequests";
import { IPaginationDetail } from "../../../../redux/types/IPaginationDetail";
import { IReadonlyFlagsField } from "../../../types/IReadonlyFlagsField";
import { Dictionary } from "../../../../core/types/Dictionary";
import { IWorkflowParams } from "../../../types/IWorkFlowParams";
import { INumberAdditional } from "../../../types/INumberAdditional";
import { IWorksSearchResult } from "../../../types/IWorksSearchResult";
import { IWorksSearchQuery } from "../../../types/IWorksSearchQuery";
import WorkMaintenanceWrapper from "../workMaintenanceGeneralDataView/WorkMaintenanceWrapper";
import { updateWorkWithGeneralWorkData } from "./workMaintenanceHelpers";
import { IWorkSubmissionStatus } from "../../../types/IWorkSubmissionStatus";
import { EntityType } from "../../../types/EntityType";

export interface IWorkMaintenancePageProps {
    productMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    workMaintenanceGridsViewData?: IRepertoireComponentDataItem;
    workMaintenanceAccordionViewData?: IRepertoireComponentDataItem;
    workMaintenanceToolbarData?: IRepertoireComponentDataItem;
    workMaintenanceSaveResultData?: IRepertoireComponentDataItem;
    workMaintenanceSearchResultData?: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    contributorsDataViewData?: IRepertoireComponentDataItem;
    workMetadataAccordionViewData?: IRepertoireComponentDataItem;
    workSubmissionDetails?: IRepertoireComponentDataItem;
    showModal: typeof showModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    showMessage: typeof showMessage;
    hideModal: typeof hideModal;
    clearModalSearchResults: typeof clearModalSearchResults;
    setChangesMade: typeof setChangesMade;
    addTab: typeof addTab;
    updateWorkField: typeof updateWorkField;
    updateWorkFields: typeof updateWorkFields;
    sortWorkFieldData: typeof sortWorkFieldData;
    saveChanges: (
        updatedWork: IWorkState,
        saveOption: SaveOption,
        lookups: ILookupDictionary, 
        otherIndicatorsWorkFlagTypes: string[],
        dataActions: IDataActionToolbar[],
        readonlyIndicatorsWorkFlagTypes: string[],
        isWorkflowSession?: boolean,
        workflowsSessionItems?: IWorkflowSearchResult[],
        currentWorkflowIndex?: number,
        activeTab?: number,
        formats?: FormatFields[],
        workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem,
        isOpenForWorkflowSession?: boolean,
        customer?: string,
        worksSearchResults?: IWorksSearchResult[],
        paneIndex?: number,
    ) => void;
    autoMatch: typeof autoMatchWork;
    resetMessageBanner: () => void;
    undoChanges: (dataSource: string, workID: number, lookups: ILookupDictionary, paneIndex: number) => void;
    postWorkBatch: typeof postWorkBatchRequest;
    matchWorkBatch: typeof matchWorkBatchRequest;
    switchSetTypeGroup: typeof switchSetTypeGroup;
    updateContributorSetType: typeof updateContributorSetType;
    searchWorkVersionHistory: (workId: number, lookups: ILookupDictionary, actionList: IDataActionToolbar[], formats: FormatFields[], otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataSource: DataSource, paneIndex: number) => void;
    work: IWorkState;
    isFetchingWork: boolean;
    lookupValues: ILookupDictionary;
    activeAccordian: string[];
    openWorkAccordian: (accordionName: string, componentName: string) => void;
    closeWorkAccordian: (accordionName: string, componentName: string) => void;
    addNewWork: (lookups: ILookupDictionary, actionList?: IDataActionToolbar[], isNew?: boolean, workMaintenanceGeneralDataView?: IRepertoireComponentDataItem, formats?: FormatFields[], paneIndex?: number) => void;
    addNewAccount: (lookupValues: ILookupDictionary, isNew?: boolean, ipMaintenanceGeneralDataView?: IRepertoireComponentDataItem) => void;
    copyExistingWork: (actionList: IDataActionToolbar[], userName: string, lookups: ILookupDictionary, paneIndex?: number) => void;
    deleteWork: (dataSource: DataSource, workId: number, activeTab: number, paneIndex: number) => void;
    activeTab: number;
    tabs: ITabReduxItem[];
    dataSource: DataSource;
    saveInProgress: boolean;
    getAgreementDetails: (dataSource: string, agreementID: number, lookups: ILookupDictionary, dataAction?: IDataActionToolbar[], agreementGeneralDataView?: IRepertoireComponentDataItem) => void;
    getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataAction?: IDataActionToolbar[], workMaintenanceDataView?: IRepertoireComponentDataItem, formats?: FormatFields[], isOpenForWorkflowSession?: boolean) => void;
    getProductDetails: (dataSource: string, productID: number, productMaintenanceGeneralDataView: IRepertoireComponentDataItem, formats: FormatFields[]) => void;
    refreshWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], activeTabItem: ITabReduxItem, readonlyIndicatorsWorkFlagTypes: string[], formats?: FormatFields[]) => void;
    loadMatchConnectorStatus: (workID: number, activeTabItem: ITabReduxItem) => void;
    getIPDetails: (ipBaseNumber: string, accountNumber: string, ipMaintenanceGeneralDataView: IRepertoireComponentDataItem) => void;
    showWorkSubmissionDetail?: typeof showWorkSubmission;
    currentUser?: string;
    manualMergeWork: (work: IWorkState, matchingWorkId: number, manualMergeOption: ManualMergeOptions, lookups: ILookupDictionary, dataAction: IDataActionToolbar[], mergeIntoMatch: boolean, customer: string, paneIndex: number) => void,
    contributorDisplaySettings: IContributorSiteConfiguration[]
    readonlyFlagsField: IReadonlyFlagsField[]
    otherIndicatorsWorkFlagTypes: string[];
    readonlyIndicatorsWorkFlagTypes: string[];
    getContributorDisplaySettings: () => void,
    getReadonlyFlagsField: () => void,
    getOtherIndicatorsWorkFlagTypes: () => void,
    getReadonlyIndicatorsWorkFlagTypes: () => void,
    showUpdateFieldsModal: typeof showUpdateFieldsModal;
    editableFieldsDataView: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    updateEditableField?: typeof updateEditableFields;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    ipMaintenanceGeneralDataView: IRepertoireComponentDataItem;
    agreementMaintenanceGeneralDataView: IRepertoireComponentDataItem;
    productMaintenanceGeneralDataView: IRepertoireComponentDataItem;
    formatFields: FormatFields[];
    getFieldFormats: (key: string, group: string) => void;
    containerDetailsWindowComponentData: IComponentDataItem;
    distributions: IDistribution[];
    formats: ILookupInstance[];
    destinations: ILookupInstance[];
    selectedFileSystem: IFileSystem;
    droppedFiles: IDroppedFiles;
    setInDropZone: typeof setInDropZone;
    addFileToUpload: typeof addFileToUpload;
    sortColumn: typeof sortColumn;
    isDropZone: boolean;
    setProgressUploadFileProccess: typeof setProgressUploadFileProccess;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    storagePaths: IStoragePathItemData[];
    storagePathItemData: IComponentDataItem;
    refreshDirectory: (filesystem: IFileSystem, sources: ITreeData[]) => void;
    customFields: number[];
    enableCustomField: typeof enableCustomField;
    getFilesystems: (isRepertoireModule?: boolean) => void;
    filesystems: IFileSystem[];
    treeNodes: ITreeNodeData<IFileSystem>[];
    selectedNode: ITreeNodeData<IFileSystem>;
    selectedNodeChanged: (node: ITreeNodeData<IFileSystem>, sources: ITreeData[]) => void;
    getDataIngestionComponentData: () => void;
    genreCategories: Dictionary<Array<string>>;
    getGenreCategories: () => void;
    addRepertoireAttachmentToState: (attachedFile: IAttachedFile) => void;
    attachedFiles: IStoragePathItemData[];
    removeWorkAttachmentIfExists: (attachedFile: IAttachedFile, source: DataSource) => void;
    workAttachmentsDropped?: IDroppedFiles;
    addDroppedFileRepertoire?: typeof addDroppedFile;
    setProgressUploadFileRepertoire: typeof setProgressUploadFileRepertoireProccess;
    cleanDroppedFilesRepertoire: typeof cleanDroppedFilesRepertoireState;
    updateSetTypeOnBreak: (setType: string) => void;
    editableContributorFieldGridDataView: IRepertoireComponentDataItem;
    editableFields?: IRepertoireField[];
    workflowFieldsData: IRepertoireComponentDataItem;
    agreementShareDisplayOptions: IAgreementShareDisplayConfiguration;
    shareDecimalsToDisplay?: number;
    isWorkflowSessionStarted?: boolean;
    cancelWorkflowSession?: (activeTab: number) => void;
    nextWorkflowItem?: typeof nextWorkflowInSession;
    skipWorkflowItem: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , lookups: ILookupDictionary
        , otherIndicatorsWorkFlagTypes: string[]
        , dataActions: IDataActionToolbar[]
        , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
        , formats: FormatFields[],
        readonlyIndicatorsWorkFlagTypes: string[]) => void;
    workflowSessionItems: IWorkflowSearchResult[];
    currentWorkflowIndex?: number
    isOpenForWorkflowSession?: boolean;
    setWarningMessage?: (perfWarning: boolean, mechWarning: boolean) => void;
    setValidationWarningMessage?: (displayWarning: boolean) => void;
    shareToleranceValues?: IShareToleranceValueConfiguration;
    containerWorks?: IContainedWork[];
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    sources: ITreeData[];
    skipUsageWorkflowItem: (activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]) => void;
    paginationDetails: IPaginationDetail[];
    updatePagination: typeof updatePagination;
    expandedWorkResults?: number[];
    expandMatchingWork?: (index: number) => void;
    saveWorkflow?: (workflowparams: IWorkflowParams) => void;
    genreCategoriesByDomOrInt: Dictionary<Array<string>>;
    getGenreCategoriesByDomOrInt: () => void;
    roles?: string[];
    incrementTempID?: () => void;
    commentCategoryFlag?: boolean;
    useNewUIWorkMaintenanceGeneralView: boolean;
    saveAdditionalWorkNumbers?: (work: IWorkState, lookups: ILookupDictionary, pageNumber: number,customer: string) => void;
    getCurrentPageDetails?: (work: IWorkState, lookups: ILookupDictionary, pageNumber: number) => void;
    customer?: string;
    worksSearchResults?: IWorksSearchResult[];
    searchWorks: (query: IWorksSearchQuery, modalOpen: boolean) => void;
    excludeSourceTypePickShowIP?: string[];
    filterGenreCategories: boolean;
    getFilterGenreCategories: () => void;
    updatedScroll?: number;
    pdArrangementMusicLyric?: boolean;
    recalculateDomesticorForeign?:boolean;
    addToPackages?:(userName:string, entityType:string, entityID:number, paneIndex: number)=>void;
    paneIndex?: number;
}


interface IWorkMaintenancePageState {
    toolbarWidth: number;
    iceSubmissions?: IWorksSubmission[];
    iswcSubmissions?: IWorksSubmission[];
    cisNetSubmissions?: IWorksSubmission[];
    widSubmissions?: IWorksSubmission[];
    isCompleteWorkflowOnSave: boolean;
    isChangeGeneralForm: boolean;
}

export class WorkMaintenancePage extends React.PureComponent<
    IWorkMaintenancePageProps,
    IWorkMaintenancePageState
> {
    toolbarParentRef;
    myGeneralDataFormikRef;

    constructor(props: IWorkMaintenancePageProps) {
        super(props);
        this.state = {
            toolbarWidth: null,
            iceSubmissions: [],
            iswcSubmissions: [],
            cisNetSubmissions: [],
            widSubmissions: [],
            isCompleteWorkflowOnSave: true,
            isChangeGeneralForm: false
        };
              
        this.toolbarParentRef = React.createRef();
        this.myGeneralDataFormikRef = React.createRef();
    }

    componentDidMount() {
        setEditableFields(WORK_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_WORKS)

        this.prepareMaintenanceScreen();
    }

    prepareMaintenanceScreen() {
        const {
            contributorDisplaySettings,
            readonlyFlagsField,
            otherIndicatorsWorkFlagTypes,
            getContributorDisplaySettings,
            getOtherIndicatorsWorkFlagTypes,
            getReadonlyIndicatorsWorkFlagTypes,
            getReadonlyFlagsField,
            readonlyIndicatorsWorkFlagTypes,
            formatFields,
            getFieldFormats,
            getFilesystems,
            genreCategories,
            getGenreCategories,
            getDataIngestionComponentData,
            containerDetailsWindowComponentData,
            cleanUploadFilesState,
            genreCategoriesByDomOrInt,
            getGenreCategoriesByDomOrInt,
            filterGenreCategories,
            getFilterGenreCategories
        } = this.props;

        if (otherIndicatorsWorkFlagTypes == undefined) {
            getOtherIndicatorsWorkFlagTypes();
        }
        if (readonlyIndicatorsWorkFlagTypes == undefined) {
            getReadonlyIndicatorsWorkFlagTypes();
        }
        if (readonlyFlagsField !== undefined && (readonlyFlagsField.length === 0)) {
            getReadonlyFlagsField();
        }
        if (contributorDisplaySettings !== undefined && (contributorDisplaySettings.length === 0)) {
            getContributorDisplaySettings();
        }

        if (!formatFields)
            getFieldFormats(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP);
        if (!genreCategories)
            getGenreCategories();

        if (!genreCategoriesByDomOrInt)
            getGenreCategoriesByDomOrInt();

        if (!containerDetailsWindowComponentData || !containerDetailsWindowComponentData.fields || containerDetailsWindowComponentData.fields.length === 0) {
            getDataIngestionComponentData();
        }

        if (!filterGenreCategories)
            getFilterGenreCategories();

        this.checkForWarningMessages()

        getFilesystems(true);
        cleanUploadFilesState();
    }

    completeWorkflowOnSave(value: boolean) {
        this.setState({
            isCompleteWorkflowOnSave: value
        });
    }

    saveAdditionalWorkNumbers(pageNumber: number, additionalNumbers: INumberAdditional[]) {
        const { work, lookupValues, saveAdditionalWorkNumbers, customer } = this.props;
        work.additionalNumbers = additionalNumbers
        saveAdditionalWorkNumbers(work, lookupValues, pageNumber, customer);
    }

    getCurrentPageDetails(pageNumber: number) {
        const { work, lookupValues, getCurrentPageDetails } = this.props;
        getCurrentPageDetails(work, lookupValues, pageNumber);
    }

    setIsChangeGeneralForm = (isChangeGeneralForm) => {
        this.setState({
            isChangeGeneralForm: isChangeGeneralForm
        });
    }

  
    handleSubmitGeneralDataFormikForm = async () => {
        if (this.myGeneralDataFormikRef.current) {
        await this.myGeneralDataFormikRef.current.submitForm(); 
        await this.myGeneralDataFormikRef.current.validateForm(this.myGeneralDataFormikRef.current.values);                 
        }
    };

    saveWork = async (saveOption: SaveOption) => {
        const { saveChanges, setValidationWarningMessage, work, lookupValues, tabs, activeTab, isWorkflowSessionStarted, worksSearchResults, workflowSessionItems, currentWorkflowIndex, formatFields, workMaintenanceGeneralDataViewData, isOpenForWorkflowSession, customer, useNewUIWorkMaintenanceGeneralView, paneIndex } = this.props;
        const { isCompleteWorkflowOnSave } = this.state;
        let newWork = work;
        let source: string;
        this.setIsChangeGeneralForm(false)
        if (useNewUIWorkMaintenanceGeneralView && this.myGeneralDataFormikRef.current) {
            await this.handleSubmitGeneralDataFormikForm();
            if (this.myGeneralDataFormikRef.current.errors && Object.keys(this.myGeneralDataFormikRef.current.errors).length > 0) {
                setValidationWarningMessage(true);
                return;
            }
            if (this.myGeneralDataFormikRef.current.values) {
                newWork = updateWorkWithGeneralWorkData(this.myGeneralDataFormikRef.current.values, work);
            }
        }
        

        if (tabs[activeTab].workMaintenanceState.workGroupId && Number(tabs[activeTab].workMaintenanceState.workGroupId) > 0) {
            newWork.workGroupID = tabs[activeTab].workMaintenanceState.workGroupId;
        }

        if (!tabs[activeTab].workMaintenanceState.isNew && (saveOption === SaveOption.Save || saveOption === SaveOption.SaveMatch)) {
            source = newWork.dataSource
        } else {
            source = (saveOption === SaveOption.SaveMatchPostNew || saveOption === SaveOption.SaveMatchAutoMerge) ? DataSource.Repertoire : DataSource.Intray
        }
        const dataActions = getDataAction(source, WORK_MAINTENANCE_TOOLBAR);

        if (isCompleteWorkflowOnSave) {
            if (newWork.workflows && newWork.workflows[0] && newWork.workflows[0].status) {
                newWork.workflows[0].status = "Completed";
            }
        }
        saveChanges(
            newWork, 
            saveOption, 
            lookupValues, 
            tabs[activeTab].otherIndicatorWorkFlagTypes, 
            dataActions, 
            tabs[activeTab].readonlyIndicatorWorkFlagTypes, 
            isWorkflowSessionStarted, 
            workflowSessionItems, 
            currentWorkflowIndex + 1,
            activeTab, 
            formatFields, 
            workMaintenanceGeneralDataViewData, 
            isOpenForWorkflowSession, 
            customer,
            worksSearchResults,
            paneIndex,
            );
        this.checkForWarningMessages();
    }

    autoMatchWork = async (matchWork: boolean) => {
        const { saveChanges, work, lookupValues, tabs, activeTab, useNewUIWorkMaintenanceGeneralView, setValidationWarningMessage } = this.props;
        let newWork = work;
        if (useNewUIWorkMaintenanceGeneralView && this.myGeneralDataFormikRef.current) {
            await this.handleSubmitGeneralDataFormikForm();
            if (this.myGeneralDataFormikRef.current.errors && Object.keys(this.myGeneralDataFormikRef.current.errors).length > 0) {
                setValidationWarningMessage(true);
                return;
            }
            if (this.myGeneralDataFormikRef.current.values) {
                newWork = updateWorkWithGeneralWorkData(this.myGeneralDataFormikRef.current.values, work);
            }
        }
        this.setIsChangeGeneralForm(false)
        const dataActions = getDataAction(work.dataSource, WORK_MAINTENANCE_TOOLBAR);
        saveChanges(newWork, SaveOption.SaveMatch, lookupValues, tabs[activeTab].otherIndicatorWorkFlagTypes, dataActions, tabs[activeTab].readonlyIndicatorWorkFlagTypes);
    }

    openAccordion(accordionName: string, componentName: string): void {
        const { openWorkAccordian, updateUserPreferences, allResultsPerPage, defaultActiveAccordions } = this.props;
        openWorkAccordian(accordionName, componentName);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, accordionName, true, componentName);
    }
    closeAccordion(accordionName: string, componentName: string): void {
        const { closeWorkAccordian, updateUserPreferences, allResultsPerPage, defaultActiveAccordions } = this.props;
        closeWorkAccordian(accordionName, componentName);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, accordionName, false, componentName);
    }

    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }

    getWorkDetails(dataSource: string, workID: number) {
        const { lookupValues, workMaintenanceGeneralDataViewData, formatFields, isOpenForWorkflowSession, tabs, activeTab } = this.props;
        const dataActions = getDataAction(dataSource, WORK_MAINTENANCE_TOOLBAR);

        this.props.getWorkDetails(dataSource, workID, lookupValues, tabs[activeTab].otherIndicatorWorkFlagTypes, tabs[activeTab].readonlyIndicatorWorkFlagTypes, dataActions, workMaintenanceGeneralDataViewData, formatFields, isOpenForWorkflowSession);
    }

    getProductDetails = (dataSource: string, productID: number) => {
        const { productMaintenanceGeneralDataViewData } = this.props;
        const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, PRODUCT_MAINTENANCE_GROUP)
            .then(formats => {
                this.props.getProductDetails(dataSource, productID, productMaintenanceGeneralDataViewData, formats);
            });
    }

    refreshWorkDetails() {
        const { tabs, activeTab, lookupValues, formatFields, dataSource } = this.props;
        let currentTab = tabs[activeTab];
        this.props.refreshWorkDetails(
            dataSource,
            currentTab.workMaintenanceState.work.workID,
            lookupValues,
            tabs[activeTab].otherIndicatorWorkFlagTypes,
            currentTab,
            tabs[activeTab].readonlyIndicatorWorkFlagTypes,
            formatFields
        )
    }

    getIPDetails(ipBaseNumber: string, accountNumber: string) {
        const { ipMaintenanceGeneralDataView } = this.props;
        this.props.getIPDetails(ipBaseNumber, accountNumber, ipMaintenanceGeneralDataView);
    }

    deleteWork() {
        const { activeTab, dataSource, work, paneIndex } = this.props;
        this.props.deleteWork(dataSource, work.workID, activeTab, paneIndex);
    }

    undoChanges() {
        const { undoChanges, work, lookupValues, resetMessageBanner, paneIndex } = this.props;
        undoChanges(work.dataSource.toString(), work.workID, lookupValues, paneIndex);
        resetMessageBanner();
    }

    copyWork() {
        const { copyExistingWork, tabs, activeTab, currentUser, lookupValues, paneIndex } = this.props;
        const actionList = tabs[activeTab].dataActionToolbar;
        copyExistingWork(actionList, currentUser, lookupValues, paneIndex);
    }

    addWorkToPackages() {
        const { addToPackages, work, currentUser,tabs, activeTab, paneIndex } = this.props;
        const actionList = tabs[activeTab].dataActionToolbar;
        addToPackages(currentUser, EntityType.Work, work.workID, paneIndex);
    }

    addNew() {
        const { addNewWork, lookupValues, tabs, activeTab, workMaintenanceGeneralDataViewData, paneIndex } = this.props;
        const dataActions = getDataAction(DataSource.Intray, WORK_MAINTENANCE_TOOLBAR);
        addNewWork(lookupValues, dataActions, true, workMaintenanceGeneralDataViewData, tabs[activeTab].formatFields, paneIndex);
    }

    addNewAccount() {
        const { addNewAccount, lookupValues, ipMaintenanceGeneralDataView } = this.props;
        addNewAccount(lookupValues, true, ipMaintenanceGeneralDataView);
    }

    toolbarMergeWork = async(workIds: number[]) => {
        const { manualMergeWork, work, lookupValues,customer, setValidationWarningMessage, useNewUIWorkMaintenanceGeneralView, paneIndex } = this.props;
        const dataActions = getDataAction(DataSource.Repertoire, WORK_MAINTENANCE_TOOLBAR);
        let newWork = work;
        if (useNewUIWorkMaintenanceGeneralView && this.myGeneralDataFormikRef.current) {
            await this.handleSubmitGeneralDataFormikForm();
            if (this.myGeneralDataFormikRef.current.errors && Object.keys(this.myGeneralDataFormikRef.current.errors).length > 0) {
                setValidationWarningMessage(true);
                return;
            }
            if (this.myGeneralDataFormikRef.current.values) {
                newWork = updateWorkWithGeneralWorkData(this.myGeneralDataFormikRef.current.values, work);
            }
        }
        manualMergeWork(newWork, workIds[0], ManualMergeOptions.MergeRepertoire, lookupValues, dataActions, false, customer, paneIndex)
    }

    manualMergeWork = async (matchingWorkid: number, mergeOption: string) => {
        const { manualMergeWork, work, lookupValues, formatFields, customer, setValidationWarningMessage, useNewUIWorkMaintenanceGeneralView, paneIndex } = this.props;

        let mergeAction = ManualMergeOptions[mergeOption];
        const dataActions = getDataAction(DataSource.Repertoire, WORK_MAINTENANCE_TOOLBAR);
        let newWork = work;
        if (useNewUIWorkMaintenanceGeneralView && this.myGeneralDataFormikRef.current) {
            await this.handleSubmitGeneralDataFormikForm();
            if (this.myGeneralDataFormikRef.current.errors && Object.keys(this.myGeneralDataFormikRef.current.errors).length > 0) {
                setValidationWarningMessage(true);
                return;
            }
            if (this.myGeneralDataFormikRef.current.values) {
                newWork = updateWorkWithGeneralWorkData(this.myGeneralDataFormikRef.current.values, work);
            }
        }
            manualMergeWork(newWork, matchingWorkid, mergeAction, lookupValues, dataActions, true,customer, paneIndex);
    }
    checkForWarningMessages() {
        const { work, setWarningMessage, shareToleranceValues } = this.props;
        if (work && work.contributors != null) {
            let toleranceLowerPerformer = 99.98;
            let toleranceUpperPerformer = 100.02;
            let toleranceLowerMechanical = 99.98;
            let toleranceUpperMechanical = 100.02;

            if (shareToleranceValues != null) {
                toleranceLowerPerformer = shareToleranceValues.ToleranceLowerPerformer;
                toleranceUpperPerformer = shareToleranceValues.ToleranceUpperPerformer;
                toleranceLowerMechanical = shareToleranceValues.ToleranceLowerMechanical;
                toleranceUpperMechanical = shareToleranceValues.ToleranceUpperMechanical;
            }

            let perfError = false;
            let mechError = false;
            Object.keys(work.contributors).forEach(key => {
                if (key !== 'other' && work.contributors[key] && work.contributors[key].length > 0) {
                    let setTypeGroups: string[] = [];
                    work.contributors[key].forEach(x => {
                        if (!setTypeGroups.includes(x.setTypeGroup))
                            setTypeGroups.push(x.setTypeGroup)
                    });

                    setTypeGroups.some(setTypeGroup => {
                        let performingShareTotal = 0;
                        let mechanicalShareTotal = 0;
                        work.contributors[key].forEach(x => {
                            if (x.perfPercentage != null && x.setTypeGroup === setTypeGroup) {
                                performingShareTotal += x.perfPercentage.value;
                            }
                            if (x.mechPercentage != null && x.setTypeGroup === setTypeGroup) {
                                mechanicalShareTotal += x.mechPercentage.value;
                            }
                        });

                        if (shareToleranceValues?.WarnOnPerformingSharesOutsideTolerance && (performingShareTotal < toleranceLowerPerformer || performingShareTotal > toleranceUpperPerformer)) {
                            perfError = true;
                        }
                        if (shareToleranceValues?.WarnOnMechanicalSharesOutsideTolerance && (mechanicalShareTotal < toleranceLowerMechanical || mechanicalShareTotal > toleranceUpperMechanical)) {
                            mechError = true;
                        }
                    })
                }
            })
            setWarningMessage(perfError, mechError);
        }
    }

    render() {
        const {
            workMaintenanceGeneralDataViewData,
            workMaintenanceGridsViewData,
            workMaintenanceAccordionViewData,
            workMaintenanceToolbarData,
            workMaintenanceSaveResultData,
            dataGridTableData,
            contributorsDataViewData,
            workMetadataAccordionViewData,
            showModal,
            hideModal,
            clearModalSearchResults,
            showYesNoPrompt,
            showMessage,
            isFetchingWork,
            lookupValues,
            activeAccordian,
            addNewWork,
            addNewAccount,
            copyExistingWork,
            updateWorkField,
            sortWorkFieldData,
            tabs,
            activeTab,
            addTab,
            work,
            dataSource,
            postWorkBatch,
            matchWorkBatch,
            saveInProgress,
            getAgreementDetails,
            switchSetTypeGroup,
            updateContributorSetType,
            searchWorkVersionHistory,
            showWorkSubmissionDetail,
            workSubmissionDetails,
            currentUser,
            contributorDisplaySettings,
            readonlyFlagsField,
            otherIndicatorsWorkFlagTypes,
            readonlyIndicatorsWorkFlagTypes,
            showUpdateFieldsModal,
            setEditableFields,
            editableFieldsDataView,
            updateEditableField,
            agreementMaintenanceGeneralDataView,
            formatFields,
            destinations,
            distributions,
            selectedFileSystem,
            droppedFiles,
            setInDropZone,
            sortColumn,
            addFileToUpload,
            isDropZone,
            setProgressUploadFileProccess,
            cleanUploadFilesState,
            storagePaths,
            storagePathItemData,
            containerDetailsWindowComponentData,
            refreshDirectory,
            customFields,
            enableCustomField,
            addRepertoireAttachmentToState,
            attachedFiles,
            removeWorkAttachmentIfExists,
            addDroppedFileRepertoire,
            workAttachmentsDropped,
            setProgressUploadFileRepertoire,
            cleanDroppedFilesRepertoire,
            updateSetTypeOnBreak,
            editableContributorFieldGridDataView,
            workflowFieldsData,
            agreementShareDisplayOptions,
            shareDecimalsToDisplay,
            isWorkflowSessionStarted,
            nextWorkflowItem,
            cancelWorkflowSession,
            skipWorkflowItem,
            workflowSessionItems,
            currentWorkflowIndex,
            isOpenForWorkflowSession,
            refreshWorkDetails,
            containerWorks,
            editableFields,
            updateComponentFieldsItems,
            sources,
            skipUsageWorkflowItem,
            paginationDetails,
            updatePagination,
            expandMatchingWork,
            expandedWorkResults,
            saveWorkflow,
            addToPackages,
            genreCategories,
            genreCategoriesByDomOrInt,
            defaultActiveAccordions,
            roles,
            incrementTempID,
            commentCategoryFlag,
            useNewUIWorkMaintenanceGeneralView,
            updateWorkFields,
            excludeSourceTypePickShowIP,
            filterGenreCategories,
            updatedScroll,
            pdArrangementMusicLyric,
            recalculateDomesticorForeign,
            customer,
            loadMatchConnectorStatus,
            paneIndex
        } = this.props;

        const changesMade = tabs[activeTab].changesMade || (useNewUIWorkMaintenanceGeneralView && this.state.isChangeGeneralForm);
        const workBatchOperationInProgress = tabs[activeTab].workMaintenanceState && tabs[activeTab].workMaintenanceState.workBatchOperationInProgress;
        const isReadonly: boolean = tabs[activeTab].isReadonly;

        if (!isFetchingWork && this.props.work) {
            const {
                stampID,
                compoundType,
                isMaintained,
                registrationDate,
                source,
                sourceSociety,
                territorySpecificInfo,
                titles,
                numbers,
                workEdition,
                workPrice,
                additionalNumbers,
                comments,
                contributors,
                artists,
                articleMetadata,
                sheetMusicMetadata,
                bookMetadata,
                chapterMetadata,
                magazineMetadata,
                otherIndicators,
                readonlyIndicators,
                licensesAndProducts,
                workPaymentRuns,
                matchingRepertoireWorks,
                matchingIntrayWorks,
                instrumentation,
                workBatchID,
                genre,
                distributionCategory,
                workType,
                workID,
                workContributorSetTypes,
                isOriginalWork,
                duration,
                components,
                workSourceIP,
                sourceType,
                mergeThisWork,
                isArrangement,
                replacedWorks,
                isDistributed,
                isMaintainedMCPSI,
                isLibraryWork,
                isCommissionedForCommercial,
                musicSource,
                representations,
                workPart,
                workCreationDate,
                isMaintainedLibraryWork,
                subjects,
                musicArrangement,
                lyricAdaptation,
                firstPerformance,
                additionalAttributeAssigned,
            } = this.props.work;

            const {
                toolbarWidth,
            } = this.state;

            const title = WorkDetailsMapper.getOriginalTitleFromTitles(workID, titles);
            return (
                <div className="">
                    <div><span className="title">{title}</span></div>
                    <If condition={tabs[activeTab].versionNumber != null}>
                        <div><span>Version history number: {tabs[activeTab].versionNumber}</span></div>
                    </If>

                    <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                        <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                    </button>

                    <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={this.toolbarParentRef}>
                        <WorkMaintenanceToolbar
                            scroll={updatedScroll}
                            toolbarWidth={toolbarWidth}
                            toolbarData={workMaintenanceToolbarData}
                            saveResultData={workMaintenanceSaveResultData}
                            saveChanges={this.saveWork.bind(this)}
                            autoMatchWork={this.autoMatchWork.bind(this)}
                            resetMessageBanner={this.resetMessageBanner.bind(this)}
                            undoChanges={this.undoChanges.bind(this)}
                            changesMade={changesMade}
                            work={work}
                            addNewWork={this.addNew.bind(this)}
                            copyExistingWork={this.copyWork.bind(this)}
                            deleteWork={this.deleteWork.bind(this)}
                            activeTab={activeTab}
                            lookupValues={lookupValues}
                            tabs={tabs}
                            showYesNoPrompt={showYesNoPrompt}
                            showMessage={showMessage}
                            hideModal={hideModal}
                            dataSource={dataSource}
                            searchVersionHistory={searchWorkVersionHistory}
                            clearModalSearchResults={clearModalSearchResults}
                            isReadonly={isReadonly}
                            mergeWork={this.toolbarMergeWork.bind(this)}
                            workflowFieldsData={workflowFieldsData}
                            currentUser={currentUser}
                            isWorkflowSessionStarted={isWorkflowSessionStarted}
                            nextWorkflowItem={nextWorkflowItem}
                            skipWorkflowItem={skipWorkflowItem}
                            cancelWorkflowSession={cancelWorkflowSession}
                            workflowSessionItems={workflowSessionItems}
                            workMaintenanceGeneralDataViewData={workMaintenanceGeneralDataViewData}
                            currentWorkflowIndex={currentWorkflowIndex}
                            workFormats={formatFields}
                            isOpenForWorkflowSession={isOpenForWorkflowSession}
                            skipUsageWorkflowItem={skipUsageWorkflowItem}
                            refreshWorkDetails={this.refreshWorkDetails.bind(this)}
                            saveWorkflow={saveWorkflow}
                            addToPackages={this.addWorkToPackages.bind(this)}
                            roles={roles}
                            completeWorkflowOnSave={this.completeWorkflowOnSave.bind(this)}
                            customer={customer}
                            paneIndex={paneIndex}
                            
                            />
                    </div>

                    {useNewUIWorkMaintenanceGeneralView && workMaintenanceGeneralDataViewData ? 
                        <WorkMaintenanceWrapper
                            myGeneralDataFormikRef={this.myGeneralDataFormikRef}
                            setIsChangeGeneralForm={this.setIsChangeGeneralForm}
                            workMaintenanceGeneralDataViewData={workMaintenanceGeneralDataViewData}
                            stampID={stampID}
                            compoundType={compoundType}
                            isMaintained={isMaintained}
                            registrationDate={registrationDate}
                            source={source}
                            sourceType={sourceType}
                            sourceSociety={sourceSociety}
                            mergeThisWork={mergeThisWork}
                            territorySpecificInfo={territorySpecificInfo}
                            updateWorkField={updateWorkField}
                            updateWorkFields={updateWorkFields}
                            lookupValues={lookupValues}
                            dataSource={dataSource}
                            workBatchID={workBatchID}
                            changesMade={changesMade}
                            otherIndicatorsWorkFlagTypes={otherIndicatorsWorkFlagTypes}
                            readonlyIndicatorsWorkFlagTypes={readonlyIndicatorsWorkFlagTypes}
                            postWorkBatch={postWorkBatch}
                            matchWorkBatch={matchWorkBatch}
                            saveInProgress={saveInProgress}
                            saveChanges={this.saveWork.bind(this)}
                            workBatchOperationInProgress={workBatchOperationInProgress}
                            genre={genre}
                            distributionCategory={distributionCategory}
                            genreCategories={genreCategories}
                            switchSetTypeGroup={switchSetTypeGroup}
                            isReadonly={isReadonly}
                            isOriginalWork={isOriginalWork}
                            duration={duration}
                            showModal={showModal}
                            hideModal={hideModal}
                            clearModalSearchResults={clearModalSearchResults}
                            workSourceIP={workSourceIP}
                            updateComponentFieldsItems={updateComponentFieldsItems}
                            refreshWorkDetails={this.refreshWorkDetails.bind(this)}
                            showUpdateFieldsModal={showUpdateFieldsModal}
                            editableFieldsDataView={editableFieldsDataView}
                            dataGridTableData={dataGridTableData}
                            setEditableFields={setEditableFields}
                            updateEditableField={updateEditableField}
                            activeTab={activeTab}
                            tabs={tabs}
                            formatFields={formatFields}
                            isArrangement={isArrangement || false}
                            isDistributed={isDistributed || false}
                            isMaintainedMCPSI={isMaintainedMCPSI || false}
                            isLibraryWork={isLibraryWork || false}
                            isCommissionedForCommercial={isCommissionedForCommercial || false}
                            editableFields={editableFields}
                            workType={workType}
                            otherIndicators={otherIndicators}
                            readonlyIndicators={readonlyIndicators}
                            readonlyFlagsField={readonlyFlagsField}
                            musicSource={musicSource}
                            workPart={workPart}
                            workCreationDate={workCreationDate}
                            genreCategoriesByDomOrInt={genreCategoriesByDomOrInt}
                            openAccordion={this.openAccordion.bind(this)}
                            closeAccordion={this.closeAccordion.bind(this)}
                            defaultActiveAccordions={defaultActiveAccordions}
                            roles={roles}
                            isMaintainedLibraryWork={isMaintainedLibraryWork}
                            excludeSourceTypePickShowIP={excludeSourceTypePickShowIP}
                            musicArrangement={musicArrangement}
                            lyricAdaptation={lyricAdaptation}
                            filterGenreCategories={filterGenreCategories}
                            resetMessageBanner={this.resetMessageBanner.bind(this)}
                                    /> 
                                    : 
                            <WorkMaintenanceGeneralDataView
                            workMaintenanceGeneralDataViewData={workMaintenanceGeneralDataViewData}
                            stampID={stampID}
                            compoundType={compoundType}
                            isMaintained={isMaintained}
                            registrationDate={registrationDate}
                            source={source}
                            sourceType={sourceType}
                            sourceSociety={sourceSociety}
                            mergeThisWork={mergeThisWork}
                            territorySpecificInfo={territorySpecificInfo}
                            updateWorkField={updateWorkField}
                            lookupValues={lookupValues}
                            dataSource={dataSource}
                            workBatchID={workBatchID}
                            changesMade={changesMade}
                            otherIndicatorsWorkFlagTypes={otherIndicatorsWorkFlagTypes}
                            readonlyIndicatorsWorkFlagTypes={readonlyIndicatorsWorkFlagTypes}
                            postWorkBatch={postWorkBatch}
                            matchWorkBatch={matchWorkBatch}
                            saveInProgress={saveInProgress}
                            workBatchOperationInProgress={workBatchOperationInProgress}
                            genre={genre}
                            distributionCategory={distributionCategory}
                            genreCategories={genreCategories}
                            switchSetTypeGroup={switchSetTypeGroup}
                            isReadonly={isReadonly}
                            isOriginalWork={isOriginalWork}
                            duration={duration}
                            showModal={showModal}
                            hideModal={hideModal}
                            clearModalSearchResults={clearModalSearchResults}
                            workSourceIP={workSourceIP}
                            updateComponentFieldsItems={updateComponentFieldsItems}
                            refreshWorkDetails={this.refreshWorkDetails.bind(this)}
                            loadMatchConnectorStatus={loadMatchConnectorStatus}
                            showUpdateFieldsModal={showUpdateFieldsModal}
                            editableFieldsDataView={editableFieldsDataView}
                            dataGridTableData={dataGridTableData}
                            setEditableFields={setEditableFields}
                            updateEditableField={updateEditableField}
                            activeTab={activeTab}
                            tabs={tabs}
                            formatFields={formatFields}
                            isArrangement={isArrangement || false}
                            isDistributed={isDistributed || false}
                            isMaintainedMCPSI={isMaintainedMCPSI || false}
                            isLibraryWork={isLibraryWork || false}
                            isCommissionedForCommercial={isCommissionedForCommercial || false}
                            editableFields={editableFields}
                            workType={workType}
                            otherIndicators={otherIndicators}
                            readonlyIndicators={readonlyIndicators}
                            readonlyFlagsField={readonlyFlagsField}
                            musicSource={musicSource}
                            workPart={workPart}
                            workCreationDate={workCreationDate}
                            genreCategoriesByDomOrInt={genreCategoriesByDomOrInt}
                            openAccordion={this.openAccordion.bind(this)}
                            closeAccordion={this.closeAccordion.bind(this)}
                            defaultActiveAccordions={defaultActiveAccordions}
                            roles={roles}
                            isMaintainedLibraryWork={isMaintainedLibraryWork}
                            excludeSourceTypePickShowIP={excludeSourceTypePickShowIP}
                            musicArrangement={musicArrangement}
                            lyricAdaptation={lyricAdaptation}
                            filterGenreCategories={filterGenreCategories}
                            pdArrangementMusicLyric={pdArrangementMusicLyric}
                            recalculateDomesticorForeign={recalculateDomesticorForeign}
                            firstPerformance={firstPerformance}
                            additionalAttributeAssigned={additionalAttributeAssigned}
                                    />
                                    }
                    <WorkMaintenanceGridsView
                        workSubmissionDetails={workSubmissionDetails}
                        updateWorkField={updateWorkField}
                        sortWorkFieldData={sortWorkFieldData}
                        workMaintenanceGridsViewData={workMaintenanceGridsViewData}
                        workMaintenanceAccordionViewData={workMaintenanceAccordionViewData}
                        dataGridTableData={dataGridTableData}
                        contributorsDataViewData={contributorsDataViewData}
                        workMetadataAccordionViewData={workMetadataAccordionViewData}
                        showModal={showModal}
                        clearModalSearchResults={clearModalSearchResults}
                        lookupValues={lookupValues}
                        activeAccordian={activeAccordian}
                        openWorkAccordian={this.openAccordion.bind(this)}
                        closeWorkAccordian={this.closeAccordion.bind(this)}
                        dataSource={dataSource}
                        workNames={titles}
                        workEdition={workEdition}
                        workNumbers={numbers}
                        subjects={subjects}
                        workPrice={workPrice}
                        workNumbersAdditional={additionalNumbers}
                        workContributorSetTypes={workContributorSetTypes}
                        workContributors={contributors}
                        workArticles={articleMetadata}
                        workSheetMusic={sheetMusicMetadata}
                        workBook={bookMetadata}
                        workChapter={chapterMetadata}
                        workMagazine={magazineMetadata}
                        workFlags={otherIndicators}
                        readonlyIndicators={readonlyIndicators}
                        workArtists={artists}
                        workNotes={comments}
                        workProduct={licensesAndProducts}
                        workPaymentRun={workPaymentRuns}
                        matchingWorks={matchingRepertoireWorks}
                        matchingIntrayWorks={matchingIntrayWorks}
                        workInstrumentation={instrumentation}
                        addTab={addTab}
                        tabs={tabs}
                        activeTab={activeTab}
                        getAgreementDetails={getAgreementDetails}
                        updateContributorSetType={updateContributorSetType}
                        isReadonly={isReadonly}
                        getWorkDetails={this.getWorkDetails.bind(this)}
                        getProductDetails={this.getProductDetails.bind(this)}
                        getIPDetails={this.getIPDetails.bind(this)}
                        showWorkSubmissionDetails={showWorkSubmissionDetail}
                        hideModal={hideModal}
                        work={this.props.work}
                        currentUser={currentUser}
                        manualMergeWork={this.manualMergeWork.bind(this)}
                        relatedWorks={components}
                        contributorDisplaySettings={contributorDisplaySettings}
                        readonlyFlagsField={readonlyFlagsField}
                        agreementMaintenanceGeneralDataView={agreementMaintenanceGeneralDataView}
                        addFileToUpload={addFileToUpload}
                        cleanUploadFilesState={cleanUploadFilesState}
                        containerDetailsWindowComponentData={containerDetailsWindowComponentData}
                        destinations={destinations}
                        distributions={distributions}
                        droppedFiles={droppedFiles}
                        isDropZone={isDropZone}
                        selectedFileSystem={selectedFileSystem}
                        setInDropZone={setInDropZone}
                        setProgressUploadFileProccess={setProgressUploadFileProccess}
                        sortColumn={sortColumn}
                        storagePathItemData={storagePathItemData}
                        storagePaths={attachedFiles}
                        refreshDirectory={(x) => refreshDirectory(x, sources)}
                        customFields={customFields}
                        enableCustomField={enableCustomField}
                        addRepertoireAttachmentToState={addRepertoireAttachmentToState}
                        attachedFiles={attachedFiles}
                        removeWorkAttachmentIfExists={removeWorkAttachmentIfExists}
                        addDroppedFileRepertoire={addDroppedFileRepertoire}
                        workAttachmentsDropped={workAttachmentsDropped}
                        setProgressUploadFileRepertoire={setProgressUploadFileRepertoire}
                        cleanDroppedFilesRepertoire={cleanDroppedFilesRepertoire}
                        updateSetTypeOnBreak={updateSetTypeOnBreak}
                        showUpdateFieldsModal={showUpdateFieldsModal}
                        editableFieldsDataView={editableFieldsDataView}
                        editableContributorFieldGridDataView={editableContributorFieldGridDataView}
                        contributorGridEditableFields={editableFields}
                        setEditableFields={setEditableFields}
                        updateComponentFieldsItems={updateComponentFieldsItems}
                        replacedWorks={replacedWorks}
                        agreementShareDisplayOptions={agreementShareDisplayOptions}
                        shareDecimalsToDisplay={shareDecimalsToDisplay}
                        resetMessageBanner={this.resetMessageBanner.bind(this)}
                        refreshWorkDetails={this.refreshWorkDetails.bind(this)}
                        containerWorks={containerWorks}
                        editableFields={editableFields}
                        addNewAccount={this.addNewAccount.bind(this)}
                        paginationDetail={paginationDetails}
                        updatePagination={updatePagination}
                        representations={representations}
                        expandedWorkResults={expandedWorkResults}
                        expandMatchingWork={expandMatchingWork}
                        workMaintenanceSaveResultData={workMaintenanceSaveResultData}
                        incrementTempID={incrementTempID}
                        commentCategoryFlag={commentCategoryFlag}
                        saveAdditionalWorkNumbers={this.saveAdditionalWorkNumbers.bind(this)}
                        getCurrentPageDetails={this.getCurrentPageDetails.bind(this)}
                    />
                </div>
            );
        }
        return <div>Loading...</div>;
    }
}
