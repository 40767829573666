import * as React from "react";
import MyPackagesMaintenancePage from "./components/MyPackagesMaintenancePage/MyPackagesMaintenancePage";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { IRepertoireField } from "../types/IRepertoireField";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { placeholder } from "lodash/fp";
import { SearchRequests } from "../services/SearchRequests";
import { MY_PACKAGE_MAINTENANCE_GROUP, MY_PACKAGE_MAINTENANCE_KEY } from "../ConfigurationConsts";
import { IMyPackagesDownloadFileFormat } from "../types/IMyPackagesDownloadFileFormat";
import { IMyPackagesDetails } from "../types/IMyPackagesDetails";
import { IRepertoirePathInfo } from "../types/IRepertoirePathInfo";


export interface IMyPackagesPageProps {
    myPackagesGridView:IRepertoireComponentDataItem;
    packageMaintenanceGridsViewData: IRepertoireComponentDataItem;
    myPackagesGeneralDataViewData:IRepertoireComponentDataItem;
    activeTab: number;
    tabs: ITabReduxItem[];
    editableFieldsDataView?: IRepertoireComponentDataItem;
    lookupValues: ILookupDictionary;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    editableFields: IRepertoireField[];
    incrementTempID?: () => void;
    updatedScroll?: number;
    customer?:string;
    title:string;
    placeholder:string;
    downloadFileFormat?:IMyPackagesDownloadFileFormat[];
    getDownloadFileFormat?:()=>void;   
    getMyPackagesDetails?: (userName: string, modalOpen: boolean) => void;
    currentUser?:string;
    myPackagesResult?:IMyPackagesDetails[];
    getUserCRMValues?:()=>void;
    clearPackages?:(myPackages:IMyPackagesDetails)=>void;
    loadRepertoireFromPath: (pathInfo: IRepertoirePathInfo, location: Location) => void;
    generatePackage?: (myPackage: IMyPackagesDetails) => void;
    closePackage?: (myPackage: IMyPackagesDetails) => void;
    addPackageNote?: (myPackage: IMyPackagesDetails) => void;
}

interface IMyPackagesPageState {
    toolbarWidth: number;
    ipBaseNumber: string;
    activeHeaderSection?: string;
    title?:string;
    placeholder?:string;
    myPackagesGeneralDataViewData?:IRepertoireComponentDataItem;
    currentUser?:string;
}
export type IInterestedPartyMaintenanceToolbarKeys = keyof IMyPackagesPageProps;

export class MyPackagesPage extends React.PureComponent<IMyPackagesPageProps,IMyPackagesPageState> {
    toolbarParentRef;
    constructor(props: IMyPackagesPageProps) {
        super(props)
        this.state = {
            toolbarWidth: null,
            ipBaseNumber: "",
            currentUser:""
        };
        this.toolbarParentRef = React.createRef();
    }

    componentDidMount() {
        const {
            myPackagesGeneralDataViewData: { fields },getUserCRMValues,getDownloadFileFormat,getMyPackagesDetails,myPackagesGridView,loadRepertoireFromPath
        } = this.props  
        const{ currentUser }=this.state
        
        const username = sessionStorage.getItem('username');
        getUserCRMValues(); 
        getDownloadFileFormat();
        getMyPackagesDetails(username,true);
    };


    render() {
        const {packageMaintenanceGridsViewData,tabs,activeTab,incrementTempID,
            customer,title,myPackagesGeneralDataViewData,placeholder,downloadFileFormat,
            getMyPackagesDetails, myPackagesGridView, myPackagesResult, getDownloadFileFormat,
            clearPackages, generatePackage, closePackage, addPackageNote } = this.props;

            const username = sessionStorage.getItem('username');
        return (
            <>
            <a target="_blank" id="downloadLinkPackages"></a>
            <div>
              {myPackagesGeneralDataViewData && myPackagesGeneralDataViewData.fields.length > 0 && 
                <MyPackagesMaintenancePage
                        myPackagesGridView={myPackagesGridView}
                        currentUser={username}
                        placeholder={placeholder}
                        myPackagesResult={myPackagesResult}
                        getMyPackagesDetails={getMyPackagesDetails}
                        getDownloadFileFormat={getDownloadFileFormat}
                        clearPackages={clearPackages}
                        packageMaintenanceGridsViewData={packageMaintenanceGridsViewData}
                        myPackagesGeneralDataViewData={myPackagesGeneralDataViewData}
                        activeTab={activeTab}
                        tabs={tabs}
                        componentInstance={null}
                        title={title}
                        isReadonly={true}
                        downloadFileFormat={downloadFileFormat}
                        activeHeaderSection={null} lookupValues={undefined}
                        generatePackage={generatePackage}                    
                        closePackage={closePackage}                    
                        addPackageNote={addPackageNote}                    />
              }
                </div>
                </>
        )
    }
}
export default MyPackagesPage;
