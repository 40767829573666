import { IStoragePathItemData } from "../../dataingestion/types/IStoragePathItemData";
import { PRODUCT_SOURCE_LOOKUP, PRODUCT_TYPE_LOOKUP } from "../../lookup/Consts";
import { getLookupDefault } from "../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { FormatFields } from "../../redux/types/FormatFields";
import { IAttachedFile } from "../../redux/types/IAttachedFile";
import { IProductRecordingDetails } from "../../redux/types/IProductRecordingDetails";
import { IProductState } from "../../redux/types/IProductState";
import { BOOLEAN_READONLY_INPUT, CHECKBOX_INPUT, DROPDOWN_INPUT, INTEGER_INPUT, READ_ONLY_INPUT, READ_ONLY_TIMESPAN_INPUT, TEXT_INPUT } from "../Consts";
import { DataSource } from "../types/DataSource";
import { IComment } from "../types/IComment";
import { IDataItem } from "../types/IDataItem";
import { IIntrayMatchingProduct } from "../types/IIntrayMatchingProduct";
import { IIntrayProductDuplicate } from "../types/IIntrayProductDuplicate";
import { IMatchingProduct } from "../types/IMatchingProduct";
import { IProductContributor } from "../types/IProductContributor";
import { IProductContributorDataItems } from "../types/IProductContributorDataItems";
import { IProductCore } from "../types/IProductCore";
import { IProductDuplicate } from "../types/IProductDuplicate";
import { IProductName } from "../types/IProductName";
import { IProductNameDataItems } from "../types/IProductNameDataItems";
import { IProductNote } from "../types/IProductNote";
import { IProductNumber } from "../types/IProductNumber";
import { IProductNumberDataItems } from "../types/IProductNumberDataItems";
import { IProductPerformer } from "../types/IProductPerformer";
import { IProductPerformerDataItems } from "../types/IProductPerformerDataItems";
import { IProductSourceIP } from "../types/IProductSourceIP";
import { IProductWork } from "../types/IProductWork";
import { IProductWorkDataItems } from "../types/IProductWorkDataItems";
import { ISourceIP } from "../types/ISourceIP";
import { IWorkflow } from "../types/IWorkflow";
import { IReplacedProducts } from "../types/IReplacedProduct";
import { IProductReplacedProduct } from "../types/IProductReplacedProduct";

export class ProductDetailsMapper {
    public static mapNameIPToState(name: IProductName[]): IProductNameDataItems[] {
        if (name) {
            const stateName: IProductNameDataItems[] = [];
            name.map(a =>
                stateName.push({
                    productNameID: a.productNameID,
                    productCoreID: a.productCoreID,
                    productNameType: { value: a.productNameType, inputType: DROPDOWN_INPUT },
                    name: { value: a.name, inputType: TEXT_INPUT },
                    language: { value: a.language, inputType: DROPDOWN_INPUT }
                }));
            return stateName;
        }
        else {
            return [];
        }
    }

    public static mapStateToName(stateName: IProductNameDataItems[]): IProductName[] {
        if (stateName) {
            const name: IProductName[] = [];
            stateName.map(a =>
                name.push({
                    productNameID: a.productNameID,
                    productCoreID: a.productCoreID,
                    productNameType: a.productNameType.value,
                    name: a.name.value,
                    language: a.language.value
                }));

            return name;
        }
        else {
            return [];
        }
    }


    public static mapNumberToState(number: IProductNumber[]): IProductNumberDataItems[] {
        if (number) {
            const stateNumber: IProductNumberDataItems[] = [];
            number.map(a =>
                stateNumber.push({
                    productNumberID: a.productNumberID,
                    productCoreID: a.productCoreID,
                    productNumberType: { value: a.productNumberType, inputType: DROPDOWN_INPUT },
                    number: { value: a.number.trim(), inputType: TEXT_INPUT },
                    status: { value: a.status, inputType: DROPDOWN_INPUT }
                }));
            return stateNumber;
        }
        else {
            return [];
        }
    }

    public static mapStateToNumber(stateNumber: IProductNumberDataItems[]): IProductNumber[] {
        if (stateNumber) {
            const number: IProductNumber[] = [];
            stateNumber.map(a =>
                number.push({
                    productNumberID: a.productNumberID,
                    productCoreID: a.productCoreID,
                    productNumberType: a.productNumberType.value,
                    number: a.number.value.trim(),
                    status: a.status.value
                }));

            return number;
        }
        else {
            return [];
        }
    }


    public static mapPerformerToState(performer: IProductPerformer[]): IProductPerformerDataItems[] {
        if (performer) {
            const statePerformer: IProductPerformerDataItems[] = [];
            performer.map(a =>
                statePerformer.push({
                    productPerformerID: a.productPerformerID,
                    performerID: a.performerID,
                    performerName: { value: a.performerName, inputType: TEXT_INPUT },
                    type: { value: a.type, inputType: DROPDOWN_INPUT },
                }));
            return statePerformer;
        }
        else {
            return [];
        }
    }

    public static mapStateToPerformer(statePerformer: IProductPerformerDataItems[]): IProductPerformer[] {
        if (statePerformer) {
            const performer: IProductPerformer[] = [];
            statePerformer.map(a =>
                performer.push({
                    productPerformerID: a.productPerformerID,
                    performerID: a.performerID,
                    type: a.type.value,
                    performerName: a.performerName.value
                }));

            return performer;
        }
        else {
            return [];
        }
    }

    public static mapWorkToState(work: IProductWork[]): IProductWorkDataItems[] {
        if (work) {
            const stateWork: IProductWorkDataItems[] = [];
            work.map(a => {
                stateWork.push({
                    productWorkID: a.productWorkID,
                    workCode: a.workCode,
                    title: { value: a.title, inputType: READ_ONLY_INPUT },
                    number: { value: a.number, inputType: READ_ONLY_INPUT },
                    writer: { value: a.writer, inputType: READ_ONLY_INPUT },
                    duration: { value: a.duration, inputType: READ_ONLY_TIMESPAN_INPUT },
                    musicUsage: { value: a.musicUsage, inputType: DROPDOWN_INPUT },
                    intrayIndicator: { value: a.workDataSource === DataSource.Intray ? true : false, inputType: BOOLEAN_READONLY_INPUT },
                    artist: { value: a.artist, inputType: READ_ONLY_INPUT },
                    status: { value: a.status, inputType: READ_ONLY_INPUT },
                    side: { value: a.side, inputType: INTEGER_INPUT },
                    track: { value: a.track, inputType: INTEGER_INPUT },
                    sequence: { value: a.sequence, inputType: INTEGER_INPUT },
                })
            });
            return stateWork;
        }
        else {
            return [];
        }
    }

    public static mapStateToWork(stateWork: IProductWorkDataItems[]): IProductWork[] {
        if (stateWork) {
            const work: IProductWork[] = [];
            stateWork.map(a => {
                let workDataSource = DataSource.Repertoire;
                if (!a.intrayIndicator || a.intrayIndicator?.value === true) {
                    workDataSource = DataSource.Intray;
                }
                work.push({
                    productWorkID: a.productWorkID,
                    workCode: a.workCode,
                    title: a.title.value,
                    number: a.number.value,
                    writer: a.writer.value,
                    duration: a.duration.value,
                    workDataSource: workDataSource,
                    musicUsage: a.musicUsage ? a.musicUsage.value : null,
                    artist: a.artist ? a.artist.value : null,
                    side: a.side ? a.side.value : null,
                    track: a.track ? a.track.value : null,
                    sequence: a.sequence ? a.sequence.value : null,
                })
            });
            return work;
        }
        else {
            return [];
        }
    }

    public static mapMatchingProductsNameToState(names: string[]): IDataItem[] {
        const returnNames: IDataItem[] = [];
        names.map(n => {
            returnNames.push({ inputType: READ_ONLY_INPUT, value: n })
        })
        return returnNames;
    }

    public static mapMatchingProductsNumberToState(number: string[]): IDataItem[] {
        const returnNumbers: IDataItem[] = [];
        number.map(n => {
            returnNumbers.push({ inputType: READ_ONLY_INPUT, value: n })
        })
        return returnNumbers;
    }

    public static mapDuplicatesToState(duplicates: IMatchingProduct[]): IProductDuplicate[] {
        if (duplicates) {
            const matches: IProductDuplicate[] = [];

            duplicates.map((d, index) => {
                matches.push({
                    repertoireProductID: d.repertoireProductID,
                    isDefinite: d.isDefinite.value,
                    name: d.name.map(x => x.value),
                    number: d.number.map(x => x.value),
                    productID: index
                })
            });

            return matches;
        }
        else {
            return [];
        }
    }

    public static mapIntrayProductDuplicatesToIntrayMatchingProduct(duplicates: IIntrayProductDuplicate[]): IIntrayMatchingProduct[] {
        if (duplicates) {
            const matches: IIntrayMatchingProduct[] = [];

            duplicates.map((d) => {
                matches.push({
                    duplicateIntrayProductID: d.duplicateIntrayProductID,
                    isDefinite: d.isDefinite.value,
                    name: d.name.value,
                    number: d.number.value,
                    contributorName: d.contributorName.value
                })
            });

            return matches;
        }
        else {
            return [];
        }
    }

    public static mapIntrayMatchingProductToIntrayProductDuplicates(matchingProducts: IIntrayMatchingProduct[]): IIntrayProductDuplicate[] {
        if (matchingProducts) {
            const duplicates: IIntrayProductDuplicate[] = [];

            matchingProducts.map((m, index) => {
                duplicates.push({
                    duplicateIntrayProductID: m.duplicateIntrayProductID,
                    isDefinite: { value: m.isDefinite, inputType: CHECKBOX_INPUT },
                    name: { value: m.name, inputType: READ_ONLY_INPUT },
                    number: { value: m.number, inputType: READ_ONLY_INPUT },
                    contributorName: { value: m.contributorName, inputType: READ_ONLY_INPUT },
                    productID: index
                })
            });

            return duplicates;
        }
        else {
            return [];
        }
    }

    public static mapStateToDuplicates(duplicates: IProductDuplicate[]): IMatchingProduct[] {
        if (duplicates) {
            const matches: IMatchingProduct[] = [];

            duplicates.map(d => {
                matches.push({
                    isDefinite: { value: d.isDefinite, inputType: CHECKBOX_INPUT },
                    name: ProductDetailsMapper.mapMatchingProductsNameToState(d.name),
                    number: ProductDetailsMapper.mapMatchingProductsNumberToState(d.number),
                    repertoireProductID: d.repertoireProductID
                })
            });

            return matches;
        }
        else {
            return [];
        }
    }

    public static mapContributorToState(contributor: IProductContributor[]): IProductContributorDataItems[] {
        if (contributor) {
            const stateContributor: IProductContributorDataItems[] = [];
            contributor.map(a =>
                stateContributor.push({
                    productContributorID: a.productContributorID,
                    productCoreID: a.productCoreID,
                    submitterIP: a.submitterIP,
                    ipBaseNumber: a.ipBaseNumber,
                    name: { value: a.name, inputType: TEXT_INPUT },
                    avRipRoleCode: { value: a.avRipRoleCode, inputType: DROPDOWN_INPUT },
                }));
            return stateContributor;
        }
        else {
            return [];
        }
    }

    public static mapStateToContributor(stateContributor: IProductContributorDataItems[]): IProductContributor[] {
        if (stateContributor) {
            const contributor: IProductContributor[] = [];
            stateContributor.map(a =>
                contributor.push({
                    productContributorID: a.productContributorID,
                    productCoreID: a.productCoreID,
                    submitterIP: a.submitterIP,
                    ipBaseNumber: a.ipBaseNumber,
                    avRipRoleCode: a.avRipRoleCode.value,
                    name: a.name.value
                }));

            return contributor;
        }
        else {
            return [];
        }
    }

    public static mapReplacedProductToProductReplacedProduct(replacedProduct: IReplacedProducts[]): IProductReplacedProduct[] {
        const productReplacedProduct: IProductReplacedProduct[] = [];
        if(replacedProduct){
            replacedProduct.map(a =>
                productReplacedProduct.push({
                    replacedProductID: a.replacedProductID,
                    iD1: a.iD1.value,
                    iD2: a.iD2.value
                }));
        }
        return productReplacedProduct;
    }

    public static mapProductReplacedProductToReplacedProduct(productReplacedProduct: IProductReplacedProduct[]): IReplacedProducts[] {
        const replacedProduct: IReplacedProducts[] = [];
        if (productReplacedProduct){
            productReplacedProduct.map(a =>
                replacedProduct.push({
                    replacedProductID: a.replacedProductID,
                    iD1: { value: a.iD1, inputType: "" },
                    iD2: { value: a.iD2, inputType: "" }
                }));
        }
        return replacedProduct;
    }

    public static getOriginalTitleFromProductNames(names: IProductName[]): string {
        if (!names) {
            return 'New Product';
        }
        const original = names.find(n => n.productNameType == 'OT');
        if (original) {
            return original.name;
        }
        return names[0].name;
    }

    public static getOriginalTitleFromProductNamesState(productCoreID: number, titles: IProductNameDataItems[]): string {
        if (!titles || titles.length === 0) {
            return 'New Product';
        }
        const original = titles.find(t => t.productNameType.value == 'OT');
        let title: string;
        if (original) {
            title = original.name.value;
        }
        else {
            title = titles[0].name.value;
        }
        if (productCoreID === 0)
            return title;
        else {
            return '(' + productCoreID.toString() + ') ' + title;
        }
    }

    public static mapAttachmentsToFileState(attachments: IAttachedFile[]): IStoragePathItemData[] {
        const files: IStoragePathItemData[] = [];
        if (attachments) {
            attachments.map(file => {
                files.push(
                    {
                        fullName: `repertoireAttachments/product/${file.guid}`,
                        inProgress: false,
                        isDirectory: false,
                        isDirty: false,
                        isEditing: false,
                        lastModified: file.lastModifiedDate && file.lastModifiedDate.toString(),
                        sources: [],
                        metadata: undefined,
                        name: file.fileName,
                        pools: [],
                        formats: [],
                        distributions: [],
                        validationError: undefined,
                        attachmentTypeID: file.attachmentTypeID,
                        attachmentTypeCode: file.attachmentTypeCode,
                    }
                )
            })
        }
        return files;
    }

    public static mapStateToAttachedFiles(files: IStoragePathItemData[], productID: number): IAttachedFile[] {
        const attachedFiles: IAttachedFile[] = [];
        if (files) {
            files.map(
                file => {
                    let guid: string = "";
                    const fullNameSplit: string[] = file.fullName.split('/');
                    if (fullNameSplit.length > 2)
                        guid = fullNameSplit[2];

                    attachedFiles.push({
                        fileName: file.name,
                        productCoreID: productID,
                        guid: guid,
                        attachmentTypeID: file.attachmentTypeID,
                        attachmentTypeCode: file.attachmentTypeCode,
                    })
                }
            )
        }
        return attachedFiles;
    }

    public static mapCommentsToProductNotes(comments: IComment[]): IProductNote[] {
        if (comments) {
            const productNotes: IProductNote[] = [];
            comments.map(comment => productNotes.push({
                notesID: comment.notesID,
                username: comment.user.value,
                lastModifiedDate: null,
                subject: comment.subject.value,
                noteText: comment.comment.value,
                isModified: comment.isModified,
                commentCategory: comment.commentCategory.value,
            }));
            return productNotes;
        }
        else {
            return [];
        }
    }

    public static mapProductWorkflowToWorkflow(workflows: IWorkflow[]): IWorkflow[] {
        if (workflows) {
            const mappedWorkflows: IWorkflow[] = [];
            workflows.map(a =>
                mappedWorkflows.push({
                    id: a.id,
                    entityType: a.entityType,
                    status: a.status,
                    workflowType: a.workflowType,
                    entityID: a.entityID,
                    setType: a.setType,
                    assignedTo: a.assignedTo,
                    dateCreated: a.dateCreated,
                    dateLastUpdated: a.dateLastUpdated,
                    references: a.references,
                    values: a.values,
                    actions: a.actions,
                    errorMessages: a.errorMessages,
                    rejectReason: a.rejectReason
                }));
            return mappedWorkflows;

        }
        else {
            return [];
        }
    }

    public static mapStateToProduct(productState: IProductState, isMembersPortal?: boolean): IProductCore {
        const {
            productCoreID,
            productType,
            dataSource,
            id1,
            id2,
            duration,
            released,
            trackCount,
            source,
            maintained,
            production,
            lastModifiedDate,
            licenseeID,
            productWork,
            versionNumber,
            productName,
            productBatchID,
            productAVWork,
            productAVRequest,
            productAVWorkSubmissions,
            files,
            comments,
            intrayProductDuplicate,
            matchingRepertoireProducts,
            showCommentSubjectAsDropdown,
            isProductAttachmentTypeNotRequired,
            isProductAttachmentTypeOptional,
            status,
            sourceIP,
            sourceType,
            dateCreated,
            productSourceIP,
            originalTitle,
            recordingDetails,
            societyInterestPercentage,
            originOfRevision,
            replacedProducts
        } = productState;

        let releasedDate = !released || released.toString() === '' ? null : released;
        let productNameList = ProductDetailsMapper.mapStateToName(productName);

        if (isMembersPortal) {
            if (originalTitle) {
                productNameList = productNameList.map(element => {
                    if (element.productNameType === 'OT') {
                        element.name = originalTitle
                    }
                    return element;
                })
            }
            if (productNameList.filter(x => x.productNameType === 'OT').length == 0) {
                productNameList.push({
                    productNameID: 0 - Math.floor(Math.random() * 99999999),
                    productNameType: 'OT',
                    name: originalTitle,
                    productCoreID: 0,
                    language: ""
                })
            }

            if (!releasedDate)
                releasedDate = new Date();
        }

        const product: IProductCore = {
            productCoreID,
            dataSource,
            productType,
            id1: id1,
            id2: id2,
            duration,
            released: releasedDate,
            trackCount,
            source,
            maintained,
            production,
            licenseeID,
            lastModifiedDate,
            productAVWork: productAVWork,
            productBatchID: productBatchID ? Number(productBatchID) : null,
            productNames: productNameList,
            productNumbers: ProductDetailsMapper.mapStateToNumber(productState.productNumber),
            productPerformers: ProductDetailsMapper.mapStateToPerformer(productState.productPerformer),
            productWorks: ProductDetailsMapper.mapStateToWork(productState.productWork),
            matchingProducts: ProductDetailsMapper.mapDuplicatesToState(productState.matchingRepertoireProducts),
            matchingIntrayDuplicateProducts: ProductDetailsMapper.mapIntrayProductDuplicatesToIntrayMatchingProduct(intrayProductDuplicate),
            productContributors: ProductDetailsMapper.mapStateToContributor(productState.productContributor),
            versionNumber,
            productAVWorkSubmissions,
            productAVRequest,
            attachedFiles: ProductDetailsMapper.mapStateToAttachedFiles(files, productCoreID),
            notes: ProductDetailsMapper.mapCommentsToProductNotes(comments),
            workflows: ProductDetailsMapper.mapProductWorkflowToWorkflow(productState.workflows),
            replacedProducts: ProductDetailsMapper.mapReplacedProductToProductReplacedProduct(replacedProducts),
            showCommentSubjectAsDropdown,
            isProductAttachmentTypeNotRequired,
            isProductAttachmentTypeOptional,
            status: status,
            sourceIP: sourceIP,
            productSourceIP: ProductDetailsMapper.mapSourceIPToProductSourceIP(productSourceIP),
            sourceType: sourceType,
            dateCreated: dateCreated,
            recordingDetails: ProductDetailsMapper.mapProductRecording(recordingDetails),
            societyInterestPercentage: societyInterestPercentage,
            originalTitle,
            originOfRevision: originOfRevision
        }
        return product;
    }

    public static mapProductToState(product: IProductCore, formats?: FormatFields[]): IProductState {
        if (!product)
            return null;

        const {
            productCoreID,
            productType,
            dataSource,
            id1,
            id2,
            duration,
            released,
            trackCount,
            source,
            maintained,
            production,
            licenseeID,
            lastModifiedDate,
            productWorks,
            productNames,
            versionNumber,
            productBatchID,
            productAVWork,
            productAVRequest,
            productAVWorkSubmissions,
            notes,
            matchingIntrayDuplicateProducts,
            workflows,
            showCommentSubjectAsDropdown,
            isProductAttachmentTypeNotRequired,
            isProductAttachmentTypeOptional,
            status,
            sourceIP,
            sourceType,
            dateCreated,
            productSourceIP,
            societyInterestPercentage,
            originOfRevision
        } = product;
        const originalTitle = (productNames.find(element => element.productNameType === 'OT'))?.name;
        const replacedProducts: IReplacedProducts[] = ProductDetailsMapper.mapProductReplacedProductToReplacedProduct(product.replacedProducts);
        const productState: IProductState = {
            productCoreID,
            dataSource,
            productType,
            id1: id1,
            id2: id2,
            duration,
            released,
            trackCount,
            source,
            maintained,
            production,
            licenseeID,
            lastModifiedDate,
            productBatchID,
            productAVWork: productAVWork,
            productName: ProductDetailsMapper.mapNameIPToState(product.productNames),
            productNumber: ProductDetailsMapper.mapNumberToState(product.productNumbers),
            productPerformer: ProductDetailsMapper.mapPerformerToState(product.productPerformers),
            matchingRepertoireProducts: ProductDetailsMapper.mapStateToDuplicates(product.matchingProducts),
            intrayProductDuplicate: ProductDetailsMapper.mapIntrayMatchingProductToIntrayProductDuplicates(product.matchingIntrayDuplicateProducts),
            productWork: ProductDetailsMapper.mapWorkToState(product.productWorks),
            productContributor: ProductDetailsMapper.mapContributorToState(product.productContributors),
            versionNumber,
            productAVWorkSubmissions,
            productAVRequest,
            files: ProductDetailsMapper.mapAttachmentsToFileState(product.attachedFiles),
            comments: ProductDetailsMapper.mapProductNotesToComments(product.notes),
            workflows,
            replacedProducts,
            showCommentSubjectAsDropdown,
            isProductAttachmentTypeNotRequired,
            isProductAttachmentTypeOptional,
            status: status,
            sourceIP: sourceIP,
            productSourceIP: ProductDetailsMapper.mapProductSourceIPToSourceIP(product.productSourceIP),
            recordingDetails: ProductDetailsMapper.mapProductRecording(product.recordingDetails),
            sourceType: sourceType,
            dateCreated: dateCreated,
            societyInterestPercentage: societyInterestPercentage,
            originalTitle,
            originOfRevision
        }

        return productState;
    }

    public static mapProductNotesToComments(notes: IProductNote[]): IComment[] {
        if (notes) {
            const comments: IComment[] = [];
            notes.map(n => {
                comments.push({
                    notesID: n.notesID,
                    user: { value: n.username, inputType: "" },
                    date: { value: n.lastModifiedDate, inputType: "" },
                    subject: { value: n.subject, inputType: TEXT_INPUT },
                    comment: { value: n.noteText, inputType: TEXT_INPUT },
                    isModified: n.isModified,
                    commentCategory: { value: n.commentCategory, inputType: DROPDOWN_INPUT },
                })
            });
            return comments;
        }
        else {
            return [];
        }
    }

    public static createNewProduct(lookups: ILookupDictionary): IProductCore {
        const product: IProductCore = {
            productCoreID: 0,
            dataSource: DataSource.Intray,
            source: getLookupDefault(PRODUCT_SOURCE_LOOKUP, lookups),
            productType: getLookupDefault(PRODUCT_TYPE_LOOKUP, lookups),
            id1: '0',
            id2: null,
            duration: 0,
            released: null,
            trackCount: 0,
            maintained: false,
            production: 0,
            licenseeID: 0,
            lastModifiedDate: null,
            productNames: [
                {
                    productNameID: 0,
                    productCoreID: 0,
                    productNameType: "OT",
                    name: "",
                    language: "EN"
                }
            ],
            productNumbers: [],
            productPerformers: [],
            productWorks: [],
            matchingProducts: [],
            productAVWork: null,
            productContributors: [],
            versionNumber: 0,
            attachedFiles: [],
            replacedProducts: [],
            showCommentSubjectAsDropdown: false,
            status: "",
            sourceIP: null,
            sourceType: "",
            dateCreated: null,
            productSourceIP: null,
            originalTitle: '',
            originOfRevision:''
        }

        return product;
    }

    public static mapSourceIPToProductSourceIP(sourceIP: ISourceIP): IProductSourceIP {
        if (sourceIP) {
            const productSource: IProductSourceIP = {
                productSourceIPID: sourceIP.sourceIPID,
                ipiNameNumber: sourceIP.ipiNameNumber,
                fullName: sourceIP.fullName.value,
            };

            return productSource;
        }
        else {
            return null;
        }
    }

    public static mapProductRecording(productRecording: IProductRecordingDetails) {
        if (productRecording) {
            return productRecording;
        }
        else {
            return null;
        }
    }

    public static mapProductSourceIPToSourceIP(productSourceIP: IProductSourceIP): ISourceIP {
        if (productSourceIP) {

            const productSource: ISourceIP = {
                sourceIPID: productSourceIP.productSourceIPID,
                ipiNameNumber: productSourceIP.ipiNameNumber,
                fullName: { value: productSourceIP.fullName, inputType: TEXT_INPUT },
            };

            return productSource;
        }
        else {
            return null;
        }
    }

    public static createNewProductState(lookups: ILookupDictionary, formats: FormatFields[], memberIP?: IProductSourceIP): IProductState {
        let product = ProductDetailsMapper.createNewProduct(lookups);
        if (memberIP) {
            product.productType = "SET LIST";
            product.source = "Portal";
            product.productSourceIP = memberIP;
            product.sourceIP = memberIP.ipiNameNumber;
            product.sourceType = "WR";
            product.dataSource = DataSource.Intray;

        }
        return ProductDetailsMapper.mapProductToState(product, formats)
    }
}