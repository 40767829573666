import * as React from "react";
import { Tab } from "react-bootstrap";
import { ITab } from "../../types/ITab";
import TabComponent from "./TabComponent";
import { displayRepresentationFlag } from "../../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { hideModal, showYesNoPrompt } from "../../../redux/reducers/ModalReducer";
import { INTRAY, DRAFT_WORKS_PAGE_VIEW, MEMBER_STATEMENTS_USAGE_DETAILS_PAGE }
    from "../../Consts";
import { IYesNoPromptViewModalProps } from "../../../repertoire/components/modalViews/yesNoPromptView/YesNoPromptView";
import { ALL_REGISTERED_WORKS_INTRAY_TAB_ICON, ALL_REGISTERED_WORKS_INTRAY_TAB_ICON_TRANSFORM, ALL_REGISTERED_WORKS_REPERTOIRE_TAB_ICON, ALL_REGISTERED_WORKS_REPERTOIRE_TAB_ICON_TRANSFORM, REGISTERED_WORKS_INTRAY_TAB_ICON, REGISTERED_WORKS_INTRAY_TAB_ICON_TRANSFORM, REGISTERED_WORKS_REPERTOIRE_TAB_ICON, REGISTERED_WORKS_REPERTOIRE_TAB_ICON_TRANSFORM, DRAFT_WORK_INTRAY_TAB_ICON, DRAFT_WORK_INTRAY_TAB_ICON_TRANSFORM, DRAFT_WORK_REPERTOIRE_TAB_ICON, DRAFT_WORK_REPERTOIRE_TAB_ICON_TRANSFORM } from "../../../membersportal/Consts";
import CarouselScrollButton from "../../../repertoire/components/carouselScrollButton/CarouselScrollButton";
import { mp_removeTab } from "../../../redux/reducers/MembersPortalReducer";
import IMembersPortalComponentDataItem from "../../../redux/types/IMembersPortalComponentDataItem";
import { MEMBERS_PORTAL } from "../../../accounts/Consts";
import { setCurrentPage } from "../../../redux/reducers/HomeReducer";
import { AGREEMENT_INTRAY_TAB_ICON, AGREEMENT_INTRAY_TAB_ICON_TRANSFORM, AGREEMENT_REPERTOIRE_TAB_ICON, AGREEMENT_REPERTOIRE_TAB_ICON_TRANSFORM } from "../../../repertoire/Consts";

export interface ITabsViewProps {
    tabs: ITabReduxItem[];
    tabsViewData: IMembersPortalComponentDataItem;
    removeTab: typeof mp_removeTab;
    activeTab: number;
    openTab: (index: number) => void;
    closeTabDisabled: boolean;
    hideModal?: typeof hideModal;
    showYesNoPrompt?: typeof showYesNoPrompt;
    displayRepresentation: typeof displayRepresentationFlag;
    setCurrentPage: typeof setCurrentPage;
    portalType: string;
}

export default class TabsView extends React.PureComponent<ITabsViewProps, {}> {
    constructor(props: ITabsViewProps) {
        super(props);
    }

    onClickRemoveTab = (index: number) => {
        const { removeTab, tabs, closeTabDisabled, tabsViewData,
            hideModal, showYesNoPrompt } = this.props;
        if (closeTabDisabled) {
            return;
        }

        if (tabs[index].changesMade) {
            const title: string = tabsViewData.fields[0].data;
            const props: IYesNoPromptViewModalProps = {
                yesCallback: () => {
                    removeTab(index);
                    hideModal();
                },
                noCallback: () => { hideModal(); }
            }
            showYesNoPrompt(title, props);
        } else {
            removeTab(index);
        }
    }

    async onClick(target, index, componentInstance) {
        let address = this.props.portalType === MEMBERS_PORTAL ? componentInstance === DRAFT_WORKS_PAGE_VIEW ? '/works/' : '/products/' : '/repertoire';
        const META_DATA_KEY: any = componentInstance === DRAFT_WORKS_PAGE_VIEW ? {
            'view my draft works': { 'link': "draftWorks", instance: MEMBERS_PORTAL },
            'view my registered works': { 'link': "myRegisteredWorks", instance: MEMBERS_PORTAL },
            'view all registered works': { 'link': "allRegisteredWorks", instance: MEMBERS_PORTAL },
            'member statements': { 'link': "memberStatements", instance: MEMBERS_PORTAL },
        } : {
            'view my draft set lists': { 'link': "draftSetLists", instance: MEMBERS_PORTAL },
            'view my registered set lists': { 'link': "registeredSetLists", instance: MEMBERS_PORTAL },
            'view my live performance submissions': { 'link': "livePerformanceSubmission", instance: MEMBERS_PORTAL },
            'view my unpaid claims': { 'link': "unpaidClaims", instance: MEMBERS_PORTAL },
            'view my draft agreements': { 'link': "draftAgreements", instance: MEMBERS_PORTAL },
            'view my registered agreements': { 'link': "registeredAgreements", instance: MEMBERS_PORTAL },
            'view my claims history': { 'link': "claimsHistory", instance: MEMBERS_PORTAL },
        }

        const remove = document.getElementsByClassName("icon ms-Icon ms-Icon--ChromeClose");
        if (remove[index].contains(target)) {
            await this.onClickRemoveTab(index);
        }
        else {
            await this.props.openTab(index);
        }

        const { tabs, activeTab } = this.props;

        if (tabs[activeTab].title.toLocaleLowerCase().search('find') > 0) {
            this.props.setCurrentPage(address + META_DATA_KEY[tabs[activeTab].title.toLowerCase()].link);
        }
        document.title = tabs[activeTab].title;
    }

    renderTabs = () => {
        const { tabs, activeTab, closeTabDisabled } = this.props;
        const componentInstance = tabs[activeTab].component;

        return tabs.map((tab: ITab, index: number) => (
            <div onClick={(e) => this.onClick(e.target, index, componentInstance)} key={index} className={activeTab === index ? "activeTab" : "tab"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 100 100">
                    {tabs[index].draftWorkMaintenanceState && tabs[index].draftWorkMaintenanceState.work ?
                        (tabs[index].draftWorkMaintenanceState.work.dataSource === INTRAY ?
                            <path id="" className="tabIconType" d={DRAFT_WORK_INTRAY_TAB_ICON} transform={DRAFT_WORK_INTRAY_TAB_ICON_TRANSFORM}></path>
                            : <path id="" className="tabIconType" d={DRAFT_WORK_REPERTOIRE_TAB_ICON} transform={DRAFT_WORK_REPERTOIRE_TAB_ICON_TRANSFORM}></path>)
                        : tabs[index].registeredWorkMaintenanceState && tabs[index].registeredWorkMaintenanceState.work ?
                            (tabs[index].registeredWorkMaintenanceState.work.dataSource === INTRAY ?
                                <path id="" className="tabIconType" d={REGISTERED_WORKS_INTRAY_TAB_ICON} transform={REGISTERED_WORKS_INTRAY_TAB_ICON_TRANSFORM}></path>
                                : <path id="" className="tabIconType" d={REGISTERED_WORKS_REPERTOIRE_TAB_ICON} transform={REGISTERED_WORKS_REPERTOIRE_TAB_ICON_TRANSFORM}></path>)
                            : tabs[index].allRegisteredWorkMaintenanceState && tabs[index].allRegisteredWorkMaintenanceState.work ?
                                (tabs[index].allRegisteredWorkMaintenanceState.work.dataSource === INTRAY ?
                                    <path id="" className="tabIconType" d={ALL_REGISTERED_WORKS_INTRAY_TAB_ICON} transform={ALL_REGISTERED_WORKS_INTRAY_TAB_ICON_TRANSFORM}></path>
                                    : <path id="" className="tabIconType" d={ALL_REGISTERED_WORKS_REPERTOIRE_TAB_ICON} transform={ALL_REGISTERED_WORKS_REPERTOIRE_TAB_ICON_TRANSFORM}></path>)
                                : tabs[index].agreementMaintenanceState && tabs[index].agreementMaintenanceState.agreement ?
                                (tabs[index].agreementMaintenanceState.agreement.dataSource === INTRAY ?
                                    <path id="" className="tabIconType" d={AGREEMENT_INTRAY_TAB_ICON} transform={AGREEMENT_INTRAY_TAB_ICON_TRANSFORM}></path>
                                    : <path id="" className="tabIconType" d={AGREEMENT_REPERTOIRE_TAB_ICON} transform={AGREEMENT_REPERTOIRE_TAB_ICON_TRANSFORM}></path>)
                                : <></>
                    }
                </svg>

                <div>
                    {tab.title ? tab.title : "untitled"}
                </div>
                <div
                    className={["removeTabDiv", !!closeTabDisabled && "closeDisabled"].join(" ")}
                >
                    <span className="icon ms-Icon ms-Icon--ChromeClose" aria-hidden="true"></span>
                </div>
            </div>
        ));
    };

    renderTabContent = () => {
        const { tabs, displayRepresentation, activeTab } = this.props;
        return tabs.map((tab: ITabReduxItem, index: number) => (
            <Tab.Pane eventKey={index} key={index} className={tab.isReadonly ? 'versionHistory' : ''} >
                <TabComponent component={tab.component} displayRepresentation={displayRepresentation} />
            </Tab.Pane>
        ));
    };

    render() {
        const { activeTab } = this.props;
        return (
            <div className="form-group col-md-12 TabsView">
                <div className="tabsDiv">
                    <CarouselScrollButton
                        content={this.renderTabs()}
                        distanceToScroll={100}
                    />
                </div>
                <Tab.Container
                    activeKey={activeTab}
                    onSelect={() => null}
                    id="tab-container" className="tab-content formBox tab-pane active fade in"
                >
                    <Tab.Content>{this.renderTabContent()}</Tab.Content>
                </Tab.Container>
            </div>
        );
    }
}
