import { connect } from "react-redux";
import IAppState from "../../../../redux/types/IAppState";
import UsageMaintenanceAttachmentsGrid from "./UsageMaintenanceAttachmentsGrid";
import { CleanUploadFilesState, addFileToUpload, setInDropZone, setProgressUploadFileProccess, sortColumn, updatePagination } from "../../../../redux/reducers/DataIngestionReducer";
import { getDataIngestionComponentDataThunk, getFilesystemsThunk, refreshDirectoryThunk } from "../../../../redux/thunks/DataIngestionThunks";
import { addDroppedFile, addRepertoireAttachmentSuccess, cleanDroppedFilesRepertoireState, setProgressUploadFileRepertoireProccess } from "../../../../redux/reducers/RepertoireReducer";
import { refreshUsageGroupDetailsThunk, removeUsageAttachmentIfExistsThunk } from "../../../../redux/thunks/RepertoireThunks";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IFileSystem } from "../../../../dataingestion/types/IFileSystem";
import { ITreeData } from "../../../types/ITreeData";
import IFile from "../../../../redux/types/IFiles";
import { IAttachedFile } from "../../../../redux/types/IAttachedFile"; 

export default connect(
    (state: IAppState) => ({
        lookupValues: state.lookupEntities,
        activeTab: state.repertoire.panes[state.repertoire.activePane].activeTab,
        tabs: state.repertoire.panes[state.repertoire.activePane].tabs,
        dataSource: state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab].usageMaintenanceState && state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab].usageMaintenanceState.usageGroup && state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab].usageMaintenanceState.usageGroup.usageType,
        loaded: state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab].usageMaintenanceState && state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab].usageMaintenanceState.loaded,
        usageGroup: state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab].usageMaintenanceState && state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab].usageMaintenanceState.usageGroup,
        allResultsPerPage: state.repertoire.resultsPerPage,
        containerDetailsWindowComponentData: state.dataIngestion.componentData.ContainerDetailsWindow,
        storagePathItemData: state.dataIngestion.componentData.StoragePathItem,
        storagePaths: state.dataIngestion.storagePaths,
        selectedFileSystem: state.dataIngestion.selectedFileSystem,
        droppedFiles: state.dataIngestion.droppedFiles,
        isDropZone: state.dataIngestion.droppedFiles.inDropZone,
        filesystems: state.dataIngestion.filesystems,
        treeNodes: state.dataIngestion.treeNodes,
        selectedNode: state.dataIngestion.selectedNode,
        attachedFiles: state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab].usageMaintenanceState && state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab].usageMaintenanceState.usageGroup && state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab].usageMaintenanceState.usageGroup.files,
        usageAttachmentsDropped: state.repertoire.panes[state.repertoire.activePane].tabs && state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab] && state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab].droppedFiles,
        paginationDetails: state.dataIngestion.paginationDetails,
    }),
    dispatch => ({
        setInDropZone: (inDropZone: boolean) => dispatch(setInDropZone(inDropZone)),
        refreshDirectory: (filesystem: IFileSystem, sources: ITreeData[]) => dispatch<any>(refreshDirectoryThunk(filesystem, sources)),
        addFileToUpload: (file: IFile, isComplete: boolean) => dispatch<any>(addFileToUpload(file, isComplete)),
        setProgressUploadFileProcess: (message: string, file: IFile, isUploadComplete: boolean) => dispatch<any>(setProgressUploadFileProccess(message, file, isUploadComplete)),
        sortColumn: (section: string, ascending: boolean) => dispatch<any>(sortColumn(section, ascending)),
        cleanUploadFilesState: () => dispatch(CleanUploadFilesState()),
        getFilesystems: (isRepertoireModule?: boolean) => dispatch<any>(getFilesystemsThunk(isRepertoireModule)),
        getDataIngestionComponentData: () => dispatch<any>(getDataIngestionComponentDataThunk()),
        addRepertoireAttachmentToState: (attachedFile: IAttachedFile) => dispatch<any>(addRepertoireAttachmentSuccess(attachedFile)),
        removeUsageAttachmentIfExists: (attachedFile: IAttachedFile) => dispatch<any>(removeUsageAttachmentIfExistsThunk(attachedFile)),
        addDroppedFile: (file: IFile, inProgress: boolean) => dispatch(addDroppedFile(file, inProgress)),
        setProgressUploadFileRepertoire: (message: string, file: IFile, isUploadComplete: boolean) => dispatch(setProgressUploadFileRepertoireProccess(message, file, isUploadComplete)),
        cleanDroppedFilesRepertoire: () => dispatch(cleanDroppedFilesRepertoireState()),
        refreshUsageDetails: (usageId: string, activeTabItem: ITabReduxItem) =>
            dispatch<any>(refreshUsageGroupDetailsThunk(usageId, activeTabItem)),
        updatePagination: (resultsPerPage: number, currentPage: number, section: string) =>
            dispatch<any>(updatePagination(resultsPerPage, currentPage, section)),

    })
)(UsageMaintenanceAttachmentsGrid);