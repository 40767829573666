import React, { FunctionComponent, useState } from "react";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import { APPLICABLE_WORKS_KEY, ASSING_TO_SEARCH_WORKFLOW_STATE_KEY, DISTRIBUTION_MAINTENANCE_GRIDS_VIEW, DISTRIBUTION_SUBJECTS_KEY, DISTRIBUTION_SUBJECT_LICENSING_TYPE_STATE_KEY, DISTRIBUTION_SUBJECT_MEMBER_TYPE_STATE_KEY, DISTRIBUTION_SUBJECT_WORK_TYPE_STATE_KEY, EMPTY_STRING_VALUE, INVALID_KEYS, REGION_LABEL_VALUE, REPERTOIRE_PAGE_FORM_ACTION_BUTTON_SIZE_2, REPERTOIRE_PAGE_FORM_ACTION_BUTTON_SIZE_3, REPERTOIRE_PAGE_FORM_ITEM_SIZE, REPERTOIRE_PAGE_FORM_LARGE_BOX_SIZE, REPERTOIRE_PAGE_FORM_WIDE_SIZE, SEARCH_VIEW_WORKFLOW, UPDATE_WORKFLOW_VIEW, UPDATE_WORKFLOW_VIEW_STATUS_FIELD_STATE_KEY, WORKFLOW_ENTITY_TYPE_STATE_KEY, WORK_MAINTENANCE_TERRITORY_SPECIFIC_INFO_VIEW } from "../../Consts";
import { Dropdown, DropdownMenuItemType, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { ComboBox, IComboBoxStyles } from "@fluentui/react";
import { IMembersPortalStateKeys } from "../../../membersportal/types/IMembersPortalStateKeys";

export interface IDropdownDataInputProps {
    label?: string;
    value: any;
    changeData: (
        value: string | string[] | boolean,
        fieldName: IRepertoireStateKeys | IMembersPortalStateKeys,
        index?: number,
        event?: any
    ) => void;
    fieldName: IRepertoireStateKeys | IMembersPortalStateKeys;
    options: {
        code: string;
        description: string;
        extraFields?: object;
    }[];
    allowNull: boolean;
    showDropdownsAsCodes?: boolean;
    readOnly?: boolean;
    isHidden: boolean;
    componentInstance?: string;
    selectedCode?: string;
    index?: number;
    useComboBoxAsMenuWidth?: boolean;
    isMandatory?: boolean;
    key?: string;
    backendSearch?: boolean;
    onBackendSearch?: (value: string, e?: any) => void;
}

export const SizedDropdownDataInput: FunctionComponent<IDropdownDataInputProps> = (props: IDropdownDataInputProps) => {

    if (props.isHidden)
        return null;
    let containerClass: string = "";

    if (props.componentInstance === SEARCH_VIEW_WORKFLOW) {

        if (props.fieldName === WORKFLOW_ENTITY_TYPE_STATE_KEY)
            containerClass = REPERTOIRE_PAGE_FORM_ACTION_BUTTON_SIZE_3;
        else if (props.fieldName === UPDATE_WORKFLOW_VIEW_STATUS_FIELD_STATE_KEY)
            containerClass = REPERTOIRE_PAGE_FORM_ACTION_BUTTON_SIZE_2;
        else if (props.fieldName === ASSING_TO_SEARCH_WORKFLOW_STATE_KEY)
            containerClass = REPERTOIRE_PAGE_FORM_ACTION_BUTTON_SIZE_3;
        else
            containerClass = REPERTOIRE_PAGE_FORM_ITEM_SIZE;
    }
    else if (props.componentInstance === UPDATE_WORKFLOW_VIEW) {
        containerClass = REPERTOIRE_PAGE_FORM_WIDE_SIZE;
    }
    else if (props.componentInstance === DISTRIBUTION_SUBJECTS_KEY) {

        if (props.fieldName === DISTRIBUTION_SUBJECT_WORK_TYPE_STATE_KEY)
            containerClass = REPERTOIRE_PAGE_FORM_ACTION_BUTTON_SIZE_3;
        else if (props.fieldName === DISTRIBUTION_SUBJECT_LICENSING_TYPE_STATE_KEY)
            containerClass = REPERTOIRE_PAGE_FORM_ACTION_BUTTON_SIZE_3;
        else if (props.fieldName === DISTRIBUTION_SUBJECT_MEMBER_TYPE_STATE_KEY)
            containerClass = REPERTOIRE_PAGE_FORM_ACTION_BUTTON_SIZE_3;
    }
    else {
        containerClass = REPERTOIRE_PAGE_FORM_ITEM_SIZE;
    }

    //const containerClass: string = (props.componentInstance && props.componentInstance === UPDATE_WORKFLOW_VIEW) ? EMPTY_STRING_VALUE : REPERTOIRE_PAGE_FORM_ITEM_SIZE
    return <div className={containerClass}>
        <DropdownDataInput {...props} />
    </div>
}

const DropdownDataInput: FunctionComponent<IDropdownDataInputProps> = ({
    options,
    label,
    changeData,
    value,
    fieldName,
    allowNull,
    showDropdownsAsCodes,
    readOnly,
    componentInstance,
    selectedCode,
    index,
    useComboBoxAsMenuWidth,
    isMandatory,
    key,
    backendSearch,
    onBackendSearch
}) => {
    const makeOptionsList = () => {
        var arr: IDropdownOption[] = [];
        if (allowNull && fieldName !== "setTypeGroup") {
            arr.push({ key: -1, text: "", data: "" });
        }

        if (options) {
            var optionGroups: { [key: string]: IDropdownOption[] } = {};
            if (fieldName === "numbers") options.sort((a, b) => a.description.localeCompare(b.description.toLowerCase()));

            if (fieldName === "musicArrangement" || fieldName === "lyricAdaptation") {
                options.forEach((opt, i) => {
                    const optElement: IDropdownOption = {
                        key: i,
                        data: opt.code,
                        text: fieldName === "musicArrangement" ? opt.extraFields['ArrangementType'] : opt.extraFields['AdaptationType'],
                        title: showDropdownsAsCodes ? opt.description : null,
                        disabled: opt.extraFields && opt.extraFields['DisabledInDropdown'],
                        isSelected: opt.code === selectedCode
                    };
                    arr.push(optElement);
                });
            }
            else {
                options.forEach((opt, i) => {
                    const optElement: IDropdownOption = {
                        key: i,
                        data: opt.code,
                        text: showDropdownsAsCodes ? opt.code : opt.description,
                        title: showDropdownsAsCodes ? opt.description : null,
                        disabled: opt.extraFields && opt.extraFields['DisabledInDropdown'],
                        isSelected: opt.code === selectedCode
                    };
                    if (opt.extraFields && 'Type' in opt.extraFields && opt.extraFields['Type'] !== undefined) {
                        if (opt.extraFields['Type'].toString() in optionGroups) {
                            if (['ISRC', 'ISWC'].includes(opt.code))
                                optionGroups[opt.extraFields['Type'].toString()].unshift(optElement);
                            else
                                optionGroups[opt.extraFields['Type'].toString()].push(optElement);
                        } else {
                            optionGroups[opt.extraFields['Type'].toString()] = [optElement];
                        }
                    } else {
                        arr.push(optElement);
                    }
                });
            }

            for (const group in optionGroups) {
                const groupItem = { key: `Group-${group}`, text: group, title: group, itemType: DropdownMenuItemType.Header };
                if (group === 'Other') {
                    arr.unshift(groupItem, ...optionGroups[group]);
                } else {
                    arr.push(groupItem, ...optionGroups[group]);
                }
            }
        }
        return arr;
    };
    const showCodesClassName: string = showDropdownsAsCodes ? ' showAsCode ' : EMPTY_STRING_VALUE
    const pointerClassName: string = componentInstance === WORK_MAINTENANCE_TERRITORY_SPECIFIC_INFO_VIEW && label === REGION_LABEL_VALUE ? ' pointerEnable ' : EMPTY_STRING_VALUE;

    const optionsFinal = makeOptionsList().sort((a, b) => a.text != null ? a.text.localeCompare(b.text) : 0);
    const displayValue: string = !showDropdownsAsCodes ?
        (optionsFinal !== undefined ?
            (value && optionsFinal.find(opt => opt.data == value) ?
                optionsFinal.find(opt => opt.data == value).text : value || '')
            : value || ''
        ) : value || '';

    const selectedValue = !showDropdownsAsCodes ?
        optionsFinal.find((option) => option.text === displayValue) :
        optionsFinal.find((option) => option.data === displayValue);


  const onKeyDownAction = (key: string) => {
      if (backendSearch) onBackendSearch(key);
    } 
    
    const onChangesHanle = ( item) => { 
      changeData(item.data, fieldName, index)
    }
    
    return (
        <div key={key} className="dropdownDataInput">
            <ComboBox
                label={label ? label + ':' : undefined}
                options={optionsFinal}
                onChange={(_event, item) => { onChangesHanle( item); }}
                onInputValueChange={(val) => { onKeyDownAction(val) }}
                useComboBoxAsMenuWidth={useComboBoxAsMenuWidth === false ? false : true}
                calloutProps={{ calloutMaxHeight: 270 }}
                placeholder={displayValue}
                selectedKey={selectedValue ? selectedValue.key : null}
                className={showCodesClassName.concat(pointerClassName,
                    isMandatory ? " required " : "",
                    readOnly ? " disabledItem " : "")}
                disabled={readOnly}
                allowFreeInput={true}
                autoComplete={!backendSearch  ? "on" : "off"}
                data-testid={fieldName + "-combo-box"}
            />
        </div>
    );
};

export default DropdownDataInput;
