import * as React from "react";
import TableHeaderSection from "../tableHeader/TableHeader";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IRepertoireField } from "../../../types/IRepertoireField";
import ActionButton from "../../../../repertoire/components/actionButton/ActionButton";
import PaginationView from "../tablePagination/TablePagination";
import {
    PRODUCT_SEARCH_MUSIC_DURATION_KEY,
    SELECT_ACTION,
    TITLE_STATE_KEY,
    USAGE_MATCHING_PRODUCTS_SECTION_KEY,
    USAGE_MATCHING_WORKS_SECTION_KEY
} from "../../../Consts";
import { addMatchWorkToUsage, addMatchProductToUsage, addWorkOrProductToUsageSearchCriteria } from "../../../../redux/reducers/RepertoireReducer";

import { hideModal } from "../../../../redux/reducers/ModalReducer";
import { CopyTableContents } from "../../../services/TableFunctions";
import { convertSecondsToTimeStamp } from "../../../../util/tools";

export interface IResultsTableProps {
    searchResultsTableData: IRepertoireComponentDataItem;
    tableContents: any;
    componentInstance: string;
    hideModal: typeof hideModal;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    repertoireSection?: string;
    sourceItem?: any;
    addMatchworkToUsage?: typeof addMatchWorkToUsage;
    addMatchProductToUsage?: typeof addMatchProductToUsage;
    expandedResults?: number[];
    expandAll?: boolean;
    expandResult?: (matchWorkResults: number) => void;
    expandAllResults?: () => void;
    sortSearchResults?: (name: string, results: any) => void;
    addWorkOrProductToUsageSearchCriteria?: typeof addWorkOrProductToUsageSearchCriteria;
    getWorkDetails?: (tableItem?:any) => void;
}

interface IResultsTableState {
    tableContents: any;
    activeSortHeaderSection: string;
    currentPage: number;
    resultsPerPage: number;
    indexOfFirstResult: number;
    indexOfLastResult: number;
}

export default class ResultsTable extends React.PureComponent<
    IResultsTableProps,
    IResultsTableState
> {
    constructor(props: IResultsTableProps) {
        super(props);

        this.state = {
            tableContents: props.tableContents,
            activeSortHeaderSection: undefined,
            indexOfFirstResult: this.props.indexOfFirstResult ? this.props.indexOfFirstResult : 0,
            indexOfLastResult: this.props.indexOfLastResult ? this.props.indexOfLastResult : 10,
            resultsPerPage: this.props.resultsPerPage ? this.props.resultsPerPage : 10,
            currentPage: this.props.currentPage ? this.props.currentPage : 1,
        };
    }

    public ascending: boolean = false;

    componentDidUpdate = (prevProps: IResultsTableProps) => {
        const { tableContents } = this.props;

        if (prevProps.tableContents !== tableContents) {
            this.setState({ tableContents });
        }
    };

    sortTableByColumn = (section: string) => {
        const { componentInstance, sortSearchResults } = this.props;
        const { activeSortHeaderSection, tableContents } = this.state;
        const tableContentsCopy = CopyTableContents(tableContents);

        if (activeSortHeaderSection !== section) {
            this.ascending = false;
        }

        tableContentsCopy.sort((a: any, b: any) => {
            // set to emtpy string if null
            let newA = a[section] || "";
            let newB = b[section] || "";

            if (!this.ascending) {
                return newA.toString().localeCompare(newB.toString(), undefined, { 'numeric': true });
            }
            return newB.toString().localeCompare(newA.toString(), undefined, { 'numeric': true });

        });

        this.setState({
            tableContents: tableContentsCopy,
            activeSortHeaderSection: section
        });

        this.ascending = !this.ascending;

        if (sortSearchResults) {
            sortSearchResults(componentInstance, tableContentsCopy);
        }
    };

    onClickSelect = (tableContentItem: any) => {
        const {
            hideModal,
            addMatchworkToUsage,
            addMatchProductToUsage,
            addWorkOrProductToUsageSearchCriteria
        } = this.props;
        if (addWorkOrProductToUsageSearchCriteria) {
            addWorkOrProductToUsageSearchCriteria({ ...tableContentItem });
            hideModal && hideModal();
        }
        else if (addMatchworkToUsage) {
            addMatchworkToUsage({ ...tableContentItem });
            hideModal && hideModal();
        }
        else if (addMatchProductToUsage) {
            addMatchProductToUsage({ ...tableContentItem });
            hideModal();
        }
    };


    renderExpandAll = () => {
        const { expandAll, expandAllResults, expandResult,componentInstance } = this.props;
        let expandText ='';
        let collapseText =''
        if(componentInstance === USAGE_MATCHING_WORKS_SECTION_KEY){
              expandText ='Expand Search Results';
              collapseText ='Collapse Search Results'
        }else{
            expandText = 'Expand Results'
            collapseText ='Collapse Results'
        }
        return (expandResult ?
            <div className='arrowIconExpandDiv'>
                <div className={expandAll ? "arrowIconExpandAllExpanded" : "arrowIconExpandAll"}>
                    <i className="icon ms-Icon ms-Icon--DoubleChevronLeft" aria-hidden="true" id="expandResultsButton" onClick={() => expandAllResults()} title={expandAll ? "Collapse Results" : "Expand Results"}></i>
                </div>
                {expandAll ? <div className='arrowIconExpandText'> {collapseText}</div> : <div className='arrowIconExpandText'> {expandText}</div>}
            </div> : <div></div>
        );
    }

    renderPaginationView(): JSX.Element {
        const { repertoireSection } = this.props;
        const { tableContents, resultsPerPage, currentPage } = this.state;
        return <PaginationView
            contentsLength={tableContents ? tableContents.length : 0}
            resultsPerPage={resultsPerPage}
            currentPage={currentPage}
            updateContentsTable={this.updateContentsTable.bind(this)}
            repertoireSection={repertoireSection}
        />
    }

    updateContentsTable(indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string): void {
        this.setState({ indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage });
        this.props.updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
    }

    renderHeaderSections = () => {
        const { searchResultsTableData, componentInstance } = this.props;
        const { activeSortHeaderSection } = this.state;

        if (searchResultsTableData && searchResultsTableData.fields) {
            const filteredFields = searchResultsTableData.fields.filter(
                (field: IRepertoireField) =>
                    field.componentInstance === componentInstance
            );

            return filteredFields.map((section: IRepertoireField, index: number) => (
                <td key={index} className="td">
                    <TableHeaderSection
                        section={section.data}
                        fieldName={section.name}
                        isSortingActive={section.name === activeSortHeaderSection}
                        ascending={this.ascending}
                        onClickHeaderSection={this.sortTableByColumn}
                        sortable
                    />
                </td>
            ));
        }
    };

    excludeObjectKeys = (key: string) => {
        const excludedKeys = ['compoundType'];

        if (excludedKeys.includes(key)) {
            return true;
        } else {
            return false;
        }
    }
    onClickExternalIcon = (value) => {
        const {getWorkDetails,componentInstance} = this.props;
        switch (componentInstance) {
            case USAGE_MATCHING_WORKS_SECTION_KEY:
                getWorkDetails(value)
                break;
            default:
                break;
        }
    };

    renderMultipleTitlesDiv = (array: string[],objectKey?:any,currentResults?:any) => {
        return (
            <div className="multipleTitlesDiv">
                {array.map((item, index) => (
                    <>
                        {(objectKey === TITLE_STATE_KEY && item) ? <div key={index}>
                            <a onClick={()=>this.onClickExternalIcon(currentResults[index])}>
                                <div>
                                    {item}
                                    {index + 1 !== array.length ? <span>,</span> : null}
                                </div>
                            </a>

                        </div> : <>
                            <div key={index}>
                                <div>
                                    {item}
                                    {index + 1 !== array.length ? <span>,</span> : null}
                                </div>

                            </div>
                        </>}
                    </>
                ))}
            </div>
        );
    };

    renderSearchResults = () => {
        const { tableContents, indexOfFirstResult, indexOfLastResult } = this.state;
        const { searchResultsTableData, componentInstance, expandedResults, expandResult } = this.props;
        const currentResults = tableContents.slice(
            indexOfFirstResult,
            indexOfLastResult
        );

        let filteredFields = searchResultsTableData.fields.filter(
            (field: IRepertoireField) =>
                field.componentInstance === componentInstance
        );
        return currentResults.map(
            (tableContentItem: any, index: number) => {
                const sections: JSX.Element[] = [];

                filteredFields.map((field, x: number) => {
                    if (field.fieldType === SELECT_ACTION) {
                        sections.push(
                            <td key={`${index}.${x}`} className="td" title={field.data}>
                                <ActionButton
                                    buttonText={field.data}
                                    buttonAction={() => this.onClickSelect(tableContentItem)}
                                />
                            </td>
                        );
                    }
                    else {
                        let item;

                        if (!this.excludeObjectKeys(field.name) && componentInstance !== USAGE_MATCHING_WORKS_SECTION_KEY) {
                            if (componentInstance === USAGE_MATCHING_PRODUCTS_SECTION_KEY && field.name === PRODUCT_SEARCH_MUSIC_DURATION_KEY){
                                    item = tableContentItem[field.name][0] ? convertSecondsToTimeStamp(tableContentItem[field.name][0]) : '' ;
                            }else{
                                item = tableContentItem[field.name];
                            }
                        }
                        else {
                            item = tableContentItem[field.name][0];
                        }
                        sections.push(
                            <>
                                <td key={`${index}.${x}`} className="td" title={
                                    field.name in tableContentItem ? tableContentItem[field.name][0] : field.data}>
                                    <div className="arrowIconDiv">
                                        {(field.name === 'title') && (
                                            <img
                                                src="assets/right-arrow.svg"
                                                title="Expand title"
                                                alt="Right Arrow icon"
                                                className={(expandedResults && expandedResults.includes(index)) ? "arrowIconExpanded" : "arrowIcon"}
                                                onClick={() => expandResult(index)}
                                            />
                                        )}
                                        {(expandedResults && expandedResults.includes(index)) && (!this.excludeObjectKeys(field.name) && tableContentItem[field.name].length > 1) ? (
                                            this.renderMultipleTitlesDiv(tableContentItem[field.name],field.name,currentResults)
                                        ) : (
                                            <div className="workTitle">
                                                {(field.name === TITLE_STATE_KEY && item && componentInstance===USAGE_MATCHING_WORKS_SECTION_KEY) ? (<a onClick={()=>this.onClickExternalIcon(currentResults[index])}>{item}</a>) : (item === "" && field.name === TITLE_STATE_KEY && componentInstance===USAGE_MATCHING_WORKS_SECTION_KEY) ? "Title not available" : item ?? ''}
                                            </div>
                                        )}
                                    </div>
                                </td>
                            </>)
                    }
                });

                return (
                    <tr
                        key={index}
                        className="trSelectable"
                        onClick={() => null}
                    >
                        {sections}
                    </tr>
                );
            }
        );
    };

    render() {
        return (
            <div className="selectionTable">
                {this.renderExpandAll()}
                {this.renderPaginationView()}
                <div className="tableContainer">
                    <table className="table">
                        <thead className="thead">
                            <tr className="tr">{this.renderHeaderSections()}</tr>
                        </thead>
                        <tbody className="tbody">{this.renderSearchResults()}</tbody>
                    </table>
                </div>
                {this.renderPaginationView()}
            </div>
        );
    }
}

