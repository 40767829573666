import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import IAppState from "../../../redux/types/IAppState";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getCustomerTimeZoneConfigurationThunk } from "../../../redux/thunks/AccountThunks";
dayjs.extend(utc);

export interface IDateDisplayProps {
    value: any;
    customerDatePreference?: string;
    currentFormat?: string;
    showTime?: boolean;
    customerTimeZone?: string;
}

export const YEAR_DAY_MONTH_DATE_FORMAT = "YYYY/DD/MM";

const DateDisplay:  React.FC<IDateDisplayProps> = ({ value, customerDatePreference, currentFormat, showTime, customerTimeZone }) => {
        const [dateToDisplay, setDateToDisplay] = useState('');

        useEffect(() => {
            let newValue = value;
            if(value && value?.value) {
                newValue = value?.value;
            }
            formatDate(newValue)
        }, [value]);

        const EUROPEAN_DATE_FORMAT = "DD/MM/YYYY";
        let preferenceDateFormat = customerDatePreference ? customerDatePreference : EUROPEAN_DATE_FORMAT;
        preferenceDateFormat = `${preferenceDateFormat}${showTime ? " HH:mm" : ''}`;
        const formatDate = (value) => {
            if(value && value.toString().includes('+')) {
                value = value.toString().split('+')[0];
            }
            if(value && value.toString().includes('.')) {
                value = value.toString().split('.')[0]
            }
            const formattedDate = dayjs(value).format('YYYY-MM-DDTHH:mm:ss');
            let date = dayjs.utc(formattedDate);
            let dateString = '';
            if (value) {
                if (date.isValid()) {
                    // Use customer's time zone for the date formatting
                    date = customerTimeZone ? date.utcOffset(customerTimeZone) : date.utc();
                    dateString = date.format(preferenceDateFormat);
                }
            }
            setDateToDisplay(dateString);
        }


        return (
            <div className={'flexColumn'} >
                {dateToDisplay}
            </div>
        );
}

export default connect((state: IAppState) => {
    return ({
    customerDatePreference: state.account?.customerDate ?? '',
    customerTimeZone: state.account?.customerTimeZone
})
})(DateDisplay);
