import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { DataSource } from "../../repertoire/types/DataSource";
import { IAgreement } from "../../repertoire/types/IAgreement";
import { IAgreementLink } from "../../repertoire/types/IAgreementLink";
import { IAgreementSearchResult } from "../../repertoire/types/IAgreementSearchResult";
import { IArtist } from "../../repertoire/types/IArtist";
import { IContributorSearchResult } from "../../repertoire/types/IContributorSearchResult";
import { IIPsSearchResult } from "../../repertoire/types/IIPsSearchResult";
import { IP } from "../../repertoire/types/IP";
import { IWork } from "../../repertoire/types/IWork";
import { IWorkFlowSaveResult, IWorkSaveResult } from "../../repertoire/types/IWorkSaveResult";
import { IWorksSearchResult } from "../../repertoire/types/IWorksSearchResult";
import IRepertoireComponentData from "../types/IRepertoireComponentData";
import { ITabReduxItem } from "../types/ITabReduxItem";
import { IWorkStateKeys } from "../types/IWorkState";
import { IAccountSaveResult } from "../../repertoire/types/IAccountSaveResult";
import { IInterestedParty, IInterestedPartyStateKeys } from "../../repertoire/types/IInterestedParty";
import { IAgreementSaveResult } from "../../repertoire/types/IAgreementSaveResult";
import { IAgreementStateKeys } from "../types/IAgreementState";
import { IProductSearchResult } from "../../repertoire/types/IProductSearchResult";
import { IProductCore } from "../../repertoire/types/IProductCore";
import { IProductState, IProductStateKeys } from "../types/IProductState";
import { IProductSaveResult } from "../../repertoire/types/IProductSaveResult";
import { IVersionHistorySearchResult } from "../../repertoire/types/IVersionHistorySearchResult";
import { IDataActionToolbar } from "../../repertoire/types/IDataActionToolbar";
import { IProductVersionHistorySearchResult } from "../../repertoire/types/IProductVersionHistorySearchResult";
import { IIPSaveResult } from "../../repertoire/types/IIPSaveResult";
import { IAgreementSearchStateKeys } from "../../repertoire/types/IAgreementSearchState";
import { IIPAgreement } from "../../repertoire/types/IIPAgreement";
import { IContributorSiteConfiguration } from "../../repertoire/types/IContributorSiteConfiguration";
import { IRepertoireField } from "../../repertoire/types/IRepertoireField";
import { IRepertoireStateKeys } from "../../repertoire/types/IRepertoireStateKeys";
import IRepertoireComponentDataItem from "../types/IRepertoireComponentDataItem";
import { FormatFields } from "../types/FormatFields";
import { ISocIPVersionHistorySearchResult } from "../../repertoire/types/ISocIPVersionHistorySearchResult";
import { IAttachedFile } from "../types/IAttachedFile";
import IFile from "../types/IFiles";
import { IWorkflowSearchResult } from "../../repertoire/types/IWorkflowSearchResult";
import { IWorkflowSearchQuery } from "../../repertoire/types/IWorkflowSearchQuery";
import { IAgreementShareDisplayConfiguration } from "../../repertoire/types/IAgreementShareDisplayConfiguration";
import { IWorksSearchQuery } from "../../repertoire/types/IWorksSearchQuery";
import { SaveOption } from "../../repertoire/types/SaveOption";
import { IValidationMessage } from "../../repertoire/types/IValidationMessage";
import { IProductAVRequest } from "../../repertoire/types/IProductAVRequest";
import { IProductAVWorkSubmission } from "../../repertoire/types/IProductAVWorkSubmission";
import { IProductMaintenanceSubmissionConfig } from "../../repertoire/types/IProductMaintenanceSubmissionConfig";
import { IResultsPerPage } from "../types/IResultsPerPage";
import { IActiveAccordion } from "../types/IActiveAccordion";

import { IUsagesSearchQuery } from "../../repertoire/types/usageTypes/IUsagesSearchQuery";
import { IUsageGroupsSearchResult, IUsageSearchResultRowType } from "../../repertoire/types/usageTypes/IUsageGroupsSearchResult";
import { IMatchWorksSearchResult } from "../../repertoire/types/usageTypes/IMatchWorksSearchResult";
import { IMatchWorksSearchQuery } from "../../repertoire/types/usageTypes/IMatchWorksSearchQuery";
import { IUsageGroup } from "../../repertoire/types/usageTypes/IUsageGroup";
import { IDistribution } from "../../repertoire/types/usageTypes/IDistribution";
import { IUsageGroupSaveResult } from "../../repertoire/types/usageTypes/IUsageGroupSaveResult";
import { ISourceMatchType } from "../../settings/types/ISourceMatchType";
import { Dictionary } from "../../core/types/Dictionary";
import { IArtistStateKeys } from "../types/IArtistState";
import { IArtistCore } from "../../repertoire/types/IArtistCore";
import { IArtistSaveResult } from "../../repertoire/types/IArtistSaveResult";
import { IMatchProductsSearchQuery } from "../../repertoire/types/usageTypes/IMatchProductsSearchQuery";
import { IUsagePool } from "../../repertoire/types/usageTypes/IUsagePool";
import { IUsagePoolCore } from "../../repertoire/types/usageTypes/IUsagePoolCore";
import { IUsagePoolState, IUsagePoolStateKeys } from "../types/IUsagePoolState";
import { IUsagePoolSaveResult } from "../../repertoire/types/usageTypes/IUsagePoolSaveResult";
import { ISourceSearchResult } from "../../settings/types/ISourceSearchResult";
import { IDistributionType } from "../../repertoire/types/usageTypes/IDistibutionType";
import { IDistributionState, IDistributionStateKeys } from "../types/IDistributionState";
import { IDistributionSaveResult } from "../../repertoire/types/usageTypes/IDistributionSaveResult";
import { IIPMaintenancePageUIConfiguration } from "../types/IIPMaintenancePageUIConfiguration";
import { IAgreementVersionHistorySearchResult } from "../../repertoire/types/IAgreementVersionHistorySearchResult";
import { ISelectionCriteria } from "../../repertoire/types/ISelectionCriteria";
import { IUsagePoolVersionHistorySearchResult } from "../../repertoire/types/usageTypes/IUsagePoolVersionHistorySearchResult";
import { IRepresentationStateKeys } from "../../repertoire/types/usageTypes/IRepresentation";
import { IAllocateDistributionPoolsResponse } from "../../repertoire/types/usageTypes/IAllocateDistributionPoolsResponse";
import { IClaimStateKeys } from "../types/IClaimState";
import { IIpLicensingRepresentation } from "../../repertoire/types/IIpLicensingRepresentation";
import { IClaimSaveResult } from "../../repertoire/types/IClaimSaveResult";
import { IReadonlyFlagsField } from "../../repertoire/types/IReadonlyFlagsField";
import { IAdjustment } from "../../repertoire/types/usageTypes/IAdjustment";
import { IAdjustmentSaveResult } from "../../repertoire/types/usageTypes/IAdjustmentSaveResult";
import { IAdjustmentState, IAdjustmentStateKeys } from "../types/IAdjustmentState";
import { IClaimSearchResult } from "../../repertoire/types/IClaimSearchResult";
import { IDistributionSubjects } from "../../repertoire/types/usageTypes/IDistributionSubjects";
import { IGenreCategoriesConfiguration } from "../../repertoire/types/IGenreCategoriesConfiguration";
import { IGenereCategoryDefaultsConfiguration } from "../../repertoire/types/IGenereCategoryDefaultsConfiguration";
import { IPaymentRunSearchQuery } from "../../repertoire/types/IPaymentRunSearchQuery";
import { IPaymentRunStateKeys } from "../types/IPaymentRunState";
import { IPaymentRun } from "../../repertoire/types/usageTypes/IPaymentRun";
import { IPaymentRunSaveResult } from "../../repertoire/types/usageTypes/IPaymentRunSaveResult";
import { IPaymentRunVersionHistorySearchResult } from "../../repertoire/types/usageTypes/IPaymentRunVersionHistorySearchResult";
import { IAdjustmentVersionHistorySearchResult } from "../../repertoire/types/usageTypes/IAdjustmentVersionHistorySearchResult";
import { IShoppingCart } from "../../repertoire/types/IShoppingCart";
import { ILicenseUserSearchResult } from "../../repertoire/types/ILicenseUserSearchResult";
import { ILicenseRequestWorkItem } from "../types/ILicenseRequestWorkItem";
import { IUsageDetailsAdjustmentsDataItem } from "../../repertoire/usage/components/usageRowDropdown/UsageRowDropdown";
import { ILicenseInputItem } from "../types/ILicenseInputItem";
import { ILicenseSaveResult } from "../../repertoire/types/ILicenseSaveResult";
import { IWorkContributor } from "../../repertoire/types/IWorkContributor";
import { IUsageGroupSetListAdditional } from "../../repertoire/types/usageTypes/IUsageGroupSetListAdditional";
import { IUsageGroupLocation } from "../../repertoire/types/usageTypes/IUsageGroupLocation";
import { IUsageGroupVenueDetails } from "../../repertoire/types/usageTypes/IUsageGroupVenueDetails";
import { IClaim } from "../../repertoire/types/IClaim";
import { IShoppingCartState } from "../types/IShoppingCartState";
import { IName } from "../../repertoire/types/IName";
import { IInterestedPartySaveResult } from "../../repertoire/types/IInterestedPartySaveResult";
import { IBookPriceCalculationsResponse } from "../../repertoire/types/IBookPriceCalculationsResponse";
import { ISocIP } from "../../repertoire/types/ISocIP";
import { IDistributionSubType } from "../../repertoire/types/usageTypes/IDistributionSubType";
import { ILicenseSubmitResult } from "../../repertoire/types/ILicenseSubmitResult";
import { IUsagePoolWeightSettingsStateKeys } from "../../repertoire/types/usageTypes/IUsagePoolWeightSettings";
import { IWorkSubjectSearchResult } from "../../repertoire/types/IWorkSubjectSearchResult";
import { IWorkMaintenanceSubject } from "../../repertoire/types/IWorkMaintenanceSubjects";
import { ISubject } from "../../repertoire/types/ISubject";
import { IWorkflow } from "../../repertoire/types/IWorkflow";
import { IRunQADistributionPoolsResponse } from "../../repertoire/types/usageTypes/IRunQADistributionPoolsResponse";
import IShortCutKeys from "../types/IShortCutKeys";
import { IScheduledJobStateKeys } from "../types/IScheduledJobState";
import { IShareToleranceValueConfiguration } from "../../repertoire/types/IShareToleranceValueConfiguration";
import { IRepresentationSearchStateKeys } from "../../repertoire/types/IRepresentationSearchState";
import { IIPRepresentation } from "../../repertoire/types/IIPRepresentation";
import { GetAllDistributionsFailure, GetAllDistributionsRequest, GetAllDistributionsSuccess } from "./HomeActions";
import { IDistributionPoolStateKeys } from "../types/IDistributionPoolState";
import { IMyPackagesDownloadFileFormat } from "../../repertoire/types/IMyPackagesDownloadFileFormat";
import { IAddtoPackageResult } from "../../repertoire/types/IAddtoPackageResult";
import { IMyPackagesDetails } from "../../repertoire/types/IMyPackagesDetails";
import { IMyPackagesResult } from "../../repertoire/types/IMyPackagesResult";
import { IUsageGroupState } from "../types/IUsageGroupState";
import { IUsagePoolSearchQuery } from "../../repertoire/types/usageTypes/IUsagePoolSearchQuery";
// import { IDistributionPoolByCode } from "../../repertoire/types/IDistributionPoolByCode";


// Action name types
//USAGES
type GET_USAGE_REQUEST_TYPE = "GET_USAGE_REQUEST";
type GET_USAGE_SUCCESS_TYPE = "GET_USAGE_SUCCESS";
type GET_USAGE_FAILURE_TYPE = "GET_USAGE_FAILURE";

type SAVE_USAGEGROUP_REQUEST_TYPE = "SAVE_USAGEGROUP_REQUEST";
type SAVE_USAGEGROUP_SUCCESS_TYPE = "SAVE_USAGEGROUP_SUCCESS";
type SAVE_USAGEGROUP_FAILURE_TYPE = "SAVE_USAGEGROUP_FAILURE";

type UPDATE_USAGEPAGINATION_TYPE = 'UPDATE_USAGEPAGINATION';
type RESET_USAGEPAGINATION_TYPE = 'RESET_USAGEPAGINATION';

type UPDATE_USAGE_FIELD_TYPE = "UPDATE_USAGE_FIELD";
type UPDATE_USAGE_ROW_DROPDOWN_TYPE = "UPDATE_USAGE_ROW_DROPDOWN";
type SEARCHDATA_CHANGED_TYPE = "SEARCHDATA_CHANGED";

type FETCH_USAGE_COMPONENT_DATA_REQUEST_TYPE = "FETCH_USAGE_COMPONENT_DATA_REQUEST";
type FETCH_USAGE_COMPONENT_DATA_SUCCESS_TYPE = "FETCH_USAGE_COMPONENT_DATA_SUCCESS";
type FETCH_USAGE_COMPONENT_DATA_FAILURE_TYPE = "FETCH_USAGE_COMPONENT_DATA_FAILURE";

type SEARCH_USAGEGROUP_REQUEST_TYPE = "SEARCH_USAGES_REQUEST";
type SEARCH_USAGEGROUP_SUCCESS_TYPE = "SEARCH_USAGES_SUCCESS";
type SEARCH_USAGEGROUP_FAILURE_TYPE = "SEARCH_USAGES_FAILURE";

type GET_USAGE_MATCHES_REQUEST_TYPE = "GET_USAGE_MATCHES_REQUEST";
type GET_USAGE_MATCHES_SUCCESS_TYPE = "GET_USAGE_MATCHES_SUCCESS";
type GET_USAGE_MATCHES_FAILURE_TYPE = "GET_USAGE_MATCHES_FAILURE";

type UPDATE_USAGE_SEARCH_FIELDS_TYPE = "UPDATE_USAGEGROUP_SEARCH_FIELDS";
type ADD_WORK_OR_PRODUCT_TO_USAGE_SEARCH_CRITERIA_TYPE = "ADD_WORK_OR_PRODUCT_TO_USAGE_SEARCH_CRITERIA";

type CLEAR_USAGEMODAL_SEARCH_RESULTS_TYPE = "CLEAR_USAGEMODAL_SEARCH_RESULTS";

type GET_USAGEDISTR_REQUEST_TYPE = "GET_USAGEDISTR_REQUEST";
type GET_USAGEDISTR_SUCCESS_TYPE = "GET_USAGEDISTR_SUCCESS";
type GET_USAGEDISTR_FAILURE_TYPE = "GET_USAGEDISTR_FAILURE";

type GET_USAGEPOOL_REQUEST_TYPE = "GET_USAGEPOOL_REQUEST";
type GET_USAGEPOOL_SUCCESS_TYPE = "GET_USAGEPOOL_SUCCESS";
type GET_USAGEPOOL_FAILURE_TYPE = "GET_USAGEPOOL_FAILURE";

type GET_SOURCEMAJOR_SUCCESS_TYPE = "GET_SOURCEMAJOR_SUCCESS";
type GET_SOURCEMAJOR_REQUEST_TYPE = "GET_SOURCEMAJOR_REQUEST";
type GET_SOURCEMAJOR_FAILURE_TYPE = "GET_SOURCEMAJOR_FAILURE";

type GET_SOURCEMINOR_SUCCESS_TYPE = "GET_SOURCEMINOR_SUCCESS";
type GET_SOURCEMINOR_REQUEST_TYPE = "GET_SOURCEMINOR_REQUEST";
type GET_SOURCEMINOR_FAILURE_TYPE = "GET_SOURCEMINOR_FAILURE";

type SEARCH_MATCHWORKS_FAILURE_TYPE = "SEARCH_MATCHWORKS_FAILURE";
type SEARCH_MATCHWORKS_REQUEST_TYPE = "SEARCH_MATCHWORKS_REQUEST";
type SEARCH_MATCHWORKS_SUCCESS_TYPE = "SEARCH_MATCHWORKS_SUCCESS";

type SEARCH_MATCHPRODUCTS_FAILURE_TYPE = "SEARCH_MATCHPRODUCTS_FAILURE";
type SEARCH_MATCHPRODUCTS_REQUEST_TYPE = "SEARCH_MATCHPRODUCTS_REQUEST";
type SEARCH_MATCHPRODUCTS_SUCCESS_TYPE = "SEARCH_MATCHPRODUCTS_SUCCESS";

type ADD_MATCHWORK_TO_USAGE_TYPE = "ADD_MATCHWORK_TO_USAGE";
type ADD_MATCHPRODUCT_TO_USAGE_TYPE = "ADD_MATCHPRODUCT_TO_USAGE";
type EXPAND_MATCHWORK_RESULT_TYPE = "EXPAND_MATCHWORK_RESULT";
type USAGEEXPAND_ALL_RESULTS_TYPE = "USAGEEXPAND_ALL_RESULTS";
type RESET_USAGEMESSAGE_BANNER_TYPE = "RESET_USAGEMESSAGE_BANNER";

type SET_USAGE_TYPE_TYPE = "SET_USAGE_TYPE";

type GET_SOURCES_REQUEST_TYPE = "GET_SOURCES_REQUEST";
type GET_SOURCES_SUCCESS_TYPE = "GET_SOURCES_SUCCESS";
type GET_SOURCES_FAILURE_TYPE = "GET_SOURCES_FAILURE";

type GET_USAGE_POOL_REQUEST_TYPE = "GET_USAGE_POOL_REQUEST";
type GET_USAGE_POOL_SUCCESS_TYPE = "GET_USAGE_POOL_SUCCESS";
type GET_USAGE_POOL_FAILURE_TYPE = "GET_USAGE_POOL_FAILURE";

type UPDATE_USAGE_POOL_FIELD_TYPE = "UPDATE_USAGE_POOL_FIELD";

type SAVE_USAGE_POOL_REQUEST_TYPE = "SAVE_USAGE_POOL_REQUEST";
type SAVE_USAGE_POOL_SUCCESS_TYPE = "SAVE_USAGE_POOL_SUCCESS";
type SAVE_USAGE_POOL_FAILURE_TYPE = "SAVE_USAGE_POOL_FAILURE";

type UNDO_USAGE_POOL_CHANGES_REQUEST_TYPE = "UNDO_USAGE_POOL_CHANGES_REQUEST";
type UNDO_USAGE_POOL_CHANGES_SUCCESS_TYPE = "UNDO_USAGE_POOL_CHANGES_SUCCESS";
type UNDO_USAGE_POOL_CHANGES_FAILURE_TYPE = "UNDO_USAGE_POOL_CHANGES_FAILURE";

type DELETE_USAGE_POOL_REQUEST_TYPE = "DELETE_USAGE_POOL_REQUEST";
type DELETE_USAGE_POOL_SUCCESS_TYPE = "DELETE_USAGE_POOL_SUCCESS";
type DELETE_USAGE_POOL_FAILURE_TYPE = "DELETE_USAGE_POOL_FAILURE";

type CREATE_NEW_USAGE_POOL_TYPE = "CREATE_NEW_USAGE_POOL";

type GET_MATCHING_SOURCES_REQUEST_TYPE = "GET_MATCHING_SOURCES_REQUEST";
type GET_MATCHING_SOURCES_SUCCESS_TYPE = "GET_MATCHING_SOURCES_SUCCESS";
type GET_MATCHING_SOURCES_FAILURE_TYPE = "GET_MATCHING_SOURCES_FAILURE";

type GET_SHORT_CUT_KEYS_REQUEST_TYPE = "GET_SHORT_CUT_KEYS_REQUEST";
type GET_SHORT_CUT_KEYS_SUCCESS_TYPE = "GET_SHORT_CUT_KEYS_SUCCESS";
type GET_SHORT_CUT_KEYS_FAILURE_TYPE = "GET_SHORT_CUT_KEYS_FAILURE";

type GET_DISTRIBUTION_REQUEST_TYPE = "GET_DISTRIBUTION_REQUEST";
type GET_DISTRIBUTION_SUCCESS_TYPE = "GET_DISTRIBUTION_SUCCESS";
type GET_DISTRIBUTION_FAILURE_TYPE = "GET_DISTRIBUTION_FAILURE";

type UPDATE_DISTRIBUTION_FIELD_TYPE = "UPDATE_DISTRIBUTION_FIELD";

type SAVE_DISTRIBUTION_REQUEST_TYPE = "SAVE_DISTRIBUTION_REQUEST";
type SAVE_DISTRIBUTION_SUCCESS_TYPE = "SAVE_DISTRIBUTION_SUCCESS";
type SAVE_DISTRIBUTION_FAILURE_TYPE = "SAVE_DISTRIBUTION_FAILURE";

type CLEAR_PACKAGES_REQUEST_TYPE = "CLEAR_PACKAGES_REQUEST";
type CLEAR_PACKAGES_SUCCESS_TYPE = "CLEAR_PACKAGES_SUCCESS";
type CLEAR_PACKAGES_FAILURE_TYPE = "CLEAR_PACKAGES_FAILURE";

type ALLOCATE_DISTRIBUTION_POOLS_REQUEST_TYPE = "ALLOCATE_DISTRIBUTION_POOLS_REQUEST";
type ALLOCATE_DISTRIBUTION_POOLS_SUCCESS_TYPE = "ALLOCATE_DISTRIBUTION_POOLS_SUCCESS";
type ALLOCATE_DISTRIBUTION_POOLS_FAILURE_TYPE = "ALLOCATE_DISTRIBUTION_POOLS_FAILURE";

type RUN_QA_DISTRIBUTION_POOLS_REQUEST_TYPE = "RUN_QA_DISTRIBUTION_POOLS_REQUEST";
type RUN_QA_DISTRIBUTION_POOLS_SUCCESS_TYPE = "RUN_QA_DISTRIBUTION_POOLS_SUCCESS";
type RUN_QA_DISTRIBUTION_POOLS_FAILURE_TYPE = "RUN_QA_DISTRIBUTION_POOLS_FAILURE";



type EXPORT_DISTRIBUTION_POOLS_REQUEST_TYPE = "EXPORT_DISTRIBUTION_POOLS_REQUEST";
type EXPORT_DISTRIBUTION_POOLS_SUCCESS_TYPE = "EXPORT_DISTRIBUTION_POOLS_SUCCESS";
type EXPORT_DISTRIBUTION_POOLS_FAILURE_TYPE = "EXPORT_DISTRIBUTION_POOLS_FAILURE";

type UPDATE_SELECTION_CRITERIA_TYPE = "UPDATE_SELECTION_CRITERIA";
type UPDATE_REPRESENTATION_SELECTOR_TYPE = "UPDATE_REPRESENTATION_SELECTOR";

type UPDATE_FOREIGN_SOCIETY_INFO_TYPE = "UPDATE_FOREIGN_SOCIETY_INFO"

type GET_PAYMENTRUN_REQUEST_TYPE = "GET_PAYMENTRUN_REQUEST";
type GET_PAYMENTRUN_SUCCESS_TYPE = "GET_PAYMENTRUN_SUCCESS";
type GET_PAYMENTRUN_FAILURE_TYPE = "GET_PAYMENTRUN_FAILURE";

type GET_PAYMENTRUN_ID_REQUEST_TYPE = "GET_PAYMENTRUN_ID_REQUEST";
type GET_PAYMENTRUN_ID_SUCCESS_TYPE = "GET_PAYMENTRUN_ID_SUCCESS";
type GET_PAYMENTRUN_ID_FAILURE_TYPE = "GET_PAYMENTRUN_ID_FAILURE";

type SAVE_PAYMENTRUN_REQUEST_TYPE = "SAVE_PAYMENTRUN_REQUEST";
type SAVE_PAYMENTRUN_SUCCESS_TYPE = "SAVE_PAYMENTRUN_SUCCESS";
type SAVE_PAYMENTRUN_FAILURE_TYPE = "SAVE_PAYMENTRUN_FAILURE";

type DELETE_PAYMENTRUN_REQUEST_TYPE = "DELETE_PAYMENTRUN_REQUEST";
type DELETE_PAYMENTRUN_SUCCESS_TYPE = "DELETE_PAYMENTRUN_SUCCESS";
type DELETE_PAYMENTRUN_FAILURE_TYPE = "DELETE_PAYMENTRUN_FAILURE";

type UPDATE_PAYMENTRUN_FIELD_TYPE = "UPDATE_PAYMENTRUN_FIELD"

type DELETE_LICENSE_REQUEST_TYPE = "DELETE_LICENSE_REQUEST";
type DELETE_LICENSE_SUCCESS_TYPE = "DELETE_LICENSE_SUCCESS";
type DELETE_LICENSE_FAILURE_TYPE = "DELETE_LICENSE_FAILURE";

type GET_SHOPPING_CART_PARAMETERS_SUCCESS_TYPE = "GET_SHOPPING_CART_PARAMETERS_SUCCESS";
type GET_SHOPPING_CART_PARAMETERS_FAILURE_TYPE = "GET_SHOPPING_CART_PARAMETERS_FAILURE";

type UPDATE_LICENSE_USER_TYPE = "UPDATE_LICENSE_USER";

//CLAIM
type UPDATE_CLAIM_FIELD_TYPE = "UPDATE_CLAIM_FIELD";

//REPERTOIRE
type REDUX_STORAGE_LOAD_TYPE = "REDUX_STORAGE_LOAD";
type ADD_TAB_TYPE = "ADD_TAB";
type REPLACE_TAB_TYPE = "REPLACE_TAB";
type REMOVE_TAB_TYPE = "REMOVE_TAB";
type REMOVE_PANE_TWO_TYPE = "REMOVE_PANE_TWO";
type OPEN_TAB_TYPE = "OPEN_TAB";
type SET_ACTIVE_PANE_TYPE = "SET_ACTIVE_PANE";
type SET_DATA_SOURCE_TYPE = "SET_DATA_SOURCE";
type SET_AGREEMENT_DATA_SOURCE_TYPE = "SET_AGREEMENT_DATA_SOURCE";
type SET_AGREEMENT_SEARCH_TYPE_TYPE = "SET_AGREEMENT_SEARCH_TYPE";
type SET_PRODUCT_DATA_SOURCE_TYPE = "SET_PRODUCT_DATA_SOURCE";
type SET_ADJUSTMENT_DATA_SOURCE_TYPE = "SET_ADJUSTMENT_DATA_SOURCE";
type ADD_SET_TYPES_TYPE = "ADD_SET_TYPES";
type REMOVE_SET_TYPES_TYPE = "REMOVE_SET_TYPES";
type CLEAR_SET_TYPES_TYPE = "CLEAR_SET_TYPES";
type UPDATE_WORK_FIELD_TYPE = "UPDATE_WORK_FIELD";
type UPDATE_WORK_FIELDS_TYPE = "UPDATE_WORK_FIELDS";
type SORT_WORK_FIELD_DATA_TYPE = "SORT_WORK_FIELD_DATA";
type UPDATE_AGREEMENT_FIELD_TYPE = "UPDATE_AGREEMENT_FIELD";
type UPDATE_INTERESTED_PARTY_FIELD_TYPE = "UPDATE_INTERESTED_PARTY_FIELD";
type UPDATE_REPRESENTATION_FIELD_TYPE = "UPDATE_REPRESENTATION_FIELD";
type UPDATE_INTERESTED_PARTY_IP_BASE_NUMBER_TYPE = "UPDATE_INTERESTED_PARTY_IP_BASE_NUMBER";
type SORT_AGREEMENT_FIELD_DATA_TYPE = "SORT_AGREEMENT_FIELD_DATA";
type SORT_WEIGHT_SETTINGS_FIELD_DATA_TYPE = "SORT_WEIGHT_SETTINGS_FIELD_DATA";
type SORT_DISTRIBUTION_POOL_FIELD_DATA_TYPE = "SORT_DISTRIBUTION_POOL_FIELD_DATA";

type SORT_INTERESTED_PARTY_FIELD_DATA_TYPE = "SORT_INTERESTED_PARTY_FIELD_DATA";
type UPDATE_PRODUCT_FIELD_TYPE = "UPDATE_PRODUCT_FIELD";
type UPDATE_PRODUCT_FIELDS_TYPE = "UPDATE_PRODUCT_FIELDS";
type SORT_PRODUCT_FIELD_DATA_TYPE = "SORT_PRODUCT_FIELD_DATA";
type UPDATE_ARTIST_FIELD_TYPE = "UPDATE_ARTIST_FIELD";
type SORT_ARTIST_FIELD_DATA_TYPE = "SORT_ARTIST_FIELD_DATA";
type UPDATE_SCHEDULED_JOB_FIELD_TYPE = "UPDATE_SCHEDULED_JOB_FIELD";

type FETCH_REPERTOIRE_COMPONENT_DATA_REQUEST_TYPE = "FETCH_REPERTOIRE_COMPONENT_DATA_REQUEST";
type FETCH_REPERTOIRE_COMPONENT_DATA_SUCCESS_TYPE = "FETCH_REPERTOIRE_COMPONENT_DATA_SUCCESS";
type FETCH_REPERTOIRE_COMPONENT_DATA_FAILURE_TYPE = "FETCH_REPERTOIRE_COMPONENT_DATA_FAILURE";

type SEARCH_WORKS_REQUEST_TYPE = "SEARCH_WORKS_REQUEST";
type SEARCH_WORKS_SUCCESS_TYPE = "SEARCH_WORKS_SUCCESS";
type SEARCH_WORKS_FAILURE_TYPE = "SEARCH_WORKS_FAILURE";

type SEARCH_ARTISTS_REQUEST_TYPE = "SEARCH_ARTISTS_REQUEST";
type SEARCH_ARTISTS_SUCCESS_TYPE = "SEARCH_ARTISTS_SUCCESS";
type SEARCH_ARTISTS_FAILURE_TYPE = "SEARCH_ARTISTS_FAILURE";

type SEARCH_CONTRIBUTORS_REQUEST_TYPE = "SEARCH_CONTRIBUTORS_REQUEST";
type SEARCH_CONTRIBUTORS_SUCCESS_TYPE = "SEARCH_CONTRIBUTORS_SUCCESS";
type SEARCH_CONTRIBUTORS_FAILURE_TYPE = "SEARCH_CONTRIBUTORS_FAILURE";

type SEARCH_AGREEMENTS_REQUEST_TYPE = "SEARCH_AGREEMENTS_REQUEST";
type SEARCH_AGREEMENTS_SUCCESS_TYPE = "SEARCH_AGREEMENTS_SUCESS";
type SEARCH_AGREEMENTS_FAILURE_TYPE = "SEARCH_AGREEMENTS_FAILURE";

type SEARCH_IPS_REQUEST_TYPE = "SEARCH_IPS_REQUEST";
type SEARCH_IPS_SUCCESS_TYPE = "SEARCH_IPS_SUCESS";
type SEARCH_IPS_FAILURE_TYPE = "SEARCH_IPS_FAILURE";

type SEARCH_PRODUCTS_REQUEST_TYPE = "SEARCH_PRODUCTS_REQUEST";
type SEARCH_PRODUCTS_SUCCESS_TYPE = "SEARCH_PRODUCTS_SUCESS";
type SEARCH_PRODUCTS_FAILURE_TYPE = "SEARCH_PRODUCTS_FAILURE";

type SEARCH_USAGE_POOLS_REQUEST_TYPE = "SEARCH_POOLS_REQUEST";
type SEARCH_USAGE_POOLS_SUCCESS_TYPE = "SEARCH_POOLS_SUCCESS";
type SEARCH_USAGE_POOLS_FAILURE_TYPE = "SEARCH_POOLS_FAILURE";

type SEARCH_DISTRIBUTIONS_REQUEST_TYPE = "SEARCH_DISTRIBUTIONS_REQUEST";
type SEARCH_DISTRIBUTIONS_SUCCESS_TYPE = "SEARCH_DISTRIBUTIONS_SUCCESS";
type SEARCH_DISTRIBUTIONS_FAILURE_TYPE = "SEARCH_DISTRIBUTIONS_FAILURE";

type SEARCH_LAST_PERCENTAGE_REQUEST_TYPE = "SEARCH_LAST_PERCENTAGE_REQUEST";
type SEARCH_LAST_PERCENTAGE_SUCCESS_TYPE = "SEARCH_LAST_PERCENTAGE_SUCCESS";
type SEARCH_LAST_PERCENTAGE_FAILURE_TYPE = "SEARCH_LAST_PERCENTAGE_FAILURE";

type SEARCH_ADJUSTMENTS_REQUEST_TYPE = "SEARCH_ADJUSTMENTS_REQUEST";
type SEARCH_ADJUSTMENTS_SUCCESS_TYPE = "SEARCH_ADJUSTMENTS_SUCCESS";
type SEARCH_ADJUSTMENTS_FAILURE_TYPE = "SEARCH_ADJUSTMENTS_FAILURE";

type SEARCH_CLAIM_REQUEST_TYPE = "SEARCH_CLAIM_REQUEST";
type SEARCH_CLAIM_SUCCESS_TYPE = "SEARCH_CLAIM_SUCCESS";
type SEARCH_CLAIM_FAILURE_TYPE = "SEARCH_CLAIM_FAILURE";

type GET_DISTRIBUTION_TYPES_REQUEST_TYPE = "GET_DISTRIBUTION_TYPES_REQUEST";
type GET_DISTRIBUTION_TYPES_SUCCESS_TYPE = "GET_DISTRIBUTION_TYPES_SUCCESS";
type GET_DISTRIBUTION_TYPES_FAILURE_TYPE = "GET_DISTRIBUTION_TYPES_FAILURE";

type GET_DISTRIBUTION_SUB_TYPES_REQUEST_TYPE = "GET_DISTRIBUTION_SUB_TYPES_REQUEST";
type GET_DISTRIBUTION_SUB_TYPES_SUCCESS_TYPE = "GET_DISTRIBUTION_SUB_TYPES_SUCCESS";
type GET_DISTRIBUTION_SUB_TYPES_FAILURE_TYPE = "GET_DISTRIBUTION_SUB_TYPES_FAILURE";

type CLEAR_MODAL_SEARCH_RESULTS_TYPE = "CLEAR_MODAL_SEARCH_RESULTS"

type GET_WORK_DETAILS_REQUEST_TYPE = "GET_WORK_DETAILS_REQUEST";
type GET_WORK_DETAILS_SUCCESS_TYPE = "GET_WORK_DETAILS_SUCCESS";
type GET_WORK_DETAILS_FAILURE_TYPE = "GET_WORK_DETAILS_FAILURE";

type REFRESH_WORK_DETAILS_REQUEST_TYPE = "REFRESH_WORK_DETAILS_REQUEST";
type REFRESH_WORK_DETAILS_SUCCESS_TYPE = "REFRESH_WORK_DETAILS_SUCCESS";
type REFRESH_WORK_DETAILS_FAILURE_TYPE = "REFRESH_WORK_DETAILS_FAILURE";

type GET_MATCHING_WORK_INFO_REQUEST_TYPE = "GET_MATCHING_WORK_INFO_REQUEST";
type GET_MATCHING_WORK_INFO_SUCCESS_TYPE = "GET_MATCHING_WORK_INFO_SUCCESS";
type GET_MATCHING_WORK_INFO_FAILURE_TYPE = "GET_MATCHING_WORK_INFO_FAILURE";

type REFRESH_PRODUCT_DETAILS_REQUEST_TYPE = "REFRESH_PRODUCT_DETAILS_REQUEST";
type REFRESH_PRODUCT_DETAILS_SUCCESS_TYPE = "REFRESH_PRODUCT_DETAILS_SUCCESS";
type REFRESH_PRODUCT_DETAILS_FAILURE_TYPE = "REFRESH_PRODUCT_DETAILS_FAILURE";

type REFRESH_USAGE_DETAILS_REQUEST_TYPE = "REFRESH_USAGE_DETAILS_REQUEST";
type REFRESH_USAGE_DETAILS_SUCCESS_TYPE = "REFRESH_USAGE_DETAILS_SUCCESS";
type REFRESH_USAGE_DETAILS_FAILURE_TYPE = "REFRESH_USAGE_DETAILS_FAILURE";

type GET_AGREEMENT_DETAILS_REQUEST_TYPE = "GET_AGREEMENT_DETAILS_REQUEST";
type GET_AGREEMENT_DETAILS_SUCCESS_TYPE = "GET_AGREEMENT_DETAILS_SUCCESS";
type GET_AGREEMENT_DETAILS_FAILURE_TYPE = "GET_AGREEMENT_DETAILS_FAILURE";


type GET_IP_DETAILS_REQUEST_TYPE = "GET_IP_DETAILS_REQUEST";
type GET_IP_DETAILS_SUCCESS_TYPE = "GET_IP_DETAILS_SUCCESS";
type GET_IP_DETAILS_FAILURE_TYPE = "GET_IP_DETAILS_FAILURE";

type GET_PRODUCT_DETAILS_REQUEST_TYPE = "GET_PRODUCT_DETAILS_REQUEST";
type GET_PRODUCT_DETAILS_SUCCESS_TYPE = "GET_PRODUCT_DETAILS_SUCCESS";
type GET_PRODUCT_DETAILS_FAILURE_TYPE = "GET_PRODUCT_DETAILS_FAILURE";

type GET_ARTIST_DETAILS_REQUEST_TYPE = "GET_ARTIST_DETAILS_REQUEST";
type GET_ARTIST_DETAILS_SUCCESS_TYPE = "GET_ARTIST_DETAILS_SUCCESS";
type GET_ARTIST_DETAILS_FAILURE_TYPE = "GET_ARTIST_DETAILS_FAILURE";

type GET_ALL_PACKAGES_DETAILS_REQUEST_TYPE = "GET_ALL_PACKAGES_DETAILS_REQUEST";
type GET_ALL_PACKAGES_DETAILS_SUCCESS_TYPE = "GET_ALL_PACKAGES_DETAILS_SUCCESS";
type GET_ALL_PACKAGES_DETAILS_FAILURE_TYPE = "GET_ALL_PACKAGES_DETAILS_FAILURE";

type ADD_ARTIST_TO_WORK_TYPE = "ADD_ARTIST_TO_WORK";
type ADD_ARTIST_TO_PRODUCT_TYPE = "ADD_ARTIST_TO_PRODUCT";
type ADD_WORK_TO_PRODUCT_TYPE = "ADD_WORK_TO_PRODUCT";
type ADD_WORK_TO_LICENSE_REQUEST_TYPE = "ADD_WORK_TO_LICENSE_REQUEST";
type ADD_WORK_TO_CLAIM_REQUEST_TYPE = "ADD_WORK_TO_CLAIM_REQUEST";
type SEARCH_SUBJECT_SUCCESS_TYPE = "SEARCH_SUBJECT_SUCCESS";
type UPDATE_SUBJECT_CODE_TYPE = "UPDATE_SUBJECT_CODE";

type UPDATE_WORK_CONTRIBUTOR_IP_REQUEST_TYPE = "UPDATE_WORK_CONTRIBUTOR_IP_REQUEST";
type UPDATE_WORK_CONTRIBUTOR_IP_SUCCESS_TYPE = "UPDATE_WORK_CONTRIBUTOR_IP_SUCCESS";
type UPDATE_WORK_CONTRIBUTOR_IP_FAILURE_TYPE = "UPDATE_WORK_CONTRIBUTOR_IP_FAILURE";



type UPDATE_PRODUCT_CONTRIBUTOR_IP_TYPE = "UPDATE_PRODUCT_CONTRIBUTOR_IP";

type UPDATE_AGREEMENT_IP_TYPE = "UPDATE_AGREEMENT_IP";
type UPDATE_AGREEMENT_WORK_TYPE = "UPDATE_AGREEMENT_WORK";
type UPDATE_AGREEMENT_CARVEOUT_PRODUCT_TYPE = "UPDATE_AGREEMENT_CARVEOUT_PRODUCT";

type UPDATE_MATCHING_WORK_TYPE = "UPDATE_MATCHING_WORK";
type UPDATE_MATCHING_PRODUCT_TYPE = "UPDATE_MATCHING_PRODUCT";
type UPDATE_RELATED_WORK_TYPE = "UPDATE_RELATED_WORK";
type UPDATE_WORK_SOURCE_IP_TYPE = "UPDATE_WORK_SOURCE_IP"

type COPY_EXISTING_WORK_REQUEST_TYPE = "COPY_EXISTING_WORK_REQUEST";
type COPY_EXISTING_WORK_SUCCESS_TYPE = "COPY_EXISTING_WORK_SUCCESS";

type UNDO_WORK_CHANGES_REQUEST_TYPE = "UNDO_WORK_CHANGES_REQUEST";
type UNDO_WORK_CHANGES_SUCCESS_TYPE = "UNDO_WORK_CHANGES_SUCCESS";
type UNDO_WORK_CHANGES_FAILURE_TYPE = "UNDO_WORK_CHANGES_FAILURE";

type MERGE_MATCHING_WORK_REQUEST_TYPE = "MERGE_MATCHING_WORK_REQUEST";
type MERGE_MATCHING_WORK_SUCCESS_TYPE = "MERGE_MATCHING_WORK_SUCCESS";
type MERGE_MATCHING_WORK_FAILURE_TYPE = "MERGE_MATCHING_WORK_FAILURE";

type MERGE_MATCHING_INTERESTED_PARTY_REQUEST_TYPE = "MERGE_MATCHING_INTERESTED_PARTY_REQUEST";
type MERGE_MATCHING_INTERESTED_PARTY_SUCCESS_TYPE = "MERGE_MATCHING_INTERESTED_PARTY_SUCCESS";
type MERGE_MATCHING_INTERESTED_PARTY_FAILURE_TYPE = "MERGE_MATCHING_INTERESTED_PARTY_FAILURE";

type MERGE_REPERTOIRE_WORK_REQUEST_TYPE = "MERGE_REPERTOIRE_WORK_REQUEST";
type MERGE_REPERTOIRE_WORK_SUCCESS_TYPE = "MERGE_REPERTOIRE_WORK_SUCCESS";
type MERGE_REPERTOIRE_WORK_FAILURE_TYPE = "MERGE_REPERTOIRE_WORK_FAILURE";

type MERGE_MATCHING_PRODUCT_REQUEST_TYPE = "MERGE_MATCHING_PRODUCT_REQUEST";
type MERGE_MATCHING_PRODUCT_SUCCESS_TYPE = "MERGE_MATCHING_PRODUCT_SUCCESS";
type MERGE_MATCHING_PRODUCT_FAILURE_TYPE = "MERGE_MATCHING_PRODUCT_FAILURE";

type MERGE_REPERTOIRE_PRODUCT_REQUEST_TYPE = "MERGE_REPERTOIRE_PRODUCT_REQUEST";
type MERGE_REPERTOIRE_PRODUCT_SUCCESS_TYPE = "MERGE_REPERTOIRE_PRODUCT_SUCCESS";
type MERGE_REPERTOIRE_PRODUCT_FAILURE_TYPE = "MERGE_REPERTOIRE_PRODUCT_FAILURE";

type DELETE_WORK_REQUEST_TYPE = "DELETE_WORK_REQUEST";
type DELETE_WORK_SUCCESS_TYPE = "DELETE_WORK_SUCCESS";
type DELETE_WORK_FAILURE_TYPE = "DELETE_WORK_FAILURE";

type CREATE_NEW_WORK_TYPE = "CREATE_NEW_WORK";
type CREATE_NEW_REPRESENTATION_TYPE = "CREATE_NEW_REPRESENTATION";
type CREATE_NEW_AGREEMENT_TYPE = "CREATE_NEW_AGREEMENT";
type CREATE_NEW_ACCOUNT_TYPE = "CREATE_NEW_ACCOUNT";
type CREATE_NEW_PRODUCT_TYPE = "CREATE_NEW_PRODUCT";
type CREATE_NEW_ARTIST_TYPE = "CREATE_NEW_ARTIST";
type CREATE_NEW_DISTRIBUTION_TYPE = "CREATE_NEW_DISTRIBUTION";
type CREATE_NEW_PAYMENTRUN_TYPE = "CREATE_NEW_PAYMENTRUN";
type CREATE_NEW_MY_PACKAGES_TYPE = "CREATE_NEW_MY_PACKAGES";
type CREATE_NEW_CLAIM_TYPE = "CREATE_NEW_CLAIM";
type CREATE_NEW_LICENSE_TYPE = "CREATE_NEW_LICENSE";
type UPDATE_LICENSE_WORK_ITEM_TYPE = "UPDATE_LICENSE_WORK_ITEM";

type SAVE_WORK_REQUEST_TYPE = "SAVE_WORK_REQUEST";
type SAVE_WORK_SUCCESS_TYPE = "SAVE_WORK_SUCCESS";
type SAVE_WORK_FAILURE_TYPE = "SAVE_WORK_FAILURE";
type SAVE_WORK_NUMBERS_ADDITIONAL_SUCCESS_TYPE = "SAVE_WORK_NUMBERS_ADDITIONAL_SUCCESS";
type SAVE_WORK_NUMBERS_ADDITIONAL_FAILURE_TYPE = "SAVE_WORK_NUMBERS_ADDITIONAL_FAILURE";
type GET_WORK_NUMBERS_ADDITIONAL_PAGE_DETAILS_SUCCESS_TYPE = "GET_WORK_NUMBERS_ADDITIONAL_PAGE_DETAILS_SUCCESS";
type GET_WORK_NUMBERS_ADDITIONAL_PAGE_DETAILS_FAILURE_TYPE = "GET_WORK_NUMBERS_ADDITIONAL_PAGE_DETAILS_FAILURE";

type AUTO_MATCH_WORK_TYPE = "AUTO_MATCH_WORK";

type RESET_MESSAGE_BANNER_TYPE = "RESET_MESSAGE_BANNER";

type SAVE_AGREEMENT_REQUEST_TYPE = "SAVE_AGREEMENT_REQUEST";
type SAVE_AGREEMENT_SUCCESS_TYPE = "SAVE_AGREEMENT_SUCCESS";
type SAVE_AGREEMENT_FAILURE_TYPE = "SAVE_AGREEMENT_FAILURE";

type COPY_AGREEMENT_TYPE = "COPY_AGREEMENT";

type DELETE_AGREEMENT_REQUEST_TYPE = "DELETE_AGREEMENT_REQUEST";
type DELETE_AGREEMENT_SUCCESS_TYPE = "DELETE_AGREEMENT_SUCCESS";
type DELETE_AGREEMENT_FAILURE_TYPE = "DELETE_AGREEMENT_FAILURE";

type MERGE_AGREEMENT_REQUEST_TYPE = "MERGE_AGREEMENT_REQUEST";
type MERGE_AGREEMENT_SUCCESS_TYPE = "MERGE_AGREEMENT_SUCCESS";
type MERGE_AGREEMENT_FAILURE_TYPE = "MERGE_AGREEMENT_FAILURE";

type DELETE_DISTRIBUTION_REQUEST_TYPE = "DELETE_DISTRIBUTION_REQUEST";
type DELETE_DISTRIBUTION_SUCCESS_TYPE = "DELETE_DISTRIBUTION_SUCCESS";
type DELETE_DISTRIBUTION_FAILURE_TYPE = "DELETE_DISTRIBUTION_FAILURE";

type COPY_POOL_TYPE = "COPY_POOL";
type COPY_DISTRIBUTION_TYPE = "COPY_DISTRIBUTION";
type COPY_PAYMENTRUN_TYPE = "COPY_PAYMENTRUN";
type COPY_LICENSE_TYPE = "COPY_LICENSE";

type SAVE_PRODUCT_REQUEST_TYPE = "SAVE_PRODUCT_REQUEST";
type SAVE_PRODUCT_SUCCESS_TYPE = "SAVE_PRODUCT_SUCCESS";
type SAVE_PRODUCT_FAILURE_TYPE = "SAVE_PRODUCT_FAILURE";

type COPY_PRODUCT_TYPE = "COPY_PRODUCT";

type DELETE_PRODUCT_REQUEST_TYPE = "DELETE_PRODUCT_REQUEST";
type DELETE_PRODUCT_SUCCESS_TYPE = "DELETE_PRODUCT_SUCCESS";
type DELETE_PRODUCT_FAILURE_TYPE = "DELETE_PRODUCT_FAILURE";

type UPDATE_PRODUCT_SOURCE_IP_TYPE = "UPDATE_PRODUCT_SOURCE_IP";

type SAVE_ACCOUNT_REQUEST_TYPE = "SAVE_ACCOUNT_REQUEST";
type SAVE_ACCOUNT_SUCCESS_TYPE = "SAVE_ACCOUNT_SUCCESS";
type SAVE_ACCOUNT_FAILURE_TYPE = "SAVE_ACCOUNT_FAILURE";

type SAVE_ARTIST_REQUEST_TYPE = "SAVE_ARTIST_REQUEST";
type SAVE_ARTIST_SUCCESS_TYPE = "SAVE_ARTIST_SUCCESS";
type SAVE_ARTIST_FAILURE_TYPE = "SAVE_ARTIST_FAILURE";

type SAVE_CLAIM_REQUEST_TYPE = "SAVE_CLAIM_REQUEST";
type SAVE_CLAIM_SUCCESS_TYPE = "SAVE_CLAIM_SUCCESS";
type SAVE_CLAIM_FAILURE_TYPE = "SAVE_CLAIM_FAILURE";

type OPEN_ACCORDION_TYPE = "OPEN_ACCORDION";
type CLOSE_ACCORDION_TYPE = "CLOSE_ACCORDION";

type OPEN_IP_MAINTENANCE_ACCORDION_TYPE = "OPEN_IP_MAINTENANCE_ACCORDION";
type CLOSE_IP_MAINTENANCE_ACCORDION_TYPE = "CLOSE_IP_MAINTENANCE_ACCORDION";

type SET_CHANGES_MADE_TYPE = "SET_CHANGES_MADE";

type GET_WORK_MATCH_CONNECTOR_REQUEST_TYPE = "GET_WORK_MATCH_CONNECTOR_REQUEST";
type GET_WORK_MATCH_CONNECTOR_SUCCESS_TYPE = "GET_WORK_MATCH_CONNECTOR_SUCCESS";
type GET_WORK_MATCH_CONNECTOR_FAILED_TYPE = "GET_WORK_MATCH_CONNECTOR_FAILED";

type POST_WORK_BATCH_REQUEST_TYPE = "POST_WORK_BATCH_REQUEST";
type POST_WORK_BATCH_SUCCESS_TYPE = "POST_WORK_BATCH_SUCCESS";
type POST_WORK_BATCH_FAILED_TYPE = "POST_WORK_BATCH_FAILED";

type MATCH_WORK_BATCH_REQUEST_TYPE = "MATCH_WORK_BATCH_REQUEST";
type MATCH_WORK_BATCH_SUCCESS_TYPE = "MATCH_WORK_BATCH_SUCCESS";
type MATCH_WORK_BATCH_FAILED_TYPE = "MATCH_WORK_BATCH_FAILED";

type POST_PRODUCT_BATCH_REQUEST_TYPE = "POST_PRODUCT_BATCH_REQUEST";
type POST_PRODUCT_BATCH_SUCCESS_TYPE = "POST_PRODUCT_BATCH_SUCCESS";
type POST_PRODUCT_BATCH_FAILED_TYPE = "POST_PRODUCT_BATCH_FAILED";

type MATCH_PRODUCT_BATCH_REQUEST_TYPE = "MATCH_PRODUCT_BATCH_REQUEST";
type MATCH_PRODUCT_BATCH_SUCCESS_TYPE = "MATCH_PRODUCT_BATCH_SUCCESS";
type MATCH_PRODUCT_BATCH_FAILED_TYPE = "MATCH_PRODUCT_BATCH_FAILED";

type POST_AGREEMENT_BATCH_REQUEST_TYPE = "POST_AGREEMENT_BATCH_REQUEST";
type POST_AGREEMENT_BATCH_SUCCESS_TYPE = "POST_AGREEMENT_BATCH_SUCCESS";
type POST_AGREEMENT_BATCH_FAILED_TYPE = "POST_AGREEMENT_BATCH_FAILED";

type POST_ADJUSTMENT_BATCH_REQUEST_TYPE = "POST_ADJUSTMENT_BATCH_REQUEST";
type POST_ADJUSTMENT_BATCH_SUCCESS_TYPE = "POST_ADJUSTMENT_BATCH_SUCCESS";
type POST_ADJUSTMENT_BATCH_FAILED_TYPE = "POST_ADJUSTMENT_BATCH_FAILED";

type SWITCH_SET_TYPE_GROUP_TYPE = "SWITCH_SET_TYPE_GROUP";
type UPDATE_CONTRIBUTOR_SET_TYPE_TYPE = "UPDATE_CONTRIBUTOR_SET_TYPE";

type UNDO_AGREEMENT_CHANGES_REQUEST_TYPE = "UNDO_AGREEMENT_CHANGES_REQUEST";
type UNDO_AGREEMENT_CHANGES_SUCCESS_TYPE = "UNDO_AGREEMENT_CHANGES_SUCCESS";
type UNDO_AGREEMENT_CHANGES_FAILURE_TYPE = "UNDO_AGREEMENT_CHANGES_FAILURE";

type UNDO_DISTRIBUTION_CHANGES_REQUEST_TYPE = "UNDO_DISTRIBUTION_CHANGES_REQUEST";
type UNDO_DISTRIBUTION_CHANGES_SUCCESS_TYPE = "UNDO_DISTRIBUTION_CHANGES_SUCCESS";
type UNDO_DISTRIBUTION_CHANGES_FAILURE_TYPE = "UNDO_DISTRIBUTION_CHANGES_FAILURE";

type UNDO_IP_CHANGES_REQUEST_TYPE = "UNDO_IP_CHANGES_REQUEST";
type UNDO_IP_CHANGES_SUCCESS_TYPE = "UNDO_IP_CHANGES_SUCCESS";
type UNDO_IP_CHANGES_FAILURE_TYPE = "UNDO_IP_CHANGES_FAILURE";

type UNDO_PRODUCT_CHANGES_REQUEST_TYPE = "UNDO_PRODUCT_CHANGES_REQUEST";
type UNDO_PRODUCT_CHANGES_SUCCESS_TYPE = "UNDO_PRODUCT_CHANGES_SUCCESS";
type UNDO_PRODUCT_CHANGES_FAILURE_TYPE = "UNDO_PRODUCT_CHANGES_FAILURE";

type UNDO_ARTIST_CHANGES_REQUEST_TYPE = "UNDO_ARTIST_CHANGES_REQUEST";
type UNDO_ARTIST_CHANGES_SUCCESS_TYPE = "UNDO_ARTIST_CHANGES_SUCCESS";
type UNDO_ARTIST_CHANGES_FAILURE_TYPE = "UNDO_ARTIST_CHANGES_FAILURE";

type UNDO_PAYMENTRUN_CHANGES_REQUEST_TYPE = "UNDO_PAYMENTRUN_CHANGES_REQUEST";
type UNDO_PAYMENTRUN_CHANGES_SUCCESS_TYPE = "UNDO_PAYMENTRUN_CHANGES_SUCCESS";
type UNDO_PAYMENTRUN_CHANGES_FAILURE_TYPE = "UNDO_PAYMENTRUN_CHANGES_FAILURE";

type SEARCH_WORK_VERSION_HISTORY_REQUEST_TYPE = "SEARCH_WORK_VERSION_HISTORY_REQUEST";
type SEARCH_WORK_VERSION_HISTORY_SUCCESS_TYPE = "SEARCH_WORK_VERSION_HISTORY_SUCCESS";
type SEARCH_WORK_VERSION_HISTORY_FAILURE_TYPE = "SEARCH_WORK_VERSION_HISTORY_FAILURE";

type SEARCH_PRODUCT_VERSION_HISTORY_REQUEST_TYPE = "SEARCH_PRODUCT_VERSION_HISTORY_REQUEST";
type SEARCH_PRODUCT_VERSION_HISTORY_SUCCESS_TYPE = "SEARCH_PRODUCT_VERSION_HISTORY_SUCCESS";
type SEARCH_PRODUCT_VERSION_HISTORY_FAILURE_TYPE = "SEARCH_PRODUCT_VERSION_HISTORY_FAILURE";

type ADD_TO_PACKAGE_REQUEST_TYPE = "ADD_TO_PACKAGE_REQUEST";
type ADD_TO_PACKAGE_SUCCESS_TYPE = "ADD_TO_PACKAGE_SUCCESS";
type ADD_TO_PACKAGE_FAILURE_TYPE = "ADD_TO_PACKAGE_FAILURE";

type GENERATE_PACKAGE_SUCCESS_TYPE = "GENERATE_PACKAGE_SUCCESS";
type GENERATE_PACKAGE_FAILURE_TYPE = "GENERATE_PACKAGE_FAILURE";

type CLOSE_PACKAGE_FAILURE_TYPE = "CLOSE_PACKAGE_FAILURE";

type ADD_PACKAGE_NOTE_FAILURE_TYPE = "ADD_PACKAGE_NOTE_FAILURE";

type SEARCH_SOCIP_VERSION_HISTORY_REQUEST_TYPE = "SEARCH_SOCIP_VERSION_HISTORY_REQUEST";
type SEARCH_SOCIP_VERSION_HISTORY_SUCCESS_TYPE = "SEARCH_SOCIP_VERSION_HISTORY_SUCCESS";
type SEARCH_SOCIP_VERSION_HISTORY_FAILURE_TYPE = "SEARCH_SOCIP_VERSION_HISTORY_FAILURE";

type SEARCH_AGREEMENT_VERSION_HISTORY_REQUEST_TYPE = "SEARCH_AGREEMENT_VERSION_HISTORY_REQUEST";
type SEARCH_AGREEMENT_VERSION_HISTORY_SUCCESS_TYPE = "SEARCH_AGREEMENT_VERSION_HISTORY_SUCCESS";
type SEARCH_AGREEMENT_VERSION_HISTORY_FAILURE_TYPE = "SEARCH_AGREEMENT_VERSION_HISTORY_FAILURE";

type SEARCH_DISTRIBUTION_VERSION_HISTORY_REQUEST_TYPE = "SEARCH_DISTRIBUTION_VERSION_HISTORY_REQUEST";
type SEARCH_DISTRIBUTION_VERSION_HISTORY_SUCCESS_TYPE = "SEARCH_DISTRIBUTION_VERSION_HISTORY_SUCCESS";
type SEARCH_DISTRIBUTION_VERSION_HISTORY_FAILURE_TYPE = "SEARCH_DISTRIBUTION_VERSION_HISTORY_FAILURE";

type SEARCH_PAYMENTRUN_VERSION_HISTORY_REQUEST_TYPE = "SEARCH_PAYMENTRUN_VERSION_HISTORY_REQUEST";
type SEARCH_PAYMENTRUN_VERSION_HISTORY_SUCCESS_TYPE = "SEARCH_PAYMENTRUN_VERSION_HISTORY_SUCCESS";
type SEARCH_PAYMENTRUN_VERSION_HISTORY_FAILURE_TYPE = "SEARCH_PAYMENTRUN_VERSION_HISTORY_FAILURE";

type SEARCH_ADJUSTMENT_VERSION_HISTORY_REQUEST_TYPE = "SEARCH_ADJUSTMENT_VERSION_HISTORY_REQUEST";
type SEARCH_ADJUSTMENT_VERSION_HISTORY_SUCCESS_TYPE = "SEARCH_ADJUSTMENT_VERSION_HISTORY_SUCCESS";
type SEARCH_ADJUSTMENT_VERSION_HISTORY_FAILURE_TYPE = "SEARCH_ADJUSTMENT_VERSION_HISTORY_FAILURE";

type SEARCH_POOL_VERSION_HISTORY_REQUEST_TYPE = "SEARCH_POOL_VERSION_HISTORY_REQUEST";
type SEARCH_POOL_VERSION_HISTORY_SUCCESS_TYPE = "SEARCH_POOL_VERSION_HISTORY_SUCCESS";
type SEARCH_POOL_VERSION_HISTORY_FAILURE_TYPE = "SEARCH_POOL_VERSION_HISTORY_FAILURE";

type DELETE_ACCOUNT_REQUEST_TYPE = 'DELETE_ACCOUNT_REQUEST'
type DELETE_ACCOUNT_REQUEST_SUCCESS_TYPE = 'DELETE_ACCOUNT_REQUEST_SUCCESS'
type DELETE_ACCOUNT_REQUEST_FAILURE_TYPE = 'DELETE_ACCOUNT_REQUEST_FAILURE'

type GET_REPRESENTATION_GROUPS_TYPE = "GET_REPRESENTATION_GROUPS";
type GET_REPRESENTATION_GROUP_REQUEST_TYPE = "GET_REPRESENTATION_GROUP_REQUEST";
type GET_REPRESENTATION_GROUP_SUCCESS_TYPE = "GET_REPRESENTATION_GROUP_SUCCESS";
type GET_REPRESENTATION_GROUP_FAILURE_TYPE = "GET_REPRESENTATION_GROUP_FAILURE";

type DELETE_REPRESENTATIONS_TYPE = "DELETE_REPRESENTATIONS";
type DELETE_REPRESENTATIONS_UPDATE_TYPE = "DELETE_REPRESENTATIONS_UPDATE";
type DELETE_REPRESENTATIONS_REQUEST_TYPE = "DELETE_REPRESENTATIONS_REQUEST";
type DELETE_REPRESENTATIONS_REQUEST_SUCCESS_TYPE = "DELETE_REPRESENTATIONS_REQUEST_SUCCESS";
type DELETE_REPRESENTATIONS_REQUEST_FAILURE_TYPE = "DELETE_REPRESENTATIONS_REQUEST_FAILURE";

type ADD_REPRESENTATION_REQUEST_TYPE = "ADD_REPRESENTATION_REQUEST";
type ADD_REPRESENTATION_REQUEST_SUCCESS_TYPE = "ADD_REPRESENTATION_REQUEST_SUCCESS";
type ADD_REPRESENTATION_REQUEST_FAILURE_TYPE = "ADD_REPRESENTATION_REQUEST_FAILURE";
type ADD_NEW_REPRESENTATION_TYPE = "ADD_NEW_REPRESENTATION";

type IP_TRANSFER_JOB_REQUEST_TYPE = "IP_TRANSFER_JOB_REQUEST";
type IP_TRANSFER_JOB_REQUEST_SUCCESS_TYPE = "IP_TRANSFER_JOB_REQUEST_SUCCESS";
type IP_TRANSFER_JOB_REQUEST_FAILURE_TYPE = "IP_TRANSFER_JOB_REQUEST_FAILURE";

type SET_WORK_GROUP_MAINTENANCE_STATE_TYPE = "SET_WORK_GROUP_MAINTENANCE_STATE";

type DELETE_ARTIST_REQUEST_TYPE = 'DELETE_ARTIST_REQUEST'
type DELETE_ARTIST_REQUEST_SUCCESS_TYPE = 'DELETE_ARTIST_REQUEST_SUCCESS'
type DELETE_ARTIST_REQUEST_FAILURE_TYPE = 'DELETE_ARTIST_REQUEST_FAILURE'

type UPDATE_IP_AGREEMENT_FILTER_STATE_TYPE = "UPDATE_IP_AGREEMENT_FILTER_STATE";
type FILTER_IP_AGREEMENT_SUCCESS_TYPE = 'FILTER_IP_AGREEMENT_SUCCESS'
type FILTER_IP_AGREEMENT_REQUEST_TYPE = 'FILTER_IP_AGREEMENT_REQUEST'

type UPDATE_IP_REPRESENTATION_FILTER_STATE_TYPE = "UPDATE_IP_REPRESENTATION_FILTER_STATE";
type FILTER_IP_REPRESENTATION_SUCCESS_TYPE = 'FILTER_IP_REPRESENTATION_SUCCESS'
type FILTER_IP_REPRESENTATION_REQUEST_TYPE = 'FILTER_IP_REPRESENTATION_REQUEST'

type CONTRIBUTORS_DISPLAY_SETTING_SUCCESS_TYPE = 'CONTRIBUTORS_DISPLAY_SETTING_SUCCESS'
type CONTRIBUTORS_DISPLAY_SETTING_REQUEST_TYPE = 'CONTRIBUTORS_DISPLAY_SETTING_REQUEST'
type CONTRIBUTORS_DISPLAY_SETTING_FAILURE_TYPE = 'CONTRIBUTORS_DISPLAY_SETTING_FAILURE'

type GENRE_CATEGORIES_SUCCESS_TYPE = 'GENRE_CATEGORIES_SUCCESS'
type GENRE_CATEGORIES_REQUEST_TYPE = 'GENRE_CATEGORIES_REQUEST'
type GENRE_CATEGORIES_FAILURE_TYPE = 'GENRE_CATEGORIES_FAILURE'

type FILTER_GENRE_CATEGORY_FLAG_REQUEST_TYPE = 'FILTER_GENRE_CATEGORY_FLAG_REQUEST'
type FILTER_GENRE_CATEGORY_FLAG_SUCCESS_TYPE = 'FILTER_GENRE_CATEGORY_FLAG_SUCCESS'
type FILTER_GENRE_CATEGORY_FLAG_FAILURE_TYPE = 'FILTER_GENRE_CATEGORY_FLAG_FAILURE'

type GENRE_CATEGORIESBYDOMORFOREIN_SUCCESS_TYPE = 'GENRE_CATEGORIESBYDOMORFOREIN_SUCCESS'
type GENRE_CATEGORIESBYDOMORFOREIN_REQUEST_TYPE = 'GENRE_CATEGORIESBYDOMORFOREIN_REQUEST'
type GENRE_CATEGORIESBYDOMORFOREIN_FAILURE_TYPE = 'GENRE_CATEGORIESBYDOMORFOREIN_FAILURE'

type READONLY_FLAGS_FIELD_SUCCESS_TYPE = 'READONLY_FLAGS_FIELD_SUCCESS'
type READONLY_FLAGS_FIELD_REQUEST_TYPE = 'READONLY_FLAGS_FIELD_REQUEST'
type READONLY_FLAGS_FIELD_FAILURE_TYPE = 'READONLY_FLAGS_FIELD_FAILURE'

type OTHER_INDICATORS_WORK_FLAG_TYPES_SUCCESS_TYPE = 'OTHER_INDICATORS_WORK_FLAG_TYPES_SUCCESS'
type OTHER_INDICATORS_WORK_FLAG_TYPES_REQUEST_TYPE = 'OTHER_INDICATORS_WORK_FLAG_TYPES_REQUEST'
type OTHER_INDICATORS_WORK_FLAG_TYPES_FAILURE_TYPE = 'OTHER_INDICATORS_WORK_FLAG_TYPES_FAILURE'

type READONLY_INDICATORS_WORK_FLAG_TYPES_SUCCESS_TYPE = 'READONLY_INDICATORS_WORK_FLAG_TYPES_SUCCESS'
type READONLY_INDICATORS_WORK_FLAG_TYPES_REQUEST_TYPE = 'READONLY_INDICATORS_WORK_FLAG_TYPES_REQUEST'
type READONLY_INDICATORS_WORK_FLAG_TYPES_FAILURE_TYPE = 'READONLY_INDICATORS_WORK_FLAG_TYPES_FAILURE'

type SET_EDITABLE_FIELDS_TAB_TYPE = 'SET_EDITABLE_FIELDS_TAB';
type UPDATE_EDITABLE_FIELD_TYPE = 'UPDATE_EDITABLE_FIELD';
type UPDATE_COMPONENT_FIELD_REQUEST_TYPE = 'UPDATE_COMPONENT_FIELD_REQUEST';
type UPDATE_COMPONENT_FIELD_SUCCESS_TYPE = 'UPDATE_COMPONENT_FIELD_SUCCESS';
type UPDATE_COMPONENT_FIELD_FAILURE_TYPE = 'UPDATE_COMPONENT_FIELD_FAILURE';
type UPDATE_MULTIPLE_COMPONENT_FIELD_SUCCESS_TYPE = 'UPDATE_MULTIPLE_COMPONENT_FIELD_SUCCESS';

type GET_FORMAT_FIELD_REQUEST_TYPE = 'GET_FIELD_FORMAT_REQUEST';
type GET_FORMAT_FIELD_SUCCESS_TYPE = 'GET_FIELD_SUCCESS_REQUEST';
type GET_FORMAT_FIELD_FAILURE_TYPE = 'GET_FIELD_FAILURE_REQUEST';

type APPLY_FORMATS_WORK_MAINTENANCE_TYPE = 'APPLY_FORMATS_WORK_MAINTENANCE';
type APPLY_FORMATS_PRODUCT_MAINTENANCE_TYPE = 'APPLY_FORMATS_PRODUCT_MAINTENANCE';

type EXPAND_WORK_RESULT_TYPE = 'EXPAND_WORK_RESULT';
type EXPAND_USAGE_DETAIL_TYPE = 'EXPAND_USAGE_DETAIL';
type EXPAND_MATCHING_WORK_TYPE = 'EXPAND_MATCHING_WORK';
type EXPAND_MATCHING_PRODUCT_TYPE = 'EXPAND_MATCHING_PRODUCT';
type EXPAND_PRODUCT_RESULT_TYPE = 'EXPAND_PRODUCT_RESULT';
type EXPAND_ALL_RESULTS_TYPE = 'EXPAND_ALL_RESULTS';

type ENABLE_CUSTOM_FIELD_TYPE = 'ENABLE_CUSTOM_FIELD';

type ADD_REPERTOIRE_ATTACHMENT_REQUEST_TYPE = 'ADD_REPERTOIRE_ATTACHMENT_REQUEST';
type ADD_REPERTOIRE_ATTACHMENT_SUCCESS_TYPE = 'ADD_REPERTOIRE_ATTACHMENT_SUCCESS';
type ADD_REPERTOIRE_ATTACHMENT_FAILURE_TYPE = 'ADD_REPERTOIRE_ATTACHMENT_FAILURE';

type REMOVE_WORK_ATTACHMENT_REQUEST_TYPE = 'REMOVE_WORK_ATTACHMENT_REQUEST';
type REMOVE_WORK_ATTACHMENT_SUCCESS_TYPE = 'REMOVE_WORK_ATTACHMENT_SUCCESS';
type REMOVE_WORK_ATTACHMENT_FAILURE_TYPE = 'REMOVE_WORK_ATTACHMENT_FAILURE';

type REMOVE_AGREEMENT_ATTACHMENT_REQUEST_TYPE = 'REMOVE_AGREEMENT_ATTACHMENT_REQUEST';
type REMOVE_AGREEMENT_ATTACHMENT_SUCCESS_TYPE = 'REMOVE_AGREEMENT_ATTACHMENT_SUCCESS';
type REMOVE_AGREEMENT_ATTACHMENT_FAILURE_TYPE = 'REMOVE_AGREEMENT_ATTACHMENT_FAILURE';

type REMOVE_PRODUCT_ATTACHMENT_REQUEST_TYPE = 'REMOVE_PRODUCT_ATTACHMENT_REQUEST';
type REMOVE_PRODUCT_ATTACHMENT_SUCCESS_TYPE = 'REMOVE_PRODUCT_ATTACHMENT_SUCCESS';
type REMOVE_PRODUCT_ATTACHMENT_FAILURE_TYPE = 'REMOVE_PRODUCT_ATTACHMENT_FAILURE';

type REMOVE_USAGE_ATTACHMENT_REQUEST_TYPE = 'REMOVE_USAGE_ATTACHMENT_REQUEST';
type REMOVE_USAGE_ATTACHMENT_SUCCESS_TYPE = 'REMOVE_USAGE_ATTACHMENT_SUCCESS';
type REMOVE_USAGE_ATTACHMENT_FAILURE_TYPE = 'REMOVE_USAGE_ATTACHMENT_FAILURE';

type DELETE_BLOB_REQUEST_TYPE = 'DELETE_BLOB_REQUEST';
type DELETE_BLOB_SUCCESS_TYPE = 'DELETE_BLOB_SUCCESS';
type DELETE_BLOB_FAILURE_TYPE = 'DELETE_BLOB_FAILURE';

type ADD_DROPPED_FILE_TYPE = 'ADD_DROPPED_FILE';
type SET_NEW_PROGRESS_UPLOAD_FILE_REPERTOIRE_PROCCESS_TYPE = "SET_NEW_PROGRESS_UPLOAD_FILE_REPERTOIRE_PROCCESS";
type CLEAN_DROPPED_FILES_STATE_TYPE = 'CLEAN_DROPPED_FILES_STATE'

type UPDATE_SET_TYPE_ON_BREAK_TYPE = 'UPDATE_SET_TYPE_ON_BREAK';

type SEARCH_WORKFLOW_REQUEST_TYPE = "SEARCH_WORKFLOW_REQUEST";
type SEARCH_WORKFLOW_SUCCESS_TYPE = "SEARCH_WORKFLOW_SUCCESS";
type SEARCH_WORKFLOW_FAILURE_TYPE = "SEARCH_WORKFLOW_FAILURE";

type SELECT_SINGLE_WORKFLOW_TYPE = 'SELECT_SINGLE_WORKFLOW';
type SELECT_ALL_WORKFLOW_TYPE = 'SELECT_ALL_WORKFLOW';
type MARK_SELECT_ALL_WORKFLOW_TYPE = 'MARK_SELECT_ALL_WORKFLOW';

type ASSIGN_WORKFLOWS_REQUEST_TYPE = "ASSIGN_WORKFLOWS_REQUEST";
type ASSIGN_WORKFLOWS_SUCCESS_TYPE = "ASSIGN_WORKFLOWS_SUCCESS";
type ASSIGN_WORKFLOWS_FAILURE_TYPE = "ASSIGN_WORKFLOWS_FAILURE";

type UPDATE_STATUS_WORKFLOWS_REQUEST_TYPE = "UPDATE_STATUS_WORKFLOWS_REQUEST";
type UPDATE_STATUS_WORKFLOWS_SUCCESS_TYPE = "UPDATE_STATUS_WORKFLOWS_SUCCESS";
type UPDATE_STATUS_WORKFLOWS_FAILURE_TYPE = "UPDATE_STATUS_WORKFLOWS_FAILURE";

type CLEAR_SELECTED_WORKFLOWS_TYPE = "CLEAR_SELECTED_WORKFLOWS";

type GET_USERS_REQUEST_TYPE = "GET_USERS_REQUEST";
type GET_USERS_SUCCESS_TYPE = "GET_USERS_SUCCESS";
type GET_USERS_FAILURE_TYPE = "GET_USERS_FAILURE";

type GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_REQUEST_TYPE = 'GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_REQUEST';
type GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_SUCCESS_TYPE = 'GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_SUCCESS';
type GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_FAILURE_TYPE = 'GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_FAILURE';

type GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_REQUEST_TYPE = 'GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_REQUEST';
type GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_SUCCESS_TYPE = 'GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_SUCCESS';
type GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_FAILURE_TYPE = 'GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_FAILURE';

type GET_IPMAINTENANCE_DISPLAY_SETTINGS_REQUEST_TYPE = 'GET_IPMAINTENANCE_DISPLAY_SETTINGS_REQUEST';
type GET_IPMAINTENANCE_DISPLAY_SETTINGS_SUCCESS_TYPE = 'GET_IPMAINTENANCE_DISPLAY_SETTINGS_SUCCESS';
type GET_IPMAINTENANCE_DISPLAY_SETTINGS_FAILURE_TYPE = 'GET_IPMAINTENANCE_DISPLAY_SETTINGS_FAILURE';

type GET_USER_PREFERENCES_REQUEST_TYPE = 'GET_USER_PREFERENCES_REQUEST';
type GET_USER_PREFERENCES_SUCCESS_TYPE = 'GET_USER_PREFERENCES_SUCCESS';
type GET_USER_PREFERENCES_FAILURE_TYPE = 'GET_USER_PREFERENCES_FAILURE';

type UPDATE_USER_PREFERENCE_TYPE = 'UPDATE_USER_PREFERENCE';

type GET_USAGE_GRID_DISPLAY_OPTIONS_REQUEST_TYPE = 'GET_USAGE_GRID_DISPLAY_OPTIONS_REQUEST';
type GET_USAGE_GRID_DISPLAY_OPTIONS_SUCCESS_TYPE = 'GET_USAGE_GRID_DISPLAY_OPTIONS_SUCCESS';
type GET_USAGE_GRID_DISPLAY_OPTIONS_FAILURE_TYPE = 'GET_USAGE_GRID_DISPLAY_OPTIONS_FAILURE';

type GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_REQUEST_TYPE = 'GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_REQUEST';
type GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_SUCCESS_TYPE = 'GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_SUCCESS';
type GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_FAILURE_TYPE = 'GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_FAILURE';

type GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_REQUEST_TYPE = 'GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_REQUEST';
type GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_SUCCESS_TYPE = 'GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_SUCCESS';
type GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_FAILURE_TYPE = 'GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_FAILURE';

type GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST_TYPE = 'GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST';
type GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS_TYPE = 'GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS';
type GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE_TYPE = 'GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE';

type GET_SHARE_TOLERANCE_SETTINGS_REQUEST_TYPE = 'GET_SHARE_TOLERANCE_SETTINGS_REQUEST';
type GET_SHARE_TOLERANCE_SETTINGS_SUCCESS_TYPE = 'GET_SHARE_TOLERANCE_SETTINGS_SUCCESS';
type GET_SHARE_TOLERANCE_SETTINGS_FAILURE_TYPE = 'GET_SHARE_TOLERANCE_SETTINGS_FAILURE';

type GET_PD_ARRANGEMENT_MUSIC_LYRIC_REQUEST_TYPE = 'GET_PD_ARRANGEMENT_MUSIC_LYRIC_OPTIONS_REQUEST';
type GET_PD_ARRANGEMENT_MUSIC_LYRIC_SUCCESS_TYPE = 'GET_PD_ARRANGEMENT_MUSIC_LYRIC_OPTIONS_SUCCESS';
type GET_PD_ARRANGEMENT_MUSIC_LYRIC_FAILURE_TYPE = 'GET_PD_ARRANGEMENT_MUSIC_LYRIC_OPTIONS_FAILURE';

type GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_REQUEST_TYPE = 'GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_REQUEST';
type GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_SUCCESS_TYPE = 'GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_SUCCESS';
type GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_FAILURE_TYPE = 'GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_FAILURE';

type GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_REQUEST_TYPE = 'GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_REQUEST';
type GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_SUCCESS_TYPE = 'GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_SUCCESS';
type GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_FAILURE_TYPE = 'GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_FAILURE';


type GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST_TYPE = 'GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST';
type GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS_TYPE = 'GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS';
type GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE_TYPE = 'GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE';

type GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST_TYPE = 'GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST';
type GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS_TYPE = 'GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS';
type GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE_TYPE = 'GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE';

type GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_REQUEST_TYPE = 'GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_REQUEST';
type GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_SUCCESS_TYPE = 'GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_SUCCESS';
type GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_FAILURE_TYPE = 'GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_FAILURE';

type GET_USAGE_MATCHING_DEFAULTS_WORKS_REQUEST_TYPE = 'GET_USAGE_MATCHING_DEFAULTS_WORKS_REQUEST';
type GET_USAGE_MATCHING_DEFAULTS_WORKS_SUCCESS_TYPE = 'GET_USAGE_MATCHING_DEFAULTS_WORKS_SUCCESS';
type GET_USAGE_MATCHING_DEFAULTS_WORKS_FAILURE_TYPE = 'GET_USAGE_MATCHING_DEFAULTS_WORKS_FAILURE';

type GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_REQUEST_TYPE = 'GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_REQUEST';
type GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_SUCCESS_TYPE = 'GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_SUCCESS';
type GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_FAILURE_TYPE = 'GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_FAILURE';

type UPDATE_PAGINATION_TYPE = 'UPDATE_PAGINATION';
type RESET_PAGINATION_TYPE = 'RESET_PAGINATION';
type SET_IS_HUNDRED_RESULTS_PER_PAGE_TYPE = 'SET_IS_HUNDRED_RESULTS_PER_PAGE';

type SORT_SEARCH_RESULTS_TYPE = 'SORT_SEARCH_RESULTS';

type GET_SHARE_DECIMALS_TO_DISPLAY_REQUEST_TYPE = 'GET_SHARE_DECIMALS_TO_DISPLAY_REQUEST_TYPE';
type GET_SHARE_DECIMALS_TO_DISPLAY_SUCCESS_TYPE = 'GET_SHARE_DECIMALS_TO_DISPLAY_SUCCESS_TYPE';
type GET_SHARE_DECIMALS_TO_DISPLAY_FAILURE_TYPE = 'GET_SHARE_DECIMALS_TO_DISPLAY_FAILURE_TYPE';

type START_WORKFLOW_SESSION_TYPE = 'START_WORKFLOW_SESSION';
type CANCEL_WORKFLOW_SESSION_TYPE = 'CANCEL_WORKFLOW_SESSION';
type NEXT_WORKFLOW_IN_SESSION_TYPE = 'NEXT_WORKFLOW_IN_SESSION';
type SKIP_WORKFLOW_IN_SESSION_TYPE = 'SKIP_WORKFLOW_IN_SESSION';
type SET_ERROR_WORKFLOW_SESSION_TYPE = 'SET_ERROR_WORKFLOW_SESSION';

type SUBMIT_PRODUCT_AV_REQUEST_TYPE = "SUBMIT_PRODUCT_AV_REQUEST";
type SUBMIT_PRODUCT_AV_REQUEST_SUCCESS_TYPE = "SUBMIT_PRODUCT_AV_REQUEST_SUCCESS";
type SUBMIT_PRODUCT_AV_REQUEST_FAILURE_TYPE = "SUBMIT_PRODUCT_AV_REQUEST_FAILURE";

type SET_WARNING_MESSAGE_TYPE = 'SET_WARNING_MESSAGE';
type SET_VALIDATION_WARNING_MESSAGE_TYPE = 'SET_VALIDATION_WARNING_MESSAGE';

type GET_PRODUCT_MAINTENANCE_SUBMISSION_CONFIG_REQUEST_TYPE = "GET_PRODUCT_MAINTENANCE_SUBMISSION_CONFIG_REQUEST"
type GET_PRODUCT_MAINTENANCE_SUBMISSION_CONFIG_FAILURE_TYPE = "GET_PRODUCT_MAINTENANCE_SUBMISSION_CONFIG_FAILURE"
type GET_PRODUCT_MAINTENANCE_SUBMISSION_CONFIG_SUCCESS_TYPE = "GET_PRODUCT_MAINTENANCE_SUBMISSION_CONFIG_SUCCESS"

type CLEAR_REPERTOIRE_CACHE_TYPE = "CLEAR_REPERTOIRE_CACHE";

type DISPLAY_REPRESENTATION_TYPE = "DISPLAY_REPRESENTATION";

type SAVE_WORKFLOW_REQUEST_TYPE = "SAVE_WORKFLOW_REQUEST";
type SAVE_WORKFLOW_SUCCESS_TYPE = "SAVE_WORKFLOW_SUCCESS";
type SAVE_WORKFLOW_FAILURE_TYPE = "SAVE_WORKFLOW_FAILURE";

type APPROVE_REJECT_WORKFLOW_SUCCESS_TYPE = "APPROVE_REJECT_WORKFLOW_SUCCESS"

type SAVE_ADJUSTMENT_REQUEST_TYPE = "SAVE_ADJUSTMENT_REQUEST";
type SAVE_ADJUSTMENT_SUCCESS_TYPE = "SAVE_ADJUSTMENT_SUCCESS";
type SAVE_ADJUSTMENT_FAILURE_TYPE = "SAVE_ADJUSTMENT_FAILURE";

type GET_ADJUSTMENT_DETAILS_REQUEST_TYPE = "GET_ADJUSTMENT_DETAILS_REQUEST";
type GET_ADJUSTMENT_DETAILS_SUCCESS_TYPE = "GET_ADJUSTMENT_DETAILS_SUCCESS";
type GET_ADJUSTMENT_DETAILS_FAILURE_TYPE = "GET_ADJUSTMENT_DETAILS_FAILURE";

type COPY_ADJUSTMENT_TYPE = "COPY_ADJUSTMENT";
type UNDO_ADJUSTMENT_CHANGES_TYPE = "UNDO_ADJUSTMENT_CHANGES";
type UNDO_ADJUSTMENT_CHANGES_SUCCESS_TYPE = "UNDO_ADJUSTMENT_CHANGES_SUCCESS";
type UNDO_ADJUSTMENT_CHANGES_FAILURE_TYPE = "UNDO_ADJUSTMENT_CHANGES_FAILURE";
type UPDATE_ADJUSTMENT_FIELD_TYPE = "UPDATE_ADJUSTMENT_FIELD";
type CREATE_NEW_ADJUSTMENT_TYPE = "CREATE_NEW_ADJUSTMENT";

type IP_TRANSFER_REQUEST_TYPE = "IP_TRANSFER_REQUEST";
type IP_TRANSFER_SUCCESS_TYPE = "IP_TRANSFER_SUCCESS";
type IP_TRANSFER_FAILURE_TYPE = "IP_TRANSFER_FAILURE";

type SEARCH_DATABRICKS_ALLOCATIONS_REQUEST_TYPE = "SEARCH_DATABRICKS_ALLOCATIONS_REQUEST";
type SEARCH_DATABRICKS_ALLOCATIONS_SUCCESS_TYPE = "SEARCH_DATABRICKS_ALLOCATIONS_SUCCESS";
type SEARCH_DATABRICKS_ALLOCATIONS_FAILURE_TYPE = "SEARCH_DATABRICKS_ALLOCATIONS_FAILURE";

type ADD_IP_TO_ADJUSTMENT_DETAILS_TYPE = "ADD_IP_TO_ADJUSTMENT_DETAILS";
type ADD_IP_TO_USAGE_DETAILS_TYPE = "ADD_IP_TO_USAGE_DETAILS";

type SEARCH_POOL_BY_CODE_REQUEST_TYPE = "SEARCH_POOL_BY_CODE_REQUEST";
type SEARCH_POOL_BY_CODE_REQUEST_SUCCESS_TYPE = "SEARCH_POOL_BY_CODE_REQUEST_SUCCESS";
type SEARCH_POOL_BY_CODE_REQUEST_FAILURE_TYPE = "SEARCH_POOL_BY_CODE_REQUEST_FAILURE";

type SEARCH_PAYMENTRUNS_REQUEST_TYPE = "SEARCH_PAYMENTRUNS_REQUEST";
type SEARCH_PAYMENTRUNS_SUCCESS_TYPE = "SEARCH_PAYMENTRUNS_SUCCESS";
type SEARCH_PAYMENTRUNS_FAILURE_TYPE = "SEARCH_PAYMENTRUNS_FAILURE";

type UPDATE_USAGE_GROUP_MATCHES_TYPE = "UPDATE_USAGE_GROUP_MATCHES"
type SAVE_USAGE_GROUP_FROM_SEARCH_SUCCESS_TYPE = "SAVE_USAGE_GROUP_FROM_SEARCH_SUCCESS";
type SAVE_USAGE_GROUP_FROM_SEARCH_FAILURE_TYPE = "SAVE_USAGE_GROUP_FROM_SEARCH_FAILURE";

type SEARCH_LICENSES_REQUEST_TYPE = "SEARCH_LICENSES_REQUEST";
type SEARCH_LICENSES_SUCCESS_TYPE = "SEARCH_LICENSES_SUCCESS";
type SEARCH_LICENSES_FAILURE_TYPE = "SEARCH_LICENSES_FAILURE";

type UPDATE_LICENSE_FIELD_TYPE = "UPDATE_LICENSE_FIELD";

type SAVE_LICENSE_REQUEST_TYPE = "SAVE_LICENSE_REQUEST";
type SAVE_LICENSE_SUCCESS_TYPE = "SAVE_LICENSE_SUCCESS";
type SAVE_LICENSE_FAILURE_TYPE = "SAVE_LICENSE_FAILURE";

type GET_LICENSE_REQUEST_TYPE = "GET_LICENSE_REQUEST";
type GET_LICENSE_SUCCESS_TYPE = "GET_LICENSE_SUCCESS";
type GET_LICENSE_FAILURE_TYPE = "GET_LICENSE_FAILURE";

type UNDO_LICENSE_SUCCESS_TYPE = "UNDO_LICENSE_SUCCESS";

type SUBMIT_LICENSE_SUCCESS_TYPE = "SUBMIT_LICENSE_SUCCESS";
type SUBMIT_LICENSE_FAILURE_TYPE = "SUBMIT_LICENSE_FAILURE";

type GET_CLAIM_REQUEST_TYPE = "GET_CLAIM_REQUEST";
type GET_CLAIM_SUCCESS_TYPE = "GET_CLAIM_SUCCESS";
type GET_CLAIM_FAILURE_TYPE = "GET_CLAIM_FAILURE";

type SEARCH_LICENSE_USER_REQUEST_TYPE = "SEARCH_LICENSE_USER_REQUEST";
type SEARCH_LICENSE_USER_SUCCESS_TYPE = "SEARCH_LICENSE_USER_SUCESS";
type SEARCH_LICENSE_USER_FAILURE_TYPE = "SEARCH_LICENSE_USER_FAILURE";

type GET_LICENSE_REQUEST_WORK_PARAMETERS_REQUEST_TYPE = "GET_LICENSE_REQUEST_WORK_PARAMETERS_REQUEST";
type GET_LICENSE_REQUEST_WORK_PARAMETERS_SUCCESS_TYPE = "GET_LICENSE_REQUEST_WORK_PARAMETERS_SUCCESS";
type GET_LICENSE_REQUEST_WORK_PARAMETERS_FAILURE_TYPE = "GET_LICENSE_REQUEST_WORK_PARAMETERS_FAILURE";

type GET_LICENSE_WORKS_CONTRIBUTORS_REQUEST_TYPE = "GET_LICENSE_WORKS_CONTRIBUTORS_REQUEST";
type GET_LICENSE_WORKS_CONTRIBUTORS_SUCCESS_TYPE = "GET_LICENSE_WORKS_CONTRIBUTORS_SUCCESS";
type GET_LICENSE_WORKS_CONTRIBUTORS_FAILURE_TYPE = "GET_LICENSE_WORKS_CONTRIBUTORS_FAILURE";

type IMPORT_LICENSE_WORKS_PRICES_TYPE = "IMPORT_LICENSE_WORKS_PRICES";
type CALCULATE_LICENSE_WORKS_PRICES_REQUEST_TYPE = "CALCULATE_LICENSE_WORKS_PRICES_REQUEST";
type CALCULATE_LICENSE_WORKS_PRICES_SUCCESS_TYPE = "CALCULATE_LICENSE_WORKS_PRICES_SUCCESS";
type CALCULATE_LICENSE_WORKS_PRICES_FAILURE_TYPE = "CALCULATE_LICENSE_WORKS_PRICES_FAILURE";

type GET_COMMENT_CATEGORY_FLAG_REQUEST_TYPE = "GET_COMMENT_CATEGORY_FLAG_REQUEST";
type GET_COMMENT_CATEGORY_FLAG_SUCCESS_TYPE = "GET_COMMENT_CATEGORY_FLAG_SUCCESS";
type GET_COMMENT_CATEGORY_FLAG_FAILURE_TYPE = "GET_COMMENT_CATEGORY_FLAG_FAILURE";

type GET_OPEN_DISTRIBUTIONS_REQUEST_TYPE = "GET_OPEN_DISTRIBUTIONS_REQUEST";
type GET_OPEN_DISTRIBUTIONS_SUCCESS_TYPE = "GET_OPEN_DISTRIBUTIONS_SUCCESS";
type GET_OPEN_DISTRIBUTIONS_FAILURE_TYPE = "GET_OPEN_DISTRIBUTIONS_FAILURE";

type GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_REQUEST_TYPE = "GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_REQUEST";
type GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_SUCCESS_TYPE = "GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_SUCCESS";
type GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_FAILURE_TYPE = "GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_FAILURE";

type GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_REQUEST_TYPE = "GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_REQUEST";
type GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_SUCCESS_TYPE = "GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_SUCCESS";
type GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_FAILURE_TYPE = "GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_FAILURE";

// IncrementTempID
type INCREMENT_TEMPID_TYPE = "INCREMENT_TEMPID";

type UPDATE_USAGE_GROUP_SET_LIST_ADDITIONAL_TYPE = "UPDATE_USAGE_GROUP_SET_LIST_ADDITIONAL";
type UPDATE_USAGE_GROUP_LOCATION_TYPE = "UPDATE_USAGE_GROUP_LOCATION";
type UPDATE_USAGE_GROUP_VENUE_DETAILS_TYPE = "UPDATE_USAGE_GROUP_VENUE_DETAILS";

type UPDATE_IPNAMES_TYPE = "UPDATE_IPNAMES";
type SAVE_IPNAMES_SUCCESS_TYPE = "SAVE_IPNAMES_SUCCESS";

type TRIGGER_MANUAL_CLAIM_JOB_SUCCESS_TYPE = "TRIGGER_MANUAL_CLAIM_JOB_SUCCESS_TYPE";
type TRIGGER_MANUAL_CLAIM_JOB_REQUEST_TYPE = "TRIGGER_MANUAL_CLAIM_JOB_REQUEST_TYPE";
type TRIGGER_MANUAL_CLAIM_JOB_FAILURE_TYPE = "TRIGGER_MANUAL_CLAIM_JOB_FAILURE_TYPE";

type GET_DOMESTIC_OR_FOREIGN_RECALCULATE_REQUEST_TYPE = 'GET_DOMESTIC_OR_FOREIGN_RECALCULATE_REQUEST_TYPE';
type GET_DOMESTIC_OR_FOREIGN_RECALCULATE_SUCCESS_TYPE = 'GET_DOMESTIC_OR_FOREIGN_RECALCULATE_SUCCESS_TYPE';
type GET_DOMESTIC_OR_FOREIGN_RECALCULATE_FAILURE_TYPE = 'GET_DOMESTIC_OR_FOREIGN_RECALCULATE_FAILURE_TYPE';

type MY_PACKAGES_DOWNLOAD_FORMAT_SUCCESS_TYPE = 'MY_PACKAGES_DOWNLOAD_FORMAT_SUCCESS'

type UPDATE_POOL_CODE_TO_SEARCH_TYPE = 'UPDATE_POOL_CODE_TO_SEARCH';

// Action types
export interface UpdateUsageField {
    type: UPDATE_USAGE_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IRepertoireStateKeys;
    };
}

export interface UpdateUsageRowDropdown {
    type: UPDATE_USAGE_ROW_DROPDOWN_TYPE;
    payload: {
        value: IUsageDetailsAdjustmentsDataItem[];
        usageRowViewIndex: number;
        usageDetailsIndex: number;
    };
}

export interface DisplayRepresentation {
    type: DISPLAY_REPRESENTATION_TYPE;
    payload: {
        displayRepresentation: boolean;
    };
}

export interface GetUsageMatchesRequest {
    type: GET_USAGE_MATCHES_REQUEST_TYPE;
}

export interface GetUsageMatchesSuccess {
    type: GET_USAGE_MATCHES_SUCCESS_TYPE;
    payload: {
        usageResults: IUsageGroupsSearchResult;
        continuationToken?: string;
        startIndex?: number;
    };
}

export interface GetUsageMatchesFailure {
    type: GET_USAGE_MATCHES_FAILURE_TYPE;
    payload: {
        error: string;
    };
}


export interface SearchUsageGroupRequest {
    type: SEARCH_USAGEGROUP_REQUEST_TYPE;
    payload: {
        continuationToken?: string;
    };
}

export interface SearchUsageGroupSuccess {
    type: SEARCH_USAGEGROUP_SUCCESS_TYPE;
    payload: {
        usageResult: IUsageGroupsSearchResult;
        searchBody?: IUsagesSearchQuery;
        continuationToken?: string;
        updatingMatches?: boolean;
        isHundredResultsPerPage?: boolean;
    };
}

export interface SearchUsageGroupFailure {
    type: SEARCH_USAGEGROUP_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface AddWorkOrProductToUsageSearchCriteria {
    type: ADD_WORK_OR_PRODUCT_TO_USAGE_SEARCH_CRITERIA_TYPE;
    payload: {
        value: any;
    }
}

export interface UpdateUsageGroupMatches {
    type: UPDATE_USAGE_GROUP_MATCHES_TYPE;
    payload: {
        usageGroup: IUsageSearchResultRowType;
    }
}

export interface SaveUsageGroupFromSearchSuccess {
    type: SAVE_USAGE_GROUP_FROM_SEARCH_SUCCESS_TYPE;
    payload: {
        usageGroupSaveResult: IUsageGroupSaveResult;
    }
}

export interface SaveUsageGroupFromSearchFailure {
    type: SAVE_USAGE_GROUP_FROM_SEARCH_FAILURE_TYPE;
    payload: {
        usageGroupSaveResult: IUsageGroupSaveResult;
    }
}

export interface AddMatchWorkToUsage {
    type: ADD_MATCHWORK_TO_USAGE_TYPE;
    payload: {
        work: IMatchWorksSearchResult;
    }
}

export interface AddMatchProductToUsage {
    type: ADD_MATCHPRODUCT_TO_USAGE_TYPE;
    payload: {
        product: IProductSearchResult;
    }
}

export interface UpdateUsagePagination {
    type: UPDATE_USAGEPAGINATION_TYPE;
    payload: {
        indexOfFirstResult: number,
        indexOfLastResult: number,
        resultsPerPage: number,
        currentPage: number,
        repertoireSection: string
    }
}

export interface ResetUsagePagination {
    type: RESET_USAGEPAGINATION_TYPE;
    payload: {
        repertoireSection: string
    }
}

export interface GetUsageRequest {
    type: GET_USAGE_REQUEST_TYPE;
}

export interface GetUsageSuccess {
    type: GET_USAGE_SUCCESS_TYPE;
    payload: {
        usageGroup: IUsageGroup;
    }
}

export interface GetUsageFailure {
    type: GET_USAGE_FAILURE_TYPE;
    payload: {
        error: string;
    }
}

export interface UpdateUsageField {
    type: UPDATE_USAGE_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IRepertoireStateKeys;
    };
}

//export interface ClearUsageModalSearchResults {
//    type: CLEAR_USAGEMODAL_SEARCH_RESULTS_TYPE;
//}

export interface GetUsageDistributionsRequest {
    type: GET_USAGEDISTR_REQUEST_TYPE;
}

export interface GetUsageDistributionsSuccess {
    type: GET_USAGEDISTR_SUCCESS_TYPE;
    payload: {
        distributions: IDistributionState[];
        customer: string;
    };
}

export interface GetUsageDistributionsFailure {
    type: GET_USAGEDISTR_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface GetUsagePoolsRequest {
    type: GET_USAGEPOOL_REQUEST_TYPE;
}

export interface GetUsagePoolsSuccess {
    type: GET_USAGEPOOL_SUCCESS_TYPE;
    payload: {
        pools: IUsagePoolState[];
    };
}

export interface GetUsagePoolsFailure {
    type: GET_USAGEPOOL_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface GetSourceMajorsRequest {
    type: GET_SOURCEMAJOR_REQUEST_TYPE;
}

export interface GetSourceMajorsSuccess {
    type: GET_SOURCEMAJOR_SUCCESS_TYPE;
    payload: {
        sourceMajors: string[];
    };
}

export interface GetSourceMajorsFailure {
    type: GET_SOURCEMAJOR_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface GetSourceMinorsRequest {
    type: GET_SOURCEMINOR_REQUEST_TYPE;
}

export interface GetSourceMinorsSuccess {
    type: GET_SOURCEMINOR_SUCCESS_TYPE;
    payload: {
        sourceMinors: string[];
    };
}

export interface GetSourceMinorsFailure {
    type: GET_SOURCEMINOR_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SearchMatchWorksRequest {
    type: SEARCH_MATCHWORKS_REQUEST_TYPE;
}

export interface SearchMatchProductsRequest {
    type: SEARCH_MATCHPRODUCTS_REQUEST_TYPE;
}

export interface SearchMatchWorksSuccess {
    type: SEARCH_MATCHWORKS_SUCCESS_TYPE;
    payload: {
        matchWorkSearchResult: IMatchWorksSearchResult[];
        searchBody?: IMatchWorksSearchQuery;
    };
}
export interface SearchMatchProductsSuccess {
    type: SEARCH_MATCHPRODUCTS_SUCCESS_TYPE;
    payload: {
        matchProductsSearchResult: IProductSearchResult[];
        searchBody?: IMatchProductsSearchQuery;
    };
}


export interface SearchDataChanged {
    type: SEARCHDATA_CHANGED_TYPE;
    payload: {
        field: string;
        value: any;
    };
}

export interface SearchMatchWorksFailure {
    type: SEARCH_MATCHWORKS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SearchMatchProductsFailure {
    type: SEARCH_MATCHPRODUCTS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface ExpandMatchWorkResult {
    type: EXPAND_MATCHWORK_RESULT_TYPE,
    payload: {
        matchWorkResults: number
    }
}

export interface UsageExpandAllResults {
    type: USAGEEXPAND_ALL_RESULTS_TYPE
}

export interface SaveUsageGroupRequest {
    type: SAVE_USAGEGROUP_REQUEST_TYPE;
}

export interface SaveUsageGroupSuccess {
    type: SAVE_USAGEGROUP_SUCCESS_TYPE;
    payload: {
        usageGroupSaveResult: IUsageGroupSaveResult;
    };
}

export interface SaveUsageGroupFailure {
    type: SAVE_USAGEGROUP_FAILURE_TYPE;
    payload: {
        usageGroupSaveResult: IUsageGroupSaveResult;
    };
}

export interface SaveUsageGroupRequest {
    type: SAVE_USAGEGROUP_REQUEST_TYPE;
}

export interface SaveUsageGroupSuccess {
    type: SAVE_USAGEGROUP_SUCCESS_TYPE;
    payload: {
        usageGroupSaveResult: IUsageGroupSaveResult;
    };
}

export interface SaveUsageGroupFailure {
    type: SAVE_USAGEGROUP_FAILURE_TYPE;
    payload: {
        usageGroupSaveResult: IUsageGroupSaveResult;
    };
}
export interface ResetUsageMessageBanner {
    type: RESET_USAGEMESSAGE_BANNER_TYPE;
}

export interface SetUsageTypeAction {
    type: SET_USAGE_TYPE_TYPE;
    payload: {
        usageType: string;
    };
}

export interface GetSourcesRequest {
    type: GET_SOURCES_REQUEST_TYPE;
}

export interface GetSourcesSuccess {
    type: GET_SOURCES_SUCCESS_TYPE;
    payload: {
        sources: Dictionary<ISourceMatchType>;
    };
}

export interface GetSourcesFailure {
    type: GET_SOURCES_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface GetUsagePoolRequest {
    type: GET_USAGE_POOL_REQUEST_TYPE
}

export interface GetUsagePoolSuccess {
    type: GET_USAGE_POOL_SUCCESS_TYPE;
    payload: {
        usagePool: IUsagePoolCore
    }
}

export interface GetUsagePoolFailure {
    type: GET_USAGE_POOL_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface UpdateUsagePoolField {
    type: UPDATE_USAGE_POOL_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IUsagePoolStateKeys;
        lookups?: ILookupDictionary;
    }
}

export interface SaveUsagePoolRequest {
    type: SAVE_USAGE_POOL_REQUEST_TYPE
}

export interface SaveUsagePoolSuccess {
    type: SAVE_USAGE_POOL_SUCCESS_TYPE;
    payload: {
        usagePoolSaveResult: IUsagePoolSaveResult;
    }
}

export interface SaveUsagePoolFailure {
    type: SAVE_USAGE_POOL_FAILURE_TYPE;
    payload: {
        usagePoolSaveResult: IUsagePoolSaveResult;
    }
}

export interface DeleteUsagePoolRequest {
    type: DELETE_USAGE_POOL_REQUEST_TYPE;
}

export interface DeleteUsagePoolSuccess {
    type: DELETE_USAGE_POOL_SUCCESS_TYPE;
    payload: {
        poolId: number;
    }
}

export interface DeleteUsagePoolFailure {
    type: DELETE_USAGE_POOL_FAILURE_TYPE;
    payload: {
        error?: IValidationMessage;
    }
}

export interface CreateNewUsagePool {
    type: CREATE_NEW_USAGE_POOL_TYPE;
    payload: {
        lookups: ILookupDictionary,
        isNew?: boolean,
        usagePoolMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem
    }
}

export interface GetMatchingSourcesRequest {
    type: GET_MATCHING_SOURCES_REQUEST_TYPE
}

export interface GetMatchingSourcesSuccess {
    type: GET_MATCHING_SOURCES_SUCCESS_TYPE;
    payload: {
        sourceSearchResult: ISourceSearchResult[];
    }
}

export interface GetMatchingSourcesFailure {
    type: GET_MATCHING_SOURCES_FAILURE_TYPE;
    payload: {
        error: any;
    }
}

export interface GetShortCutKeysRequest {
    type: GET_SHORT_CUT_KEYS_REQUEST_TYPE
}

export interface GetShortCutKeysSuccess {
    type: GET_SHORT_CUT_KEYS_SUCCESS_TYPE;
    payload: {
        shortCutKeys: IShortCutKeys[];
    }
}

export interface GetShortCutKeysFailure {
    type: GET_SHORT_CUT_KEYS_FAILURE_TYPE;
    payload: {
        error: any;
    }
}



export interface ReduxStorageLoadAction {
    type: REDUX_STORAGE_LOAD_TYPE;
}

export interface SetDataSourceAction {
    type: SET_DATA_SOURCE_TYPE;
    payload: {
        dataSource: string;
    };
}

export interface SetAgreementDataSourceAction {
    type: SET_AGREEMENT_DATA_SOURCE_TYPE;
    payload: {
        dataSource: string;
    };
}
export interface SetAgreementSearchTypeAction {
    type: SET_AGREEMENT_SEARCH_TYPE_TYPE;
    payload: {
        searchType: string;
    };
}

export interface SetProductDataSourceAction {
    type: SET_PRODUCT_DATA_SOURCE_TYPE;
    payload: {
        dataSource: string;
    }
}

export interface SetAdjustmentDataSource {
    type: SET_ADJUSTMENT_DATA_SOURCE_TYPE;
    payload: {
        dataSource: string;
    }
}

export interface AddSetTypes {
    type: ADD_SET_TYPES_TYPE;
}

export interface RemoveSetTypes {
    type: REMOVE_SET_TYPES_TYPE;
}

export interface ClearSetTypes {
    type: CLEAR_SET_TYPES_TYPE;
}

export interface RepertoireComponentDataRequest {
    type: FETCH_REPERTOIRE_COMPONENT_DATA_REQUEST_TYPE;
}

export interface RepertoireComponentDataSuccess {
    type: FETCH_REPERTOIRE_COMPONENT_DATA_SUCCESS_TYPE;
    payload: {
        componentData: IRepertoireComponentData;
    };
}

export interface RepertoireComponentDataFailure {
    type: FETCH_REPERTOIRE_COMPONENT_DATA_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface AddTabAction {
    type: ADD_TAB_TYPE;
    payload: {
        tab: ITabReduxItem;
        keepTitle?: boolean;
        pane: number;
    };
}

export interface ReplaceTabAction {
    type: REPLACE_TAB_TYPE;
    payload: {
        tab: ITabReduxItem,
        targetTabTitle: string
    };
}

export interface RemoveTabAction {
    type: REMOVE_TAB_TYPE;
    payload: {
        index: number;
        paneIndex: number;
    };
}

export interface RemovePaneTwoAction {
    type: REMOVE_PANE_TWO_TYPE;
}

export interface OpenTabAction {
    type: OPEN_TAB_TYPE;
    payload: {
        index: number;
        paneIndex: number;
    };
}

export interface setActivePaneAction {
    type: SET_ACTIVE_PANE_TYPE;
    payload: {
        paneIndex: number;
    };
}

export interface SearchWorksRequest {
    type: SEARCH_WORKS_REQUEST_TYPE;
}

export interface SearchWorksSuccess {
    type: SEARCH_WORKS_SUCCESS_TYPE;
    payload: {
        worksResult: IWorksSearchResult[];
        searchBody?: IWorksSearchQuery;
    };
}

export interface SearchWorksFailure {
    type: SEARCH_WORKS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SearchArtistsRequest {
    type: SEARCH_ARTISTS_REQUEST_TYPE;
}

export interface SearchArtistsSuccess {
    type: SEARCH_ARTISTS_SUCCESS_TYPE;
    payload: {
        artistsResult: IArtist[];
    };
}

export interface SearchArtistsFailure {
    type: SEARCH_ARTISTS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SearchContributorRequest {
    type: SEARCH_CONTRIBUTORS_REQUEST_TYPE;
}

export interface SearchContributorsSuccess {
    type: SEARCH_CONTRIBUTORS_SUCCESS_TYPE;
    payload: {
        contributorsResult: IContributorSearchResult[];
    };
}

export interface SearchContributorsFailure {
    type: SEARCH_CONTRIBUTORS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SearchAgreementsRequest {
    type: SEARCH_AGREEMENTS_REQUEST_TYPE;

}

export interface SearchAgreementsSuccess {
    type: SEARCH_AGREEMENTS_SUCCESS_TYPE;
    payload: {
        agreementsResult: IAgreementSearchResult[];
    }
}

export interface SearchAgreementsFailure {
    type: SEARCH_AGREEMENTS_FAILURE_TYPE;
    payload: {
        error: string;
    }
}

export interface SearchIPsRequest {
    type: SEARCH_IPS_REQUEST_TYPE;
}

export interface SearchIPsSuccess {
    type: SEARCH_IPS_SUCCESS_TYPE;
    payload: {
      ipsResult: IIPsSearchResult[];
      isSameSearch?: boolean;
    }
}

export interface SearchIPsFailure {
    type: SEARCH_IPS_FAILURE_TYPE;
    payload: {
        error: string;
    }
}

export interface SearchProductsRequest {
    type: SEARCH_PRODUCTS_REQUEST_TYPE;
}

export interface SearchProductsSuccess {
    type: SEARCH_PRODUCTS_SUCCESS_TYPE;
    payload: {
        productsResult: IProductSearchResult[];
    }
}

export interface SearchProductsFailure {
    type: SEARCH_PRODUCTS_FAILURE_TYPE;
    payload: {
        error: string;
    }
}

export interface ClearModalSearchResults {
    type: CLEAR_MODAL_SEARCH_RESULTS_TYPE;
}

export interface GetWorkDetailsRequest {
    type: GET_WORK_DETAILS_REQUEST_TYPE;
}

export interface GetWorkDetailsSuccess {
    type: GET_WORK_DETAILS_SUCCESS_TYPE;
    payload: {
        workDetails: IWork;
    };
}

export interface GetWorkDetailsFailure {
    type: GET_WORK_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        workId: number;
    };
}

export interface GetMatchingWorkInfoRequest {
    type: GET_MATCHING_WORK_INFO_REQUEST_TYPE
}

export interface GetMatchingWorkInfoSuccess {
    type: GET_MATCHING_WORK_INFO_SUCCESS_TYPE;
    payload: {
        workInfo: IWork;
    }
}

export interface GetMatchingWorkInfoFailure {
    type: GET_MATCHING_WORK_INFO_FAILURE_TYPE;
    payload: {
        error: string;
        workId: number;
    }
}

export interface RefreshWorkDetailsRequest {
    type: REFRESH_WORK_DETAILS_REQUEST_TYPE;
}

export interface RefreshWorkDetailsSuccess {
    type: REFRESH_WORK_DETAILS_SUCCESS_TYPE;
    payload: {
        workDetails: IWork;
    };
}
export interface RefreshWorkDetailsFailure {
    type: REFRESH_WORK_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        workId: number;
    };
}

export interface RefreshProductDetailsRequest {
    type: REFRESH_PRODUCT_DETAILS_REQUEST_TYPE;
}

export interface RefreshProductDetailsSuccess {
    type: REFRESH_PRODUCT_DETAILS_SUCCESS_TYPE;
    payload: {
        productDetails: IProductCore;
    };
}
export interface RefreshProductDetailsFailure {
    type: REFRESH_PRODUCT_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        productCoreID: number;
    };
}
export interface RefreshUsageDetailsRequest {
    type: REFRESH_USAGE_DETAILS_REQUEST_TYPE;
}

export interface RefreshUsageDetailsSuccess {
    type: REFRESH_USAGE_DETAILS_SUCCESS_TYPE;
    payload: {
        usageDetails: IUsageGroup;
    };
}
export interface RefreshUsageDetailsFailure {
    type: REFRESH_USAGE_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        usageID: string;
    };
}
export interface GetAgreementDetailsRequest {
    type: GET_AGREEMENT_DETAILS_REQUEST_TYPE;
}

export interface GetAgreementDetailsSuccess {
    type: GET_AGREEMENT_DETAILS_SUCCESS_TYPE;
    payload: {
        agreementDetails: IAgreement;
    }
}

export interface GetAgreementDetailsFailure {
    type: GET_AGREEMENT_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        agreementId: number;
    }
}

export interface GetIPDetailsRequest {
    type: GET_IP_DETAILS_REQUEST_TYPE;
}

export interface GetIPDetailsSuccess {
    type: GET_IP_DETAILS_SUCCESS_TYPE;
    payload: {
        ipDetails: IInterestedParty;
    }
}

export interface GetIPDetailsFailure {
    type: GET_IP_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        ipBaseNumber?: string;
        accountNumber?: string;
    }
}

export interface GetProductDetailsRequest {
    type: GET_PRODUCT_DETAILS_REQUEST_TYPE;
}

export interface GetProductDetailsSuccess {
    type: GET_PRODUCT_DETAILS_SUCCESS_TYPE;
    payload: {
        productDetails: IProductCore;
        dontopentab?: boolean;
    };
}

export interface GetProductDetailsFailure {
    type: GET_PRODUCT_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        productCoreId: number;
    };
}

export interface GetArtistDetailsRequest {
    type: GET_ARTIST_DETAILS_REQUEST_TYPE;
}

export interface GetArtistDetailsSuccess {
    type: GET_ARTIST_DETAILS_SUCCESS_TYPE;
    payload: {
        artistDetails: IArtistCore;
    };
}

export interface GetArtistDetailsFailure {
    type: GET_ARTIST_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        performerID: number;
    };
}

export interface GetAllPackagesDetailsRequest {
    type: GET_ALL_PACKAGES_DETAILS_REQUEST_TYPE;
}

export interface GetAllPackagesDetailsSuccess {
    type: GET_ALL_PACKAGES_DETAILS_SUCCESS_TYPE;
    payload: {
        myPackagesDetails: IMyPackagesDetails[];
    };
}

export interface GetAllPackagesDetailsFailure {
    type: GET_ALL_PACKAGES_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        userName: string;
    };
}

export interface AddArtistToWork {
    type: ADD_ARTIST_TO_WORK_TYPE;
    payload: {
        artist: IArtist;
        id: number;
    }
}

export interface AddArtistToProduct {
    type: ADD_ARTIST_TO_PRODUCT_TYPE;
    payload: {
        artist: IArtist;
        id: number;
        modalIndex: number;
    }
}

export interface AddWorkToProduct {
    type: ADD_WORK_TO_PRODUCT_TYPE;
    payload: {
        work: IWorksSearchResult;
    }
}

export interface AddWorkToLicenseRequest {
    type: ADD_WORK_TO_LICENSE_REQUEST_TYPE;
    payload: {
        work: IWorksSearchResult;
    }
}
export interface UpdateWorkContributorIPRequest {
    type: UPDATE_WORK_CONTRIBUTOR_IP_REQUEST_TYPE;
    payload: {
    }
}

export interface UpdateWorkContributorIPSuccess {
    type: UPDATE_WORK_CONTRIBUTOR_IP_SUCCESS_TYPE;
    payload: {
        workContributorId: number,
        ip: IP,
        agreementChain: IAgreementLink[]
    }
}

export interface UpdateWorkContributorIPFailure {
    type: UPDATE_WORK_CONTRIBUTOR_IP_FAILURE_TYPE;
    payload: {
    }
}

export interface UpdateAgreementIP {
    type: UPDATE_AGREEMENT_IP_TYPE;
    payload: {
        agreementAdminIPID: number,
        ip: IP
    }
}

export interface UpdateProductContributorIP {
    type: UPDATE_PRODUCT_CONTRIBUTOR_IP_TYPE;
    payload: {
        productContributorID: number,
        ip: IP
    }
}

export interface UpdateAgreementWork {
    type: UPDATE_AGREEMENT_WORK_TYPE;
    payload: {
        work: IWorksSearchResult
    }
}
export interface UpdateAgreementCarveOutProduct {
    type: UPDATE_AGREEMENT_CARVEOUT_PRODUCT_TYPE;
    payload: {
        agreementProductWorkID: number,
        product: IProductSearchResult
    }
}

export interface UpdateMatchingWork {
    type: UPDATE_MATCHING_WORK_TYPE;
    payload: {
        workID: number,
        work: IWorksSearchResult
    }
}

export interface UpdateMatchingProduct {
    type: UPDATE_MATCHING_PRODUCT_TYPE;
    payload: {
        productID: number,
        product: IProductSearchResult
    }
}

export interface UpdateRelatedWork {
    type: UPDATE_RELATED_WORK_TYPE;
    payload: {
        work: IWorksSearchResult
    }
}

export interface UpdateWorkSourceIP {
    type: UPDATE_WORK_SOURCE_IP_TYPE;
    payload: {
        workSource: IP
    }
}

export interface UpdateProductSourceIP {
    type: UPDATE_PRODUCT_SOURCE_IP_TYPE;
    payload: {
        productSource: IP
    }
}

export interface CopyExistingWorkRequest {
    type: COPY_EXISTING_WORK_REQUEST_TYPE;
}
export interface CopyExistingWorkSuccess {
    type: COPY_EXISTING_WORK_SUCCESS_TYPE;
    payload: {
        actionList: IDataActionToolbar[],
        userName: string,
        lookups: ILookupDictionary,
        excludedFields: string[],
        societyNumberCode: string,
        paneIndex?: number,
    }
}

export interface UndoWorkChangesRequest {
    type: UNDO_WORK_CHANGES_REQUEST_TYPE;
}

export interface UndoWorkChangesSuccess {
    type: UNDO_WORK_CHANGES_SUCCESS_TYPE;
    payload: {
        work: IWork,
        lookups: ILookupDictionary,
        paneIndex: number,
    }
}

export interface UndoWorkChangesFailure {
    type: UNDO_WORK_CHANGES_FAILURE_TYPE;
}

export interface MergeMatchingWorkRequest {
    type: MERGE_MATCHING_WORK_REQUEST_TYPE;
}

export interface MergeMatchingWorkSuccess {
    type: MERGE_MATCHING_WORK_SUCCESS_TYPE;
    payload: {
        workSaveResult: IWorkSaveResult,
        lookups: ILookupDictionary,
        dataAction: IDataActionToolbar[],
        paneIndex: number,
    };
}

export interface MergeMatchingWorkFailure {
    type: MERGE_MATCHING_WORK_FAILURE_TYPE;
    payload: {
        workSaveResult: IWorkSaveResult,
        lookups: ILookupDictionary,
        paneIndex: number,
    };
}

export interface MergeMatchingIPRequest {
    type: MERGE_MATCHING_INTERESTED_PARTY_REQUEST_TYPE;
}

export interface MergeMatchingIPSuccess {
    type: MERGE_MATCHING_INTERESTED_PARTY_SUCCESS_TYPE;
    payload: {
        ipSaveResult: IInterestedParty,
        lookups: ILookupDictionary,
        dataAction: IDataActionToolbar[];
    };
}

export interface MergeMatchingIPFailure {
    type: MERGE_MATCHING_INTERESTED_PARTY_FAILURE_TYPE;
    payload: {
        ipSaveResult: IInterestedParty,
        lookups: ILookupDictionary
    };
}

export interface MergeMatchingIPRequest {
    type: MERGE_MATCHING_INTERESTED_PARTY_REQUEST_TYPE;
}

export interface MergeMatchingIPSuccess {
    type: MERGE_MATCHING_INTERESTED_PARTY_SUCCESS_TYPE;
    payload: {
        ipSaveResult: IInterestedParty,
        lookups: ILookupDictionary,
        dataAction: IDataActionToolbar[];
    };
}

export interface MergeMatchingIPFailure {
    type: MERGE_MATCHING_INTERESTED_PARTY_FAILURE_TYPE;
    payload: {
        ipSaveResult: IInterestedParty,
        lookups: ILookupDictionary
    };
}

export interface MergeMatchingProductSuccess {
    type: MERGE_MATCHING_PRODUCT_SUCCESS_TYPE;
    payload: {
        productSaveResult: IProductSaveResult,
        lookups: ILookupDictionary,
        dataAction: IDataActionToolbar[],
        paneIndex: number,
    };
}

export interface MergeMatchingProductFailure {
    type: MERGE_MATCHING_PRODUCT_FAILURE_TYPE;
    payload: {
        productSaveResult: IProductSaveResult,
        lookups: ILookupDictionary,
        paneIndex: number,
    };
}

export interface MergeRepertoireWorkRequest {
    type: MERGE_REPERTOIRE_WORK_REQUEST_TYPE;
}

export interface MergeRepertoireWorkSuccess {
    type: MERGE_REPERTOIRE_WORK_SUCCESS_TYPE;
    payload: {
        workSaveResult: IWorkSaveResult,
        lookups: ILookupDictionary,
        dataAction: IDataActionToolbar[],
        paneIndex: number,
    };
}

export interface MergeRepertoireWorkFailure {
    type: MERGE_REPERTOIRE_WORK_FAILURE_TYPE;
    payload: {
        workSaveResult: IWorkSaveResult,
        lookups: ILookupDictionary,
        paneIndex: number,
    };
}

export interface MergeRepertoireProductSuccess {
    type: MERGE_REPERTOIRE_PRODUCT_SUCCESS_TYPE;
    payload: {
        productSaveResult: IProductSaveResult,
        lookups: ILookupDictionary,
        dataAction: IDataActionToolbar[],
        paneIndex: number,
    };
}

export interface MergeRepertoireProductFailure {
    type: MERGE_REPERTOIRE_PRODUCT_FAILURE_TYPE;
    payload: {
        productSaveResult: IProductSaveResult,
        lookups: ILookupDictionary
    };
}

export interface DeleteWorkRequest {
    type: DELETE_WORK_REQUEST_TYPE;
    payload: {
        workId: number;
        dataSource: DataSource;
    }
}

export interface DeleteWorkSuccess {
    type: DELETE_WORK_SUCCESS_TYPE;
    payload: {
        workId: number;
    }
}

export interface DeleteWorkFailure {
    type: DELETE_WORK_FAILURE_TYPE;
    payload: {
        error: any;
    };
}

export interface CreateNewWork {
    type: CREATE_NEW_WORK_TYPE;
    payload: {
        lookups: ILookupDictionary,
        actionList?: IDataActionToolbar[],
        isNew?: boolean,
        workMaintenanceGeneralDataView?: IRepertoireComponentDataItem,
        formats: FormatFields[],
        workGroupId?: string,
        workGroup?: string,
        isFromUsage?:boolean,
        usage?:IUsageGroupState
        paneIndex?: number;
        isOpenedInSecondTab?: boolean;
    }
}

export interface CreateNewRepresentation {
    type: CREATE_NEW_REPRESENTATION_TYPE;
    payload: {
        ipBaseNumber: string,
        lookups: ILookupDictionary,
        isNew?: boolean,
        representationMaintenanceGeneralDataView?: IRepertoireComponentDataItem
    }
}

export interface CreateNewAgreement {
    type: CREATE_NEW_AGREEMENT_TYPE;
    payload: {
        lookups: ILookupDictionary,
        isNew?: boolean,
        dataAction?: IDataActionToolbar[],
        agreementMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
    }
}

export interface CreateNewDistribution {
    type: CREATE_NEW_DISTRIBUTION_TYPE;
    payload: {
        lookups: ILookupDictionary,
        isNew?: boolean,
        distributionMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
    }
}

export interface CreateNewPaymentRun {
    type: CREATE_NEW_PAYMENTRUN_TYPE;
    payload: {
        lookups: ILookupDictionary,
        isNew?: boolean,
        paymentRunMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
    }
}

export interface CreateNewLicense {
    type: CREATE_NEW_LICENSE_TYPE;
    payload: {
        lookups: ILookupDictionary,
        isNew?: boolean,
        licenseMaintenanceGeneralDataViewData: IRepertoireComponentDataItem,
        licenseRequestWorkParameterFields: ILicenseInputItem[];
    }
}

export interface UpdateLicenseRequestWorkItem {
    type: UPDATE_LICENSE_WORK_ITEM_TYPE;
    payload: {
        licenseRequestWorkID: number,
        licenseRequestWorks: ILicenseRequestWorkItem[]
    }
}

export interface SaveWorkRequest {
    type: SAVE_WORK_REQUEST_TYPE;
}

export interface AutoMatchWork {
    type: AUTO_MATCH_WORK_TYPE;
}

export interface ResetMessageBanner {
    type: RESET_MESSAGE_BANNER_TYPE;
}


export interface SaveWorkSuccess {
    type: SAVE_WORK_SUCCESS_TYPE;
    payload: {
        workSaveResult: IWorkSaveResult;
        lookups: ILookupDictionary;
        dataAction: IDataActionToolbar[];
        saveOption?: SaveOption;
        enableCoreWorkNumbers?: boolean;
        paneIndex?: number;
    }
}

export interface SaveWorkFailure {
    type: SAVE_WORK_FAILURE_TYPE;
    payload: {
        workSaveResult: IWorkSaveResult;
        lookups: ILookupDictionary;
        paneIndex?: number;
    };
}

export interface SaveWorkNumberAdditionalSuccess {
    type: SAVE_WORK_NUMBERS_ADDITIONAL_SUCCESS_TYPE;
    payload: {
        work: IWork;
        lookups: ILookupDictionary;
    }
}

export interface SaveWorkNumberAdditionalFailure {
    type: SAVE_WORK_NUMBERS_ADDITIONAL_FAILURE_TYPE;
    payload: {
        work: IWork;
        lookups: ILookupDictionary;
    };
}

export interface GetCurrentPageAdditionalWorkNumberDetailsSuccess {
    type: GET_WORK_NUMBERS_ADDITIONAL_PAGE_DETAILS_SUCCESS_TYPE;
    payload: {
        work: IWork;
        lookups: ILookupDictionary;
    }
}

export interface GetCurrentPageAdditionalWorkNumberDetailsFailure {
    type: GET_WORK_NUMBERS_ADDITIONAL_PAGE_DETAILS_FAILURE_TYPE;
    payload: {
        work: IWork;
        lookups: ILookupDictionary;
    };
}

export interface SaveAgreementRequest {
    type: SAVE_AGREEMENT_REQUEST_TYPE;
}

export interface SaveAgreementSuccess {
    type: SAVE_AGREEMENT_SUCCESS_TYPE;
    payload: {
        agreementSaveResult: IAgreementSaveResult;
        dataAction: IDataActionToolbar[];
    };
}

export interface SaveAgreementFailure {
    type: SAVE_AGREEMENT_FAILURE_TYPE;
    payload: {
        agreementSaveResult: IAgreementSaveResult;
    };
}

export interface CopyExistingAgreement {
    type: COPY_AGREEMENT_TYPE;
    actionList: IDataActionToolbar[];
    userName?: string;
}

export interface DeleteAgreementRequest {
    type: DELETE_AGREEMENT_REQUEST_TYPE;
    payload: {
        agreementId: number;
        dataSource: DataSource;
    }
}

export interface DeleteAgreementSuccess {
    type: DELETE_AGREEMENT_SUCCESS_TYPE;
    payload: {
        agreementId: number;
    }
}

export interface DeleteAgreementFailure {
    type: DELETE_AGREEMENT_FAILURE_TYPE;
    payload: {
        error: any;
    };
}
export interface MergeAgreementRequest {
    type: MERGE_AGREEMENT_REQUEST_TYPE;
}

export interface MergeAgreementSuccess {
    type: MERGE_AGREEMENT_SUCCESS_TYPE;
    payload: {
        agreementSaveResult: IAgreementSaveResult,
        lookups: ILookupDictionary,
        dataAction: IDataActionToolbar[];
    };
}
export interface MergeAgreementFailure {
    type: MERGE_AGREEMENT_FAILURE_TYPE;
    payload: {
        agreementSaveResult: IAgreementSaveResult,
        lookups: ILookupDictionary,
    };
}

export interface DeleteDistributionRequest {
    type: DELETE_DISTRIBUTION_REQUEST_TYPE;
    payload: {
        distributionId: number;
    }
}

export interface DeleteDistributionSuccess {
    type: DELETE_DISTRIBUTION_SUCCESS_TYPE;
    payload: {
        distributionId: number;
    }
}

export interface DeleteDistributionFailure {
    type: DELETE_DISTRIBUTION_FAILURE_TYPE;
    payload: {
        error: any;
    };
}

export interface CopyExistingPool {
    type: COPY_POOL_TYPE;
    actionList: IDataActionToolbar[];
}

export interface CopyExistingDistribution {
    type: COPY_DISTRIBUTION_TYPE;
    actionList: IDataActionToolbar[];
}

export interface CopyExistingPaymentRun {
    type: COPY_PAYMENTRUN_TYPE;
    actionList: IDataActionToolbar[];
}

export interface CopyExistingProduct {
    type: COPY_PRODUCT_TYPE;
    payload: {
        paneIndex?: number;
    }
}

export interface CopyExistingLicense {
    type: COPY_LICENSE_TYPE;
    shoppingCart: IShoppingCartState;
}

export interface CreateNewProduct {
    type: CREATE_NEW_PRODUCT_TYPE;
    payload: {
        lookups: ILookupDictionary,
        isNew?: boolean,
        productMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem,
        formats: FormatFields[],
        paneIndex: number,
    }
}

export interface SaveProductRequest {
    type: SAVE_PRODUCT_REQUEST_TYPE;
}

export interface SaveProductSuccess {
    type: SAVE_PRODUCT_SUCCESS_TYPE;
    payload: {
        productSaveResult: IProductSaveResult;
        paneIndex: number;
    };
}

export interface SaveProductFailure {
    type: SAVE_PRODUCT_FAILURE_TYPE;
    payload: {
        productSaveResult: IProductSaveResult;
        paneIndex: number;
    };
}

export interface DeleteProductRequest {
    type: DELETE_PRODUCT_REQUEST_TYPE;
    payload: {
        productCoreId: number;
        dataSource: DataSource;
    }
}

export interface DeleteProductSuccess {
    type: DELETE_PRODUCT_SUCCESS_TYPE;
    payload: {
        productCoreId: number;
    }
}

export interface DeleteProductFailure {
    type: DELETE_PRODUCT_FAILURE_TYPE;
    payload: {
        error: any;
    };
}

export interface CreateNewAccount {
    type: CREATE_NEW_ACCOUNT_TYPE;
    payload: {
        lookupValues: ILookupDictionary,
        isNew?: boolean,
        ipMaintenanceGeneralViewData?: IRepertoireComponentDataItem
    }
}

export interface CreateNewArtist {
    type: CREATE_NEW_ARTIST_TYPE;
    payload: {
        lookupValues: ILookupDictionary,
        isNew?: boolean,
        artistMaintenanceGeneralViewData?: IRepertoireComponentDataItem
    }
}

export interface CreateNewClaim {
    type: CREATE_NEW_CLAIM_TYPE;
    payload: {
        lookupValues: ILookupDictionary,
        isNew?: boolean,
        claimMaintenanceGeneralViewData?: IRepertoireComponentDataItem
    }
}

export interface SaveAccountRequest {
    type: SAVE_ACCOUNT_REQUEST_TYPE;
}

export interface SaveAccountSuccess {
    type: SAVE_ACCOUNT_SUCCESS_TYPE;
    payload: {
        accountSaveResult: IAccountSaveResult;
        lookups?:ILookupDictionary;
    }
}

export interface SaveAccountFailure {
    type: SAVE_ACCOUNT_FAILURE_TYPE;
    payload: {
        accountSaveResult: IAccountSaveResult;
        lookups?:ILookupDictionary;
    };
}

export interface SaveArtistRequest {
    type: SAVE_ARTIST_REQUEST_TYPE;
}

export interface SaveArtistSuccess {
    type: SAVE_ARTIST_SUCCESS_TYPE;
    payload: {
        artistSaveResult: IArtistSaveResult;
    }
}

export interface SaveArtistFailure {
    type: SAVE_ARTIST_FAILURE_TYPE;
    payload: {
        artistSaveResult: IArtistSaveResult;
    };
}

export interface SaveClaimRequest {
    type: SAVE_CLAIM_REQUEST_TYPE;
}

export interface SaveClaimSuccess {
    type: SAVE_CLAIM_SUCCESS_TYPE;
    payload: {
        claimSaveResult: IClaimSaveResult;
    }
}

export interface SaveClaimFailure {
    type: SAVE_CLAIM_FAILURE_TYPE;
    payload: {
        claimSaveResult: IClaimSaveResult;
    };
}

export interface AddWorkToClaimRequest {
    type: ADD_WORK_TO_CLAIM_REQUEST_TYPE;
    payload: {
        work: IWorksSearchResult;
    }
}

export interface SearchSubjectSuccess{
    type: SEARCH_SUBJECT_SUCCESS_TYPE;
    payload: {
        workSubjects: IWorkSubjectSearchResult[];
    }
}

export interface UpdateSubjectCode {
    type: UPDATE_SUBJECT_CODE_TYPE;
    payload: {
        subject: ISubject;
    }
}

export interface OpenAccordion {
    type: OPEN_ACCORDION_TYPE;
    payload: {
        accordionName: string;
        componentName: string;
    };
}

export interface CloseWorkMaintenanceAccordian {
    type: CLOSE_ACCORDION_TYPE;
    payload: {
        accordionName: string;
        componentName: string;
    };
}

export interface OpenIPMaintenanceAccordion {
    type: OPEN_IP_MAINTENANCE_ACCORDION_TYPE;
    payload: {
        accordionName: string;
    };
}

export interface CloseIPMaintenanceAccordion {
    type: CLOSE_IP_MAINTENANCE_ACCORDION_TYPE;
    payload: {
        accordionName: string;
    };
}

export interface SetChangesMade {
    type: SET_CHANGES_MADE_TYPE;
    payload: {
        changesMade: boolean;
        tabIndex: number;
    };
}

export interface UpdateWorkField {
    type: UPDATE_WORK_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IWorkStateKeys;
        index?: number;
    };
}

export interface UpdateWorkFields {
    type: UPDATE_WORK_FIELDS_TYPE;
    payload: {
        updatedWork: any;
    };
}

export interface UpdateAgreementField {
    type: UPDATE_AGREEMENT_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IAgreementStateKeys;
    }
}

export interface UpdateInterestedPartyField {
    type: UPDATE_INTERESTED_PARTY_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IInterestedPartyStateKeys;
    }
}

export interface UpdateRepresentationField {
    type: UPDATE_REPRESENTATION_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IRepresentationStateKeys;
    }
}

export interface UpdateArtistField {
    type: UPDATE_ARTIST_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IArtistStateKeys;
    }
}
export interface UpdateScheduledJobField {
    type: UPDATE_SCHEDULED_JOB_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IScheduledJobStateKeys;
        componentName: string;
    }
}

export interface UpdateProductField {
    type: UPDATE_PRODUCT_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IProductStateKeys;
    };
}

export interface UpdateProductFields {
    type: UPDATE_PRODUCT_FIELDS_TYPE;
    payload: {
        values: any;
    };
}

export interface SortWorkFieldData {
    type: SORT_WORK_FIELD_DATA_TYPE;
    payload: {
        value: any;
        fieldName: IWorkStateKeys;
    };
}

export interface SortAgreementFieldData {
    type: SORT_AGREEMENT_FIELD_DATA_TYPE;
    payload: {
        value: any;
        fieldName: IAgreementStateKeys;
    }
}
export interface SortWeightSettingsFieldData {
    type: SORT_WEIGHT_SETTINGS_FIELD_DATA_TYPE;
    payload: {
        value: any;
        fieldName: IUsagePoolWeightSettingsStateKeys;
    }
}

export interface SortDistributionPoolFieldData {
    type: SORT_DISTRIBUTION_POOL_FIELD_DATA_TYPE;
    payload: {
        value: any;
        fieldName: IDistributionPoolStateKeys;
    }
}

export interface SortInterestedPartyFieldData {
    type: SORT_INTERESTED_PARTY_FIELD_DATA_TYPE;
    payload: {
        value: any;
        fieldName: IInterestedPartyStateKeys;
    }
}

export interface SortProductFieldData {
    type: SORT_PRODUCT_FIELD_DATA_TYPE;
    payload: {
        value: any;
        fieldName: IProductStateKeys;
    };
}

export interface SortArtistFieldData {
    type: SORT_ARTIST_FIELD_DATA_TYPE;
    payload: {
        value: any;
        fieldName: IArtistStateKeys;
    };
}

export interface PostWorkBatchRequest {
    type: POST_WORK_BATCH_REQUEST_TYPE;
    payload: {
        workBatchID: number;
    }
}

export interface GetWorkMatchConnectorRequest {
    type: GET_WORK_MATCH_CONNECTOR_REQUEST_TYPE;
}

export interface GetWorkMatchConnectorSuccess {
    type: GET_WORK_MATCH_CONNECTOR_SUCCESS_TYPE;
    payload: {
        matchConnectorStatus: boolean;
    }
}

export interface GetWorkMatchConnectorFailed {
    type: GET_WORK_MATCH_CONNECTOR_FAILED_TYPE;
    payload: {
        error: string;
    }
}

export interface SwitchSetTypeGroup {
    type: SWITCH_SET_TYPE_GROUP_TYPE;
    payload: {
        setTypeGroup: string;
    }
}

export interface UpdateContributorSetType {
    type: UPDATE_CONTRIBUTOR_SET_TYPE_TYPE;
    payload: {
        setType: string;
        inheritFrom: string;
    }
}

export interface PostWorkBatchSuccess {
    type: POST_WORK_BATCH_SUCCESS_TYPE;
}

export interface PostWorkBatchFailed {
    type: POST_WORK_BATCH_FAILED_TYPE;
}

export interface MatchWorkBatchRequest {
    type: MATCH_WORK_BATCH_REQUEST_TYPE;
    payload: {
        workBatchID: number;
    }
}

export interface MatchWorkBatchSuccess {
    type: MATCH_WORK_BATCH_SUCCESS_TYPE;
}

export interface MatchWorkBatchFailed {
    type: MATCH_WORK_BATCH_FAILED_TYPE;
}

export interface PostProductBatchRequest {
    type: POST_PRODUCT_BATCH_REQUEST_TYPE;
    payload: {
        productBatchID: number;
    }
}

export interface PostProductBatchSuccess {
    type: POST_PRODUCT_BATCH_SUCCESS_TYPE;
}

export interface PostProductBatchFailed {
    type: POST_PRODUCT_BATCH_FAILED_TYPE;
}

export interface MatchProductBatchRequest {
    type: MATCH_PRODUCT_BATCH_REQUEST_TYPE;
    payload: {
        productBatchID: number;
    }
}

export interface MatchProductBatchSuccess {
    type: MATCH_PRODUCT_BATCH_SUCCESS_TYPE;
}

export interface MatchProductBatchFailed {
    type: MATCH_PRODUCT_BATCH_FAILED_TYPE;
}

export interface PostAgreementBatchRequest {
    type: POST_AGREEMENT_BATCH_REQUEST_TYPE;
    payload: {
        agreementBatchID: number;
    }
}

export interface PostAgreementBatchSuccess {
    type: POST_AGREEMENT_BATCH_SUCCESS_TYPE;
}

export interface PostAgreementBatchFailed {
    type: POST_AGREEMENT_BATCH_FAILED_TYPE;
}

export interface PostAdjustmentBatchRequest {
    type: POST_ADJUSTMENT_BATCH_REQUEST_TYPE;
    payload: {
        agreementBatchID: number;
    }
}

export interface PostAdjustmentBatchSuccess {
    type: POST_ADJUSTMENT_BATCH_SUCCESS_TYPE;
}

export interface PostAdjustmentBatchFailed {
    type: POST_ADJUSTMENT_BATCH_FAILED_TYPE;
}


export interface UndoAgreementChangesRequest {
    type: UNDO_AGREEMENT_CHANGES_REQUEST_TYPE;
}

export interface UndoAgreementChangesSuccess {
    type: UNDO_AGREEMENT_CHANGES_SUCCESS_TYPE;
    payload: {
        agreement: IAgreement
    }
}

export interface UndoAgreementChangesFailure {
    type: UNDO_AGREEMENT_CHANGES_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface UndoDistributionChangesRequest {
    type: UNDO_DISTRIBUTION_CHANGES_REQUEST_TYPE;
}

export interface UndoDistributionChangesSuccess {
    type: UNDO_DISTRIBUTION_CHANGES_SUCCESS_TYPE;
    payload: {
        distribution: IDistribution,
        lookupValues: ILookupDictionary
    }
}

export interface UndoDistributionChangesFailure {
    type: UNDO_DISTRIBUTION_CHANGES_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface UndoIPChangesRequest {
    type: UNDO_IP_CHANGES_REQUEST_TYPE;
}

export interface UndoIPChangesSuccess {
    type: UNDO_IP_CHANGES_SUCCESS_TYPE;
    payload: {
        ip: IInterestedParty
    }
}

export interface UndoIPChangesFailure {
    type: UNDO_IP_CHANGES_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface UndoProductChangesRequest {
    type: UNDO_PRODUCT_CHANGES_REQUEST_TYPE;
}

export interface UndoProductChangesSuccess {
    type: UNDO_PRODUCT_CHANGES_SUCCESS_TYPE;
    payload: {
        product: IProductCore;
        paneIndex: number;
    }
}

export interface UndoProductChangesFailure {
    type: UNDO_PRODUCT_CHANGES_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface UndoArtistChangesRequest {
    type: UNDO_ARTIST_CHANGES_REQUEST_TYPE;
}

export interface UndoArtistChangesSuccess {
    type: UNDO_ARTIST_CHANGES_SUCCESS_TYPE;
    payload: {
        artist: IArtistCore
    }
}

export interface UndoArtistChangesFailure {
    type: UNDO_ARTIST_CHANGES_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface UndoPaymentRunChangesRequest {
    type: UNDO_PAYMENTRUN_CHANGES_REQUEST_TYPE;
}

export interface UndoPaymentRunChangesSuccess {
    type: UNDO_PAYMENTRUN_CHANGES_SUCCESS_TYPE;
    payload: {
        paymentRun: IPaymentRun,
        lookupValues: ILookupDictionary
    }
}

export interface UndoPaymentRunChangesFailure {
    type: UNDO_PAYMENTRUN_CHANGES_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface SearchWorkVersionHistoryRequest {
    type: SEARCH_WORK_VERSION_HISTORY_REQUEST_TYPE;
}

export interface SearchWorkVersionHistorySuccess {
    type: SEARCH_WORK_VERSION_HISTORY_SUCCESS_TYPE;
    payload: {
        results: IVersionHistorySearchResult[]
    }
}

export interface SearchWorkVersionHistoryFailure {
    type: SEARCH_WORK_VERSION_HISTORY_FAILURE_TYPE;
}

export interface SearchProductVersionHistoryRequest {
    type: SEARCH_PRODUCT_VERSION_HISTORY_REQUEST_TYPE;
}

export interface SearchProductVersionHistorySuccess {
    type: SEARCH_PRODUCT_VERSION_HISTORY_SUCCESS_TYPE;
    payload: {
        results: IProductVersionHistorySearchResult[]
    }
}

export interface SearchProductVersionHistoryFailure {
    type: SEARCH_PRODUCT_VERSION_HISTORY_FAILURE_TYPE;
}

export interface AddtoPackageRequest {
    type: ADD_TO_PACKAGE_REQUEST_TYPE;
}

export interface AddtoPackageSuccess {
    type: ADD_TO_PACKAGE_SUCCESS_TYPE;
    payload: {
        results: IAddtoPackageResult;
        paneIndex: number;
    }
}

export interface AddtoPackageFailure {
    type: ADD_TO_PACKAGE_FAILURE_TYPE;
    payload: {
        results: IAddtoPackageResult;
        paneIndex: number;
    }
}

export interface GeneratePackageSuccess {
    type: GENERATE_PACKAGE_SUCCESS_TYPE;
    payload: {
        result: any
    }
}

export interface GeneratePackageFailure {
    type: GENERATE_PACKAGE_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface ClosePackageFailure {
    type: CLOSE_PACKAGE_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface AddPackageNoteFailure {
    type: ADD_PACKAGE_NOTE_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface SearchSocIPVersionHistoryRequest {
    type: SEARCH_SOCIP_VERSION_HISTORY_REQUEST_TYPE;
}

export interface SearchSocIPVersionHistorySuccess {
    type: SEARCH_SOCIP_VERSION_HISTORY_SUCCESS_TYPE;
    payload: {
        results: ISocIPVersionHistorySearchResult[]
    }
}

export interface SearchSocIPVersionHistoryFailure {
    type: SEARCH_SOCIP_VERSION_HISTORY_FAILURE_TYPE;
}

export interface SearchAgreementVersionHistoryRequest {
    type: SEARCH_AGREEMENT_VERSION_HISTORY_REQUEST_TYPE;
}

export interface SearchAgreementVersionHistorySuccess {
    type: SEARCH_AGREEMENT_VERSION_HISTORY_SUCCESS_TYPE;
    payload: {
        results: IAgreementVersionHistorySearchResult[]
    }
}

export interface SearchAgreementVersionHistoryFailure {
    type: SEARCH_AGREEMENT_VERSION_HISTORY_FAILURE_TYPE;
}

export interface SearchDistributionVersionHistoryRequest {
    type: SEARCH_DISTRIBUTION_VERSION_HISTORY_REQUEST_TYPE;
}

export interface SearchDistributionVersionHistorySuccess {
    type: SEARCH_DISTRIBUTION_VERSION_HISTORY_SUCCESS_TYPE;
    payload: {
        results: IVersionHistorySearchResult[]
    }
}

export interface SearchDistributionVersionHistoryFailure {
    type: SEARCH_DISTRIBUTION_VERSION_HISTORY_FAILURE_TYPE;
}

export interface SearchPaymentRunVersionHistoryRequest {
    type: SEARCH_PAYMENTRUN_VERSION_HISTORY_REQUEST_TYPE;
}

export interface SearchPaymentRunVersionHistorySuccess {
    type: SEARCH_PAYMENTRUN_VERSION_HISTORY_SUCCESS_TYPE;
    payload: {
        results: IPaymentRunVersionHistorySearchResult[]
    }
}

export interface SearchPaymentRunVersionHistoryFailure {
    type: SEARCH_PAYMENTRUN_VERSION_HISTORY_FAILURE_TYPE;
}

export interface SearchAdjustmentVersionHistoryRequest {
    type: SEARCH_ADJUSTMENT_VERSION_HISTORY_REQUEST_TYPE;
}

export interface SearchAdjustmentVersionHistorySuccess {
    type: SEARCH_ADJUSTMENT_VERSION_HISTORY_SUCCESS_TYPE;
    payload: {
        results: IAdjustmentVersionHistorySearchResult[]
    }
}

export interface SearchAdjustmentVersionHistoryFailure {
    type: SEARCH_ADJUSTMENT_VERSION_HISTORY_FAILURE_TYPE;
}

export interface SearchPoolVersionHistoryRequest {
    type: SEARCH_POOL_VERSION_HISTORY_REQUEST_TYPE;
}

export interface SearchPoolVersionHistorySuccess {
    type: SEARCH_POOL_VERSION_HISTORY_SUCCESS_TYPE;
    payload: {
        results: IUsagePoolVersionHistorySearchResult[]
    }
}

export interface SearchPoolVersionHistoryFailure {
    type: SEARCH_POOL_VERSION_HISTORY_FAILURE_TYPE;
}

export interface AddNewRepresentation {
    type: ADD_NEW_REPRESENTATION_TYPE;
    payload: {
        representation,
        activeTab,
        activePane
    }
}

export interface AddRepresentationRequest {
    type: ADD_REPRESENTATION_REQUEST_TYPE;
}

export interface AddRepresentationSuccess {
    type: ADD_REPRESENTATION_REQUEST_SUCCESS_TYPE;
    payload: {
        ip: IIPSaveResult
    }
}

export interface AddRepresentationFailure {
    type: ADD_REPRESENTATION_REQUEST_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface DeleteRepresentations {
    type: DELETE_REPRESENTATIONS_TYPE;
    payload: {
        representations,
        activeTab
    }
}

export interface UpdateRepresentationIdsToDelete {
    type: DELETE_REPRESENTATIONS_UPDATE_TYPE;
    payload: {
        representationIdsToDelete: number[],
        updatedRepresentationTable: IIpLicensingRepresentation[]
    }
}

export interface UpdateIPNames {
    type: UPDATE_IPNAMES_TYPE;
    payload: {
        ipNames: IName[]
    }
}

export interface DeleteRepresentationsRequest {
    type: DELETE_REPRESENTATIONS_REQUEST_TYPE;
}

export interface DeleteRepresentationsSuccess {
    type: DELETE_REPRESENTATIONS_REQUEST_SUCCESS_TYPE;
    payload: {
        result: IIPSaveResult
    }
}

export interface DeleteRepresentationsFailure {
    type: DELETE_REPRESENTATIONS_REQUEST_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface GetRepresentationGroups {
    type: GET_REPRESENTATION_GROUPS_TYPE;
    payload: {
        ipBaseNumber
    }
}

export interface GetRepresentationGroupRequest {
    type: GET_REPRESENTATION_GROUP_REQUEST_TYPE;
}

export interface GetRepresentationGroupSuccess {
    type: GET_REPRESENTATION_GROUP_SUCCESS_TYPE;
    payload: {
        groups: {
            representationGroups: Array<{ code: string, description: string }>,
            representationUses: Array<{ code: string, description: string }>,
            representationWorkGroups: Array<{ code: string, description: string }>
        }
    }
}
export interface GetRepresentationGroupFailure {
    type: GET_REPRESENTATION_GROUP_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface DeleteAccountRequest {
    type: DELETE_ACCOUNT_REQUEST_TYPE;
}

export interface DeleteAccountRequestSuccess {
    type: DELETE_ACCOUNT_REQUEST_SUCCESS_TYPE;
    payload: {
        ip: IIPSaveResult
    }
}

export interface DeleteAccountRequestFailure {
    type: DELETE_ACCOUNT_REQUEST_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface DeleteArtistRequest {
    type: DELETE_ARTIST_REQUEST_TYPE;
    payload: {
        performerID: number
    }
}

export interface DeleteArtistRequestSuccess {
    type: DELETE_ARTIST_REQUEST_SUCCESS_TYPE;
    payload: {
        performerID: number
    }
}

export interface DeleteArtistRequestFailure {
    type: DELETE_ARTIST_REQUEST_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface UpdateIpAgreementFilterStateField {
    type: UPDATE_IP_AGREEMENT_FILTER_STATE_TYPE;
    payload: {
        field: IAgreementSearchStateKeys;
        value: any;
    };
}
export interface UpdateIpRepresentationFilterStateField {
    type: UPDATE_IP_REPRESENTATION_FILTER_STATE_TYPE;
    payload: {
        field: IRepresentationSearchStateKeys;
        value: any;
    };
}


export interface FilterIpAgreementsRequest {
    type: FILTER_IP_AGREEMENT_REQUEST_TYPE;
}
export interface FilterIpRepresentationsRequest {
    type: FILTER_IP_REPRESENTATION_REQUEST_TYPE;
}

export interface FilterIpAgreementsSuccess {
    type: FILTER_IP_AGREEMENT_SUCCESS_TYPE;
    payload: {
        filteredAgreements: IIPAgreement[]
    };
}

export interface FilterIpRepresentationsSuccess {
    type: FILTER_IP_REPRESENTATION_SUCCESS_TYPE;
    payload: {
        filteredRepresentations: IIPRepresentation[]
    };
}


export interface ContributorsDisplaySettingSuccess {
    type: CONTRIBUTORS_DISPLAY_SETTING_SUCCESS_TYPE;
    payload: {
        contributorDisplaySettings: IContributorSiteConfiguration[]
    };
}
export interface DownloadFileFormatSuccess {
    type: MY_PACKAGES_DOWNLOAD_FORMAT_SUCCESS_TYPE ;
    payload: {
        downloadFileFormat: IMyPackagesDownloadFileFormat[]
    };
}

export interface GenreCategoriesSuccess {
    type: GENRE_CATEGORIES_SUCCESS_TYPE;
    payload: {
        genreCategoriesConfiguration: IGenreCategoriesConfiguration
    };
}

export interface GenreCategoriesByDomOrForeignRequest {
    type: GENRE_CATEGORIESBYDOMORFOREIN_REQUEST_TYPE;
}

export interface GenreCategoriesByDomOrForeignSuccess {
    type: GENRE_CATEGORIESBYDOMORFOREIN_SUCCESS_TYPE;
    payload: {
        genereCategoryDefaultsConfigurations: IGenereCategoryDefaultsConfiguration
    };
}

export interface FilterGenreCategorySuccess {
    type: FILTER_GENRE_CATEGORY_FLAG_SUCCESS_TYPE;
    payload: {
        filterGenreCategories: boolean;
    }
}

export interface FilterGenreCategoryFailure {
    type: FILTER_GENRE_CATEGORY_FLAG_FAILURE_TYPE;
    payload: {
        error: string
    }
}
export interface GenreCategoriesByDomOrForeignFailure {
    type: GENRE_CATEGORIESBYDOMORFOREIN_FAILURE_TYPE;
    payload: {
        error: string
    }
}

export interface ReadonlyFlagsFieldSuccess {
    type: READONLY_FLAGS_FIELD_SUCCESS_TYPE;
    payload: {
        readonlyFlagsFields: IReadonlyFlagsField[]
    };
}

export interface OtherIndicatorWorkFlagTypeSuccess {
    type: OTHER_INDICATORS_WORK_FLAG_TYPES_SUCCESS_TYPE;
    payload: {
        otherIndicatorWorkFlagTypes: string[]
    };
}

export interface ReadonlyIndicatorWorkFlagTypeSuccess {
    type: READONLY_INDICATORS_WORK_FLAG_TYPES_SUCCESS_TYPE;
    payload: {
        readonlyIndicatorWorkFlagTypes: string[]
    };
}

export interface ContributorsDisplaySettingRequest {
    type: CONTRIBUTORS_DISPLAY_SETTING_REQUEST_TYPE;
}
export interface GenreCategoriesRequest {
    type: GENRE_CATEGORIES_REQUEST_TYPE;
}
export interface FilterGenreCategoryRequest {
    type: FILTER_GENRE_CATEGORY_FLAG_REQUEST_TYPE;
}
export interface ReadonlyFlagsFieldRequest {
    type: READONLY_FLAGS_FIELD_REQUEST_TYPE;
}

export interface OtherIndicatorWorkFlagTypeRequest {
    type: OTHER_INDICATORS_WORK_FLAG_TYPES_REQUEST_TYPE;
}

export interface ReadonlyIndicatorWorkFlagTypeRequest {
    type: READONLY_INDICATORS_WORK_FLAG_TYPES_REQUEST_TYPE;
}

export interface ContributorsDisplaySettingFailure {
    type: CONTRIBUTORS_DISPLAY_SETTING_FAILURE_TYPE;
    payload: {
        error: string
    }
}
export interface GenreCategoriesFailure {
    type: GENRE_CATEGORIES_FAILURE_TYPE;
    payload: {
        error: string
    }
}

export interface ReadonlyFlagsFieldFailure {
    type: READONLY_FLAGS_FIELD_FAILURE_TYPE;
    payload: {
        error: string
    }
}

export interface OtherIndicatorWorkFlagTypeFailure {
    type: OTHER_INDICATORS_WORK_FLAG_TYPES_FAILURE_TYPE;
    payload: {
        error: string
    }
}

export interface ReadonlyIndicatorWorkFlagTypeFailure {
    type: READONLY_INDICATORS_WORK_FLAG_TYPES_FAILURE_TYPE;
    payload: {
        error: string
    }
}


export interface SetEditableFields {
    type: SET_EDITABLE_FIELDS_TAB_TYPE;
    payload: {
        componentFieldName: string,
        componentInstance: string;
    }
}

export interface UpdateEditableField {
    type: UPDATE_EDITABLE_FIELD_TYPE;
    payload: {
        value: any[];
        key: string;
    }
}

export interface UpdateComponentFieldRequest {
    type: UPDATE_COMPONENT_FIELD_REQUEST_TYPE;
}

export interface UpdateComponentFieldFailure {
    type: UPDATE_COMPONENT_FIELD_FAILURE_TYPE;
    payload: {
        error: string
    }
}

export interface UpdateComponentFieldSuccess {
    type: UPDATE_COMPONENT_FIELD_SUCCESS_TYPE;
    payload: {
        componentFields: IRepertoireField[];
        component: string;
        componentInstance: string;
    };
}

export interface GetFormatFieldsRequest {
    type: GET_FORMAT_FIELD_REQUEST_TYPE
}

export interface GetFormatFieldsSuccess {
    type: GET_FORMAT_FIELD_SUCCESS_TYPE,
    payload: {
        formatFields: FormatFields[]
    }
}
export interface GetFormatFieldsFailure {
    type: GET_FORMAT_FIELD_FAILURE_TYPE,
    payload: {
        error: any
    }
}
export interface ApplyFormatsWorkMaintenance {
    type: APPLY_FORMATS_WORK_MAINTENANCE_TYPE,
}
export interface ApplyFormatsProductMaintenance {
    type: APPLY_FORMATS_PRODUCT_MAINTENANCE_TYPE
}

export interface ExpandWorkResult {
    type: EXPAND_WORK_RESULT_TYPE,
    payload: {
        workResult: number
    }
}
export interface ExpandUsageDetail {
    type: EXPAND_USAGE_DETAIL_TYPE,
    payload: {
        usageDetail: number
    }
}

export interface ExpandMatchingWork {
    type: EXPAND_MATCHING_WORK_TYPE,
    payload: {
        index: number
    }
}

export interface ExpandMatchingProduct {
    type: EXPAND_MATCHING_PRODUCT_TYPE,
    payload: {
        index: number
    }
}

export interface ExpandProductResult {
    type: EXPAND_PRODUCT_RESULT_TYPE,
    payload: {
        productResult: number
    }
}

export interface ExpandAllResults {
    type: EXPAND_ALL_RESULTS_TYPE
}

export interface EnableCustomField {
    type: ENABLE_CUSTOM_FIELD_TYPE
    payload: {
        index: number
    }
}

export interface AddRepertoireAttachmentRequest {
    type: ADD_REPERTOIRE_ATTACHMENT_REQUEST_TYPE,
}
export interface AddRepertoireAttachmentSuccess {
    type: ADD_REPERTOIRE_ATTACHMENT_SUCCESS_TYPE,
    payload: {
        attachment: IAttachedFile
    }
}
export interface AddRepertoireAttachmentFailure {
    type: ADD_REPERTOIRE_ATTACHMENT_FAILURE_TYPE,
    payload: {
        error: any
    }
}
export interface RemoveWorkAttachmentRequest {
    type: REMOVE_WORK_ATTACHMENT_REQUEST_TYPE,
}
export interface RemoveWorkAttachmentSuccess {
    type: REMOVE_WORK_ATTACHMENT_SUCCESS_TYPE,
    payload: {
        attachment: IAttachedFile
    }
}
export interface RemoveWorkAttachmentFailure {
    type: REMOVE_WORK_ATTACHMENT_FAILURE_TYPE,
    payload: {
        error: any
    }
}

export interface RemoveAgreementAttachmentRequest {
    type: REMOVE_AGREEMENT_ATTACHMENT_REQUEST_TYPE,
}
export interface RemoveAgreementAttachmentSuccess {
    type: REMOVE_AGREEMENT_ATTACHMENT_SUCCESS_TYPE,
    payload: {
        attachment: IAttachedFile
    }
}
export interface RemoveAgreementAttachmentFailure {
    type: REMOVE_AGREEMENT_ATTACHMENT_FAILURE_TYPE,
    payload: {
        error: any
    }
}

export interface DeleteBlobRequest {
    type: DELETE_BLOB_REQUEST_TYPE,
}
export interface DeleteBlobSuccess {
    type: DELETE_BLOB_SUCCESS_TYPE,
    payload: {
        success: boolean
    }
}
export interface DeleteBlobFailure {
    type: DELETE_BLOB_FAILURE_TYPE,
    payload: {
        error: any
    }
}

export interface RemoveProductAttachmentRequest {
    type: REMOVE_PRODUCT_ATTACHMENT_REQUEST_TYPE,
}
export interface RemoveProductAttachmentSuccess {
    type: REMOVE_PRODUCT_ATTACHMENT_SUCCESS_TYPE,
    payload: {
        attachment: IAttachedFile
    }
}
export interface RemoveProductAttachmentFailure {
    type: REMOVE_PRODUCT_ATTACHMENT_FAILURE_TYPE,
    payload: {
        error: any
    }
}

export interface RemoveUsageAttachmentRequest {
    type: REMOVE_USAGE_ATTACHMENT_REQUEST_TYPE,
}
export interface RemoveUsageAttachmentSuccess {
    type: REMOVE_USAGE_ATTACHMENT_SUCCESS_TYPE,
    payload: {
        attachment: IAttachedFile
    }
}
export interface RemoveUsageAttachmentFailure {
    type: REMOVE_USAGE_ATTACHMENT_FAILURE_TYPE,
    payload: {
        error: any
    }
}


export interface UpdateSetTypeOnBreak {
    type: UPDATE_SET_TYPE_ON_BREAK_TYPE,
    payload: {
        setType: string
    }
}

export interface AddDroppedFile {
    type: ADD_DROPPED_FILE_TYPE,
    payload: {
        file: IFile,
        inProgress: boolean
    }
}

export interface SetProgressUploadFileRepertoireProccess {
    type: SET_NEW_PROGRESS_UPLOAD_FILE_REPERTOIRE_PROCCESS_TYPE;
    payload: {
        file: IFile,
        message: string,
        isUploadComplete: boolean
    }
}

export interface CleanDroppedFilesState {
    type: CLEAN_DROPPED_FILES_STATE_TYPE;
}

export interface SearchWorkflowRequest {
    type: SEARCH_WORKFLOW_REQUEST_TYPE;
}

export interface SearchWorkflowSuccess {
    type: SEARCH_WORKFLOW_SUCCESS_TYPE;
    payload: {
        worksflowResult: IWorkflowSearchResult[];
        searchQuery: IWorkflowSearchQuery;
    };
}

export interface SearchWorkflowFailure {
    type: SEARCH_WORKFLOW_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SelectSingleWorkflow {
    type: SELECT_SINGLE_WORKFLOW_TYPE;
    payload: {
        tableContentItem: any,
        index: number
    }
}

export interface SelectAllWorkflow {
    type: SELECT_ALL_WORKFLOW_TYPE;
    payload: {
        indexOfFirst: number,
        indexOfLast: number,
        value: boolean
    }
}
export interface MarkSelectAll {
    type: MARK_SELECT_ALL_WORKFLOW_TYPE;
    payload: {
        value: boolean
    }
}

export interface AssignWorkflowsRequest {
    type: ASSIGN_WORKFLOWS_REQUEST_TYPE;

}

export interface AssignWorkflowsSuccess {
    type: ASSIGN_WORKFLOWS_SUCCESS_TYPE;
    payload: {
        success: boolean;
    };
}

export interface AssignWorkflowsFailure {
    type: ASSIGN_WORKFLOWS_FAILURE_TYPE;
    payload: {
        error: any
    }
}
export interface UpdateStatusWorkflowsRequest {
    type: UPDATE_STATUS_WORKFLOWS_REQUEST_TYPE;

}

export interface UpdateStatusWorkflowsSuccess {
    type: UPDATE_STATUS_WORKFLOWS_SUCCESS_TYPE;
    payload: {
        success: boolean;
    };
}
export interface UpdateStatusWorkflowsFailure {
    type: UPDATE_STATUS_WORKFLOWS_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface ClearWorkflowSelection {
    type: CLEAR_SELECTED_WORKFLOWS_TYPE
}

export interface GetUsersRequest {
    type: GET_USERS_REQUEST_TYPE;
}

export interface GetUsersSuccess {
    type: GET_USERS_SUCCESS_TYPE;
    payload: {
        users: string[];
    };
}

export interface GetUsersFailure {
    type: GET_USERS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SearchClaimsRequest {
    type: SEARCH_CLAIM_REQUEST_TYPE;
}

export interface SearchClaimsSuccess {
    type: SEARCH_CLAIM_SUCCESS_TYPE;
    payload: {
        claimResult: IClaimSearchResult[];
    };
}

export interface SearchClaimsFailure {
    type: SEARCH_CLAIM_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface GetAgreementDisplayOptionsRequest {
    type: GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_REQUEST_TYPE;
}

export interface GetAgreementDisplayOptionsSuccess {
    type: GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_SUCCESS_TYPE;
    payload: {
        agreementShareDisplayOptions: IAgreementShareDisplayConfiguration
    };
}

export interface GetAgreementDisplayOptionsFailure {
    type: GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface GetShowCommentSubjectAsDropdownConfigurationParameterRequest {
    type: GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_REQUEST_TYPE;
}

export interface GetShowCommentSubjectAsDropdownConfigurationParameterSuccess {
    type: GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_SUCCESS_TYPE;
    payload: {
        showCommentSubjectAsDropdownConfiguration: boolean
    };
}

export interface GetShowCommentSubjectAsDropdownConfigurationParameterFailure {
    type: GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface GetIPMaintenanceDisplaySettingsRequest {
    type: GET_IPMAINTENANCE_DISPLAY_SETTINGS_REQUEST_TYPE;
}

export interface GetIPMaintenanceDisplaySettingsSuccess {
    type: GET_IPMAINTENANCE_DISPLAY_SETTINGS_SUCCESS_TYPE;
    payload: {
        ipMaintenancePageDisplaySettings: IIPMaintenancePageUIConfiguration
    };
}

export interface GetIPMaintenanceDisplaySettingsFailure {
    type: GET_IPMAINTENANCE_DISPLAY_SETTINGS_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface GetProductMaintenanceEnableCuesheetRequest {
    type: GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_REQUEST_TYPE;
}
export interface GetProductMaintenanceEnableCuesheetSuccess {
    type: GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_SUCCESS_TYPE;
    payload: {
        enableCommonCuesheets: boolean
    };
}
export interface GetProductMaintenanceEnableCuesheetFailure {
    type: GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface GetProductMaintenanceCuesheetsDataSourceRequest {
    type: GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_REQUEST_TYPE;
}
export interface GetProductMaintenanceCuesheetsDataSourceSuccess {
    type: GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_SUCCESS_TYPE;
    payload: {
        cuesheetDatasource: any
    };
}
export interface GetProductMaintenanceCuesheetsDataSourceFailure {
    type: GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_FAILURE_TYPE;
    payload: {
        error: any
    }
}


export interface GetUsageGridSearchResultsDisplayOptionsRequest {
    type: GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_REQUEST_TYPE;
}

export interface GetUsageGridSearchResultsDisplayOptionsSuccess {
    type: GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_SUCCESS_TYPE;
    payload: {
        useNewUIgridSearchResults: boolean
    };
}
export interface GetUsageGridSearchResultsDisplayOptionsFailure {
    type: GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface GetIPGridSearchResultsDisplayOptionsRequest {
    type: GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_REQUEST_TYPE;
}

export interface GetIPGridSearchResultsDisplayOptionsSuccess {
    type: GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_SUCCESS_TYPE;
    payload: {
        useNewUIgridIPSearchResults: boolean
    };
}
export interface GetIPGridSearchResultsDisplayOptionsFailure {
    type: GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface GetPDArrangementMusicLyricRequest{
    type: GET_PD_ARRANGEMENT_MUSIC_LYRIC_REQUEST_TYPE;
}

export interface GetPDArrangementMusicLyricSuccess{
    type: GET_PD_ARRANGEMENT_MUSIC_LYRIC_SUCCESS_TYPE;
    payload:{
        pdArrangementMusicLyric: boolean
    };
}

export interface GetPDArrangementMusicLyricFailure{
    type:GET_PD_ARRANGEMENT_MUSIC_LYRIC_FAILURE_TYPE;
    payload:{
        error: any
    }
}

export interface GetWorkMaintenanceGeneralViewDisplayOptionsRequest {
    type: GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST_TYPE;
}

export interface GetWorkMaintenanceGeneralViewDisplayOptionsSuccess {
    type: GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS_TYPE;
    payload: {
        useNewUIWorkMaintenanceGeneralView: boolean
    };
}

export interface GetWorkMaintenanceGeneralViewDisplayOptionsFailure {
    type: GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface GetShareToleranceSettingsRequest {
    type: GET_SHARE_TOLERANCE_SETTINGS_REQUEST_TYPE;
}

export interface GetShareToleranceSettingsSuccess {
    type: GET_SHARE_TOLERANCE_SETTINGS_SUCCESS_TYPE;
    payload: {
        shareToleranceValues: IShareToleranceValueConfiguration
    };
}

export interface GetShareToleranceSettingsFailure {
    type: GET_SHARE_TOLERANCE_SETTINGS_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface GetProductMaintenanceGeneralViewDisplayOptionsRequest {
    type: GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST_TYPE;
}

export interface GetProductMaintenanceGeneralViewDisplayOptionsFailure {
    type: GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE_TYPE;
    payload: {
        error: any
    }
}
export interface GetProductMaintenanceGeneralViewDisplayOptionsSuccess {
    type: GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS_TYPE;
    payload: {
        useNewUIProductMaintenanceGeneralView: boolean
    };
}

export interface GetPoolMaintenanceGeneralViewDisplayOptionsRequest {
    type: GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST_TYPE;
}

export interface GetPoolMaintenanceGeneralViewDisplayOptionsFailure {
    type: GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE_TYPE;
    payload: {
        error: any
    }
}
export interface GetPoolMaintenanceGeneralViewDisplayOptionsSuccess {
    type: GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS_TYPE;
    payload: {
        useNewUIPoolMaintenanceGeneralView: boolean
    };
}

export interface GetDistributionMaintenanceSettingsDisplayOptionsRequest {
    type: GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_REQUEST_TYPE;
}

export interface GetDistributionMaintenanceSettingsDisplayOptionsSuccess {
    type: GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_SUCCESS_TYPE;
    payload: {
        jobStatusQA: string
    };
}

export interface GetDistributionMaintenanceSettingsDisplayOptionsFailure {
    type: GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_FAILURE_TYPE;
    payload: {
        error: any
    }
}


export interface GetUsageMatchingDefaultsProductsRequest {
    type: GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_REQUEST_TYPE;
}

export interface GetUsageMatchingDefaultsProductsSuccess {
    type: GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_SUCCESS_TYPE;
    payload: {
        usageMatchingSearchDefaultsProducts: string[]
    };
}

export interface GetUsageMatchingDefaultsProductsFailure {
    type: GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface GetUsageMatchingDefaultsWorksRequest {
    type: GET_USAGE_MATCHING_DEFAULTS_WORKS_REQUEST_TYPE;
}

export interface GetUsageMatchingDefaultsWorksSuccess {
    type: GET_USAGE_MATCHING_DEFAULTS_WORKS_SUCCESS_TYPE;
    payload: {
        usageMatchingSearchDefaultsWorks: string[]
    };
}

export interface GetUsageMatchingDefaultsWorksFailure {
    type: GET_USAGE_MATCHING_DEFAULTS_WORKS_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface GetShareDecimalsToDisplayRequest {
    type: GET_SHARE_DECIMALS_TO_DISPLAY_REQUEST_TYPE;
}

export interface GetShareDecimalsToDisplaySuccess {
    type: GET_SHARE_DECIMALS_TO_DISPLAY_SUCCESS_TYPE;
    payload: {
        decimalsToDisplay: number
    };
}

export interface GetShareDecimalsToDisplayFailure {
    type: GET_SHARE_DECIMALS_TO_DISPLAY_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface GetUserPreferenceRequest {
    type: GET_USER_PREFERENCES_REQUEST_TYPE;
}
export interface GetUserPreferenceSuccess {
    type: GET_USER_PREFERENCES_SUCCESS_TYPE;
    payload: {
        resultsPerPage: Array<IResultsPerPage>;
        activeAccordions: Array<IActiveAccordion>;
    }
}
export interface GetUserPreferenceFailure {
    type: GET_USER_PREFERENCES_FAILURE_TYPE;
}

export interface UpdateUserPreference {
    type: UPDATE_USER_PREFERENCE_TYPE;
    payload: {
        resultsPerPage: Array<IResultsPerPage>;
        activeAccordions: Array<IActiveAccordion>;
    }
}

export interface UpdatePagination {
    type: UPDATE_PAGINATION_TYPE;
    payload: {
        indexOfFirstResult: number,
        indexOfLastResult: number,
        resultsPerPage: number,
        currentPage: number,
        repertoireSection: string
    }
}

export interface SortSearchResults {
    type: SORT_SEARCH_RESULTS_TYPE;
    payload: {
        name: string,
        results: any
    }
}

export interface ResetPagination {
    type: RESET_PAGINATION_TYPE;
    payload: {
        repertoireSection: string
    }
}

export interface SetIsHundredResultsPerPage {
    type: SET_IS_HUNDRED_RESULTS_PER_PAGE_TYPE;
    payload: {
        isHundredResultsPerPage: boolean
    }
}

export interface StartWorkflowSession {
    type: START_WORKFLOW_SESSION_TYPE;
    payload: {
        items: IWorkflowSearchResult[];
        currentIndex: number
    }
}
export interface CancelWorkflowSession {
    type: CANCEL_WORKFLOW_SESSION_TYPE;
}
export interface NextWorkflowInSession {
    type: NEXT_WORKFLOW_IN_SESSION_TYPE;
}
export interface SkipWorkflowInSession {
    type: SKIP_WORKFLOW_IN_SESSION_TYPE;
}

export interface SetErrorWorkflowSession {
    type: SET_ERROR_WORKFLOW_SESSION_TYPE
}

export interface SetWarningMessage {
    type: SET_WARNING_MESSAGE_TYPE;
    payload: {
        mechWarning: boolean;
        perfWarning: boolean;
    }
}

export interface SeValidationtWarningMessage {
    type: SET_VALIDATION_WARNING_MESSAGE_TYPE;
    payload: {
        displayWarning: boolean;
    }
}

export interface SubmitProductAVRequest {
    type: SUBMIT_PRODUCT_AV_REQUEST_TYPE;
    payload: {
        productAVRequest: IProductAVRequest;
        productCoreID: number;
    }
}

export interface SubmitProductAVRequestFailure {
    type: SUBMIT_PRODUCT_AV_REQUEST_FAILURE_TYPE
}

export interface SubmitProductAVRequestSuccess {
    type: SUBMIT_PRODUCT_AV_REQUEST_SUCCESS_TYPE;
    payload: {
        productAVWorkSubmissions?: IProductAVWorkSubmission[];
    }
}

export interface GetProductMaintenanceSubmissionConfigRequest {
    type: GET_PRODUCT_MAINTENANCE_SUBMISSION_CONFIG_REQUEST_TYPE;
}

export interface GetProductMaintenanceSubmissionConfigFailure {
    type: GET_PRODUCT_MAINTENANCE_SUBMISSION_CONFIG_FAILURE_TYPE
}

export interface GetProductMaintenanceSubmissionConfigSuccess {
    type: GET_PRODUCT_MAINTENANCE_SUBMISSION_CONFIG_SUCCESS_TYPE;
    payload: {
        config: IProductMaintenanceSubmissionConfig;
    }
}

export interface SearchUsagePoolsRequest {
    type: SEARCH_USAGE_POOLS_REQUEST_TYPE;
}

export interface SearchUsagePoolsSuccess {
    type: SEARCH_USAGE_POOLS_SUCCESS_TYPE;
    payload: {
        usagePoolsResult: IUsagePool[];
    };
}

export interface SearchUsagePoolsFailure {
    type: SEARCH_USAGE_POOLS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SearchDistributionsRequest {
    type: SEARCH_DISTRIBUTIONS_REQUEST_TYPE;
}

export interface SearchDistributionsSuccess {
    type: SEARCH_DISTRIBUTIONS_SUCCESS_TYPE;
    payload: {
        distributionsResult: IDistribution[];
    };
}

export interface SearchDistributionsFailure {
    type: SEARCH_DISTRIBUTIONS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SearchLastPercentageRequest {
    type: SEARCH_LAST_PERCENTAGE_REQUEST_TYPE;
}

export interface SearchLastPercentageSuccess {
    type: SEARCH_LAST_PERCENTAGE_SUCCESS_TYPE;
    payload: {
        distributionsResult: IDistributionSubjects[];
        lookups: ILookupDictionary
    };
}

export interface SearchLastPercentageFailure {
    type: SEARCH_LAST_PERCENTAGE_FAILURE_TYPE;
    payload: {
        error: string;
    };
}
export interface SearchAdjustmentsRequest {
    type: SEARCH_ADJUSTMENTS_REQUEST_TYPE;
}

export interface SearchAdjustmentsSuccess {
    type: SEARCH_ADJUSTMENTS_SUCCESS_TYPE;
    payload: {
        adjustmentSearchResult: IAdjustment[];
    }
}

export interface SearchAdjustmentsFailure {
    type: SEARCH_ADJUSTMENTS_FAILURE_TYPE;
    payload: {
        error: string;
    }
}

export interface GetDistributionTypesRequest {
    type: GET_DISTRIBUTION_TYPES_REQUEST_TYPE;
}

export interface GetDistributionTypesSuccess {
    type: GET_DISTRIBUTION_TYPES_SUCCESS_TYPE;
    payload: {
        distributionTypesResult: IDistributionType[];
    };
}

export interface GetDistributionTypesFailure {
    type: GET_DISTRIBUTION_TYPES_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface GetDistributionSubTypesRequest {
    type: GET_DISTRIBUTION_SUB_TYPES_REQUEST_TYPE;
}

export interface GetDistributionSubTypesSuccess {
    type: GET_DISTRIBUTION_SUB_TYPES_SUCCESS_TYPE;
    payload: {
        distributionSubTypesResult: IDistributionSubType[];
    };
}

export interface GetDistributionSubTypesFailure {
    type: GET_DISTRIBUTION_SUB_TYPES_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface GetDistributionRequest {
    type: GET_DISTRIBUTION_REQUEST_TYPE
}

export interface GetDistributionSuccess {
    type: GET_DISTRIBUTION_SUCCESS_TYPE;
    payload: {
        distribution: IDistribution
    }
}

export interface GetDistributionFailure {
    type: GET_DISTRIBUTION_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface UpdateDistributionField {
    type: UPDATE_DISTRIBUTION_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IDistributionStateKeys;
        lookups?: ILookupDictionary;
        subFieldName?: string;
    }
}

export interface SaveDistributionRequest {
    type: SAVE_DISTRIBUTION_REQUEST_TYPE
}

export interface SaveDistributionSuccess {
    type: SAVE_DISTRIBUTION_SUCCESS_TYPE;
    payload: {
        distributionSaveResult: IDistributionSaveResult;
        lookupValues: ILookupDictionary
    }
}

export interface SaveDistributionFailure {
    type: SAVE_DISTRIBUTION_FAILURE_TYPE;
    payload: {
        distributionSaveResult: IDistributionSaveResult;
        lookupValues: ILookupDictionary
    }
}

export interface ClearPackagesRequest {
    type: CLEAR_PACKAGES_REQUEST_TYPE
}

export interface ClearPackagesSuccess {
    type: CLEAR_PACKAGES_SUCCESS_TYPE;
    payload: {
        myPackages: IMyPackagesResult;
    }
}

export interface ClearPackagesFailure {
    type: CLEAR_PACKAGES_FAILURE_TYPE;
    payload: {
        myPackages: IMyPackagesResult;
    }
}

export interface UpdateForeignSocietyInfo {
    type: UPDATE_FOREIGN_SOCIETY_INFO_TYPE;
    payload: {
        distributionPoolID: number;
        value: any;
        fieldName: string;
    }
}

export interface AllocateDistributionPoolsRequest {
    type: ALLOCATE_DISTRIBUTION_POOLS_REQUEST_TYPE
}

export interface AllocateDistributionPoolsSuccess {
    type: ALLOCATE_DISTRIBUTION_POOLS_SUCCESS_TYPE;
    payload: {
        allocateDistributionPoolsResponse: IAllocateDistributionPoolsResponse
    }
}

export interface AllocateDistributionPoolsFailure {
    type: ALLOCATE_DISTRIBUTION_POOLS_FAILURE_TYPE;
    payload: {
        allocateDistributionPoolsResponse: IAllocateDistributionPoolsResponse
    }
}

export interface RunQADistributionPoolsRequest {
    type: RUN_QA_DISTRIBUTION_POOLS_REQUEST_TYPE
}

export interface RunQADistributionPoolsSuccess {
    type: RUN_QA_DISTRIBUTION_POOLS_SUCCESS_TYPE;
    payload: {
        qaDistributionPoolsResponse: IRunQADistributionPoolsResponse
    }
}

export interface RunQADistributionPoolsFailure {
    type: RUN_QA_DISTRIBUTION_POOLS_FAILURE_TYPE;
    payload: {
        qaDistributionPoolsResponse: IRunQADistributionPoolsResponse
    }
}
export interface ExportDistributionPoolsRequest {
    type: EXPORT_DISTRIBUTION_POOLS_REQUEST_TYPE;
}

export interface ExportDistributionPoolsSuccess {
    type: EXPORT_DISTRIBUTION_POOLS_SUCCESS_TYPE;
    payload: {
        distributionSaveResult: IDistributionSaveResult;
        lookupValues: ILookupDictionary
    }
}

export interface ExportDistributionPoolsFailure {
    type: EXPORT_DISTRIBUTION_POOLS_FAILURE_TYPE;
    payload: {
        error: string;
    }
}

export interface UpdateSelectionCriteria {
    type: UPDATE_SELECTION_CRITERIA_TYPE;
    payload: {
        distributionPoolId: number,
        value: ISelectionCriteria
    }
}
export interface UpdateRepresentationSelector {
    type: UPDATE_REPRESENTATION_SELECTOR_TYPE;
    payload: {
        repId: number,
        value: IIPRepresentation
    }
}


export interface ClearRepertoireCache {
    type: CLEAR_REPERTOIRE_CACHE_TYPE;
}

export interface UpdateClaimField {
    type: UPDATE_CLAIM_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IClaimStateKeys;
        lookups?: ILookupDictionary;
    }
}

export interface SetWorkGroupMaintenanceState {
    type: SET_WORK_GROUP_MAINTENANCE_STATE_TYPE;
    payload: {
        ipLicensingRepresentation: IIpLicensingRepresentation;
    }
}

export interface SaveWorkFlowRequest {
    type: SAVE_WORKFLOW_REQUEST_TYPE;
    payload: {
        workflow: any;
    }
}

export interface SaveWorkFlowSuccess {
    type: SAVE_WORKFLOW_SUCCESS_TYPE;
    payload: {
        workFlowResult: IWorkFlowSaveResult;
        callFrom: string;
        isWFSession: boolean;
    }
}

export interface SaveWorkFlowFailure {
    type: SAVE_WORKFLOW_FAILURE_TYPE;
    payload: {
        error: any;
    };
}

export interface ApproveRejectWorkFlowSuccess {
    type: APPROVE_REJECT_WORKFLOW_SUCCESS_TYPE;
    payload: {
        workflow: any;
    }
}

export interface SaveAdjustmentRequest {
    type: SAVE_ADJUSTMENT_REQUEST_TYPE
}

export interface SaveAdjustmentSuccess {
    type: SAVE_ADJUSTMENT_SUCCESS_TYPE;
    payload: {
        adjustment: IAdjustmentSaveResult;
    }
}

export interface SaveAdjustmentFailure {
    type: SAVE_ADJUSTMENT_FAILURE_TYPE;
    payload: {
        adjustmentSaveResult: IAdjustmentSaveResult;
    }
}

export interface CopyAdjustment {
    type: COPY_ADJUSTMENT_TYPE;
}

export interface GetAdjustmentDetailsRequest {
    type: GET_ADJUSTMENT_DETAILS_REQUEST_TYPE;
}

export interface GetAdjustmentDetailsSuccess {
    type: GET_ADJUSTMENT_DETAILS_SUCCESS_TYPE;
    payload: {
        adjustment: IAdjustment;
    }
}

export interface GetAdjustmentDetailsFailure {
    type: GET_ADJUSTMENT_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
    }
}

export interface UndoAdjustmentChanges {
    type: UNDO_ADJUSTMENT_CHANGES_TYPE;
}
export interface UndoAdjustmentChangesSuccess {
    type: UNDO_ADJUSTMENT_CHANGES_SUCCESS_TYPE;
    payload: {
        adjustment: IAdjustment;
    }
}
export interface UndoAdjustmentChangesFailure {
    type: UNDO_ADJUSTMENT_CHANGES_FAILURE_TYPE;
    payload: {
        error: any;
    }
}

export interface UpdateAdjustmentField {
    type: UPDATE_ADJUSTMENT_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IAdjustmentStateKeys;
    }
}

export interface CreateNewAdjustment {
    type: CREATE_NEW_ADJUSTMENT_TYPE;
    payload: {
        lookupValues: ILookupDictionary,
        isNew?: boolean,
        adjustmentMaintenanceGeneralViewData?: IRepertoireComponentDataItem
    }
}
export interface CreateNewMyPackages {
    type: CREATE_NEW_MY_PACKAGES_TYPE;
    payload: {
        lookupValues: ILookupDictionary,
        isNew?: boolean
        myPackagesGeneralDataViewData?:IRepertoireComponentDataItem
    }
}

export interface IPTransferRequest {
    type: IP_TRANSFER_REQUEST_TYPE;
}

export interface IPTransferSuccess {
    type: IP_TRANSFER_SUCCESS_TYPE;
}

export interface IPTransferFailure {
    type: IP_TRANSFER_FAILURE_TYPE;
}

export interface IpTransferJobRequest {
    type: IP_TRANSFER_JOB_REQUEST_TYPE;
}

export interface IpTransferJobSuccess {
    type: IP_TRANSFER_JOB_REQUEST_SUCCESS_TYPE;
    payload: {
        result: IIPSaveResult
    }
}

export interface IpTransferJobFailure {
    type: IP_TRANSFER_JOB_REQUEST_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface SearchDatabricksAllocationsRequest {
    type: SEARCH_DATABRICKS_ALLOCATIONS_REQUEST_TYPE
}

export interface SearchDatabricksAllocationsSuccess {
    type: SEARCH_DATABRICKS_ALLOCATIONS_SUCCESS_TYPE;
    payload: {
        result: any;
    }
}

export interface SearchDatabricksAllocationsFailure {
    type: SEARCH_DATABRICKS_ALLOCATIONS_FAILURE_TYPE
}

export interface AddIpToAdjustmentDetails {
    type: ADD_IP_TO_ADJUSTMENT_DETAILS_TYPE;
    payload: {
        ip: IP;
        id: number;
        index: number;
    }
}

export interface AddIpToUsageDetails {
    type: ADD_IP_TO_USAGE_DETAILS_TYPE;
    payload: {
        ip: IP;
        usageDetailsIndex: number;
        usageRowIndex: number;
        adjustmentsIndex: number;
    }
}

export interface GetPoolByCodeRequest {
    type: SEARCH_POOL_BY_CODE_REQUEST_TYPE;
}

export interface GetPoolByCodeSuccess {
    type: SEARCH_POOL_BY_CODE_REQUEST_SUCCESS_TYPE,
    payload: {
        pools: IUsagePool[];
    }
}
export interface GetPoolByCodeFailure {
    type: SEARCH_POOL_BY_CODE_REQUEST_FAILURE_TYPE;
}

export interface SavePaymentRunRequest {
    type: SAVE_PAYMENTRUN_REQUEST_TYPE
}

export interface SavePaymentRunSuccess {
    type: SAVE_PAYMENTRUN_SUCCESS_TYPE;
    payload: {
        paymentRunSaveResult: IPaymentRunSaveResult;
        lookupValues: ILookupDictionary
    }
}

export interface SavePaymentRunFailure {
    type: SAVE_PAYMENTRUN_FAILURE_TYPE;
    payload: {
        paymentRunSaveResult: IPaymentRunSaveResult;
        lookupValues: ILookupDictionary
    }
}

export interface DeletePaymentRunRequest {
    type: DELETE_PAYMENTRUN_REQUEST_TYPE;
}

export interface DeletePaymentRunSuccess {
    type: DELETE_PAYMENTRUN_SUCCESS_TYPE;
    payload: {
        paymentRunId: number;
    }
}

export interface DeletePaymentRunFailure {
    type: DELETE_PAYMENTRUN_FAILURE_TYPE;
    payload: {
        error?: IValidationMessage;
    }
}

export interface SearchPaymentRunsRequest {
    type: SEARCH_PAYMENTRUNS_REQUEST_TYPE;
}

export interface SearchPaymentRunsSuccess {
    type: SEARCH_PAYMENTRUNS_SUCCESS_TYPE;
    payload: {
        paymentRunsResult: IPaymentRun[];
    };
}

export interface SearchPaymentRunsFailure {
    type: SEARCH_PAYMENTRUNS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface GetPaymentRunRequest {
    type: GET_PAYMENTRUN_REQUEST_TYPE
}

export interface GetPaymentRunSuccess {
    type: GET_PAYMENTRUN_SUCCESS_TYPE;
    payload: {
        paymentRun: IPaymentRun
    }
}

export interface GetPaymentRunFailure {
    type: GET_PAYMENTRUN_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface GetPaymentRunIDsRequest {
    type: GET_PAYMENTRUN_ID_REQUEST_TYPE;
}

export interface GetPaymentRunIDsSuccess {
    type: GET_PAYMENTRUN_ID_SUCCESS_TYPE;
    payload: {
        paymentRunIDs: string[];
        searchBody?: IPaymentRunSearchQuery;
    };
}

export interface GetPaymentRunIDsFailure {
    type: GET_PAYMENTRUN_ID_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface UpdatePaymentRunField {
    type: UPDATE_PAYMENTRUN_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IPaymentRunStateKeys;
        lookups?: ILookupDictionary;
        subFieldName?: string;
    }
}

export interface SearchLicensesRequest {
    type: SEARCH_LICENSES_REQUEST_TYPE;
}

export interface SearchLicensesSuccess {
    type: SEARCH_LICENSES_SUCCESS_TYPE;
    payload: {
        licensesResult: IShoppingCart[];
    };
}

export interface SearchLicensesFailure {
    type: SEARCH_LICENSES_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface UpdateLicenseField {
    type: UPDATE_LICENSE_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IRepertoireStateKeys;
        lookups?: ILookupDictionary;
        subFieldName?: string;
    }
}

export interface GetLicenseRequest {
    type: GET_LICENSE_REQUEST_TYPE
}

export interface GetLicenseSuccess {
    type: GET_LICENSE_SUCCESS_TYPE;
    payload: {
        license: IShoppingCart
    }
}

export interface GetLicenseFailure {
    type: GET_LICENSE_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface UndoLicenseSuccess {
    type: UNDO_LICENSE_SUCCESS_TYPE;
    payload: {
        license: IShoppingCartState
    }
}

export interface SubmitLicenseSuccess {
    type: SUBMIT_LICENSE_SUCCESS_TYPE;
    payload: {
        licenseSubmitResult: ILicenseSubmitResult,
        licenseRequestID: number
    }
}

export interface SubmitLicenseFailure {
    type: SUBMIT_LICENSE_FAILURE_TYPE;
    payload: {
        licenseSubmitResult: ILicenseSubmitResult,
        licenseRequestID: number
    }
}
export interface GetClaimSuccess {
    type: GET_CLAIM_SUCCESS_TYPE;
    payload: {
        claim: IClaim
    }
}

export interface GetClaimFailure {
    type: GET_CLAIM_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface SaveLicenseRequest {
    type: SAVE_LICENSE_REQUEST_TYPE
}

export interface SaveLicenseSuccess {
    type: SAVE_LICENSE_SUCCESS_TYPE;
    payload: {
        result: ILicenseSaveResult;
        lookupValues: ILookupDictionary;
        licenseRequestWorkParameterFields: ILicenseInputItem[];
    }
}

export interface SaveLicenseFailure {
    type: SAVE_LICENSE_FAILURE_TYPE;
    payload: {
        licenseSaveResult: ILicenseSaveResult;
        lookupValues: ILookupDictionary;
        licenseRequestWorkParameterFields: ILicenseInputItem[];
    }
}

export interface DeleteLicenseRequest {
    type: DELETE_LICENSE_REQUEST_TYPE;
}

export interface DeleteLicenseSuccess {
    type: DELETE_LICENSE_SUCCESS_TYPE;
    payload: {
        licenseId: number;
    }
}

export interface DeleteLicenseFailure {
    type: DELETE_LICENSE_FAILURE_TYPE;
    payload: {
        error?: IValidationMessage;
    }
}

export interface IncrementTempID {
    type: INCREMENT_TEMPID_TYPE;
}

export interface SearchLicenseUserRequest {
    type: SEARCH_LICENSE_USER_REQUEST_TYPE;
}

export interface SearchLicenseUserSuccess {
    type: SEARCH_LICENSE_USER_SUCCESS_TYPE;
    payload: {
        licenseUserResults: ILicenseUserSearchResult[];
    }
}

export interface SearchLicenseUserFailure {
    type: SEARCH_LICENSE_USER_FAILURE_TYPE;
    payload: {
        error: string;
    }
}

export interface GetShoppingCartIDsSuccess {
    type: GET_SHOPPING_CART_PARAMETERS_SUCCESS_TYPE;
    payload: {
        shoppingCartParamtersID: any;
    };
}

export interface GetShoppingCartIDsFailure {
    type: GET_SHOPPING_CART_PARAMETERS_FAILURE_TYPE;
    payload: {
        error: any;
    };
}

export interface UpdateLicenceUserValues {
    type: UPDATE_LICENSE_USER_TYPE;
    payload: {
        params: any;
    };
}
export interface GetLicenseRequestWorkParameterRequest {
    type: GET_LICENSE_REQUEST_WORK_PARAMETERS_REQUEST_TYPE;
}
export interface GetLicenseRequestWorkParameterSuccess {
    type: GET_LICENSE_REQUEST_WORK_PARAMETERS_SUCCESS_TYPE;
    payload: {
        licenseInputs: ILicenseInputItem[],
        licenseRequestWorkID: number
    };
}
export interface GetLicenseRequestWorkParameterFailure {
    type: GET_LICENSE_REQUEST_WORK_PARAMETERS_FAILURE_TYPE;
    payload: {
        params: any;
    };
}

export interface GetLicenseWorksContributors {
    type: GET_LICENSE_WORKS_CONTRIBUTORS_REQUEST_TYPE;
}
export interface GetLicenseWorksContributorsSuccess {
    type: GET_LICENSE_WORKS_CONTRIBUTORS_SUCCESS_TYPE;
    payload: {
        workContributors: IWorkContributor[]
    };
}
export interface GetLicenseWorksContributorsFailure {
    type: GET_LICENSE_WORKS_CONTRIBUTORS_FAILURE_TYPE;
    payload: {
        params: any;
    };
}

export interface ImportLicenseWorksPrices {
    type: IMPORT_LICENSE_WORKS_PRICES_TYPE;
    payload: {
        licenseWorksPriceImport: ILicenseRequestWorkItem[];
    }
}

export interface UpdateUsageGroupSetListAdditional {
    type: UPDATE_USAGE_GROUP_SET_LIST_ADDITIONAL_TYPE,
    payload: {
        newSetListAdditional: IUsageGroupSetListAdditional
    }
}
export interface UpdateUsageGroupVenueDetails {
    type: UPDATE_USAGE_GROUP_VENUE_DETAILS_TYPE,
    payload: {
        newVenueDetails: IUsageGroupVenueDetails
    }
}

export interface UpdateUsageGroupLocation {
    type: UPDATE_USAGE_GROUP_LOCATION_TYPE,
    payload: {
        newLocation: IUsageGroupLocation
    }
}

export interface CalculateLicenseWorksPricesRequest {
    type: CALCULATE_LICENSE_WORKS_PRICES_REQUEST_TYPE;
}

export interface CalculateLicenseWorksPricesSuccess {
    type: CALCULATE_LICENSE_WORKS_PRICES_SUCCESS_TYPE;
    payload: {
        bookPriceCalculationsResponse: IBookPriceCalculationsResponse;
    };
}
export interface CalculateLicenseWorksPricesFailure {
    type: CALCULATE_LICENSE_WORKS_PRICES_FAILURE_TYPE;
    payload: {
        bookPriceCalculationsResponse: IBookPriceCalculationsResponse;
    };
}

export interface GetCommentCategoryFlagRequest {
    type: GET_COMMENT_CATEGORY_FLAG_REQUEST_TYPE;
}

export interface GetCommentCategoryFlagSuccess {
    type: GET_COMMENT_CATEGORY_FLAG_SUCCESS_TYPE;
    payload: {
        commentCategoryFlag: boolean;
    };
}
export interface GetCommentCategoryFlagFailure {
    type: GET_COMMENT_CATEGORY_FLAG_FAILURE_TYPE;
}

export interface SaveIPNamesSuccess {
    type: SAVE_IPNAMES_SUCCESS_TYPE;
    payload: {
        ipNames: IName[];
    };
}

export interface GetOpenDistributionsRequest {
    type: GET_OPEN_DISTRIBUTIONS_REQUEST_TYPE   
}

export interface GetOpenDistributionsSuccess {    
    type: GET_OPEN_DISTRIBUTIONS_SUCCESS_TYPE,
    payload: {
        openDistributions
    }    
}

export interface GetOpenDistributionsFailure {    
    type: GET_OPEN_DISTRIBUTIONS_FAILURE_TYPE,
    payload: {
        value
    }    
}

export interface GetAgreementAttachmentTypeRequiredRequest {
    type: GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_REQUEST_TYPE;
}

export interface GetAgreementAttachmentTypeRequiredSuccess {
    type: GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_SUCCESS_TYPE;
    payload: {
        agreementAttachmentTypeRequired: string;
    };
}
export interface GetAgreementAttachmentTypeRequiredFailure {
    type: GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_FAILURE_TYPE;
}

export interface GetExcludeSourceTypeShowFieldsRequest {
    type: GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_REQUEST_TYPE;
}

export interface GetExcludeSourceTypeShowFieldsSuccess {
    type: GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_SUCCESS_TYPE;
    payload: {
        excludeSourceTypePickShowIP: string[];
    };
}
export interface GetExcludeSourceTypeShowFieldsFailure {
    type: GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_FAILURE_TYPE;
}

export interface TriggerManualClaimJobSuccess {
    type: TRIGGER_MANUAL_CLAIM_JOB_SUCCESS_TYPE,
}

export interface TriggerManualClaimJobRequest {
    type: TRIGGER_MANUAL_CLAIM_JOB_REQUEST_TYPE,
}


export interface TriggerManualClaimJobFailure {
    type: TRIGGER_MANUAL_CLAIM_JOB_FAILURE_TYPE,
    payload: {
        error: string;
    };
}

export interface GetRecalculateDomesticorForeignFlagRequest {
    type: GET_DOMESTIC_OR_FOREIGN_RECALCULATE_REQUEST_TYPE;
}

export interface GetRecalculateDomesticorForeignFlagSuccess {
    type: GET_DOMESTIC_OR_FOREIGN_RECALCULATE_SUCCESS_TYPE;
    payload: {
        recalculateDomesticorForeign: boolean
    };
}

export interface GetRecalculateDomesticorForeignFlagFailure {
    type: GET_DOMESTIC_OR_FOREIGN_RECALCULATE_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface UpdatePoolCodeToSearch{
    type: UPDATE_POOL_CODE_TO_SEARCH_TYPE,
    payload: {
        poolCode: string
    }
}

export type ActionTypes =
    | RemovePaneTwoAction
    | GetPoolMaintenanceGeneralViewDisplayOptionsSuccess
    | GetProductMaintenanceGeneralViewDisplayOptionsSuccess
    | GetShortCutKeysSuccess
    | UpdateProductFields
    | UpdateWorkFields
    | GetWorkMaintenanceGeneralViewDisplayOptionsSuccess
    | GetShareToleranceSettingsSuccess
    | GetDistributionMaintenanceSettingsDisplayOptionsSuccess
    | GetProductMaintenanceSubmissionConfigRequest
    | GetProductMaintenanceSubmissionConfigFailure
    | GetProductMaintenanceSubmissionConfigSuccess
    | ReduxStorageLoadAction
    | SetDataSourceAction
    | SetAgreementDataSourceAction
    | SetProductDataSourceAction
    | SetAdjustmentDataSource
    | RepertoireComponentDataRequest
    | RepertoireComponentDataSuccess
    | RepertoireComponentDataFailure
    | AddTabAction
    | ReplaceTabAction
    | RemoveTabAction
    | OpenTabAction
    | setActivePaneAction
    | SearchWorksRequest
    | SearchWorksSuccess
    | SearchWorksFailure
    | SearchArtistsRequest
    | SearchArtistsSuccess
    | SearchArtistsFailure
    | SearchContributorRequest
    | SearchContributorsSuccess
    | SearchContributorsFailure
    | SearchAgreementsRequest
    | SearchAgreementsSuccess
    | SearchAgreementsFailure
    | SearchIPsRequest
    | SearchIPsSuccess
    | SearchIPsFailure
    | SearchProductsRequest
    | SearchProductsSuccess
    | SearchProductsFailure
    | ClearModalSearchResults
    | GetWorkDetailsRequest
    | GetWorkDetailsSuccess
    | GetWorkDetailsFailure
    | RefreshWorkDetailsRequest
    | RefreshWorkDetailsSuccess
    | RefreshWorkDetailsFailure
    | GetAgreementDetailsRequest
    | GetAgreementDetailsSuccess
    | GetAgreementDetailsFailure
    | GetProductDetailsRequest
    | GetProductDetailsSuccess
    | GetProductDetailsFailure
    | GetArtistDetailsRequest
    | GetArtistDetailsSuccess
    | GetArtistDetailsFailure
    | AddArtistToWork
    | AddArtistToProduct
    | AddWorkToProduct
    | UpdateWorkContributorIPRequest
    | UpdateWorkContributorIPSuccess
    | UpdateWorkContributorIPFailure
    | UpdateProductContributorIP
    | UpdateAgreementIP
    | UpdateAgreementWork
    | UpdateAgreementCarveOutProduct
    | CopyExistingWorkSuccess
    | UndoWorkChangesRequest
    | UndoWorkChangesSuccess
    | UndoWorkChangesFailure
    | MergeMatchingWorkRequest
    | MergeMatchingWorkSuccess
    | MergeMatchingWorkFailure
    | MergeMatchingIPRequest
    | MergeMatchingIPSuccess
    | MergeMatchingIPFailure
    | MergeMatchingProductSuccess
    | MergeMatchingProductFailure
    | MergeRepertoireWorkRequest
    | MergeRepertoireWorkSuccess
    | MergeRepertoireWorkFailure
    | MergeRepertoireProductSuccess
    | MergeRepertoireProductFailure
    | DeleteWorkRequest
    | DeleteWorkSuccess
    | DeleteWorkFailure
    | CreateNewWork
    | CreateNewAgreement
    | CreateNewProduct
    | CreateNewArtist
    | SaveWorkRequest
    | SaveWorkSuccess
    | SaveWorkFailure
    | SaveWorkNumberAdditionalSuccess
    | SaveWorkNumberAdditionalFailure
    | GetCurrentPageAdditionalWorkNumberDetailsSuccess
    | GetCurrentPageAdditionalWorkNumberDetailsFailure
    | SaveAgreementRequest
    | SaveAgreementSuccess
    | SaveAgreementFailure
    | CopyExistingAgreement
    | DeleteAgreementRequest
    | DeleteAgreementSuccess
    | DeleteAgreementFailure
    | DeleteDistributionRequest
    | DeleteDistributionSuccess
    | DeleteDistributionFailure
    | ReadonlyFlagsFieldSuccess
    | ReadonlyFlagsFieldRequest
    | ReadonlyFlagsFieldFailure
    | SaveProductRequest
    | SaveProductSuccess
    | SaveProductFailure
    | SaveArtistRequest
    | SaveArtistSuccess
    | SaveArtistFailure
    | CopyExistingProduct
    | DeleteProductRequest
    | DeleteProductSuccess
    | DeleteProductFailure
    | CreateNewAccount
    | SaveAccountRequest
    | SaveAccountSuccess
    | SaveAccountFailure
    | OpenAccordion
    | CloseWorkMaintenanceAccordian
    | SetChangesMade
    | UpdateWorkField
    | UpdateAgreementField
    | UpdateMatchingWork
    | UpdateMatchingProduct
    | UpdateRelatedWork
    | UpdateWorkSourceIP
    | UpdateProductSourceIP
    | UpdateInterestedPartyField
    | UpdateRepresentationField
    | UpdateProductField
    | UpdateArtistField
    | UpdateScheduledJobField
    | SortWorkFieldData
    | SortAgreementFieldData
    | SortWeightSettingsFieldData
    | SortDistributionPoolFieldData
    | SortInterestedPartyFieldData
    | SortProductFieldData
    | SortArtistFieldData
    | PostWorkBatchRequest
    | PostWorkBatchSuccess
    | PostWorkBatchFailed
    | GetWorkMatchConnectorRequest
    | GetWorkMatchConnectorSuccess
    | GetWorkMatchConnectorFailed
    | MatchWorkBatchRequest
    | MatchWorkBatchSuccess
    | MatchWorkBatchFailed
    | PostProductBatchRequest
    | PostProductBatchSuccess
    | PostProductBatchFailed
    | MatchProductBatchRequest
    | MatchProductBatchSuccess
    | MatchProductBatchFailed
    | PostAgreementBatchRequest
    | PostAgreementBatchSuccess
    | PostAgreementBatchFailed
    | PostAdjustmentBatchRequest
    | PostAdjustmentBatchSuccess
    | PostAdjustmentBatchFailed
    | AddSetTypes
    | RemoveSetTypes
    | ClearSetTypes
    | SwitchSetTypeGroup
    | UpdateContributorSetType
    | UndoAgreementChangesRequest
    | UndoAgreementChangesSuccess
    | UndoAgreementChangesFailure
    | UndoDistributionChangesRequest
    | UndoDistributionChangesSuccess
    | UndoDistributionChangesFailure
    | UndoArtistChangesRequest
    | UndoArtistChangesSuccess
    | UndoArtistChangesFailure
    | UndoIPChangesRequest
    | UndoIPChangesSuccess
    | UndoIPChangesFailure
    | UndoProductChangesRequest
    | UndoProductChangesSuccess
    | UndoProductChangesFailure
    | AutoMatchWork
    | SearchWorkVersionHistoryRequest
    | SearchWorkVersionHistorySuccess
    | SearchWorkVersionHistoryFailure
    | SearchProductVersionHistoryRequest
    | SearchProductVersionHistorySuccess
    | SearchProductVersionHistoryFailure
    | SearchPaymentRunVersionHistoryRequest
    | SearchPaymentRunVersionHistorySuccess
    | SearchPaymentRunVersionHistoryFailure
    | SearchAdjustmentVersionHistoryRequest
    | SearchAdjustmentVersionHistorySuccess
    | SearchAdjustmentVersionHistoryFailure
    | ResetMessageBanner
    | DeleteAccountRequest
    | DeleteAccountRequestSuccess
    | DeleteAccountRequestFailure
    | DeleteArtistRequest
    | DeleteArtistRequestSuccess
    | DeleteArtistRequestFailure
    | UpdateIpAgreementFilterStateField
    | UpdateIpRepresentationFilterStateField
    | FilterIpAgreementsRequest
    | FilterIpRepresentationsRequest
    | FilterIpAgreementsSuccess
    | FilterIpRepresentationsSuccess
    | ContributorsDisplaySettingSuccess
    | ContributorsDisplaySettingRequest
    | ContributorsDisplaySettingFailure
    | GenreCategoriesSuccess
    | GenreCategoriesRequest
    | GenreCategoriesFailure
    | OtherIndicatorWorkFlagTypeSuccess
    | OtherIndicatorWorkFlagTypeFailure
    | OtherIndicatorWorkFlagTypeRequest
    | UpdateComponentFieldRequest
    | UpdateComponentFieldSuccess
    | UpdateComponentFieldFailure
    | SetEditableFields
    | UpdateEditableField
    | GetFormatFieldsRequest
    | GetFormatFieldsSuccess
    | GetFormatFieldsFailure
    | ApplyFormatsWorkMaintenance
    | ApplyFormatsProductMaintenance
    | ExpandWorkResult
    | ExpandUsageDetail
    | ExpandProductResult
    | ExpandAllResults
    | EnableCustomField
    | AddRepertoireAttachmentRequest
    | AddRepertoireAttachmentSuccess
    | AddRepertoireAttachmentFailure
    | RemoveWorkAttachmentRequest
    | RemoveWorkAttachmentSuccess
    | RemoveWorkAttachmentFailure
    | AddDroppedFile
    | SetProgressUploadFileRepertoireProccess
    | CleanDroppedFilesState
    | RemoveWorkAttachmentFailure
    | UpdateSetTypeOnBreak
    | SearchWorkflowRequest
    | SearchWorkflowSuccess
    | SearchWorksFailure
    | SelectSingleWorkflow
    | AssignWorkflowsRequest
    | AssignWorkflowsSuccess
    | AssignWorkflowsFailure
    | UpdateStatusWorkflowsRequest
    | UpdateStatusWorkflowsSuccess
    | UpdateStatusWorkflowsFailure
    | ClearWorkflowSelection
    | GetUsersRequest
    | GetUsersFailure
    | GetUsersSuccess
    | SelectAllWorkflow
    | MarkSelectAll
    | ReadonlyIndicatorWorkFlagTypeSuccess
    | ReadonlyIndicatorWorkFlagTypeFailure
    | ReadonlyIndicatorWorkFlagTypeRequest
    | GetAgreementDisplayOptionsRequest
    | GetAgreementDisplayOptionsSuccess
    | GetAgreementDisplayOptionsFailure
    | GetUserPreferenceRequest
    | GetUserPreferenceSuccess
    | GetUserPreferenceFailure
    | UpdateUserPreference
    | UpdatePagination
    | ResetPagination
    | SetIsHundredResultsPerPage
    | RemoveAgreementAttachmentRequest
    | RemoveAgreementAttachmentSuccess
    | RemoveAgreementAttachmentFailure
    | DeleteBlobRequest
    | DeleteBlobSuccess
    | DeleteBlobFailure
    | GetShareDecimalsToDisplayRequest
    | GetShareDecimalsToDisplaySuccess
    | GetShareDecimalsToDisplayFailure
    | SetAgreementSearchTypeAction
    | StartWorkflowSession
    | CancelWorkflowSession
    | SkipWorkflowInSession
    | NextWorkflowInSession
    | SetErrorWorkflowSession
    | SetWarningMessage
    | SeValidationtWarningMessage
    | SubmitProductAVRequest
    | SubmitProductAVRequestFailure
    | SubmitProductAVRequestSuccess
    | GetUsageRequest
    | GetUsageSuccess
    | GetUsageFailure
    | SearchUsageGroupRequest
    | SearchUsageGroupFailure
    | SearchUsageGroupSuccess
    | GetUsageDistributionsFailure
    | GetUsageDistributionsRequest
    | GetUsageDistributionsSuccess
    | GetUsagePoolsRequest
    | GetUsagePoolsSuccess
    | GetUsagePoolsFailure
    | GetUsageMatchingDefaultsProductsRequest
    | GetUsageMatchingDefaultsProductsSuccess
    | GetUsageMatchingDefaultsProductsFailure
    | GetUsageMatchingDefaultsWorksRequest
    | GetUsageMatchingDefaultsWorksSuccess
    | GetUsageMatchingDefaultsWorksFailure
    | GetSourceMajorsRequest
    | GetSourceMajorsFailure
    | GetSourceMajorsSuccess
    | GetSourceMinorsFailure
    | GetSourceMinorsRequest
    | GetSourceMinorsSuccess
    | SearchMatchWorksFailure
    | SearchMatchWorksRequest
    | SearchMatchWorksSuccess
    | UsageExpandAllResults
    | ExpandMatchWorkResult
    | SetUsageTypeAction
    | AddMatchWorkToUsage
    | ExpandMatchWorkResult
    | SaveUsageGroupSuccess
    | SaveUsageGroupFailure
    | SaveUsageGroupRequest
    | ResetUsageMessageBanner
    | UpdateUsageField
    | UpdateUsageRowDropdown
    | SearchDataChanged
    | GetSourcesFailure
    | GetSourcesRequest
    | GetSourcesSuccess
    | SearchUsagePoolsRequest
    | SearchUsagePoolsSuccess
    | SearchUsagePoolsFailure
    | SearchMatchProductsFailure
    | SearchMatchProductsSuccess
    | AddMatchProductToUsage
    | GetUsagePoolRequest
    | GetUsagePoolSuccess
    | GetUsagePoolFailure
    | UpdateUsagePoolField
    | SaveUsagePoolRequest
    | SaveUsagePoolSuccess
    | SaveUsagePoolFailure
    | DeleteUsagePoolRequest
    | DeleteUsagePoolSuccess
    | DeleteUsagePoolFailure
    | CreateNewUsagePool
    | GetMatchingSourcesRequest
    | GetMatchingSourcesSuccess
    | GetMatchingSourcesFailure
    | CreateNewUsagePool
    | SearchDistributionsRequest
    | SearchDistributionsSuccess
    | SearchDistributionsFailure
    | SearchLastPercentageRequest
    | SearchLastPercentageSuccess
    | SearchLastPercentageFailure
    | SearchAdjustmentsRequest
    | SearchAdjustmentsSuccess
    | SearchAdjustmentsFailure
    | GetDistributionTypesRequest
    | GetDistributionTypesSuccess
    | GetDistributionTypesFailure
    | GetDistributionSubTypesRequest
    | GetDistributionSubTypesSuccess
    | GetDistributionSubTypesFailure
    | UpdateDistributionField
    | SaveDistributionRequest
    | SaveDistributionSuccess
    | SaveDistributionFailure
    | AllocateDistributionPoolsRequest
    | AllocateDistributionPoolsSuccess
    | AllocateDistributionPoolsFailure
    | ExportDistributionPoolsRequest
    | ExportDistributionPoolsSuccess
    | ExportDistributionPoolsFailure
    | GetDistributionTypesFailure
    | GetIPMaintenanceDisplaySettingsRequest
    | GetIPMaintenanceDisplaySettingsSuccess
    | GetIPMaintenanceDisplaySettingsFailure
    | GetProductMaintenanceEnableCuesheetRequest
    | GetProductMaintenanceEnableCuesheetSuccess
    | GetProductMaintenanceEnableCuesheetFailure
    | GetProductMaintenanceCuesheetsDataSourceRequest
    | GetProductMaintenanceCuesheetsDataSourceSuccess
    | GetProductMaintenanceCuesheetsDataSourceFailure
    | CreateNewDistribution
    | UpdateSelectionCriteria
    | UpdateRepresentationSelector
    | CopyExistingPool
    | CopyExistingDistribution
    | SearchPoolVersionHistoryRequest
    | SearchPoolVersionHistorySuccess
    | SearchPoolVersionHistoryFailure
    | ClearRepertoireCache
    | UpdateForeignSocietyInfo
    | GetMatchingWorkInfoRequest
    | GetMatchingWorkInfoSuccess
    | GetMatchingWorkInfoFailure
    | DisplayRepresentation
    | AddRepresentationRequest
    | AddRepresentationSuccess
    | AddRepresentationFailure
    | AddNewRepresentation
    | CreateNewRepresentation
    | GetRepresentationGroupRequest
    | GetRepresentationGroupSuccess
    | GetRepresentationGroupFailure
    | GetRepresentationGroups
    | DeleteRepresentations
    | DeleteRepresentationsRequest
    | DeleteRepresentationsSuccess
    | DeleteRepresentationsFailure
    | UpdateRepresentationIdsToDelete
    | SetWorkGroupMaintenanceState
    | SaveClaimSuccess
    | SaveClaimRequest
    | SaveClaimFailure
    | CreateNewClaim
    | SortSearchResults
    | SaveWorkFlowRequest
    | SaveWorkFlowSuccess
    | SaveWorkFlowFailure
    | ApproveRejectWorkFlowSuccess
    | SaveAdjustmentRequest
    | SaveAdjustmentSuccess
    | SaveAdjustmentFailure
    | GetAdjustmentDetailsRequest
    | GetAdjustmentDetailsSuccess
    | GetAdjustmentDetailsFailure
    | CopyAdjustment
    | UndoAdjustmentChanges
    | UpdateAdjustmentField
    | CreateNewAdjustment
    | ExpandMatchingWork
    | ExpandMatchingProduct
    | SortSearchResults
    | UpdateClaimField
    | IPTransferRequest
    | IPTransferSuccess
    | IPTransferFailure
    | IpTransferJobRequest
    | IpTransferJobSuccess
    | IpTransferJobFailure
    | SearchDatabricksAllocationsRequest
    | SearchDatabricksAllocationsSuccess
    | SearchDatabricksAllocationsFailure
    | AddIpToAdjustmentDetails
    | AddIpToUsageDetails
    | GenreCategoriesByDomOrForeignFailure
    | GenreCategoriesByDomOrForeignSuccess
    | GenreCategoriesByDomOrForeignRequest
    | SearchPaymentRunsFailure
    | SearchPaymentRunsSuccess
    | SearchPaymentRunsRequest
    | GetPaymentRunIDsRequest
    | GetPaymentRunIDsSuccess
    | GetPaymentRunIDsFailure
    | CreateNewPaymentRun
    | DeletePaymentRunRequest
    | DeletePaymentRunSuccess
    | DeletePaymentRunFailure
    | UndoPaymentRunChangesRequest
    | UndoPaymentRunChangesSuccess
    | UndoPaymentRunChangesFailure
    | GetPaymentRunFailure
    | GetPaymentRunRequest
    | GetPaymentRunSuccess
    | UpdatePaymentRunField
    | SavePaymentRunRequest
    | SavePaymentRunSuccess
    | SavePaymentRunFailure
    | CopyExistingPaymentRun
    | GetPaymentRunIDsFailure
    | GetUsageGridSearchResultsDisplayOptionsRequest
    | GetUsageGridSearchResultsDisplayOptionsSuccess
    | GetUsageGridSearchResultsDisplayOptionsFailure
    | GetIPGridSearchResultsDisplayOptionsRequest
    | GetIPGridSearchResultsDisplayOptionsSuccess
    | GetIPGridSearchResultsDisplayOptionsFailure
    | GetPDArrangementMusicLyricFailure
    | GetPDArrangementMusicLyricRequest
    | GetPDArrangementMusicLyricSuccess
    | UpdateUsageGroupMatches
    | SaveUsageGroupFromSearchSuccess
    | SaveUsageGroupFromSearchFailure
    | AddWorkOrProductToUsageSearchCriteria
    | CreateNewLicense
    | CopyExistingLicense
    | SearchLicensesRequest
    | SearchLicensesSuccess
    | SearchLicensesFailure
    | GetLicenseRequest
    | GetLicenseFailure
    | GetLicenseSuccess
    | SaveLicenseRequest
    | SaveLicenseSuccess
    | SaveLicenseFailure
    | SubmitLicenseSuccess
    | SubmitLicenseFailure
    | DeleteLicenseRequest
    | DeleteLicenseSuccess
    | DeleteLicenseFailure
    | UpdateLicenseField
    | IncrementTempID
    | GetUsageMatchesRequest
    | GetUsageMatchesSuccess
    | GetUsageMatchesFailure
    | IncrementTempID
    | GetShoppingCartIDsSuccess
    | GetShoppingCartIDsFailure
    | SearchLicenseUserRequest
    | SearchLicenseUserSuccess
    | SearchLicenseUserFailure
    | UpdateLicenceUserValues
    | AddWorkToLicenseRequest
    | UpdateLicenseRequestWorkItem
    | GetLicenseRequestWorkParameterRequest
    | GetLicenseRequestWorkParameterSuccess
    | GetLicenseRequestWorkParameterFailure
    | GetLicenseWorksContributors
    | GetLicenseWorksContributorsSuccess
    | GetLicenseWorksContributorsFailure
    | UndoLicenseSuccess
    | ImportLicenseWorksPrices
    | GetShowCommentSubjectAsDropdownConfigurationParameterRequest
    | GetShowCommentSubjectAsDropdownConfigurationParameterSuccess
    | GetShowCommentSubjectAsDropdownConfigurationParameterFailure
    | RemoveUsageAttachmentRequest
    | RemoveUsageAttachmentSuccess
    | RemoveUsageAttachmentFailure
    | UndoAdjustmentChangesSuccess
    | UndoAdjustmentChangesFailure
    | UpdateUsageGroupSetListAdditional
    | UpdateUsageGroupLocation
    | UpdateUsageGroupVenueDetails
    | AddWorkToClaimRequest
    | SearchClaimsRequest
    | SearchClaimsSuccess
    | SearchClaimsFailure
    | UpdateUsageGroupVenueDetails
    | CalculateLicenseWorksPricesRequest
    | CalculateLicenseWorksPricesSuccess
    | CalculateLicenseWorksPricesFailure
    | GetCommentCategoryFlagRequest
    | GetCommentCategoryFlagSuccess
    | GetCommentCategoryFlagFailure
    | UpdateIPNames
    | SaveIPNamesSuccess
    | GetOpenDistributionsFailure
    | GetOpenDistributionsSuccess
    | GetOpenDistributionsRequest
    | GetAllDistributionsFailure
    | GetAllDistributionsRequest
    | GetAllDistributionsSuccess
    | GetAgreementAttachmentTypeRequiredRequest
    | GetAgreementAttachmentTypeRequiredSuccess
    | GetAgreementAttachmentTypeRequiredFailure
    | GetExcludeSourceTypeShowFieldsRequest
    | GetExcludeSourceTypeShowFieldsSuccess
    | GetExcludeSourceTypeShowFieldsFailure
    | SearchSubjectSuccess
    | UpdateSubjectCode
    | FilterGenreCategorySuccess
    | FilterGenreCategoryRequest
    | FilterGenreCategoryFailure
    | TriggerManualClaimJobSuccess
    | TriggerManualClaimJobRequest
    | TriggerManualClaimJobFailure
    | RunQADistributionPoolsRequest
    | RunQADistributionPoolsSuccess
    | RunQADistributionPoolsFailure
    | GetRecalculateDomesticorForeignFlagRequest
    | GetRecalculateDomesticorForeignFlagSuccess
    | GetRecalculateDomesticorForeignFlagFailure
    | CreateNewMyPackages
    | DownloadFileFormatSuccess
    | AddtoPackageSuccess
    | AddtoPackageFailure
    | MergeAgreementFailure
    | MergeAgreementSuccess
    | GetAllPackagesDetailsSuccess
    | GetAllPackagesDetailsRequest
    | GetAllPackagesDetailsFailure
    | ClearPackagesSuccess
    | ClearPackagesRequest
    | ClearPackagesFailure
    | GeneratePackageSuccess
    | ClosePackageFailure
    | AddPackageNoteFailure
    | GetPoolByCodeRequest
    | GetPoolByCodeSuccess
    | GetPoolByCodeFailure
    | UpdatePoolCodeToSearch
    
      
// Action names
//USAGES

export const SEARCH_USAGEGROUP_REQUEST: SEARCH_USAGEGROUP_REQUEST_TYPE = "SEARCH_USAGES_REQUEST";
export const SEARCH_USAGEGROUP_SUCCESS: SEARCH_USAGEGROUP_SUCCESS_TYPE = "SEARCH_USAGES_SUCCESS";
export const SEARCH_USAGEGROUP_FAILURE: SEARCH_USAGEGROUP_FAILURE_TYPE = "SEARCH_USAGES_FAILURE";

export const GET_USAGE_MATCHES_REQUEST: GET_USAGE_MATCHES_REQUEST_TYPE = "GET_USAGE_MATCHES_REQUEST";
export const GET_USAGE_MATCHES_SUCCESS: GET_USAGE_MATCHES_SUCCESS_TYPE = "GET_USAGE_MATCHES_SUCCESS";
export const GET_USAGE_MATCHES_FAILURE: GET_USAGE_MATCHES_FAILURE_TYPE = "GET_USAGE_MATCHES_FAILURE";

export const UPDATE_USAGE_GROUP_MATCHES: UPDATE_USAGE_GROUP_MATCHES_TYPE = "UPDATE_USAGE_GROUP_MATCHES";
export const ADD_WORK_OR_PRODUCT_TO_USAGE_SEARCH_CRITERIA: ADD_WORK_OR_PRODUCT_TO_USAGE_SEARCH_CRITERIA_TYPE = "ADD_WORK_OR_PRODUCT_TO_USAGE_SEARCH_CRITERIA";
export const SAVE_USAGE_GROUP_FROM_SEARCH_SUCCESS: SAVE_USAGE_GROUP_FROM_SEARCH_SUCCESS_TYPE = "SAVE_USAGE_GROUP_FROM_SEARCH_SUCCESS";
export const SAVE_USAGE_GROUP_FROM_SEARCH_FAILURE: SAVE_USAGE_GROUP_FROM_SEARCH_FAILURE_TYPE = "SAVE_USAGE_GROUP_FROM_SEARCH_FAILURE";

export const CLEAR_USAGEMODAL_SEARCH_RESULTS: CLEAR_USAGEMODAL_SEARCH_RESULTS_TYPE = "CLEAR_USAGEMODAL_SEARCH_RESULTS";

export const UPDATE_USAGEPAGINATION: UPDATE_USAGEPAGINATION_TYPE = 'UPDATE_USAGEPAGINATION';
export const RESET_USAGEPAGINATION: RESET_USAGEPAGINATION_TYPE = 'RESET_USAGEPAGINATION';

export const FETCH_USAGE_COMPONENT_DATA_REQUEST: FETCH_USAGE_COMPONENT_DATA_REQUEST_TYPE =
    "FETCH_USAGE_COMPONENT_DATA_REQUEST";
export const FETCH_USAGE_COMPONENT_DATA_SUCCESS: FETCH_USAGE_COMPONENT_DATA_SUCCESS_TYPE =
    "FETCH_USAGE_COMPONENT_DATA_SUCCESS";
export const FETCH_USAGE_COMPONENT_DATA_FAILURE: FETCH_USAGE_COMPONENT_DATA_FAILURE_TYPE =
    "FETCH_USAGE_COMPONENT_DATA_FAILURE";

export const GET_USAGE_REQUEST: GET_USAGE_REQUEST_TYPE =
    "GET_USAGE_REQUEST";
export const GET_USAGE_SUCCESS: GET_USAGE_SUCCESS_TYPE =
    "GET_USAGE_SUCCESS";
export const GET_USAGE_FAILURE: GET_USAGE_FAILURE_TYPE =
    "GET_USAGE_FAILURE";

export const GET_USAGEDISTR_REQUEST: GET_USAGEDISTR_REQUEST_TYPE = "GET_USAGEDISTR_REQUEST";
export const GET_USAGEDISTR_SUCCESS: GET_USAGEDISTR_SUCCESS_TYPE = "GET_USAGEDISTR_SUCCESS";
export const GET_USAGEDISTR_FAILURE: GET_USAGEDISTR_FAILURE_TYPE = "GET_USAGEDISTR_FAILURE";

export const GET_USAGEPOOL_REQUEST: GET_USAGEPOOL_REQUEST_TYPE = "GET_USAGEPOOL_REQUEST";
export const GET_USAGEPOOL_SUCCESS: GET_USAGEPOOL_SUCCESS_TYPE = "GET_USAGEPOOL_SUCCESS";
export const GET_USAGEPOOL_FAILURE: GET_USAGEPOOL_FAILURE_TYPE = "GET_USAGEPOOL_FAILURE";

export const GET_SOURCEMAJOR_REQUEST: GET_SOURCEMAJOR_REQUEST_TYPE = "GET_SOURCEMAJOR_REQUEST";
export const GET_SOURCEMAJOR_SUCCESS: GET_SOURCEMAJOR_SUCCESS_TYPE = "GET_SOURCEMAJOR_SUCCESS";
export const GET_SOURCEMAJOR_FAILURE: GET_SOURCEMAJOR_FAILURE_TYPE = "GET_SOURCEMAJOR_FAILURE";

export const GET_SOURCEMINOR_REQUEST: GET_SOURCEMINOR_REQUEST_TYPE = "GET_SOURCEMINOR_REQUEST";
export const GET_SOURCEMINOR_SUCCESS: GET_SOURCEMINOR_SUCCESS_TYPE = "GET_SOURCEMINOR_SUCCESS";
export const GET_SOURCEMINOR_FAILURE: GET_SOURCEMINOR_FAILURE_TYPE = "GET_SOURCEMINOR_FAILURE";

export const SEARCH_MATCHWORKS_SUCCESS: SEARCH_MATCHWORKS_SUCCESS_TYPE = "SEARCH_MATCHWORKS_SUCCESS";
export const SEARCH_MATCHWORKS_REQUEST: SEARCH_MATCHWORKS_REQUEST_TYPE = "SEARCH_MATCHWORKS_REQUEST";
export const SEARCH_MATCHWORKS_FAILURE: SEARCH_MATCHWORKS_FAILURE_TYPE = "SEARCH_MATCHWORKS_FAILURE";

export const SEARCH_MATCHPRODUCTS_SUCCESS: SEARCH_MATCHPRODUCTS_SUCCESS_TYPE = "SEARCH_MATCHPRODUCTS_SUCCESS";
export const SEARCH_MATCHPRODUCTS_REQUEST: SEARCH_MATCHPRODUCTS_REQUEST_TYPE = "SEARCH_MATCHPRODUCTS_REQUEST";
export const SEARCH_MATCHPRODUCTS_FAILURE: SEARCH_MATCHPRODUCTS_FAILURE_TYPE = "SEARCH_MATCHPRODUCTS_FAILURE";

export const ADD_MATCHWORK_TO_USAGE: ADD_MATCHWORK_TO_USAGE_TYPE = "ADD_MATCHWORK_TO_USAGE";
export const ADD_MATCHPRODUCT_TO_USAGE: ADD_MATCHPRODUCT_TO_USAGE_TYPE = "ADD_MATCHPRODUCT_TO_USAGE";

export const EXPAND_MATCHWORK_RESULT: EXPAND_MATCHWORK_RESULT_TYPE = "EXPAND_MATCHWORK_RESULT";
export const USAGEEXPAND_ALL_RESULTS: USAGEEXPAND_ALL_RESULTS_TYPE = "USAGEEXPAND_ALL_RESULTS";
export const UPDATE_USAGE_FIELD: UPDATE_USAGE_FIELD_TYPE = "UPDATE_USAGE_FIELD";
export const UPDATE_USAGE_ROW_DROPDOWN: UPDATE_USAGE_ROW_DROPDOWN_TYPE = "UPDATE_USAGE_ROW_DROPDOWN";

export const SEARCHDATA_CHANGED: SEARCHDATA_CHANGED_TYPE = "SEARCHDATA_CHANGED";

export const SET_USAGE_TYPE: SET_USAGE_TYPE_TYPE = "SET_USAGE_TYPE";

export const SAVE_USAGEGROUP_REQUEST: SAVE_USAGEGROUP_REQUEST_TYPE = "SAVE_USAGEGROUP_REQUEST";
export const SAVE_USAGEGROUP_SUCCESS: SAVE_USAGEGROUP_SUCCESS_TYPE = "SAVE_USAGEGROUP_SUCCESS";
export const SAVE_USAGEGROUP_FAILURE: SAVE_USAGEGROUP_FAILURE_TYPE = "SAVE_USAGEGROUP_FAILURE";

export const RESET_USAGEMESSAGE_BANNER: RESET_USAGEMESSAGE_BANNER_TYPE = "RESET_USAGEMESSAGE_BANNER";

export const GET_SOURCES_REQUEST: GET_SOURCES_REQUEST_TYPE = "GET_SOURCES_REQUEST";
export const GET_SOURCES_SUCCESS: GET_SOURCES_SUCCESS_TYPE = "GET_SOURCES_SUCCESS";
export const GET_SOURCES_FAILURE: GET_SOURCES_FAILURE_TYPE = "GET_SOURCES_FAILURE";

export const GET_USAGE_POOL_REQUEST: GET_USAGE_POOL_REQUEST_TYPE = "GET_USAGE_POOL_REQUEST";
export const GET_USAGE_POOL_SUCCESS: GET_USAGE_POOL_SUCCESS_TYPE = "GET_USAGE_POOL_SUCCESS";
export const GET_USAGE_POOL_FAILURE: GET_USAGE_POOL_FAILURE_TYPE = "GET_USAGE_POOL_FAILURE";

export const UPDATE_USAGE_POOL_FIELD: UPDATE_USAGE_POOL_FIELD_TYPE = "UPDATE_USAGE_POOL_FIELD";

export const SAVE_USAGE_POOL_REQUEST: SAVE_USAGE_POOL_REQUEST_TYPE = "SAVE_USAGE_POOL_REQUEST";
export const SAVE_USAGE_POOL_SUCCESS: SAVE_USAGE_POOL_SUCCESS_TYPE = "SAVE_USAGE_POOL_SUCCESS";
export const SAVE_USAGE_POOL_FAILURE: SAVE_USAGE_POOL_FAILURE_TYPE = "SAVE_USAGE_POOL_FAILURE";

export const DELETE_USAGE_POOL_REQUEST: DELETE_USAGE_POOL_REQUEST_TYPE = "DELETE_USAGE_POOL_REQUEST";
export const DELETE_USAGE_POOL_SUCCESS: DELETE_USAGE_POOL_SUCCESS_TYPE = "DELETE_USAGE_POOL_SUCCESS";
export const DELETE_USAGE_POOL_FAILURE: DELETE_USAGE_POOL_FAILURE_TYPE = "DELETE_USAGE_POOL_FAILURE";

export const CREATE_NEW_USAGE_POOL: CREATE_NEW_USAGE_POOL_TYPE = "CREATE_NEW_USAGE_POOL";

export const GET_MATCHING_SOURCES_REQUEST: GET_MATCHING_SOURCES_REQUEST_TYPE = "GET_MATCHING_SOURCES_REQUEST";
export const GET_MATCHING_SOURCES_SUCCESS: GET_MATCHING_SOURCES_SUCCESS_TYPE = "GET_MATCHING_SOURCES_SUCCESS";
export const GET_MATCHING_SOURCES_FAILURE: GET_MATCHING_SOURCES_FAILURE_TYPE = "GET_MATCHING_SOURCES_FAILURE";

export const GET_SHORT_CUT_KEYS_REQUEST: GET_SHORT_CUT_KEYS_REQUEST_TYPE = "GET_SHORT_CUT_KEYS_REQUEST";
export const GET_SHORT_CUT_KEYS_SUCCESS: GET_SHORT_CUT_KEYS_SUCCESS_TYPE = "GET_SHORT_CUT_KEYS_SUCCESS";
export const GET_SHORT_CUT_KEYS_FAILURE: GET_SHORT_CUT_KEYS_FAILURE_TYPE = "GET_SHORT_CUT_KEYS_FAILURE";

export const GET_DISTRIBUTION_REQUEST: GET_DISTRIBUTION_REQUEST_TYPE = "GET_DISTRIBUTION_REQUEST";
export const GET_DISTRIBUTION_SUCCESS: GET_DISTRIBUTION_SUCCESS_TYPE = "GET_DISTRIBUTION_SUCCESS";
export const GET_DISTRIBUTION_FAILURE: GET_DISTRIBUTION_FAILURE_TYPE = "GET_DISTRIBUTION_FAILURE";

export const UPDATE_DISTRIBUTION_FIELD: UPDATE_DISTRIBUTION_FIELD_TYPE = "UPDATE_DISTRIBUTION_FIELD";

export const SAVE_DISTRIBUTION_REQUEST: SAVE_DISTRIBUTION_REQUEST_TYPE = "SAVE_DISTRIBUTION_REQUEST";
export const SAVE_DISTRIBUTION_SUCCESS: SAVE_DISTRIBUTION_SUCCESS_TYPE = "SAVE_DISTRIBUTION_SUCCESS";
export const SAVE_DISTRIBUTION_FAILURE: SAVE_DISTRIBUTION_FAILURE_TYPE = "SAVE_DISTRIBUTION_FAILURE";

export const CLEAR_PACKAGES_REQUEST: CLEAR_PACKAGES_REQUEST_TYPE = "CLEAR_PACKAGES_REQUEST";
export const CLEAR_PACKAGES_SUCCESS: CLEAR_PACKAGES_SUCCESS_TYPE = "CLEAR_PACKAGES_SUCCESS";
export const CLEAR_PACKAGES_FAILURE: CLEAR_PACKAGES_FAILURE_TYPE = "CLEAR_PACKAGES_FAILURE";



export const ALLOCATE_DISTRIBUTION_POOLS_REQUEST: ALLOCATE_DISTRIBUTION_POOLS_REQUEST_TYPE = "ALLOCATE_DISTRIBUTION_POOLS_REQUEST";
export const ALLOCATE_DISTRIBUTION_POOLS_SUCCESS: ALLOCATE_DISTRIBUTION_POOLS_SUCCESS_TYPE = "ALLOCATE_DISTRIBUTION_POOLS_SUCCESS";
export const ALLOCATE_DISTRIBUTION_POOLS_FAILURE: ALLOCATE_DISTRIBUTION_POOLS_FAILURE_TYPE = "ALLOCATE_DISTRIBUTION_POOLS_FAILURE";

export const RUN_QA_DISTRIBUTION_POOLS_REQUEST: RUN_QA_DISTRIBUTION_POOLS_REQUEST_TYPE = "RUN_QA_DISTRIBUTION_POOLS_REQUEST";
export const RUN_QA_DISTRIBUTION_POOLS_SUCCESS: RUN_QA_DISTRIBUTION_POOLS_SUCCESS_TYPE = "RUN_QA_DISTRIBUTION_POOLS_SUCCESS";
export const RUN_QA_DISTRIBUTION_POOLS_FAILURE: RUN_QA_DISTRIBUTION_POOLS_FAILURE_TYPE = "RUN_QA_DISTRIBUTION_POOLS_FAILURE";

export const EXPORT_DISTRIBUTION_POOLS_REQUEST: EXPORT_DISTRIBUTION_POOLS_REQUEST_TYPE = "EXPORT_DISTRIBUTION_POOLS_REQUEST";
export const EXPORT_DISTRIBUTION_POOLS_SUCCESS: EXPORT_DISTRIBUTION_POOLS_SUCCESS_TYPE = "EXPORT_DISTRIBUTION_POOLS_SUCCESS";
export const EXPORT_DISTRIBUTION_POOLS_FAILURE: EXPORT_DISTRIBUTION_POOLS_FAILURE_TYPE = "EXPORT_DISTRIBUTION_POOLS_FAILURE";

export const SAVE_PAYMENTRUN_REQUEST: SAVE_PAYMENTRUN_REQUEST_TYPE = "SAVE_PAYMENTRUN_REQUEST";
export const SAVE_PAYMENTRUN_SUCCESS: SAVE_PAYMENTRUN_SUCCESS_TYPE = "SAVE_PAYMENTRUN_SUCCESS";
export const SAVE_PAYMENTRUN_FAILURE: SAVE_PAYMENTRUN_FAILURE_TYPE = "SAVE_PAYMENTRUN_FAILURE";

export const DELETE_PAYMENTRUN_REQUEST: DELETE_PAYMENTRUN_REQUEST_TYPE = "DELETE_PAYMENTRUN_REQUEST";
export const DELETE_PAYMENTRUN_SUCCESS: DELETE_PAYMENTRUN_SUCCESS_TYPE = "DELETE_PAYMENTRUN_SUCCESS";
export const DELETE_PAYMENTRUN_FAILURE: DELETE_PAYMENTRUN_FAILURE_TYPE = "DELETE_PAYMENTRUN_FAILURE";

export const DELETE_LICENSE_REQUEST: DELETE_LICENSE_REQUEST_TYPE = "DELETE_LICENSE_REQUEST";
export const DELETE_LICENSE_SUCCESS: DELETE_LICENSE_SUCCESS_TYPE = "DELETE_LICENSE_SUCCESS";
export const DELETE_LICENSE_FAILURE: DELETE_LICENSE_FAILURE_TYPE = "DELETE_LICENSE_FAILURE";

export const GET_SHOPPING_CART_PARAMETERS_SUCCESS: GET_SHOPPING_CART_PARAMETERS_SUCCESS_TYPE = "GET_SHOPPING_CART_PARAMETERS_SUCCESS";
export const GET_SHOPPING_CART_PARAMETERS_FAILURE: GET_SHOPPING_CART_PARAMETERS_FAILURE_TYPE = "GET_SHOPPING_CART_PARAMETERS_FAILURE";

export const UPDATE_LICENSE_USER: UPDATE_LICENSE_USER_TYPE = "UPDATE_LICENSE_USER";

//CLAIM
export const UPDATE_CLAIM_FIELD: UPDATE_CLAIM_FIELD_TYPE = "UPDATE_CLAIM_FIELD";

//REPERTOIRE
export const REDUX_STORAGE_LOAD: REDUX_STORAGE_LOAD_TYPE = "REDUX_STORAGE_LOAD";
export const ADD_TAB: ADD_TAB_TYPE = "ADD_TAB";
export const REPLACE_TAB: REPLACE_TAB_TYPE = "REPLACE_TAB";
export const REMOVE_TAB: REMOVE_TAB_TYPE = "REMOVE_TAB";
export const REMOVE_PANE_TWO: REMOVE_PANE_TWO_TYPE = "REMOVE_PANE_TWO";
export const OPEN_TAB: OPEN_TAB_TYPE = "OPEN_TAB";
export const SET_ACTIVE_PANE: SET_ACTIVE_PANE_TYPE = "SET_ACTIVE_PANE";
export const SET_DATA_SOURCE: SET_DATA_SOURCE_TYPE = "SET_DATA_SOURCE";
export const SET_AGREEMENT_DATA_SOURCE: SET_AGREEMENT_DATA_SOURCE_TYPE = "SET_AGREEMENT_DATA_SOURCE";
export const SET_AGREEMENT_SEARCH_TYPE: SET_AGREEMENT_SEARCH_TYPE_TYPE = "SET_AGREEMENT_SEARCH_TYPE";
export const SET_PRODUCT_DATA_SOURCE: SET_PRODUCT_DATA_SOURCE_TYPE = "SET_PRODUCT_DATA_SOURCE";
export const SET_ADJUSTMENT_DATA_SOURCE: SET_ADJUSTMENT_DATA_SOURCE_TYPE = "SET_ADJUSTMENT_DATA_SOURCE";
export const ADD_SET_TYPES: ADD_SET_TYPES_TYPE = "ADD_SET_TYPES";
export const REMOVE_SET_TYPES: REMOVE_SET_TYPES_TYPE = "REMOVE_SET_TYPES";
export const CLEAR_SET_TYPES: CLEAR_SET_TYPES_TYPE = "CLEAR_SET_TYPES";

export const FETCH_REPERTOIRE_COMPONENT_DATA_REQUEST: FETCH_REPERTOIRE_COMPONENT_DATA_REQUEST_TYPE =
    "FETCH_REPERTOIRE_COMPONENT_DATA_REQUEST";
export const FETCH_REPERTOIRE_COMPONENT_DATA_SUCCESS: FETCH_REPERTOIRE_COMPONENT_DATA_SUCCESS_TYPE =
    "FETCH_REPERTOIRE_COMPONENT_DATA_SUCCESS";
export const FETCH_REPERTOIRE_COMPONENT_DATA_FAILURE: FETCH_REPERTOIRE_COMPONENT_DATA_FAILURE_TYPE =
    "FETCH_REPERTOIRE_COMPONENT_DATA_FAILURE";

export const SEARCH_WORKS_REQUEST: SEARCH_WORKS_REQUEST_TYPE =
    "SEARCH_WORKS_REQUEST";
export const SEARCH_WORKS_SUCCESS: SEARCH_WORKS_SUCCESS_TYPE =
    "SEARCH_WORKS_SUCCESS";
export const SEARCH_WORKS_FAILURE: SEARCH_WORKS_FAILURE_TYPE =
    "SEARCH_WORKS_FAILURE";

export const SEARCH_ARTISTS_REQUEST: SEARCH_ARTISTS_REQUEST_TYPE =
    "SEARCH_ARTISTS_REQUEST";
export const SEARCH_ARTISTS_SUCCESS: SEARCH_ARTISTS_SUCCESS_TYPE =
    "SEARCH_ARTISTS_SUCCESS";
export const SEARCH_ARTISTS_FAILURE: SEARCH_ARTISTS_FAILURE_TYPE =
    "SEARCH_ARTISTS_FAILURE";

export const SEARCH_CONTRIBUTORS_REQUEST: SEARCH_CONTRIBUTORS_REQUEST_TYPE =
    "SEARCH_CONTRIBUTORS_REQUEST";
export const SEARCH_CONTRIBUTORS_SUCCESS: SEARCH_CONTRIBUTORS_SUCCESS_TYPE =
    "SEARCH_CONTRIBUTORS_SUCCESS";
export const SEARCH_CONTRIBUTORS_FAILURE: SEARCH_CONTRIBUTORS_FAILURE_TYPE =
    "SEARCH_CONTRIBUTORS_FAILURE";

export const SEARCH_AGREEMENTS_REQUEST: SEARCH_AGREEMENTS_REQUEST_TYPE =
    "SEARCH_AGREEMENTS_REQUEST";
export const SEARCH_AGREEMENTS_SUCCESS: SEARCH_AGREEMENTS_SUCCESS_TYPE =
    "SEARCH_AGREEMENTS_SUCESS";
export const SEARCH_AGREEMENTS_FAILURE: SEARCH_AGREEMENTS_FAILURE_TYPE =
    "SEARCH_AGREEMENTS_FAILURE";

export const SEARCH_IPS_REQUEST: SEARCH_IPS_REQUEST_TYPE =
    "SEARCH_IPS_REQUEST";
export const SEARCH_IPS_SUCCESS: SEARCH_IPS_SUCCESS_TYPE =
    "SEARCH_IPS_SUCESS";
export const SEARCH_IPS_FAILURE: SEARCH_IPS_FAILURE_TYPE =
    "SEARCH_IPS_FAILURE";


export const SEARCH_PRODUCTS_REQUEST: SEARCH_PRODUCTS_REQUEST_TYPE =
    "SEARCH_PRODUCTS_REQUEST";
export const SEARCH_PRODUCTS_SUCCESS: SEARCH_PRODUCTS_SUCCESS_TYPE =
    "SEARCH_PRODUCTS_SUCESS";
export const SEARCH_PRODUCTS_FAILURE: SEARCH_PRODUCTS_FAILURE_TYPE =
    "SEARCH_PRODUCTS_FAILURE";

export const SEARCH_USAGE_POOLS_REQUEST: SEARCH_USAGE_POOLS_REQUEST_TYPE = "SEARCH_POOLS_REQUEST";
export const SEARCH_USAGE_POOLS_SUCCESS: SEARCH_USAGE_POOLS_SUCCESS_TYPE = "SEARCH_POOLS_SUCCESS";
export const SEARCH_USAGE_POOLS_FAILURE: SEARCH_USAGE_POOLS_FAILURE_TYPE = "SEARCH_POOLS_FAILURE";

export const SEARCH_DISTRIBUTIONS_REQUEST: SEARCH_DISTRIBUTIONS_REQUEST_TYPE = "SEARCH_DISTRIBUTIONS_REQUEST";
export const SEARCH_DISTRIBUTIONS_SUCCESS: SEARCH_DISTRIBUTIONS_SUCCESS_TYPE = "SEARCH_DISTRIBUTIONS_SUCCESS";
export const SEARCH_DISTRIBUTIONS_FAILURE: SEARCH_DISTRIBUTIONS_FAILURE_TYPE = "SEARCH_DISTRIBUTIONS_FAILURE";

export const SEARCH_LAST_PERCENTAGE_REQUEST: SEARCH_LAST_PERCENTAGE_REQUEST_TYPE = "SEARCH_LAST_PERCENTAGE_REQUEST";
export const SEARCH_LAST_PERCENTAGE_SUCCESS: SEARCH_LAST_PERCENTAGE_SUCCESS_TYPE = "SEARCH_LAST_PERCENTAGE_SUCCESS";
export const SEARCH_LAST_PERCENTAGE_FAILURE: SEARCH_LAST_PERCENTAGE_FAILURE_TYPE = "SEARCH_LAST_PERCENTAGE_FAILURE";

export const SEARCH_ADJUSTMENTS_REQUEST: SEARCH_ADJUSTMENTS_REQUEST_TYPE = "SEARCH_ADJUSTMENTS_REQUEST";
export const SEARCH_ADJUSTMENTS_SUCCESS: SEARCH_ADJUSTMENTS_SUCCESS_TYPE = "SEARCH_ADJUSTMENTS_SUCCESS";
export const SEARCH_ADJUSTMENTS_FAILURE: SEARCH_ADJUSTMENTS_FAILURE_TYPE = "SEARCH_ADJUSTMENTS_FAILURE";

export const SEARCH_CLAIM_REQUEST: SEARCH_CLAIM_REQUEST_TYPE = "SEARCH_CLAIM_REQUEST";
export const SEARCH_CLAIM_SUCCESS: SEARCH_CLAIM_SUCCESS_TYPE = "SEARCH_CLAIM_SUCCESS";
export const SEARCH_CLAIM_FAILURE: SEARCH_CLAIM_FAILURE_TYPE = "SEARCH_CLAIM_FAILURE";

export const GET_DISTRIBUTION_TYPES_REQUEST: GET_DISTRIBUTION_TYPES_REQUEST_TYPE = "GET_DISTRIBUTION_TYPES_REQUEST";
export const GET_DISTRIBUTION_TYPES_SUCCESS: GET_DISTRIBUTION_TYPES_SUCCESS_TYPE = "GET_DISTRIBUTION_TYPES_SUCCESS";
export const GET_DISTRIBUTION_TYPES_FAILURE: GET_DISTRIBUTION_TYPES_FAILURE_TYPE = "GET_DISTRIBUTION_TYPES_FAILURE";

export const GET_DISTRIBUTION_SUB_TYPES_REQUEST: GET_DISTRIBUTION_SUB_TYPES_REQUEST_TYPE = "GET_DISTRIBUTION_SUB_TYPES_REQUEST";
export const GET_DISTRIBUTION_SUB_TYPES_SUCCESS: GET_DISTRIBUTION_SUB_TYPES_SUCCESS_TYPE = "GET_DISTRIBUTION_SUB_TYPES_SUCCESS";
export const GET_DISTRIBUTION_SUB_TYPES_FAILURE: GET_DISTRIBUTION_SUB_TYPES_FAILURE_TYPE = "GET_DISTRIBUTION_SUB_TYPES_FAILURE";

export const CLEAR_MODAL_SEARCH_RESULTS: CLEAR_MODAL_SEARCH_RESULTS_TYPE = "CLEAR_MODAL_SEARCH_RESULTS"

export const GET_WORK_DETAILS_REQUEST: GET_WORK_DETAILS_REQUEST_TYPE =
    "GET_WORK_DETAILS_REQUEST";
export const GET_WORK_DETAILS_SUCCESS: GET_WORK_DETAILS_SUCCESS_TYPE =
    "GET_WORK_DETAILS_SUCCESS";
export const GET_WORK_DETAILS_FAILURE: GET_WORK_DETAILS_FAILURE_TYPE =
    "GET_WORK_DETAILS_FAILURE";
export const REFRESH_WORK_DETAILS_REQUEST: REFRESH_WORK_DETAILS_REQUEST_TYPE =
    "REFRESH_WORK_DETAILS_REQUEST";
export const REFRESH_WORK_DETAILS_SUCCESS: REFRESH_WORK_DETAILS_SUCCESS_TYPE =
    "REFRESH_WORK_DETAILS_SUCCESS";
export const REFRESH_WORK_DETAILS_FAILURE: REFRESH_WORK_DETAILS_FAILURE_TYPE =
    "REFRESH_WORK_DETAILS_FAILURE";

export const GET_MATCHING_WORK_INFO_REQUEST: GET_MATCHING_WORK_INFO_REQUEST_TYPE =
    "GET_MATCHING_WORK_INFO_REQUEST";
export const GET_MATCHING_WORK_INFO_SUCCESS: GET_MATCHING_WORK_INFO_SUCCESS_TYPE =
    "GET_MATCHING_WORK_INFO_SUCCESS";
export const GET_MATCHING_WORK_INFO_FAILURE: GET_MATCHING_WORK_INFO_FAILURE_TYPE =
    "GET_MATCHING_WORK_INFO_FAILURE";

export const REFRESH_PRODUCT_DETAILS_REQUEST: REFRESH_PRODUCT_DETAILS_REQUEST_TYPE = "REFRESH_PRODUCT_DETAILS_REQUEST";
export const REFRESH_PRODUCT_DETAILS_SUCCESS: REFRESH_PRODUCT_DETAILS_SUCCESS_TYPE = "REFRESH_PRODUCT_DETAILS_SUCCESS";
export const REFRESH_PRODUCT_DETAILS_FAILURE: REFRESH_PRODUCT_DETAILS_FAILURE_TYPE = "REFRESH_PRODUCT_DETAILS_FAILURE";

export const GET_AGREEMENT_DETAILS_REQUEST: GET_AGREEMENT_DETAILS_REQUEST_TYPE =
    "GET_AGREEMENT_DETAILS_REQUEST";
export const GET_AGREEMENT_DETAILS_SUCCESS: GET_AGREEMENT_DETAILS_SUCCESS_TYPE =
    "GET_AGREEMENT_DETAILS_SUCCESS";
export const GET_AGREEMENT_DETAILS_FAILURE: GET_AGREEMENT_DETAILS_FAILURE_TYPE =
    "GET_AGREEMENT_DETAILS_FAILURE";

export const GET_IP_DETAILS_REQUEST: GET_IP_DETAILS_REQUEST_TYPE =
    "GET_IP_DETAILS_REQUEST";
export const GET_IP_DETAILS_SUCCESS: GET_IP_DETAILS_SUCCESS_TYPE =
    "GET_IP_DETAILS_SUCCESS";
export const GET_IP_DETAILS_FAILURE: GET_IP_DETAILS_FAILURE_TYPE =
    "GET_IP_DETAILS_FAILURE";

export const GET_PRODUCT_DETAILS_REQUEST: GET_PRODUCT_DETAILS_REQUEST_TYPE =
    "GET_PRODUCT_DETAILS_REQUEST";
export const GET_PRODUCT_DETAILS_SUCCESS: GET_PRODUCT_DETAILS_SUCCESS_TYPE =
    "GET_PRODUCT_DETAILS_SUCCESS";
export const GET_PRODUCT_DETAILS_FAILURE: GET_PRODUCT_DETAILS_FAILURE_TYPE =
    "GET_PRODUCT_DETAILS_FAILURE";

export const GET_ARTIST_DETAILS_REQUEST: GET_ARTIST_DETAILS_REQUEST_TYPE =
    "GET_ARTIST_DETAILS_REQUEST";
export const GET_ARTIST_DETAILS_SUCCESS: GET_ARTIST_DETAILS_SUCCESS_TYPE =
    "GET_ARTIST_DETAILS_SUCCESS";
export const GET_ARTIST_DETAILS_FAILURE: GET_ARTIST_DETAILS_FAILURE_TYPE =
    "GET_ARTIST_DETAILS_FAILURE";

export const GET_ALL_PACKAGES_DETAILS_REQUEST: GET_ALL_PACKAGES_DETAILS_REQUEST_TYPE =
    "GET_ALL_PACKAGES_DETAILS_REQUEST";
export const GET_ALL_PACKAGES_DETAILS_SUCCESS: GET_ALL_PACKAGES_DETAILS_SUCCESS_TYPE =
    "GET_ALL_PACKAGES_DETAILS_SUCCESS";
export const GET_ALL_PACKAGES_DETAILS_FAILURE: GET_ALL_PACKAGES_DETAILS_FAILURE_TYPE =
    "GET_ALL_PACKAGES_DETAILS_FAILURE";

export const ADD_ARTIST_TO_WORK: ADD_ARTIST_TO_WORK_TYPE =
    "ADD_ARTIST_TO_WORK";
export const ADD_ARTIST_TO_PRODUCT: ADD_ARTIST_TO_PRODUCT_TYPE =
    "ADD_ARTIST_TO_PRODUCT";
export const ADD_WORK_TO_PRODUCT: ADD_WORK_TO_PRODUCT_TYPE =
    "ADD_WORK_TO_PRODUCT";
export const ADD_WORK_TO_LICENSE_REQUEST: ADD_WORK_TO_LICENSE_REQUEST_TYPE =
    "ADD_WORK_TO_LICENSE_REQUEST";
export const ADD_WORK_TO_CLAIM_REQUEST: ADD_WORK_TO_CLAIM_REQUEST_TYPE =
    "ADD_WORK_TO_CLAIM_REQUEST";
export const SEARCH_SUBJECT_SUCCESS: SEARCH_SUBJECT_SUCCESS_TYPE =
    "SEARCH_SUBJECT_SUCCESS";
export const UPDATE_SUBJECT_CODE: UPDATE_SUBJECT_CODE_TYPE =
    "UPDATE_SUBJECT_CODE";

export const UPDATE_WORK_CONTRIBUTOR_IP_REQUEST: UPDATE_WORK_CONTRIBUTOR_IP_REQUEST_TYPE = "UPDATE_WORK_CONTRIBUTOR_IP_REQUEST";
export const UPDATE_WORK_CONTRIBUTOR_IP_SUCCESS: UPDATE_WORK_CONTRIBUTOR_IP_SUCCESS_TYPE = "UPDATE_WORK_CONTRIBUTOR_IP_SUCCESS";
export const UPDATE_WORK_CONTRIBUTOR_IP_FAILURE: UPDATE_WORK_CONTRIBUTOR_IP_FAILURE_TYPE = "UPDATE_WORK_CONTRIBUTOR_IP_FAILURE";

export const UPDATE_PRODUCT_CONTRIBUTOR_IP: UPDATE_PRODUCT_CONTRIBUTOR_IP_TYPE = "UPDATE_PRODUCT_CONTRIBUTOR_IP";

export const UPDATE_AGREEMENT_IP: UPDATE_AGREEMENT_IP_TYPE = "UPDATE_AGREEMENT_IP";
export const UPDATE_AGREEMENT_WORK: UPDATE_AGREEMENT_WORK_TYPE = "UPDATE_AGREEMENT_WORK";
export const UPDATE_AGREEMENT_CARVEOUT_PRODUCT: UPDATE_AGREEMENT_CARVEOUT_PRODUCT_TYPE = "UPDATE_AGREEMENT_CARVEOUT_PRODUCT";

export const UPDATE_MATCHING_WORK: UPDATE_MATCHING_WORK_TYPE = "UPDATE_MATCHING_WORK";
export const UPDATE_MATCHING_PRODUCT: UPDATE_MATCHING_PRODUCT_TYPE = "UPDATE_MATCHING_PRODUCT";

export const UPDATE_RELATED_WORK: UPDATE_RELATED_WORK_TYPE = "UPDATE_RELATED_WORK";
export const UPDATE_WORK_SOURCE_IP: UPDATE_WORK_SOURCE_IP_TYPE = "UPDATE_WORK_SOURCE_IP";

export const COPY_EXISTING_WORK_REQUEST: COPY_EXISTING_WORK_REQUEST_TYPE = "COPY_EXISTING_WORK_REQUEST";
export const COPY_EXISTING_WORK_SUCCESS: COPY_EXISTING_WORK_SUCCESS_TYPE = "COPY_EXISTING_WORK_SUCCESS";

export const UNDO_WORK_CHANGES_REQUEST: UNDO_WORK_CHANGES_REQUEST_TYPE = "UNDO_WORK_CHANGES_REQUEST";
export const UNDO_WORK_CHANGES_SUCCESS: UNDO_WORK_CHANGES_SUCCESS_TYPE = "UNDO_WORK_CHANGES_SUCCESS";
export const UNDO_WORK_CHANGES_FAILURE: UNDO_WORK_CHANGES_FAILURE_TYPE = "UNDO_WORK_CHANGES_FAILURE";

export const MERGE_MATCHING_WORK_REQUEST: MERGE_MATCHING_WORK_REQUEST_TYPE = "MERGE_MATCHING_WORK_REQUEST";
export const MERGE_MATCHING_WORK_SUCCESS: MERGE_MATCHING_WORK_SUCCESS_TYPE = "MERGE_MATCHING_WORK_SUCCESS";
export const MERGE_MATCHING_WORK_FAILURE: MERGE_MATCHING_WORK_FAILURE_TYPE = "MERGE_MATCHING_WORK_FAILURE";

export const MERGE_MATCHING_INTERESTED_PARTY_REQUEST: MERGE_MATCHING_INTERESTED_PARTY_REQUEST_TYPE = "MERGE_MATCHING_INTERESTED_PARTY_REQUEST";
export const MERGE_MATCHING_INTERESTED_PARTY_SUCCESS: MERGE_MATCHING_INTERESTED_PARTY_SUCCESS_TYPE = "MERGE_MATCHING_INTERESTED_PARTY_SUCCESS";
export const MERGE_MATCHING_INTERESTED_PARTY_FAILURE: MERGE_MATCHING_INTERESTED_PARTY_FAILURE_TYPE = "MERGE_MATCHING_INTERESTED_PARTY_FAILURE";

export const MERGE_REPERTOIRE_WORK_REQUEST: MERGE_REPERTOIRE_WORK_REQUEST_TYPE = "MERGE_REPERTOIRE_WORK_REQUEST";
export const MERGE_REPERTOIRE_WORK_SUCCESS: MERGE_REPERTOIRE_WORK_SUCCESS_TYPE = "MERGE_REPERTOIRE_WORK_SUCCESS";
export const MERGE_REPERTOIRE_WORK_FAILURE: MERGE_REPERTOIRE_WORK_FAILURE_TYPE = "MERGE_REPERTOIRE_WORK_FAILURE";

export const MERGE_MATCHING_PRODUCT_REQUEST: MERGE_MATCHING_PRODUCT_REQUEST_TYPE = "MERGE_MATCHING_PRODUCT_REQUEST";
export const MERGE_MATCHING_PRODUCT_SUCCESS: MERGE_MATCHING_PRODUCT_SUCCESS_TYPE = "MERGE_MATCHING_PRODUCT_SUCCESS";
export const MERGE_MATCHING_PRODUCT_FAILURE: MERGE_MATCHING_PRODUCT_FAILURE_TYPE = "MERGE_MATCHING_PRODUCT_FAILURE";

export const MERGE_REPERTOIRE_PRODUCT_REQUEST: MERGE_REPERTOIRE_PRODUCT_REQUEST_TYPE = "MERGE_REPERTOIRE_PRODUCT_REQUEST";
export const MERGE_REPERTOIRE_PRODUCT_SUCCESS: MERGE_REPERTOIRE_PRODUCT_SUCCESS_TYPE = "MERGE_REPERTOIRE_PRODUCT_SUCCESS";
export const MERGE_REPERTOIRE_PRODUCT_FAILURE: MERGE_REPERTOIRE_PRODUCT_FAILURE_TYPE = "MERGE_REPERTOIRE_PRODUCT_FAILURE";

export const DELETE_WORK_REQUEST: DELETE_WORK_REQUEST_TYPE =
    "DELETE_WORK_REQUEST";
export const DELETE_WORK_SUCCESS: DELETE_WORK_SUCCESS_TYPE =
    "DELETE_WORK_SUCCESS";
export const DELETE_WORK_FAILURE: DELETE_WORK_FAILURE_TYPE =
    "DELETE_WORK_FAILURE";

export const CREATE_NEW_WORK: CREATE_NEW_WORK_TYPE =
    "CREATE_NEW_WORK";
export const CREATE_NEW_REPRESENTATION: CREATE_NEW_REPRESENTATION_TYPE = "CREATE_NEW_REPRESENTATION";
export const CREATE_NEW_AGREEMENT: CREATE_NEW_AGREEMENT_TYPE =
    "CREATE_NEW_AGREEMENT";
export const CREATE_NEW_ACCOUNT: CREATE_NEW_ACCOUNT_TYPE =
    "CREATE_NEW_ACCOUNT";
export const CREATE_NEW_PRODUCT: CREATE_NEW_PRODUCT_TYPE =
    "CREATE_NEW_PRODUCT";
export const CREATE_NEW_ARTIST: CREATE_NEW_ARTIST_TYPE =
    "CREATE_NEW_ARTIST";
export const CREATE_NEW_DISTRIBUTION: CREATE_NEW_DISTRIBUTION_TYPE =
    "CREATE_NEW_DISTRIBUTION";
export const CREATE_NEW_PAYMENTRUN: CREATE_NEW_PAYMENTRUN_TYPE =
    "CREATE_NEW_PAYMENTRUN";

export const CREATE_NEW_MY_PACKAGES:CREATE_NEW_MY_PACKAGES_TYPE=
     "CREATE_NEW_MY_PACKAGES";    

export const CREATE_NEW_CLAIM: CREATE_NEW_CLAIM_TYPE =
    "CREATE_NEW_CLAIM";

export const CREATE_NEW_LICENSE: CREATE_NEW_LICENSE_TYPE =
    "CREATE_NEW_LICENSE";
export const UPDATE_LICENSE_WORK_ITEM: UPDATE_LICENSE_WORK_ITEM_TYPE =
    "UPDATE_LICENSE_WORK_ITEM";

export const SAVE_WORK_REQUEST: SAVE_WORK_REQUEST_TYPE =
    "SAVE_WORK_REQUEST";
export const SAVE_WORK_SUCCESS: SAVE_WORK_SUCCESS_TYPE =
    "SAVE_WORK_SUCCESS";
export const SAVE_WORK_FAILURE: SAVE_WORK_FAILURE_TYPE =
    "SAVE_WORK_FAILURE";
export const SAVE_WORK_NUMBERS_ADDITIONAL_SUCCESS: SAVE_WORK_NUMBERS_ADDITIONAL_SUCCESS_TYPE =
    "SAVE_WORK_NUMBERS_ADDITIONAL_SUCCESS";
export const SAVE_WORK_NUMBERS_ADDITIONAL_FAILURE: SAVE_WORK_NUMBERS_ADDITIONAL_FAILURE_TYPE =
    "SAVE_WORK_NUMBERS_ADDITIONAL_FAILURE";
export const GET_WORK_NUMBERS_ADDITIONAL_PAGE_DETAILS_SUCCESS: GET_WORK_NUMBERS_ADDITIONAL_PAGE_DETAILS_SUCCESS_TYPE =
    "GET_WORK_NUMBERS_ADDITIONAL_PAGE_DETAILS_SUCCESS";
export const GET_WORK_NUMBERS_ADDITIONAL_PAGE_DETAILS_FAILURE: GET_WORK_NUMBERS_ADDITIONAL_PAGE_DETAILS_FAILURE_TYPE =
    "GET_WORK_NUMBERS_ADDITIONAL_PAGE_DETAILS_FAILURE";

export const SAVE_AGREEMENT_REQUEST: SAVE_AGREEMENT_REQUEST_TYPE =
    "SAVE_AGREEMENT_REQUEST";
export const SAVE_AGREEMENT_SUCCESS: SAVE_AGREEMENT_SUCCESS_TYPE =
    "SAVE_AGREEMENT_SUCCESS";
export const SAVE_AGREEMENT_FAILURE: SAVE_AGREEMENT_FAILURE_TYPE =
    "SAVE_AGREEMENT_FAILURE";

export const COPY_AGREEMENT: COPY_AGREEMENT_TYPE = "COPY_AGREEMENT";

export const DELETE_AGREEMENT_REQUEST: DELETE_AGREEMENT_REQUEST_TYPE =
    "DELETE_AGREEMENT_REQUEST";
export const DELETE_AGREEMENT_SUCCESS: DELETE_AGREEMENT_SUCCESS_TYPE =
    "DELETE_AGREEMENT_SUCCESS";
export const DELETE_AGREEMENT_FAILURE: DELETE_AGREEMENT_FAILURE_TYPE =
    "DELETE_AGREEMENT_FAILURE";

export const MERGE_AGREEMENT_REQUEST: MERGE_AGREEMENT_REQUEST_TYPE =
    "MERGE_AGREEMENT_REQUEST";
export const MERGE_AGREEMENT_SUCCESS: MERGE_AGREEMENT_SUCCESS_TYPE =
    "MERGE_AGREEMENT_SUCCESS";
export const MERGE_AGREEMENT_FAILURE: MERGE_AGREEMENT_FAILURE_TYPE =
    "MERGE_AGREEMENT_FAILURE";

export const DELETE_DISTRIBUTION_REQUEST: DELETE_DISTRIBUTION_REQUEST_TYPE =
    "DELETE_DISTRIBUTION_REQUEST";
export const DELETE_DISTRIBUTION_SUCCESS: DELETE_DISTRIBUTION_SUCCESS_TYPE =
    "DELETE_DISTRIBUTION_SUCCESS";
export const DELETE_DISTRIBUTION_FAILURE: DELETE_DISTRIBUTION_FAILURE_TYPE =
    "DELETE_DISTRIBUTION_FAILURE";

export const COPY_POOL: COPY_POOL_TYPE = "COPY_POOL";

export const COPY_DISTRIBUTION: COPY_DISTRIBUTION_TYPE = "COPY_DISTRIBUTION";

export const COPY_PAYMENTRUN: COPY_PAYMENTRUN_TYPE = "COPY_PAYMENTRUN";

export const COPY_LICENSE: COPY_LICENSE_TYPE = "COPY_LICENSE";

export const SAVE_PRODUCT_REQUEST: SAVE_PRODUCT_REQUEST_TYPE =
    "SAVE_PRODUCT_REQUEST";
export const SAVE_PRODUCT_SUCCESS: SAVE_PRODUCT_SUCCESS_TYPE =
    "SAVE_PRODUCT_SUCCESS";
export const SAVE_PRODUCT_FAILURE: SAVE_PRODUCT_FAILURE_TYPE =
    "SAVE_PRODUCT_FAILURE";

export const COPY_PRODUCT: COPY_PRODUCT_TYPE = "COPY_PRODUCT";

export const DELETE_PRODUCT_REQUEST: DELETE_PRODUCT_REQUEST_TYPE =
    "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS: DELETE_PRODUCT_SUCCESS_TYPE =
    "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE: DELETE_PRODUCT_FAILURE_TYPE =
    "DELETE_PRODUCT_FAILURE";

export const UPDATE_PRODUCT_SOURCE_IP: UPDATE_PRODUCT_SOURCE_IP_TYPE = "UPDATE_PRODUCT_SOURCE_IP";

export const SAVE_ACCOUNT_REQUEST: SAVE_ACCOUNT_REQUEST_TYPE =
    "SAVE_ACCOUNT_REQUEST";
export const SAVE_ACCOUNT_SUCCESS: SAVE_ACCOUNT_SUCCESS_TYPE =
    "SAVE_ACCOUNT_SUCCESS";
export const SAVE_ACCOUNT_FAILURE: SAVE_ACCOUNT_FAILURE_TYPE =
    "SAVE_ACCOUNT_FAILURE";

export const SAVE_ARTIST_REQUEST: SAVE_ARTIST_REQUEST_TYPE =
    "SAVE_ARTIST_REQUEST";
export const SAVE_ARTIST_SUCCESS: SAVE_ARTIST_SUCCESS_TYPE =
    "SAVE_ARTIST_SUCCESS";
export const SAVE_ARTIST_FAILURE: SAVE_ARTIST_FAILURE_TYPE =
    "SAVE_ARTIST_FAILURE";

export const SAVE_CLAIM_REQUEST: SAVE_CLAIM_REQUEST_TYPE =
    "SAVE_CLAIM_REQUEST";
export const SAVE_CLAIM_SUCCESS: SAVE_CLAIM_SUCCESS_TYPE =
    "SAVE_CLAIM_SUCCESS";
export const SAVE_CLAIM_FAILURE: SAVE_CLAIM_FAILURE_TYPE =
    "SAVE_CLAIM_FAILURE";

export const OPEN_ACCORDION: OPEN_ACCORDION_TYPE =
    "OPEN_ACCORDION";
export const CLOSE_ACCORDION: CLOSE_ACCORDION_TYPE =
    "CLOSE_ACCORDION";

export const OPEN_IP_MAINTENANCE_ACCORDION: OPEN_IP_MAINTENANCE_ACCORDION_TYPE =
    "OPEN_IP_MAINTENANCE_ACCORDION";
export const CLOSE_IP_MAINTENANCE_ACCORDION: CLOSE_IP_MAINTENANCE_ACCORDION_TYPE =
    "CLOSE_IP_MAINTENANCE_ACCORDION";

export const SET_CHANGES_MADE: SET_CHANGES_MADE_TYPE = "SET_CHANGES_MADE";
export const UPDATE_WORK_FIELD: UPDATE_WORK_FIELD_TYPE = "UPDATE_WORK_FIELD";
export const UPDATE_WORK_FIELDS: UPDATE_WORK_FIELDS_TYPE = "UPDATE_WORK_FIELDS";
export const UPDATE_PRODUCT_FIELD: UPDATE_PRODUCT_FIELD_TYPE = "UPDATE_PRODUCT_FIELD";
export const UPDATE_PRODUCT_FIELDS: UPDATE_PRODUCT_FIELDS_TYPE = "UPDATE_PRODUCT_FIELDS";
export const UPDATE_AGREEMENT_FIELD: UPDATE_AGREEMENT_FIELD_TYPE = "UPDATE_AGREEMENT_FIELD";
export const UPDATE_INTERESTED_PARTY_FIELD: UPDATE_INTERESTED_PARTY_FIELD_TYPE = "UPDATE_INTERESTED_PARTY_FIELD";
export const UPDATE_REPRESENTATION_FIELD: UPDATE_REPRESENTATION_FIELD_TYPE = "UPDATE_REPRESENTATION_FIELD";
export const UPDATE_INTERESTED_PARTY_IP_BASE_NUMBER: UPDATE_INTERESTED_PARTY_IP_BASE_NUMBER_TYPE = "UPDATE_INTERESTED_PARTY_IP_BASE_NUMBER";
export const UPDATE_ARTIST_FIELD: UPDATE_ARTIST_FIELD_TYPE = "UPDATE_ARTIST_FIELD";
export const UPDATE_SCHEDULED_JOB_FIELD: UPDATE_SCHEDULED_JOB_FIELD_TYPE = "UPDATE_SCHEDULED_JOB_FIELD";
export const SORT_WORK_FIELD_DATA: SORT_WORK_FIELD_DATA_TYPE = "SORT_WORK_FIELD_DATA"; 
export const SORT_AGREEMENT_FIELD_DATA: SORT_AGREEMENT_FIELD_DATA_TYPE = "SORT_AGREEMENT_FIELD_DATA";
export const SORT_WEIGHT_SETTINGS_FIELD_DATA: SORT_WEIGHT_SETTINGS_FIELD_DATA_TYPE = "SORT_WEIGHT_SETTINGS_FIELD_DATA";
export const SORT_DISTRIBUTION_POOL_FIELD_DATA: SORT_DISTRIBUTION_POOL_FIELD_DATA_TYPE = "SORT_DISTRIBUTION_POOL_FIELD_DATA";

export const SORT_INTERESTED_PARTY_FIELD_DATA: SORT_INTERESTED_PARTY_FIELD_DATA_TYPE = "SORT_INTERESTED_PARTY_FIELD_DATA";
export const SORT_PRODUCT_FIELD_DATA: SORT_PRODUCT_FIELD_DATA_TYPE = "SORT_PRODUCT_FIELD_DATA";
export const SORT_ARTIST_FIELD_DATA: SORT_ARTIST_FIELD_DATA_TYPE = "SORT_ARTIST_FIELD_DATA";

export const POST_WORK_BATCH_REQUEST: POST_WORK_BATCH_REQUEST_TYPE = "POST_WORK_BATCH_REQUEST";
export const POST_WORK_BATCH_SUCCESS: POST_WORK_BATCH_SUCCESS_TYPE = "POST_WORK_BATCH_SUCCESS";
export const POST_WORK_BATCH_FAILED: POST_WORK_BATCH_FAILED_TYPE = "POST_WORK_BATCH_FAILED";

export const GET_WORK_MATCH_CONNECTOR_REQUEST: GET_WORK_MATCH_CONNECTOR_REQUEST_TYPE = "GET_WORK_MATCH_CONNECTOR_REQUEST";
export const GET_WORK_MATCH_CONNECTOR_SUCCESS: GET_WORK_MATCH_CONNECTOR_SUCCESS_TYPE = "GET_WORK_MATCH_CONNECTOR_SUCCESS";
export const GET_WORK_MATCH_CONNECTOR_FAILED: GET_WORK_MATCH_CONNECTOR_FAILED_TYPE = "GET_WORK_MATCH_CONNECTOR_FAILED";

export const MATCH_WORK_BATCH_REQUEST: MATCH_WORK_BATCH_REQUEST_TYPE = "MATCH_WORK_BATCH_REQUEST";
export const MATCH_WORK_BATCH_SUCCESS: MATCH_WORK_BATCH_SUCCESS_TYPE = "MATCH_WORK_BATCH_SUCCESS";
export const MATCH_WORK_BATCH_FAILED: MATCH_WORK_BATCH_FAILED_TYPE = "MATCH_WORK_BATCH_FAILED";

export const POST_PRODUCT_BATCH_REQUEST: POST_PRODUCT_BATCH_REQUEST_TYPE = "POST_PRODUCT_BATCH_REQUEST";
export const POST_PRODUCT_BATCH_SUCCESS: POST_PRODUCT_BATCH_SUCCESS_TYPE = "POST_PRODUCT_BATCH_SUCCESS";
export const POST_PRODUCT_BATCH_FAILED: POST_PRODUCT_BATCH_FAILED_TYPE = "POST_PRODUCT_BATCH_FAILED";

export const MATCH_PRODUCT_BATCH_REQUEST: MATCH_PRODUCT_BATCH_REQUEST_TYPE = "MATCH_PRODUCT_BATCH_REQUEST";
export const MATCH_PRODUCT_BATCH_SUCCESS: MATCH_PRODUCT_BATCH_SUCCESS_TYPE = "MATCH_PRODUCT_BATCH_SUCCESS";
export const MATCH_PRODUCT_BATCH_FAILED: MATCH_PRODUCT_BATCH_FAILED_TYPE = "MATCH_PRODUCT_BATCH_FAILED";

export const POST_AGREEMENT_BATCH_REQUEST: POST_AGREEMENT_BATCH_REQUEST_TYPE = "POST_AGREEMENT_BATCH_REQUEST";
export const POST_AGREEMENT_BATCH_SUCCESS: POST_AGREEMENT_BATCH_SUCCESS_TYPE = "POST_AGREEMENT_BATCH_SUCCESS";
export const POST_AGREEMENT_BATCH_FAILED: POST_AGREEMENT_BATCH_FAILED_TYPE = "POST_AGREEMENT_BATCH_FAILED";

export const POST_ADJUSTMENT_BATCH_REQUEST: POST_ADJUSTMENT_BATCH_REQUEST_TYPE = "POST_ADJUSTMENT_BATCH_REQUEST";
export const POST_ADJUSTMENT_BATCH_SUCCESS: POST_ADJUSTMENT_BATCH_SUCCESS_TYPE = "POST_ADJUSTMENT_BATCH_SUCCESS";
export const POST_ADJUSTMENT_BATCH_FAILED: POST_ADJUSTMENT_BATCH_FAILED_TYPE = "POST_ADJUSTMENT_BATCH_FAILED";

export const AUTO_MATCH_WORK: AUTO_MATCH_WORK_TYPE =
    "AUTO_MATCH_WORK";

export const RESET_MESSAGE_BANNER: RESET_MESSAGE_BANNER_TYPE = "RESET_MESSAGE_BANNER";

export const SWITCH_SET_TYPE_GROUP: SWITCH_SET_TYPE_GROUP_TYPE = "SWITCH_SET_TYPE_GROUP";
export const UPDATE_CONTRIBUTOR_SET_TYPE: UPDATE_CONTRIBUTOR_SET_TYPE_TYPE = "UPDATE_CONTRIBUTOR_SET_TYPE";

export const UNDO_AGREEMENT_CHANGES_REQUEST: UNDO_AGREEMENT_CHANGES_REQUEST_TYPE = "UNDO_AGREEMENT_CHANGES_REQUEST";
export const UNDO_AGREEMENT_CHANGES_SUCCESS: UNDO_AGREEMENT_CHANGES_SUCCESS_TYPE = "UNDO_AGREEMENT_CHANGES_SUCCESS";
export const UNDO_AGREEMENT_CHANGES_FAILURE: UNDO_AGREEMENT_CHANGES_FAILURE_TYPE = "UNDO_AGREEMENT_CHANGES_FAILURE";

export const UNDO_DISTRIBUTION_CHANGES_REQUEST: UNDO_DISTRIBUTION_CHANGES_REQUEST_TYPE = "UNDO_DISTRIBUTION_CHANGES_REQUEST";
export const UNDO_DISTRIBUTION_CHANGES_SUCCESS: UNDO_DISTRIBUTION_CHANGES_SUCCESS_TYPE = "UNDO_DISTRIBUTION_CHANGES_SUCCESS";
export const UNDO_DISTRIBUTION_CHANGES_FAILURE: UNDO_DISTRIBUTION_CHANGES_FAILURE_TYPE = "UNDO_DISTRIBUTION_CHANGES_FAILURE";

export const UNDO_IP_CHANGES_REQUEST: UNDO_IP_CHANGES_REQUEST_TYPE = "UNDO_IP_CHANGES_REQUEST";
export const UNDO_IP_CHANGES_SUCCESS: UNDO_IP_CHANGES_SUCCESS_TYPE = "UNDO_IP_CHANGES_SUCCESS";
export const UNDO_IP_CHANGES_FAILURE: UNDO_IP_CHANGES_FAILURE_TYPE = "UNDO_IP_CHANGES_FAILURE";

export const UNDO_PRODUCT_CHANGES_REQUEST: UNDO_PRODUCT_CHANGES_REQUEST_TYPE = "UNDO_PRODUCT_CHANGES_REQUEST";
export const UNDO_PRODUCT_CHANGES_SUCCESS: UNDO_PRODUCT_CHANGES_SUCCESS_TYPE = "UNDO_PRODUCT_CHANGES_SUCCESS";
export const UNDO_PRODUCT_CHANGES_FAILURE: UNDO_PRODUCT_CHANGES_FAILURE_TYPE = "UNDO_PRODUCT_CHANGES_FAILURE";

export const UNDO_ARTIST_CHANGES_REQUEST: UNDO_ARTIST_CHANGES_REQUEST_TYPE = "UNDO_ARTIST_CHANGES_REQUEST";
export const UNDO_ARTIST_CHANGES_SUCCESS: UNDO_ARTIST_CHANGES_SUCCESS_TYPE = "UNDO_ARTIST_CHANGES_SUCCESS";
export const UNDO_ARTIST_CHANGES_FAILURE: UNDO_ARTIST_CHANGES_FAILURE_TYPE = "UNDO_ARTIST_CHANGES_FAILURE";

export const UNDO_PAYMENTRUN_CHANGES_REQUEST: UNDO_PAYMENTRUN_CHANGES_REQUEST_TYPE = "UNDO_PAYMENTRUN_CHANGES_REQUEST";
export const UNDO_PAYMENTRUN_CHANGES_SUCCESS: UNDO_PAYMENTRUN_CHANGES_SUCCESS_TYPE = "UNDO_PAYMENTRUN_CHANGES_SUCCESS";
export const UNDO_PAYMENTRUN_CHANGES_FAILURE: UNDO_PAYMENTRUN_CHANGES_FAILURE_TYPE = "UNDO_PAYMENTRUN_CHANGES_FAILURE";

export const SEARCH_WORK_VERSION_HISTORY_REQUEST: SEARCH_WORK_VERSION_HISTORY_REQUEST_TYPE = "SEARCH_WORK_VERSION_HISTORY_REQUEST";
export const SEARCH_WORK_VERSION_HISTORY_SUCCESS: SEARCH_WORK_VERSION_HISTORY_SUCCESS_TYPE = "SEARCH_WORK_VERSION_HISTORY_SUCCESS";
export const SEARCH_WORK_VERSION_HISTORY_FAILURE: SEARCH_WORK_VERSION_HISTORY_FAILURE_TYPE = "SEARCH_WORK_VERSION_HISTORY_FAILURE";

export const SEARCH_PRODUCT_VERSION_HISTORY_REQUEST: SEARCH_PRODUCT_VERSION_HISTORY_REQUEST_TYPE = "SEARCH_PRODUCT_VERSION_HISTORY_REQUEST";
export const SEARCH_PRODUCT_VERSION_HISTORY_SUCCESS: SEARCH_PRODUCT_VERSION_HISTORY_SUCCESS_TYPE = "SEARCH_PRODUCT_VERSION_HISTORY_SUCCESS";
export const SEARCH_PRODUCT_VERSION_HISTORY_FAILURE: SEARCH_PRODUCT_VERSION_HISTORY_FAILURE_TYPE = "SEARCH_PRODUCT_VERSION_HISTORY_FAILURE";

export const ADD_TO_PACKAGE_REQUEST: ADD_TO_PACKAGE_REQUEST_TYPE = "ADD_TO_PACKAGE_REQUEST";
export const ADD_TO_PACKAGE_SUCCESS: ADD_TO_PACKAGE_SUCCESS_TYPE = "ADD_TO_PACKAGE_SUCCESS";
export const ADD_TO_PACKAGE_FAILURE: ADD_TO_PACKAGE_FAILURE_TYPE = "ADD_TO_PACKAGE_FAILURE";

export const GENERATE_PACKAGE_SUCCESS: GENERATE_PACKAGE_SUCCESS_TYPE = "GENERATE_PACKAGE_SUCCESS";
export const GENERATE_PACKAGE_FAILURE: GENERATE_PACKAGE_FAILURE_TYPE = "GENERATE_PACKAGE_FAILURE";

export const CLOSE_PACKAGE_FAILURE: CLOSE_PACKAGE_FAILURE_TYPE = "CLOSE_PACKAGE_FAILURE";

export const ADD_PACKAGE_NOTE_FAILURE: ADD_PACKAGE_NOTE_FAILURE_TYPE = "ADD_PACKAGE_NOTE_FAILURE";


export const SEARCH_SOCIP_VERSION_HISTORY_REQUEST: SEARCH_SOCIP_VERSION_HISTORY_REQUEST_TYPE = "SEARCH_SOCIP_VERSION_HISTORY_REQUEST";
export const SEARCH_SOCIP_VERSION_HISTORY_SUCCESS: SEARCH_SOCIP_VERSION_HISTORY_SUCCESS_TYPE = "SEARCH_SOCIP_VERSION_HISTORY_SUCCESS";
export const SEARCH_SOCIP_VERSION_HISTORY_FAILURE: SEARCH_SOCIP_VERSION_HISTORY_FAILURE_TYPE = "SEARCH_SOCIP_VERSION_HISTORY_FAILURE";

export const SEARCH_AGREEMENT_VERSION_HISTORY_REQUEST: SEARCH_AGREEMENT_VERSION_HISTORY_REQUEST_TYPE = "SEARCH_AGREEMENT_VERSION_HISTORY_REQUEST";
export const SEARCH_AGREEMENT_VERSION_HISTORY_SUCCESS: SEARCH_AGREEMENT_VERSION_HISTORY_SUCCESS_TYPE = "SEARCH_AGREEMENT_VERSION_HISTORY_SUCCESS";
export const SEARCH_AGREEMENT_VERSION_HISTORY_FAILURE: SEARCH_AGREEMENT_VERSION_HISTORY_FAILURE_TYPE = "SEARCH_AGREEMENT_VERSION_HISTORY_FAILURE";

export const SEARCH_DISTRIBUTION_VERSION_HISTORY_REQUEST: SEARCH_DISTRIBUTION_VERSION_HISTORY_REQUEST_TYPE = "SEARCH_DISTRIBUTION_VERSION_HISTORY_REQUEST";
export const SEARCH_DISTRIBUTION_VERSION_HISTORY_SUCCESS: SEARCH_DISTRIBUTION_VERSION_HISTORY_SUCCESS_TYPE = "SEARCH_DISTRIBUTION_VERSION_HISTORY_SUCCESS";
export const SEARCH_DISTRIBUTION_VERSION_HISTORY_FAILURE: SEARCH_DISTRIBUTION_VERSION_HISTORY_FAILURE_TYPE = "SEARCH_DISTRIBUTION_VERSION_HISTORY_FAILURE";

export const SEARCH_PAYMENTRUN_VERSION_HISTORY_REQUEST: SEARCH_PAYMENTRUN_VERSION_HISTORY_REQUEST_TYPE = "SEARCH_PAYMENTRUN_VERSION_HISTORY_REQUEST";
export const SEARCH_PAYMENTRUN_VERSION_HISTORY_SUCCESS: SEARCH_PAYMENTRUN_VERSION_HISTORY_SUCCESS_TYPE = "SEARCH_PAYMENTRUN_VERSION_HISTORY_SUCCESS";
export const SEARCH_PAYMENTRUN_VERSION_HISTORY_FAILURE: SEARCH_PAYMENTRUN_VERSION_HISTORY_FAILURE_TYPE = "SEARCH_PAYMENTRUN_VERSION_HISTORY_FAILURE";

export const SEARCH_ADJUSTMENT_VERSION_HISTORY_REQUEST: SEARCH_ADJUSTMENT_VERSION_HISTORY_REQUEST_TYPE = "SEARCH_ADJUSTMENT_VERSION_HISTORY_REQUEST";
export const SEARCH_ADJUSTMENT_VERSION_HISTORY_SUCCESS: SEARCH_ADJUSTMENT_VERSION_HISTORY_SUCCESS_TYPE = "SEARCH_ADJUSTMENT_VERSION_HISTORY_SUCCESS";
export const SEARCH_ADJUSTMENT_VERSION_HISTORY_FAILURE: SEARCH_ADJUSTMENT_VERSION_HISTORY_FAILURE_TYPE = "SEARCH_ADJUSTMENT_VERSION_HISTORY_FAILURE";

export const SEARCH_POOL_VERSION_HISTORY_REQUEST: SEARCH_POOL_VERSION_HISTORY_REQUEST_TYPE = "SEARCH_POOL_VERSION_HISTORY_REQUEST";
export const SEARCH_POOL_VERSION_HISTORY_SUCCESS: SEARCH_POOL_VERSION_HISTORY_SUCCESS_TYPE = "SEARCH_POOL_VERSION_HISTORY_SUCCESS";
export const SEARCH_POOL_VERSION_HISTORY_FAILURE: SEARCH_POOL_VERSION_HISTORY_FAILURE_TYPE = "SEARCH_POOL_VERSION_HISTORY_FAILURE";

export const DELETE_ACCOUNT_REQUEST: DELETE_ACCOUNT_REQUEST_TYPE = "DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_REQUEST_SUCCESS: DELETE_ACCOUNT_REQUEST_SUCCESS_TYPE = "DELETE_ACCOUNT_REQUEST_SUCCESS";
export const DELETE_ACCOUNT_REQUEST_FAILURE: DELETE_ACCOUNT_REQUEST_FAILURE_TYPE = "DELETE_ACCOUNT_REQUEST_FAILURE";

export const DELETE_REPRESENTATIONS: DELETE_REPRESENTATIONS_TYPE = "DELETE_REPRESENTATIONS";
export const DELETE_REPRESENTATIONS_UPDATE: DELETE_REPRESENTATIONS_UPDATE_TYPE = "DELETE_REPRESENTATIONS_UPDATE";
export const DELETE_REPRESENTATIONS_REQUEST: DELETE_REPRESENTATIONS_REQUEST_TYPE = "DELETE_REPRESENTATIONS_REQUEST";
export const DELETE_REPRESENTATIONS_REQUEST_SUCCESS: DELETE_REPRESENTATIONS_REQUEST_SUCCESS_TYPE = "DELETE_REPRESENTATIONS_REQUEST_SUCCESS";
export const DELETE_REPRESENTATIONS_REQUEST_FAILURE: DELETE_REPRESENTATIONS_REQUEST_FAILURE_TYPE = "DELETE_REPRESENTATIONS_REQUEST_FAILURE";

export const ADD_REPRESENTATION_REQUEST: ADD_REPRESENTATION_REQUEST_TYPE = "ADD_REPRESENTATION_REQUEST";
export const ADD_REPRESENTATION_REQUEST_SUCCESS: ADD_REPRESENTATION_REQUEST_SUCCESS_TYPE = "ADD_REPRESENTATION_REQUEST_SUCCESS";
export const ADD_REPRESENTATION_REQUEST_FAILURE: ADD_REPRESENTATION_REQUEST_FAILURE_TYPE = "ADD_REPRESENTATION_REQUEST_FAILURE";
export const ADD_NEW_REPRESENTATION: ADD_NEW_REPRESENTATION_TYPE = "ADD_NEW_REPRESENTATION";

export const IP_TRANSFER_JOB_REQUEST: IP_TRANSFER_JOB_REQUEST_TYPE = "IP_TRANSFER_JOB_REQUEST";
export const IP_TRANSFER_JOB_REQUEST_SUCCESS: IP_TRANSFER_JOB_REQUEST_SUCCESS_TYPE = "IP_TRANSFER_JOB_REQUEST_SUCCESS";
export const IP_TRANSFER_JOB_REQUEST_FAILURE: IP_TRANSFER_JOB_REQUEST_FAILURE_TYPE = "IP_TRANSFER_JOB_REQUEST_FAILURE";

export const GET_REPRESENTATION_GROUPS: GET_REPRESENTATION_GROUPS_TYPE = "GET_REPRESENTATION_GROUPS";
export const GET_REPRESENTATION_GROUP_REQUEST: GET_REPRESENTATION_GROUP_REQUEST_TYPE = "GET_REPRESENTATION_GROUP_REQUEST";
export const GET_REPRESENTATION_GROUP_SUCCESS: GET_REPRESENTATION_GROUP_SUCCESS_TYPE = "GET_REPRESENTATION_GROUP_SUCCESS";
export const GET_REPRESENTATION_GROUP_FAILURE: GET_REPRESENTATION_GROUP_FAILURE_TYPE = "GET_REPRESENTATION_GROUP_FAILURE";

export const SET_WORK_GROUP_MAINTENANCE_STATE: SET_WORK_GROUP_MAINTENANCE_STATE_TYPE = "SET_WORK_GROUP_MAINTENANCE_STATE";

export const DELETE_ARTIST_REQUEST: DELETE_ARTIST_REQUEST_TYPE = "DELETE_ARTIST_REQUEST";
export const DELETE_ARTIST_REQUEST_SUCCESS: DELETE_ARTIST_REQUEST_SUCCESS_TYPE = "DELETE_ARTIST_REQUEST_SUCCESS";
export const DELETE_ARTIST_REQUEST_FAILURE: DELETE_ARTIST_REQUEST_FAILURE_TYPE = "DELETE_ARTIST_REQUEST_FAILURE";

export const UPDATE_IP_AGREEMENT_FILTER_STATE: UPDATE_IP_AGREEMENT_FILTER_STATE_TYPE = "UPDATE_IP_AGREEMENT_FILTER_STATE"
export const FILTER_IP_AGREEMENT_REQUEST: FILTER_IP_AGREEMENT_REQUEST_TYPE = "FILTER_IP_AGREEMENT_REQUEST";
export const FILTER_IP_AGREEMENT_SUCCESS: FILTER_IP_AGREEMENT_SUCCESS_TYPE = "FILTER_IP_AGREEMENT_SUCCESS";

export const UPDATE_IP_REPRESENTATION_FILTER_STATE: UPDATE_IP_REPRESENTATION_FILTER_STATE_TYPE = "UPDATE_IP_REPRESENTATION_FILTER_STATE"
export const FILTER_IP_REPRESENTATION_REQUEST: FILTER_IP_REPRESENTATION_REQUEST_TYPE = "FILTER_IP_REPRESENTATION_REQUEST";
export const FILTER_IP_REPRESENTATION_SUCCESS: FILTER_IP_REPRESENTATION_SUCCESS_TYPE = "FILTER_IP_REPRESENTATION_SUCCESS";

export const CONTRIBUTORS_DISPLAY_SETTING_SUCCESS: CONTRIBUTORS_DISPLAY_SETTING_SUCCESS_TYPE = "CONTRIBUTORS_DISPLAY_SETTING_SUCCESS";
export const CONTRIBUTORS_DISPLAY_SETTING_REQUEST: CONTRIBUTORS_DISPLAY_SETTING_REQUEST_TYPE = "CONTRIBUTORS_DISPLAY_SETTING_REQUEST";
export const CONTRIBUTORS_DISPLAY_SETTING_FAILURE: CONTRIBUTORS_DISPLAY_SETTING_FAILURE_TYPE = "CONTRIBUTORS_DISPLAY_SETTING_FAILURE";

export const GENRE_CATEGORIES_SUCCESS: GENRE_CATEGORIES_SUCCESS_TYPE = "GENRE_CATEGORIES_SUCCESS";
export const GENRE_CATEGORIES_REQUEST: GENRE_CATEGORIES_REQUEST_TYPE = "GENRE_CATEGORIES_REQUEST";
export const GENRE_CATEGORIES_FAILURE: GENRE_CATEGORIES_FAILURE_TYPE = "GENRE_CATEGORIES_FAILURE";

export const FILTER_GENRE_CATEGORY_FLAG_REQUEST: FILTER_GENRE_CATEGORY_FLAG_REQUEST_TYPE = "FILTER_GENRE_CATEGORY_FLAG_REQUEST";
export const FILTER_GENRE_CATEGORY_FLAG_SUCCESS: FILTER_GENRE_CATEGORY_FLAG_SUCCESS_TYPE = "FILTER_GENRE_CATEGORY_FLAG_SUCCESS";
export const FILTER_GENRE_CATEGORY_FLAG_FAILURE: FILTER_GENRE_CATEGORY_FLAG_FAILURE_TYPE = "FILTER_GENRE_CATEGORY_FLAG_FAILURE";

export const GENRE_CATEGORIESBYDOMORFOREIN_SUCCESS: GENRE_CATEGORIESBYDOMORFOREIN_SUCCESS_TYPE = "GENRE_CATEGORIESBYDOMORFOREIN_SUCCESS";
export const GENRE_CATEGORIESBYDOMORFOREIN_REQUEST: GENRE_CATEGORIESBYDOMORFOREIN_REQUEST_TYPE = "GENRE_CATEGORIESBYDOMORFOREIN_REQUEST";
export const GENRE_CATEGORIESBYDOMORFOREIN_FAILURE: GENRE_CATEGORIESBYDOMORFOREIN_FAILURE_TYPE = "GENRE_CATEGORIESBYDOMORFOREIN_FAILURE";

export const READONLY_FLAGS_FIELD_SUCCESS: READONLY_FLAGS_FIELD_SUCCESS_TYPE = "READONLY_FLAGS_FIELD_SUCCESS";
export const READONLY_FLAGS_FIELD_REQUEST: READONLY_FLAGS_FIELD_REQUEST_TYPE = "READONLY_FLAGS_FIELD_REQUEST";
export const READONLY_FLAGS_FIELD_FAILURE: READONLY_FLAGS_FIELD_FAILURE_TYPE = "READONLY_FLAGS_FIELD_FAILURE";

export const OTHER_INDICATORS_WORK_FLAG_TYPES_SUCCESS: OTHER_INDICATORS_WORK_FLAG_TYPES_SUCCESS_TYPE = "OTHER_INDICATORS_WORK_FLAG_TYPES_SUCCESS";
export const OTHER_INDICATORS_WORK_FLAG_TYPES_REQUEST: OTHER_INDICATORS_WORK_FLAG_TYPES_REQUEST_TYPE = "OTHER_INDICATORS_WORK_FLAG_TYPES_REQUEST";
export const OTHER_INDICATORS_WORK_FLAG_TYPES_FAILURE: OTHER_INDICATORS_WORK_FLAG_TYPES_FAILURE_TYPE = "OTHER_INDICATORS_WORK_FLAG_TYPES_FAILURE";

export const READONLY_INDICATORS_WORK_FLAG_TYPES_SUCCESS: READONLY_INDICATORS_WORK_FLAG_TYPES_SUCCESS_TYPE = "READONLY_INDICATORS_WORK_FLAG_TYPES_SUCCESS";
export const READONLY_INDICATORS_WORK_FLAG_TYPES_REQUEST: READONLY_INDICATORS_WORK_FLAG_TYPES_REQUEST_TYPE = "READONLY_INDICATORS_WORK_FLAG_TYPES_REQUEST";
export const READONLY_INDICATORS_WORK_FLAG_TYPES_FAILURE: READONLY_INDICATORS_WORK_FLAG_TYPES_FAILURE_TYPE = "READONLY_INDICATORS_WORK_FLAG_TYPES_FAILURE";

export const SET_EDITABLE_FIELDS_TAB: SET_EDITABLE_FIELDS_TAB_TYPE = "SET_EDITABLE_FIELDS_TAB";
export const UPDATE_EDITABLE_FIELD: UPDATE_EDITABLE_FIELD_TYPE = "UPDATE_EDITABLE_FIELD";
export const UPDATE_COMPONENT_FIELD_REQUEST: UPDATE_COMPONENT_FIELD_REQUEST_TYPE = "UPDATE_COMPONENT_FIELD_REQUEST";
export const UPDATE_COMPONENT_FIELD_SUCCESS: UPDATE_COMPONENT_FIELD_SUCCESS_TYPE = "UPDATE_COMPONENT_FIELD_SUCCESS";
export const UPDATE_COMPONENT_FIELD_FAILURE: UPDATE_COMPONENT_FIELD_FAILURE_TYPE = "UPDATE_COMPONENT_FIELD_FAILURE";
export const UPDATE_MULTIPLE_COMPONENT_FIELD_SUCCESS: UPDATE_MULTIPLE_COMPONENT_FIELD_SUCCESS_TYPE = 'UPDATE_MULTIPLE_COMPONENT_FIELD_SUCCESS';

export const GET_FORMAT_FIELDS_REQUEST: GET_FORMAT_FIELD_REQUEST_TYPE = 'GET_FIELD_FORMAT_REQUEST';
export const GET_FORMAT_FIELDS_SUCCESS: GET_FORMAT_FIELD_SUCCESS_TYPE = 'GET_FIELD_SUCCESS_REQUEST';
export const GET_FORMAT_FIELDS_FAILURE: GET_FORMAT_FIELD_FAILURE_TYPE = 'GET_FIELD_FAILURE_REQUEST';

export const APPLY_FORMATS_WORK_MAINTENANCE: APPLY_FORMATS_WORK_MAINTENANCE_TYPE = 'APPLY_FORMATS_WORK_MAINTENANCE';
export const APPLY_FORMATS_PRODUCT_MAINTENANCE: APPLY_FORMATS_PRODUCT_MAINTENANCE_TYPE = 'APPLY_FORMATS_PRODUCT_MAINTENANCE';

export const EXPAND_WORK_RESULT: EXPAND_WORK_RESULT_TYPE = 'EXPAND_WORK_RESULT';
export const EXPAND_USAGE_DETAIL: EXPAND_USAGE_DETAIL_TYPE = 'EXPAND_USAGE_DETAIL';
export const EXPAND_MATCHING_WORK: EXPAND_MATCHING_WORK_TYPE = 'EXPAND_MATCHING_WORK';
export const EXPAND_MATCHING_PRODUCT: EXPAND_MATCHING_PRODUCT_TYPE = 'EXPAND_MATCHING_PRODUCT';
export const EXPAND_PRODUCT_RESULT: EXPAND_PRODUCT_RESULT_TYPE = 'EXPAND_PRODUCT_RESULT';
export const EXPAND_ALL_RESULTS: EXPAND_ALL_RESULTS_TYPE = 'EXPAND_ALL_RESULTS';

export const ENABLE_CUSTOM_FIELD: ENABLE_CUSTOM_FIELD_TYPE = 'ENABLE_CUSTOM_FIELD';

export const ADD_REPERTOIRE_ATTACHMENT_REQUEST: ADD_REPERTOIRE_ATTACHMENT_REQUEST_TYPE = 'ADD_REPERTOIRE_ATTACHMENT_REQUEST';
export const ADD_REPERTOIRE_ATTACHMENT_SUCCESS: ADD_REPERTOIRE_ATTACHMENT_SUCCESS_TYPE = 'ADD_REPERTOIRE_ATTACHMENT_SUCCESS';
export const ADD_REPERTOIRE_ATTACHMENT_FAILURE: ADD_REPERTOIRE_ATTACHMENT_FAILURE_TYPE = 'ADD_REPERTOIRE_ATTACHMENT_FAILURE';

export const REMOVE_WORK_ATTACHMENT_REQUEST: REMOVE_WORK_ATTACHMENT_REQUEST_TYPE = 'REMOVE_WORK_ATTACHMENT_REQUEST';
export const REMOVE_WORK_ATTACHMENT_SUCCESS: REMOVE_WORK_ATTACHMENT_SUCCESS_TYPE = 'REMOVE_WORK_ATTACHMENT_SUCCESS';
export const REMOVE_WORK_ATTACHMENT_FAILURE: REMOVE_WORK_ATTACHMENT_FAILURE_TYPE = 'REMOVE_WORK_ATTACHMENT_FAILURE';

export const REMOVE_AGREEMENT_ATTACHMENT_REQUEST: REMOVE_AGREEMENT_ATTACHMENT_REQUEST_TYPE = 'REMOVE_AGREEMENT_ATTACHMENT_REQUEST';
export const REMOVE_AGREEMENT_ATTACHMENT_SUCCESS: REMOVE_AGREEMENT_ATTACHMENT_SUCCESS_TYPE = 'REMOVE_AGREEMENT_ATTACHMENT_SUCCESS';
export const REMOVE_AGREEMENT_ATTACHMENT_FAILURE: REMOVE_AGREEMENT_ATTACHMENT_FAILURE_TYPE = 'REMOVE_AGREEMENT_ATTACHMENT_FAILURE';

export const REMOVE_PRODUCT_ATTACHMENT_REQUEST: REMOVE_PRODUCT_ATTACHMENT_REQUEST_TYPE = 'REMOVE_PRODUCT_ATTACHMENT_REQUEST';
export const REMOVE_PRODUCT_ATTACHMENT_SUCCESS: REMOVE_PRODUCT_ATTACHMENT_SUCCESS_TYPE = 'REMOVE_PRODUCT_ATTACHMENT_SUCCESS';
export const REMOVE_PRODUCT_ATTACHMENT_FAILURE: REMOVE_PRODUCT_ATTACHMENT_FAILURE_TYPE = 'REMOVE_PRODUCT_ATTACHMENT_FAILURE';

export const REMOVE_USAGE_ATTACHMENT_REQUEST: REMOVE_USAGE_ATTACHMENT_REQUEST_TYPE = 'REMOVE_USAGE_ATTACHMENT_REQUEST';
export const REMOVE_USAGE_ATTACHMENT_SUCCESS: REMOVE_USAGE_ATTACHMENT_SUCCESS_TYPE = 'REMOVE_USAGE_ATTACHMENT_SUCCESS';
export const REMOVE_USAGE_ATTACHMENT_FAILURE: REMOVE_USAGE_ATTACHMENT_FAILURE_TYPE = 'REMOVE_USAGE_ATTACHMENT_FAILURE';

export const DELETE_BLOB_REQUEST: DELETE_BLOB_REQUEST_TYPE = 'DELETE_BLOB_REQUEST';
export const DELETE_BLOB_SUCCESS: DELETE_BLOB_SUCCESS_TYPE = 'DELETE_BLOB_SUCCESS';
export const DELETE_BLOB_FAILURE: DELETE_BLOB_FAILURE_TYPE = 'DELETE_BLOB_FAILURE';

export const ADD_DROPPED_FILE: ADD_DROPPED_FILE_TYPE = 'ADD_DROPPED_FILE';
export const SET_NEW_PROGRESS_REPERTOIRE_UPLOAD_FILE: SET_NEW_PROGRESS_UPLOAD_FILE_REPERTOIRE_PROCCESS_TYPE = 'SET_NEW_PROGRESS_UPLOAD_FILE_REPERTOIRE_PROCCESS';
export const CLEAN_DROPPED_FILES_STATE: CLEAN_DROPPED_FILES_STATE_TYPE = 'CLEAN_DROPPED_FILES_STATE';

export const UPDATE_SET_TYPE_ON_BREAK: UPDATE_SET_TYPE_ON_BREAK_TYPE = 'UPDATE_SET_TYPE_ON_BREAK';

export const SEARCH_WORKFLOW_REQUEST: SEARCH_WORKFLOW_REQUEST_TYPE = 'SEARCH_WORKFLOW_REQUEST';
export const SEARCH_WORKFLOW_SUCCESS: SEARCH_WORKFLOW_SUCCESS_TYPE = 'SEARCH_WORKFLOW_SUCCESS';
export const SEARCH_WORKFLOW_FAILURE: SEARCH_WORKFLOW_FAILURE_TYPE = 'SEARCH_WORKFLOW_FAILURE';
export const SELECT_SINGLE_WORKFLOW: SELECT_SINGLE_WORKFLOW_TYPE = 'SELECT_SINGLE_WORKFLOW';
export const SELECT_ALL_WORKFLOW: SELECT_ALL_WORKFLOW_TYPE = 'SELECT_ALL_WORKFLOW';
export const MARK_SELECT_ALL_WORKFLOW: MARK_SELECT_ALL_WORKFLOW_TYPE = 'MARK_SELECT_ALL_WORKFLOW';

export const ASSIGN_WORKFLOWS_REQUEST: ASSIGN_WORKFLOWS_REQUEST_TYPE = 'ASSIGN_WORKFLOWS_REQUEST';
export const ASSIGN_WORKFLOWS_SUCCESS: ASSIGN_WORKFLOWS_SUCCESS_TYPE = 'ASSIGN_WORKFLOWS_SUCCESS';
export const ASSIGN_WORKFLOWS_FAILURE: ASSIGN_WORKFLOWS_FAILURE_TYPE = 'ASSIGN_WORKFLOWS_FAILURE';

export const UPDATE_STATUS_WORKFLOWS_REQUEST: UPDATE_STATUS_WORKFLOWS_REQUEST_TYPE = 'UPDATE_STATUS_WORKFLOWS_REQUEST';
export const UPDATE_STATUS_WORKFLOWS_SUCCESS: UPDATE_STATUS_WORKFLOWS_SUCCESS_TYPE = 'UPDATE_STATUS_WORKFLOWS_SUCCESS';
export const UPDATE_STATUS_WORKFLOWS_FAILURE: UPDATE_STATUS_WORKFLOWS_FAILURE_TYPE = 'UPDATE_STATUS_WORKFLOWS_FAILURE';
export const CLEAR_SELECTED_WORKFLOW: CLEAR_SELECTED_WORKFLOWS_TYPE = 'CLEAR_SELECTED_WORKFLOWS';
export const GET_USERS_REQUEST: GET_USERS_REQUEST_TYPE = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS: GET_USERS_SUCCESS_TYPE = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE: GET_USERS_FAILURE_TYPE = "GET_USERS_FAILURE";

export const GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_REQUEST: GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_REQUEST_TYPE = 'GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_REQUEST';
export const GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_SUCCESS: GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_SUCCESS_TYPE = 'GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_SUCCESS';
export const GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_FAILURE: GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_FAILURE_TYPE = 'GET_SHOW_COMMENT_AS_DROP_DOWN_CONFIG_PARAMETER_FAILURE';

export const GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_REQUEST: GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_REQUEST_TYPE = 'GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_REQUEST';
export const GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_SUCCESS: GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_SUCCESS_TYPE = 'GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_SUCCESS';
export const GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_FAILURE: GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_FAILURE_TYPE = 'GET_AGREEMENT_SHARE_DISPLAY_OPTIONS_FAILURE';

export const GET_IPMAINTENANCE_DISPLAY_SETTINGS_REQUEST: GET_IPMAINTENANCE_DISPLAY_SETTINGS_REQUEST_TYPE = 'GET_IPMAINTENANCE_DISPLAY_SETTINGS_REQUEST';
export const GET_IPMAINTENANCE_DISPLAY_SETTINGS_SUCCESS: GET_IPMAINTENANCE_DISPLAY_SETTINGS_SUCCESS_TYPE = 'GET_IPMAINTENANCE_DISPLAY_SETTINGS_SUCCESS';
export const GET_IPMAINTENANCE_DISPLAY_SETTINGS_FAILURE: GET_IPMAINTENANCE_DISPLAY_SETTINGS_FAILURE_TYPE = 'GET_IPMAINTENANCE_DISPLAY_SETTINGS_FAILURE';

export const GET_USER_PREFERENCES_REQUEST: GET_USER_PREFERENCES_REQUEST_TYPE = 'GET_USER_PREFERENCES_REQUEST';
export const GET_USER_PREFERENCES_SUCCESS: GET_USER_PREFERENCES_SUCCESS_TYPE = 'GET_USER_PREFERENCES_SUCCESS';
export const GET_USER_PREFERENCES_FAILURE: GET_USER_PREFERENCES_FAILURE_TYPE = 'GET_USER_PREFERENCES_FAILURE';

export const UPDATE_USER_PREFERENCE: UPDATE_USER_PREFERENCE_TYPE = 'UPDATE_USER_PREFERENCE';

export const GET_USAGE_GRID_DISPLAY_OPTIONS_REQUEST: GET_USAGE_GRID_DISPLAY_OPTIONS_REQUEST_TYPE = 'GET_USAGE_GRID_DISPLAY_OPTIONS_REQUEST';
export const GET_USAGE_GRID_DISPLAY_OPTIONS_SUCCESS: GET_USAGE_GRID_DISPLAY_OPTIONS_SUCCESS_TYPE = 'GET_USAGE_GRID_DISPLAY_OPTIONS_SUCCESS';
export const GET_USAGE_GRID_DISPLAY_OPTIONS_FAILURE: GET_USAGE_GRID_DISPLAY_OPTIONS_FAILURE_TYPE = 'GET_USAGE_GRID_DISPLAY_OPTIONS_FAILURE';

export const GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_REQUEST: GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_REQUEST_TYPE = 'GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_REQUEST';
export const GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_SUCCESS: GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_SUCCESS_TYPE = 'GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_SUCCESS';
export const GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_FAILURE: GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_FAILURE_TYPE = 'GET_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_FAILURE';

export const GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_REQUEST: GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_REQUEST_TYPE = 'GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_REQUEST';
export const GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_SUCCESS: GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_SUCCESS_TYPE = 'GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_SUCCESS';
export const GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_FAILURE: GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_FAILURE_TYPE = 'GET_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS_FAILURE';

export const GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_REQUEST: GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_REQUEST_TYPE = 'GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_REQUEST';
export const GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_SUCCESS: GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_SUCCESS_TYPE = 'GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_SUCCESS';
export const GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_FAILURE: GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_FAILURE_TYPE = 'GET_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_FAILURE';

export const GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_REQUEST: GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_REQUEST_TYPE = 'GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_REQUEST';
export const GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_SUCCESS: GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_SUCCESS_TYPE = 'GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_SUCCESS';
export const GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_FAILURE: GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_FAILURE_TYPE = 'GET_PRODUCT_MAINTENANCE_CUESHEET_DATASOURCE_FAILURE';

export const GET_PD_ARRANGEMENT_MUSIC_LYRIC_OPTIONS_REQUEST: GET_PD_ARRANGEMENT_MUSIC_LYRIC_REQUEST_TYPE = 'GET_PD_ARRANGEMENT_MUSIC_LYRIC_OPTIONS_REQUEST';
export const GET_PD_ARRANGEMENT_MUSIC_LYRIC_OPTIONS_SUCCESS: GET_PD_ARRANGEMENT_MUSIC_LYRIC_SUCCESS_TYPE = 'GET_PD_ARRANGEMENT_MUSIC_LYRIC_OPTIONS_SUCCESS';
export const GET_PD_ARRANGEMENT_MUSIC_LYRIC_OPTIONS_FAILURE: GET_PD_ARRANGEMENT_MUSIC_LYRIC_FAILURE_TYPE = 'GET_PD_ARRANGEMENT_MUSIC_LYRIC_OPTIONS_FAILURE';

export const GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST: GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST_TYPE = 'GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST';
export const GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS: GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS_TYPE = 'GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS';
export const GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE: GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE_TYPE = 'GET_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE';

export const GET_SHARE_TOLERANCE_SETTINGS_REQUEST: GET_SHARE_TOLERANCE_SETTINGS_REQUEST_TYPE = 'GET_SHARE_TOLERANCE_SETTINGS_REQUEST';
export const GET_SHARE_TOLERANCE_SETTINGS_SUCCESS: GET_SHARE_TOLERANCE_SETTINGS_SUCCESS_TYPE = 'GET_SHARE_TOLERANCE_SETTINGS_SUCCESS';
export const GET_SHARE_TOLERANCE_SETTINGS_FAILURE: GET_SHARE_TOLERANCE_SETTINGS_FAILURE_TYPE = 'GET_SHARE_TOLERANCE_SETTINGS_FAILURE';

export const GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST: GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST_TYPE = 'GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST';
export const GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS: GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS_TYPE = 'GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS';
export const GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE: GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE_TYPE = 'GET_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE';

export const GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST: GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST_TYPE = 'GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_REQUEST';
export const GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS: GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS_TYPE = 'GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_SUCCESS';
export const GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE: GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE_TYPE = 'GET_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS_FAILURE';

export const GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_REQUEST: GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_REQUEST_TYPE = 'GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_REQUEST';
export const GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_SUCCESS: GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_SUCCESS_TYPE = 'GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_SUCCESS';
export const GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_FAILURE: GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_FAILURE_TYPE = 'GET_DISTRIBUTION_MAINTENANCE_SETTINGS_VIEW_DISPLAY_OPTIONS_FAILURE';

export const GET_USAGE_MATCHING_DEFAULTS_WORKS_REQUEST: GET_USAGE_MATCHING_DEFAULTS_WORKS_REQUEST_TYPE = 'GET_USAGE_MATCHING_DEFAULTS_WORKS_REQUEST';
export const GET_USAGE_MATCHING_DEFAULTS_WORKS_SUCCESS: GET_USAGE_MATCHING_DEFAULTS_WORKS_SUCCESS_TYPE = 'GET_USAGE_MATCHING_DEFAULTS_WORKS_SUCCESS';
export const GET_USAGE_MATCHING_DEFAULTS_WORKS_FAILURE: GET_USAGE_MATCHING_DEFAULTS_WORKS_FAILURE_TYPE = 'GET_USAGE_MATCHING_DEFAULTS_WORKS_FAILURE';

export const GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_REQUEST: GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_REQUEST_TYPE = 'GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_REQUEST';
export const GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_SUCCESS: GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_SUCCESS_TYPE = 'GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_SUCCESS';
export const GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_FAILURE: GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_FAILURE_TYPE = 'GET_USAGE_MATCHING_DEFAULTS_PRODUCTS_FAILURE';

export const UPDATE_PAGINATION: UPDATE_PAGINATION_TYPE = 'UPDATE_PAGINATION';
export const RESET_PAGINATION: RESET_PAGINATION_TYPE = 'RESET_PAGINATION';
export const SET_IS_HUNDRED_RESULTS_PER_PAGE: SET_IS_HUNDRED_RESULTS_PER_PAGE_TYPE = 'SET_IS_HUNDRED_RESULTS_PER_PAGE';

export const SORT_SEARCH_RESULTS: SORT_SEARCH_RESULTS_TYPE = 'SORT_SEARCH_RESULTS';

export const GET_SHARE_DECIMALS_TO_DISPLAY_REQUEST: GET_SHARE_DECIMALS_TO_DISPLAY_REQUEST_TYPE = 'GET_SHARE_DECIMALS_TO_DISPLAY_REQUEST_TYPE';
export const GET_SHARE_DECIMALS_TO_DISPLAY_SUCCESS: GET_SHARE_DECIMALS_TO_DISPLAY_SUCCESS_TYPE = 'GET_SHARE_DECIMALS_TO_DISPLAY_SUCCESS_TYPE';
export const GET_SHARE_DECIMALS_TO_DISPLAY_FAILURE: GET_SHARE_DECIMALS_TO_DISPLAY_FAILURE_TYPE = 'GET_SHARE_DECIMALS_TO_DISPLAY_FAILURE_TYPE';

export const START_WORKFLOW_SESSION: START_WORKFLOW_SESSION_TYPE = 'START_WORKFLOW_SESSION';
export const CANCEL_WORKFLOW_SESSION: CANCEL_WORKFLOW_SESSION_TYPE = 'CANCEL_WORKFLOW_SESSION';
export const NEXT_WORKFLOW_IN_SESSION: NEXT_WORKFLOW_IN_SESSION_TYPE = 'NEXT_WORKFLOW_IN_SESSION';
export const SKIP_WORKFLOW_IN_SESSION: SKIP_WORKFLOW_IN_SESSION_TYPE = 'SKIP_WORKFLOW_IN_SESSION';
export const SET_ERROR_WORKFLOW_SESSION: SET_ERROR_WORKFLOW_SESSION_TYPE = 'SET_ERROR_WORKFLOW_SESSION';

export const SET_WARNING_MESSAGE: SET_WARNING_MESSAGE_TYPE = 'SET_WARNING_MESSAGE';
export const SET_VALIDATION_WARNING_MESSAGE: SET_VALIDATION_WARNING_MESSAGE_TYPE = 'SET_VALIDATION_WARNING_MESSAGE';

export const SUBMIT_PRODUCT_AV_REQUEST = "SUBMIT_PRODUCT_AV_REQUEST";
export const SUBMIT_PRODUCT_AV_REQUEST_SUCCESS = "SUBMIT_PRODUCT_AV_REQUEST_SUCCESS";
export const SUBMIT_PRODUCT_AV_REQUEST_FAILURE = "SUBMIT_PRODUCT_AV_REQUEST_FAILURE";

export const GET_PRODUCT_MAINTENANCE_SUBMISSION_CONFIG_REQUEST = "GET_PRODUCT_MAINTENANCE_SUBMISSION_CONFIG_REQUEST";
export const GET_PRODUCT_MAINTENANCE_SUBMISSION_CONFIG_FAILURE = "GET_PRODUCT_MAINTENANCE_SUBMISSION_CONFIG_FAILURE";
export const GET_PRODUCT_MAINTENANCE_SUBMISSION_CONFIG_SUCCESS = "GET_PRODUCT_MAINTENANCE_SUBMISSION_CONFIG_SUCCESS";

export const UPDATE_SELECTION_CRITERIA: UPDATE_SELECTION_CRITERIA_TYPE = "UPDATE_SELECTION_CRITERIA";
export const UPDATE_REPRESENTATION_SELECTOR: UPDATE_REPRESENTATION_SELECTOR_TYPE = "UPDATE_REPRESENTATION_SELECTOR";
export const CLEAR_REPERTOIRE_CACHE: CLEAR_REPERTOIRE_CACHE_TYPE = "CLEAR_REPERTOIRE_CACHE";

export const UPDATE_FOREIGN_SOCIETY_INFO: UPDATE_FOREIGN_SOCIETY_INFO_TYPE = "UPDATE_FOREIGN_SOCIETY_INFO";

export const DISPLAY_REPRESENTATION: DISPLAY_REPRESENTATION_TYPE = "DISPLAY_REPRESENTATION";

export const SAVE_WORKFLOW_REQUEST: SAVE_WORKFLOW_REQUEST_TYPE = "SAVE_WORKFLOW_REQUEST";
export const SAVE_WORKFLOW_SUCCESS: SAVE_WORKFLOW_SUCCESS_TYPE = "SAVE_WORKFLOW_SUCCESS";
export const SAVE_WORKFLOW_FAILURE: SAVE_WORKFLOW_FAILURE_TYPE = "SAVE_WORKFLOW_FAILURE";

export const APPROVE_REJECT_WORKFLOW_SUCCESS: APPROVE_REJECT_WORKFLOW_SUCCESS_TYPE = "APPROVE_REJECT_WORKFLOW_SUCCESS";

export const REFRESH_USAGE_DETAILS_REQUEST: REFRESH_USAGE_DETAILS_REQUEST_TYPE = "REFRESH_USAGE_DETAILS_REQUEST";
export const REFRESH_USAGE_DETAILS_SUCCESS: REFRESH_USAGE_DETAILS_SUCCESS_TYPE = "REFRESH_USAGE_DETAILS_SUCCESS";
export const REFRESH_USAGE_DETAILS_FAILURE: REFRESH_USAGE_DETAILS_FAILURE_TYPE = "REFRESH_USAGE_DETAILS_FAILURE";

export const SAVE_ADJUSTMENT_REQUEST: SAVE_ADJUSTMENT_REQUEST_TYPE = "SAVE_ADJUSTMENT_REQUEST";
export const SAVE_ADJUSTMENT_SUCCESS: SAVE_ADJUSTMENT_SUCCESS_TYPE = "SAVE_ADJUSTMENT_SUCCESS";
export const SAVE_ADJUSTMENT_FAILURE: SAVE_ADJUSTMENT_FAILURE_TYPE = "SAVE_ADJUSTMENT_FAILURE";

export const GET_ADJUSTMENT_DETAILS_REQUEST: GET_ADJUSTMENT_DETAILS_REQUEST_TYPE = "GET_ADJUSTMENT_DETAILS_REQUEST";
export const GET_ADJUSTMENT_DETAILS_SUCCESS: GET_ADJUSTMENT_DETAILS_SUCCESS_TYPE = "GET_ADJUSTMENT_DETAILS_SUCCESS";
export const GET_ADJUSTMENT_DETAILS_FAILURE: GET_ADJUSTMENT_DETAILS_FAILURE_TYPE = "GET_ADJUSTMENT_DETAILS_FAILURE";

export const IP_TRANSFER_REQUEST: IP_TRANSFER_REQUEST_TYPE = "IP_TRANSFER_REQUEST";
export const IP_TRANSFER_SUCCESS: IP_TRANSFER_SUCCESS_TYPE = "IP_TRANSFER_SUCCESS";
export const IP_TRANSFER_FAILURE: IP_TRANSFER_FAILURE_TYPE = "IP_TRANSFER_FAILURE";

export const COPY_ADJUSTMENT: COPY_ADJUSTMENT_TYPE = "COPY_ADJUSTMENT";
export const UNDO_ADJUSTMENT_CHANGES: UNDO_ADJUSTMENT_CHANGES_TYPE = "UNDO_ADJUSTMENT_CHANGES";
export const UNDO_ADJUSTMENT_CHANGES_SUCCESS: UNDO_ADJUSTMENT_CHANGES_SUCCESS_TYPE = "UNDO_ADJUSTMENT_CHANGES_SUCCESS";
export const UNDO_ADJUSTMENT_CHANGES_FAILURE: UNDO_ADJUSTMENT_CHANGES_FAILURE_TYPE = "UNDO_ADJUSTMENT_CHANGES_FAILURE";
export const UPDATE_ADJUSTMENT_FIELD: UPDATE_ADJUSTMENT_FIELD_TYPE = "UPDATE_ADJUSTMENT_FIELD";
export const CREATE_NEW_ADJUSTMENT: CREATE_NEW_ADJUSTMENT_TYPE = "CREATE_NEW_ADJUSTMENT";

export const SEARCH_DATABRICKS_ALLOCATIONS_REQUEST: SEARCH_DATABRICKS_ALLOCATIONS_REQUEST_TYPE = "SEARCH_DATABRICKS_ALLOCATIONS_REQUEST";
export const SEARCH_DATABRICKS_ALLOCATIONS_SUCCESS: SEARCH_DATABRICKS_ALLOCATIONS_SUCCESS_TYPE = "SEARCH_DATABRICKS_ALLOCATIONS_SUCCESS";
export const SEARCH_DATABRICKS_ALLOCATIONS_FAILURE: SEARCH_DATABRICKS_ALLOCATIONS_FAILURE_TYPE = "SEARCH_DATABRICKS_ALLOCATIONS_FAILURE";

export const ADD_IP_TO_ADJUSTMENT_DETAILS: ADD_IP_TO_ADJUSTMENT_DETAILS_TYPE = "ADD_IP_TO_ADJUSTMENT_DETAILS";
export const ADD_IP_TO_USAGE_DETAILS: ADD_IP_TO_USAGE_DETAILS_TYPE = "ADD_IP_TO_USAGE_DETAILS";

export const SEARCH_PAYMENTRUNS_REQUEST: SEARCH_PAYMENTRUNS_REQUEST_TYPE = "SEARCH_PAYMENTRUNS_REQUEST";
export const SEARCH_PAYMENTRUNS_SUCCESS: SEARCH_PAYMENTRUNS_SUCCESS_TYPE = "SEARCH_PAYMENTRUNS_SUCCESS";
export const SEARCH_PAYMENTRUNS_FAILURE: SEARCH_PAYMENTRUNS_FAILURE_TYPE = "SEARCH_PAYMENTRUNS_FAILURE";

export const GET_PAYMENTRUN_REQUEST: GET_PAYMENTRUN_REQUEST_TYPE = "GET_PAYMENTRUN_REQUEST";
export const GET_PAYMENTRUN_SUCCESS: GET_PAYMENTRUN_SUCCESS_TYPE = "GET_PAYMENTRUN_SUCCESS";
export const GET_PAYMENTRUN_FAILURE: GET_PAYMENTRUN_FAILURE_TYPE = "GET_PAYMENTRUN_FAILURE";

export const GET_PAYMENTRUN_ID_REQUEST: GET_PAYMENTRUN_ID_REQUEST_TYPE = "GET_PAYMENTRUN_ID_REQUEST";
export const GET_PAYMENTRUN_ID_SUCCESS: GET_PAYMENTRUN_ID_SUCCESS_TYPE = "GET_PAYMENTRUN_ID_SUCCESS";
export const GET_PAYMENTRUN_ID_FAILURE: GET_PAYMENTRUN_ID_FAILURE_TYPE = "GET_PAYMENTRUN_ID_FAILURE";

export const UPDATE_PAYMENTRUN_FIELD: UPDATE_PAYMENTRUN_FIELD_TYPE = "UPDATE_PAYMENTRUN_FIELD";


export const SEARCH_LICENSES_REQUEST: SEARCH_LICENSES_REQUEST_TYPE = "SEARCH_LICENSES_REQUEST";
export const SEARCH_LICENSES_SUCCESS: SEARCH_LICENSES_SUCCESS_TYPE = "SEARCH_LICENSES_SUCCESS";
export const SEARCH_LICENSES_FAILURE: SEARCH_LICENSES_FAILURE_TYPE = "SEARCH_LICENSES_FAILURE";

export const UPDATE_LICENSE_FIELD: UPDATE_LICENSE_FIELD_TYPE = "UPDATE_LICENSE_FIELD";

export const GET_LICENSE_REQUEST: GET_LICENSE_REQUEST_TYPE = "GET_LICENSE_REQUEST";
export const GET_LICENSE_SUCCESS: GET_LICENSE_SUCCESS_TYPE = "GET_LICENSE_SUCCESS";
export const GET_LICENSE_FAILURE: GET_LICENSE_FAILURE_TYPE = "GET_LICENSE_FAILURE";

export const UNDO_LICENSE_SUCCESS: UNDO_LICENSE_SUCCESS_TYPE = "UNDO_LICENSE_SUCCESS";

export const GET_CLAIM_REQUEST: GET_CLAIM_REQUEST_TYPE = "GET_CLAIM_REQUEST";
export const GET_CLAIM_SUCCESS: GET_CLAIM_SUCCESS_TYPE = "GET_CLAIM_SUCCESS";
export const GET_CLAIM_FAILURE: GET_CLAIM_FAILURE_TYPE = "GET_CLAIM_FAILURE";

export const SAVE_LICENSE_REQUEST: SAVE_LICENSE_REQUEST_TYPE = "SAVE_LICENSE_REQUEST";
export const SAVE_LICENSE_SUCCESS: SAVE_LICENSE_SUCCESS_TYPE = "SAVE_LICENSE_SUCCESS";
export const SAVE_LICENSE_FAILURE: SAVE_LICENSE_FAILURE_TYPE = "SAVE_LICENSE_FAILURE";

export const SUBMIT_LICENSE_SUCCESS: SUBMIT_LICENSE_SUCCESS_TYPE = "SUBMIT_LICENSE_SUCCESS";
export const SUBMIT_LICENSE_FAILURE: SUBMIT_LICENSE_FAILURE_TYPE = "SUBMIT_LICENSE_FAILURE";

export const SEARCH_LICENSE_USER_REQUEST: SEARCH_LICENSE_USER_REQUEST_TYPE = "SEARCH_LICENSE_USER_REQUEST";
export const SEARCH_LICENSE_USER_SUCCESS: SEARCH_LICENSE_USER_SUCCESS_TYPE = "SEARCH_LICENSE_USER_SUCESS";
export const SEARCH_LICENSE_USER_FAILURE: SEARCH_LICENSE_USER_FAILURE_TYPE = "SEARCH_LICENSE_USER_FAILURE";

export const GET_LICENSE_REQUEST_WORK_PARAMETERS_REQUEST: GET_LICENSE_REQUEST_WORK_PARAMETERS_REQUEST_TYPE = "GET_LICENSE_REQUEST_WORK_PARAMETERS_REQUEST";
export const GET_LICENSE_REQUEST_WORK_PARAMETERS_SUCCESS: GET_LICENSE_REQUEST_WORK_PARAMETERS_SUCCESS_TYPE = "GET_LICENSE_REQUEST_WORK_PARAMETERS_SUCCESS";
export const GET_LICENSE_REQUEST_WORK_PARAMETERS_FAILURE: GET_LICENSE_REQUEST_WORK_PARAMETERS_FAILURE_TYPE = "GET_LICENSE_REQUEST_WORK_PARAMETERS_FAILURE";

export const GET_LICENSE_WORKS_CONTRIBUTORS_REQUEST: GET_LICENSE_WORKS_CONTRIBUTORS_REQUEST_TYPE = "GET_LICENSE_WORKS_CONTRIBUTORS_REQUEST";
export const GET_LICENSE_WORKS_CONTRIBUTORS_SUCCESS: GET_LICENSE_WORKS_CONTRIBUTORS_SUCCESS_TYPE = "GET_LICENSE_WORKS_CONTRIBUTORS_SUCCESS";
export const GET_LICENSE_WORKS_CONTRIBUTORS_FAILURE: GET_LICENSE_WORKS_CONTRIBUTORS_FAILURE_TYPE = "GET_LICENSE_WORKS_CONTRIBUTORS_FAILURE";

export const IMPORT_LICENSE_WORKS_PRICES: IMPORT_LICENSE_WORKS_PRICES_TYPE = "IMPORT_LICENSE_WORKS_PRICES";

export const INCREMENT_TEMPID: INCREMENT_TEMPID_TYPE = "INCREMENT_TEMPID";

export const UPDATE_USAGE_GROUP_SET_LIST_ADDITIONAL: UPDATE_USAGE_GROUP_SET_LIST_ADDITIONAL_TYPE =
    "UPDATE_USAGE_GROUP_SET_LIST_ADDITIONAL";
export const UPDATE_USAGE_GROUP_LOCATION: UPDATE_USAGE_GROUP_LOCATION_TYPE =
    "UPDATE_USAGE_GROUP_LOCATION";
export const UPDATE_USAGE_GROUP_VENUE_DETAILS: UPDATE_USAGE_GROUP_VENUE_DETAILS_TYPE =
    "UPDATE_USAGE_GROUP_VENUE_DETAILS";

export const CALCULATE_LICENSE_WORKS_PRICES_REQUEST: CALCULATE_LICENSE_WORKS_PRICES_REQUEST_TYPE = "CALCULATE_LICENSE_WORKS_PRICES_REQUEST";
export const CALCULATE_LICENSE_WORKS_PRICES_SUCCESS: CALCULATE_LICENSE_WORKS_PRICES_SUCCESS_TYPE = "CALCULATE_LICENSE_WORKS_PRICES_SUCCESS";
export const CALCULATE_LICENSE_WORKS_PRICES_FAILURE: CALCULATE_LICENSE_WORKS_PRICES_FAILURE_TYPE = "CALCULATE_LICENSE_WORKS_PRICES_FAILURE";

export const GET_COMMENT_CATEGORY_FLAG_REQUEST: GET_COMMENT_CATEGORY_FLAG_REQUEST_TYPE = "GET_COMMENT_CATEGORY_FLAG_REQUEST";
export const GET_COMMENT_CATEGORY_FLAG_SUCCESS: GET_COMMENT_CATEGORY_FLAG_SUCCESS_TYPE = "GET_COMMENT_CATEGORY_FLAG_SUCCESS";
export const GET_COMMENT_CATEGORY_FLAG_FAILURE: GET_COMMENT_CATEGORY_FLAG_FAILURE_TYPE = "GET_COMMENT_CATEGORY_FLAG_FAILURE";

export const UPDATE_IPNAMES: UPDATE_IPNAMES_TYPE = "UPDATE_IPNAMES";
export const SAVE_IPNAMES_SUCCESS: SAVE_IPNAMES_SUCCESS_TYPE = "SAVE_IPNAMES_SUCCESS";

export const GET_OPEN_DISTRIBUTIONS_REQUEST: GET_OPEN_DISTRIBUTIONS_REQUEST_TYPE = "GET_OPEN_DISTRIBUTIONS_REQUEST";
export const GET_OPEN_DISTRIBUTIONS_SUCCESS: GET_OPEN_DISTRIBUTIONS_SUCCESS_TYPE = "GET_OPEN_DISTRIBUTIONS_SUCCESS";
export const GET_OPEN_DISTRIBUTIONS_FAILURE: GET_OPEN_DISTRIBUTIONS_FAILURE_TYPE = "GET_OPEN_DISTRIBUTIONS_FAILURE";

export const GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_REQUEST: GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_REQUEST_TYPE = "GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_REQUEST";
export const GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_SUCCESS: GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_SUCCESS_TYPE = "GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_SUCCESS";
export const GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_FAILURE: GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_FAILURE_TYPE = "GET_AGREEMENT_ATTACHMENT_TYPE_REQUIRED_FAILURE";

export const GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_REQUEST: GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_REQUEST_TYPE = "GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_REQUEST";
export const GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_SUCCESS: GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_SUCCESS_TYPE = "GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_SUCCESS";
export const GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_FAILURE: GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_FAILURE_TYPE = "GET_EXCLUDE_SOURCE_TYPE_SHOW_FIELDS_FAILURE";

export const TRIGGER_MANUAL_CLAIM_JOB_SUCCESS: TRIGGER_MANUAL_CLAIM_JOB_SUCCESS_TYPE = "TRIGGER_MANUAL_CLAIM_JOB_SUCCESS_TYPE";
export const TRIGGER_MANUAL_CLAIM_JOB_REQUEST: TRIGGER_MANUAL_CLAIM_JOB_REQUEST_TYPE = "TRIGGER_MANUAL_CLAIM_JOB_REQUEST_TYPE";
export const TRIGGER_MANUAL_CLAIM_JOB_FAILURE: TRIGGER_MANUAL_CLAIM_JOB_FAILURE_TYPE = "TRIGGER_MANUAL_CLAIM_JOB_FAILURE_TYPE";

export const GET_DOMESTIC_OR_FOREIGN_RECALCULATE_REQUEST: GET_DOMESTIC_OR_FOREIGN_RECALCULATE_REQUEST_TYPE = 'GET_DOMESTIC_OR_FOREIGN_RECALCULATE_REQUEST_TYPE';
export const GET_DOMESTIC_OR_FOREIGN_RECALCULATE_SUCCESS: GET_DOMESTIC_OR_FOREIGN_RECALCULATE_SUCCESS_TYPE = 'GET_DOMESTIC_OR_FOREIGN_RECALCULATE_SUCCESS_TYPE';
export const GET_DOMESTIC_OR_FOREIGN_RECALCULATE_FAILURE: GET_DOMESTIC_OR_FOREIGN_RECALCULATE_FAILURE_TYPE = 'GET_DOMESTIC_OR_FOREIGN_RECALCULATE_FAILURE_TYPE';

export const MY_PACKAGES_DOWNLOAD_FORMAT_SUCCESS: MY_PACKAGES_DOWNLOAD_FORMAT_SUCCESS_TYPE = "MY_PACKAGES_DOWNLOAD_FORMAT_SUCCESS";

export const SEARCH_POOL_BY_CODE_REQUEST : SEARCH_POOL_BY_CODE_REQUEST_TYPE = "SEARCH_POOL_BY_CODE_REQUEST";
export const SEARCH_POOL_BY_CODE_SUCCESS : SEARCH_POOL_BY_CODE_REQUEST_SUCCESS_TYPE = "SEARCH_POOL_BY_CODE_REQUEST_SUCCESS";
export const SEARCH_POOL_BY_CODE_FAILURE: SEARCH_POOL_BY_CODE_REQUEST_FAILURE_TYPE = "SEARCH_POOL_BY_CODE_REQUEST_FAILURE";

export const UPDATE_POOL_CODE_TO_SEARCH: UPDATE_POOL_CODE_TO_SEARCH_TYPE = "UPDATE_POOL_CODE_TO_SEARCH";